import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import ctx from 'classnames';

import {
    selectStudentCourseTopicIds
} from '../../../features/students/courseSlice';

import styles from './courseTopics.module.scss';
import { StudentCourseTopic } from './CourseTopic';
import { getTranslations } from '../../../features/translations/translationsUtils';

interface StudentCourseTopicsProps { }

export const StudentCourseTopics: FunctionComponent<StudentCourseTopicsProps> = () => {
    const topicIds = useSelector(selectStudentCourseTopicIds);
    if (!topicIds || topicIds.length === 0) {
        return null;
    }
    let lastId: string;
    const lastIndex = topicIds.length - 1;
    return <>
        <div className={styles.divider} />
        <div className={styles.title}>{getTranslations('STUDENT_TOPICS')}</div>
        <div className={ctx(styles.container)}>
            {topicIds.map((topicId, index) => {
                const topic = <StudentCourseTopic lastTopicId={lastId} key={topicId} id={topicId} isFirst={index === 0} isLast={lastIndex === index} />;
                lastId = topicId;
                return topic;
            })}
        </div>
    </>;
}
