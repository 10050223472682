import React, { FunctionComponent } from 'react';

import moment from 'moment';
import { Button } from 'antd';
import cls from 'classnames';
import { useSelector } from 'react-redux';

import { ICampaign } from '../../../../features/campaign/campaignSlice';
import { CogIcon } from '../../../common/CogIcon';
import { SIZES } from '../../../common/screen';
import { analytics } from '../../../../services/analytics';
import { useDeviceSize } from '../../../utils/useDeviceSize';
import { selectWasSurveyTaken } from '../../../../features/students/surveysTakenSlice';

import styles from './campaignBlock.module.scss';

import campaignIcon from '../../../../assets/campaign.png';
import { getTranslations } from '../../../../features/translations/translationsUtils';
import campaignPlaceholder from "../../../../assets/campaign_placeholder.svg";

interface ICampaignBlockProps {
  campaign: ICampaign
  setSurveyVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setStartTime: React.Dispatch<React.SetStateAction<moment.Moment>>,
}

const getSince = (date: moment.Moment) => {
  const now = moment();
  const diffYears = now.diff(date, 'years');
  if (diffYears > 0) {
    if (diffYears === 1) {
      return getTranslations('HOME_ONE_YEAR')
    }
    return getTranslations('HOME_TIME_IN_YEARS', [diffYears]);
  }
  const diffMonth = now.diff(date, 'month');
  if (diffMonth > 0) {
    if (diffYears === 1) {
      return getTranslations('HOME_ONE_MONTH');
    }
    return getTranslations('HOME_TIME_IN_MONTHS', [diffMonth]);
  }
  const diffWeeks = now.diff(date, 'weeks');
  if (diffWeeks > 7) {
    if (diffYears === 1) {
      return getTranslations('HOME_ONE_WEEK');
    }
    return getTranslations('HOME_TIME_IN_WEEKS', [diffWeeks]);
  }
  const diffDays = now.diff(date, 'days');
  if (diffDays > 0) {
    if (diffYears === 1) {
      return getTranslations('HOME_ONE_DAY');
    }
    return getTranslations('HOME_TIME_IN_DAYS', [diffDays]);
  }
  const diffHours = now.diff(date, 'hours');
  if (diffHours > 0) {
    if (diffYears === 1) {
      return getTranslations('HOME_ONE_HOUR');
    }
    return getTranslations('HOME_TIME_IN_HOURS', [diffHours]);
  }
  const diffMins = now.diff(date, 'minutes');
  if (diffMins > 0) {
    if (diffYears === 1) {
      return getTranslations('HOME_ONE_MINUTE');
    }
    return getTranslations('HOME_TIME_IN_MINUTES', [diffMins]);
  }
  return getTranslations('HOME_JUST_NOW');
}
export const CampaignBlock: FunctionComponent<ICampaignBlockProps> = ({ 
  campaign,
  setStartTime,
  setSurveyVisible
 }) => {
  const size = useDeviceSize();
  const isSurveyTaken = useSelector(selectWasSurveyTaken(campaign._id));
  const isMobile = size === SIZES.PHONE;

  const since = getSince(moment(campaign.fromDate));
  return <div className={cls(styles.container, {
    [styles.mobile]: isMobile,
  })}>
    <div className={styles.header}>
      <div className={styles.icon}>
        <img src={campaignIcon} alt="campaign" />
      </div>
      <div className={styles.titleContainer}>
        <div className={styles.title}>
          {campaign.title}
        </div>
        <div className={styles.since}>
          <CogIcon color='#81858B' size={17} icon='clock' /> {since}
        </div>
      </div>
    </div>
    <div className={styles.description}>
      {campaign.description}
    </div>
    <div className={styles.image} style={{
      background: campaign.image ? `url('${campaign.image}') lightgray 50% / cover no-repeat` : `url('${campaignPlaceholder}') #f7f8fa 50% / 40% no-repeat`
    }}>
    </div>
    <div className={styles.actions}>
      {isSurveyTaken ? null : <Button
        type='primary'
        size='large'
        className='clientSize'
        onClick={() => {
          setSurveyVisible(true);
          setStartTime(moment());
          analytics.selectPromoBanner({
            banner: campaign,
          });
        }}
      >
        Contestar encuesta
      </Button>}
    </div>
  </div>;
};