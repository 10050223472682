import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../app/AllowedMicroservices";
import { RootState } from "../../app/store";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { FetchStatus } from "../common/enums";
import { RegisterEvaluationStatus } from "./common/enums";
import {
  IEvaluation,
  RegisterEvaluationResultParams,
} from "./common/interfaces";

interface IEvaluationDict {[_id: string]: IEvaluation | null }
interface IStatusDict {[_id: string]: FetchStatus }
interface CurrentEvaluationState {
  evaluations: IEvaluationDict;
  status: IStatusDict;
  fetched: boolean;
  regiterStatus: RegisterEvaluationStatus;
}
const initialState: CurrentEvaluationState = {
  evaluations: {},
  status: {},
  regiterStatus: RegisterEvaluationStatus.Idle,
  fetched: false,
};

const evaluationSlice = createSlice({
  name: "currentEvaluation",
  initialState,
  reducers: {
    fetching: (state, action:PayloadAction<string>) => {
      state.status[ action.payload] = FetchStatus.Fetching;
    },
    setNotFetched(state) {
      state.fetched = false;
    },
    fetchedEvaluation: (state, action: PayloadAction<IEvaluation>) => {
      state.evaluations[ action.payload._id] = action.payload;
      state.status[ action.payload._id] = FetchStatus.Fetched;
      state.fetched = true;
    },
    error: (state, action:PayloadAction<string>) => {
      state.status[ action.payload] = FetchStatus.Error;
    },
    registering: (state) => {
      state.regiterStatus = RegisterEvaluationStatus.Registering;
    },
    registeredEvaluationResult: (state) => {
      state.regiterStatus = RegisterEvaluationStatus.Registered;
    },
    errorEvaluationResult: (state, action: PayloadAction) => {
      state.regiterStatus = RegisterEvaluationStatus.Error;
    },
  },
});

const BASE_ENDPOINT = "/evaluations";
const {
  fetching,
  fetchedEvaluation,
  error,
  registering,
  registeredEvaluationResult,
  errorEvaluationResult,
} = evaluationSlice.actions;

export const setNotFetched = evaluationSlice.actions.setNotFetched;
export const fetchEvaluation = (id: string, parentId: string, courseId: string) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_ENDPOINT}/${id}/${parentId}/${courseId}`,
      types: {
        requestType: {
          type: fetching.type,
          payload: id,
        },
        successTypes: [
          {
            actionOrCreator: fetchedEvaluation,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: {
              type: error.type,
              payload: id,
            }
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.EVALUATION_SERVICE,
      authenticated: true,
    })
  );
};

export const registerEvaluationResult = (
  params: RegisterEvaluationResultParams
) => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: `/evaluation/results/register`,
      types: {
        requestType: registering,
        successTypes: [
          {
            actionOrCreator: registeredEvaluationResult,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: errorEvaluationResult,
          },
        ],
      },
      method: HttpMethods.POST,
      data: params,
      microservice: Microservices.EVALUATION_SERVICE,
      authenticated: true,
    })
  );
};

export const selectEvaluation = (id:string) => (state: RootState):IEvaluation | null =>
  state.currentEvaluation.evaluations[id] ?? null;
export const selectIsEvaluationFetching = (id:string) =>  (state: RootState) =>
  state.currentEvaluation.status[id] === FetchStatus.Fetching;
export const selectIsEvaluationFetched = (state: RootState) =>
  state.currentEvaluation.fetched;
export const selectEvaluationFetchStatus = (id:string) => (state: RootState) =>
  state.currentEvaluation.status[id];

export default evaluationSlice.reducer;
