import React from "react";

import { Input } from "antd";

import styles from './videoYoutube.module.scss';

import linkIcon from '../../assets/link.svg';
import { getTranslations } from "../../features/translations/translationsUtils";

interface IVideoSwitcher {
  change: (value: string) => void;
  url: string;
  error: string;
  videoSwitcher: React.ReactElement | null;
}

export const VideoYoutube: React.FunctionComponent<IVideoSwitcher> = ({ change, url, error, videoSwitcher }) => {
  const onChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    change(value);
  }
  return <div className={styles.container}>
    {videoSwitcher}
    <div className={styles.imageContainer}>
      <img src={linkIcon} alt="link" />
    </div>
    <Input value={url} onChange={onChange} placeholder="Pega aquí el link del video de youtube" className={styles.imageContainer} />
    {!!error ? <div className={styles.error}>{error} {getTranslations('GENERAL_YOUTUBE_URL_INVALID')}</div> : null}
  </div>;
};
