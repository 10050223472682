import React, { useEffect, useState } from "react";

import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { selectCompanyId, selectUserRole } from '../../features/auth/authSlice';
import { IEvaluation } from "../../features/evaluation/common/interfaces";
import {
  fetchEvaluations,
  hideModal,
  selectAreEvaluationFetching,
  selectDisplayModal,
  selectEvaluationById,
  updateEvaluation,
} from "../../features/evaluation/evaluationsSlice";
import { UserRole } from '../../features/users/usersSlice';
import ConfirmationModal from "../common/ConfirmationModal";
import { AfterActions, DialogPopUp } from "../common/DialogPopUp";
import { MainLayout } from "../common/Layouts/Main";
import EvaluationForm, { Values } from "./Form";
import { useHistory, useRouteMatch } from "react-router-dom";
import LoadingOverlay from "../common/LoadingOverlay";
import { ActionSuffixes, Prefixes } from "../prefixes";
import { getTranslations } from "../../features/translations/translationsUtils";

export const EditEvaluation: React.FunctionComponent = () => {
  const [afterAction, setAfterAction] = useState<AfterActions>();
  const match = useRouteMatch();
  const history = useHistory();
  const [initialValues, setInitialValues] = useState<Values>();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const userRole = useSelector(selectUserRole);
  const companyId = useSelector(selectCompanyId);
  const selectedEvaluation = useSelector(selectEvaluationById((match.params as any).id!));

  const isModalVisible = useSelector(selectDisplayModal);
  const isLoading = useSelector(selectAreEvaluationFetching);
  const dispatch = useDispatch();


  const handleClose = () => {
    history.push(`${Prefixes.evaluation}`);
    setShowConfirmationModal(false);
  };
  const handleOpenConfirmationModal = () => setShowConfirmationModal(true);
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);
  const handleCreate = (currentEvaluation: IEvaluation, afterAction: AfterActions) => {
    if (userRole !== UserRole.SuperAdmin) {
      if (currentEvaluation.companyId !== companyId) { // don't allow editing courses that don't belong to the company
        return;
      }
      currentEvaluation.companyId = companyId; // force to company in case of creating
    }
    if (!currentEvaluation.companyId) {
      currentEvaluation.companyId = undefined;
    }
    setAfterAction(afterAction);
    dispatch(updateEvaluation({ ...currentEvaluation, _id: selectedEvaluation._id }))
  };

  useEffect(() => {
    dispatch(fetchEvaluations());
  }, [dispatch]);

  useEffect(() => {
    if (!selectedEvaluation) {
      return;
    }
    const loadValues = {
      type: selectedEvaluation.type,
      companyId: selectedEvaluation.companyId || '',
      evaluationTitle: selectedEvaluation.title,
      questions: selectedEvaluation.questions.map((question) => {
        const foundAnswer = question.possibleAnswers.findIndex(
          (answer) => answer._id === question.correctAnswer
        );
        return {
          title: question.title,
          answers: question.possibleAnswers.map((answer) => ({
            title: answer.label,
            feedback: answer.feedback ?? "",
          })),
          selected: foundAnswer ?? 0,
        };
      }),
    };
    setInitialValues(loadValues);
  }, [selectedEvaluation])

  if (!initialValues || isLoading) {
    return <LoadingOverlay spinning={true} />;
  }
  return (
    <MainLayout
      title={getTranslations('EVALUATION_EDIT')}
      onGoBack={() => {
        history.push(`${Prefixes.evaluation}`);
      }}
      breadcrumbs={[getTranslations('EVALUATION_TITLE'), getTranslations('EVALUATION_EDIT')]}
    >
      {showConfirmationModal && (
        <ConfirmationModal
          headerIcon="Help"
          title={getTranslations('EVALUATION_EXIT_MESSAGE')}
          message={
            <p>{getTranslations('EVALUATION_CANCEL_MESSAGE')}</p>
          }
          footer={[
            <Button key='keep' type="text" onClick={handleCloseConfirmationModal}>
              {getTranslations('EVALUATION_CANCEL_kEEP')}
            </Button>,
            <Button key='out' type="link" onClick={handleClose}>
              {getTranslations('EVALUATION_CANCEL_EXIT')}
            </Button>,
          ]}

          onCancel={handleCloseConfirmationModal}
          onConfirmation={handleClose}
        />
      )}
      <DialogPopUp
        name={getTranslations('EVALUATION_EXCELLENT')}
        action={"crear"}
        visible={isModalVisible}
        onCancel={() => {
          dispatch(hideModal());
          if (afterAction === AfterActions.BACK) {
            history.push(`${Prefixes.evaluation}`);
            return;
          }
          history.push(`${Prefixes.evaluation}${ActionSuffixes.new}`);
        }}
      />
      <EvaluationForm
        isLoading={isLoading}
        id={selectedEvaluation._id}
        onClose={handleOpenConfirmationModal}
        onSave={handleCreate}
        initialValues={initialValues}
      />
    </MainLayout>
  );
};
