import { Button, Card } from "antd";
import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import {
  selectIsEvaluationFetching,
  selectEvaluation,
} from "../../../../features/evaluation/currentEvaluationSlice";
import { selectStudentCourse } from "../../../../features/students/courseSlice";
import { CogIcon } from "../../../common/CogIcon";
import styles from "./firstPage.module.scss";
import { getTranslations } from "../../../../features/translations/translationsUtils";

interface FirstPageProps {
  evaluationId: string;
  className?: string;
  onNext: Function;
}

export const FirstPage: FunctionComponent<FirstPageProps> = ({ onNext, evaluationId }) => {
  const course = useSelector(selectStudentCourse);
  const isFetchingEvaluation = useSelector(selectIsEvaluationFetching(evaluationId));
  const evaluation = useSelector(selectEvaluation(evaluationId));
  const questionsCount = evaluation?.questions?.length || 1;
  const correctAnswersToPass = course?.correctAnswersToPass || 0;
  const percentageToPass = Math.round(
    (correctAnswersToPass / questionsCount) * 100
  );
  const buttonLabel = "Comenzar examen";
  const disabledButton = evaluation?.timeToRetake !== 0 || isFetchingEvaluation;

  let cannotRetake = null;
  if (
    evaluation &&
    (evaluation?.timeToRetake === false ||
      evaluation?.timeToRetake === undefined)
  ) {
    cannotRetake = <li>{getTranslations('STUDENT_EXAM_CANT_RETAKE')}</li>;
  } else if (evaluation && (evaluation?.timeToRetake || 0) > 0) {
    const timeToRetake = evaluation.timeToRetake as number;
    cannotRetake = (
      <li>
        {timeToRetake < 1 ? getTranslations('STUDENT_EXAM_RETAKE_LESS_THAN_MINUTE') : getTranslations('STUDENT_EXAM_RETAKE')}
      </li>
    );
  }
  return (
    <Card
      className={styles.container}
    >
      <CogIcon className={styles.icon} icon="test-1" />
      <div className={styles.info}>{getTranslations('STUDENT_EXAM_TITLE')}</div>
      <ul className={styles.infoList}>
        <li>{getTranslations('STUDENT_EXAM_INSTRUCTION_1')}</li>
        <li>{getTranslations('STUDENT_EXAM_INSTRUCTION_2', [
          percentageToPass > 100 || percentageToPass === 0
            ? "--"
            : percentageToPass
        ])}
          
        </li>
        <li>{getTranslations('STUDENT_EXAM_INSTRUCTION_3', [course?.timeToRetry ?? 0])}
        </li>
        {cannotRetake}
      </ul>
      <div className={styles.buttonContainer}>
        <Button
          className={styles.button}
          disabled={disabledButton}
          onClick={() => onNext()}
          type="primary"
        >
          {buttonLabel}
        </Button>
      </div>
    </Card>
  );
};
