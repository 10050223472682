import React, { FunctionComponent, useEffect, useState } from 'react';
import Table from 'antd/lib/table';
import { useSelector } from 'react-redux';

import { ICourse, ICourseDict, selectAreCoursesFetching, selectCoursesDict } from '../../../../../features/courses/coursesSlice';
import { ILessonDict, selectAreLessonsFetching, selectLessonsDict } from '../../../../../features/lesson/lessonSlice';
import { LearningType, IFulfillment, LearningLabels } from '../../../../../features/reports/learning/common/interfaces';
import { ITopicDict, selectAreTopicsFetching, selectTopics } from '../../../../../features/topic/topicSlice';
import { getColumnByType } from './ColumnsPerType';
import { RootState } from '../../../../../app/store';
import { StatusEnum } from '../../../../common/Status';
import { selectAreUsersFetching, selectStudentIdPerGroup, selectStudents } from '../../../../../features/users/usersSlice';
import { selectAreProgramsFetching, selectProgramsDict } from '../../../../../features/program/programSlice';
import { selectCompanies, selectCompaniesAreFetching } from '../../../../../features/company/companySlice';
import { selectAreGroupsFetching, selectCompaniesGroups, selectGroupsDict } from '../../../../../features/groups/groupSlice';
import { selectEvaluationsDict, selectAreEvaluationFetching } from '../../../../../features/evaluation/evaluationsSlice';
import { IGroupsChildren } from '../interfaces';
import { selectCompaniesContentsAreFetching } from '../../../../../features/reports/learning/common/companiesContent';
import { IEvaluationsCounters } from '../../../../../features/reports/learning/common/interfaces';

import styles from './index.module.scss';
import tableStyles from "../../../../common/table.module.scss";
import { ITableRecord } from './interfaces';
import { EvaluationTypeEnum } from '../../../../../features/evaluation/common/enums';
import { selectSurveyResultsCounters, selectSurveyResultsCountersIsFetching } from '../../../../../features/reports/learning/courses/surveyResultsCounters';
import { getTranslations } from '../../../../../features/translations/translationsUtils';


interface ContentTableProps {
  companyId: string;
  learningType: LearningType;
  selectFulfillments: (state: RootState) => IFulfillment[];
  selectFulfillmentsFetching: (state: RootState) => boolean;
  selectEvaluationsResultsCounters?: (state: RootState) => IEvaluationsCounters[];
  selectEvaluationsResultsCountersIsFetching?: (state: RootState) => boolean;
}
const PAGE_SIZE = 10;

export const ContentTable: FunctionComponent<ContentTableProps> = ({
  learningType,
  companyId,
  selectFulfillments,
  selectFulfillmentsFetching,
  selectEvaluationsResultsCounters = (state: RootState) => ([]),
  selectEvaluationsResultsCountersIsFetching = (state: RootState) => false,
}) => {
  const evaluationsResultsCounters = useSelector(selectEvaluationsResultsCounters);
  const evaluationsResultsCountersIsFetching = useSelector(selectEvaluationsResultsCountersIsFetching);
  const surveysResultsCounters = useSelector(selectSurveyResultsCounters);
  const surveysResultsCountersIsFetching = useSelector(selectSurveyResultsCountersIsFetching);
  const contentsAreFetching = useSelector(selectCompaniesContentsAreFetching);
  const [groupsChildren, setGroupsChildren] = useState<IGroupsChildren>({});
  const [groupsChildrenSet, setGroupsChildrenSet] = useState<boolean>(false);
  const [content, setContent] = useState<ITableRecord[]>([]);
  const [contentSet, setContentSet] = useState<boolean>(false);
  // Groups
  const groups = useSelector(selectGroupsDict);
  const companiesGroups = useSelector(selectCompaniesGroups);
  const groupFetching = useSelector(selectAreGroupsFetching);
  // Companies
  const companies = useSelector(selectCompanies);
  const companiesFetching = useSelector(selectCompaniesAreFetching);
  // Users
  const students = useSelector(selectStudents);
  const studentIdPerGroup = useSelector(selectStudentIdPerGroup);
  const studentsFetching = useSelector(selectAreUsersFetching);
  // Fulfillments/Content
  const isFulfillmentsFetching = useSelector(selectFulfillmentsFetching);
  const fulfillments = useSelector(selectFulfillments);
  //evaluations
  const evaluations = useSelector(selectEvaluationsDict);
  const isEvaluationFetching = useSelector(selectAreEvaluationFetching);
  // Lessons
  const lessonsFetching = useSelector(selectAreLessonsFetching);
  // Topics
  const topicsFetching = useSelector(selectAreTopicsFetching);
  // Courses
  const coursesFetching = useSelector(selectAreCoursesFetching);
  // Programs
  const programsFetching = useSelector(selectAreProgramsFetching);
  const programs = useSelector(selectProgramsDict);
  //LearningContent
  const learningContents: {
    lessons: ILessonDict;
    topics: ITopicDict;
    courses: ICourseDict;
  } = {
    lessons: useSelector(selectLessonsDict),
    topics: useSelector(selectTopics),
    courses: useSelector(selectCoursesDict),
  };

  const learningContent = learningContents[learningType];
  const columns = getColumnByType(learningType);
  const learningContentFetching = lessonsFetching || topicsFetching || coursesFetching || programsFetching || contentsAreFetching || groupFetching;

  const fetching = studentsFetching ||
    learningContentFetching ||
    isFulfillmentsFetching ||
    companiesFetching ||
    groupFetching ||
    isEvaluationFetching ||
    evaluationsResultsCountersIsFetching ||
    surveysResultsCountersIsFetching;
  const courses = learningContents.courses;
  const topics = learningContents.topics;
  useEffect(() => {
    setGroupsChildrenSet(false);
  }, [companyId]);
  useEffect(() => {
    if(groupsChildrenSet || learningContentFetching || Object.keys(courses).length === 0){
      return;
    }
    const groupsChildren: IGroupsChildren = {};
    Object.keys(courses).forEach((courseId) => {
      const groupsIds: string[] = Object.values(groups)
        .filter((group) => group.courses.includes(courseId))
        .map((group) => group._id);
      const course = courses[courseId];
      const topicsIds: string[] = course.topics;
      const lessonsIds: string[] = [];
      course.topics.forEach((topicId) => {
        const topic = topics[topicId];
        if(topic){
          lessonsIds.push(...topic.lessons);
        }
      });
      groupsIds.forEach((groupId) => {
        if (!groupsChildren[groupId]) {
          groupsChildren[groupId] = {
            courses: [],
            topics: [],
            lessons: [],
            students: 0,
          };
        }
        groupsChildren[groupId].lessons.push(...lessonsIds);
        groupsChildren[groupId].courses.push(courseId);
        groupsChildren[groupId].topics.push(...topicsIds);
      });
    });
    setGroupsChildrenSet(true);
    setGroupsChildren(groupsChildren);
  }, [
    courses,
    groups,
    topics,
    learningContentFetching,
    groupsChildrenSet,
    companyId,
  ]);
  useEffect(() => {
    const data: ITableRecord[] = [];
    if (
      fetching
    ) {
      setContentSet(false);    
      return;
    }
    if(contentSet || !groupsChildrenSet || Object.keys(courses).length === 0 || Object.keys(studentIdPerGroup).length === 0){
      return;
    }
    const getContentsIds = (coursesIds: string[], learningType: LearningType): { [id:string]: number } => {
      const contentsIds = ((coursesIds: string[], learningType: LearningType): string[] => {
        if(learningType === LearningType.courses){
          return coursesIds;
        }
        if(learningType === LearningType.topics){
          const topicsIds:string[] = [];
          coursesIds.forEach(courseId => {
            topicsIds.push(...learningContents.courses[courseId].topics)
          });
          return topicsIds;
        }
        const lessonsIds:string[] = [];
        coursesIds.forEach(courseId => {
          learningContents.courses[courseId].topics.forEach(topicId=>{
            if(!learningContents.topics[topicId]){
              return;
            }
            lessonsIds.push(...learningContents.topics[topicId].lessons)
          })
        })
        return lessonsIds;
      })(coursesIds, learningType);
      const results: { [id:string]: number } = {};
      contentsIds.forEach(contentId => {
        if(!results[contentId]){
          results[contentId] = 0;
        }
        ++results[contentId];
      })
      return results;
    };
    Object.keys(companies).forEach(itemCompanyId=>{
      if(companyId && companyId !== itemCompanyId){
        return;
      }
      const company = companies[itemCompanyId] || {};
      if(!company.studyProgram){
        return;
      }
      const program = programs[company.studyProgram];
      if(!program){
        return;
      }
      if(!companiesGroups[itemCompanyId]){
        return;
      }
      const companyGroups = companiesGroups[itemCompanyId] || [];
      const contentsTotals: {[id:string]:{total: number, completed: number}} = {}
      companyGroups.forEach(companyGroupId => {
        const group = groups[companyGroupId];
        if(!group){
          return;
        }
        const contentsIds = getContentsIds(group.courses, learningType);
        Object.keys(contentsIds).forEach(contentId=>{
          if(!contentsTotals[contentId]){
            contentsTotals[contentId] = {
              total: 0,
              completed: 0,
            };
          }
          const groupContents = groupsChildren[companyGroupId];
          if(!groupContents || !groupContents[learningType].includes(contentId)){
            return;
          }
          const contentFulfillments = fulfillments.filter(fulfillment => fulfillment.id === contentId && students[fulfillment.username]?.studentGroupId === companyGroupId && students[fulfillment.username]?.company ===  itemCompanyId && fulfillment.completed > 0);
          contentsTotals[contentId].completed += contentFulfillments.reduce((accumulator, currentValue) => accumulator + currentValue.completed, 0);
          contentsTotals[contentId].total += studentIdPerGroup[companyGroupId] ? studentIdPerGroup[companyGroupId].length : 0;
        });
      });
      let evaluationType: EvaluationTypeEnum;
      switch(learningType){
        case LearningType.courses:
          evaluationType = EvaluationTypeEnum.Exam;
          break;
        case LearningType.topics:
          evaluationType = EvaluationTypeEnum.Test;
          break;
        case LearningType.lessons:
          evaluationType = EvaluationTypeEnum.Quiz;
          break;
            }
      Object.keys(contentsTotals).forEach(contentId => {
        const content = learningContent[contentId] || {};
        const {total, completed} = contentsTotals[contentId];
        const evaluation = evaluations[content.evaluation || ''];
        let score:number|undefined = undefined;
        const usersIds: string[] = [];
        if(evaluation){
          score = 0;
          let total = 0;
          let passed = 0;
          const counters = evaluationsResultsCounters.filter(evaluationResultCounter => 
            evaluationResultCounter._id.parentId === contentId && 
            students[evaluationResultCounter._id.username]?.company ===  itemCompanyId
          );
          counters.forEach(evaluationResultCounter => {
            usersIds.push(evaluationResultCounter._id.username);
            total += evaluationResultCounter.total;
            passed += evaluationResultCounter.passed;
          });
          if(total > 0){
            score = passed / total * 100;
          }
        }
        const record: any = {
          company: company.name,
          contentName: (learningContent[contentId] || {}).title,
          evaluation: evaluation ? evaluation.title : ' -- ',
          fulfillment: (total > 0 ? completed / total * 100 : 0),
          key: `${company._id}-${contentId}`,
          status: content.status || StatusEnum.Disable,
          score,
          contentId,
          companyId: itemCompanyId,
          evaluationId: content.evaluation,
          evaluationType,
          contentType: learningType,
        };
        if(learningType === LearningType.courses){
          const counters = surveysResultsCounters.filter(surveyResultsCounter => surveyResultsCounter._id.parentId === contentId);
          const evaluation = evaluations[(content as ICourse).survey || ''];
          record.survey = evaluation ? evaluation.title : ' -- ';
          record.surveyId = evaluation ? evaluation._id : ' -- ';
          let total = 0;
          if(counters.length > 0){
            counters.forEach(evaluationResultCounter => {
              total += evaluationResultCounter.total;
            });
          }
          record.completed =  total ? 100 : 0;
        }
        data.push(record)
      });
    });
    setContentSet(true);
    setContent(data);
  }, [companies, companyId, companiesGroups, contentSet, courses, evaluations, evaluationsResultsCounters, fetching, fulfillments, groups, groupsChildren, groupsChildrenSet, learningContent, learningContents.courses, learningContents.topics, learningType, programs, studentIdPerGroup, students, surveysResultsCounters]);

  return <div className={styles.container}>
    <div className={styles.title}>{getTranslations('REPORT_FINISH_CONTENT_PER_COMPANY', [getTranslations(LearningLabels[learningType])])}</div>
    <Table<ITableRecord>
      loading={fetching}
      className={tableStyles.table}
      rowClassName={() => tableStyles.row}
      rowKey="key"
      columns={columns}
      dataSource={content}
      scroll={{ x: 2000, y: 500 }}
      pagination={{ position: ["bottomRight"], pageSize: PAGE_SIZE }}
    />
  </div>
};