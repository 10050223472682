import React, { FunctionComponent, useState } from "react";

import { useSelector } from "react-redux";
import moment from "moment";
import { Button, Progress } from "antd";
import { useHistory } from "react-router-dom";
import cls from 'classnames';

import { selectCategory } from "../../../../../../features/category/categorySlice";
import { CogIcon } from "../../../../../common/CogIcon";
import {
  COURSES_PRIORITY_COLORS,
  COURSES_PRIORITY_LABELS,
  COURSES_PRIORITY_ICON,
} from "../../../../../../utils/constants";
import ConfirmationModal from "../../../../../common/ConfirmationModal";
import { selectIsStudentMyCourseBlocked, selectStudentMyCoursesByIds } from "../../../../../../features/students/myCoursesSlice";
import placeholderImage from "../../../../../../assets/business-12.png";
import { IStudentCourse } from "../../../../../../features/students/common";
import { useDeviceSize } from "../../../../../utils/useDeviceSize";
import { SIZES } from "../../../../../common/screen";

import styles from "./courseBlock.module.scss";
import { getTranslations } from "../../../../../../features/translations/translationsUtils";
import { getTimeInHours } from "../../../../common/timeUtils";

interface ICourseBlockProps {
  courseKey: number,
  selector: any
}

export const CourseBlock: FunctionComponent<ICourseBlockProps> = ({
  courseKey,
  selector
}) => {
  const history = useHistory();
  const [isModalShowing, setIsModalShowing] = useState<boolean>(false);
  const { course, extra, advancePercentage, isCompleted }: IStudentCourse = useSelector(selector(courseKey));
  const { priority = 1 } = extra || {};
  const category = useSelector(selectCategory(course.category.toString()));
  const size = useDeviceSize();
  const isMobile = size === SIZES.PHONE;

  const isBlockedBecausePredecesor = useSelector(selectIsStudentMyCourseBlocked(course._id));
  const isCourseDateAvailable = extra?.endUndefined ?
    moment().isAfter(extra?.startDate) :
    moment().isBetween(
      extra?.startDate,
      extra?.endDate
    );
  const predecesorId = extra?.predecessor.map((id) => id) ?? [];
  const predecesorCourses = useSelector(selectStudentMyCoursesByIds(predecesorId));
  const predecesorCourse = predecesorCourses[0];

  const imageUrl = course.imageurl ?? placeholderImage;
  const startDate = extra?.startDate;
  const endDate = extra?.endDate;

  const goToPredecesor = () => {
    const courseId = predecesorCourse?.course._id || "";
    history.push(`/course/${courseId}`);
  };

  const renderForMobile = () => {
    return <div className={cls(styles.container, styles.mobile)}>
      <div className={styles.image} style={{
        background: `url("${imageUrl}") lightgray 50% / cover no-repeat`
      }} />
      <div className={styles.titleContainer}>
        <div className={styles.info}>
          <div className={styles.categories}>
            {category && course && <span className={styles.category}>{category.name}</span>}
          </div>
          <div className={styles.titleAndDescription}>
            <div className={styles.title}>{course.title} dsad sadsad sadsa dsad asd asdsad as dsad sadsad sadsa dsad asd asdsad as dsad sadsad sadsa dsad asd asdsad as dsad sadsad sadsa dsad asd asdsad as dsad sadsad sadsa dsad asd asdsad as</div>
            <div className={styles.description}>{course.description} dsad sadsad sadsa dsad asd asdsad as dsad sadsad sadsa dsad asd asdsad as dsad sadsad sadsa dsad asd asdsad as dsad sadsad sadsa dsad asd asdsad as dsad sadsad sadsa dsad asd asdsad as</div>
          </div>
        </div>
        <div className={styles.goto}>
          <div
            className={styles.button}
            onClick={() => {
              if ((isCompleted || isCourseDateAvailable) &&
                !isBlockedBecausePredecesor) {
                history.push(`/course/${course._id}`);
                return;
              }
              if (isBlockedBecausePredecesor) {
                setIsModalShowing(true);
              }
            }}
          >
            <CogIcon size="20" color="black" icon="Arrow-right-big" />
          </div>
        </div>
      </div>
      <div className={styles.progressbar}>
        <Progress percent={advancePercentage * 100} showInfo={false} />
      </div>
      <div className={styles.data}>
        <div className={styles.priority}>
          <CogIcon size={16} className={styles.icon} color={COURSES_PRIORITY_COLORS[priority]} icon={COURSES_PRIORITY_ICON[priority]} />
          {getTranslations('STUDENT_PRIORITY', [getTranslations(COURSES_PRIORITY_LABELS[priority])])}
        </div>
        <div className={styles.duration}>
          <CogIcon size={16} className={styles.icon} color="#81858B" icon="clock" />
          {getTimeInHours(course?.durationHour)}
        </div>
        <div className={styles.date}>
          {moment(startDate).format('DD-MM-YY')}
          {endDate ? <span>/</span> : null}
          {endDate ? `${moment(endDate).format('DD-MM-YY')}` : ''}
        </div>
      </div>
    </div>;
  }

  const renderForNoMobile = () => {
    return <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.image} style={{
          background: `url("${imageUrl}") lightgray 50% / cover no-repeat`
        }} />
        <div className={styles.info}>
          <div className={styles.categories}>
            {category && course && <span className={styles.category}>{category.name}</span>}
          </div>
          <div className={styles.titleAndDescription}>
            <div className={styles.title}>{course.title}</div>
            <div className={styles.description}>{course.description}</div>
          </div>
          <div className={styles.progressbar}>
            <Progress percent={advancePercentage * 100} showInfo={false} />
          </div>
        </div>
        <div className={styles.goto}>
          <div
            className={styles.button}
            onClick={() => {
              if ((isCompleted || isCourseDateAvailable) &&
                !isBlockedBecausePredecesor) {
                history.push(`/course/${course._id}`);
                return;
              }
              if (isBlockedBecausePredecesor) {
                setIsModalShowing(true);
              }
            }}
          >
            <CogIcon size="20" color="black" icon="Arrow-right-big" />
          </div>
        </div>
      </div>
      <div className={styles.data}>
        <div className={styles.priority}>
          <CogIcon size={16} className={styles.icon} color={COURSES_PRIORITY_COLORS[priority]} icon={COURSES_PRIORITY_ICON[priority]} />
          {getTranslations('STUDENT_PRIORITY', [getTranslations(COURSES_PRIORITY_LABELS[priority])])}
        </div>
        <div className={styles.duration}>
          <CogIcon size={16} className={styles.icon} color="#81858B" icon="clock" />
          {getTimeInHours(course?.durationHour)}
        </div>
        <div className={styles.date}>
          {moment(startDate).format('DD-MM-YYYY')}
          {endDate ? <span>/</span> : null}
          {endDate ? `${moment(endDate).format('DD-MM-YYYY')}` : ''}
        </div>
      </div>
    </div>;
  }

  const renderGoToPredecesor = () => {
    return <>
      {isModalShowing ? (
        <ConfirmationModal
          closable
          headerIcon="Attention"
          onCancel={() => setIsModalShowing(false)}
          title="Hay un curso predecesor pendiente"
          message={
            <div className={styles.containerModal}>
              <div className={styles.mainContent}>
                <div className={styles.description}>
                  Este curso no se puede mostrar hasta culminar el curso "
                  {predecesorCourse?.course.title}".
                </div>
              </div>
            </div>
          }
          footer={
            <Button
              type="link"
              onClick={() => goToPredecesor()}
              className={styles.text}
            >
              Ir al curso predecesor
            </Button>
          }
          onConfirmation={goToPredecesor}
        />
      ) : null}
    </>;
  };
  return <>
    {renderGoToPredecesor()}
    {isMobile ? renderForMobile() : renderForNoMobile()}
  </>;
}