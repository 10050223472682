import { Tooltip } from "antd";
import React from "react";
import award from "../../../assets/award.png";
import bullseye from "../../../assets/bullseye.png";
import { CogIcon } from "../../common/CogIcon";
import styles from "./TopFiveAward.module.scss";
import { getTranslations } from "../../../features/translations/translationsUtils";

interface Props {
  isInTop5: boolean;
  tooltipText: string;
  topAmount?: number;
}

export const TopFiveAward: React.FunctionComponent<Props> = ({
  isInTop5,
  tooltipText,
  topAmount = 10,
}) => {
  const title = isInTop5
    ? getTranslations('REPORT_COMPANY_IN_TOP_N')
    : getTranslations('REPORT_COMPANY_GET_TO_TOP_N');
  const subtitle = isInTop5 ? getTranslations('REPORT_IN_TOP_N', [topAmount]) : getTranslations('REPORT_GET_TO_TOP_N', [topAmount]);
  const text = isInTop5
    ? tooltipText
    : `${tooltipText}, ${getTranslations('REPORT_TOP_5_MOTIVATION_MESSAGE')}`;
  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <div className={styles.imageWrapper}>
          <img src={isInTop5 ? award : bullseye} alt={subtitle} />
        </div>
        <p>{title}</p>
        <div className={styles.subtitle}>
          <h3>{subtitle}</h3>
          <Tooltip placement="right" title={text} className={styles.tooltip}>
            <div>
              <CogIcon size="1.4em" color="#81858b" icon={"Attention"} />
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
