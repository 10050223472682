import React, { FunctionComponent } from "react";

import styles from './courses.module.scss';

import coursesIcon from '../../../assets/infographic/courses.png';
import { Progress } from "antd";
import { useSelector } from "react-redux";
import { selectUserCoursesCounts } from "../../../features/students/coursesCountsSlice";
import { getTranslations } from "../../../features/translations/translationsUtils";

export const Courses: FunctionComponent<{}> = () => {
  const counts = useSelector(selectUserCoursesCounts);
  const total = counts.completed + counts.inProgress + counts.pending;
  const percentage = Math.round((counts.completed ?? 0) / (+total ? total : 1) * 100);
  return <div className={styles.container}>
    <div className={styles.title}>{getTranslations('STUDENT_PROFILE_COURSES')}</div>
    <div className={styles.secondSection}>
      <div className={styles.chart}>
        <img src={coursesIcon} alt="Courses chart" />
        <Progress
          strokeWidth={8}
          width={153}
          trailColor="#DEE2E9"
          strokeColor="#3B54EC"
          className={styles.progressBar}
          showInfo={false}
          type="circle"
          percent={percentage}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.percentage}>{percentage}%</div>
        <div className={styles.description}>{getTranslations('STUDENT_PROFILE_COMPLETED_COURSES')}</div>
      </div>
    </div>
    <div className={styles.bottomSection}>
      <div className={styles.column}>
        <div className={styles.value}>{counts.completed}</div>
        <div className={styles.description}>{getTranslations('STUDENT_PROFILE_COMPLETED_COURSES')}</div>
      </div>
      <div className={styles.column}>
        <div className={styles.value}>{counts.inProgress}</div>
        <div className={styles.description}>{getTranslations('STUDENT_PROFILE_COURSES_IN_PROGRESS')}</div>
      </div>
      <div className={styles.column}>
        <div className={styles.value}>{counts.pending}</div>
        <div className={styles.description}>{getTranslations('STUDENT_PROFILE_PENDING_COURSES')}</div>
      </div>
    </div>
  </div>
};