import React, { FunctionComponent, useEffect } from 'react';
import { Select, Form } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import { selectCompanyTypes, selectCompanyTypesDict, fetchCompanyTypes } from '../../../features/companyType/companyTypeSlice';
import { ICompany } from '../../../features/company/companySlice';
import { getTranslations } from '../../../features/translations/translationsUtils';

export const OTHER_CATEGORY_OPTION = 'OTHER_CATEGORY_OPTION';

interface CompanyTypeFieldProps {
    object?: ICompany,
    setObject: Function,
    label: string,
    markRequired?: boolean,
}

export const CompanyTypeField: FunctionComponent<CompanyTypeFieldProps> = ({
    object,
    setObject,
    label,
    markRequired,
}) => {
    const categoriesIds = useSelector(selectCompanyTypes);
    const categories = useSelector(selectCompanyTypesDict);
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(fetchCompanyTypes()); 
    }, [dispatch]);
    return <React.Fragment>
        <Form.Item label={label} name={'category'} rules={[
                { required: markRequired, message: getTranslations('GENERAL_REQUIRED') }]}
                initialValue={object?.companyType?.toString()}>
        <Select
            placeholder={getTranslations('COMPANY_TYPE_SELECT')}
            style={{ width: '100%' }}
            onChange={(value) => {
                setObject({ ...object, companyType: value, companyTypeName: '' })
            }}>
            {categoriesIds.map(id => {
                return <Select.Option key={id} value={id}>{categories[id].name}</Select.Option>
            })}
        </Select>
        </Form.Item>
    </React.Fragment>
};