import React, { useEffect, useState } from "react";

import { Checkbox, DatePicker, Form, Input, InputNumber, Select, Col, Row, Switch } from "antd";
import { FormInstance } from "antd/lib/form";
import moment from "moment";
import phoneValidator from "phone";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompanies, ICompany, selectCompanies } from "../../features/company/companySlice";
import {
  selectPrograms,
  selectProgramsDict,
} from "../../features/program/programSlice";
import { dateFormatList } from "../../utils/constants";
import { CompanyTypeField } from "../common/CompanyTypeField";
import { StatusEnum } from "../common/Status";
import { UploadImage } from "../common/UploadImage";
import { Countries, ISelectValue, MIN_3_CHAR } from "../Users/Form";
import { PhoneInputAntdWrapper } from "../common/PhoneInputAntdWrapper";
import { getUniqueNamesCities } from "../common/cities.utils";
import { getTranslations } from "../../features/translations/translationsUtils";
import { selectLanguages } from "../../features/languages/languagesSlice";

import supportedLanguages from '../Translations/googleTranslateSupportedLanguages.json';

import formStyles from "../common/form.module.scss";

interface CompanyFormProps {
  newCompany?: ICompany;
  setNewCompany: Function;
  form: FormInstance;
}

export const CompanyForm: React.FunctionComponent<CompanyFormProps> = (
  props
) => {
  const { setNewCompany, newCompany, form } = props;
  const programsIds = useSelector(selectPrograms);
  const programs = useSelector(selectProgramsDict);
  const companies = useSelector(selectCompanies);
  const languages = useSelector(selectLanguages);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCompanies());
    form.setFieldsValue({
      logo: newCompany?.logo,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [loading, setLoading] = useState<boolean>(false);
  const [cities, setCities] = useState<ISelectValue[]>([]);
  const [citiesContactInfo, setCitiesContactInfo] = useState<ISelectValue[]>([]);
  const { web, sms, email } = newCompany?.notificationTypesEnabled ?? { web: false, sms: false, email: false };
  const hasNotificationOpened = !!(web || sms || email);
  const [notificationOpened, setNotificationOpened] = useState(hasNotificationOpened);
  useEffect(() => {
    (async () => {
      if (newCompany?.country) {
        const countryCities: any = await import(`../../assets/countries/cities/${newCompany?.country}.json`);
        const cities: ISelectValue[] = getUniqueNamesCities(countryCities);
        setCities(cities);
      }
      if (newCompany?.contactInfo?.country) {
        const countryCities: any = await import(`../../assets/countries/cities/${newCompany?.contactInfo?.country}.json`);
        const cities: ISelectValue[] = getUniqueNamesCities(countryCities);
        setCitiesContactInfo(cities);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleChange = (info: any) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setLoading(false);
      setNewCompany({ ...newCompany, logo: info.file.response.url });
      form.setFieldsValue({
        logo: info.file.response.url,
      });
    }
  };
  const usedPrograms = Object.values(companies).filter(company => company.studyProgram && company.studyProgram !== newCompany?.studyProgram).map(company => company.studyProgram);
  return (
    <Form
      form={form}
      className={formStyles.form}
      layout="vertical"
      name="control-hooks"
    >
      <Row gutter={50}>
        <Col span={10}>
          <div className={formStyles.section}>
            <Form.Item
              name="logo"
              wrapperCol={{ sm: 24 }}
              rules={[{ type: "string", required: true, message: "Logo requerido" }]}
            >
              <UploadImage
                loading={loading}
                imageUrl={newCompany?.logo}
                onHandleChange={handleChange}
              />
            </Form.Item>
          </div>
          <div className={formStyles.section}>
            <div className={formStyles.sectionTitle}>{getTranslations('COMPANY_INFO')}</div>
            <Form.Item
              initialValue={newCompany?.name}
              name="name"
              label={getTranslations('COMPANY_NAME')}
              rules={[
                { required: true, message: getTranslations('GENERAL_REQUIRED') },
                { type: "string", max: 100, message: getTranslations('COMPANY_NAME_ERROR_1') },
                { type: "string", min: 3, message: MIN_3_CHAR() },
              ]}
            >
              <Input
                onChange={(event) => {
                  setNewCompany({ ...newCompany, name: event.target.value });
                }}
                type="text"
                placeholder={getTranslations('COMPANY_NAME_PLACEHOLDER')}
              />
            </Form.Item>

            <CompanyTypeField
              markRequired
              label={getTranslations('COMPANY_TYPE')}
              object={newCompany}
              setObject={setNewCompany}
            />
            <Form.Item
              initialValue={newCompany?.ruc}
              name="ruc"
              label={getTranslations('COMPANY_RUC')}
              rules={[
                { required: true, message: getTranslations('GENERAL_REQUIRED') },
                {
                  validator: (rule, value: string = "", callback) => {
                    if (value.match(/\D/)) {
                      callback(getTranslations('COMPANY_RUC_ERROR_1'));
                      return;
                    }
                    callback();
                  },
                },
                { type: "string", len: 13, message: getTranslations('COMPANY_RUC_ERROR_2') },
              ]}
            >
              <Input
                onChange={(event) => {
                  const { value } = event.target;
                  setNewCompany({ ...newCompany, ruc: value.replace(/\D/g, "") });
                }}
                type="text"
                value={newCompany ? newCompany.ruc : ""}
                placeholder={getTranslations('COMPANY_RUC')}
              />
            </Form.Item>
            <Form.Item
              initialValue={newCompany?.maxActiveStudents}
              name="maxActiveStudents"
              label={getTranslations('COMPANY_MAX_STUDENTS')}
              rules={[
                { type: "number", required: true, message: getTranslations('GENERAL_REQUIRED') },
                { type: "number", max: 10000, message: getTranslations('COMPANY_MAX_STUDENTS_ERROR_1') },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                onChange={(value) => {
                  setNewCompany({ ...newCompany, maxActiveStudents: value });
                }}
                type="number"
                placeholder={getTranslations('COMPANY_MAX_STUDENTS')}
              />
            </Form.Item>
            <Form.Item
              initialValue={newCompany?.address}
              name="address"
              label={getTranslations('COMPANY_ADDRESS')}
              rules={[
                { required: true, message: getTranslations('GENERAL_REQUIRED') },
                { type: "string", max: 200, message: getTranslations('COMPANY_ADDRESS_ERROR_1') },
                { type: "string", min: 3, message: MIN_3_CHAR() },
              ]}
            >
              <Input.TextArea
                onChange={(event) => {
                  setNewCompany({ ...newCompany, address: event.target.value });
                }}
                placeholder={getTranslations('COMPANY_ADDRESS')}
              />
            </Form.Item>
            <div className={formStyles.separator} />
            <Row gutter={14}>
              <Col span={12}>
                <Form.Item
                  initialValue={newCompany?.country}
                  name="country"
                  label={getTranslations('COMPANY_COUNTRY')}
                  rules={[{ required: true, message: getTranslations('GENERAL_REQUIRED') }]}
                >
                  <Select
                    showSearch
                    filterOption={(input, option) => {
                      return (option?.children?.toString().toLowerCase() ?? '').includes(`${input}`.toLowerCase());
                    }}
                    filterSort={(optionA, optionB) => {
                      return (optionA?.children?.toString() ?? '').toLowerCase().localeCompare((optionB?.children?.toString() ?? '').toLowerCase());
                    }
                    }
                    placeholder={getTranslations('COMPANY_COUNTRY_PLACEHOLDER')}
                    onChange={async (value) => {
                      setNewCompany({ ...newCompany, country: value });
                      form.setFieldsValue({
                        city: null
                      });
                      const countryCities: any = await import(`../../assets/countries/cities/${value}.json`);
                      const cities: ISelectValue[] = getUniqueNamesCities(countryCities);
                      setCities(cities);
                    }}
                  >
                    {Countries.map((item) => (
                      <Select.Option key={item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  initialValue={newCompany?.city}
                  name="city"
                  label={getTranslations('COMPANY_CITY')}
                  rules={[{ required: true, message: getTranslations('GENERAL_REQUIRED') }]}
                >
                  <Select
                    showSearch
                    placeholder={getTranslations('COMPANY_CITY_PLACEHOLDER')}
                    onChange={(value) => {
                      setNewCompany({ ...newCompany, city: value });
                    }}
                  >
                    {cities.map((item) => (
                      <Select.Option key={item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              initialValue={newCompany?.supportEmail}
              name="supportEmail"
              label={getTranslations('COMPANY_EMAIL_SUPPORT')}
              rules={[
                { type: "email", message: getTranslations('COMPANY_EMAIL_SUPPORT_ERROR_1') },
                { type: "string", max: 50, message: getTranslations('COMPANY_EMAIL_SUPPORT_ERROR_2') },
              ]}
            >
              <Input
                onChange={(event) => {
                  setNewCompany({ ...newCompany, supportEmail: event.target.value });
                }}
                type="email"
                placeholder={getTranslations('COMPANY_EMAIL_SUPPORT_PLACEHOLDER')}
              />
            </Form.Item>
            <Form.Item
              initialValue={newCompany?.language ?? 'es'}
              name="language"
              label={getTranslations('COMPANY_LANGUAGE')}
              rules={[{ required: true, message: getTranslations('GENERAL_REQUIRED') }]}
            >
              <Select
                showSearch
                filterOption={(input, option) => {
                  return (option?.children?.toString().toLowerCase() ?? '').includes(`${input}`.toLowerCase());
                }}
                filterSort={(optionA, optionB) => {
                  return (optionA?.children?.toString() ?? '').toLowerCase().localeCompare((optionB?.children?.toString() ?? '').toLowerCase());
                }
                }
                placeholder={getTranslations('COMPANY_LANGUAGE_PLACEHOLDER')}
                onChange={async (value) => {
                  setNewCompany({ ...newCompany, language: value });
                }}
              >
                {languages.map((language) => {
                  const item = supportedLanguages.find((item) => item.code === language);
                  return <Select.Option key={item?.code} value={item?.code}>
                  {item?.language}
                </Select.Option>;
                })}
              </Select>
            </Form.Item>
          </div>
        </Col>
        <Col span={14}>
          <div className={formStyles.section}>
            <div className={formStyles.sectionTitle}>{getTranslations('COMPANY_CONTACT_INFO')}</div>
            <Row gutter={15}>
              <Col span={12}>
                <Form.Item
                  initialValue={newCompany?.contactInfo?.firstname}
                  name="contactInfofirstname"
                  label={getTranslations('COMPANY_FIRST_NAME')}
                  rules={[
                    { required: true, message: getTranslations('GENERAL_REQUIRED') },
                    { type: "string", min: 3, message: MIN_3_CHAR() },
                    { type: "string", max: 15, message: getTranslations('COMPANY_FIRST_NAME_ERROR_1') },
                    {
                      validator: (rule, value: string = "", callback) => {
                        if (value.match(/\d/)) {
                          callback(getTranslations('USERS_NO_NUMBER_ALLOWED'));
                          return;
                        }
                        callback();
                      },
                    },
                  ]}
                >
                  <Input
                    onChange={(event) => {
                      setNewCompany({
                        ...newCompany,
                        contactInfo: {
                          ...newCompany?.contactInfo,
                          firstname: event.target.value,
                        },
                      });
                    }}
                    type="text"
                    placeholder={getTranslations('COMPANY_FIRST_NAME')}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  initialValue={newCompany?.contactInfo?.middlename}
                  name="contactInfomiddlename"
                  label={getTranslations('COMPANY_SECOND_NAME')}
                  rules={[
                    { type: "string", max: 15, message: getTranslations('COMPANY_SECOND_NAME_ERROR_1') },
                    { type: "string", min: 3, message: MIN_3_CHAR() },
                    {
                      validator: (rule, value: string = "", callback) => {
                        if (value.match(/\d/)) {
                          callback(getTranslations('USERS_NO_NUMBER_ALLOWED'));
                          return;
                        }
                        callback();
                      },
                    },
                  ]}
                >
                  <Input
                    onChange={(event) => {
                      setNewCompany({
                        ...newCompany,
                        contactInfo: {
                          ...newCompany?.contactInfo,
                          middlename: event.target.value,
                        },
                      });
                    }}
                    type="text"
                    placeholder={getTranslations('COMPANY_SECOND_NAME')}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col span={12}>
                <Form.Item
                  initialValue={newCompany?.contactInfo?.lastname}
                  name="contactInfolastname"
                  label={getTranslations('COMPANY_LAST_NAME')}
                  rules={[
                    { required: true, message: getTranslations('GENERAL_REQUIRED') },
                    { type: "string", max: 15, message: getTranslations('COMPANY_LAST_NAME_ERROR_1') },
                    { type: "string", min: 3, message: MIN_3_CHAR() },
                    {
                      validator: (rule, value: string = "", callback) => {
                        if (value.match(/\d/)) {
                          callback(getTranslations('USERS_NO_NUMBER_ALLOWED'));
                          return;
                        }
                        callback();
                      },
                    },
                  ]}
                >
                  <Input
                    onChange={(event) => {
                      setNewCompany({
                        ...newCompany,
                        contactInfo: {
                          ...newCompany?.contactInfo,
                          lastname: event.target.value,
                        },
                      });
                    }}
                    type="text"
                    placeholder={getTranslations('COMPANY_LAST_NAME')}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  initialValue={newCompany?.contactInfo?.secondLastname}
                  name="contactInfosecondLastname"
                  label={getTranslations('COMPANY_SECOND_LAST_NAME')}
                  rules={[
                    { type: "string", max: 15, message: getTranslations('COMPANY_SECOND_LAST_NAME_ERROR_1') },
                    { type: "string", min: 3, message: MIN_3_CHAR() },
                    {
                      validator: (rule, value: string = "", callback) => {
                        if (value.match(/\d/)) {
                          callback(getTranslations('USERS_NO_NUMBER_ALLOWED'));
                          return;
                        }
                        callback();
                      },
                    },
                  ]}
                >
                  <Input
                    onChange={(event) => {
                      setNewCompany({
                        ...newCompany,
                        contactInfo: {
                          ...newCompany?.contactInfo,
                          secondLastname: event.target.value,
                        },
                      });
                    }}
                    type="text"
                    placeholder={getTranslations('COMPANY_SECOND_LAST_NAME')}
                  />
                </Form.Item>
              </Col>
            </Row>
            <div className={formStyles.separator} />
            <Row gutter={15}>
              <Col span={12}>
                <Form.Item
                  initialValue={newCompany?.contactInfo?.email}
                  name="contactInfoemail"
                  label={getTranslations('COMPANY_EMAIL')}
                  rules={[
                    { required: true, message: getTranslations('GENERAL_REQUIRED') },
                    { type: "email", message: getTranslations('COMPANY_EMAIL_ERROR_1') },
                    { type: "string", max: 50, message: getTranslations('COMPANY_EMAIL_ERROR_2') },
                  ]}
                >
                  <Input
                    onChange={(event) => {
                      setNewCompany({
                        ...newCompany,
                        contactInfo: {
                          ...newCompany?.contactInfo,
                          email: event.target.value,
                        },
                      });
                    }}
                    type="email"
                    placeholder={getTranslations('COMPANY_EMAIL')}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  initialValue={newCompany?.contactInfo?.cellphone}
                  name="contactInfocellphone"
                  label={getTranslations('COMPANY_CELLPHONE')}
                  rules={[
                    { required: true, message: getTranslations('GENERAL_REQUIRED') },
                    (obj) => ({
                      validator: async (rules, value) => {
                        const plusPhone = `+${value}`;
                        const phone = phoneValidator(plusPhone);
                        if (phone.length === 0) {
                          throw new Error(
                            getTranslations('COMPANY_CELLPHONE_ERROR_1')
                          );
                        }
                      },
                    }),
                  ]}
                >
                  {/* @ts-ignore */}
                  <PhoneInputAntdWrapper
                    onChange={(phone) => {
                      const plusPhone = `+${phone}`;
                      setNewCompany({
                        ...newCompany,
                        contactInfo: {
                          ...newCompany?.contactInfo,
                          cellphone: plusPhone,
                        },
                      });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <div className={formStyles.separator} />
            <Form.Item
              initialValue={newCompany?.contactInfo?.position}
              name="contactInfoposition"
              label={getTranslations('COMPANY_JOB')}
              rules={[
                { required: true, message: getTranslations('GENERAL_REQUIRED') },
                { type: "string", min: 3, message: MIN_3_CHAR() },
                { type: "string", max: 50, message: getTranslations('COMPANY_JOB_ERROR_1') },
              ]}
            >
              <Input
                onChange={(event) => {
                  setNewCompany({
                    ...newCompany,
                    contactInfo: {
                      ...newCompany?.contactInfo,
                      position: event.target.value,
                    },
                  });
                }}
                type="text"
                placeholder={getTranslations('COMPANY_JOB')}
              />
            </Form.Item>
            <Row gutter={15}>
              <Col span={12}>
                <Form.Item
                  initialValue={newCompany?.contactInfo?.country}
                  name="contactInfocountry"
                  label={getTranslations('COMPANY_COUNTRY')}
                  rules={[{ required: true, message: getTranslations('GENERAL_REQUIRED') }]}
                >
                  <Select
                    showSearch
                    filterOption={(input, option) => {
                      return (option?.children?.toString().toLowerCase() ?? '').includes(`${input}`.toLowerCase());
                    }}
                    filterSort={(optionA, optionB) => {
                      return (optionA?.children?.toString() ?? '').toLowerCase().localeCompare((optionB?.children?.toString() ?? '').toLowerCase());
                    }
                    }
                    placeholder={getTranslations('COMPANY_COUNTRY_PLACEHOLDER')}
                    onChange={async (value) => {
                      setNewCompany({
                        ...newCompany,
                        contactInfo: { ...newCompany?.contactInfo, country: value },
                      });
                      form.setFieldsValue({
                        contactInfocity: null,
                      });
                      const countryCities: any = await import(`../../assets/countries/cities/${value}.json`);
                      const cities: ISelectValue[] = getUniqueNamesCities(countryCities);
                      setCitiesContactInfo(cities);
                    }}
                  >
                    {Countries.map((item) => (
                      <Select.Option key={item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  initialValue={newCompany?.contactInfo?.city}
                  name="contactInfocity"
                  label={getTranslations('COMPANY_CITY')}
                  rules={[{ required: true, message: getTranslations('GENERAL_REQUIRED') }]}
                >
                  <Select
                    showSearch
                    placeholder={getTranslations('COMPANY_CITY_PLACEHOLDER')}
                    onChange={(value) => {
                      setNewCompany({
                        ...newCompany,
                        contactInfo: { ...newCompany?.contactInfo, city: value },
                      });
                    }}
                  >
                    {citiesContactInfo.map((item) => (
                      <Select.Option key={item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              initialValue={newCompany?.contactInfo?.address}
              name="contactInfoaddress"
              label={getTranslations('COMPANY_ADDRESS')}
              rules={[
                { required: true, message: getTranslations('GENERAL_REQUIRED') },
                { type: "string", min: 3, message: MIN_3_CHAR() },
                { type: "string", max: 200, message: getTranslations('COMPANY_ADDRESS_ERROR_1') },
              ]}
            >
              <Input.TextArea
                onChange={(event) => {
                  setNewCompany({
                    ...newCompany,
                    contactInfo: {
                      ...newCompany?.contactInfo,
                      address: event.target.value,
                    },
                  });
                }}
                placeholder={getTranslations('COMPANY_ADDRESS')}
              />
            </Form.Item>
          </div>
          <div className={formStyles.section}>
            <div className={formStyles.sectionTitle}>{getTranslations('COMPANY_CONTRACT')}</div>
            <Form.Item
              initialValue={moment(newCompany?.contractDate)}
              name="contractDate"
              label={getTranslations('COMPANY_CONTRACT_START_DATE')}
              rules={[{ required: true, message: getTranslations('GENERAL_REQUIRED') }]}
            >
              <DatePicker
                disabledDate={(current) =>
                  current && current < moment().startOf("day")
                }
                onChange={(value) => {
                  setNewCompany({ ...newCompany, contractDate: value?.toDate() });
                }}
                format={dateFormatList}
              />
            </Form.Item>
            <Form.Item
              initialValue={newCompany?.studyProgram}
              name="studyProgram"
              label={getTranslations('COMPANY_STUDY_PROGRAM')}
              rules={[{ required: true, message: getTranslations('GENERAL_REQUIRED') }]}
            >
              <Select
                placeholder={getTranslations('COMPANY_STUDY_PROGRAM_PLACEHOLDER')}
                value={newCompany?.studyProgram}
                style={{ width: "100%" }}
                onChange={(value) => {
                  setNewCompany({ ...newCompany, studyProgram: value });
                }}
              >
                {programsIds.map((id) => {
                  if (programs[id] && programs[id].status === StatusEnum.Enable && !usedPrograms.includes(id)) {
                    return (
                      <Select.Option key={id} value={id}>
                        {programs[id].title}
                      </Select.Option>
                    );
                  }
                  return null;
                })}
              </Select>
            </Form.Item>
            <div className={formStyles.separator} />
            <Form.Item
              initialValue={newCompany?.showInfographic ?? false}
              name="showInfographic"
              label={getTranslations('COMPANY_INFOGRAPHIC')}
              className={formStyles.itemSwitch}
            >
              <Switch checked={newCompany?.showInfographic ?? false} size='small'
                onChange={(checked) => {
                  setNewCompany({
                    ...newCompany,
                    showInfographic: checked,
                    showGamification: checked,
                  });
                }}
              />
            </Form.Item>
            <div className={formStyles.separator} />
            <Form.Item
              initialValue={newCompany?.showKnowledgeBase ?? false}
              name="showKnowledgeBase"
              label={getTranslations('COMPANY_KNOWLEDGE_BASE')}
              className={formStyles.itemSwitch}
            >
              <Switch checked={newCompany?.showKnowledgeBase ?? false} size='small'
                onChange={(checked) => {
                  setNewCompany({
                    ...newCompany,
                    showKnowledgeBase: checked
                  });
                }}
              />
            </Form.Item>
            <div className={formStyles.separator} />
            <div className={formStyles.hidableContainer}>
              <div className={formStyles.label}>{getTranslations('COMPANY_NOTIFICATIONS')}</div>
              <Switch checked={notificationOpened} size='small' onChange={() => {
                setNewCompany({
                  ...newCompany,
                  notificationTypesEnabled: {
                    sms: false,
                    emal: false,
                    web: false,
                  }
                });
                setNotificationOpened(!notificationOpened);
              }} />
            </div>
            {notificationOpened ?
              <Form.Item name="notifications">
                <Checkbox
                  checked={newCompany?.notificationTypesEnabled?.sms}
                  onChange={(event) => {
                    setNewCompany({
                      ...newCompany,
                      notificationTypesEnabled: {
                        ...newCompany?.notificationTypesEnabled,
                        sms: event.target.checked,
                      },
                    });
                  }}
                >
                  {getTranslations('COMPANY_NOTIFICATIONS_SMS')}
                </Checkbox><br />
                <Checkbox
                  checked={newCompany?.notificationTypesEnabled?.email}
                  onChange={(event) => {
                    setNewCompany({
                      ...newCompany,
                      notificationTypesEnabled: {
                        ...newCompany?.notificationTypesEnabled,
                        email: event.target.checked,
                      },
                    });
                  }}
                >
                  {getTranslations('COMPANY_NOTIFICATIONS_EMAIL')}
                </Checkbox><br />
                <Checkbox
                  checked={newCompany?.notificationTypesEnabled?.web}
                  onChange={(event) => {
                    setNewCompany({
                      ...newCompany,
                      notificationTypesEnabled: {
                        ...newCompany?.notificationTypesEnabled,
                        web: event.target.checked,
                      },
                    });
                  }}
                >
                  {getTranslations('COMPANY_NOTIFICATIONS_PUSH')}
                </Checkbox>
              </Form.Item> : null}
            <Form.Item name="xOneDisabled" label="">
              <Checkbox
                checked={newCompany?.xOneDisabled}
                onChange={(event) => {
                  setNewCompany({
                    ...newCompany,
                    xOneDisabled: event.target.checked
                  });
                }}
              >
                {getTranslations('COMPANY_DISABLE_REPORT_MODULE')}
              </Checkbox>
            </Form.Item>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
