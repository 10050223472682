import { Button, Card, Carousel, Tabs } from "antd";
import { CarouselRef } from "antd/lib/carousel";
import { default as cls, default as ctx } from "classnames";
import React, {
  forwardRef,
  FunctionComponent,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getCognitoUserData } from "../../../features/auth/authSlice";
import {
  fetchCategories,
  ICategory,
  selectCategory,
} from "../../../features/category/categorySlice";
import {
  fetchCompany,
  selectCompany,
} from "../../../features/company/companySlice";
import { ParentTypeEnum } from "../../../features/evaluation/common/enums";
import { IAnswer } from "../../../features/evaluation/common/interfaces";
import {
  fetchEvaluation,
  selectEvaluation,
} from "../../../features/evaluation/currentEvaluationSlice";
import {
  fetchEvaluations,
  selectEvaluationById,
} from "../../../features/evaluation/evaluationsSlice";
import { fetchGroups, selectGroup } from "../../../features/groups/groupSlice";
import { ILesson, MediaTypes, VideoTypes } from "../../../features/lesson/lessonSlice";
import { setMessageError } from "../../../features/message/messageSlice";
import {
  fetchProgram,
  fetchPrograms,
  selectProgram,
  selectPrograms,
  selectProgramsDict,
} from "../../../features/program/programSlice";
import {
  COURSE_EXAM,
  COURSE_SURVEY,
  fetchStudentCourse,
  FINISH_COURSE,
  makeProgressStudentCourse,
  selectStudentCourse,
  selectStudentCourseLesson,
  selectStudentCourseLessonProgress,
  selectStudentCourseNextLessonId,
  selectStudentCourseTopic,
  selectStudentCourseTopicLesson,
  TOPIC_TEST,
} from "../../../features/students/courseSlice";
import { ITopic, ITopicEmpty } from "../../../features/topic/topicSlice";
import { selectUser } from "../../../features/users/usersSlice";
import { analytics } from "../../../services/analytics";
import { CogIcon } from "../../common/CogIcon";
import { CogDrawer } from "../../common/Drawer";
import { StudentMainLayout } from "../../common/Layouts/StudentMainLayout";
import LoadingOverlay from "../../common/LoadingOverlay";
import { SIZES } from "../../common/screen";
import { useDeviceSize } from "../../utils/useDeviceSize";
import { TwoColumnLayout } from "../common/TwoColumnLayout";
import { Quiz } from "../Evaluation/Quiz";
import { Survey } from "../Evaluation/Survey";
import { StudentLessonCourseInfo } from "./CourseInfo";
import styles from "./index.module.scss";
import { StudentLessonTopics } from "./Topics";
import { VideoLesson } from "./Video";
import { PDFViewer } from "./PDFViewer";
import { getTranslations } from "../../../features/translations/translationsUtils";
// import { convertToP } from "../../utils/convertions";
// import { CategoryChip } from "../common/Chip";
import { LessonTeacher } from "./Teacher";

const { TabPane } = Tabs;
interface StudentLessonProps { }

const label = () => ({
  TAKE_QUIZ: getTranslations('STUDENT_TAKE_QUIZ'),
  TAKE_TEST: getTranslations('STUDENT_TAKE_TEST'),
  COMPLETE_COURSE: getTranslations('STUDENT_COMPLETE_COURSE'),
  TAKE_EXAM: getTranslations('STUDENT_TAKE_EXAM'),
  TAKE_SURVEY_COMPLETE_COURSE: getTranslations('STUDENT_TAKE_SURVEY_AND_COMPLETE_COURSE'),
  COMPLETE_LESSON_AND_CONTINUE: getTranslations('STUDENT_COMPLETE_LESSON_AND_CONTINUE'),
});

export const getButtonLabel = (
  hasEvaluation: boolean,
  evaluationIsDone: boolean,
  nextLessonId: string,
  isVideoLesson: boolean
) => {
  if (hasEvaluation && !evaluationIsDone && !isVideoLesson) {
    return label().TAKE_QUIZ;
  }
  if (nextLessonId === TOPIC_TEST) {
    return label().TAKE_TEST;
  }
  if (nextLessonId === FINISH_COURSE) {
    return label().COMPLETE_COURSE;
  }
  if (nextLessonId === COURSE_EXAM) {
    return label().TAKE_EXAM;
  }
  if (nextLessonId === COURSE_SURVEY) {
    return label().TAKE_SURVEY_COMPLETE_COURSE;
  }
  return label().COMPLETE_LESSON_AND_CONTINUE;
};

interface NavigationAndProgressProps {
  topicId: string;
  lessonId: string;
}

const NavigationAndProgress: FunctionComponent<NavigationAndProgressProps> = ({
  topicId,
  lessonId,
}) => {
  return (
    <React.Fragment>
      <StudentLessonCourseInfo />
      <StudentLessonTopics
        currentTopicId={topicId}
        currentLessonId={lessonId}
        isExam={false}
        isTest={false}
      />
    </React.Fragment>
  );
};

interface InfoProps {
  lesson: ILesson;
  category: ICategory;
  cta?: React.ReactNode;
}

const Info: FunctionComponent<InfoProps> = ({ lesson, category, cta }) => {
  if (!lesson.teacher) {
    return <React.Fragment>
    <div className={styles.cta}>{cta}</div>
  </React.Fragment>;
  }
  return (
    <React.Fragment>
      <div className={styles.cta}>{cta}</div>
      <Card className={styles.info}>
        <Tabs defaultActiveKey="1">
          {/* <TabPane tab="Información" key="1">
            {convertToP(lesson.description, styles.description, styles.p)}
          </TabPane> */}
          <TabPane tab="Detalle de la lección" key="2">
            {/* <div className={styles.title}>{lesson.title}</div>
            <div className={styles.categoryContainer}>
              <CategoryChip categoryName={category?.name} />
            </div> */}
            <LessonTeacher teacherId={lesson.teacher as string} />
          </TabPane>
        </Tabs>
      </Card>
    </React.Fragment>
  );
};

interface MastheadProps {
  topic: ITopic | ITopicEmpty;
  courseId: string;
  lesson: ILesson;
  hasEvaluation: boolean;
  showEvaluation: boolean;
  evaluationIsDone: boolean;
  lessonId: string;
  setEvaluationIsDone: React.Dispatch<SetStateAction<boolean>>;
  setShowEvaluation: React.Dispatch<SetStateAction<boolean>>;
  media: React.ReactNode;
}
const Masthead: FunctionComponent<MastheadProps> = ({
  topic,
  lesson,
  courseId,
  hasEvaluation,
  showEvaluation,
  evaluationIsDone,
  lessonId,
  setEvaluationIsDone,
  setShowEvaluation,
  media,
}) => {
  // analytics
  const completeQuizRef = useRef<AnalyticsCompleteQuizRef>(null);
  const [quizAnswers, setQuizAnswers] = useState<IAnswer[]>([]);
  // analytics

  return (
    <div className={styles.masthead}>
      <Card className={styles.titleContainer}>
        <div className={styles.topicTitle}>{topic?.title}</div>
        <div className={styles.title}>{lesson?.title}</div>
      </Card>
      {hasEvaluation && showEvaluation && !evaluationIsDone ? (
        <Quiz
          courseId={courseId}
          lessonId={lessonId}
          className={styles.quizContainer}
          quizId={lesson?.evaluation || ""}
          setQuizAnswers={setQuizAnswers}
          onEvaluationComplete={() => {
            setEvaluationIsDone(true);
            setShowEvaluation(false);
            setTimeout(() => {
              completeQuizRef?.current?.trigger(topic as ITopic, quizAnswers);
            }, 500);
          }}
        />
      ) : null}
      {media}
      <AnalyticsCompleteQuiz
        ref={completeQuizRef}
        lessonId={lesson._id || ""}
      />
    </div>
  );
};

export const StudentLesson: FunctionComponent<StudentLessonProps> = () => {
  const [showSurvey, setShowSurvey] = useState<boolean>(false);
  const [isVideoEnded, setIsVideoEnded] = useState<boolean>(false);
  const [enableNextButtonForCarrousel, setEnableNextButtonForCarrousel] =
    useState<boolean>(false);
  const [showEvaluation, setShowEvaluation] = useState<boolean>(false);
  const [evaluationIsDone, setEvaluationIsDone] = useState<boolean>(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);
  const { courseId, lessonId }: { courseId: string; lessonId: string } =
    useParams();
  const lesson = useSelector(selectStudentCourseLesson(lessonId));
  const course = useSelector(selectStudentCourse);
  const hasEvaluation = !!lesson?.evaluation;
  const progress = useSelector(selectStudentCourseLessonProgress(lessonId));
  const nextLessonId = useSelector(selectStudentCourseNextLessonId(lessonId));
  const history = useHistory();
  const category = useSelector(selectCategory(lesson?.category as string));
  const topic = useSelector(selectStudentCourseTopicLesson(lessonId));
  const topicId = topic?._id;
  const refCarousel = useRef<CarouselRef>(null);
  const dispatch = useDispatch();
  // analytics
  const selectTestRef = useRef<AnalyticsSelectTestRef>(null);
  const selectExamRef = useRef<AnalyticsSelectExamRef>(null);
  const completeCourseRef = useRef<AnalyticsCompleteCourseRef>(null);
  const completeLessonRef = useRef<AnalyticsCompleteLessonRef>(null);
  const completeTopicRef = useRef<AnalyticsCompleteTopicRef>(null);
  const [, setSurveyAnswers] = useState<IAnswer[]>([]);
  const [loading, setLoading] = useState<number>(0);
  const size = useDeviceSize();
  // analytics

  useEffect(() => {
    dispatch(fetchStudentCourse(courseId));
    dispatch(fetchCategories());
  }, [courseId, dispatch]);

  useEffect(() => {
    if (!progress && lesson) {
      dispatch(
        makeProgressStudentCourse({
          courseId,
          lessonId,
          topicId,
          progress: 0,
        })
      );
    }
  }, [progress, dispatch, courseId, lessonId, lesson, topicId]);

  useEffect(() => {
    if (course && !lesson) {
      dispatch(setMessageError({ message: getTranslations('STUDENT_LESSON_NOT_AVAILABLE') }));
      history.push(`/course/${courseId}`);
    }
  }, [lesson, course, dispatch, history, courseId]);

  useEffect(() => {
    dispatch(fetchPrograms());
  }, [dispatch]);

  if (!lesson) {
    return <LoadingOverlay spinning={true} />;
  }
  let mediaItemsCount = lesson.media.length;
  const currentProgress = progress?.progress || 0;
  const onNextCarrousel = () => {
    refCarousel?.current?.next();
  };
  const onNext = (current: number) => {
    const progressToSend = mediaItemsCount ? current / mediaItemsCount : 0;
    if (progressToSend > currentProgress && progressToSend < 1) {
      dispatch(
        makeProgressStudentCourse({
          courseId,
          lessonId,
          topicId,
          progress: progressToSend,
        })
      );
    }
    if (mediaItemsCount === current) {
      setEnableNextButtonForCarrousel(true);
    }
  };
  const onPrev = () => {
    refCarousel?.current?.prev();
  };
  const mediaType = lesson.media[0].type;
  let media;
  let isDisabledNextLessonButton = true;

  switch (mediaType) {
    case MediaTypes.IMAGE:
      media = (
        <div className={styles.carouselContainer}>
          <div className={styles.navigator}>
            <span className={ctx(styles.button, styles.left)} onClick={onPrev}>
              <CogIcon
                className={styles.icon}
                color="white"
                icon="Arrow-left-big"
              />
            </span>
            <span
              className={ctx(styles.button, styles.right)}
              onClick={onNextCarrousel}
            >
              <CogIcon
                className={styles.icon}
                color="white"
                icon="Arrow-right-big"
              />
            </span>
          </div>
          <Carousel
            afterChange={async (current) => {
              onNext(current + 1);
            }}
            infinite={false}
            ref={refCarousel}
            className={styles.carousel}
            dots={false}
          >
            {lesson.media.map((item, index) => {
              return (
                <div key={index}>
                  <img
                    className={styles.image}
                    src={item.url}
                    alt={`Lesson ${index + 1}`}
                  />
                </div>
              );
            })}
          </Carousel>
        </div>
      );
      isDisabledNextLessonButton = !enableNextButtonForCarrousel;
      break;
    case MediaTypes.PDF:
      const isMobile = size === SIZES.PHONE || size === SIZES.TABLET;
      if (isMobile) {
        ++mediaItemsCount;
        media = (
          <div className={styles.pdfsContainer}>
            {lesson.media.map((item, index) => {
              const isDonwloadDisabled =
                currentProgress * mediaItemsCount < index;
              return (
                <div key={item._id} className={styles.pdf}>
                  <div className={styles.intruction1}>
                    {getTranslations('STUDENT_DOWNLOAD_FILE_TO_CONTINUE')}
                  </div>
                  <div className={styles.intruction2}>
                    {getTranslations('STUDENT_DOWNLOAD_FILE_TO_CONTINUE_2')}
                  </div>
                  <Button
                    onClick={() => {
                      const progressToSend = mediaItemsCount
                        ? (index + 1) / mediaItemsCount
                        : 0;
                      if (progressToSend > currentProgress) {
                        dispatch(
                          makeProgressStudentCourse({
                            courseId,
                            lessonId,
                            topicId,
                            progress: progressToSend,
                          })
                        );
                      }
                      window.open(item.url, "_blank");
                    }}
                    disabled={isDonwloadDisabled}
                    className={cls(styles.downloadButton, {
                      [styles.mobile]: size === SIZES.PHONE,
                    })}
                  >
                    <CogIcon
                      className={styles.attachIcon}
                      color="#686F84"
                      icon="Attach"
                    />{" "}
                    <span className={styles.text}>{item.filename} </span>
                    <CogIcon
                      className={styles.downloadIcon}
                      icon="Download"
                      color="#4376F9"
                    />
                  </Button>
                </div>
              );
            })}
          </div>
        );
        isDisabledNextLessonButton =
          currentProgress * mediaItemsCount < mediaItemsCount - 1;
        break;
      }
      media = (
        <div className={styles.pdfsContainer}>
          {lesson.media.map((item, index) => {
            const isDownloadDisabled =
              Math.round(currentProgress * mediaItemsCount) < index;
            const currentHasNotSeen =
              Math.round(currentProgress * mediaItemsCount) === index || (currentProgress === 0 && index === 1);
            if ((isDownloadDisabled || currentHasNotSeen) && index !== 0) {
              if (loading === index) {
                return <div key={lesson.media[index]._id} className={styles.pdf}>
                  <LoadingOverlay height={80} inline spinning />
                </div>;
              }
              return (
                <div key={lesson.media[index]._id} className={styles.pdf}>
                  <Button
                    disabled={!currentHasNotSeen}
                    onClick={() => {
                      const progressToSend = mediaItemsCount
                        ? (index + 1) / mediaItemsCount
                        : 0;
                      if (progressToSend > currentProgress) {
                        setLoading(index);
                        dispatch(
                          makeProgressStudentCourse({
                            courseId,
                            lessonId,
                            topicId,
                            progress: progressToSend,
                          })
                        );
                      }
                    }}
                    className={cls(styles.downloadButton)}
                  >
                    {getTranslations('STUDENT_VIEW_DOCUMENT')}&nbsp;&nbsp;&nbsp;<span className={styles.text}>{item.filename} </span>
                  </Button>
                </div>
              );
            }
            return <PDFViewer
              key={lesson.media[index]._id}
              url={lesson.media[index].url}
            />

          })}
        </div>
      );
      isDisabledNextLessonButton =
        currentProgress * mediaItemsCount < mediaItemsCount && mediaItemsCount !== 1;
      break;
    case MediaTypes.VIDEO:
      media = (
        <div>
          <VideoLesson
            courseId={courseId}
            onEndVideo={() => {
              setIsVideoEnded(true);
            }}
            lessonId={lesson._id ?? ""}
            evaluationId={lesson.evaluation}
            quizCuePoints={lesson.quizCuePoints || []}
            media={lesson.media[0]}
          />
        </div>
      );
      isDisabledNextLessonButton = !isVideoEnded;
      break;
    default:
      media = (
        <div className={styles.pdfsContainer}>
          NOT SUPPORTED YET {mediaType}
        </div>
      );
  }
  const buttonLabel = getButtonLabel(
    hasEvaluation,
    evaluationIsDone,
    nextLessonId.where,
    lesson?.media[0]?.type === MediaTypes.VIDEO && lesson?.media[0]?.videoType !== VideoTypes.YOUTUBE,
  );
  const goToMyCourses = () => {
    history.push(`/course/${courseId}/congratulations`);
  };

  const buttonIsDisable =
    isDisabledNextLessonButton || (hasEvaluation && showEvaluation);

  const mainCTA = (
    <div
      className={cls(styles.nextLessonContainer, {
        [styles.stickyCtaContainer]: size === SIZES.PHONE,
      })}
    >
      <Button
        title={
          buttonIsDisable && hasEvaluation
            ? getTranslations('STUDENT_COMPLETE_LESSON_TO_CONTINUE')
            : ""
        }
        className={cls({
          [styles.stickyCta]: size === SIZES.PHONE,
          [styles.big]: size !== SIZES.PHONE,
        })}
        onClick={() => {
          if (hasEvaluation && mediaType !== MediaTypes.VIDEO) {
            if (!showEvaluation && !evaluationIsDone) {
              setShowEvaluation(true);
              return;
            }
          }
          if (buttonLabel === label().COMPLETE_LESSON_AND_CONTINUE) {
            completeLessonRef?.current?.trigger();
            setEnableNextButtonForCarrousel(false);
            refCarousel?.current?.goTo(0);
          }
          let doNext = () => { };
          if (nextLessonId.where === TOPIC_TEST) {
            doNext = () => {
              selectTestRef?.current?.trigger();
              history.push(`/course/${courseId}/test/${topic?._id}`);
            };
          } else if (nextLessonId.where === COURSE_SURVEY) {
            doNext = () => {
              setShowSurvey(true);
            };
          } else if (nextLessonId.where === COURSE_EXAM) {
            doNext = () => {
              selectExamRef?.current?.trigger();
              history.push(`/course/${courseId}/exam`);
            };
          } else if (nextLessonId.where !== FINISH_COURSE) {
            doNext = () => {
              setIsVideoEnded(false);
              history.push(`/course/${courseId}/${nextLessonId.id}`);
            };
          } else {
            refCarousel?.current?.goTo(0);
            setEnableNextButtonForCarrousel(false);
            dispatch(
              makeProgressStudentCourse(
                {
                  courseId,
                  lessonId,
                  topicId,
                  progress: 1,
                },
                () =>
                  dispatch(
                    makeProgressStudentCourse(
                      {
                        courseId: courseId,
                        progress: 1,
                      },
                      () => {
                        completeCourseRef?.current?.trigger();
                        goToMyCourses();
                      }
                    )
                  )
              )
            );
            return;
          }
          const doLast = () => {
            if (currentProgress < 1) {
              refCarousel?.current?.goTo(0);
              setEnableNextButtonForCarrousel(false);
              dispatch(
                makeProgressStudentCourse(
                  {
                    courseId,
                    lessonId,
                    topicId,
                    progress: 1,
                  },
                  doNext
                )
              );
              return;
            }
            doNext();
          }
          if (!nextLessonId.samePreviousTopic) {
            dispatch(makeProgressStudentCourse(
              {
                courseId,
                topicId,
                progress: 1,
              }
              , () => {
                doLast();
                completeTopicRef?.current?.trigger();
              }
            ));
            return;
          }
          doLast();
        }}
        disabled={buttonIsDisable || showSurvey}
        type="primary"
      >
        {buttonLabel}
      </Button>
    </div>
  );

  const info = (
    <Info
      lesson={lesson}
      category={category}
      cta={size !== SIZES.PHONE ? mainCTA : null}
    />
  );
  const masthead = (
    <Masthead
      topic={topic ?? { _id: "", title: "" }}
      courseId={courseId}
      lesson={lesson}
      hasEvaluation={hasEvaluation}
      showEvaluation={showEvaluation}
      evaluationIsDone={evaluationIsDone}
      lessonId={lessonId}
      setEvaluationIsDone={setEvaluationIsDone}
      setShowEvaluation={setShowEvaluation}
      media={media}
    />
  );

  const navigationAndProgress = (
    <NavigationAndProgress
      topicId={topic?._id ?? ""}
      lessonId={lesson._id ?? ""}
    />
  );

  const mobileDrawerHeader = (
    <div className={"header-container"}>
      <div className={"inners"}>
        <Button
          type="default"
          onClick={() => {
            setIsDrawerVisible(false);
          }}
        >
          <CogIcon className={"el-style"} icon="Arrow-right-big" size="1em" />
          {getTranslations('STUDENT_CLOSE')}
        </Button>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <div className={styles.container}>
        {showSurvey ? (
          <Survey
            courseId={courseId}
            parentId={courseId}
            surveyId={course?.survey || ""}
            onSurveyComplete={() => {
              dispatch(
                makeProgressStudentCourse({
                  courseId: courseId,
                  progress: 1,
                })
              );
              goToMyCourses();
            }}
            setSurveyAnswers={setSurveyAnswers}
            parentType={ParentTypeEnum.CourseSurvey}
            onCancelSurvey={() => setShowSurvey(false)}
          />
        ) : null}
        {size === SIZES.DESKTOP ? (
          <StudentMainLayout>
            <TwoColumnLayout
              gap
              firstColumnSpan={18}
              firstColumnContent={
                <React.Fragment>
                  {masthead}
                  {info}
                </React.Fragment>
              }
              secondColumnContent={navigationAndProgress}
            />
          </StudentMainLayout>
        ) : null}
        {size === SIZES.TABLET ? (
          <StudentMainLayout>
            {masthead}
            <TwoColumnLayout
              gap
              firstColumnSpan={12}
              firstColumnContent={info}
              secondColumnContent={navigationAndProgress}
            />
          </StudentMainLayout>
        ) : null}
        {size === SIZES.PHONE ? (
          <React.Fragment>
            <div className={styles.viewLessonsMobile}>
              <div></div>
              <Button
                type="default"
                onClick={() => {
                  setIsDrawerVisible(true);
                }}
              >
                <CogIcon
                  className={"el-style"}
                  icon="Arrow-left-big"
                  size="1em"
                />
                {getTranslations('STUDENT_VIEW_LESSONS')}
              </Button>
            </div>
            <StudentMainLayout>
              {masthead}
              <TwoColumnLayout firstColumnSpan={24} firstColumnContent={info} />
              {mainCTA}
              <CogDrawer
                visible={isDrawerVisible}
                toggleVisible={() => { }}
                title={mobileDrawerHeader}
                footer={<div></div>}
                width={"100%"}
                headerStyle={{
                  borderBottom: 0,
                  paddingLeft: 0,
                  paddingRight: 0,
                }}
                closeIcon={null}
                noGutter
              >
                {navigationAndProgress}
              </CogDrawer>
            </StudentMainLayout>
          </React.Fragment>
        ) : null}
      </div>
      <AnalyticsSelectTest ref={selectTestRef} courseId={courseId} topicId={topic?._id || ""} />
      <AnalyticsSelectExam ref={selectExamRef} />
      <AnalyticsCompleteCourse ref={completeCourseRef} />
      <AnalyticsCompleteTopic ref={completeTopicRef} lessonId={lesson._id ?? ''} />
      <AnalyticsCompleteLesson
        ref={completeLessonRef}
        lessonId={lesson._id ?? ""}
      />
    </React.Fragment>
  );
};

export type AnalyticsSelectTestRef = {
  trigger: () => void;
};

interface AnalyticsSelectTestProps {
  topicId: string;
  courseId: string;
}

export const AnalyticsSelectTest = forwardRef<
  AnalyticsSelectTestRef,
  AnalyticsSelectTestProps
>(({ topicId, courseId }, ref) => {
  useImperativeHandle(ref, () => ({
    trigger: () => {
      analytics.selectTest({
        test: evaluation,
        topic: topic,
        course: studentCourse,
        program: program,
        company: company, //TODO: think company can be removed
        group: group || null,
      });
    },
  }));
  const dispatch = useDispatch();
  const topic = useSelector(selectStudentCourseTopic(topicId));
  const evaluation = useSelector(selectEvaluation(topic.evaluation || ''));
  const studentCourse = useSelector(selectStudentCourse);
  const cognitoUserData = useSelector(getCognitoUserData);
  const cognitoUsername = cognitoUserData
    ? cognitoUserData["cognito:username"]
    : null;
  const userFromStore = useSelector(selectUser(cognitoUsername));
  const companyId = userFromStore?.company ?? "";
  const testId = topic?.evaluation;
  const company = useSelector(selectCompany(companyId));
  const group =
    useSelector(selectGroup(userFromStore?.studentGroupId || "")) || null;
  const program = useSelector(selectProgram(company?.studyProgram || null));
  const studyProgram = company?.studyProgram;

  useEffect(() => {
    if (userFromStore) {
      dispatch(fetchCompany(userFromStore?.company ?? ""));
    }
  }, [userFromStore, dispatch]);

  useEffect(() => {
    if (!testId) {
      return;
    }
    dispatch(fetchEvaluation(testId, topicId, courseId));
  }, [dispatch, testId, topicId, courseId]);

  useEffect(() => {
    dispatch(fetchGroups());
  }, [dispatch]);

  useEffect(() => {
    if (studyProgram) {
      dispatch(fetchProgram(studyProgram));
    }
  }, [dispatch, studyProgram]);

  return null;
});

export type AnalyticsSelectExamRef = {
  trigger: () => void;
};

interface AnalyticsSelectExamProps { }

export const AnalyticsSelectExam = forwardRef<
  AnalyticsSelectExamRef,
  AnalyticsSelectExamProps
>((_, ref) => {
  useImperativeHandle(ref, () => ({
    trigger: () => {
      analytics.selectExam({
        exam: exam,
        course: studentCourse,
        program: program,
        company: company,
        group: group || null,
      });
    },
  }));
  const dispatch = useDispatch();
  const studentCourse = useSelector(selectStudentCourse);
  const cognitoUserData = useSelector(getCognitoUserData);
  const cognitoUsername = cognitoUserData
    ? cognitoUserData["cognito:username"]
    : null;
  const userFromStore = useSelector(selectUser(cognitoUsername));
  const companyId = userFromStore?.company ?? "";
  const examId = studentCourse?.evaluation;
  const exam = useSelector(selectEvaluationById(examId ?? ""));
  const company = useSelector(selectCompany(companyId));
  const group =
    useSelector(selectGroup(userFromStore?.studentGroupId || "")) || null;
  const program = useSelector(selectProgram(company?.studyProgram || null));
  const studyProgram = company?.studyProgram;

  useEffect(() => {
    if (userFromStore) {
      dispatch(fetchCompany(userFromStore?.company ?? ""));
    }
  }, [userFromStore, dispatch]);

  useEffect(() => {
    dispatch(fetchEvaluations());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchGroups());
  }, [dispatch]);

  useEffect(() => {
    if (studyProgram) {
      dispatch(fetchProgram(studyProgram));
    }
  }, [dispatch, studyProgram]);

  return null;
});

export type AnalyticsCompleteCourseRef = {
  trigger: () => void;
};

interface IAnalyticsCompleteCourse { }

export const AnalyticsCompleteCourse = forwardRef<
  AnalyticsCompleteCourseRef,
  IAnalyticsCompleteCourse
>((_, ref) => {
  useImperativeHandle(ref, () => ({
    trigger: () => {
      analytics.completeCourse({
        course: course,
        program: program,
        company: company,
        group: group,
      });
    },
  }));

  const dispatch = useDispatch();
  const course = useSelector(selectStudentCourse);
  const cognitoUserData = useSelector(getCognitoUserData);
  const cognitoUsername = cognitoUserData
    ? cognitoUserData["cognito:username"]
    : null;
  const userFromStore = useSelector(selectUser(cognitoUsername));
  const companyId = userFromStore?.company ?? "";
  const company = useSelector(selectCompany(companyId));
  const group =
    useSelector(selectGroup(userFromStore?.studentGroupId || "")) || null;
  const program = useSelector(selectProgram(company?.studyProgram || null));

  useEffect(() => {
    dispatch(fetchGroups());
  }, [dispatch]);

  return null;
});

export type AnalyticsCompleteQuizRef = {
  trigger: (topic: ITopic, quizAnswers: IAnswer[]) => void;
};

interface AnalyticsCompleteQuizProps {
  lessonId: string;
}

export const AnalyticsCompleteQuiz = forwardRef<
  AnalyticsCompleteQuizRef,
  AnalyticsCompleteQuizProps
>(({ lessonId }, ref) => {
  useImperativeHandle(ref, () => ({
    trigger: (topic: ITopic, quizAnswers: IAnswer[]) => {
      analytics.completeQuiz({
        quiz: evaluation,
        lesson: lesson,
        topic: topic,
        course: course,
        program: courseProgram,
        company: company,
        group: group,
        answers: quizAnswers,
      });
    },
  }));
  const lesson = useSelector(selectStudentCourseLesson(lessonId));
  const evaluation = useSelector(selectEvaluation(lesson.evaluation || ''));
  const course = useSelector(selectStudentCourse);
  const programsDict = useSelector(selectProgramsDict);
  const allProgramsIds = useSelector(selectPrograms);
  const programId = allProgramsIds.filter((programId) => {
    const program = programsDict[programId] || {};
    const { courses: programCourses = [] } = program;
    const foundCourse = programCourses.find(
      (courseId) => courseId === course?._id
    );
    if (foundCourse !== undefined) {
      return true;
    }
    return false;
  });
  const courseProgram = programsDict[programId[0]] || null;
  const cognitoUserData = useSelector(getCognitoUserData);
  const cognitoUsername = cognitoUserData
    ? cognitoUserData["cognito:username"]
    : null;
  const userFromStore = useSelector(selectUser(cognitoUsername));
  const company =
    useSelector(selectCompany(userFromStore?.company ?? "")) || null;
  const group =
    useSelector(selectGroup(userFromStore?.studentGroupId || "")) || null;

  return null;
});

export type AnalyticsCompleteLessonRef = {
  trigger: () => void;
};

interface AnalyticsCompleteLessonProps {
  lessonId: string;
}

export const AnalyticsCompleteLesson = forwardRef<
  AnalyticsCompleteLessonRef,
  AnalyticsCompleteLessonProps
>(({ lessonId }, ref) => {
  useImperativeHandle(ref, () => ({
    trigger: () => {
      analytics.completeLesson({
        lesson: lesson,
        topic: topic,
        course: course,
        program: courseProgram,
        company: company,
        group: group,
      });
    },
  }));
  const lesson = useSelector(selectStudentCourseLesson(lessonId));
  const topic = useSelector(selectStudentCourseTopicLesson(lessonId));
  const course = useSelector(selectStudentCourse);
  const programsDict = useSelector(selectProgramsDict);
  const allProgramsIds = useSelector(selectPrograms);
  const programId = allProgramsIds.filter((programId) => {
    const program = programsDict[programId] || {};
    const { courses: programCourses = [] } = program;
    const foundCourse = programCourses.find(
      (courseId) => courseId === course?._id
    );
    if (foundCourse !== undefined) {
      return true;
    }
    return false;
  });
  const courseProgram = programsDict[programId[0]] || null;
  const cognitoUserData = useSelector(getCognitoUserData);
  const cognitoUsername = cognitoUserData
    ? cognitoUserData["cognito:username"]
    : null;
  const userFromStore = useSelector(selectUser(cognitoUsername));
  const company =
    useSelector(selectCompany(userFromStore?.company ?? "")) || null;
  const group =
    useSelector(selectGroup(userFromStore?.studentGroupId || "")) || null;

  return null;
});


export type AnalyticsCompleteTopicRef = {
  trigger: () => void;
};

interface AnalyticsCompleteTopicProps {
  lessonId: string;
}

export const AnalyticsCompleteTopic = forwardRef<
  AnalyticsCompleteTopicRef,
  AnalyticsCompleteTopicProps
>(({ lessonId }, ref) => {
  useImperativeHandle(ref, () => ({
    trigger: () => {
      analytics.completeTopic({
        topic: topic,
        course: course,
        program: courseProgram,
        company: company,
        group: group,
      });
    },
  }));
  const topic = useSelector(selectStudentCourseTopicLesson(lessonId));
  const course = useSelector(selectStudentCourse);
  const programsDict = useSelector(selectProgramsDict);
  const allProgramsIds = useSelector(selectPrograms);
  const programId = allProgramsIds.filter((programId) => {
    const program = programsDict[programId] || {};
    const { courses: programCourses = [] } = program;
    const foundCourse = programCourses.find(
      (courseId) => courseId === course?._id
    );
    if (foundCourse !== undefined) {
      return true;
    }
    return false;
  });
  const courseProgram = programsDict[programId[0]] || null;
  const cognitoUserData = useSelector(getCognitoUserData);
  const cognitoUsername = cognitoUserData
    ? cognitoUserData["cognito:username"]
    : null;
  const userFromStore = useSelector(selectUser(cognitoUsername));
  const company =
    useSelector(selectCompany(userFromStore?.company ?? "")) || null;
  const group =
    useSelector(selectGroup(userFromStore?.studentGroupId || "")) || null;

  return null;
});
