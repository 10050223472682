import React from "react";
import { useSelector } from "react-redux";
import { CampaignSurveyTaken } from "..";
import { getCognitoUserData } from "../../../../features/auth/authSlice";
import { selectCompanies } from "../../../../features/company/companySlice";
import { UserRole } from "../../../../features/users/usersSlice";
import LoadingOverlay from "../../../common/LoadingOverlay";
import { BarChartSimple } from "../../common/BarChartSimple";
import { TopFiveAward } from "../../common/TopFiveAward";
import { fillWithEmptyDataIfNeeded } from "../../common/utils";

import styles from "./index.module.scss";
import { getTranslations } from "../../../../features/translations/translationsUtils";

interface Props {
  campaignsSurveyTakenPercentage: CampaignSurveyTaken[];
  loading: boolean;
}

export const CampaignsTop5: React.FunctionComponent<Props> = ({
  campaignsSurveyTakenPercentage,
  loading,
}) => {
  const companyDict = useSelector(selectCompanies);
  const cognitoData = useSelector(getCognitoUserData);
  const role = cognitoData ? cognitoData["custom:role"] : undefined;
  const userCompanyId = cognitoData ? cognitoData["custom:company"] : undefined;

  const campaignsPerCompany = campaignsSurveyTakenPercentage.reduce(
    (
      list: Record<
        string,
        { campaignId: string; surveyTakenPercentage: number }[]
      >,
      campaign
    ) => {
      list[campaign.companyId] = list[campaign.companyId] || [];
      list[campaign.companyId].push({
        campaignId: campaign.name,
        surveyTakenPercentage: campaign.percentage,
      });
      return list;
    },
    {}
  );

  const companiesWithAverageCampaignSurveyTaken = Object.keys(
    campaignsPerCompany
  ).map((id) => ({
    companyId: id,
    averageSurveyTaken:
      campaignsPerCompany[id].length > 0
        ? Number(
            (
              campaignsPerCompany[id].reduce(
                (total, campaign) => (total += campaign.surveyTakenPercentage),
                0
              ) / campaignsPerCompany[id].length
            ).toFixed(2)
          )
        : 0,
  }));

  const data = companiesWithAverageCampaignSurveyTaken
    .filter((company) => company.averageSurveyTaken > 0)
    .sort((a, b) => b.averageSurveyTaken - a.averageSurveyTaken)
    .slice(0, 5)
    .map((item) => ({
      key: item.companyId,
      label: companyDict[item.companyId]?.name,
      value: item.averageSurveyTaken,
    }));

  const isInTop5 =
    data.find((item) => item.key === userCompanyId) !== undefined;

  const top5 = fillWithEmptyDataIfNeeded(data, 5);

  return (
    <>
      {role === UserRole.SuperAdmin && (
        <>
          <div className={styles.title}>
            {getTranslations('REPORT_TOP_5_COMPANY_WITH_TAKEN_SURVEYS')}
          </div>
          {loading ? (
            <LoadingOverlay inline height={400} spinning />
          ) : (
            <BarChartSimple
              data={top5}
              height={400}
              labelColumnTitle=""
              fetching={false}
              xMaxValue={100}
              xTickCallBack={(value: number) => (value + '%')}
              labelCallback={(tooltipItem: any, data: any) => {
                const dataset = data.datasets[tooltipItem.datasetIndex];
                return `${(dataset.data[tooltipItem.index].x).toFixed(2)}%`;
              }}
            />
          )}
        </>
      )}
      {role !== UserRole.SuperAdmin && (
        <>
          {loading ? (
            <LoadingOverlay inline height={400} spinning />
          ) : (
            <TopFiveAward
              isInTop5={isInTop5}
              tooltipText={getTranslations('REPORT_TOP_10_COMPANY_WITH_TAKEN_SURVEYS_INFO')}
            />
          )}
        </>
      )}
    </>
  );
};
