import React, { FunctionComponent } from "react";

import { useSelector } from "react-redux";
import cls from 'classnames';

import { CourseBlock } from "./CourseBlock";

import styles from './course.module.scss';
import { useDeviceSize } from "../../../../utils/useDeviceSize";
import { SIZES } from "../../../../common/screen";

interface IMyCoursesProps { 
  selector: any,
  individualSelector: any,
  emptyMessage: string,
}


export const Courses: FunctionComponent<IMyCoursesProps> = ({
  emptyMessage,
  individualSelector,
  selector
}) => {
  const coursesKeys: number[] = useSelector(selector);
  const size = useDeviceSize();
  const isMobile = size === SIZES.PHONE;

  const renderCourses = () => {
    return coursesKeys.map((courseIndex) => {
      return <CourseBlock
        key={courseIndex}
        courseKey={courseIndex}
        selector={individualSelector}
      />
    });
  }
  const renderEmpty = () => {
    return <div>{emptyMessage}</div>
  }
  return <div className={cls(styles.container, {
    [styles.mobile]: isMobile,
  })}>
    {coursesKeys.length === 0 ? renderEmpty() : renderCourses()}
  </div>
}