import React from 'react';
import ctx from 'classnames';

import { Input as AntInput, Row, Col, Button } from 'antd';
import { SearchProps } from 'antd/lib/input';

import styles from './index.module.scss';
import './tableTools.scss';
import { SearchOutlined } from '@ant-design/icons';
import { getTranslations } from '../../../features/translations/translationsUtils';

export interface TableToolsProps extends SearchProps {
  onEnable?: React.MouseEventHandler<HTMLElement>;
  onDisable?: React.MouseEventHandler<HTMLElement>;
  loading?: boolean;
  filter?: React.ReactNode
  activationBtnsVisible: boolean,
  enableLabel?: string,
  disableLabel?: string,
}

export const TableTools: React.FunctionComponent<TableToolsProps> = (props) => {
  const {
    activationBtnsVisible,
    filter,
    className,
    onEnable,
    onDisable,
    enableLabel=getTranslations('LIST_ENABLE'),
    disableLabel=getTranslations('LIST_DISABLE'),
    ...rest
  } = props;
  return (
    <Row align='middle' gutter={[0, 16]}>
      <Col span={8}>
        <AntInput prefix={<SearchOutlined className='search-icon' />} className={ctx(className, styles.search, styles.wrapper, 'custom-search')} {...rest} />
      </Col>
      { filter && <Col span={3}>
        {filter}
      </Col>}
      {
        activationBtnsVisible && onEnable &&
        <Col span={2} style={{ paddingBottom: 17 }}>
          <Button size='large' type='link' onClick={onEnable}>{enableLabel}</Button>
        </Col>
      }
      {
        activationBtnsVisible && onDisable &&
        <Col span={2} style={{ paddingBottom: 17 }}>
          <Button size='large' type='link' onClick={onDisable}>{disableLabel}</Button>
        </Col>
      }
    </Row>
  );
}