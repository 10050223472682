import React, { FunctionComponent } from "react";

import { Button } from "antd";
import cls from "classnames";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  selectStudentHomeCoursesIndexes,
} from "../../../../features/students/homeCoursesSlice";
import { SIZES } from "../../../common/screen";
import { useDeviceSize } from "../../../utils/useDeviceSize";
import { CourseBlock } from "../CourseBlock";

import styles from "./coursesBlock.module.scss";
import { getTranslations } from "../../../../features/translations/translationsUtils";

interface StudentRouterProps { }

export const CoursesBlock: FunctionComponent<StudentRouterProps> = () => {
  const history = useHistory();
  const size = useDeviceSize();
  const coursesKeys = useSelector(selectStudentHomeCoursesIndexes);
  const coursesChildren = coursesKeys.map((courseKey) => {
    return <CourseBlock key={courseKey} courseKey={courseKey} />;
  });
  if (coursesKeys.length === 0) {
    return null;
  }

  const renderForMobile = () => {
    return <div className={styles.horizontalContainer}>
      {coursesChildren}
    </div>;
  };
  const renderForNonMobile = () => {
    return <div
      className={cls(
        styles.innerContainer,
        "courses-block-carousel-container"
      )}
    >
      {coursesChildren}
    </div>;
  };
  const isMobile = size === SIZES.PHONE;
  return (
    <div className={cls(styles.container, {
      [styles.mobile]: isMobile,
    })}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{getTranslations('HOME_PENDING_COURSES')}</div>
        <div className={styles.linkContainer}>
          <Button
            className="withBorder clientSize"
            type="link"
            size="large"
            onClick={() => {
              history.push('/myCourses');
            }}
          >
            {getTranslations('HOME_VIEW_ALL_PENDING_COURSES')}
          </Button>
        </div>
      </div>
      {isMobile ? renderForMobile() : renderForNonMobile()}
    </div>
  );
};
