import { buildShortIds, IWithShortId } from './../common/shortId';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../app/AllowedMicroservices";
import { AppThunk, RootState } from "../../app/store";
import { StatusEnum } from "../../components/common/Status";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { MODAL_DELAY_MS } from "../../utils/constants";
import { fetchCategories, ICategory } from "../category/categorySlice";
import { FetchStatus, ModalStatus } from "../common/enums";
import { setMessageError, setMessageInfo } from "../message/messageSlice";
import { fetchTeachers } from "../teacher/teacherSlice";
import { ITeacher } from "./../teacher/teacherSlice";
import { getTranslations } from '../translations/translationsUtils';

export enum MediaTypes {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  PDF = "PDF",
}
export enum VideoTypes {
  FILE = "FILE",
  YOUTUBE = "YOUTUBE",
}

export const MediaTitles = () => ({
  [MediaTypes.IMAGE]: getTranslations('LESSON_IMAGES'),
  [MediaTypes.VIDEO]: getTranslations('LESSON_VIDEO'),
  [MediaTypes.PDF]: getTranslations('LESSON_DOCUMENTS'),
});

export interface IVideoInfo {
  duration: number;
  height: number;
  width: number;
}

export interface IMedia {
  _id?: string;
  type: MediaTypes;
  url: string;
  filename: string;
  size: number;
  videoInfo?: IVideoInfo;
  videoType?: VideoTypes;
}

export interface IQuizCuePoint {
  questionId: string;
  time: number;
}

export interface ILesson extends IWithShortId {
  _id?: string;
  title: string;
  category: ICategory | string;
  description: string;
  tags: string[];
  media: IMedia[];
  createdAt?: string;
  categoryName?: string;
  teacherName?: string;
  teacher?: string | ITeacher;
  status?: StatusEnum;
  evaluation?: string;
  quizCuePoints?: IQuizCuePoint[];
  companyId?: string;
  companyName?: string;
}
export interface ILessonDict {
  [_id: string]: ILesson;
}
interface LessonState {
  list: ILesson[];
  dict: ILessonDict;
  status: FetchStatus;
  modalStatus: ModalStatus;
}

const initialState: LessonState = {
  list: [],
  dict: {},
  status: FetchStatus.Idle,
  modalStatus: ModalStatus.Hide,
};

interface LessonFetch {
  docs: ILesson[];
  limit: number;
  page: number;
  pages: number;
  total: number;
}

interface LessonUpdateStatusData {
  ids: string[];
}
export const lessonsSlice = createSlice({
  name: "lessons",
  initialState,
  reducers: {
    creating: (state) => {
      state.status = FetchStatus.Creating;
    },
    fetching: (state) => {
      state.list = [];
      state.dict = {};
      state.status = FetchStatus.Fetching;
    },
    fetched: (state, action: PayloadAction<LessonFetch>) => {
      state.list = buildShortIds(action.payload.docs) as ILesson[];
      state.dict = {};
      state.list.forEach((lesson) => {
        state.dict[lesson?._id ?? ""] = lesson;
      });
      state.status = FetchStatus.Fetched;
    },
    showModal: (state) => {
      state.modalStatus = ModalStatus.Show;
    },
    hideModal: (state) => {
      state.modalStatus = ModalStatus.Hide;
    },
    error: (state, _action: PayloadAction<LessonFetch>) => {
      state.list = [];
      state.dict = {};
      state.status = FetchStatus.Error;
    },
  },
});

export const { hideModal } = lessonsSlice.actions;
const { fetching, fetched, error, creating, showModal } = lessonsSlice.actions;
const BASE_ENDPOINT = "/lessons";

export const fetchLessons = () => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_ENDPOINT}?limit=1000000`,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
    })
  );
};

export const createLesson = (newLesson: ILesson) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: BASE_ENDPOINT,
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: fetchTeachers,
          },
          {
            actionOrCreator: fetchCategories,
          },
          {
            actionOrCreator: fetchLessons,
          },
          {
            actionOrCreator: showModal,
            selector: () => {
              dispatch(hideModalWithDelay());
            },
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
            selector: (_response: any) => {
              const message = `Error desconocido`;
              dispatch(setMessageError({ message }));
            },
          },
        ],
      },
      method: HttpMethods.POST,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
      data: newLesson,
    })
  );
};

export const updateLesson = (lesson: ILesson) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_ENDPOINT}/${lesson._id}`,
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: fetchTeachers,
          },
          {
            actionOrCreator: fetchCategories,
          },
          {
            actionOrCreator: fetchLessons,
          },
          {
            actionOrCreator: showModal,
            selector: () => {
              dispatch(hideModalWithDelay());
            },
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
            selector: (_response: any) => {
              const message = `Error desconocido`;
              dispatch(setMessageError({ message }));
            },
          },
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
      data: lesson,
    })
  );
};

export const enableLessonsStatus = (lessons: LessonUpdateStatusData) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_ENDPOINT}/enable`,
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: fetchLessons,
          },
          {
            actionOrCreator: setMessageInfo({
              message: getTranslations('LESSON_ENABLED_LESSONS_MESSAGE'),
            }),
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
            selector: (response: any) => {
              if (
                response.data &&
                response.data.statusCode === 409 &&
                response.data.data === "CanBeEnabled"
              ) {
                const message = getTranslations('LESSON_ENABLED_LESSONS_MESSAGE_FAILED_2');
                dispatch(setMessageError({ message }));
                return;
              }
              dispatch(setMessageError({ message: getTranslations('LESSON_ENABLED_LESSONS_MESSAGE_FAILED') }));
            },
          },
          {
            actionOrCreator: fetchLessons,
          },
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
      data: lessons,
    })
  );
};

export const disableLessonsStatus = (lessons: LessonUpdateStatusData) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_ENDPOINT}/disable`,
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: fetchLessons,
          },
          {
            actionOrCreator: setMessageInfo({
              message: getTranslations('LESSON_DISABLED_LESSONS_MESSAGE'),
            }),
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
          {
            actionOrCreator: fetchLessons,
          },
          {
            actionOrCreator: setMessageError({
              message: getTranslations('LESSON_DISABLED_LESSONS_MESSAGE_FAILED'),
            }),
          },
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
      data: lessons,
    })
  );
};

const hideModalWithDelay = (): AppThunk => async (dispatch) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      dispatch(hideModal());
      resolve();
    }, MODAL_DELAY_MS);
  });
};

export const selectLessons = (state: RootState) => state.lessons.list;
export const selectLessonsStatus = (state: RootState) => {
  const status: {
    [id:string]: StatusEnum
  } = {};
  state.lessons.list.forEach((lesson) => {
    status[lesson._id || ''] = lesson.status || StatusEnum.Disable;
  });
  return status;
};
export const selectLessonsDict = (state: RootState) => state.lessons.dict;
export const selectLesson = (id: string) => (state: RootState) =>
  state.lessons.list.find((lesson) => lesson._id === id);
export const selectAreLessonsFetching = (state: RootState) =>
  state.lessons.status === FetchStatus.Fetching;
export const selectIsCreatingLesson = (state: RootState) =>
  state.lessons.status === FetchStatus.Creating;
export const selectDisplayModal = (state: RootState) =>
  state.lessons.modalStatus === ModalStatus.Show;

export default lessonsSlice.reducer;
