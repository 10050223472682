import React, { FunctionComponent } from 'react';

import { useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import cls from 'classnames';

import { selectKnowledgeBase } from '../../../../../features/students/knowledgeBaseSlice';
import { CogIcon } from '../../../../common/CogIcon';
import { selectContentCategoriesDict } from '../../../../../features/contentCategory/contentCategorySlice';
import { useDeviceSize } from '../../../../utils/useDeviceSize';
import { SIZES } from '../../../../common/screen';

import styles from './content.module.scss';

import contentIcon from '../../../../../assets/content.png';

interface IContentProps {
  contentId: string;
}

export const Content: FunctionComponent<IContentProps> = ({
  contentId
}) => {
  const history = useHistory();
  const content = useSelector(selectKnowledgeBase(contentId));
  const contentCategoryDict = useSelector(selectContentCategoriesDict);
  const size = useDeviceSize();
  const isMobile = size === SIZES.PHONE;

  const publishDate = moment(content.createdAt).format('DD/MM/YYYY');
  return <div className={cls(styles.container, {
    [styles.mobile]: isMobile
  })} onClick={() => history.push(`/knowledgeBases/${content._id}`)}>
    <div className={styles.icon}>
      <img src={contentIcon} alt="Contenido" />
    </div>
    <div className={styles.content}>
      <div className={styles.categories}>
        <div className={styles.category}>
          {contentCategoryDict[content.category]?.name}
        </div>
      </div>
      <div className={styles.title}>
        {content.title}
      </div>
      <div className={styles.publishDate}>
        <CogIcon color='#81858B' size={16} icon='clock' /> {publishDate}
      </div>
    </div>
  </div>
};