import React, { useEffect } from 'react';
import { Select, Form } from 'antd';
import { useSelector } from 'react-redux';

import { IGroup } from '../../../features/groups/groupSlice';
import { IUser } from '../../../features/users/usersSlice';
import {
    selectCompanies,
    ICompany,
    selectCompaniesIds
} from '../../../features/company/companySlice';
import { getTranslations } from '../../../features/translations/translationsUtils';

interface CompanyFieldProps {
    newObject?: IGroup | IUser,
    value?: string | ICompany,
    setNewObject: Function,
    resetForm: (companyId: string) => void,
}

export const CompanyField: React.FunctionComponent<CompanyFieldProps> = (props) => {
    const { value, setNewObject, newObject } = props;
    const idsList = useSelector(selectCompaniesIds);
    const list = useSelector(selectCompanies);
    useEffect(() => {
        if (idsList.length === 1 && !newObject?.company) {
            setNewObject({ ...newObject, company: idsList[0] });
            props.resetForm(idsList[0] );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idsList]);
    return <Form.Item
        initialValue={newObject?.company}
        name="company"
        wrapperCol={{ sm: 24 }}
        label={getTranslations('GENERAL_COMPANY')}
        rules={[{ required: true, message: 'Requerido' }]}
    >
        <Select
            placeholder={getTranslations('GENERAL_SELECT_COMPANY')}
            value={value?.toString()}
            style={{ width: '100%' }}
            onChange={(value) => {
                setNewObject({ ...newObject, company: value })
            }}>
            {idsList.map(id => {
                return <Select.Option key={id} value={id}>{list[id].name}</Select.Option>
            })}
        </Select>
    </Form.Item>
};