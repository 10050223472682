import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectTranslationsAdminTranslation, setTranslatedValue, translateItem } from '../../features/translationsAdmin/translationsAdminSlice';

import styles from './translationsItem.module.scss';
import { Button, Input } from 'antd';
import { getTranslations } from '../../features/translations/translationsUtils';

interface ITranslationPaneProps {
  itemKey: string;
  selectedLanguage: string;
}

export const TranslationItem: React.FunctionComponent<ITranslationPaneProps> = ({ itemKey, selectedLanguage }) => {
  const dispatch = useDispatch();
  const translation = useSelector(selectTranslationsAdminTranslation(itemKey));

  return <div className={styles.container}>
    <div className={styles.key}>{itemKey}</div>
    <div className={styles.es}>{translation.esText}</div>
    <div className={styles.other}><Input
      onChange={(event) => {
        const value = event.target.value;
        dispatch(setTranslatedValue({
          key: itemKey,
          text: value,
        }));
      }}
      value={translation.otherText}
    /></div>
    {selectedLanguage !== 'es' ? <div>
      <Button type='default' onClick={() => {
        dispatch(translateItem(itemKey, selectedLanguage));
      }} >
        {getTranslations('TRANSLATIONS_TRANSLATE')}
      </Button>
    </div> : null}
  </div>;
};