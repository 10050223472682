import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import cls from 'classnames';
import { Form, Button, Input } from 'antd';

import { Header } from './Header';
import { Footer } from './Footer';
import {
  errorMessage,
  loginToCognito,
  newPasswordRequired,
  loggedIn,
  forgotPassword
} from '../../features/auth/authSlice';
import { setMessageError } from '../../features/message/messageSlice';
import { ContainerWithCarousel } from './ContainerWithCarousel';
import LoadingOverlay from '../common/LoadingOverlay';
import { getTranslations } from '../../features/translations/translationsUtils';

import './LoginOverrides.scss';
import styles from './Login.module.scss';
import { LanguageSelector } from './LanguageSelector';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export function Login() {
  const [key, setKey] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const dispatch = useDispatch();
  const history = useHistory();
  const newPassword = useSelector(newPasswordRequired);
  const isLoggedIn = useSelector(loggedIn);
  const loginErrorMessage = useSelector(errorMessage);

  useEffect(
    () => {
      if (newPassword) {
        history.push('/finishRegistration');
      }
    }, [history, isLoggedIn, newPassword]
  );
  if (isLoggedIn) {
    history.push('/welcome');
    return <LoadingOverlay spinning />;
  }

  return (
    <React.Fragment>
      <div className={key} />
      {key === '' ? <LoadingOverlay spinning /> :
        <ContainerWithCarousel>
          <Form
            {...layout}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={(values: { email: string, password: string }) => {
              const { email, password } = values;
              dispatch(loginToCognito(email, password, history as any));
            }}
            onFinishFailed={() => { dispatch(setMessageError({ message: 'Hubo un error' })) }}
          >
            <div className={cls(styles.formInnerContainer, styles.overload)}>
              <div className={styles.topPart}>
                <Header title={getTranslations('LOGIN_WELCOME')}>
                  <div>{getTranslations('LOGIN_INTRO_FIRST_LINE')}</div>
                  <div>{getTranslations('LOGIN_INTRO_SECOND_LINE')}</div>
                </Header>
                <Form.Item
                  label={getTranslations('LOGIN_EMAIL')}
                  name="email"
                  rules={[{
                    required: true,
                    type: "email",
                    message: getTranslations('LOGIN_EMAIL_ERROR')
                  }
                  ]}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Input placeholder={getTranslations('LOGIN_EMAIL_PLACEHOLDER')} onChange={(event) => {
                    setEmail(event.target.value)
                  }} />
                </Form.Item>
                <Form.Item
                  label={getTranslations('LOGIN_PASSWORD')}
                  name="password"
                  rules={[{ required: true, message: getTranslations('LOGIN_PASSWORD_ERROR') }]}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Input.Password />
                </Form.Item>
                <div className={cls('error', styles.error)}>{loginErrorMessage}</div>
                <div className={styles.buttons}>
                  <Button
                    loading={false}
                    disabled={false}
                    type="link"
                    key="changePassword"
                    className={styles.forgotPassword}
                    onClick={() => {
                      if (email === '') {
                        dispatch(setMessageError({ message: getTranslations('LOGIN_FORGOT_PASSWORD_EMAIL_ERROR') }));
                      }
                      dispatch(forgotPassword(email));
                    }}
                  >
                    {getTranslations('LOGIN_FORGOT_PASSWORD_BUTTON')}
                  </Button>
                  <Button
                    type='primary'
                    htmlType='submit'
                    disabled={false}
                    className={cls(styles.loginBtn)}
                  >
                    {getTranslations('LOGIN_LOGIN_BUTTON')}
                  </Button>

                </div>
              </div>
              <Footer title={getTranslations('LOGIN_DONT_HAVE_ACCOUNT_BUTTON')} linkLabel={getTranslations('LOGIN_SIGNUP_BUTTON')} linkRef={'/signup'} />
            </div>
          </Form>
        </ContainerWithCarousel>
      }
      <LanguageSelector loading={key === ''} setKey={(value: string) => {
        setKey(value);
      }} />
    </React.Fragment>
  );
}
