import React, { FunctionComponent } from 'react';

import cls from 'classnames';

import { CogIcon } from '../../../../common/CogIcon';
import { useDeviceSize } from '../../../../utils/useDeviceSize';
import { SIZES } from '../../../../common/screen';

import styles from './content.module.scss';

import { ICompanyLink } from '../../../../../features/company/companySlice';

interface IContentProps {
  link: ICompanyLink;
}

const openInNewTab = (url: string) => {
  window.open(url, "_blank", "noreferrer");
};

export const Content: FunctionComponent<IContentProps> = ({
  link
}) => {
  const size = useDeviceSize();
  const isMobile = size === SIZES.PHONE;

  return <div className={cls(styles.container, {
    [styles.mobile]: isMobile
  })} onClick={() => openInNewTab(link.url)}>
    <div className={styles.icon}>
      <div className={styles.iconContainer}>
        <CogIcon color="#81858B" icon="report" size={20} />
      </div>
    </div>
    <div className={styles.content}>
      <div className={styles.title}>
        {link.title}
      </div>
      <div className={styles.description}>
        {link.description}
      </div>
      <div className={styles.link}>
        <div className={styles.text}>{link.buttonText}</div>
        <div className={styles.iconContainer}>
          <CogIcon size={20} color="var(--tertiary-text-button-color)" className={styles.icon} icon="Arrow-down" />
        </div>
      </div>
    </div>
  </div>
};