import { Button, Form, Card, Col, Row } from "antd";
import cls from "classnames";
import React, { Dispatch, FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { getCognitoUserData } from "../../features/auth/authSlice";
import {
  fetchCompanies,
  fetchCompany,
  ICompany,
  selectCompany,
} from "../../features/company/companySlice";
import { fetchGroups, IGroup, selectGroup } from "../../features/groups/groupSlice";
import {
  disableUsersStatus,
  enableUsersStatus,
  fetchUser,
  hideModal,
  IUser,
  RolesDict,
  selectDisplayModal,
  selectUser,
  updateUser,
  UserRole,
} from "../../features/users/usersSlice";
import { DialogPopUp } from "../common/DialogPopUp";
import { CogDrawer } from "../common/Drawer";
import { ProfileLayout } from "../common/Layouts/ProfileLyt";
import { Output } from "../common/Output";
import { Status, StatusEnum } from "../common/Status";
import { extractDate } from "../utils/convertions";
import styles from "./shared.module.scss";
import { Headlines, ProfileDetailsColumn } from "./UserDetailsColumn";
import { getTranslations } from "../../features/translations/translationsUtils";

interface ProfileProps { }

interface RenderOptionsProps {
  toggleVisible: any;
  username: string;
  dispatch: Dispatch<any>;
  user: IUser;
  currentlySeenBy: string;
  goToPrint(): void;
}

const renderOptions = ({
  toggleVisible,
  username,
  dispatch,
  user,
  currentlySeenBy,
  goToPrint,
}: RenderOptionsProps) => {
  return (
    <Row style={{ justifyContent: "flex-end" }} >
      {currentlySeenBy === user.username ? null : (
        <Button
          type="link"
          onClick={() => {
            if (user.enabled) {
              dispatch(
                disableUsersStatus(true, { usernames: [user.username] })
              );
            } else {
              dispatch(enableUsersStatus(true, { usernames: [user.username] }));
            }
          }}
        >
          {user.enabled ? "Deshabilitar" : "Habilitar"}
        </Button>
      )}
      <Button
        onClick={() => {
          dispatch(fetchUser(username));
          toggleVisible();
        }}
      >
        {getTranslations('LIST_EDIT')}
      </Button>
      <Button
        onClick={() => goToPrint()}
        className={cls(styles.downloadBtn)}
        type="primary"
      >
        Descargar ficha del perfil
      </Button>
    </Row>
  );
};

const renderLeftColumn = (user: IUser, company: ICompany) => {
  return (
    <ProfileDetailsColumn
      headlinesType={Headlines.Admin}
      user={user}
      company={company}
    />
  );
};

const renderRightColumn = (user: IUser, group?: IGroup) => {

  const groupName = group?.name ?? '--';
  return <Row gutter={[16, 40]}>
    <Col span={24}>
      <Card className={cls(styles.card, styles.overload)}>
        <h2 className={cls(styles.title)}>Rol</h2>
        <Output
          label={"Rol"}
          text={
            user?.role && RolesDict[user?.role]
              ? RolesDict[user?.role]
              : "--"
          }
        />
        {
          user.role === UserRole.Supervisor ?
            <Output
              label={"Grupo de supervisión"}
              text={groupName}
            /> :
            null
        }

        <Output label={"Estatus"} text={""}>
          <div style={{ paddingTop: '1rem' }}>
            <Status
              status={
                user.enabled ? StatusEnum.Enable : StatusEnum.Disable
              }
            ></Status>
          </div>
        </Output>
      </Card>
    </Col>
  </Row>;
};

interface RouteParams {
  id: string;
}

const studentFormId = "userForm";

export const Profile: FunctionComponent<ProfileProps> = () => {
  const cognitoUserData = useSelector(getCognitoUserData);
  const [visible, setVisible] = useState<boolean>(false);
  const toggleVisible = () => setVisible(!visible);
  const [user, setUser] = useState<IUser>();
  const [currentUser, setCurrentUser] = useState<IUser>();
  const [company, setCompany] = useState<ICompany>();
  const dispatch = useDispatch();
  const { id } = useParams<RouteParams>();
  const isModalVisible = useSelector(selectDisplayModal);
  const history = useHistory();
  const group = useSelector(selectGroup(user?.groupId || ''));

  const userFromStore = useSelector(selectUser(id));
  useEffect(() => {
    if (userFromStore) {
      setUser(userFromStore);
      setCurrentUser(userFromStore);
      if (userFromStore?.company) {
        dispatch(fetchCompany(userFromStore?.company ?? ""));
      }
    }
  }, [userFromStore, dispatch]);

  const companyFromStore = useSelector(
    selectCompany(userFromStore?.company ?? "")
  );
  useEffect(() => {
    if (companyFromStore) {
      setCompany(companyFromStore);
    }
  }, [companyFromStore]);

  useEffect(() => {
    dispatch(fetchUser(id));
    dispatch(fetchGroups());
    dispatch(fetchCompanies());
  }, [dispatch, id]);

  const goToPrint = () => {
    history.push(`/admin-users/${id}/print`);
  };

  const [form] = Form.useForm();

  const cognitoUsername = cognitoUserData
    ? cognitoUserData["cognito:username"]
    : null;
  return (
    <ProfileLayout
      image={currentUser?.picture}
      rightTopOptions={renderOptions({
        toggleVisible,
        username: currentUser?.username || "",
        dispatch,
        user: Object.assign({}, currentUser),
        currentlySeenBy: cognitoUsername,
        goToPrint,

      })}
      leftBottomOptions={renderLeftColumn(
        Object.assign({}, currentUser),
        Object.assign({}, company)
      )}
      rightBottomOptions={renderRightColumn(Object.assign({}, currentUser), group)}
    >
      <DialogPopUp
        name="Usuario administrativo"
        action="actualizado"
        visible={isModalVisible}
        onCancel={() => {
          dispatch(hideModal());
        }}
      />
      <CogDrawer
        visible={visible}
        toggleVisible={toggleVisible}
        title="Editar"
        footer={
          <div>
            <Button onClick={toggleVisible}>Cancelar</Button>&nbsp;&nbsp;
            <Button
              type="primary"
              htmlType="submit"
              form={studentFormId}
              onClick={() => {
                form
                  .validateFields()
                  .then((value) => {
                    const userToSend = { ...user } as IUser;
                    if (!userToSend) {
                      return;
                    }
                    delete userToSend.companyName;
                    delete userToSend.status;
                    userToSend.birthdate = extractDate(userToSend.birthdate);
                    dispatch(updateUser(userToSend));
                    dispatch(fetchUser(id));
                  })
                  .catch((error) => {
                    console.error(
                      "Company:React.FunctionComponent -> error",
                      error
                    );
                  });
              }}
            >
              Actualizar
            </Button>
          </div>
        }
      >
        <div>
          {/* {!visible ? null : (
            <UserForm
              form={form}
              formId={studentFormId}
              user={user}
              setUser={setUser}
              adminOptions={true}
              isCreating={false}
            />
          )} */}
        </div>
      </CogDrawer>
    </ProfileLayout>
  );
};
