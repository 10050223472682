import React, { ReactText, useEffect, useState } from "react";

import { Button, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import { RangeValue } from "rc-picker/es/interface";
import { useDispatch, useSelector } from "react-redux";
import * as H from 'history';

import { getCognitoUserData, selectCompanyId, selectUserRole } from "../../features/auth/authSlice";
import {
  fetchCategories,
  selectCategoriesDict,
} from "../../features/category/categorySlice";
import { FetchStatus } from "../../features/common/enums";
import { selectCompanies } from "../../features/company/companySlice";
import { fetchLessons } from "../../features/lesson/lessonSlice";
import {
  disableTopicStatus,
  enableTopicStatus,
  fetchTopics,
  ITopic,
  selectTopics,
  selectTopicsFetchStatus,
  selectTopicsIds,
} from "../../features/topic/topicSlice";
import { UserRole } from "../../features/users/usersSlice";
import { PAGE_SIZE } from "../../utils/constants";
import { getDateFilterAndSortProps } from "../common/DateFilter";
import { MainLayout } from "../common/Layouts/Main";
import { Status } from "../common/Status";
import { TableTools } from "../common/TableTools";
import { CompanyFilter } from "../Filters/Company";
import { useDebounce } from "../utils/useDebounce";

import tableStyles from "../common/table.module.scss";
import { ActionSuffixes, Prefixes } from "../prefixes";
import { useHistory } from "react-router-dom";
import { getTranslations } from "../../features/translations/translationsUtils";

function renderOptions(
  history: H.History,
) {
  return (
    <Button
      size="large"
      onClick={() => {
        history.push(`${Prefixes.topic}${ActionSuffixes.new}`);
      }}
      type="primary"
    >
      {getTranslations('TOPIC_NEW_SHORT')}
    </Button>
  );
}

export const Topics: React.FunctionComponent = () => {
  const history = useHistory();
  const categoriesDict = useSelector(selectCategoriesDict);
  const userRole = useSelector(selectUserRole);
  const companyId = useSelector(selectCompanyId);
  const companiesList = useSelector(selectCompanies);
  const [dateFilterValues, setDateFilterValues] = useState<
    RangeValue<moment.Moment>
  >();
  const isTopicsFetching =
    useSelector(selectTopicsFetchStatus) === FetchStatus.Fetching;
  const isTopicCreating =
    useSelector(selectTopicsFetchStatus) === FetchStatus.Creating;
  const topicIdsList = useSelector(selectTopicsIds);
  const topicDict = useSelector(selectTopics);
  const list = topicIdsList.filter(topicId => {
    if (userRole === UserRole.SuperAdmin) {
      return true;
    }
    return companyId === topicDict[topicId].companyId;
  }).map((topicId) => ({
    ...topicDict[topicId],
    categoryName: categoriesDict[topicDict[topicId].category as string]?.name,
    companyName: topicDict[topicId].companyId ? companiesList[topicDict[topicId].companyId || '']?.name : getTranslations('COMPANY_ALL'),
  }));
  const [selectedRowKeys, selectRowKeys] = useState<ReactText[]>([]);
  const dispatch = useDispatch();
  const [filterValue, setFilterValue] = useState<ReactText[]>([]);
  const [filteredInfo, setFilteredInfo] = useState<ReactText[]>([]);
  const debounceFilterValue = useDebounce(filterValue, 500);
  const [showActivationBtns, setShowActivationBtns] = useState<boolean>(false);
  const cognitoData = useSelector(getCognitoUserData);
  const role = cognitoData ? cognitoData["custom:role"] : undefined;

  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      setShowActivationBtns(true);
    } else {
      setShowActivationBtns(false);
    }
  }, [selectedRowKeys]);

  const columns: ColumnsType<ITopic> = [
    {
      title: getTranslations('LIST_NAME'),
      dataIndex: "title",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      sorter: (a: ITopic, b: ITopic) => a.title.localeCompare(b.title),
      filteredValue: debounceFilterValue,
      onFilter: (value, record) => {
        const categoryName = (record.categoryName ?? "")
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase();
        const normalizedValue = value
          .toString()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase();
        return (
          record.title.toLowerCase().includes(value.toString().toLowerCase()) ||
          record._id.toLowerCase().includes(value.toString().toLowerCase()) ||
          categoryName.includes(normalizedValue)
        );
      },
      ellipsis: true,
    },
    {
      title: getTranslations('LIST_CATEGORY'),
      dataIndex: "categoryName",
      sortDirections: ["descend", "ascend"],
      filteredValue: filteredInfo,
      sorter: (a: ITopic, b: ITopic) => {
        const nameA = a.categoryName ?? "";
        const nameB = b.categoryName ?? "";
        return nameA.localeCompare(nameB);
      },
    },
    {
      title: getTranslations('LIST_STATUS'),
      dataIndex: "status",
      render: (enabled) => {
        return <Status status={enabled} />;
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a: ITopic, b: ITopic) =>
        a.status === b.status ? 0 : a.status > b.status ? 1 : -1,
    },
    {
      title: getTranslations('LIST_DATE'),
      dataIndex: "createdAt",
      sortDirections: ["descend", "ascend"],
      ...getDateFilterAndSortProps<ITopic>(
        dateFilterValues,
        setDateFilterValues
      ),
      render: (date, topic) => {
        return (
          <React.Fragment>
            {moment(date).format("DD/MM/YYYY")}
          </React.Fragment>
        );
      },
    },
    {
      title: getTranslations('LIST_COMPANY'),
      dataIndex: "companyName",
      sortDirections: ["descend", "ascend"],
      filteredValue: filteredInfo,
      ellipsis: true,
      onFilter: (value, record: ITopic) => record.companyId === (value as string),
      sorter: (a: ITopic, b: ITopic) =>
        (a.companyName || "").localeCompare(b.companyName || ""),
      render: (company, topic) => {
        return (
          <React.Fragment>
            {company}
            <div className={tableStyles.actions}>
              <Button
                type="default"
                onClick={() => {
                  history.push(`${Prefixes.topic}/${topic._id}${ActionSuffixes.edit}`);
                }}
              >
                {getTranslations('LIST_EDIT')}
              </Button>
            </div>
          </React.Fragment>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchTopics());
    dispatch(fetchCategories());
    dispatch(fetchLessons()); // make form ready to work
  }, [dispatch]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: ReactText[]) => {
      selectRowKeys(selectedRowKeys);
    },
  };

  const enableStatus = () => {
    const data = {
      ids: selectedRowKeys.map((key) => key.toString()),
    };
    dispatch(enableTopicStatus(data));
  };

  const disableStatus = () => {
    const data = {
      ids: selectedRowKeys.map((key) => key.toString()),
    };
    dispatch(disableTopicStatus(data));
  };
  return <MainLayout
    title={getTranslations('TOPIC_TITLE')}
    rightTopOptions={renderOptions(history)}
    breadcrumbs={[getTranslations('TOPIC_TITLE')]}
  >
    <TableTools
      placeholder={getTranslations('LIST_SEARCH_IN_TEURONA')}
      onSearch={(value) => setFilterValue([value])}
      onChange={(event) => setFilterValue([event.target.value])}
      onEnable={() => enableStatus()}
      onDisable={() => disableStatus()}
      filter={
        role === UserRole.SuperAdmin ? (
          <CompanyFilter onApply={setFilteredInfo} />
        ) : (
          undefined
        )
      }
      activationBtnsVisible={showActivationBtns}
    />
    <Table<ITopic>
      loading={isTopicsFetching || isTopicCreating}
      className={tableStyles.table}
      rowClassName={() => tableStyles.row}
      rowKey="_id"
      rowSelection={rowSelection}
      columns={columns}
      dataSource={list}
      pagination={{ position: ["bottomRight"], pageSize: PAGE_SIZE }}
    />
  </MainLayout>;
};
