import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Vimeo from "@u-wave/react-vimeo";
import {
  checkVideoStatus,
  selectVideoStatus,
  selectVideoStatusInfo,
  VideoStatus
} from "../../features/lesson/videoStatus";
import { IVideoInfo } from "../../features/lesson/lessonSlice";
import { Alert, Spin } from "antd";

interface VideoPreviewProps {
  videoUrl: string;
  isReady(videoInfo: IVideoInfo): void;
}

export const VideoPreview: React.FunctionComponent<VideoPreviewProps> = ({
  videoUrl,
  isReady
}) => {
  let playerInstance: any;
  const video: string = videoUrl.split("/").pop() || '';
  const dispatch = useDispatch();
  const videoStatus = useSelector(selectVideoStatus);
  const videoInfo = useSelector(selectVideoStatusInfo);

  useEffect(() => {
    dispatch(checkVideoStatus(videoUrl));
  }, [dispatch, videoUrl]);
  useEffect(() => {
    if (
      ![
        VideoStatus.available,
        VideoStatus.checking,
        VideoStatus.error
      ].includes(videoStatus)
    ) {
      setTimeout(() => {
        dispatch(checkVideoStatus(videoUrl));
      }, 30000);
      return;
    }
    if (videoStatus === VideoStatus.available) {
      isReady({
        duration: videoInfo?.duration || 0,
        height: videoInfo?.height || 0,
        width: videoInfo?.width || 0
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoStatus, videoUrl]);
  if (
    [VideoStatus.transcodeStarting, VideoStatus.transcoding].includes(
      videoStatus
    )
  ) {
    return (
      <Alert
        message={
          <span>
            El video está siendo procesado,
            <br />
            Debe esperar unos minutos a que se termine de procesar para poder
            utilizarlo para vincular el quiz. Puede guardar el curso y regresar
            mas tarde.
          </span>
        }
        type="info"
      />
    );
  }
  if (videoStatus !== VideoStatus.available) {
    return <Spin />;
  }
  return (
    <Vimeo
      video={video}
      style={{ width: '100%' }}
      onReady={player => {
        player.addCuePoint(3, {
          text: "YEIII"
        });
        playerInstance = player;
      }}
      onCuePoint={a => {
        playerInstance.pause();
      }}
    />
  );
};
