import React, { FunctionComponent } from 'react';
import { Layout } from 'antd';
import {
    Router,
    Switch,
    Route,
  } from "react-router-dom";
import * as H from 'history';

import ScrollToTop from '../../components/common/ScrollToTop';
import Certificate from '../../components/Certificates';
import { Translator } from '../../components/Translator';

const { Content } = Layout;

interface PublicRouterProps {
    history: H.History<any>;
}

export const PublicRouter: FunctionComponent<PublicRouterProps> = ({history}) => {
    return <Router history={history}>
    <ScrollToTop />
    <Layout>
      <Content className="site-layout">
        <Switch>
          <Route path="/public/certificate/:hash">
            <Translator>
              <Certificate />
            </Translator>
          </Route>
          <Route path="/">
            <div/>
          </Route>
        </Switch>
      </Content>
    </Layout>
  </Router>
};



