import React from "react";
import { useSelector } from "react-redux";
import {
  selectCasesByCompanyTopFiveData,
  selectTopFiveIsFetching,
} from "../../../../features/reports/case/ticketsByCompanyTopFive";
import LoadingOverlay from "../../../common/LoadingOverlay";
import { BarChartSimple } from "../../common/BarChartSimple";
import { fillWithEmptyDataIfNeeded } from "../../common/utils";
import styles from "./index.module.scss";
import { getTranslations } from "../../../../features/translations/translationsUtils";

export const CasesTop5: React.FunctionComponent = () => {
  const isLoading = useSelector(selectTopFiveIsFetching);
  const data = useSelector(selectCasesByCompanyTopFiveData);
  const userTypeData = fillWithEmptyDataIfNeeded(data, 5);

  return (
    <>
      <div className={styles.title}>
        {getTranslations('REPORT_TOP_5_COMPANIES_REGISTERED_CASES')}
      </div>
      {isLoading ? (
        <LoadingOverlay inline height={400} spinning />
      ) : (
        <BarChartSimple
          data={userTypeData}
          labelColumnTitle=" "
          fetching={false}
          titleCallback={()=>{
              return null;
            }
          }
          xTickCallBack={(value: number) => (`${value}`)}
          labelCallback={(tooltipItem: any, data: any) => {
            const dataset = data.datasets[tooltipItem.datasetIndex];
            return `${dataset.data[tooltipItem.index].x}`;
          }}
        />
      )}
    </>
  );
};
