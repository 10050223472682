import { Dispatch } from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Microservices } from "../../app/AllowedMicroservices";
import { RootState } from "../../app/store";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { FetchStatus } from "../common/enums";

interface IText {
  key: string;
  text: string;
}
interface ITtranslationItem {
  esText: string;
  otherText: string;
};

interface TranslationsState {
  status: FetchStatus;
  language: string;
  languageTo: string;
  texts: Record<string, ITtranslationItem>; 
  keys: Record<string, string[]>;
  translating: boolean;
  changed: boolean;
}

const initialState: TranslationsState = {
  status: FetchStatus.Idle,
  language: 'es',
  languageTo: '',
  texts: {},
  keys: {},
  translating: false,
  changed: false,
};

export const translationsAdminSlice = createSlice({
  name: "translationsAdmin",
  initialState,
  reducers: {
    translating: (state) => {
      state.translating = true;
    },
    fetching: (state, action: PayloadAction<string>) => {
      state.status = FetchStatus.Fetching;
      state.language = action.payload;
      state.keys = {};
      state.texts = {};
      state.changed = false;
    },
    fetchES: (state, action: PayloadAction<IText[]>) => {
      action.payload.forEach(({ key, text }) => {
        if (!key) {
          return;
        }
        const initialPart = key.split('_')[0];
        if (!state.keys[initialPart]) {
          state.keys[initialPart] = [];
        }
        state.texts[key] = {
          esText: text,
          otherText: '',
        };
        state.keys[initialPart].push(key);
        Object.values(state.keys).forEach(keys => {
          keys.sort();
        })
      });
    },
    fetchOther: (state, action: PayloadAction<IText[]>) => {
      state.status = FetchStatus.Fetched;
      state.translating = false;
      action.payload.forEach(({ key, text }) => {
        if (!key) {
          return;
        }
        if (state.texts[key]) {
          state.texts[key].otherText = text;
        }
      });
      state.changed = false;
    },
    error: (state) => {
      state.status = FetchStatus.Error;
    },
    translate: (state, action: PayloadAction<{key: string, text: string}>) => {
      const { key, text } = action.payload;
      state.texts[key].otherText = text;
      state.translating = false;
      state.changed = true;
    },
    translateAll: (state, action: PayloadAction) => {
    },
    setTranslatedValue: (state, action: PayloadAction<{text: string; key: string}>) => {
      const { key, text } = action.payload;
      state.texts[key].otherText = text;
      state.changed = true;
    }
  },
});

const BASE_ENDPOINT = "/translations";
export const { fetchES, fetching, error, fetchOther, translate, translateAll, translating, setTranslatedValue } = translationsAdminSlice.actions;

export const translateAllItems = (language: string) => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_ENDPOINT}/translateAll`,
      types: {
        requestType:  translating,
        successTypes: [
          {
            actionOrCreator: translateAll,
          },
          {
            actionOrCreator: () => ({ type: 'DO_NOTHING', payload: undefined }),
            selector: () => {
              dispatch(fetchTranslationsAdminOther(language));
            }
          },
        ],
        errorTypes: [
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.COMPANY_SERVICE,
      authenticated: true,
      data: {
        translateToLanguage: language,
      }
    })
  );
};

export const saveAllItems = (language: string) => (dispatch: Dispatch<object>, getState: () => RootState) => {
  const texts = getState().translationsAdmin.texts;
  const translations: IText[] = [];
  Object.keys(texts).forEach((key) => {
    const text = texts[key].otherText;
    translations.push({
      key,
      text,
    });
  });
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_ENDPOINT}/save`,
      types: {
        requestType:  translating,
        successTypes: [
          {
            actionOrCreator: translateAll,
          },
          {
            actionOrCreator: () => ({ type: 'DO_NOTHING', payload: undefined }),
            selector: () => {
              dispatch(fetchTranslationsAdminOther(language));
            }
          },
        ],
        errorTypes: [
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.COMPANY_SERVICE,
      authenticated: true,
      data: {
        translateToLanguage: language,
        translations,
      }
    })
  );
};

export const translateItem = (key: string, language: string) => (dispatch: Dispatch<object>, getState: any) => {
  const text = getState().translationsAdmin.texts[key].esText;
  
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_ENDPOINT}/translate`,
      types: {
        requestType:  translating,
        successTypes: [
          {
            actionOrCreator: () => ({ type: 'DO_NOTHING', payload: undefined }),
            selector: (response) => {
              dispatch(translate({
                key,
                text: response as any,
              }));
            }
          },
        ],
        errorTypes: [
        ],
      },
      method: HttpMethods.POST,
      microservice: Microservices.COMPANY_SERVICE,
      authenticated: true,
      data: {
        originalText: text,
        translateToLanguage: language,
      }
    })
  );
};

const fetchTranslationsAdminOther = (language: string) => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_ENDPOINT}/public/${language}`,
      types: {
        requestType: () => ({ type: 'DO_NOTHING', payload: undefined }),
        successTypes: [
          {
            actionOrCreator: fetchOther,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.COMPANY_SERVICE,
      authenticated: true,
    })
  );
};

export const fetchTranslationsAdmin = (language: string) => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_ENDPOINT}/public/es`,
      types: {
        requestType: fetching(language),
        successTypes: [
          {
            actionOrCreator: fetchES,
          },{
            actionOrCreator: ()=> {},
            selector: () => {
              dispatch(fetchTranslationsAdminOther(language));
            }
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.COMPANY_SERVICE,
      authenticated: true,
    })
  );
};

export const selectTranslationsAdminIsFetching = (state: RootState) =>
  state.translationsAdmin.status === FetchStatus.Fetching;

export const selectTranslationsAdminIsTranslating = (state: RootState) =>
  state.translationsAdmin.translating;

export const selectTranslationsAdminFetchedLanguage = (state: RootState) =>
  state.translationsAdmin.language;

export const selectTranslationsAdminIsDirty = (state: RootState) =>
  state.translationsAdmin.changed;

export const selectTranslationsAdminKeys = (state: RootState) =>
  Object.keys(state.translationsAdmin.keys);

export const selectTranslationsAdminTranslations = (key: string) => (state: RootState) =>
  state.translationsAdmin.keys[key];

export const selectTranslationsAdminTranslation = (key: string) => (state: RootState) =>
  state.translationsAdmin.texts[key];

export default translationsAdminSlice.reducer;
