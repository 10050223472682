import React, { FunctionComponent } from "react";

import cls from 'classnames';

import styles from './time.module.scss';

import timeIcon from '../../../assets/infographic/time.png';
import { selectUserSessionInfo } from "../../../features/students/sessionInfoSlice";
import { useSelector } from "react-redux";
import { getTranslations } from "../../../features/translations/translationsUtils";

interface ITimeProps {
  isMobile: boolean;
}

export const Time: FunctionComponent<ITimeProps> = ({
  isMobile
}) => {
  const sessionInfo = useSelector(selectUserSessionInfo);

  const time = Math.round(sessionInfo.time ?? 0);
  const hours = Math.trunc(time / 60);
  const minutes = time - hours * 60;
  const timeValue = `${`${hours}`.padStart(2, '0')}:${`${minutes}`.padStart(2, '0')}`;

  return <div className={cls(styles.container, { [styles.mobile]: isMobile })}>
    <div className={styles.title}>{getTranslations('STUDENT_PROFILE_TIME')}</div>
    <div className={styles.innerContainer}>
      <div className={styles.icon}>
        <img src={timeIcon} alt={"golden hourglass"} />
      </div>
      <div className={styles.timeAndDescription}>
        <div className={styles.time}>{timeValue}</div>
        <div className={styles.description}>{getTranslations('STUDENT_PROFILE_TRAINING_HOURS')}</div>
      </div>
    </div>
  </div>;
};


