import React, { FunctionComponent, useEffect, useState } from "react";

import Vimeo from "@u-wave/react-vimeo";
import { VimeoCuePointData } from "@vimeo/player";
import ReactPlayer from 'react-player'
import cls from 'classnames';

import { QuestionsAnswers } from "../../../features/evaluation/common/interfaces";
import { IMedia, IQuizCuePoint, VideoTypes } from "../../../features/lesson/lessonSlice";
import { SIZES } from '../../common/screen';
import { useDeviceSize } from '../../utils/useDeviceSize';
import { Quiz } from "../Evaluation/Quiz";

import styles from "./video.module.scss";

interface VideoLessonProps {
  courseId: string;
  lessonId: string;
  media: IMedia;
  quizCuePoints: IQuizCuePoint[];
  evaluationId?: string;
  onEndVideo(): void;
}
let playerInstance: any | null = null;

interface IVisitedMarks {
  [_i: string]: boolean;
}

declare global {
  interface Document {
    mozCancelFullScreen?: () => Promise<void>;
    msExitFullscreen?: () => Promise<void>;
    webkitExitFullscreen?: () => Promise<void>;
    onwebkitfullscreenchange?: () => void;
    mozFullScreenElement?: Element;
    msFullscreenElement?: Element;
    webkitFullscreenElement?: Element;
    onmozfullscreenchange?: () => void;
    onmsfullscreenchange?: () => void;
  }

  interface HTMLElement {
    msRequestFullscreen?: () => Promise<void>;
    mozRequestFullscreen?: () => Promise<void>;
    webkitRequestFullscreen?: () => Promise<void>;
    onwebkitfullscreenchange?: () => void;
    onmozfullscreenchange?: () => void;
    onmsfullscreenchange?: () => void;
  }
}

export const VideoLesson: FunctionComponent<VideoLessonProps> = ({
  courseId,
  media,
  quizCuePoints = [],
  lessonId,
  evaluationId,
  onEndVideo,
}) => {
  const size = useDeviceSize();
  const [answersSelected, setAnswerSelected] = useState<QuestionsAnswers>({});
  const [showQuiz, setShowQuiz] = useState<boolean>(false);
  const [visitedMarks, setVisitedMarks] = useState<IVisitedMarks>({});
  const [playerIsSet, setPlayerIsSet] = useState<boolean>(false);
  const { url, videoType } = media;
  const isYoutubeVideo = videoType === VideoTypes.YOUTUBE;
  const video: string = isYoutubeVideo ? url : url.split("/").pop() || "";
  useEffect(() => {
    if (playerInstance && playerIsSet) {
      quizCuePoints.forEach((quizCuePoint, index) => {
        playerInstance?.addCuePoint(quizCuePoint.time, {
          questionId: quizCuePoint.questionId,
          time: quizCuePoint.time,
        });
      });
      playerInstance.on("fullscreenchange", (data: any) => {
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerIsSet]);

  const isFullscreenEnabled = () => {
    return (
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement
    );
  };
  const toggleFullscreen = function () {
    let fullscreenChange: any = null;
    // other vars …

    // Check for fullscreen support
    if (isFullscreenEnabled()) {
      // If there's currently an element fullscreen, exit fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }

      // Do stuff when the video exits fullscreen mode
      // …
    } else {
      const videoWrapper: any = document.getElementById("videoWrapper");
      // Otherwise, enter fullscreen
      // `videoWrapper` is just a `div` element wrapping the video
      if (videoWrapper?.requestFullscreen) {
        videoWrapper.requestFullscreen();
      } else if (videoWrapper?.mozRequestFullScreen) {
        videoWrapper?.mozRequestFullScreen();
      } else if (videoWrapper?.webkitRequestFullscreen) {
        videoWrapper.webkitRequestFullscreen();
      } else if (videoWrapper?.msRequestFullscreen) {
        videoWrapper.msRequestFullscreen();
      }

      // Do stuff when the video enters fullscreen mode
      // …
    }

    fullscreenChange = function () {
      // Do something on fullscreen change event
      // …
    };

    document.onfullscreenchange = function () {
      if (!document.fullscreenElement) {
        fullscreenChange();
      }
    };
    document.onwebkitfullscreenchange = function () {
      if (!document.webkitFullscreenElement) {
        fullscreenChange();
      }
    };
    document.onmozfullscreenchange = function () {
      if (!document.mozFullScreenElement) {
        fullscreenChange();
      }
    };
    document.onmsfullscreenchange = function () {
      if (!document.msFullscreenElement) {
        fullscreenChange();
      }
    };
  };
  let videoPlayer = null;
  let seekBLocker = null;
  if (!isYoutubeVideo) {
    videoPlayer = <Vimeo
      className={styles.vimeo}
      controls
      video={video}
      width={"100vw"}
      responsive
      height={444}
      onPlay={() => {
        //   setIsPlaying(true);
      }}
      onPause={() => {
        //   setIsPlaying(false);
      }}
      onEnd={() => {
        //   setIsPlaying(false);
        //   setProgress(MAX);
        let isEvaluationComplete = !evaluationId;
        quizCuePoints.forEach(
          (cuePoint) =>
            (isEvaluationComplete = visitedMarks[cuePoint.questionId])
        );

        if (isEvaluationComplete) {
          onEndVideo();
        }
      }}
      onReady={(player) => {
        playerInstance = player;
        setPlayerIsSet(true);
      }}
      onCuePoint={(point) => {
        if (playerInstance) {
          const { data }: VimeoCuePointData = point;
          if (!visitedMarks[data.questionId]) {
            playerInstance.pause();
            if (isFullscreenEnabled()) {
              toggleFullscreen();
            }
            const newVisitedMarks: IVisitedMarks = { ...visitedMarks };
            newVisitedMarks[data.questionId] = true;
            setVisitedMarks(newVisitedMarks);
            setShowQuiz(true);
          }
        }
      }}
      onTimeUpdate={(event) => {
        //   setProgress(Math.floor((event.seconds / duration) * MAX));
      }}
    />;
    seekBLocker = <div className={cls(styles.seekBlocker, { [styles.mobile]: size === SIZES.PHONE })} />;
  } else {
    seekBLocker = <div className={cls(styles.seekBlocker, styles.youtube, { [styles.mobile]: size === SIZES.PHONE })} />;
    videoPlayer = <ReactPlayer
      width="100%"
      height={size === SIZES.PHONE ? "50vw" : "30vw"}
      onEnded={() => {
        onEndVideo();
      }}
      url={video}
      controls={false}
      onProgress={(state) => {

      }}
      config={{
        youtube: {
          playerVars: {
            controls: 1,
            disablekb: 1,
            modestbranding: 1,
            rel: 0,
            loop: 0,
            autoplay: 0,

          }
        },
      }}
    />;
  }
  return (
    <div id="videoWrapper" className={styles.container}>
      {evaluationId && showQuiz ? (
        <Quiz
          courseId={courseId}
          lessonId={lessonId}
          className={styles.quizContainer}
          quizId={evaluationId || ""}
          onEvaluationComplete={() => { }}
          onNextQuestion={() => {
            setShowQuiz(false);
            playerInstance?.play();
          }}
          answers={answersSelected}
          questionToShow={Object.keys(answersSelected).length}
          onChangeAnswer={(answers) => {
            setAnswerSelected(answers);
          }}
          showOnlyOneQuestion
        />
      ) : null}
      {videoPlayer}
      {seekBLocker}

    </div>
  );
};
