import React, { useEffect, useState } from "react";

import { Form, Input, Spin, Row, Col, Tabs, Alert } from "antd";
import { FormInstance } from "antd/lib/form";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchCourses,
  selectAreCoursesFetching,
  selectCourses,
  selectCoursesDict,
} from "../../features/courses/coursesSlice";
import {
  createProgram,
  ICourseRules,
  IProgram,
  selectAreProgramsFetching,
  selectProgram,
  updateProgram,
  selectDisplayModal,
  hideModal,
} from "../../features/program/programSlice";
import { fetchTopics, selectTopics } from "../../features/topic/topicSlice";
import LoadingOverlay from "../common/LoadingOverlay";
import { StatusEnum } from "../common/Status";
import { MIN_3_CHAR } from "../Users/Form";
import { CourseField, CoursesSelected } from "./CoursesSelected";
import styles from "./form.module.scss";
import formStyles from "../common/form.module.scss";
import { FormFooter } from "../common/FormFooter";
import { useHistory } from "react-router-dom";
import { ActionSuffixes, Prefixes } from "../prefixes";
import { AfterActions, DialogPopUp } from "../common/DialogPopUp";
import { selectCompanies } from "../../features/company/companySlice";
import { fetchCategories } from "../../features/category/categorySlice";
import { getTranslations } from "../../features/translations/translationsUtils";

export const PRIORITIES = [
  {
    value: 1,
    label: 1,
  },
  {
    value: 2,
    label: 2,
  },
  {
    value: 3,
    label: 3,
  },
];

export const DATE_FORMAT = "YYYY-MM-DD";

interface Step1Props {
  program?: IProgram;
  setProgram: Function;
  validateFields?: Function;
}

interface Step2Props {
  program?: IProgram;
  setProgram: Function;
  validateFields?: Function;
  coursesField: CourseField;
}

const FirstStep: React.FC<Step1Props> = ({
  program,
  setProgram,
}) => {
  return (
    <div className={formStyles.section}>
      <div className={formStyles.sectionTitle}>{getTranslations('PROGRAM_BASIC_INFO')}</div>
      <Form.Item
        name="title"
        label={getTranslations('PROGRAM_FIELD_TITLE')}
        rules={[
          { type: "string", required: true, message: getTranslations('GENERAL_REQUIRED') },
          {
            type: "string",
            max: 50,
            message: getTranslations('PROGRAM_FIELD_TITLE_ERROR_1'),
          },
          { type: "string", min: 3, message: MIN_3_CHAR() },
        ]}
      >
        <Input
          onChange={(event) => {
            setProgram({ ...program, title: event.target.value });
          }}
        />
      </Form.Item>
    </div>
  );
};

const SecondStep: React.FC<Step2Props> = ({
  program,
  setProgram,
  coursesField,
}) => {
  const isTeuronaCourses = coursesField === 'teuronaCourses';
  const dispatch = useDispatch();
  const topics = useSelector(selectTopics);
  const courseIdsList = useSelector(selectCourses);
  const coursesDict = useSelector(selectCoursesDict);
  const companies = useSelector(selectCompanies);

  const areCoursesFetching = useSelector(selectAreCoursesFetching);

  const selectedCourses = program?.[coursesField] ? program[coursesField] : [];

  useEffect(() => { }, [program]);

  useEffect(() => {
    dispatch(fetchTopics());
  }, [dispatch]);
  let companyId: string | undefined;
  if (!isTeuronaCourses) {
    const programBelongToCompany = Object.values(companies).find((company) => {
      return program?._id && company.studyProgram === program?._id;
    });
    if (!programBelongToCompany) {
      return <Alert
        message={getTranslations('PROGRAM_OWN_COURSES_ERROR_1')}
        type="warning"
      />;
    }
    companyId = programBelongToCompany._id;
  }

  const coursesEnabledList = courseIdsList
    .map((courseId) => coursesDict[courseId])
    .filter(
      (course) =>
        course.status !== StatusEnum.Disable &&
        course.topics?.find((item) => topics[item]?.lessons.length > 0)
    ).filter((course) => {
      if (isTeuronaCourses) {
        return !course.companyId; // teurona courses
      }
      return course.companyId === companyId;
    });

  return (
    <Spin spinning={areCoursesFetching}>
      <CoursesSelected
        selectedCourses={selectedCourses}
        setProgram={setProgram}
        program={program}
        coursesList={coursesEnabledList}
        coursesField={coursesField}
      />
    </Spin>
  );
};

interface ProgramFromProps {
  form: FormInstance;
  programBeingEdited: string | null;
  isCreate: boolean;
}

interface ICourseRulesWithDateRange extends ICourseRules {
  dateRange: any;
  endDate?: Date;
}
interface IProgramExtended extends IProgram {
  coursesRules2: ICourseRulesWithDateRange[];
  teuronaCoursesRules2: ICourseRulesWithDateRange[];
}

const getNewRules = (currentRules: ICourseRules[]) => {
  return currentRules.map((rule) => ({
    ...rule,
    startDate: moment(rule.startDate, DATE_FORMAT),
    dateRange: [
      moment(rule.startDate, DATE_FORMAT),
      rule.endDate ? moment(rule.endDate, DATE_FORMAT) : null,
    ],
  }));
}
export const ProgramForm: React.FC<ProgramFromProps> = ({
  form,
  programBeingEdited,
  isCreate,
}) => {
  const [afterAction, setAfterAction] = useState('');
  const [restart, setRestart] = useState(true);
  const dispatch = useDispatch();
  const isModalVisible = useSelector(selectDisplayModal);
  const [program, setProgram] = useState<IProgram | IProgramExtended>();
  const areProgramsFetching = useSelector(selectAreProgramsFetching);
  const areCoursesFetching = useSelector(selectAreCoursesFetching);
  const programToEdit = useSelector(selectProgram(programBeingEdited));
  const [tab, setTab] = useState('teuronaCourses');
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  useEffect(() => {
    if (restart) {
      form.resetFields();
      form.setFieldsValue({});
      setRestart(false);
    }
  }, [restart, form]);

  useEffect(() => {
    if (programToEdit) {
      const newRules = getNewRules([...programToEdit.coursesRules]);
      const teuronaNewRules = getNewRules([...programToEdit.teuronaCoursesRules]);
      setProgram({ ...programToEdit, coursesRules2: [...newRules], teuronaCoursesRules2: [...teuronaNewRules] });
    }
  }, [programToEdit]);

  useEffect(() => {
    dispatch(fetchCourses());
  }, [dispatch]);

  if (areProgramsFetching || areCoursesFetching) {
    return <LoadingOverlay spinning={true} />;
  }

  const validateFields = () => {
    return form.validateFields();
  };
  const doAfterAction = () => {
    if (afterAction === AfterActions.BACK) {
      history.push(`${Prefixes.program}`);
      return;
    }
    if (isCreate) {
      setTimeout(() => {
        form.resetFields();
        form.setFieldsValue({});
        setProgram({} as IProgram);
        setRestart(true);
      }, 100);
      return;
    }
    history.push(`${Prefixes.program}${ActionSuffixes.new}`);

  }
  const getDate = (date: Date): any => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return new Date(`${year}-${month}-${day} 00:00:00.000`).toISOString();
  }
  const onSave = (afterAction: AfterActions) => {
    if (!program) {
      return;
    }
    program.teuronaCoursesRules.forEach((rule) => {
      if (rule.startDate instanceof Date) {
        rule.startDate = getDate(rule.startDate);
      }
      if (rule.endDate instanceof Date) {
        rule.endDate = getDate(rule.endDate);
      }
    });
    program.coursesRules.forEach((rule) => {
      if (rule.startDate instanceof Date) {
        rule.startDate = getDate(rule.startDate);
      }
      if (rule.endDate instanceof Date) {
        rule.endDate = getDate(rule.endDate);
      }
    });
    form
      .validateFields()
      .then(() => {
        setAfterAction(afterAction);
        if (!isCreate) {
          dispatch(updateProgram(program));
          return;
        }
        dispatch(createProgram(program));
      })
      .catch((error) => {
        console.error("ProgramForm: React.FC<ProgramFromProps> -> error", error);
      });
  };

  if (restart) {
    return null;
  }

  return (
    <div className={styles.container}>
      <DialogPopUp
        name="Programa"
        action={'guardado'}
        visible={isModalVisible}
        onCancel={() => {
          dispatch(hideModal());
          doAfterAction();
        }}
      />
      <Form form={form} layout="vertical" initialValues={{ ...program }}>
        <Row gutter={50}>
          <Col span={10}>
            <FirstStep
              setProgram={setProgram}
              program={program}
              validateFields={validateFields}
            />
          </Col>
          <Col span={14}>
            <Tabs
              activeKey={tab}
              defaultActiveKey="teuronaCourses"
              onChange={(key) => {
                setTab(key);
              }}
              items={[
                {
                  label: getTranslations('COURSE_TEURONA_COURSES'),
                  key: "teuronaCourses",
                  children: <SecondStep
                    setProgram={setProgram}
                    program={program}
                    validateFields={validateFields}
                    coursesField={"teuronaCourses"}
                  />
                },
                {
                  label: getTranslations('COURSE_OWN_COURSES'),
                  key: "courses",
                  children: <SecondStep
                    setProgram={setProgram}
                    program={program}
                    validateFields={validateFields}
                    coursesField={"courses"}
                  />
                }

              ]}
            >
            </Tabs>
          </Col>
        </Row>
      </Form>
      <FormFooter
        editing={!isCreate}
        objectName={getTranslations('PROGRAM_TITLE_SINGULAR').toLocaleLowerCase()}
        onCancel={() => {
          history.push(`${Prefixes.program}`);
        }}
        onSave={() => onSave(AfterActions.BACK)}
        onSaveAndCreateAnother={() => onSave(AfterActions.STAY)}
      />
    </div>
  );
};
