import { CognitoUserSession } from "amazon-cognito-identity-js";
import { createBrowserHistory } from "history";
import "moment/min/locales";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./App.less";
import "./App.scss";
import { AdminRouter } from "./app/router/admin";
import { AnonymousRouter } from "./app/router/anonymous";
import CompanyAdminRouter from "./app/router/companyAdmin";
import { PublicRouter } from "./app/router/public";
import { StudentRouter } from "./app/router/student";
import SupervisorRouter from "./app/router/supervisor";
import LoadingOverlay from "./components/common/LoadingOverlay";
import { PushNotification } from "./components/PushNotification";
import {
  isLoading,
  loaded,
  loggedIn,
  loginSuccess,
  logoutFromCognito,
  selectUserRole,
  setCognitoUser,
  setCognitoUserData,
  setUserRole,
} from "./features/auth/authSlice";
import {
  selectUserAdmin,
  selectUserMode,
  selectUserStudent,
  setUserMode,
  UserModes,
} from "./features/userMode/UserMode";
import { UserRole } from "./features/users/usersSlice";
import { analytics } from "./services/analytics";
import CognizantUserPool from "./utils/CognizantUserPool";
import { Translator } from "./components/Translator";

export const history = createBrowserHistory();

function App() {
  const loading = useSelector(isLoading);
  const userMode = useSelector(selectUserMode);
  const userRole = useSelector(selectUserRole);
  const isUserAnAdmin = useSelector(selectUserAdmin);
  const isUserAnStudent = useSelector(selectUserStudent);
  const user = CognizantUserPool.getCurrentUser();
  const isLoggedIn = useSelector(loggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      user.getSession((err: any, session: CognitoUserSession) => {
        if (!err) {
          user.getUserAttributes(function (err, result) {
            if (err) {
              console.error("err: ", err);
              dispatch(logoutFromCognito());
              dispatch(loaded());
              history.push("/login");
              return;
            }
            setCognitoUser(user);
            const idToken = session.getIdToken();
            const cognitoData = JSON.parse(JSON.stringify(idToken));
            const role = cognitoData.payload["custom:role"];
            dispatch(setUserRole(role));
            if (userMode === UserModes.UNSET) {
              dispatch(setUserMode(cognitoData));
            }
            dispatch(
              loginSuccess({
                userEmail: session.getIdToken().payload.email,
                cognitoData,
              })
            );
            analytics.initialize(user?.getUsername(), cognitoData.payload["custom:company"]);
            dispatch(
              setCognitoUserData({
                email: idToken.payload.email,
                username: user.getUsername(),
                givenName: idToken.payload.given_name,
              })
            );
          });
        } else {
          dispatch(logoutFromCognito());
          dispatch(loaded());
          history.push("/login");
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const includesPublic = history.location.pathname.includes("public");
    const isSignup = history.location.pathname === "/signup";
    const includesRecoverPassword = history.location.pathname.includes("recoverPassword");
    if (!user && !includesPublic && !includesRecoverPassword && !isSignup) {
      dispatch(loaded());
      history.push("/login");
    }
    if (includesPublic || includesRecoverPassword || isSignup) {
      dispatch(loaded());
    }
  }, [user, dispatch]);

  if (loading && !isLoggedIn) {
    return <LoadingOverlay spinning={loading} />;
  }

  if (history.location.pathname.includes("public")) {
    return <PublicRouter history={history} />;
  }
  let content;
  if (isUserAnAdmin && isLoggedIn) {
    switch (userRole) {
      case UserRole.SuperAdmin:
        content = <AdminRouter history={history} />;
        break;
      case UserRole.CompanyAdmin:
        content = <CompanyAdminRouter history={history} />;
        break;
      case UserRole.Supervisor:
        content = <SupervisorRouter history={history} />;
        break;
    }
  } else if (isUserAnStudent && isLoggedIn) {
    content = <StudentRouter history={history} />;
  } else {
    return <AnonymousRouter history={history} />;
  }
  return (
    <>
      <Translator>
        {content}
      </Translator>
      <PushNotification />
    </>
  );
}

export default App;
