import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cls from 'classnames';

import LoadingOverlay from "../common/LoadingOverlay";
import { Button, Form, Input, Tabs } from "antd";

import commonStyles from "../common/form.module.scss";
import { FormFooter } from "../common/FormFooter";
import { ColorPicker } from "./ColorPicker";

import { CogIcon } from "../common/CogIcon";
import { ICompanyCustomize, ICompanyLink, selectCompany } from "../../features/company/companySlice";
import { saveCustomize, selectCustomizeIsSaving, selectDisplayModal, hideModal } from "../../features/customize/customizeSlice";
import { DialogPopUp } from "../common/DialogPopUp";
import { UploadImage } from "./UploadImage";
import { getTranslations } from "../../features/translations/translationsUtils";

import styles from "./settings.module.scss";

interface ISettings {
  companyId: string;
}

export const getCustomizeDefaultValues = (customize?: ICompanyCustomize | null, links?: ICompanyLink[] | undefined): ICompanyCustomize & { links: ICompanyLink[] } => {
  if (customize) {
    const keys = Object.keys(customize);
    if (keys.length > 1 || (keys.length === 1 && !keys.includes('_id'))) {
      return { ...customize, links: links ?? [] };
    }
  }
  return {
    colors: {
      main: '#3B54EC',
      mainButton: {
        background: '#3B54EC',
        text: '#FFFFFF',
      },
      secondaryButton: {
        background: '#FFFFFF',
        text: '#3B54EC',
      },
      tertiaryButton: {
        text: '#3B54EC',
      }
    },
    favicon: null,
    logo: null,
    mobileLogo: null,
    links: links ?? [],
  }
}

export const Settings: FunctionComponent<ISettings> = ({
  companyId
}) => {
  const [selectedTab, setSelectedTab] = useState<string>('customize');
  const [uploadingLogo, setUploadingLogo] = useState(false);
  const [uploadingFavicon, setUploadingFavicon] = useState(false);
  const fetching = useSelector(selectCustomizeIsSaving);
  const isModalVisible = useSelector(selectDisplayModal);
  const company = useSelector(selectCompany(companyId));
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [customize, setCustomize] = useState<ICompanyCustomize | null>(null);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(getCustomizeDefaultValues(company?.customize, company?.links));
    setCustomize(company?.customize ?? null);
  }, [form, company]);

  if (fetching) {
    return <LoadingOverlay spinning />;
  }

  if (!company) {
    return <LoadingOverlay spinning />;
  }
  const defaults = getCustomizeDefaultValues();
  const initialValues = getCustomizeDefaultValues(customize);

  const handleChangeLogo = (info: any) => {
    if (info.file.status === "uploading") {
      setUploadingLogo(true);
      return;
    }
    if (info.file.status === "done") {
      setUploadingLogo(false);
      form.setFieldValue('logo', info.file.response.url);
    }
  };

  const handleChangeMobileLogo = (info: any) => {
    if (info.file.status === "uploading") {
      setUploadingLogo(true);
      return;
    }
    if (info.file.status === "done") {
      setUploadingLogo(false);
      form.setFieldValue('mobileLogo', info.file.response.url);
    }
  };

  const handleChangeFavicon = (info: any) => {
    if (info.file.status === "uploading") {
      setUploadingFavicon(true);
      return;
    }
    if (info.file.status === "done") {
      setUploadingFavicon(false);
      form.setFieldValue('favicon', info.file.response.url);
    }
  };

  const renderLogosContent = () => <>
    <div className={cls(commonStyles.section, styles.section)}>
      <div className={commonStyles.sectionTitle}>{getTranslations('CUSTOMIZE_LOGOS_TITLE')}</div>
      <div className={styles.innerContent}>
        <div className={styles.innerTitle}>{getTranslations('CUSTOMIZE_LOGOS_HEADER_DESKTOP')}</div>
        <Form.Item shouldUpdate>
          {() => {
            const image = form.getFieldValue('logo');
            return <UploadImage
              imageUrl={image}
              onHandleChange={handleChangeLogo}
              description={<>
                {getTranslations('CUSTOMIZE_NO_FILES_SELECTED')}<br />{getTranslations('CUSTOMIZE_LOGO_SIZE_MESSAGE')}
              </>}
              allowedFileTypes={[
                'image/jpeg',
                'image/png',
                'image/svg+xml',
              ]}
              maxHeight={32}
              maxWidth={120}
              errorMessage={getTranslations('CUSTOMIZE_ONLY_ALLOWED_FILES')}
            />
          }}</Form.Item>
      </div>
      <div className={styles.innerContent}>
        <div className={styles.innerTitle}>{getTranslations('CUSTOMIZE_LOGOS_HEADER_MOBILE')}</div>
        <Form.Item shouldUpdate>
          {() => {
            const image = form.getFieldValue('mobileLogo');
            return <UploadImage
              imageUrl={image}
              onHandleChange={handleChangeMobileLogo}
              description={<>
                {getTranslations('CUSTOMIZE_NO_FILES_SELECTED')}<br />{getTranslations('CUSTOMIZE_LOGO_MOBILE_SIZE_MESSAGE')}
              </>}
              allowedFileTypes={[
                'image/jpeg',
                'image/png',
                'image/svg+xml',
              ]}
              maxHeight={32}
              maxWidth={32}
              errorMessage={getTranslations('CUSTOMIZE_ONLY_ALLOWED_FILES')}
            />
          }}</Form.Item>
      </div>
      <div className={styles.innerContent}>
        <div className={styles.innerTitle}>{getTranslations('CUSTOMIZE_FAVICON')}</div>
        <Form.Item shouldUpdate>
          {() => {
            const image = form.getFieldValue('favicon');
            return <UploadImage
              imageUrl={image}
              onHandleChange={handleChangeFavicon}
              description={<>
                {getTranslations('CUSTOMIZE_NO_FILES_SELECTED')}<br />{getTranslations('CUSTOMIZE_FAVICON_SIZE_MESSAGE')}
              </>}
              allowedFileTypes={[
                'image/jpeg',
                'image/png',
                'image/gif',
                'image/x-icon',
              ]}
              maxHeight={32}
              maxWidth={32}
              errorMessage={'Sólo archivos JPG/PNG/GIF/ICO son permitidos'}
            />
          }}</Form.Item>
      </div>
    </div>
    <div className={cls(commonStyles.section, styles.section)}>
      <div className={commonStyles.sectionTitle}>{getTranslations('CUSTOMIZE_COLORS')}
        <Button onClick={() => {
          form.setFieldsValue({
            colors: null,
          });
        }} type="link">{getTranslations('CUSTOMIZE_FACTORY_RESET')}</Button>
      </div>
      <div className={styles.innerContent}>
        <div className={styles.innerTitle}>{getTranslations('CUSTOMIZE_MAIN_COLOR')}</div>
        <Form.Item shouldUpdate>
          {() => {
            const color = form.getFieldValue('colors')?.main ?? defaults.colors?.main;
            return <div className={styles.twoColumns}>
              <div>
                <div style={{
                  backgroundColor: color,
                }} className={styles.colorBox}></div>
              </div>
              <div className={styles.colorWithDescription}>
                <div className={styles.colorPickerWrapper}>
                  <ColorPicker
                    color={color}
                    setColor={(color) => {
                      form.setFieldsValue({
                        colors: {
                          main: color,
                        }
                      });
                    }}
                    trigger={<div className={styles.trigger}>
                      <div style={{
                        backgroundColor: color,
                      }} className={styles.miniColorBox}></div> {color}
                    </div>
                    } />
                </div>
                <div className={styles.description}>
                  {getTranslations('CUSTOMIZE_MAIN_COLOR_INFO')}
                </div>
              </div>
            </div>
          }}
        </Form.Item>
      </div>
      <div className={styles.innerContent}>
        <div className={styles.innerTitle}>{getTranslations('CUSTOMIZE_MAIN_BUTTONS')}</div>

        <Form.Item shouldUpdate>
          {() => {
            const colors = form.getFieldValue('colors');
            const color1 = colors?.mainButton?.background ?? defaults.colors?.mainButton.background;
            const color2 = colors?.mainButton?.text ?? defaults.colors?.mainButton.text;
            return <div className={styles.twoColumns}>
              <div>
                <div className={styles.buttonContainer}>
                  <div className={styles.button} style={{
                    backgroundColor: color1,
                    color: color2,
                  }}>
                    <CogIcon color={color2} size={20} icon="Heart-line" /> {getTranslations('CUSTOMIZE_BUTTON')}
                  </div>
                </div>
              </div>
              <div className={styles.colors}>
                <div className={styles.color}>
                  <div className={styles.label}>{getTranslations('CUSTOMIZE_BACKGROUND')}</div>
                  <div className={styles.colorPickerWrapper}>
                    <ColorPicker
                      color={color1}
                      setColor={(color) => {
                        form.setFieldsValue({
                          colors: {
                            mainButton: {
                              background: color,
                            }
                          }
                        });
                      }}
                      trigger={<div className={styles.trigger}>
                        <div style={{
                          backgroundColor: color1,
                        }} className={styles.miniColorBox}></div> {color1}
                      </div>
                      } />
                  </div>
                </div>
                <div className={styles.color}>
                  <div className={styles.label}>{getTranslations('CUSTOMIZE_TEXT')}</div>
                  <div className={styles.colorPickerWrapper}>
                    <ColorPicker
                      color={color2}
                      setColor={(color) => {
                        form.setFieldsValue({
                          colors: {
                            mainButton: {
                              text: color,
                            }
                          }
                        });
                      }}
                      trigger={<div className={styles.trigger}>
                        <div style={{
                          backgroundColor: color2,
                        }} className={styles.miniColorBox}></div> {color2}
                      </div>
                      } />
                  </div>
                </div>
              </div>
            </div>
          }}
        </Form.Item>
      </div>
      <div className={styles.innerContent}>
        <div className={styles.innerTitle}>{getTranslations('CUSTOMIZE_SECONDARY_BUTTONS')}</div>
        <Form.Item shouldUpdate>
          {() => {
            const colors = form.getFieldValue('colors');
            const color1 = colors?.secondaryButton?.background ?? defaults.colors?.secondaryButton.background;
            const color2 = colors?.secondaryButton?.text ?? defaults.colors?.secondaryButton.text;
            return <div className={styles.twoColumns}>
              <div>
                <div className={styles.buttonContainer}>
                  <div className={styles.button} style={{
                    backgroundColor: color1,
                    color: color2,
                    border: `2px solid ${color2}`,
                  }}>
                    <CogIcon color={color2} size={20} icon="Heart-line" /> {getTranslations('CUSTOMIZE_BUTTON')}
                  </div>
                </div>
              </div>
              <div className={styles.colors}>
                <div className={styles.color}>
                  <div className={styles.label}>{getTranslations('CUSTOMIZE_BACKGROUND')}</div>
                  <div className={styles.colorPickerWrapper}>
                    <ColorPicker
                      color={color1}
                      setColor={(color) => {
                        form.setFieldsValue({
                          colors: {
                            secondaryButton: {
                              background: color,
                            }
                          }
                        });
                      }}
                      trigger={<div className={styles.trigger}>
                        <div style={{
                          backgroundColor: color1,
                        }} className={styles.miniColorBox}></div> {color1}
                      </div>
                      } />
                  </div>
                </div>
                <div className={styles.color}>
                  <div className={styles.label}>{getTranslations('CUSTOMIZE_TEXT')}</div>
                  <div className={styles.colorPickerWrapper}>
                    <ColorPicker
                      color={color2}
                      setColor={(color) => {
                        form.setFieldsValue({
                          colors: {
                            secondaryButton: {
                              text: color,
                            }
                          }
                        });
                      }}
                      trigger={<div className={styles.trigger}>
                        <div style={{
                          backgroundColor: color2,
                        }} className={styles.miniColorBox}></div> {color2}
                      </div>
                      } />
                  </div>
                </div>
              </div>
            </div>
          }}
        </Form.Item>
      </div>
      <div className={styles.innerContent}>
        <div className={styles.innerTitle}>{getTranslations('CUSTOMIZE_TERTIARY_BUTTONS')}</div>
        <Form.Item shouldUpdate>
          {() => {
            const colors = form.getFieldValue('colors');
            const color = colors?.tertiaryButton?.text ?? defaults.colors?.tertiaryButton.text;
            return <div className={styles.twoColumns}>
              <div>
                <div className={styles.buttonContainer}>
                  <div className={styles.button} style={{
                    color,
                  }}>
                    <CogIcon color={color} size={20} icon="Heart-line" /> {getTranslations('CUSTOMIZE_BUTTON')}
                  </div>
                </div>
              </div>
              <div className={styles.colors}>
                <div className={styles.color}>
                  <div className={styles.label}>{getTranslations('CUSTOMIZE_TEXT')}</div>
                  <div className={styles.colorPickerWrapper}>
                    <ColorPicker
                      color="#FFFFFF"
                      setColor={(color) => {
                        form.setFieldsValue({
                          colors: {
                            tertiaryButton: {
                              text: color,
                            }
                          }
                        });
                      }}
                      trigger={<div className={styles.trigger}>
                        <div style={{
                          backgroundColor: color,
                        }} className={styles.miniColorBox}></div> {color}
                      </div>
                      } />
                  </div>
                </div>
              </div>
            </div>
          }}
        </Form.Item>
      </div>
    </div>
  </>;

  const renderLinksContent = () => {
    return <div className={styles.linksContainer}>
      <div className={styles.externalDescription}>{getTranslations('CUSTOMIZE_LINKS_SUBTITLE')}</div>
      <Form.List name="links">
        {(fields, { add, remove }) => {
          const links: [] = form.getFieldValue('links');
          return <>
            {fields.map((field) => {
              return <div className={styles.link}>
                <div className={styles.delete} onClick={() => remove(field.name)}><CogIcon size={22} icon="Delete" /></div>
                <div key={field.key} className={styles.fields}>
                  <Form.Item
                    required
                    rules={[
                      { type: "string", required: true, message: getTranslations('GENERAL_REQUIRED') },
                    ]}
                    label={getTranslations('CUSTOMIZE_LINKS_TITLE')}
                    name={[field.name, 'title']}
                  >
                    <Input placeholder={getTranslations('CUSTOMIZE_LINKS_TITLE')} />
                  </Form.Item>
                  <Form.Item
                    required
                    rules={[
                      { type: "string", required: true, message: getTranslations('GENERAL_REQUIRED') },
                    ]}
                    label={getTranslations('CUSTOMIZE_LINKS_DESCRIPTION')}
                    name={[field.name, 'description']}
                  >
                    <Input.TextArea placeholder={getTranslations('CUSTOMIZE_LINKS_DESCRIPTION')} />
                  </Form.Item>
                  <Form.Item
                    required
                    rules={[
                      { type: "string", required: true, message: getTranslations('GENERAL_REQUIRED') },
                      { type: "url", required: true, message: getTranslations('GENERAL_URL_INVALID') },
                    ]}
                    label={getTranslations('CUSTOMIZE_LINKS_LINK')}
                    name={[field.name, 'url']}
                  >
                    <Input placeholder="www" />
                  </Form.Item>
                  <Form.Item
                    required
                    rules={[
                      { type: "string", required: true, message: getTranslations('GENERAL_REQUIRED') },
                    ]}
                    label={getTranslations('CUSTOMIZE_LINKS_BUTTON_TEXT')}
                    name={[field.name, 'buttonText']}
                  >
                    <Input placeholder={getTranslations('CUSTOMIZE_LINKS_BUTTON_TEXT')} />
                  </Form.Item>
                </div>
                <div className={styles.divider} />
                <div className={styles.previewContainer}>
                  <div className={styles.title}>{getTranslations('CUSTOMIZE_LINKS_PREVIEW')}</div>
                  <Form.Item shouldUpdate style={{ width: '100%', maxWidth: '100%' }}>
                    {() => {
                      const values = (form.getFieldValue(`links`) ?? {})[field.name] ?? {};
                      return <div className={styles.preview}>
                        <div className={styles.icon}>
                          <div className={styles.iconContainer}>
                            <CogIcon color="#81858B" icon="report" size={20} />
                          </div>
                        </div>
                        <div className={styles.content}>
                          <div className={styles.title}>{values.title}</div>
                          <div className={styles.description}>{values.description}</div>
                          <div className={styles.linkPreview}>
                            <div className={styles.text}>{values.buttonText}</div>
                            <div className={styles.iconContainer}>
                              <CogIcon size={24} color="var(--tertiary-text-button-color)" className={styles.icon} icon="Arrow-down" />
                            </div>
                          </div>
                        </div>
                      </div>;
                    }}
                  </Form.Item>
                </div>
              </div>;
            })}
            <Button disabled={links.length >= 4} style={{ width: 237 }} type="link" className="withBorder" size="large" onClick={() => add()}>{getTranslations('CUSTOMIZE_LINKS_ADD')}</Button>
          </>;
        }}
      </Form.List>
    </div>;
  }


  const items = [
    {
      label: <span>
        {getTranslations('CUSTOMIZE_TAB_CUSTOMIZE_TITLE')}
      </span>,
      key: 'customize',
      children: renderLogosContent(),
    },
    {
      label: <span>
        {getTranslations('CUSTOMIZE_TAB_LINK_TITLE')}
      </span>,
      key: 'links',
      children: renderLinksContent(),
    },
  ];

  return <div>
    <DialogPopUp
      name={getTranslations('CUSTOMIZE_TITLE')}
      action={'guardado'}
      visible={isModalVisible}
      onCancel={() => {
        dispatch(hideModal());
      }}
    />
    <Form
      form={form}
      className={commonStyles.form}
      layout="vertical"
      name="control-hooks"
      initialValues={initialValues}

    >
      <Tabs
        className={styles.tabs}
        defaultActiveKey={selectedTab}
        items={items}
        activeKey={selectedTab}
        onChange={(tab) => {
          setSelectedTab(tab);
        }}
      />
    </Form>
    <FormFooter
      justSave
      editing
      objectName=""
      disabledSave={uploadingLogo || uploadingFavicon}
      onCancel={() => {
        form.resetFields();
      }}
      onSave={async () => {
        try {
          await form.validateFields();
        } catch {
          return;
        }
        const links = form.getFieldsValue(['links']);

        dispatch(saveCustomize(
          companyId,
          form.getFieldsValue(['colors', 'mobileLogo', 'logo', 'favicon']),
          links.links
        ));
      }}
      onSaveAndCreateAnother={() => {
        // dispatch(saveCustomize(companyId, form.getFieldsValue(['colors', 'mobileLogo', 'logo', 'favicon'])));
      }}
    />
  </div>;
};
