import React, { FunctionComponent, useEffect } from "react";
import { Layout } from "antd";
import { Router, Switch, Route } from "react-router-dom";
import * as H from "history";

import { Header } from "../../components/Header";
import { FinishRegistration } from "../../components/Authentication/FinishRegistration";
import { PasswordChange } from "../../components/Authentication/PasswordChange";
import { Login } from "../../components/Authentication/Login";
import { Message } from "../../components/common/Message";
import { Welcome } from "../../components/Authentication/Welcome";
import { GroupRouter } from "../../components/Group/router";
import { CompanyRouter } from "../../components/Company/router";
import { StudentProfile } from "../../components/Profile/StudentProfile";
import { Profile } from "../../components/Profile";
import { CoursesRouter } from "../../components/Courses/router";
import { TopicRouter } from "../../components/Topic/router";
import { ProgramRouter } from "../../components/Program/router";
import { KnowledgeBasesRouter } from "../../components/KnowledgeBase/router";
import { CampaignsRouter } from "../../components/Campaign/router";
import { Reports } from "../../components/Reports";
import { LoggedUserProfile } from "../../components/LoggedUser/LoggedUserProfile";
import ScrollToTop from "../../components/common/ScrollToTop";
import { useDispatch } from "react-redux";
import { clearBreadcrumbs } from "../../features/breadcrumbs/breadcrumbsSlice";
import { Blueprints } from "../../components/Blueprint";
import { Notifications } from "../../components/Notifications";
import { PrintableProfile } from "../../components/Printable/Profile";
import { AllNotifications } from "../../components/Students/Notifications";
import { LessonsRouter } from "../../components/Lesson/router";
import { EvaluationsRouter } from "../../components/Evaluation/router";
import { AnalyticsCompanyDimSetter } from '../../components/common/AnalyticsCompanyDimSetter';
import { Prefixes } from "../../components/prefixes";
import { UsersRouter } from "../../components/Users/router";
import { Gamification } from "../../components/Gamification";
import { Customize } from "../../components/Customize";
import { Translations } from "../../components/Translations";

const { Content } = Layout;

interface AdminRouterProps {
  history: H.History<any>;
}

export const AdminRouter: FunctionComponent<AdminRouterProps> = ({
  history,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    return history.listen((location) => {
      dispatch(clearBreadcrumbs());
    });
  }, [dispatch, history]);
  return (
    <Router history={history}>
      <AnalyticsCompanyDimSetter />
      <ScrollToTop />
      <Layout>
        <Header isLoggedIn />
        <Content className="site-layout" style={{ position: "relative" }}>
          <Switch>
            <Route path="/my-profile/:tab">
              <LoggedUserProfile />
            </Route>
            <Route path="/new-password">
              <FinishRegistration />
            </Route>
            <Route path="/change-password">
              <PasswordChange />
            </Route>
            <Route path="/welcome">
              <Welcome />
            </Route>
            <Route path={Prefixes.group}>
              <GroupRouter history={history} />
            </Route>
            <Route path={Prefixes.company}>
              <CompanyRouter history={history} />
            </Route>
            <Route path="/admin-users/:id/print">
              <PrintableProfile />
            </Route>
            <Route path="/admin-users/:id">
              <Profile />
            </Route>
            <Route path="/users">
              <UsersRouter history={history} />
            </Route>
            <Route path="/students/:id/print">
              <PrintableProfile />
            </Route>
            <Route path="/students/:id">
              <StudentProfile />
            </Route>
            <Route path={Prefixes.lesson}>
              <LessonsRouter history={history} />
            </Route>
            <Route path="/evaluations">
              <EvaluationsRouter history={history} />
            </Route>
            <Route path={Prefixes.campaign}>
              <CampaignsRouter history={history} />
            </Route>
            <Route path={Prefixes.course}>
              <CoursesRouter history={history} />
            </Route>
            <Route path={Prefixes.topic}>
              <TopicRouter history={history} />
            </Route>
            <Route path={Prefixes.program}>
              <ProgramRouter history={history} />
            </Route>
            <Route path={Prefixes.knowledgeBase}>
              <KnowledgeBasesRouter history={history} />
            </Route>
            <Route path={Prefixes.reports}>
              <Reports />
            </Route>
            <Route path="/certificates">
              <Blueprints />
            </Route>
            <Route path="/notifications">
              <Notifications />
            </Route>
            <Route path="/gamification">
              <Gamification />
            </Route>
            <Route path="/company-customize">
              <Customize />
            </Route>
            <Route path="/all/notifications">
              <AllNotifications />
            </Route>
            <Route path='/translations'>
              <Translations />
            </Route>
            <Route path="/">
              <Login />
            </Route>
          </Switch>
        </Content>
        <Message />
      </Layout>
    </Router>
  );
};
