import { Action, combineReducers } from "@reduxjs/toolkit";
import authReducer, { logout } from "../features/auth/authSlice";
import blueprintReducer from "../features/blueprint/blueprintSlice";
import breadcrumbsReducer from "../features/breadcrumbs/breadcrumbsSlice";
import campaignReducer from "../features/campaign/campaignSlice";
import categoryReducer from "../features/category/categorySlice";
import companyReducer from "../features/company/companySlice";
import companyTypesReducer from "../features/companyType/companyTypeSlice";
import contentCategoryReducer from "../features/contentCategory/contentCategorySlice";
import courseReducer from "../features/courses/coursesSlice";
import currentEvaluationReducer from "../features/evaluation/currentEvaluationSlice";
import currentSurveyReducer from "../features/evaluation/currentSurveySlice";
import evaluationReducer from "../features/evaluation/evaluationsSlice";
import groupReducer from "../features/groups/groupSlice";
import contentReducer from "../features/knowledgeBase/contentSlice";
import lessonReducer from "../features/lesson/lessonSlice";
import videoStatusReducer from "../features/lesson/videoStatus";
import loggedUserReducer from "../features/loggedUser/loggedUserSlice";
import messageReducer from "../features/message/messageSlice";
import notificationsScheduleReducer from "../features/notifications/notificationsScheduleSlice";
import notificationsReducer from "../features/notifications/notificationsSlice";
import registerClientReducer from "../features/notifications/registerClientSlice";
import userEvaluationsReducer from "../features/printable/user";
import programReducer from "../features/program/programSlice";
import campaignByCompanyReducer from "../features/reports/Campaigns/byCompany";
import campaignByDate from "../features/reports/Campaigns/byDateRange";
import campaignsByYearAndMonthReducer from "../features/reports/Campaigns/byYearAndMonth";
import campaignStats from "../features/reports/Campaigns/stats";
import ticketsByCategoryReducer from "../features/reports/case/ticketsByCategory";
import ticketsTopFiveReducer from "../features/reports/case/ticketsByCompanyTopFive";
import ticketsByReportTypeReducer from "../features/reports/case/ticketsByReportType";
import ticketsByStatusReducer from "../features/reports/case/ticketsByStatus";
import usersByCaseReducer from "../features/reports/case/usersByCase";
import usersByCaseResponseTimeReducer from "../features/reports/case/usersByCaseResponseTime";
import xOneToken from "../features/reports/case/xOneToken";
import companyEnabledVsDisabledReducer from "../features/reports/company/enabledVsDisabled";
import insAndOutsForChurnedReducer from "../features/reports/company/insAndOutsForChurned";
import insAndOutsPerDayReducer from "../features/reports/company/insAndOutsPerDay";
import insAndOutsPerDayAggregatedReducer from "../features/reports/company/insAndOutsPerDayAggregated";
import companySessionDuration from "../features/reports/company/sessionDuration";
import userStats from "../features/reports/company/userStats";
import knowledgeBaseByCategoryReducer from "../features/reports/knowledgeBase/byCategory";
import knowledgeBaseByCompanyReducer from "../features/reports/knowledgeBase/byCompany";
import knowledgeBaseVisitsReducer from "../features/reports/knowledgeBase/viewKnowledgeBase";
import companiesContentReducer from "../features/reports/learning/common/companiesContent";
import coursesByCategoryReducer from "../features/reports/learning/courses/coursesByCategory";
import coursesCompletenessByCompanyReducer from "../features/reports/learning/courses/coursesCompletenessByCompany";
import coursesCompletenessByRoleReducer from "../features/reports/learning/courses/coursesCompletenessByRole";
import examResultsCountersReducer from "../features/reports/learning/courses/examResultsCounters";
import surveyResultsCountersReducer from "../features/reports/learning/courses/surveyResultsCounters";
import coursesFulfillmentsReducer from "../features/reports/learning/courses/fulfillment";
import coursesFulfillmentsPerCompanyReducer from "../features/reports/learning/courses/fulfillmentPerCompany";
import coursesInTop10Reducer from "../features/reports/learning/courses/onTop10";
import evaluationsResultsWithResponsesReducer from "../features/reports/learning/evaluations/evaluationsResultsWithResponses";
import lessonsFulfillmentsReducer from "../features/reports/learning/lessons/fulfillment";
import lessonsFulfillmentsPerCompanyReducer from "../features/reports/learning/lessons/fulfillmentPerCompany";
import lessonsByCategoryReducer from "../features/reports/learning/lessons/lessonsByCategory";
import lessonsCompletenessByCompanyReducer from "../features/reports/learning/lessons/lessonsCompletenessByCompany";
import lessonsCompletenessByRoleReducer from "../features/reports/learning/lessons/lessonsCompletenessByRole";
import lessonsInTop10Reducer from "../features/reports/learning/lessons/onTop10";
import quizResultsCountersReducer from "../features/reports/learning/lessons/quizResultsCounters";
import scoreCompanyByCoursesReducer from "../features/reports/learning/scoreCompanyByCourses";
import scoreCompanyByCoursesAndUsersReducer from "../features/reports/learning/scoreCompanyByCoursesAndUsers";
import scoreCompanyByTopicsReducer from "../features/reports/learning/scoreCompanyByTopics";
import scoreCompanyByTopicsAndUsersReducer from "../features/reports/learning/scoreCompanyByTopicsAndUsers";
import topicsFulfillmentsReducer from "../features/reports/learning/topics/fulfillment";
import topicsFulfillmentsPerCompanyReducer from "../features/reports/learning/topics/fulfillmentPerCompany";
import topicsInTop10Reducer from "../features/reports/learning/topics/onTop10";
import testResultsCountersReducer from "../features/reports/learning/topics/testResultsCounters";
import topicsByCategoryReducer from "../features/reports/learning/topics/topicsByCategory";
import topicsCompletenessByCompanyReducer from "../features/reports/learning/topics/topicsCompletenessByCompany";
import topicsCompletenessByRoleReducer from "../features/reports/learning/topics/topicsCompletenessByRole";
import userEnabledVsDisabledByAgeReducer from "../features/reports/users/ByAge";
import userEnabledVsDisabledByGenderReducer from "../features/reports/users/ByGender";
import userEnabledVsDisabledByGroupReducer from "../features/reports/users/byGroup";
import userEnabledVsDisabledByUserTypeReducer from "../features/reports/users/byUserType";
import userSessionDuration from "../features/reports/users/sessionDuration";
import studentCampaignReducer from "../features/students/campaignSlice";
import certificateHashReducer from "../features/students/certificateHashSlice";
import certificateListReducer from "../features/students/certificateListSlice";
import certificateReducer from "../features/students/certificateSlice";
import studentCourseReducer from "../features/students/courseSlice";
import evaluationsResultsReducer from "../features/students/evaluationsResultsSlice";
import evaluationsTitlesReducer from "../features/students/evaluationsTitlesSlice";
import examResultReducer from "../features/students/examResultSlice";
import homeCoursesReducer from "../features/students/homeCoursesSlice";
import knowledgeBaseReducer from "../features/students/knowledgeBaseSlice";
import myCoursesReducer from "../features/students/myCoursesSlice";
import studentNotificationsReducer from "../features/students/notifications";
import teacherReducer from "../features/teacher/teacherSlice";
import topicReducer from "../features/topic/topicSlice";
import userModeReducer from "../features/userMode/UserMode";
import userReducer from "../features/users/usersSlice";
import prefixedImagesReducer from "../features/prefixedImages/prefixedImagesSlice";
import pointsReducer from "../features/students/pointsSlice";
import rankingReducer from "../features/students/rankingSlice";
import sessionInfoReducer from "../features/students/sessionInfoSlice";
import surveysTakenReducer from "../features/students/surveysTakenSlice";
import coursesCountsReducer from "../features/students/coursesCountsSlice";
import gamificationReducer from "../features/gamification/gamificationSlice";
import customizeSlice from "../features/customize/customizeSlice";
import translationsSlice from "../features/translations/translationsSlice";
import translationsAdminSlice from "../features/translationsAdmin/translationsAdminSlice";
import languagesSlice from "../features/languages/languagesSlice";

const innerRootReducer = combineReducers({
  auth: authReducer,
  blueprints: blueprintReducer,
  breadcrumbs: breadcrumbsReducer,
  categories: categoryReducer,
  companyTypes: companyTypesReducer,
  campaigns: campaignReducer,
  certificate: certificateReducer,
  certificateHash: certificateHashReducer,
  certificateList: certificateListReducer,
  companies: companyReducer,
  content: contentReducer,
  contentCategories: contentCategoryReducer,
  courses: courseReducer,
  coursesCounts: coursesCountsReducer,
  currentEvaluation: currentEvaluationReducer,
  currentSurvey: currentSurveyReducer,
  customize: customizeSlice,
  evaluations: evaluationReducer,
  evaluationsTitles: evaluationsTitlesReducer,
  examResult: examResultReducer,
  evaluationsResults: evaluationsResultsReducer,
  groups: groupReducer,
  gamification: gamificationReducer,
  languages: languagesSlice,
  lessons: lessonReducer,
  loggedUser: loggedUserReducer,
  message: messageReducer,
  notifications: notificationsReducer,
  notificationsSchedule: notificationsScheduleReducer,
  points: pointsReducer,
  programs: programReducer,
  prefixedImages: prefixedImagesReducer,
  ranking: rankingReducer,
  registerClient: registerClientReducer,
  reports: combineReducers({
    company: combineReducers({
      // reports of company
      companyEnabledVsDisabled: companyEnabledVsDisabledReducer,
      sessionDuration: companySessionDuration,
      userStats: userStats,
      insAndOutsNew: combineReducers({
        forChurned: insAndOutsForChurnedReducer,
        perDay: insAndOutsPerDayReducer,
        perDayAggregated: insAndOutsPerDayAggregatedReducer,
      }),
    }),
    campaign: combineReducers({
      // reports of knowledgeBase
      byCompany: campaignByCompanyReducer,
      stats: campaignStats,
      history: campaignByDate,
    }),
    knowledgeBase: combineReducers({
      // reports of knowledgeBase
      byCategory: knowledgeBaseByCategoryReducer,
      byCompany: knowledgeBaseByCompanyReducer,
      visits: knowledgeBaseVisitsReducer,
    }),
    learning: combineReducers({
      evaluations: combineReducers({
        evaluationsResultsWithResponses: evaluationsResultsWithResponsesReducer,
      }),
      common: combineReducers({
        companiesContent: companiesContentReducer,
      }),
      lessons: combineReducers({
        lessonsInTop10: lessonsInTop10Reducer,
        lessonsFulfillments: lessonsFulfillmentsReducer,
        lessonsFulfillmentsPerCompany: lessonsFulfillmentsPerCompanyReducer,
        quizResultsCounters: quizResultsCountersReducer,
      }),
      topics: combineReducers({
        topicsInTop10: topicsInTop10Reducer,
        topicsFulfillments: topicsFulfillmentsReducer,
        topicsFulfillmentsPerCompany: topicsFulfillmentsPerCompanyReducer,
        testResultsCounters: testResultsCountersReducer,
      }),
      courses: combineReducers({
        coursesInTop10: coursesInTop10Reducer,
        coursesFulfillments: coursesFulfillmentsReducer,
        coursesFulfillmentsPerCompany: coursesFulfillmentsPerCompanyReducer,
        examResultsCounters: examResultsCountersReducer,
        surveyResultsCounters: surveyResultsCountersReducer,
      }),
      campaignsByYearAndMonth: campaignsByYearAndMonthReducer,
      lessonsByCategory: lessonsByCategoryReducer,
      lessonsCompletenessByCompany: lessonsCompletenessByCompanyReducer,
      lessonsCompletenessByRole: lessonsCompletenessByRoleReducer,
      topicsByCategory: topicsByCategoryReducer,
      topicsCompletenessByCompany: topicsCompletenessByCompanyReducer,
      topicsCompletenessByRole: topicsCompletenessByRoleReducer,
      coursesByCategory: coursesByCategoryReducer,
      coursesCompletenessByCompany: coursesCompletenessByCompanyReducer,
      coursesCompletenessByRole: coursesCompletenessByRoleReducer,
      scoreCompanyByTopics: scoreCompanyByTopicsReducer,
      scoreCompanyByTopicsAndUsers: scoreCompanyByTopicsAndUsersReducer,
      scoreCompanyByCourses: scoreCompanyByCoursesReducer,
      scoreCompanyByCoursesAndUsers: scoreCompanyByCoursesAndUsersReducer,
    }),
    case: combineReducers({
      ticketsByStatus: ticketsByStatusReducer,
      usersByCase: usersByCaseReducer,
      userByCaseResponseTime: usersByCaseResponseTimeReducer,
      ticketsByCategory: ticketsByCategoryReducer,
      ticketsByReportType: ticketsByReportTypeReducer,
      ticketsTopFive: ticketsTopFiveReducer,
      xOneToken: xOneToken,
    }),
    user: combineReducers({
      // reports of students
      userEnabledVsDisabledByUserType: userEnabledVsDisabledByUserTypeReducer,
      userEnabledVsDisabledByGender: userEnabledVsDisabledByGenderReducer,
      userEnabledVsDisabledByAge: userEnabledVsDisabledByAgeReducer,
      userEnabledVsDisabledByGroup: userEnabledVsDisabledByGroupReducer,
      sessionDuration: userSessionDuration,
    }),
  }),
  sessionInfo: sessionInfoReducer,
  surveysTaken: surveysTakenReducer,
  studentCourse: studentCourseReducer,
  studentKnowledgeBase: knowledgeBaseReducer,
  studentHomeCourses: homeCoursesReducer,
  studentCampaigns: studentCampaignReducer,
  studentMyCourses: myCoursesReducer,
  studentNotifications: studentNotificationsReducer,
  teachers: teacherReducer,
  topics: topicReducer,
  translations: translationsSlice,
  translationsAdmin: translationsAdminSlice,
  userMode: userModeReducer,
  users: userReducer,
  videoStatus: videoStatusReducer,
  userEvaluationStats: userEvaluationsReducer,
});

const rootReducer = (
  state: ReturnType<typeof innerRootReducer> | undefined,
  action: Action<string>
) => {
  if (action.type === logout.type) {
    return innerRootReducer(undefined, { type: "" });
  }
  return innerRootReducer(state, action);
};

export default rootReducer;
