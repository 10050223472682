import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../app/AllowedMicroservices";
import { AppThunk, RootState } from "../../app/store";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { MODAL_DELAY_MS } from "../../utils/constants";
import { FetchStatus, ModalStatus } from "../common/enums";
import { setMessageError, setMessageInfo } from "../message/messageSlice";
import { getTranslations } from "../translations/translationsUtils";

export enum GenderEnum {
  Male = "MALE",
  Female = "FEMALE",
  Other = "OTHER",
}
export const GenderDict = () => ({
  [GenderEnum.Female]: getTranslations('REPORT_FEMALE'),
  [GenderEnum.Male]: getTranslations('REPORT_MALE'),
  [GenderEnum.Other]: getTranslations('REPORT_OTHER'),
});
export enum UserRole {
  SuperAdmin = "SuperAdmin",
  CompanyAdmin = "CompanyAdmin",
  Supervisor = "Supervisor",
}

export const RolesDict = {
  [UserRole.SuperAdmin]: "Súper administrador",
  [UserRole.CompanyAdmin]: "Administrador de empresas",
  [UserRole.Supervisor]: "Supervisor",
};
export enum UsersGroups {
  Administrators = "Administrators",
  Students = "Students",
}
export interface IUser {
  enabled: boolean;
  username: string;
  name: string;
  address: string;
  birthdate: string;
  family_name: string;
  gender: GenderEnum;
  given_name: string;
  middle_name?: string;
  phone_number: string;
  picture?: string;
  nationalId: string;
  nationalIdType: string;
  secondLastname?: string;
  country: string;
  city: string;
  company?: string;
  companyName?: string; //Only for filter
  role?: UserRole;
  email: string;
  groups: string[]; // Administrators, Students
  groupId?: string;
  studentGroupId?: string;
  createdAt: Date;
  position?: string;
  status?: string;
  isAdminToo?: boolean;
  isStudentToo?: boolean;
}

interface Attributes {
  Name: string;
  Value: string;
}

interface RawUser {
  Attributes: Attributes[];
}

export interface ResponseUser {
  User: RawUser;
}


interface IUserDict {
  [_id: string]: IUser;
}
interface UserState {
  list: string[],
  dict: IUserDict,
  perCompany: {
    [id:string]: string[],
  },
  studentsPerGroup: {
    [_id: string]: string[];
  },
  studentGroupId: {
    [_id: string]: string;
  },
  groupIdStudent: {
    [_id: string]: string;
  }
  status: FetchStatus,
  modalStatus: ModalStatus,
  created?: ResponseUser,
}

interface UserUpdateStatusData {
  usernames: string[];
}

const initialState: UserState = {
  list: [],
  dict: {},
  studentsPerGroup: {},
  studentGroupId: {},
  groupIdStudent: {},
  perCompany: {},
  status: FetchStatus.Idle,
  modalStatus: ModalStatus.Hide,
};

const BASE_USER_ENDPOINT = "/user";

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    creating: (state) => {
      state.status = FetchStatus.Creating;
    },
    created: (state, action: PayloadAction<ResponseUser>) => {
      state.created = action.payload;
    },
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetchedUsers: (state, action: PayloadAction<IUser[]>) => {
      state.list = action.payload.map((user) => user.username);
      state.dict = {};
      state.perCompany = {};
      state.studentsPerGroup = {};
      action.payload.forEach((user) => {
        const { username, enabled } = user;
        state.dict[username] = user;
        const { company = 'no-company' } = user;
        if(!state.perCompany[company]){
          state.perCompany[company] = [];
        }
        if(enabled){
          if(user.studentGroupId){
            state.perCompany[company].push(username);
            if(!state.studentsPerGroup[user.studentGroupId]){
              state.studentsPerGroup[user.studentGroupId] = [];  
            }
            state.studentsPerGroup[user.studentGroupId].push(user.username);
            state.studentGroupId[user.studentGroupId] = user.username;
            state.groupIdStudent[user.username] = user.studentGroupId;
          }
        }
      });
      state.status = FetchStatus.Fetched;
    },
    fetchedUser: (state, action: PayloadAction<IUser>) => {
      if (!state.list.includes(action.payload.username)) {
        state.list.push(action.payload.username);
      }
      state.dict[action.payload.username] = action.payload;
      state.status = FetchStatus.Fetched;
    },
    error: (state) => {
      state.status = FetchStatus.Error;
    },
    showModal: (state) => {
      state.modalStatus = ModalStatus.Show;
    },
    hideModal: (state) => {
      state.modalStatus = ModalStatus.Hide;
    },
  },
});

export const {
  fetching,
  fetchedUser,
  fetchedUsers,
  error,
  creating,
  hideModal,
  showModal,
  created,
} = usersSlice.actions;

export const createUser = (newUser: IUser) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: BASE_USER_ENDPOINT,
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: fetchUsers,
          },
          {
            actionOrCreator: showModal,
            selector: () => {
              dispatch(hideModalWithDelay());
            },
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
            selector: (response: any) => {
              if (
                response.data &&
                response.data.statusCode === 409 &&
                response.data.data === "UsernameExists"
              ) {
                const message = `El correo "${newUser.username}" ya existe.`;
                dispatch(setMessageError({ message }));
                return;
              }
              const message = `Error desconocido`;
              dispatch(setMessageError({ message }));
            },
          },
        ],
      },
      method: HttpMethods.POST,
      microservice: Microservices.USER_SERVICE,
      authenticated: true,
      data: newUser,
    })
  );
};

export const updateUser = (user: IUser) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_USER_ENDPOINT}/${user.username}`,
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: fetchUsers,
          },
          {
            actionOrCreator: showModal,
            selector: () => {
              dispatch(hideModalWithDelay());
            },
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
            selector: (response: any) => {
              if (
                response.data &&
                response.data.statusCode === 409 &&
                response.data.data === "UsernameExists"
              ) {
                const message = `El correo "${user.email}" ya existe.`;
                dispatch(setMessageError({ message }));
                return;
              }
              const message = `Error desconocido`;
              dispatch(setMessageError({ message }));
            },
          },
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.USER_SERVICE,
      authenticated: true,
      data: user,
    })
  );
};

export const fetchUsers = () => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_USER_ENDPOINT}/?limit=10000`,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetchedUsers,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.USER_SERVICE,
      authenticated: true,
    })
  );
};

export const fetchAdministrators = () => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_USER_ENDPOINT}/administrators?limit=10000`,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetchedUsers,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.USER_SERVICE,
      authenticated: true,
    })
  );
};

export const fetchUser = (id: string) => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_USER_ENDPOINT}/${id}`,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetchedUser,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.USER_SERVICE,
      authenticated: true,
    })
  );
};

export const fetchStudents = () => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_USER_ENDPOINT}/students?limit=10000`,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetchedUsers,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.USER_SERVICE,
      authenticated: true,
    })
  );
};

export const fetchStudentsByCompany = (companyId: string) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_USER_ENDPOINT}/studentsByCompany/${companyId}?limit=10000`,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetchedUsers,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.USER_SERVICE,
      authenticated: true,
    })
  );
};

export const enableUsersStatus = (
  isAdmin: boolean,
  users: UserUpdateStatusData
) => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: "/user/enable",
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: isAdmin ? fetchAdministrators : fetchStudents,
          },
          {
            actionOrCreator: setMessageInfo({
              message: getTranslations('USER_ENABLED_USERS_MESSAGE_FAILED'),
            }),
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
            selector: (response: any) => {
              if (
                response.data &&
                response.data.statusCode === 409 &&
                response.data.data === "CompanySeatsFull"
              ) {
                const message = getTranslations('USER_ENABLED_USERS_MESSAGE_FAILED_2');
                dispatch(setMessageError({ message }));
                return;
              }
              const message = getTranslations('USER_ENABLED_USERS_MESSAGE_FAILED');
              dispatch(setMessageError({ message }));
            },
          },
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.COMPANY_SERVICE,
      authenticated: true,
      data: users,
    })
  );
};

export const disableUsersStatus = (
  isAdmin: boolean,
  users: UserUpdateStatusData
) => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: "/user/disable",
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: isAdmin ? fetchAdministrators : fetchStudents,
          },
          {
            actionOrCreator: setMessageInfo({
              message: getTranslations('USER_DISABLED_USERS_MESSAGE'),
            }),
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
          {
            actionOrCreator: fetchedUsers,
          },
          {
            actionOrCreator: setMessageError({
              message: getTranslations('USER_DISABLED_USERS_MESSAGE_FAILED'),
            }),
          },
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.COMPANY_SERVICE,
      authenticated: true,
      data: users,
    })
  );
};

export const hideModalWithDelay = (): AppThunk => async (dispatch) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      dispatch(hideModal());
      resolve();
    }, MODAL_DELAY_MS);
  });
};

export const selectUsersIds = (state: RootState) => state.users.list;
export const selectUsers = (state: RootState) => state.users.dict;
export const selectUsersFetchStatus = (state: RootState) => state.users.status;
export const selectAreUsersFetching = (state: RootState) =>
  state.users.status === FetchStatus.Fetching;
export const selectUser = (id: string) => (state: RootState) =>
  state.users.dict[id];

export const selectStudentsIds = (state: RootState) => state.users.list;
export const selectStudentsPerCompany = (state: RootState) => state.users.perCompany;
export const selectStudents = (state: RootState) => state.users.dict;
export const selectStudentsCount = (state: RootState) => state.users.list.length;
export const selectStudentsFetchStatus = (state: RootState) => 
  state.users.status;
export const selectStudent = (state: RootState, id: string) =>
  state.users.dict[id];
export const selectDisplayModal = (state: RootState) =>
  state.users.modalStatus === ModalStatus.Show;
export const selectCreatedStudent = (state: RootState) => state.users.created;
export const selectStudentIdPerGroup = (state: RootState) => state.users.studentsPerGroup;
export const selectStudentGroup = (state: RootState) => state.users.studentGroupId;
export const selectGroupStudent = (state: RootState) => state.users.groupIdStudent;

export default usersSlice.reducer;
