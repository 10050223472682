import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTranslations, selectTranslationsFetchedLanguage, selectTranslationsIsFetching } from '../../features/translations/translationsSlice';
import LoadingOverlay from '../common/LoadingOverlay';
import { fetchCompanies, selectCompaniesAreFetching, selectCompany } from '../../features/company/companySlice';
import { getCognitoUserData } from '../../features/auth/authSlice';

export const Translator: React.FunctionComponent = ({ children }) => {
  const dispatch = useDispatch();
  const fetchedLanguage = useSelector(selectTranslationsFetchedLanguage)
  const companiesFetching = useSelector(selectCompaniesAreFetching);
  const translationsFetching = useSelector(selectTranslationsIsFetching);
  const cognitoUserData = useSelector(getCognitoUserData);
  const companyId = cognitoUserData
    ? cognitoUserData["custom:company"]
    : "";
  const companyFromStore = useSelector(
    selectCompany(companyId)
  );
  const language = companyFromStore || (!companyId && cognitoUserData) ? (companyFromStore?.language ?? 'es') : null;

  useEffect(() => {
    if (!translationsFetching && !companiesFetching && language && fetchedLanguage !== language) {
      dispatch(fetchTranslations(language ?? 'es'));
    }
  }, [dispatch, language, companiesFetching, fetchedLanguage, translationsFetching]);

  useEffect(() => {
    if (!companyFromStore) {
      dispatch(fetchCompanies());
    }
  }, [companyFromStore, dispatch]);

  if (translationsFetching || !language || fetchedLanguage === 'unset') {
    return <LoadingOverlay spinning />;
  }

  return <>{children}</>;
};