import React, { Dispatch, FunctionComponent, useEffect, useState } from "react";

import { DownOutlined } from "@ant-design/icons";
import { Alert, Button, Dropdown, Menu, Card, Divider, Row, Col } from "antd";
import cls from "classnames";
import FileDownload from "js-file-download";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { Microservices } from "../../app/AllowedMicroservices";
import { BASE_URL } from "../../app/apiMiddleware";
import { cognitoUser } from "../../features/auth/authSlice";
import {
  disableCompaniesStatus,
  enableCompaniesStatus,
  fetchCompany,
  hideModal,
  ICompany,
  selectCompany,
  selectDisplayModal,
} from "../../features/company/companySlice";
import {
  fetchPrograms,
  ICourseDict,
  selectProgramsDict
} from "../../features/program/programSlice";
import { CogIcon } from "../common/CogIcon";
import { DialogPopUp } from "../common/DialogPopUp";
import { ProfileLayout } from "../common/Layouts/ProfileLyt";
import { Output } from "../common/Output";
import { Status, StatusEnum } from "../common/Status";
import { getCountryName } from "../Users/utils";
import styles from "./companyProfile.module.scss";
import { getTranslations } from "../../features/translations/translationsUtils";
import { Prefixes } from "../prefixes";

export const MAX_EXTRA_STUDENTS_PERCENT = 20;

interface ProfileProps { }

const renderOptions = (
  company: ICompany,
  dispatch: Dispatch<any>,
  goToPrint: Function,
  history: any
) => {
  const menu = (
    <Menu
      onClick={async (info) => {
        let url;
        if (info.key === "1") {
          goToPrint();
          return;
        } else {
          url = `${BASE_URL[Microservices.COMPANY_SERVICE]}/companies/${company._id
            }/users/report`;
        }
        const response = await fetch(url, {
          method: "GET",
          headers: new Headers({
            Authorization:
              cognitoUser
                ?.getSignInUserSession()
                ?.getAccessToken()
                .getJwtToken() || "",
          }),
        });
        const data = await response.blob();
        const fileName = `${company.name}-Report.xlsx`;
        FileDownload(data, fileName);
      }}
    >
      <Menu.Item key="1">{getTranslations('COMPANY_FILE')}</Menu.Item>
      <Menu.Item key="2">{getTranslations('COMPANY_USERS_REPORT')}</Menu.Item>
    </Menu>
  );

  return (
    <Row style={{ justifyContent: "flex-end" }} >
      <Button
        type="link"
        onClick={() => {
          if (company.status === StatusEnum.Enable) {
            dispatch(disableCompaniesStatus({ ids: [company._id] }));
          } else {
            dispatch(enableCompaniesStatus({ ids: [company._id] }));
          }
        }}
      >
        {company.status === StatusEnum.Enable ? getTranslations('LIST_ENABLE') : getTranslations('LIST_DISABLE')}
      </Button>
      <Button
        onClick={() => {
          history.push(`${Prefixes.company}/${company._id}/edit`)
        }}
      >
        {getTranslations('LIST_EDIT')}
      </Button>
      &nbsp;&nbsp;&nbsp;
      <Dropdown overlay={menu} trigger={["click"]}>
        <Button className={styles.downloadButton} type="primary">
          {getTranslations('COMPANY_DOWNLOAD')} <DownOutlined />
        </Button>
      </Dropdown>
    </Row>
  );
};

const renderLeftColumn = (company: ICompany) => {
  const contact = company.contactInfo || {};
  return (
    <React.Fragment>
      <Row gutter={[16, 40]}>
        <Col span={24}>
          <Card className={cls(styles.card, styles.overload)}>
            <h2 className={cls(styles.title)}>{getTranslations('COMPANY_INFO')}</h2>
            <Output label={getTranslations('COMPANY_NAME')} text={company.name} />
            <Output label={getTranslations('COMPANY_RUC')} text={company.ruc} />
            <Output
              label={getTranslations('COMPANY_MAX_STUDENTS')}
              text={company.maxActiveStudents?.toString()}
            />
            <Output
              label={getTranslations('COMPANY_CREATION_DATE')}
              text={moment.utc(company.createdAt).format("DD-MM-YYYY")}
            />
            <Divider />
            <Output label={getTranslations('COMPANY_ADDRESS')} text={company.address} />
            <Output
              label={getTranslations('COMPANY_COUNTRY')}
              text={getCountryName(company.country) || "---"}
            />
            <Output label={getTranslations('COMPANY_CITY')} text={company.city} />
          </Card>
        </Col>
        <Col span={24}>
          <Card className={cls(styles.card, styles.overload)}>
            <h2 className={cls(styles.title)}>{getTranslations('COMPANY_CONTACT_INFO')}</h2>
            <Output
              label={getTranslations('COMPANY_CONTACT_NAMES')}
              text={`${contact.firstname} ${contact.middlename ?? ""}`}
            />
            <Output
              label={getTranslations('COMPANY_CONTACT_LAST_NAME')}
              text={`${contact.lastname} ${contact.secondLastname ?? ""}`}
            />
            <Output label={getTranslations('COMPANY_JOB')} text={contact.position} />
            <Divider />
            <Output label={getTranslations('COMPANY_CELLPHONE')} text={contact.cellphone} />
            <Output label={getTranslations('COMPANY_EMAIL')} text={contact.email} />
            <Output label={getTranslations('COMPANY_ADDRESS')} text={contact.address} />
            <Output
              label={getTranslations('COMPANY_COUNTRY')}
              text={getCountryName(contact.country) || "---"}
            />
            <Output label={getTranslations('COMPANY_CITY')} text={contact.city} />
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const renderRightColumn = (company: ICompany, programs: ICourseDict) => {
  const { activeUsersCount = 0, inactiveUsersCount = 0 } = company;
  const totalStudentsCount = activeUsersCount + inactiveUsersCount;
  const extraStudents = totalStudentsCount - (company.maxActiveStudents ?? 0);
  const showAlert =
    extraStudents > 0 &&
    (extraStudents / company.maxActiveStudents) * 100 >
    MAX_EXTRA_STUDENTS_PERCENT;
  const notificationBlock = company.notificationTypesEnabled?.email ||
    company.notificationTypesEnabled?.sms ||
    company.notificationTypesEnabled?.web;
  return (
    <>
      <Row gutter={[16, 40]}>
        <Col span={24}>
          <Card className={cls(styles.card, styles.overload)}>
            <h2 className={cls(styles.title)}>{getTranslations('COMPANY_CONTRACT')}</h2>
            <Output
              label={getTranslations('COMPANY_STUDY_PROGRAM')}
              text={programs[company.studyProgram]?.title ?? getTranslations('COMPANY_STUDY_PROGRAM_NOT_DEFINED')}
            />
            <Divider />
            <Output label={getTranslations('COMPANY_ENABLED_NOTIFICATIONS')} text={""}>
              {notificationBlock ?
                <>
                  {company.notificationTypesEnabled?.email ? (
                    <div>
                      <CogIcon icon="Earth" /> <span>{getTranslations('COMPANY_NOTIFICATIONS_EMAIL')}</span>
                    </div>
                  ) : null}
                  {company.notificationTypesEnabled?.web ? (
                    <div>
                      <CogIcon icon="Mail" /> <span>{getTranslations('COMPANY_NOTIFICATIONS_PUSH')}</span>
                    </div>
                  ) : null}
                  {company.notificationTypesEnabled?.sms ? (
                    <div>
                      <CogIcon icon="sms" /> <span>{getTranslations('COMPANY_NOTIFICATIONS_SMS')}</span>
                    </div>
                  ) : null}

                  <Divider />
                </> : getTranslations('COMPANY_NO_NOTIFICATIONS')}
            </Output>
            <Output label={getTranslations('COMPANY_ENABLED_GAMIFICATION')} text={""}>
              {company.showGamification ?
                getTranslations('COMPANY_FEATURE_ENABLED') : getTranslations('COMPANY_FEATURE_DISABLED')}
            </Output>
            <Output label={getTranslations('COMPANY_ENABLED_KNOWLEDGEBASE')} text={""}>
              {company.showKnowledgeBase ?
                getTranslations('COMPANY_FEATURE_ENABLED') : getTranslations('COMPANY_FEATURE_DISABLED')}
            </Output>
            <Output label={getTranslations('COMPANY_ENABLED_XONE')} text={""}>
              {!company.xOneDisabled ?
                getTranslations('COMPANY_FEATURE_ENABLED') : getTranslations('COMPANY_FEATURE_DISABLED')}
            </Output>
            <Output
              label={getTranslations('COMPANY_CONTRACT_START_DATE')}
              text={
                company.contractDate
                  ? moment(company.contractDate).format("DD/MM/YYYY")
                  : "---"
              }
            />
            <Divider />
            <Output label={getTranslations('COMPANY_STATUS')} text={""}>
              <div style={{ paddingTop: '1rem' }}>
                <Status
                  status={
                    company.status ? StatusEnum.Enable : StatusEnum.Disable
                  }
                ></Status>
              </div>
            </Output>
          </Card>
          <div style={{ marginTop: 10 }}>
            {showAlert ? (
              <Alert
                className={styles.alertError}
                message={
                  <span className={styles.message}>
                    <CogIcon className={styles.icon} color="red" icon="Attention" />
                    {getTranslations('COMPANY_20_PERCENT_STUDENTS')}
                  </span>
                }
                type="error"
              />
            ) : null}
          </div>
        </Col>
      </Row>
    </>
  );
};

interface RouteParams {
  id: string;
}

export const CompanyProfile: FunctionComponent<ProfileProps> = () => {
  const [currentCompany, setCurrentCompany] = useState<ICompany>();
  const dispatch = useDispatch();
  const { id } = useParams<RouteParams>();
  const programs = useSelector(selectProgramsDict);
  const companyFromStore = useSelector(selectCompany(id));
  const isModalVisible = useSelector(selectDisplayModal);
  const history = useHistory();
  useEffect(() => {
    if (companyFromStore) {
      setCurrentCompany(companyFromStore);
    }
  }, [companyFromStore, dispatch]);

  useEffect(() => {
    dispatch(fetchCompany(id));
    dispatch(fetchPrograms());
  }, [dispatch, id]);

  const goToProfilePrint = () => {
    history.push(`/companies/${id}/print`);
  };

  return (
    <ProfileLayout
      image={currentCompany?.logo}
      rightTopOptions={renderOptions(
        Object.assign({}, currentCompany),
        dispatch,
        goToProfilePrint,
        history,
      )}
      leftBottomOptions={renderLeftColumn(Object.assign({}, currentCompany))}
      rightBottomOptions={renderRightColumn(
        Object.assign({}, currentCompany),
        programs
      )}
    >
      <DialogPopUp
        name={getTranslations('LIST_COMPANY')}
        action="actualizada"
        visible={isModalVisible}
        onCancel={() => {
          dispatch(hideModal());
        }}
      />
    </ProfileLayout>
  );
};
