import React, { ReactElement } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Upload, message } from 'antd';
import { RcFile } from 'antd/es/upload/interface';
import ctx from 'classnames';

import { cognitoUser } from '../../../features/auth/authSlice';
import { Microservices } from '../../../app/AllowedMicroservices';
import { BASE_URL } from '../../../app/apiMiddleware';

import uploadIcon from '../../../assets/admin/upload.svg';

import styles from './index.module.scss';
import { CogIcon } from '../../common/CogIcon';
import { getTranslations } from '../../../features/translations/translationsUtils';

interface UploadButtonProps {
    loading?: boolean;
    image: ReactElement | boolean;
    description: JSX.Element;
}

interface UploadImageProps {
    loading?: boolean;
    onHandleChange: any;
    imageUrl?: string;
    description: JSX.Element;
    allowedFileTypes: string[];
    errorMessage: string;
    maxWidth: number;
    maxHeight: number;
}


const beforeUpload = (allowedFileTypes: string[], maxWidth: number, maxHeight: number, errorMessage: string) => (file: RcFile) => {
    return new Promise<boolean>((resolve) => {
        const isValidType = allowedFileTypes.includes(file.type);
        if (!isValidType) {
            message.error(errorMessage);
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Imagen no puede ser mayor a 2MB!');
        }
        const img = new Image();
        var objectUrl = URL.createObjectURL(file);
        img.onload = function () {
            // @ts-ignore
            const width = this.width;
            // @ts-ignore
            const height = this.height;
            URL.revokeObjectURL(objectUrl);
            const isValidSize = width === maxWidth && height === maxHeight;
            if (!isValidSize) {
                message.error(`Imagen no debe ser de ${maxWidth}x${maxHeight}!`);
            }
            return resolve(isValidType && isLt2M && isValidSize);
        };
        img.src = objectUrl;
    });
}

const UploadButton: React.FunctionComponent<UploadButtonProps> = (props) => {
    const { loading = false, image, description } = props;
    return <div className={styles.uploadButton}>
        <div className={ctx(styles.image, { [styles.loadedImage]: !!image })}>
            {loading ? <LoadingOutlined /> : (image ? image : <img src={uploadIcon} alt="upload icon" />)}
        </div>
        <div className={styles.secondColumn}>
            <div>
                <Button type='primary'><CogIcon color='white' size={14} className={styles.buttonIcon} icon='Backup' />{getTranslations('CUSTOMIZE_UPLOAD')}</Button>
            </div>
            <div className={styles.fileTypes}>
                {description}
            </div>
        </div>
    </div>;
};

export const UploadImage: React.FunctionComponent<UploadImageProps> = (props) => {
    const {
        imageUrl,
        loading,
        onHandleChange, 
        description, 
        allowedFileTypes, 
        errorMessage,
        maxHeight,
        maxWidth
    } = props;
    return <Upload
        headers={{
            Authorization: cognitoUser?.getSignInUserSession()?.getAccessToken().getJwtToken() || '',
        }}
        method="POST"
        name="file"
        listType="picture-card"
        className={styles.uploadImage}
        showUploadList={false}
        action={`${BASE_URL[Microservices.FILES_SERVICE]}/files/uploadToS3`}
        beforeUpload={beforeUpload(allowedFileTypes, maxWidth, maxHeight, errorMessage)}
        onChange={onHandleChange}
    >
        <UploadButton description={description} image={imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : false} loading={loading} />
    </Upload>;
};