import { createSlice } from "@reduxjs/toolkit";
import { CognitoIdToken } from "amazon-cognito-identity-js";
import { message } from "antd";
import { RootState } from "../../app/store";
import { logoutFromCognito } from "../auth/authSlice";
import { UsersGroups } from "../users/usersSlice";

export enum UserModes {
  STUDENT = "STUDENT",
  ADMIN = "ADMIN",
  UNSET = "UNSET",
}

interface UserModeState {
  mode: UserModes;
}

const initialState: UserModeState = {
  mode: UserModes.UNSET,
};

export const userModeSlice = createSlice({
  name: "userMode",
  initialState,
  reducers: {
    changeToStudent: (state) => {
      state.mode = UserModes.STUDENT;
    },
    changeToAdmin: (state) => {
      state.mode = UserModes.ADMIN;
    },
  },
});

export const setUserMode = (cognitoData: CognitoIdToken | null) => {
  const groups: string[] = cognitoData?.payload["cognito:groups"];
  if (!groups) {
    message.error("Hay un error con el usuario, contacte soporte tecnico");
    return logoutFromCognito();
  }
  if (groups.includes(UsersGroups.Students)) {
    return changeToStudent();
  }
  return changeToAdmin();
};

export const { changeToStudent, changeToAdmin } = userModeSlice.actions;

export const selectUserMode = (state: RootState) => state.userMode.mode;
export const selectUserStudent = (state: RootState) =>
  state.userMode.mode === UserModes.STUDENT;
export const selectUserAdmin = (state: RootState) =>
  state.userMode.mode === UserModes.ADMIN;

export default userModeSlice.reducer;
