import React from "react";

import {
  Alert,
  Button,
  Dropdown,
  Menu,
} from "antd";
import cls from "classnames";
import { useSelector } from "react-redux";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import { selectCompanyId, selectUserRole } from '../../features/auth/authSlice';
import {
  ILesson,
  MediaTitles,
  selectLessons,
  selectLessonsDict,
} from "../../features/lesson/lessonSlice";
import { ITopic } from "../../features/topic/topicSlice";
import { UserRole } from '../../features/users/usersSlice';
import { CogIcon } from "../common/CogIcon";
import { StatusEnum } from "../common/Status";
import { StepProps } from "./interfaces";

import styles from "./form.module.scss";
import formStyles from "../common/form.module.scss";
import { getTranslations } from "../../features/translations/translationsUtils";

const renderLessonCard = (
  lesson: ILesson,
  setNewTopic: Function,
  newTopic: ITopic | undefined,
  lessonIndex: number,
) => {

  if (!lesson) {
    return null;
  }
  return <div
    key={lesson._id}
    className={cls(styles.cardContainer, styles.extendedStyle, formStyles.section)}
  >
    <div className={formStyles.header}>
      <div className={formStyles.pillNumber}>
        <CogIcon size={18} icon="drag" /><span className={formStyles.number}>{lessonIndex + 1}</span>
      </div>
      <div className={formStyles.actionContainer}>
        <Button
          className={formStyles.action}
          onClick={(event) => {
            const currentTopicLessonsIds = newTopic
              ? (newTopic?.lessons as string[])
              : [];
            const currentLessonsIds = currentTopicLessonsIds.filter(
              (lessonId) => lessonId !== lesson._id
            );
            setNewTopic({ ...newTopic, lessons: [...currentLessonsIds] });
            return false;
          }}
        >
          <CogIcon size={18} icon="Cross" />
        </Button>
      </div>
    </div>

    <div className={styles.title}>{lesson.title}</div>
    {lesson.media && lesson.media[0] ? <div className={styles.type}>{MediaTitles()[lesson.media[0].type]}</div> : null}
    {lesson.evaluation ? <div className={styles.evaluation}><CogIcon className={styles.icon} color='#2A2A2B' icon="test" />{getTranslations('TOPIC_QUIZ')}</div> : null}
  </div>
};

const SortableItem = SortableElement(({ value }: any) => (
  <li style={{ zIndex: 99999999, listStyle: "none" }}>{value}</li>
));

const SortableList = SortableContainer(({ items, keys }: any) => {
  return (
    <ul className={styles.sortablelist}>
      {items.map((value: any, index: number) => (
        <SortableItem key={`item-${keys[index]}`} index={index} value={value} />
      ))}
    </ul>
  );
});

const renderSelectedLessons = (
  setNewTopic: Function,
  newTopic: ITopic | undefined,
  lessons: ILesson[] = [],
) => {
  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    const newLessons = lessons.map((lesson) => lesson);
    const lesson = lessons[oldIndex];
    newLessons.splice(oldIndex, 1);
    newLessons.splice(newIndex, 0, lesson);
    setNewTopic({
      ...newTopic,
      lessons: newLessons.map((lesson) => lesson._id),
    });
  };
  return (
    <div className={styles.cardsContainer}>
      <SortableList
        lockAxis="y"
        keys={lessons.map((lesson) => lesson?._id)}
        items={lessons.map((lesson, lessonIndex) =>
          renderLessonCard(lesson, setNewTopic, newTopic, lessonIndex)
        )}
        onSortEnd={onSortEnd}
      />
    </div>
  );
};

export const FirstStep2: React.FC<StepProps> = ({
  newTopic,
  setNewTopic,
  categories,
  categoriesIds,
}) => {
  const lessonDict = useSelector(selectLessonsDict);
  const lessonList = useSelector(selectLessons);
  const userRole = useSelector(selectUserRole);
  const companyId = useSelector(selectCompanyId);
  const currentLessonsIds =
    newTopic?.lessons ? [...(newTopic?.lessons as any)] : [];
  const currentLessons = currentLessonsIds.map(
    (lessonId) => lessonDict[lessonId]
  );
  const lessonListWOutCurrentWOutDisabled = lessonList.filter((lesson) => {
    return (
      !currentLessonsIds.includes(lesson._id) &&
      lesson.status === StatusEnum.Enable
    );
  });

  const lessonByTitleComparator = (a: ILesson, b: ILesson) => {
    const aName = a.title.toLowerCase();
    const bName = b.title.toLowerCase();

    if (aName < bName) {
      return -1;
    }
    if (aName > bName) {
      return 1;
    }
    return 0;
  };
  const menuItems = lessonListWOutCurrentWOutDisabled
    .filter(lesson => {
      const isSuperAdmin = userRole === UserRole.SuperAdmin;
      const topicCompanyId = isSuperAdmin ? (!newTopic?.companyId ? undefined : newTopic?.companyId) : companyId;
      const lessonFromCompany = lesson.companyId === topicCompanyId;
      return isSuperAdmin || lessonFromCompany;
    })

    .sort(lessonByTitleComparator)
    .map((lesson, index) => {
      return (
        <Menu.Item key={index}>
          <p
            onClick={() => {
              setNewTopic({
                ...newTopic,
                lessons: [...currentLessonsIds, lesson._id],
              });
            }}
          >
            {lesson.title}
          </p>
        </Menu.Item>
      );
    });
  const hasLessonsAvailable = menuItems.length > 0;
  const menu = (
    <Menu>
      {menuItems}
    </Menu>
  );

  return (
    <React.Fragment>
      {renderSelectedLessons(setNewTopic, newTopic, currentLessons)}
      {hasLessonsAvailable ? <Dropdown
        getPopupContainer={() =>
          document.getElementById("lc-div") as HTMLElement
        }
        overlay={menu}
        trigger={["click"]}
        disabled={menuItems.length === 0}
      >
        <p
          className="ant-dropdown-link"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <Button disabled={menuItems.length === 0} className={cls(styles.addLessonButton, formStyles.addNewButton)} type="default">
            {getTranslations('TOPIC_ADD_LESSON')} <CogIcon className={formStyles.square} color="var(--secondary-text-button-color)" icon="Arrow-drop-down" />
          </Button>
        </p>
      </Dropdown> : <Alert message={getTranslations('TOPIC_CREATE_MESSAGE')} type="info" />}
      <div id={"lc-div"}></div>
    </React.Fragment>
  );
};
