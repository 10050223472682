import React, { FunctionComponent, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Select } from 'antd';
import cls from 'classnames';

import { selectCampaigns } from '../../../../features/students/campaignSlice';
import { CampaignBlock } from './CampaingBlock';
import { Survey } from '../../Evaluation/Survey';
import { IAnswer } from '../../../../features/evaluation/common/interfaces';
import { analytics } from '../../../../services/analytics';
import { ParentTypeEnum } from '../../../../features/evaluation/common/enums';
import { useDeviceSize } from '../../../utils/useDeviceSize';
import { addSurveyTaken, fetchSurveysTaken, selectSurveysTakenFetching } from '../../../../features/students/surveysTakenSlice';

import styles from './campaignsBlock.module.scss';
import { SIZES } from '../../../common/screen';
import { ICampaign } from '../../../../features/campaign/campaignSlice';

interface ISurveyVisiblePerCampaign {
  [id: string]: boolean
}

const handleCompletePromoAnalyticsEvent = (surveyAnswers: IAnswer[], startTime: moment.Moment) => {
  setTimeout(() => {
    const timeDiffSecs = moment().diff(startTime, 'seconds');
    analytics.completePromoBanner({
      totalTimeSecs: timeDiffSecs,
      answers: surveyAnswers,
    });
  }, 200);
}

enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export const CampaignsBlock: FunctionComponent = () => {
  const [order, setOrder] = useState(SortOrder.DESC);
  const [surveyVisible, setSurveyVisible] = useState<ISurveyVisiblePerCampaign>({});
  const [startTime, setStartTime] = useState<moment.Moment>(moment());
  const [surveyAnswers, setSurveyAnswers] = useState<IAnswer[]>([]);
  const [allCampaignsSorted, setAllCampaignsSorted] = useState<ICampaign[]>([]);
  const allCampaigns = useSelector(selectCampaigns);
  const isResultFetching = useSelector(selectSurveysTakenFetching());
  const size = useDeviceSize();
  const dispatch = useDispatch();
  const isMobile = size === SIZES.PHONE;

  useEffect(() => {
    if (allCampaigns && allCampaigns.length > 0) {
      dispatch(fetchSurveysTaken(allCampaigns.filter(campaign => campaign.survey).map((campaign) => {
        return {
          parentId: campaign._id,
          evaluationId: campaign.survey,
        };
      })));
    }
  }, [allCampaigns, dispatch]);

  useEffect(() => {
    if (allCampaigns && allCampaigns.length > 0) {
      const sorted = [...allCampaigns].sort((a, b) => {
        if (order === SortOrder.ASC) {
          return moment(a.fromDate).diff(moment(b.fromDate));
        }
        return moment(b.fromDate).diff(moment(a.fromDate));
      });
      setAllCampaignsSorted(sorted);
    }
  }, [allCampaigns, order]);

  const onSurveyCompleted = () => {
    setSurveyVisible({});
  };

  const cancelSurvey = () => {
    setSurveyVisible({});
  };

  if (isResultFetching) {
    return null;
  }

  return <div className={cls(styles.container, {
    [styles.mobile]: isMobile,
  })}>
    <div className={styles.header}>
      <div className={styles.sortBy}>Ordenar por</div>
      <div className={styles.sortByControl}>
        <Select
          className={styles.select}
          defaultValue={order}
          style={{ width: 110 }}
          bordered={false}
          onChange={setOrder}
          options={[
            {
              value: SortOrder.DESC,
              label: 'Recientes',
            },
            {
              value: SortOrder.ASC,
              label: 'Antiguos',
            },
          ]}
        />
      </div>
      <div className={styles.line} />
    </div>
    <div className={styles.campaigns}>
      {[...allCampaignsSorted].map((campaign) => {
        return <React.Fragment key={campaign._id}>
          <CampaignBlock
            setSurveyVisible={() => {
              setSurveyVisible({ ...surveyVisible, [campaign._id]: true })
            }}
            setStartTime={setStartTime}
            campaign={campaign}

          />
          {surveyVisible[campaign._id] && campaign.survey && (
            <Survey
              courseId=''
              parentId={campaign._id}
              surveyId={campaign.survey}
              setSurveyAnswers={setSurveyAnswers}
              onSurveyComplete={() => {
                handleCompletePromoAnalyticsEvent(surveyAnswers, startTime);
                onSurveyCompleted();
                dispatch(addSurveyTaken(campaign._id));
              }}
              parentType={ParentTypeEnum.Campaign}
              onCancelSurvey={cancelSurvey}
            />
          )}
        </React.Fragment>;
      })}
    </div>
  </div>;
};