import React, { FunctionComponent, useEffect, useState } from "react";

import { Alert, Button, DatePicker, Modal } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import ctx from 'classnames';

import { getCognitoUserData } from "../../features/auth/authSlice";
import { selectCompany } from "../../features/company/companySlice";
import { fetchGroupsByCompany } from "../../features/groups/groupSlice";
import {
  hideModal,
  IToData,
  NotificationsStatusEnum,
  selectDisplayModal,
  selectNotificationsStatus,
  sendNotifications,
  sendNotificationsSchedule,
} from "../../features/notifications/notificationsSlice";
import {
  fetchStudentsByCompany,
  selectStudents,
  UserRole,
} from "../../features/users/usersSlice";
import { DialogPopUp } from "../common/DialogPopUp";
import { TargetType, ViewProps } from "./common";
import { Email } from "./Email";
import { Push } from "./Push";
import { ScheduledNotifications } from "./ScheduledNotifications";
import { SMS } from "./SMS";
import { Targets } from "./Targets";

import styles from "./manager.module.scss";
import { getTranslations } from "../../features/translations/translationsUtils";

interface ManagerProp {
  companyId: string | null;
  openScheduledNotifications: boolean;
  closeScheduledNotifications: () => void;
}

export enum NotificationsTypes {
  SMS = "SMS",
  EMAIL = "EMAIL",
  PUSH = "PUSH",
}
export const NotificationsTypesLabels = () => ({
  [NotificationsTypes.SMS]: getTranslations('NOTIFICATION_SMS_LABEL'),
  [NotificationsTypes.EMAIL]: getTranslations('NOTIFICATION_EMAIL_LABEL'),
  [NotificationsTypes.PUSH]: getTranslations('NOTIFICATION_PUSH_LABEL'),
});
const VIEWS_TITLE_LIMITS = {
  [NotificationsTypes.SMS]: 50,
  [NotificationsTypes.EMAIL]: 50,
  [NotificationsTypes.PUSH]: 50,
};

const VIEWS_MESSAGE_LIMITS = {
  [NotificationsTypes.SMS]: 150,
  [NotificationsTypes.EMAIL]: 500,
  [NotificationsTypes.PUSH]: 150,
};

const OptionsWithDisabled: () => {
  [_id: string]: any;
} = () => ({
  [NotificationsTypes.SMS]: {
    label: getTranslations('NOTIFICATION_SMS_LABEL_SHORT'),
    value: NotificationsTypes.SMS,
    disabled: false,
  },
  [NotificationsTypes.EMAIL]: {
    label: getTranslations('NOTIFICATION_EMAIL_LABEL_SHORT'),
    value: NotificationsTypes.EMAIL,
    disabled: false,
  },
  [NotificationsTypes.PUSH]: {
    label: getTranslations('NOTIFICATION_PUSH_LABEL_SHORT'),
    value: NotificationsTypes.PUSH,
    disabled: false,
  },
});

const views: { [_id: string]: React.FunctionComponent<ViewProps> } = {
  [NotificationsTypes.SMS.toString()]: SMS,
  [NotificationsTypes.PUSH.toString()]: Push,
  [NotificationsTypes.EMAIL.toString()]: Email,
};

export const Manager: FunctionComponent<ManagerProp> = ({
  companyId,
  closeScheduledNotifications,
  openScheduledNotifications,
}) => {
  const SCHEDULED = getTranslations('NOTIFICATION_SCHEDULED');
  const SENT = getTranslations('NOTIFICATION_SENT');
  const [dateTime, setDateTime] = useState<moment.Moment | null>(null);
  const [openScheduleModal, setOpenScheduleModal] = useState<boolean>(false);
  const [targetType, setTargetType] = useState<TargetType>(TargetType.groups);
  const [blocked, setBlocked] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [view, setView] = useState<NotificationsTypes>(NotificationsTypes.SMS);
  const [targets, setTargets] = useState<string[]>([]);
  const [action, setAction] = useState<string>(SENT);
  const company = useSelector(selectCompany(companyId));
  const notificationsStatus = useSelector(selectNotificationsStatus);
  const students = useSelector(selectStudents);
  const isModalVisible = useSelector(selectDisplayModal);
  const cognitoData = useSelector(getCognitoUserData);
  const groupId = cognitoData ? cognitoData["custom:group"] : undefined;
  const role = cognitoData ? cognitoData["custom:role"] : undefined;
  const View = views[view];
  const dispatch = useDispatch();

  useEffect(() => {
    if (!company) {
      return;
    }
    Object.keys(OptionsWithDisabled()).forEach((view) => {
      OptionsWithDisabled()[view].disabled = true;
    });
    const availableViews: NotificationsTypes[] = [];
    if (company.notificationTypesEnabled?.sms) {
      OptionsWithDisabled()[NotificationsTypes.SMS].disabled = false;
      availableViews.push(NotificationsTypes.SMS);
    }
    if (company.notificationTypesEnabled?.email) {
      OptionsWithDisabled()[NotificationsTypes.EMAIL].disabled = false;
      availableViews.push(NotificationsTypes.EMAIL);
    }
    if (company.notificationTypesEnabled?.web) {
      OptionsWithDisabled()[NotificationsTypes.PUSH].disabled = false;
      availableViews.push(NotificationsTypes.PUSH);
    }
    if (availableViews.length === 0) {
      setBlocked(true);
      return;
    }
    setBlocked(false);
    if (availableViews.includes(view)) {
      return;
    }
    setView(availableViews[0]);
  }, [company, view]);

  useEffect(() => {
    if (companyId) {
      dispatch(fetchStudentsByCompany(companyId));
      dispatch(fetchGroupsByCompany(companyId));
    }
  }, [companyId, dispatch]);

  useEffect(() => {
    setTitle((currentTitle) =>
      currentTitle.substring(0, VIEWS_TITLE_LIMITS[view])
    );
    setMessage((currentMessage) =>
      currentMessage.substring(0, VIEWS_MESSAGE_LIMITS[view])
    );
  }, [view]);

  if (blocked) {
    return (
      <div className={styles.noNotificationMethods}>
        <Alert
          message={getTranslations('NOTIFICATION_COMPANY_NO_NOTIFICATION')}
          type="info"
          showIcon
        />
      </div>
    );
  }
  const getToData = () => {
    const to: IToData[] = [];
    if (targetType === TargetType.groups) {
      Object.values(role === UserRole.Supervisor
        ? Object.values(students).filter(
          (student) => student.studentGroupId === groupId
        )
        : students).forEach((student) => {
          if (targets.includes(student.studentGroupId || "")) {
            switch (view) {
              case NotificationsTypes.EMAIL:
                to.push({
                  companyId: companyId || "",
                  username: student.username,
                  to: student.email,
                });
                break;
              case NotificationsTypes.SMS:
                to.push({
                  companyId: companyId || "",
                  username: student.username,
                  to: student.phone_number,
                });
                break;
              case NotificationsTypes.PUSH:
                to.push({
                  companyId: companyId || "",
                  username: student.username,
                });
                break;
            }
          }
        });
    } else {
      Object.values(role === UserRole.Supervisor
        ? Object.values(students).filter(
          (student) => student.studentGroupId === groupId
        )
        : students)
        .filter((student) => targets.includes(student.username))
        .forEach((student) => {
          switch (view) {
            case NotificationsTypes.EMAIL:
              to.push({
                companyId: companyId || "",
                username: student.username,
                to: student.email,
              });
              break;
            case NotificationsTypes.SMS:
              to.push({
                companyId: companyId || "",
                username: student.username,
                to: student.phone_number,
              });
              break;
            case NotificationsTypes.PUSH:
              to.push({
                companyId: companyId || "",
                username: student.username,
              });
              break;
          }
        });
    }
    return to;
  };
  const schedule = () => {
    if (!dateTime) {
      return;
    }
    setAction(SCHEDULED);
    const utcDateTime = moment.utc(dateTime).format();
    const to: IToData[] = getToData();
    dispatch(
      sendNotificationsSchedule({
        message,
        title,
        to,
        type: view,
        date: utcDateTime,
      })
    );
    setOpenScheduleModal(false);
    resetForm();
  };
  const sendNow = () => {
    setAction(SENT);
    const to: IToData[] = getToData();
    dispatch(
      sendNotifications({
        message,
        title,
        to,
        type: view,
      })
    );
    resetForm();
  };
  const disabled =
    targets.length === 0 ||
    message.length === 0 ||
    (title.length === 0 && view !== NotificationsTypes.SMS);

  const resetForm = () => {
    setTargets([]);
    setMessage("");
    setTitle("");
  };

  return (
    <div className={styles.container}>
      <DialogPopUp
        name={getTranslations('NOTIFICATION_TITLE_SINGULAR')}
        action={action}
        visible={isModalVisible}
        onCancel={() => {
          dispatch(hideModal());
        }}
      />
      <div className={styles.selectorContainer}>
        <div
          className={ctx(styles.selector, {
            [styles.selected]: view === NotificationsTypes.SMS,
            [styles.disabled]: OptionsWithDisabled()[NotificationsTypes.SMS].disabled
          })}
          onClick={() => {
            if (OptionsWithDisabled()[NotificationsTypes.SMS].disabled) {
              return;
            }
            setView(NotificationsTypes.SMS);
          }
          }>
          {getTranslations('NOTIFICATION_SMS_TAB')}
        </div>
        <div
          className={ctx(styles.selector, {
            [styles.selected]: view === NotificationsTypes.EMAIL,
            [styles.disabled]: OptionsWithDisabled()[NotificationsTypes.EMAIL].disabled
          })}
          onClick={() => {
            if (OptionsWithDisabled()[NotificationsTypes.EMAIL].disabled) {
              return;
            }
            setView(NotificationsTypes.EMAIL);
          }
          }>
          {getTranslations('NOTIFICATION_EMAIL_TAB')}
        </div>
        <div
          className={ctx(styles.selector, {
            [styles.selected]: view === NotificationsTypes.PUSH,
            [styles.disabled]: OptionsWithDisabled()[NotificationsTypes.PUSH].disabled
          })}
          onClick={() => {
            if (OptionsWithDisabled()[NotificationsTypes.PUSH].disabled) {
              return;
            }
            setView(NotificationsTypes.PUSH);
          }
          }>
          {getTranslations('NOTIFICATION_PUSH_TAB')}
        </div>
      </div>
      <View
        maxMessageCount={VIEWS_MESSAGE_LIMITS[view]}
        message={message}
        setMessage={(message) => {
          setMessage(message.substring(0, VIEWS_MESSAGE_LIMITS[view]));
        }}
        title={title}
        setTitle={(title) => {
          setTitle(title.substring(0, VIEWS_TITLE_LIMITS[view]));
        }}
        targets={targets}
      />
      <Targets
        setTargets={(targets) => {
          setTargets(targets);
        }}
        targets={targets}
        targetType={targetType}
        setTargetType={setTargetType}
        companyName={company?.name || ''}
      />
      <div className={styles.actions}>
        <Button
          loading={notificationsStatus === NotificationsStatusEnum.SENDING_NOW}
          onClick={sendNow}
          disabled={
            disabled ||
            notificationsStatus === NotificationsStatusEnum.SENDING_SCHEDULE
          }
          className={styles.button}
          type="default"
        >
          {getTranslations('NOTIFICATION_SEND_NOW')}
        </Button>
        <Button
          loading={
            notificationsStatus === NotificationsStatusEnum.SENDING_SCHEDULE
          }
          onClick={() => {
            setOpenScheduleModal(true);
          }}
          disabled={
            disabled ||
            notificationsStatus === NotificationsStatusEnum.SENDING_NOW
          }
          className={styles.button}
          type="primary"
        >
          {getTranslations('NOTIFICATION_SCHEDULE_SEND')}
        </Button>
      </div>
      <Modal
        className={styles.scheduleModal}
        visible={openScheduleModal}
        onOk={schedule}
        onCancel={() => {
          setOpenScheduleModal(false);
        }}
        okButtonProps={{
          disabled: !dateTime || (dateTime && dateTime < moment()),
        }}
      >
        <div className={styles.title}>{getTranslations('NOTIFICATION_CHOOSE_DATE_TIME')}</div>
        <div className={styles.dateTimeContainer}>
          <div className={styles.date}>
            <DatePicker
              value={dateTime}
              placeholder={getTranslations('NOTIFICATION_DATE_TIME')}
              format="YYYY-MM-DD HH:mm:ss"
              showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
              disabledDate={(current) => {
                // Can not select days before today
                return current && current < moment().startOf("day");
              }}
              onChange={(value) => {
                setDateTime(value);
              }}
            />
          </div>
        </div>
      </Modal>
      {companyId && openScheduledNotifications && (
        <ScheduledNotifications
          close={closeScheduledNotifications}
          companyId={companyId}
        />
      )}
    </div>
  );
};
