import React, { FunctionComponent, useEffect, useState } from "react";

import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchContent,
  hideModal,
  selectDisplayModal,
  selectJustCreatedContent,
} from "../../features/knowledgeBase/contentSlice";
import { AfterActions, DialogPopUp } from "../common/DialogPopUp";
import { MainLayout } from "../common/Layouts/Main";
import { ContentForm } from "./Form";
import { analytics } from '../../services/analytics';
import { getCognitoUserData } from '../../features/auth/authSlice';
import {
    selectCompanies,
    fetchCompanies,
} from '../../features/company/companySlice';
import { useHistory, useRouteMatch } from "react-router-dom";
import { ActionSuffixes, Prefixes } from "../prefixes";
import { getTranslations } from "../../features/translations/translationsUtils";

export const EditKnowledgeBase: FunctionComponent = () => {
  const [afterAction, setAfterAction] = useState<AfterActions>();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState<boolean>(false);
  const isModalVisible = useSelector(selectDisplayModal);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const selectedContent:string = (match.params as any).id!;
  const history = useHistory();

  useEffect(() => {
    if (!loaded) {
      dispatch(fetchContent(selectedContent));
      setLoaded(true);
    }
  }, [selectedContent, dispatch, loaded]);

  if (!loaded) {
    return null;
  }
  
  return (
    <MainLayout
      title={getTranslations('KNOWLEDGE_BASE_EDIT')}
      onGoBack={() => {
        history.push(`${Prefixes.knowledgeBase}`);
      }}
      breadcrumbs={[getTranslations('KNOWLEDGE_BASE_TITLE'), getTranslations('KNOWLEDGE_BASE_EDIT_SHORT')]}
    >
      <DialogPopUp
        name={getTranslations('KNOWLEDGE_BASE_EXCELLENT')}
        action={getTranslations('KNOWLEDGE_BASE_SUCCESSFUL_CREATION')}
        visible={isModalVisible}
        onCancel={() => {
          dispatch(hideModal());
          if (afterAction === AfterActions.BACK) {
            history.push(`${Prefixes.knowledgeBase}`);
            return;
          }
          history.push(`${Prefixes.knowledgeBase}${ActionSuffixes.new}`);
        }}
      />
      <AnalyticsKnowledgeBaseCreated />
      <ContentForm
        editing={selectedContent}
        form={form}
        onDone={(afterAction: AfterActions) => {
          setAfterAction(afterAction);
        }}
      />
    </MainLayout>
  );
};

interface AnalyticsKBCreatedProps { }
const AnalyticsKnowledgeBaseCreated: React.FunctionComponent<AnalyticsKBCreatedProps> = () => {
    const createdKB = useSelector(selectJustCreatedContent);
    const cognitoData = useSelector(getCognitoUserData);
    const cognitoUsername = cognitoData ? cognitoData['cognito:username'] : null;
    const companiesList = useSelector(selectCompanies);
    const dispatch = useDispatch();
    useEffect(() => {
        if (createdKB) {
            analytics.createKnowledgebase({
                userName: cognitoUsername,
                companyName: companiesList[createdKB.companyId]?.name,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createdKB]);
    useEffect(() => {
        dispatch(fetchCompanies());
    }, [dispatch]);
    return null;
}
