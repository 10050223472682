import { LoadingOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FetchStatus } from "../../../features/common/enums";
import {
  fetchContentCategories,
  selectAreContentCategoriesFetching,
  selectContentCategoriesDict,
} from "../../../features/contentCategory/contentCategorySlice";
import {
  fetchKnowledgeBase,
  selectIsKnowledgeBaseFetching,
  selectKnowledgeBase,
  selectKnowledgeBaseDict,
  selectKnowledgeBaseFetchStatus,
  selectKnowledgeBasesIds,
} from "../../../features/students/knowledgeBaseSlice";
import { CogIcon } from "../../common/CogIcon";
import { StudentMainLayout } from "../../common/Layouts/StudentMainLayout";
import { SIZES } from "../../common/screen";
import { useDeviceSize } from "../../utils/useDeviceSize";
import styles from "./knowledgeBaseDetails.module.scss";
import {
  IContent
} from '../../../features/students/knowledgeBaseSlice';
import { analytics } from '../../../services/analytics';
import { PDFViewer } from "../Lesson/PDFViewer";
import { getTranslations } from "../../../features/translations/translationsUtils";

interface KnowledgeBaseDetailsProps { }

interface RouteParams {
  contentId: string;
}

const RELATED_CONTENT_MAX = 3;

const shuffle = (array: number[]) => {
  // https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
  let currentIndex: number = array.length;
  let temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

export const KnowledgeBaseDetails: React.FC<KnowledgeBaseDetailsProps> = () => {
  const dispatch = useDispatch();
  const { contentId } = useParams<RouteParams>();
  const size = useDeviceSize();
  const [layoutSpan, setLayoutSpan] = useState<number>(14);
  const analyticsRef = useRef<AnalyticsViewKBRef>(null);
  const categoriesIsFetching = useSelector(selectAreContentCategoriesFetching);
  const isKbFetching = useSelector(selectIsKnowledgeBaseFetching);
  const dataIsFetching: boolean = categoriesIsFetching || isKbFetching;
  const [analyticsTriggered, setAnalyticsTriggered] = useState<boolean>(false);
  const prevkBRef = useRef<IContent>();

  useEffect(() => {
    dispatch(fetchContentCategories());
    dispatch(fetchKnowledgeBase(contentId));
  }, [dispatch, contentId]);

  useEffect(() => {
    if (size === SIZES.PHONE) {
      setLayoutSpan(24);
      return;
    }
    if (size === SIZES.TABLET) {
      setLayoutSpan(21);
      return;
    }
  }, [size]);



  const knowledgeBase = useSelector(selectKnowledgeBase(contentId));
  const status = useSelector(selectKnowledgeBaseFetchStatus);

  useEffect(() => {
    prevkBRef.current = knowledgeBase;
  });
  const contentCategoryDict = useSelector(selectContentCategoriesDict);
  const { category: categoryId } = knowledgeBase || {}
  const category = contentCategoryDict[categoryId];

  useEffect(() => {
    if (
      analyticsTriggered === true ||
      dataIsFetching ||
      Object.keys(knowledgeBase || {}).length === 0 ||
      Object.keys(category || {}).length === 0
    ) {
      return;
    }
    analyticsRef?.current?.trigger();
    setAnalyticsTriggered(true);
  }, [dataIsFetching, category, knowledgeBase, analyticsTriggered])

  const knowledgeBaseIds = useSelector(selectKnowledgeBasesIds);
  const knowledgeBaseDict = useSelector(selectKnowledgeBaseDict);

  const maxItems =
    knowledgeBaseIds.length >= RELATED_CONTENT_MAX
      ? RELATED_CONTENT_MAX
      : knowledgeBaseIds.length;
  let allAvailableIndices = Array.from(Array(knowledgeBaseIds.length).keys());
  shuffle(allAvailableIndices);

  const knowledgeBaseItems = [];
  for (let i = 0; i < maxItems; i++) {
    const kb = knowledgeBaseDict[knowledgeBaseIds[allAvailableIndices[i]]];
    if (kb) {
      const category = contentCategoryDict[kb.category];
      knowledgeBaseItems.push({
        ...kb,
        categoryName: category ? category.name : "",
      });
    }
  }

  if (!knowledgeBase && status === FetchStatus.Fetched) {
    return (
      <StudentMainLayout>
        <Row>
          <Col span={layoutSpan} className={styles.wrapper}>
            <div>{getTranslations('KNOWLEDGE_NO_RESULTS')}</div>
          </Col>
        </Row>
      </StudentMainLayout>
    );
  }

  if (status === FetchStatus.Fetching) {
    return (
      <StudentMainLayout>
        <Row>
          <Col span={layoutSpan} className={styles.wrapper}>
            <LoadingOutlined spin style={{ fontSize: 44, color: "#4376F9" }} />
          </Col>
        </Row>
      </StudentMainLayout>
    );
  }

  if (!knowledgeBase) {
    return <LoadingOutlined spin style={{ fontSize: 44, color: "#4376F9" }} />;
  }

  const isMobile = size === SIZES.PHONE || size === SIZES.TABLET;

  return (
    <StudentMainLayout>
      <Row>
        <Col span={layoutSpan} className={styles.wrapper}>
          <div className={styles.header}>
            <span className={styles.title}>{knowledgeBase.title}</span>
            <span className={styles.subTitle}>
              {getTranslations('KNOWLEDGE_UPDATED_AT')} &nbsp;
              {moment(knowledgeBase.updatedAt).format("DD/MM/YYYY")}
            </span>
          </div>
          <div className={styles.content}>
            <div dangerouslySetInnerHTML={{ __html: knowledgeBase.content }} />
          </div>
          <AnalyticsViewKB knowledgeBaseId={contentId} ref={analyticsRef} />
          <div className={styles.pdfs}>
            {!isMobile ? 
            knowledgeBase.pdfs?.map((item, index) => {
              return <PDFViewer
                key={item.url + index}
                url={item.url}
                allowDownload
              />
            }):
            knowledgeBase.pdfs?.map((item, index) => {
              return (
                <a
                  key={item.url + index}
                  className={styles.pdf}
                  href={item.url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <div className={styles.download}>
                    <CogIcon
                      size="1em"
                      icon="Attach"
                      className={styles.iconLeft}
                    />
                    <span className={styles.filename}>{item.filename}</span>
                    <CogIcon
                      size="1em"
                      color="#3B54EC"
                      icon="Download"
                      className={styles.iconRight}
                    />
                  </div>
                </a>
              );
            })}
          </div>
          {/* <Divider />
          <div className={styles.related}>
            <span className={styles.title}>Articulos relacionados</span>
            <div className={styles.suggestionsWrapper}>
              <Row gutter={[0, 22]}>
                {knowledgeBaseItems.map(item => {
                  return (
                    <Col className="gutter-row" span={24}>
                      <span onClick={() => handleOnSummaryClick({ contentId: item._id })}>
                        <KnowledgeBaseSummary
                          key={item._id}
                          title={item.title}
                          description={item.description}
                          category={item.categoryName}
                          lastUpdate={moment(item.updatedAt).format('DD/MM/YYY')}
                        />
                      </span>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div> */}
        </Col>
      </Row>
    </StudentMainLayout>
  );
};

type AnalyticsViewKBRef = {
  trigger: () => void,
}
interface AnalyticsViewKBProps {
  knowledgeBaseId: string,
}

const AnalyticsViewKB = forwardRef<AnalyticsViewKBRef, AnalyticsViewKBProps>(({ knowledgeBaseId }, ref) => {
  useImperativeHandle(ref, () => ({
    trigger: () => {
      analytics.viewKnowledgeBase({
        category,
        knowledgeBase,
      })
    }
  }));

  const knowledgeBase = useSelector(selectKnowledgeBase(knowledgeBaseId));
  const categoriesDict = useSelector(selectContentCategoriesDict);
  const category = categoriesDict[knowledgeBase.category];
  return null;
})
