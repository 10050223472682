import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../app/AllowedMicroservices";
import { RootState } from "../../app/store";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { FetchStatus } from "../common/enums";
import { BadgeType } from "../../components/Profile/Infographic/ImagePerType";

interface BadgesPoints {
  login: number;
  courses: number;
  knowledgeBase: number;
  survey: number;
}
interface PointsState {
  fetchPointsStatus: FetchStatus;
  points: number;
  fetchBadgesPointsStatus: FetchStatus;
  badgesPoints: BadgesPoints;
  badges: number;
}

const initialState: PointsState = {
  fetchPointsStatus: FetchStatus.Idle,
  points: 0,
  fetchBadgesPointsStatus: FetchStatus.Idle,
  badgesPoints: {
    login: 0,
    courses: 0,
    knowledgeBase: 0,
    survey: 0,
  },
  badges: 0,
};

interface PointsFetch {
  points: number;
}


interface BadgesPointsFetch {
  login: number;
  courses: number;
  knowledgeBase: number;
  survey: number;
}

export const getImagePosition = (points: number, type: BadgeType) => {
  switch(type) {
    case BadgeType.activity:
      if (points >= 128) {
        return 6;
      }
      if (points >= 64) {
        return 5;
      }
      if (points >= 32) {
        return 4;
      }
      if (points >= 16) {
        return 3;
      }
      if (points >= 8) {
        return 2;
      }
      if (points >= 4) {
        return 1;
      }
      return 0;
    case BadgeType.learning:
      if (points >= 32) {
        return 6;
      }
      if (points >= 16) {
        return 5;
      }
      if (points >= 8) {
        return 4;
      }
      if (points >= 4) {
        return 3;
      }
      if (points >= 2) {
        return 2;
      }
      if (points >= 1) {
        return 1;
      }
      return 0;
    case BadgeType.opinion:
      if (points >= 64) {
        return 6;
      }
      if (points >= 32) {
        return 5;
      }
      if (points >= 16) {
        return 4;
      }
      if (points >= 8) {
        return 3;
      }
      if (points >= 4) {
        return 2;
      }
      if (points >= 2) {
        return 1;
      }
      return 0;
    case BadgeType.knowledge:
      if (points >= 128) {
        return 6;
      }
      if (points >= 64) {
        return 5;
      }
      if (points >= 32) {
        return 4;
      }
      if (points >= 16) {
        return 3;
      }
      if (points >= 8) {
        return 2;
      }
      if (points >= 4) {
        return 1;
      }
      return 0;
  }
}

export const pointsSlice = createSlice({
  name: "points",
  initialState,
  reducers: {
    fetchingPoints: (state) => {
      state.fetchPointsStatus = FetchStatus.Fetching;
    },
    fetchedPoints: (state, action: PayloadAction<PointsFetch>) => {
      state.points = action.payload.points;
      state.fetchPointsStatus = FetchStatus.Fetched;
    },
    errorPoints: (state) => {
      state.points = 0;
      state.fetchPointsStatus = FetchStatus.Error;
    },
    fetchingBadgesPoints: (state) => {
      state.badgesPoints = {
        login: 0,
        courses: 0,
        knowledgeBase: 0,
        survey: 0,
      };
      state.badges = 0;
      state.fetchBadgesPointsStatus = FetchStatus.Fetching;
    },
    fetchedBadgesPoints: (state, action: PayloadAction<BadgesPointsFetch>) => {
      state.badgesPoints = action.payload;
      state.badges = 0;
      state.badges += getImagePosition(Math.min(128, action.payload.courses), BadgeType.learning);
      state.badges += getImagePosition(Math.min(128, action.payload.knowledgeBase), BadgeType.knowledge);
      state.badges += getImagePosition(Math.min(128, action.payload.login), BadgeType.activity);
      state.badges += getImagePosition(Math.min(128, action.payload.survey), BadgeType.opinion);
      state.fetchBadgesPointsStatus = FetchStatus.Fetched;
    },
    errorBadgesPoints: (state) => {
      state.points = 0;
      state.fetchBadgesPointsStatus = FetchStatus.Error;
    },
  },
});

const { errorBadgesPoints, errorPoints, fetchedBadgesPoints, fetchedPoints, fetchingBadgesPoints, fetchingPoints } = pointsSlice.actions;

export const fetchPoints = (userId?: string) => (dispatch: Dispatch<object>) => {
  const endpoint = userId ? `/points/user/${userId}`:'/points';
  return dispatch(
    apiActionCreator({
      endpoint,
      types: {
        requestType: fetchingPoints,
        successTypes: [
          {
            actionOrCreator: fetchedPoints,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: errorPoints,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.COMPANY_SERVICE,
      authenticated: true,
    })
  );
};

export const fetchBadgesPoints = (userId?: string) => (
  dispatch: Dispatch<object>, getState: any
) => {
  if (getState().points.fetchBadgesPointsStatus === FetchStatus.Fetching) {
    return dispatch({type:'DO_NOTHING'});
  }
  const endpoint = userId ? `/points/pointsPerCategory/${userId}`:'/points/pointsPerCategory';
  return dispatch(
    apiActionCreator({
      endpoint,
      types: {
        requestType: fetchingBadgesPoints,
        successTypes: [
          {
            actionOrCreator: fetchedBadgesPoints,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: errorBadgesPoints,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.COMPANY_SERVICE,
      authenticated: true,
    })
  );
};

export const selectPoints = (state: RootState) =>
  state.points.points;
export const selectArePointsFetching = (state: RootState) =>
  state.points.fetchPointsStatus === FetchStatus.Fetching;

 export const selectBadgesPoints = (state: RootState) =>
  state.points.badgesPoints;

  export const selectBadges = (state: RootState) =>
   state.points.badges;
export const selectAreBadgesPointsFetching = (state: RootState) =>
  state.points.fetchBadgesPointsStatus === FetchStatus.Fetching;

export default pointsSlice.reducer;
