import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Modal, Select, Tabs } from 'antd';
import { Prompt } from 'react-router-dom';

import LoadingOverlay from '../common/LoadingOverlay';
import {
  fetchTranslationsAdmin,
  selectTranslationsAdminIsFetching,
  selectTranslationsAdminIsTranslating,
  selectTranslationsAdminFetchedLanguage,
  selectTranslationsAdminKeys,
  translateAllItems,
  saveAllItems,
  selectTranslationsAdminIsDirty
} from '../../features/translationsAdmin/translationsAdminSlice';
import { MainLayout } from '../common/Layouts/Main';
import { getTranslations, getTranslationsJSX } from '../../features/translations/translationsUtils';
import { TranslationPane } from './TranslationPane';
import { fetchLanguages, selectLanguagesIsFetching, selectLanguages, addNewLanguage } from '../../features/languages/languagesSlice';

import languagesSupported from './googleTranslateSupportedLanguages.json';

import styles from './translations.module.scss';

export const Translations: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const [newLanguage, setNewLanguage] = useState<string>('');
  const [showNewLanguage, setShowNewLanguage] = useState<boolean>(false);
  const [language, setLanguage] = useState<null | string>(null);
  const isLanguagesFetching = useSelector(selectLanguagesIsFetching);
  const languages = useSelector(selectLanguages);
  const isFetching = useSelector(selectTranslationsAdminIsFetching);
  const isTranslating = useSelector(selectTranslationsAdminIsTranslating);
  const languageFetched = useSelector(selectTranslationsAdminFetchedLanguage);
  const keys = useSelector(selectTranslationsAdminKeys);
  const areChanges = useSelector(selectTranslationsAdminIsDirty);
  useEffect(() => {
    dispatch(fetchLanguages());
  }, [dispatch]);
  useEffect(() => {
    if (!isFetching && language && languageFetched !== language) {
      dispatch(fetchTranslationsAdmin(language));
    }
  }, [dispatch, isFetching, languageFetched, language]);
  useEffect(() => {
    if (languages) {
      const firstLanguage = languages.filter(language => language !== 'es')[0] ?? null;
      setLanguage(firstLanguage);
    }
  }, [languages]);

  if (isFetching || isLanguagesFetching) {
    return <LoadingOverlay spinning />;
  }
  const items = keys.map((key) => {
    return {
      label: <span>{key}</span>,
      key,
      children: <TranslationPane selectedLanguage={language ?? ''} translationKey={key} key={key} />
    };
  });
  const filteredLanguages = languages.filter(language => language !== 'es');
  return <MainLayout
    title={getTranslations('TRANSLATIONS_TITLE')}
    titleContainerClass={styles.header}
    breadcrumbs={[getTranslations('TRANSLATIONS_TITLE')]}
    rightTopOptions={<>
      <Select
        className={styles.languagesSelector}
        value={language}
        onChange={(value) => {
          if (value !== 'ADD_NEW') {
            setLanguage(value);
            return;
          }
          setShowNewLanguage(true);
        }}
      >
        <Select.Option key={'es'} value={'es'}>Español (Idioma principal)</Select.Option>
        {filteredLanguages.map(language => {
          const item = languagesSupported.find((item) => item.code === language);
          return <Select.Option key={language} value={language}>{item?.language}</Select.Option>
        })}
        <Select.Option key="ADD_NEW">{getTranslations('TRANSLATIONS_ADD_NEW')}</Select.Option>
      </Select>&nbsp;&nbsp;
      <Button disabled={!language || language === 'es'} type='default' onClick={() => {
        if (language) {
          dispatch(translateAllItems(language));
        }
      }}>
        {getTranslations('TRANSLATIONS_TRANSLATE_ALL')}
      </Button>&nbsp;&nbsp;
      <Button disabled={!language || !areChanges} type='primary' onClick={() => {
        if (language) {
          dispatch(saveAllItems(language));
        }
      }}>
        {getTranslations('TRANSLATIONS_SAVE_ALL')}
      </Button>
    </>}
  >
    <Modal
      open={showNewLanguage}
      onCancel={() => {
        setShowNewLanguage(false);
      }}
      closeIcon
      onOk={async () => {
        setShowNewLanguage(false);
        await dispatch(addNewLanguage(newLanguage));
        setLanguage(newLanguage);
        dispatch(fetchTranslationsAdmin(newLanguage));
      }}
      okText={getTranslations('TRANSLATIONS_ADD')}
      cancelText={getTranslations('TRANSLATIONS_CANCEL')}
    >
      {getTranslations('TRANSLATIONS_LANGUAGES')}:&nbsp;&nbsp;
      <Select
        className={styles.languagesSelector}
        value={newLanguage}
        onChange={(value) => {
          setNewLanguage(value);
        }}
      >
        {languagesSupported.filter(item => !languages.includes(item.code)).map(item => {

          return <Select.Option key={item.code} value={item.code}>{item.language}</Select.Option>
        })}
      </Select>
    </Modal>
    <Prompt message={getTranslations('TRANSLATIONS_PENDING_CHANGES')} when={areChanges} />
    {isTranslating ? <LoadingOverlay message={getTranslations('TRANSLATIONS_TRANSLATING_MESSAGE')} fullscreen spinning={isTranslating} /> : null}
    <Alert
      showIcon
      type='warning'
      closable
      description={getTranslationsJSX('NOTIFICATIONS_ALERT', <strong>{'{{%1}}'}</strong>)}
    />
    <Tabs
      className={styles.tabs}
      defaultActiveKey={keys[0]}
      items={items}
    />
  </MainLayout>;
};