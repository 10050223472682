import React, { FunctionComponent, ReactElement } from 'react';
import cls from 'classnames';

import headerStyles from './Header.module.scss';

interface HeaderProps {
  title: string | ReactElement,
  className?: string,
  titleClassname?: string,
  subTitleClassname?: string,
}

export const Header: FunctionComponent<HeaderProps> = ({ title, titleClassname, className, subTitleClassname, children }) =>
  <section className={cls(headerStyles.container, className)}>
    <div className={cls(headerStyles.title, titleClassname)}>{title}</div>
    <div className={cls(headerStyles.subtitle, subTitleClassname)}>
      {children}
    </div>
  </section>
