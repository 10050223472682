import React, { FunctionComponent } from 'react';

import { useHistory } from 'react-router-dom';
import queryString from "query-string";

import { Calendar } from '../../../Courses/Calendar';

import styles from './header.module.scss';
import { CoursesFilter, HandleFilterParams } from './CoursesFilter';
import { getTranslations } from '../../../../../features/translations/translationsUtils';

export const Header: FunctionComponent = () => {
  const history = useHistory();
  
  const handleFilter = ({ tags = [], categoryId = "" }: HandleFilterParams) => {
    const paramsObj = {
      ...(tags.length > 0 && { tag: [...tags] }),
      ...(categoryId.length > 0 && { category: categoryId })
    };
    const qParams = queryString.stringify(paramsObj)
      ? "?" + queryString.stringify(paramsObj)
      : "";
    const pathPlusParams = `/myCourses${qParams}`;
    history.push(pathPlusParams);
  };

  return <div className={styles.container}>
    <div className={styles.title}>{getTranslations('MYCOURSES_TITLE')}</div>
    <div className={styles.rightActions}>
      <Calendar />
      <CoursesFilter key="filter" onApply={handleFilter} />
    </div>
  </div>
}