import { Col, Row } from "antd";
import React, { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCognitoUserData } from "../../../features/auth/authSlice";
import {
  fetchCampaigns,
  selectCampaigns,
  selectCampaignsAreFetching,
} from "../../../features/campaign/campaignSlice";
import { ConditionEnum } from "../../../features/common/enums";
import {
  fetchCompanies,
  selectCompanies,
} from "../../../features/company/companySlice";
import {
  fetchEvaluations,
  selectEvaluations,
} from "../../../features/evaluation/evaluationsSlice";
import {
  fetchCampaignStats,
  selectCampaignStats,
  selectCampaignStatsAreFetching,
} from "../../../features/reports/Campaigns/stats";
import { UserRole } from "../../../features/users/usersSlice";
import { RoundedContainer } from "../../common/RoundedContainer";
import { StatusEnum } from "../../common/Status";
import { AnalyticsViewReport } from "../common/AnalyticsViewReport";
import { ReportTypes, sectionTitles } from "../MenuAndRoutes";
import { CampaignsBarChart } from "./CampaignsBarChart";
import { CampaignsDoughnut } from "./CampaignsDoughnut";
import { CampaignsTable } from "./CampaignsTable";
import { CampaignStats } from "./CampaignStats";
import { CampaignsTop5 } from "./CampaignsTop5";

export interface CampaignSurveyTaken {
  id: string;
  name: string;
  companyId: string;
  companyName: string;
  percentage: number;
  status: StatusEnum;
  survey: string;
  surveyId: string;
}

interface Props {
  companyId?: string;
}

export const CampaignReports: FunctionComponent<Props> = ({ companyId }) => {
  const campaigns = useSelector(selectCampaigns);
  const campaignStats = useSelector(selectCampaignStats);
  const areCampaignsFetching = useSelector(selectCampaignsAreFetching);
  const areStatsFetching = useSelector(selectCampaignStatsAreFetching);
  const companyDict = useSelector(selectCompanies);
  const surveys = useSelector(selectEvaluations);
  const cognitoData = useSelector(getCognitoUserData);
  const role = cognitoData ? cognitoData["custom:role"] : undefined;
  const userCompanyId: string | undefined = cognitoData ? cognitoData["custom:company"] : undefined;
  const currentCompanyId =
    role === UserRole.SuperAdmin ? companyId : userCompanyId;

  const campaignsWithSurvey = campaigns.filter((campaign) => campaign.survey);
  const filterdCampaigns = currentCompanyId
    ? campaignsWithSurvey.filter(
        (campaign) => campaign.companyId === currentCompanyId
      )
    : campaignsWithSurvey;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCampaigns());
    dispatch(fetchCampaignStats());
    dispatch(fetchCompanies());
    dispatch(fetchEvaluations());
  }, [dispatch]);

  const campaignsSurveyTakenPercentage: CampaignSurveyTaken[] =
    campaignStats.map((campaign) => {
      const percentage =
        campaign.users.length > 0
          ? (campaign.users.filter((user) => user.surveyTaken).length /
              campaign.users.length) *
            100
          : 0;
      const currentCampaign = campaigns.find(
        (item) => item._id === campaign.id
      );
      const currentSurvey = surveys.find(
        (item) => item._id === currentCampaign?.survey
      );
      return {
        id: campaign.id,
        name: currentCampaign?.title ?? "N/A",
        status:
          currentCampaign?.published === ConditionEnum.Published
            ? StatusEnum.Enable
            : StatusEnum.Disable,
        companyId: campaign.companyId,
        companyName: companyDict[campaign.companyId]?.name ?? "",
        percentage,
        formattedPercentage: percentage.toFixed(2) + "%",
        survey: currentSurvey?.title ?? "",
        surveyId: currentCampaign?.survey ?? "",
      };
    });

  const filteredCampaignsSurveyTakenPercentage = currentCompanyId
    ? campaignsSurveyTakenPercentage.filter(
        (campaign) => campaign.companyId === currentCompanyId
      )
    : campaignsSurveyTakenPercentage;

  const enabledCampaigns = filterdCampaigns.filter(
    (campaign) => campaign.published === ConditionEnum.Published
  );

  return (
    <>
      <AnalyticsViewReport
        companyId={currentCompanyId}
        reportName={sectionTitles()[ReportTypes.Campaigns]}
      />
      <Row gutter={[24, 24]} style={{ marginBottom: 46 }}>
        <Col span={12}>
          <RoundedContainer>
            <CampaignStats
              campaigns={filterdCampaigns}
              enabledCampaigns={enabledCampaigns}
            />
          </RoundedContainer>
        </Col>
        <Col span={12}>
          <RoundedContainer>
            <CampaignsDoughnut
              campaignsWithSurveyPercentage={
                filteredCampaignsSurveyTakenPercentage
              }
            />
          </RoundedContainer>
        </Col>
        <Col span={12}>
          <RoundedContainer>
            <CampaignsBarChart
              companyId={currentCompanyId}
              campaigns={filterdCampaigns}
              loading={areCampaignsFetching}
              enabledCampaigns={enabledCampaigns}
            />
          </RoundedContainer>
        </Col>
        <Col span={12}>
          <RoundedContainer>
            <CampaignsTop5
              campaignsSurveyTakenPercentage={campaignsSurveyTakenPercentage}
              loading={areStatsFetching}
            />
          </RoundedContainer>
        </Col>
        <Col span={24}>
          <CampaignsTable
            campaignStats={campaignStats}
            companyId={currentCompanyId}
            campaigns={campaigns}
            surveys={surveys}
            campaignsWithSurveyPercentage={
              filteredCampaignsSurveyTakenPercentage
            }
          />
        </Col>
      </Row>
    </>
  );
};
