import React, { FunctionComponent, useEffect, useState } from "react";

import { Button, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import * as H from 'history';

import { clearBreadcrumbs } from "../../features/breadcrumbs/breadcrumbsSlice";
import {
  hideModal,
  selectDisplayModal,
  selectIsContentFetching,
  selectJustCreatedContent,
} from "../../features/knowledgeBase/contentSlice";
import { DialogPopUp } from "../common/DialogPopUp";
import { MainLayout } from "../common/Layouts/Main";
import { ContentForm } from "./Form";
import { ContentList } from "./List";
import { analytics } from '../../services/analytics';
import { getCognitoUserData } from '../../features/auth/authSlice';
import {
  selectCompanies,
  fetchCompanies,
} from '../../features/company/companySlice';
import { ActionSuffixes, Prefixes } from "../prefixes";
import { useHistory } from "react-router-dom";
import { getTranslations } from "../../features/translations/translationsUtils";

function renderOptions(history: H.History) {
  return (
    <Button
      size="large"
      onClick={() => {
        history.push(`${Prefixes.knowledgeBase}${ActionSuffixes.new}`);
      }}
      type="primary"
    >
      {getTranslations('KNOWLEDGE_BASE_NEW')}
    </Button>
  );
}

export const KnowledgeBases: FunctionComponent = () => {
  const history = useHistory();
  const [isFormVisible, setFormVisible] = useState<boolean>(false);
  const [editingContent, setEditingContent] = useState<string | null>(null);
  const isModalVisible = useSelector(selectDisplayModal);
  const isFetchingContents = useSelector(selectIsContentFetching);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isFormVisible) {
      dispatch(clearBreadcrumbs());
    }
  }, [dispatch, isFormVisible]);

  useEffect(() => {
    if (!isFetchingContents) {
      form.setFieldsValue({});
      form.resetFields();
    }
  }, [form, isFetchingContents]);

  let content;
  if (isFormVisible) {
    content = (
      <ContentForm
        editing={editingContent}
        form={form}
        onDone={() => {
          setFormVisible(false);
          setEditingContent(null);
        }}
      />
    );
  } else {
    const onEdit = (contentId: string) => {
      history.push(`${Prefixes.knowledgeBase}/${contentId}${ActionSuffixes.edit}`);
    };
    content = <ContentList editContent={onEdit} />;
  }
  return (
    <MainLayout
      hideTitle={isFormVisible}
      hideRightTopOptions={isFormVisible}
      title={getTranslations('KNOWLEDGE_BASE_TITLE')}
      rightTopOptions={renderOptions(history)}
      breadcrumbs={[getTranslations('KNOWLEDGE_BASE_TITLE')]}
    >
      <DialogPopUp
        name={getTranslations('KNOWLEDGE_BASE_EXCELLENT')}
        action={getTranslations('KNOWLEDGE_BASE_SUCCESSFUL_CREATION')}
        visible={isModalVisible}
        onCancel={() => {
          dispatch(hideModal());
        }}
      />
      <AnalyticsKnowledgeBaseCreated />
      {content}
    </MainLayout>
  );
};

interface AnalyticsKBCreatedProps { }
const AnalyticsKnowledgeBaseCreated: React.FunctionComponent<AnalyticsKBCreatedProps> = () => {
  const createdKB = useSelector(selectJustCreatedContent);
  const cognitoData = useSelector(getCognitoUserData);
  const cognitoUsername = cognitoData ? cognitoData['cognito:username'] : null;
  const companiesList = useSelector(selectCompanies);
  const dispatch = useDispatch();
  useEffect(() => {
    if (createdKB) {
      analytics.createKnowledgebase({
        userName: cognitoUsername,
        companyName: companiesList[createdKB.companyId]?.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdKB]);
  useEffect(() => {
    dispatch(fetchCompanies());
  }, [dispatch]);
  return null;
}
