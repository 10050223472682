import { Checkbox, Input as AntInput } from "antd";
import ctx from "classnames";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectGroupsDict } from "../../features/groups/groupSlice";
import { IUser } from "../../features/users/usersSlice";
import styles from "./targetsFilter.module.scss";
import { SearchOutlined } from "@ant-design/icons";
import { getTranslations } from "../../features/translations/translationsUtils";

interface Itarget {
  label: string;
  id: string;
  item?: IUser;
}
interface TargetsFilterProps {
  setTargetsSelected: (targets: string[]) => void;
  targets: Itarget[];
  selectedTargets: string[];
  searchPlaceHolder: string;
}

export const TargetsFilter: React.FunctionComponent<TargetsFilterProps> = ({
  setTargetsSelected,
  selectedTargets,
  targets,
  searchPlaceHolder,
}) => {
  const [targetName, setTargetName] = useState("");
  const groupsDict = useSelector(selectGroupsDict);

  useEffect(() => {
    if (selectedTargets.length === 0) {
      setTargetName("");
    }
  }, [selectedTargets]);

  const filteredTargets = targets.filter((target) => {
    if (target.item) {
      return (
        target.item.email.toUpperCase().includes(targetName.toUpperCase()) ||
        target.item.nationalId
          .toUpperCase()
          .includes(targetName.toUpperCase()) ||
        groupsDict[target.item.studentGroupId ?? ""]?.name
          .toUpperCase()
          .includes(targetName.toUpperCase()) ||
        target.label.toUpperCase().includes(targetName.toUpperCase())
      );
    }
    return target.label.toUpperCase().includes(targetName.toUpperCase());
  });

  return (
    <>

      <AntInput
        placeholder={searchPlaceHolder}
        value={targetName}
        prefix={<SearchOutlined className='search-icon' />}
        className={ctx(styles.search, styles.wrapper, 'custom-search')}
        onChange={(event) => {
          setTargetName(event.target.value.replace(/^\s/, ""));
        }}
      />
      <div className={styles.targets}>
        <div className={ctx(styles.target)} key="all">
          <Checkbox
            checked={selectedTargets.includes("all")}
            onChange={(event) => {
              const { checked = false } = event.target;
              const set = new Set(selectedTargets);
              if (!checked) {
                set.delete("all");
                filteredTargets.forEach((item) => set.delete(item.id));
              } else {
                set.add("all");
                filteredTargets.forEach((item) => set.add(item.id));
              }
              setTargetsSelected(Array.from(set));
            }}
          >
            {getTranslations('NOTIFICATIONS_SELECT_ALL')}
          </Checkbox>
        </div>
        {filteredTargets
          .sort((a, b) => {
            return a.label.localeCompare(b.label);
          })
          .map((target) => {
            return (
              <div
                className={ctx(styles.target)}
                key={target.id}
                title={target.label}
              >
                <Checkbox
                  checked={selectedTargets.includes(target.id)}
                  onChange={(event) => {
                    const { checked = false } = event.target;
                    const set = new Set(selectedTargets);
                    if (!checked) {
                      set.delete(target.id);
                    } else {
                      set.add(target.id);
                    }
                    setTargetsSelected(Array.from(set));
                  }}
                >
                  {target.item ? (
                    <div className={styles.wrapper}>
                      <span>
                        {target.label}&nbsp;
                        <strong>
                          ({groupsDict[target.item.studentGroupId ?? ""]?.name})
                        </strong>
                      </span>&nbsp;
                      <span>{target.item.email}</span>&nbsp;
                      <span>{target.item.nationalId}</span>
                    </div>
                  ) : (
                    target.label
                  )}
                </Checkbox>
              </div>
            );
          })}
      </div>
    </>
  );
};
