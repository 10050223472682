import { Form } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCognitoUserData } from "../../features/auth/authSlice";
import {
  fetchCompanies,
  selectCompanies,
} from "../../features/company/companySlice";
import { fetchCourses } from "../../features/courses/coursesSlice";
import {
  createGroup,
  fetchGroups,
  IGroup,
  selectDisplayModal,
  selectCreatedGroup,
  hideModal,
} from "../../features/groups/groupSlice";
import { fetchPrograms } from "../../features/program/programSlice";
import { AfterActions, DialogPopUp } from "../common/DialogPopUp";
import { MainLayout } from "../common/Layouts/Main";
import { StatusEnum } from "../common/Status";
import { GroupForm } from "./Form";
import { analytics } from '../../services/analytics';
import { Prefixes } from "../prefixes";
import { useHistory } from "react-router-dom";
import { FormFooter } from "../common/FormFooter";
import { getTranslations } from "../../features/translations/translationsUtils";

function isValidate(group?: IGroup) {
  if (!group) {
    return false;
  }
  if (!group.company) {
    return false;
  }
  return (group.name || "").trim() === "";
}

export const NewGroup: React.FunctionComponent = () => {
  const [restart, setRestart] = useState(true);
  const history = useHistory();
  const companiesList = useSelector(selectCompanies);
  const [newGroup, setNewGroup] = useState<IGroup>();
  const dispatch = useDispatch();
  const [currentAction, setCurrentAction] = useState<string>("");
  const cognitoData = useSelector(getCognitoUserData);
  const isModalVisible = useSelector(selectDisplayModal);
  const cognitoUsername = cognitoData ? cognitoData['cognito:username'] : null;
  const createdGroup = useSelector(selectCreatedGroup);
  const [form] = Form.useForm();

  useEffect(() => {
    if (createdGroup) {
      analytics.createGroup({
        userName: cognitoUsername,
        companyName: companiesList[createdGroup.company]?.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdGroup]);

  useEffect(() => {
    if (restart) {
      form.resetFields();
      form.setFieldsValue({});
      setRestart(false);
    }
  }, [restart, form]);
  useEffect(() => {
    dispatch(fetchGroups());
    dispatch(fetchCompanies());
    dispatch(fetchPrograms());
    dispatch(fetchCourses());
  }, [dispatch]);
  const disabledSaveButton = isValidate(newGroup);
 
  if (restart) {
    return null;
  }

  return (
    <MainLayout
      title={getTranslations('GROUP_CREATE')}
      onGoBack={() => {
        history.push(`${Prefixes.group}`);
      }}
      breadcrumbs={[getTranslations('GROUP_TITLE'), getTranslations('GROUP_CREATE')]}
    >
      <DialogPopUp
        name={getTranslations('GROUP_TITLE_SINGULAR')}
        action={getTranslations('GROUP_CREATED')}
        visible={isModalVisible}
        onCancel={() => {
          if (currentAction === AfterActions.BACK) {
            history.push(`${Prefixes.group}`);
          } else {
            setNewGroup({} as IGroup);
            setTimeout(() => {
              setRestart(true);
            }, 100);
          }
          dispatch(hideModal());
        }}
      />
      <div>
        <GroupForm
          form={form}
          newGroup={newGroup}
          setNewGroup={setNewGroup}
        />
      </div>
      <FormFooter
        editing={false}
        disabledSave={disabledSaveButton}
        objectName={getTranslations('GROUP_TITLE_SINGULAR').toLocaleLowerCase()}
        onCancel={() => {
          history.push(`${Prefixes.group}`);
        }}
        onSave={() => {
          form
            .validateFields()
            .then((value) => {
              if (!newGroup) {
                return;
              }
              newGroup.status = StatusEnum.Enable;
              setCurrentAction(AfterActions.BACK);
              dispatch(createGroup(newGroup));
            })
            .catch((error) => {
              console.error(
                "Company:React.FunctionComponent -> error",
                error
              );
            });
        }}
        onSaveAndCreateAnother={() => {
          form
            .validateFields()
            .then((value) => {
              if (!newGroup) {
                return;
              }
              newGroup.status = StatusEnum.Enable;
              setCurrentAction(AfterActions.STAY);
              dispatch(createGroup(newGroup));
            })
            .catch((error) => {
              console.error(
                "Company:React.FunctionComponent -> error",
                error
              );
            });
        }}
      />
    </MainLayout>
  );
};
