import { Button, Card, Layout, Space } from "antd";
import ctx from "classnames";
import React, {
  FunctionComponent,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ParentTypeEnum } from "../../../../features/evaluation/common/enums";
import {
  IAnswer,
  QuestionsAnswers,
} from "../../../../features/evaluation/common/interfaces";
import {
  fetchEvaluation,
  registerEvaluationResult,
  selectEvaluation,
} from "../../../../features/evaluation/currentEvaluationSlice";
import { setMessageError } from "../../../../features/message/messageSlice";
import {
  fetchStudentCourse,
  makeProgressStudentCourse,
  selectStudentCourse,
  selectStudentCourseTopic,
  TOPIC_TEST,
} from "../../../../features/students/courseSlice";
import { CogIcon } from "../../../common/CogIcon";
import { CogDrawer } from "../../../common/Drawer";
import { StudentMainLayout } from "../../../common/Layouts/StudentMainLayout";
import { SIZES } from "../../../common/screen";
import { useDeviceSize } from "../../../utils/useDeviceSize";
import { TwoColumnLayout } from "../../common/TwoColumnLayout";
import { StudentLessonCourseInfo } from "../../Lesson/CourseInfo";
import { StudentLessonTopics } from "../../Lesson/Topics";
import { QuestionPage } from "../QuestionPage";
import { FirstPage } from "./FirstPage";
import styles from "./index.module.scss";
import { LastPage } from "./LastPage";
import { analytics } from "../../../../services/analytics";
import { selectGroup } from "../../../../features/groups/groupSlice";
import { getCognitoUserData } from '../../../../features/auth/authSlice';
import {
  selectPrograms,
  selectProgramsDict,
} from '../../../../features/program/programSlice';
import { selectCompany } from '../../../../features/company/companySlice';
import { selectUser } from '../../../../features/users/usersSlice';
import { getTranslations } from "../../../../features/translations/translationsUtils";

interface TestProps {
  questionToShow?: number;
}

export const Test: FunctionComponent<TestProps> = ({ questionToShow = -1 }) => {
  const [answersSelected, setAnswerSelected] = useState<QuestionsAnswers>({});
  const [currentQuestion, setCurrentQuestion] = useState<number>(
    questionToShow
  );
  const {
    id: courseId,
    topicId,
  }: { id: string; topicId: string } = useParams();
  const topic = useSelector(selectStudentCourseTopic(topicId));
  const course = useSelector(selectStudentCourse);
  const evaluation = useSelector(selectEvaluation(topic?.evaluation || ''));
  const history = useHistory();
  const dispatch = useDispatch();
  const size = useDeviceSize();
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchStudentCourse(courseId));
  }, [courseId, dispatch]);
  const testId = topic?.evaluation;
  useEffect(() => {
    if (!testId) {
      return;
    }
    dispatch(fetchEvaluation(testId, topicId, courseId));
  }, [dispatch, testId, topicId, courseId]);

  useEffect(() => {
    if (course && !topic) {
      dispatch(
        setMessageError({ message: "Esta evaluación no esta disponible" })
      );
      history.push(`/course/${courseId}`);
    }
  }, [course, topic, dispatch, history, courseId]);
  const completeTestRef = useRef<AnalyticsSelectTestRef>(null);

  let content;
  const questionsCount = evaluation?.questions?.length || Infinity;
  const onNext = () => {
    setCurrentQuestion(currentQuestion + 1);
    if (currentQuestion + 1 === questionsCount) {
      const answers: IAnswer[] = [];
      let result = 0;
      Object.keys(answersSelected).forEach((answerSelected) => {
        answers.push({
          answerId: answersSelected[answerSelected].answerId,
          questionId: answerSelected,
        });
        if (answersSelected[answerSelected].correct) {
          ++result;
        }
      });
      const correctAnswersToPass = topic.correctAnswersToPass || Infinity;
      const passed = result >= correctAnswersToPass;
      if (passed) {
        dispatch(
          makeProgressStudentCourse({
            topicId: topicId,
            courseId: courseId,
            progress: 1,
            evaluationId: evaluation?._id,
          })
        );
      }
      dispatch(
        registerEvaluationResult({
          courseId,
          answers,
          evaluationId: testId || "",
          parentId: topicId,
          parentType: ParentTypeEnum.Topic,
          result,
          total: Object.keys(answersSelected).length,
          correctAnswersToPass: topic.correctAnswersToPass,
          passed,
        })
      );
      completeTestRef?.current?.trigger(answers);
    }
  };
  if (currentQuestion === -1) {
    content = <FirstPage evaluationId={topic?.evaluation || ''} topicId={topicId} onNext={onNext} />;
  } else if (questionsCount <= currentQuestion) {
    content = (
      <LastPage
        retakeTest={() => {
          setAnswerSelected({});
          setCurrentQuestion(-1);
        }}
        answersSelected={answersSelected}
        topicId={topicId}
        courseId={courseId}
      />
    );
  } else {
    const question = evaluation?.questions
      ? evaluation?.questions[currentQuestion]
      : undefined;
    content = (
      <QuestionPage
        onBack={() => null}
        key={currentQuestion}
        question={question}
        currentQuestion={currentQuestion}
        questionsCount={evaluation?.questions?.length || 0}
        onNext={onNext}
        setAnswerSelected={(answerId: string) => {
          setAnswerSelected({
            ...answersSelected,
            [question?._id || ""]: {
              answerId,
              correct: question?.correctAnswer === answerId,
            },
          });
        }}
      />
    );
  }

  const title = (
    <div className={ctx(styles.container)}>
      {size === SIZES.DESKTOP ? (
        <Card className={styles.titleContainer}>
          <div className={styles.topicTitle}>{topic?.title}</div>
          <div className={styles.title}>{getTranslations('STUDENT_TEST')}: {evaluation?.title}</div>
        </Card>
      ) : (
        <div>
          <div className={styles.topicTitle}>{topic?.title}</div>
          <div className={styles.title}>{getTranslations('STUDENT_TEST')}: {evaluation?.title}</div>
        </div>
      )}
    </div>
  );

  const lessonCourseInfo = <StudentLessonCourseInfo />;

  const lessonTopics = (
    <>
      <StudentLessonTopics
        currentTopicId={topic?._id}
        currentLessonId={TOPIC_TEST}
        isExam={false}
        isTest
      />
      <AnalyticsSelectTest ref={completeTestRef} topicId={topic?._id} />
    </>
  );

  const mobileDrawerHeader = (
    <div>
      <div>
        <Button
          type="default"
          onClick={() => {
            setIsDrawerVisible(false);
          }}
        >
          <CogIcon className={"el-style"} icon="Arrow-right-big" size="1em" />
          Cerrar
        </Button>
      </div>
    </div>
  );

  if (size === SIZES.DESKTOP) {
    return (
      <StudentMainLayout>
        <TwoColumnLayout
          firstColumnSpan={18}
          firstColumnContent={
            <React.Fragment>
              {title}
              {content}
            </React.Fragment>
          }
          secondColumnContent={
            <React.Fragment>
              {lessonCourseInfo}
              {lessonTopics}
            </React.Fragment>
          }
        />
      </StudentMainLayout>
    );
  }
  if (size === SIZES.TABLET) {
    return (
      <StudentMainLayout>
        <Layout>
          <div style={{ padding: "0 1em" }}>
            <Space direction="vertical" size="middle" style={{ width: "100%" }}>
              <div>
                {title}
                {content}
              </div>
              <TwoColumnLayout
                firstColumnSpan={16}
                firstColumnContent={<div></div>}
                secondColumnContent={
                  <React.Fragment>
                    {lessonCourseInfo}
                    {lessonTopics}
                  </React.Fragment>
                }
              />
            </Space>
          </div>
        </Layout>
      </StudentMainLayout>
    );
  }

  return (
    <React.Fragment>
      <div className={styles.viewLessonsMobile}>
        <div></div>
        <Button
          type="default"
          onClick={() => {
            setIsDrawerVisible(true);
          }}
        >
          <CogIcon className={"el-style"} icon="Arrow-left-big" size="1em" />
          Ver lecciones
        </Button>
      </div>
      <StudentMainLayout>
        <Layout>
          <div>
            {title}
            {content}
            <CogDrawer
              visible={isDrawerVisible}
              toggleVisible={() => { }}
              title={mobileDrawerHeader}
              footer={<div></div>}
              width={"100%"}
              headerStyle={{ borderBottom: 0, paddingLeft: 0, paddingRight: 0 }}
              closeIcon={null}
              noGutter
            >
              {lessonTopics}
            </CogDrawer>
          </div>
        </Layout>
      </StudentMainLayout>
    </React.Fragment>
  );
};

export type AnalyticsSelectTestRef = {
  trigger: (answers: IAnswer[]) => void,
}

interface AnalyticsSelectTestProps {
  topicId: string
}

export const AnalyticsSelectTest =
  forwardRef<AnalyticsSelectTestRef, AnalyticsSelectTestProps>(({ topicId }, ref) => {
    useImperativeHandle(ref, () => ({
      trigger: (answers: IAnswer[]) => {
        analytics.completeTest({
          test: evaluation,
          topic: topic,
          course: course,
          program: courseProgram,
          company: company,
          group: group || null,
          answers: answers,
        });
      }
    }));
    const topic = useSelector(selectStudentCourseTopic(topicId));
    const evaluation = useSelector(selectEvaluation(topic?.evaluation || ''));
    const course = useSelector(selectStudentCourse);
    const allProgramsIds = useSelector(selectPrograms);
    const programsDict = useSelector(selectProgramsDict);
    const programId = allProgramsIds.filter(programId => {
      const program = programsDict[programId] || {};
      const { courses: programCourses = [] } = program;
      const foundCourse = programCourses.find(courseId => courseId === course?._id);
      if (foundCourse !== undefined) {
        return true;
      }
      return false;
    });
    const courseProgram = programsDict[programId[0]] || null;
    const cognitoUserData = useSelector(getCognitoUserData);
    const cognitoUsername = cognitoUserData ? cognitoUserData['cognito:username'] : null;
    const userFromStore = useSelector(selectUser(cognitoUsername));
    const company = useSelector(selectCompany(userFromStore?.company ?? '')) || null;
    const group = useSelector(selectGroup(userFromStore?.studentGroupId || '')) || null;

    return null;
  });

