import React from "react";
import { UserReportData } from "..";
import { IconNames } from "../../common/SimpleStat";
import { StatsRow } from "../../common/StatsRow";
import { getTranslations } from "../../../../features/translations/translationsUtils";

interface Props {
  registerUsersAmount: number;
  enabledUsersAmount: number;
  activeUsers: UserReportData[];
}

export const UserStatsChart: React.FunctionComponent<Props> = ({
  activeUsers,
  enabledUsersAmount,
  registerUsersAmount,
}) => {
  const avgSession =
    activeUsers.length > 0
      ? activeUsers.reduce(
        (total: number, user) => total + user.sessionDuration,
        0
      ) / activeUsers.length
      : 0;


  const time = Math.round(avgSession ?? 0);
  const hours = Math.trunc(time / 60 / 60);
  const minutes = Math.round((time - hours * 60 * 60) / 60);
  const timeValue = `${`${hours}`.padStart(2, '0')}:${`${minutes}`.padStart(2, '0')}`;

  const stats = [
    {
      description: getTranslations('REPORT_REGISTERED_USERS'),
      iconName: IconNames.User,
      mainText: registerUsersAmount.toString(),
      tooltipText: getTranslations('REPORT_REGISTERED_USERS_INFO'),
    },
    {
      description: getTranslations('REPORT_ENABLED_USERS'),
      iconName: IconNames.UserEnabled,
      mainText: enabledUsersAmount.toString(),
      tooltipText: getTranslations('REPORT_ENABLED_USERS_INFO'),
    },
    {
      description: getTranslations('REPORT_ACTIVE_USERS'),
      iconName: IconNames.UserActive,
      mainText: activeUsers.length.toString(),
      tooltipText: getTranslations('REPORT_ACTIVE_USERS_INFO'),
    },
    {
      description: getTranslations('REPORT_USER_AVERAGE_TIME'),
      iconName: IconNames.Frequency,
      mainText: `${timeValue}`,
      tooltipText: getTranslations('REPORT_USER_AVERAGE_TIME_INFO'),
    },
  ];
  return <StatsRow statsData={stats} />;
};
