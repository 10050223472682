import { Dispatch } from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Microservices } from "../../app/AllowedMicroservices";
import { RootState } from "../../app/store";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { FetchStatus } from "../common/enums";

interface LanguagesState {
  status: FetchStatus;
  loginLanguage: string;
  languages: string[];
}

const initialState: LanguagesState = {
  status: FetchStatus.Idle,
  loginLanguage: 'es',
  languages: ['es'],
};

export const LanguagesSlice = createSlice({
  name: "languages",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
      state.languages = [];
    },
    fetched: (state, action: PayloadAction<string[]>) => {
      state.status = FetchStatus.Fetched;
      state.languages = action.payload;
    },
    addNewLanguage: (state, action: PayloadAction<string>) => {
      state.languages.push(action.payload);
    },
    error: (state) => {
      state.status = FetchStatus.Error;
    },
  },
});

const BASE_ENDPOINT = "/translations";
export const {  fetching, error, fetched, addNewLanguage } = LanguagesSlice.actions;

export const fetchLanguages = () => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_ENDPOINT}/languages`,
      types: {
        requestType:  fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.COMPANY_SERVICE,
      authenticated: false,
    })
  );
};

export const selectLanguagesIsFetching = (state: RootState) =>
  state.languages.status === FetchStatus.Fetching;

export const selectLanguages = (state: RootState) =>
  state.languages.languages;

export default LanguagesSlice.reducer;
