import React, { useEffect } from "react";
import { CogIcon } from "../common/CogIcon";
import { Link } from 'react-router-dom';

import styles from "./welcomeMenu.module.scss";
import { ActionSuffixes, Prefixes } from "../prefixes";
import { getTranslations } from "../../features/translations/translationsUtils";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompanies, selectCompanies } from "../../features/company/companySlice";
import { getCognitoUserData } from "../../features/auth/authSlice";
import { UserRole } from "../../features/users/usersSlice";

interface IMenuBlock {
  titleIcon: string;
  title: string;
  children: React.ReactElement
}

function MenuBlock({ children, title, titleIcon }: IMenuBlock) {
  return <div className={styles.menu}>
    <div className={styles.title}>
      <div className={styles.icon}>
        <CogIcon icon={titleIcon} />
      </div>
      {title}
    </div>
    <div className={styles.links}>
      {children}
    </div>
  </div>;
}
export function WelcomeMenu() {
  const cognitoUserData = useSelector(getCognitoUserData);
  const role = cognitoUserData ? cognitoUserData["custom:role"] : undefined;
  const companiesDict = useSelector(selectCompanies);
  const companyId = cognitoUserData
    ? cognitoUserData["custom:company"]
    : null;

  const company = companiesDict[companyId ?? ''];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCompanies());
  }, [dispatch]);

  const { showGamification, showKnowledgeBase } = company ?? {};

  const isSuperAdmin = role === UserRole.SuperAdmin;
  const isCompanyAdmin = role === UserRole.CompanyAdmin;

  const isSuperAdminOrCompanyAdmin = isCompanyAdmin || isSuperAdmin;
  const hasNotification = isSuperAdmin || company?.notificationTypesEnabled?.email ||
    company?.notificationTypesEnabled?.sms ||
    company?.notificationTypesEnabled?.web;
  return <div className={styles.container}>
    <div className={styles.label}>{getTranslations('ADMIN_HOME_MENU')}</div>
    <div className={styles.row}>
      {MenuBlock({
        title: getTranslations('ADMIN_HOME_ADMIN'),
        titleIcon: 'empresa',
        children: <>
          <div className={styles.column}>
            {isSuperAdmin ? <div className={styles.link}>
              <Link to={`${Prefixes.company}${ActionSuffixes.new}`}>{getTranslations('ADMIN_HOME_NEW_COMPANY')}</Link>
            </div> : null}
            {isSuperAdminOrCompanyAdmin ? <div className={styles.link}>
              <Link to={`${Prefixes.user}${ActionSuffixes.new}`}>{getTranslations('ADMIN_HOME_NEW_USER')}</Link>
            </div> : null}
            {isSuperAdminOrCompanyAdmin ? <div className={styles.link}>
              <Link to={`${Prefixes.group}${ActionSuffixes.new}`}>{getTranslations('ADMIN_HOME_NEW_GROUP')}</Link>
            </div> : null}
            {hasNotification ? <div className={styles.link}>
              <Link to={`${Prefixes.notification}`}>{getTranslations('ADMIN_HOME_NEW_NOTIFICATIONS')}</Link>
            </div> : null}
          </div>
        </>,
      })}
      {isSuperAdminOrCompanyAdmin ? MenuBlock({
        title: getTranslations('ADMIN_HOME_TRAINING'),
        titleIcon: 'book',
        children: <>
          <div className={styles.column}>
            {isSuperAdmin ? <div className={styles.link}>
              <Link to={`${Prefixes.program}${ActionSuffixes.new}`}>{getTranslations('ADMIN_HOME_NEW_PROGRAM')}</Link>
            </div> : null}
            <div className={styles.link}>
              <Link to={`${Prefixes.course}${ActionSuffixes.new}`}>{getTranslations('ADMIN_HOME_NEW_COURSE')}</Link>
            </div>
            <div className={styles.link}>
              <Link to={`${Prefixes.topic}${ActionSuffixes.new}`}>{getTranslations('ADMIN_HOME_NEW_TOPIC')}</Link>
            </div>
            <div className={styles.link}>
              <Link to={`${Prefixes.campaign}${ActionSuffixes.new}`}>{getTranslations('ADMIN_HOME_NEW_CAMPAIGN')}</Link>
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.link}>
              <Link to={`${Prefixes.lesson}${ActionSuffixes.new}`}>{getTranslations('ADMIN_HOME_NEW_LESSON')}</Link>
            </div>
            <div className={styles.link}>
              <Link to={`${Prefixes.evaluation}${ActionSuffixes.new}`}>{getTranslations('ADMIN_HOME_NEW_EVALUATION')}</Link>
            </div>
            {showKnowledgeBase ? <div className={styles.link}>
              <Link to={`${Prefixes.knowledgeBase}${ActionSuffixes.new}`}>{getTranslations('ADMIN_HOME_NEW_KNOWLEDGE_BASE')}</Link>
            </div> : showKnowledgeBase}
          </div>
        </>,
      }) : null}
    </div>
    <div className={styles.row}>
      {MenuBlock({
        title: getTranslations('ADMIN_HOME_REPORTS_AND_DASHBOARDS'),
        titleIcon: 'Chart',
        children: <>
          <div className={styles.column}>
            <div className={styles.link}>
              <Link to={`${Prefixes.reports}${Prefixes.user}`}>{getTranslations('ADMIN_HOME_REPORT_USERS')}</Link>
            </div>
            {isSuperAdmin ? <div className={styles.link}>
              <Link to={`${Prefixes.reports}${Prefixes.company}`}>{getTranslations('ADMIN_HOME_REPORT_COMPANIES')}</Link>
            </div> : null}
            {isSuperAdmin ? <div className={styles.link}>
              <Link to={`${Prefixes.reports}${Prefixes.educationalContent}`}>{getTranslations('ADMIN_HOME_REPORT_EDUCATIONAL_CONTENT')}</Link>
            </div> : null}
            <div className={styles.link}>
              <Link to={`${Prefixes.reports}${Prefixes.lesson}`}>{getTranslations('ADMIN_HOME_REPORT_LESSONS')}</Link>
            </div>
            {isSuperAdminOrCompanyAdmin ? <div className={styles.link}>
              <Link to={`${Prefixes.reports}${Prefixes.reportedCases}`}>{getTranslations('ADMIN_MAIN_MENU_REPORTED_CASES')}</Link>
            </div> : null}
          </div>
          <div className={styles.column}>
            <div className={styles.link}>
              <Link to={`${Prefixes.reports}${Prefixes.topic}`}>{getTranslations('ADMIN_HOME_REPORT_TOPICS')}</Link>
            </div>
            <div className={styles.link}>
              <Link to={`${Prefixes.reports}${Prefixes.course}`}>{getTranslations('ADMIN_HOME_REPORT_COURSES')}</Link>
            </div>
            <div className={styles.link}>
              <Link to={`${Prefixes.reports}${Prefixes.knowledgeBase}`}>{getTranslations('ADMIN_HOME_REPORT_KNOWLEDGE_BASE')}</Link>
            </div>
            <div className={styles.link}>
              <Link to={`${Prefixes.reports}${Prefixes.reportCampaign}`}>{getTranslations('ADMIN_HOME_REPORT_CAMPAIGNS')}</Link>
            </div>
          </div>
        </>,
      })}
      {isSuperAdminOrCompanyAdmin ? MenuBlock({
        title: getTranslations('ADMIN_HOME_CONFIG'),
        titleIcon: 'config',
        children: <>
          <div className={styles.column}>
            <div className={styles.link}>
              <Link to="/company-customize" >{getTranslations('ADMIN_HOME_STYLES_CONFIG')}</Link>
            </div>
            {showGamification ? <div className={styles.link}>
              <Link to="/gamification" >{getTranslations('ADMIN_HOME_GAMIFICATION')}</Link>
            </div> : null}
            {/* <div className={styles.link}>
            <Link to="/" >{getTranslations('ADMIN_HOME_REPORTS_PANEL')}</Link>
          </div>
          <div className={styles.link}>
            <Link to="/" >{getTranslations('ADMIN_HOME_RISK_ASSESSMENT_SYSTEM')}</Link>
          </div> */}
          </div>
        </>,
      }) : null}
    </div>
  </div>
}