import React, { useEffect } from "react";
import { Form, Input, Col, Row, Dropdown, Menu, Button, Alert } from "antd";
import { FormInstance } from "antd/lib/form";
import { useDispatch, useSelector } from "react-redux";
import cls from 'classnames';

import {
  fetchCompanies,
  selectCompany,
} from "../../features/company/companySlice";
import { selectCoursesDict } from "../../features/courses/coursesSlice";
import { IGroup } from "../../features/groups/groupSlice";
import { selectProgramsDict } from "../../features/program/programSlice";
import { CompanyField } from "../common/CompanyField";
import { MIN_3_CHAR } from "../Users/Form";
import { CogIcon } from "../common/CogIcon";

import formStyles from "../common/form.module.scss";
import styles from "./from.module.scss";
import { getTranslations } from "../../features/translations/translationsUtils";

interface GroupFormProps {
  newGroup?: IGroup;
  setNewGroup: Function;
  form: FormInstance;
}

export const GroupForm: React.FunctionComponent<GroupFormProps> = (props) => {
  const { setNewGroup, newGroup, form } = props;
  const programs = useSelector(selectProgramsDict);
  const courses = useSelector(selectCoursesDict);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCompanies());
  }, [dispatch]);
  const company = useSelector(selectCompany(newGroup?.company || null));
  let coursesAvailable: string[] = [];
  if (company) {
    const program = programs[company.studyProgram];
    if (program) {
      coursesAvailable = [...program.courses, ...program.teuronaCourses];
    }
  }
  const coursesSelectorIsDisabled = coursesAvailable.length === 0;
  const menuItems = coursesAvailable.filter((courseId) => {
    return !(newGroup?.courses ?? []).includes(courseId);
  }).map((courseId) => {
    return (
      <Menu.Item
        key={courseId}
        onClick={() => {
          const changedGroup = { ...newGroup, courses: [...(newGroup?.courses ?? []), courseId] };
          setNewGroup(changedGroup);
          form.setFieldValue('courses', changedGroup.courses);
        }}
      >
        {courses[courseId]?.title}
      </Menu.Item>
    );
  });
  const menu = <Menu
    onClick={({ key }) => {
      if (coursesAvailable.length === 0) {
        return;
      }
      const changedGroup = { ...newGroup, courses: [...(newGroup?.courses ?? []), key] };
      setNewGroup(changedGroup);
      form.setFieldValue('courses', changedGroup.courses);
    }}
  >
    {menuItems}
  </Menu>;
  return (
    <Form
      form={form}
      className={formStyles.form}
      layout="vertical"
      name="control-hooks"
    >
      <Row gutter={50}>
        <Col span={10}>
          <div className={formStyles.section}>
            <div className={formStyles.sectionTitle}>{getTranslations('GROUP_BASIC_INFO')}</div>
            <Form.Item
              initialValue={newGroup?.name}
              name="name"
              label={getTranslations('GROUP_NAME')}
              rules={[
                { required: true, message: getTranslations('GENERAL_REQUIRED') },
                { type: "string", min: 3, message: MIN_3_CHAR() },
                { type: "string", max: 50, message: getTranslations('GROUP_NAME_ERROR_1') },
              ]}
            >
              <Input
                onChange={(event) => {
                  setNewGroup({ ...newGroup, name: event.target.value });
                }}
                type="text"
                placeholder={getTranslations('GROUP_NAME')}
              />
            </Form.Item>
            <CompanyField
              newObject={newGroup}
              value={newGroup?.company}
              resetForm={(companyId: string) => {
                form.setFieldValue('company', companyId);
              }}
              setNewObject={(newGroup: IGroup) => {
                setNewGroup({ ...newGroup, courses: [] });
                form.setFieldsValue({
                  courses: [],
                });
              }}
            />
          </div>
        </Col>
        <Col span={14}>
          <div className={styles.selectedCourses}>
            {newGroup?.courses?.map((courseId, index) => {
              return <div key={courseId} className={cls(formStyles.section, styles.selectedCourse)}>
                <div className={formStyles.header}>
                  <div className={formStyles.pillNumber}>
                    <CogIcon size={18} icon="drag" /><span className={formStyles.number}>{index + 1}</span>
                  </div>
                  <div className={formStyles.actionContainer}>
                    <span
                      className={formStyles.action}
                      onClick={() => {
                        const currentCourses = [...(newGroup?.courses ?? [])];
                        currentCourses.splice(index, 1);
                        setNewGroup({ ...newGroup, courses: currentCourses })
                      }}
                    >
                      <CogIcon size={18} icon="Cross" />
                    </span>
                  </div>
                </div>
                <div className={styles.title}>
                  {courses[courseId]?.title}
                </div>
                <div className={styles.description}>
                  {courses[courseId]?.description}
                </div>
              </div>;
            })}
          </div>
          <Form.Item
            initialValue={newGroup?.courses}
            name="courses"
            label={getTranslations('GROUP_COURSES')}
            rules={[{ required: true, message: getTranslations('GENERAL_REQUIRED') }]}
          >
            {!coursesSelectorIsDisabled ? <Dropdown
              overlay={menu}
              overlayClassName={styles.courseMenu}
              disabled={menuItems.length === 0}
              trigger={["click"]}
            >
              <Button
                disabled={menuItems.length === 0}
                className={cls(styles.addCourseButton, formStyles.addNewButton)}
                type="default"
              >
                {getTranslations('GROUP_ADD_COURSES')}
                <CogIcon
                  className={formStyles.square}
                  color="var(--secondary-text-button-color)"
                  icon="Arrow-drop-down"
                />
              </Button>
            </Dropdown> : <Alert message={getTranslations('GROUP_SELECT_COMPANY')} type="info" />}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
