import React, { FunctionComponent, useEffect } from "react";

import { useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import cls from 'classnames';

import { UserData } from "../UserData";
import { Progress } from "../Progress";
import { CurrentCourse } from "./CurrentCourse";
import { fetchStudentHomeCourses, selectAreStudentHomeCoursesFetching } from "../../../features/students/homeCoursesSlice";
import { fetchCategories, selectCategoriesAreFetching } from "../../../features/category/categorySlice";
import { fetchStudentMyCourses, selectAreStudentMyCoursesFetching } from "../../../features/students/myCoursesSlice";
import { Options } from "./Options";
import { CoursesBlock } from "./CoursesBlock";
import LoadingOverlay from "../../common/LoadingOverlay";
import { MyCourses } from "./MyCourses";

import styles from "./index.module.scss";
import { fetchHomeCampaigns, selectAreCampaignsFetching } from "../../../features/students/campaignSlice";
import { CampaignsBlock } from "./CampaignsBlock";
import { InterestingContent } from "./InterestingContent";
import { fetchKnowledgeBasesHome, selectIsKnowledgeBaseFetching } from "../../../features/students/knowledgeBaseSlice";
import { fetchContentCategories, selectAreContentCategoriesFetching } from "../../../features/contentCategory/contentCategorySlice";
import { MinFooter } from "./MiniFooter";
import { useDeviceSize } from "../../utils/useDeviceSize";
import { SIZES } from "../../common/screen";
import { OtherLinks } from "./OtherLinks";
import ReportCaseButton from "../../ReportCaseButton";
import { getCognitoUserData } from "../../../features/auth/authSlice";
import { selectCompanies } from "../../../features/company/companySlice";

export const StudentHome: FunctionComponent = () => {
  const cognitoUserData = useSelector(getCognitoUserData);
  const companiesDict = useSelector(selectCompanies);
  const dispatch = useDispatch();
  const { path } = useRouteMatch<{ tab: string }>();
  const myCoursesFetching = useSelector(selectAreStudentMyCoursesFetching);
  const categoriesFetching = useSelector(selectCategoriesAreFetching)
  const homeCoursesFetching = useSelector(selectAreStudentHomeCoursesFetching);
  const homeCampaignsFetching = useSelector(selectAreCampaignsFetching);
  const homeKnowledgeBaseFetching = useSelector(selectIsKnowledgeBaseFetching);
  const homeKnowledgeBaseCategoriesFetching = useSelector(selectAreContentCategoriesFetching);
  const size = useDeviceSize();

  const isLoadingCenterColumn = myCoursesFetching || categoriesFetching || homeCoursesFetching || homeCampaignsFetching || homeKnowledgeBaseFetching;
  const isLoadingRightColumn = homeKnowledgeBaseCategoriesFetching;
  useEffect(() => {
    dispatch(fetchStudentHomeCourses());
    dispatch(fetchCategories());
    dispatch(fetchStudentMyCourses());
    dispatch(fetchHomeCampaigns());
    dispatch(fetchKnowledgeBasesHome());
    dispatch(fetchContentCategories());
  }, [dispatch]);

  const isHome = path === '/home';

  const companyId = cognitoUserData
    ? cognitoUserData["custom:company"]
    : null;
  const company = companiesDict[companyId ?? ''];
  const { showGamification } = company ?? {};

  const a: any = [];
  for (let i = 0; i < 100; ++i) {
    a.push(<div key={i}>For next version</div>);
  }
  const renderLoading = () => {
    return <LoadingOverlay
      spinning
      inline
      height="calc(100vh - 280px)"
      minHeight={200}
    />
  }
  const renderHome = (isMobile: boolean) => {
    if (isLoadingCenterColumn) {
      return renderLoading();
    }
    if (isMobile) {
      return <>
        <CurrentCourse />
        <CoursesBlock />
        <InterestingContent />
        <OtherLinks />
        <CampaignsBlock />
      </>;
    }
    return <>
      <CurrentCourse />
      <CoursesBlock />
      <CampaignsBlock />
    </>;
  }
  const renderMyCourses = () => {
    if (isLoadingCenterColumn) {
      return renderLoading();
    }
    return <MyCourses />;
  }
  const renderRightColumn = () => {
    if (isLoadingRightColumn) {
      return renderLoading();
    }
    return <>
      <InterestingContent />
      <OtherLinks />
      <MinFooter />
    </>;
  }

  if (size === SIZES.PHONE) {
    return <div className={cls(styles.container, styles.mobile)}>
    <ReportCaseButton />
      <div className={styles.firstColumn}>
        <div className={styles.fixedContainer}>
          <UserData />
          {showGamification ? <Progress /> : null}
        </div>
      </div>
      <div className={styles.secondColumn}>
        <Options />
        {isHome ? renderHome(true) : renderMyCourses()}
      </div>
    </div>
  }

  return <div className={cls(styles.container, styles.nomobile)}>
    <ReportCaseButton />
    <div className={styles.innerContainer}>
      <div className={styles.firstColumn}>
        <div className={styles.fixedContainer}>
          <UserData />
          {showGamification ? <Progress /> : null}
        </div>
      </div>
      <div className={styles.middleColumn}>
        <Options />
        {isHome ? renderHome(false) : renderMyCourses()}
      </div>
      <div className={styles.lastColumn}>
        {renderRightColumn()}
      </div>
    </div>
  </div>
};
