import { Button, Dropdown, Form, Input, Menu, Switch } from "antd";
import { FormInstance } from "antd/lib/form";
import cls from "classnames";
import React, { ReactNode } from "react";
import logo from "../../assets/logo-teurona.svg";
import { IBlueprint } from "../../features/blueprint/blueprintSlice";
import Template1 from "../Certificates/Template1";
import Template2 from "../Certificates/Template2";
import Template3 from "../Certificates/Template3";
import Template4 from "../Certificates/Template4";
import { CogIcon } from "../common/CogIcon";
import formStyles from "../common/form.module.scss";
import { MIN_3_CHAR } from "../Users/Form";
import styles from "./blueprintForm.module.scss";
import { getTranslations } from "../../features/translations/translationsUtils";

const TEMPLATE_NAMES: Record<string, string> = {
  template1: "Tipo 1",
  template2: "Tipo 2",
  template3: "Tipo 3",
  template4: "Tipo 4",
};

interface BlueprintFormProps {
  newBlueprint?: IBlueprint;
  setNewBlueprint: Function;
  form: FormInstance;
}

export const DUMMY_DATA = {
  courseName: "Nombre del curso culminado",
  description:
    "Descripción del curso culminado por un estudiante dela plataforma de capacitación teurona",
  totalHours: 160,
  name: "Nombre y apellido del estudiante",
  traineeNationalId: "111 111 111 11",
  companyName: "Nombre de la empresa S.A.",
  uniqueCode: "C0D1G0 UN1(O",
  createdAt: "DD/ MM/ AAAA",
  companyLogo: logo,
};

export const BlueprintForm: React.FunctionComponent<BlueprintFormProps> = (
  props
) => {
  const { setNewBlueprint, newBlueprint, form } = props;
  const TEMPLATES: Record<string, ReactNode> = {
    template1: newBlueprint && (
      <Template1 baseFontSizePx={4} {...newBlueprint} {...DUMMY_DATA} />
    ),
    template2: newBlueprint && (
      <Template2 baseFontSizePx={4} {...newBlueprint} {...DUMMY_DATA} />
    ),
    template3: newBlueprint && (
      <Template3 baseFontSizePx={5} {...newBlueprint} {...DUMMY_DATA} />
    ),
    template4: newBlueprint && (
      <Template4 baseFontSizePx={5} {...newBlueprint} {...DUMMY_DATA} />
    ),
  };

  const menu = (
    <Menu>
      {Object.entries(TEMPLATE_NAMES).map((template) => {
        return (
          <Menu.Item
            key={template[0]}
            onClick={() => {
              const bluePrint = { ...newBlueprint, blueprintId: template[0] };
              form.setFieldsValue(bluePrint);
              setNewBlueprint(bluePrint);
            }}
          >
            <p>{template[1]}</p>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <Form
      form={form}
      className={formStyles.form}
      layout="vertical"
      name="control-hooks"
    >
      <Form.Item
        initialValue={newBlueprint?.blueprintName}
        name="blueprintName"
        label="Nombre de la plantilla"
        rules={[
          { required: true, message: getTranslations('GENERAL_REQUIRED') },
          { type: "string", max: 100, message: "Máximo 100 caracteres" },
          { type: "string", min: 3, message: MIN_3_CHAR() },
        ]}
      >
        <Input
          onChange={(event) => {
            setNewBlueprint({
              ...newBlueprint,
              blueprintName: event.target.value,
            });
          }}
          type="text"
          placeholder="Nombre de la plantilla"
        />
      </Form.Item>

      <div className={styles.blueprintDropdown}>
        <Form.Item
          name="blueprintId"
          label={getTranslations('LIST_TYPE')}
          rules={[{ required: true, message: getTranslations('GENERAL_REQUIRED') }]}
          initialValue={newBlueprint?.blueprintId}
        >
          <Dropdown overlay={menu} trigger={["click"]}>
            <p className="ant-dropdown-link">
              <Button className={styles.typeButton}>
                {newBlueprint?.blueprintId
                  ? TEMPLATE_NAMES[newBlueprint.blueprintId]
                  : "Seleccione una plantilla"}
                <CogIcon icon="Chevron-down" />
              </Button>
            </p>
          </Dropdown>
        </Form.Item>
      </div>

      <div
        className={cls(
          styles.templateWrapper,
          !newBlueprint?.blueprintId && styles.empty
        )}
      >
        {newBlueprint?.blueprintId && TEMPLATES[newBlueprint.blueprintId]}
      </div>

      <div className={styles.options}>
        <Form.Item
          initialValue={newBlueprint?.showName}
          wrapperCol={{ sm: 24 }}
        >
          <div className={styles.displayField}>
            <div>Nombre del curso</div>
            <div>
              <Switch
                checked={newBlueprint?.showName}
                onChange={(value) => {
                  setNewBlueprint({ ...newBlueprint, showName: value });
                }}
              />
            </div>
          </div>
        </Form.Item>
        <Form.Item
          initialValue={newBlueprint?.showDescription}
          wrapperCol={{ sm: 24 }}
        >
          <div className={styles.displayField}>
            <div>Descripción del curso</div>
            <div>
              <Switch
                checked={newBlueprint?.showDescription}
                onChange={(value) => {
                  setNewBlueprint({ ...newBlueprint, showDescription: value });
                }}
              />
            </div>
          </div>
        </Form.Item>
        <Form.Item
          initialValue={newBlueprint?.showTotalHours}
          wrapperCol={{ sm: 24 }}
        >
          <div className={styles.displayField}>
            <div>Horas de estudio</div>
            <div>
              <Switch
                checked={newBlueprint?.showTotalHours}
                onChange={(value) => {
                  setNewBlueprint({ ...newBlueprint, showTotalHours: value });
                }}
              />
            </div>
          </div>
        </Form.Item>
        <Form.Item
          initialValue={newBlueprint?.showTraineeFullName}
          wrapperCol={{ sm: 24 }}
        >
          <div className={styles.displayField}>
            <div>Nombre y apellido del participante</div>
            <div>
              <Switch
                checked={newBlueprint?.showTraineeFullName}
                onChange={(value) => {
                  setNewBlueprint({
                    ...newBlueprint,
                    showTraineeFullName: value,
                  });
                }}
              />
            </div>
          </div>
        </Form.Item>
        <Form.Item
          initialValue={newBlueprint?.showTraineeNationalId}
          wrapperCol={{ sm: 24 }}
        >
          <div className={styles.displayField}>
            <div>Número de identificación del participante</div>
            <div>
              <Switch
                checked={newBlueprint?.showTraineeNationalId}
                onChange={(value) => {
                  setNewBlueprint({
                    ...newBlueprint,
                    showTraineeNationalId: value,
                  });
                }}
              />
            </div>
          </div>
        </Form.Item>
        <Form.Item
          initialValue={newBlueprint?.showCompanyName}
          wrapperCol={{ sm: 24 }}
        >
          <div className={styles.displayField}>
            <div>Nombre de la empresa</div>
            <div>
              <Switch
                checked={newBlueprint?.showCompanyName}
                onChange={(value) => {
                  setNewBlueprint({ ...newBlueprint, showCompanyName: value });
                }}
              />
            </div>
          </div>
        </Form.Item>
        <Form.Item
          initialValue={newBlueprint?.showNameAndLogo}
          wrapperCol={{ sm: 24 }}
        >
          <div className={styles.displayField}>
            <div>Nombre y logo de Cognizant</div>
            <div>
              <Switch
                checked={newBlueprint?.showNameAndLogo}
                onChange={(value) => {
                  setNewBlueprint({ ...newBlueprint, showNameAndLogo: value });
                }}
              />
            </div>
          </div>
        </Form.Item>
        <Form.Item
          initialValue={newBlueprint?.showUniqueCode}
          wrapperCol={{ sm: 24 }}
        >
          <div className={styles.displayField}>
            <div>Código único</div>
            <div>
              <Switch
                checked={newBlueprint?.showUniqueCode}
                onChange={(value) => {
                  setNewBlueprint({ ...newBlueprint, showUniqueCode: value });
                }}
              />
            </div>
          </div>
        </Form.Item>
        <Form.Item
          initialValue={newBlueprint?.showQrCode}
          wrapperCol={{ sm: 24 }}
        >
          <div className={styles.displayField}>
            <div>Código QR</div>
            <div>
              <Switch
                checked={newBlueprint?.showQrCode}
                onChange={(value) => {
                  setNewBlueprint({ ...newBlueprint, showQrCode: value });
                }}
              />
            </div>
          </div>
        </Form.Item>
      </div>
    </Form>
  );
};
