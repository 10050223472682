import React, { FunctionComponent } from "react";

import styles from './badge.module.scss';

import first from '../../../assets/infographic/gold.png';
import second from '../../../assets/infographic/silver.png';
import thrid from '../../../assets/infographic/bronze.png';


interface MiniBadgeProps {
  position: number;
};
const getImage = (position: number) => {
  if (position === 1) {
    return first;
  }
  if (position === 2) {
    return second;
  }
  if (position === 3) {
    return thrid;
  }
  return false;
}
export const MiniBadge: FunctionComponent<MiniBadgeProps> = ({
  position
}) => {
  const image = getImage(position);
  if (!image) {
    return <div className={styles.badge}>
      <span className={styles.number}>{position}</span>
  </div>;
  }
  return <div className={styles.badge}>
    <img src={image}  alt="position"/>
  </div>;
};