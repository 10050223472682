import React, { FunctionComponent, useState } from "react";

import cls from 'classnames';

import styles from './rank.module.scss';
import { MiniBadge } from "./MiniBadge";
import { Ranking } from "./Ranking";
import { useSelector } from "react-redux";
import { selectUserRanking } from "../../../features/students/rankingSlice";
import { getCognitoUserData } from "../../../features/auth/authSlice";
import { selectUser } from "../../../features/users/usersSlice";
import { getTranslations } from "../../../features/translations/translationsUtils";

interface IRanksProps {
  isMobile: boolean;
  userId?: string;
}

export const Ranks: FunctionComponent<IRanksProps> = ({
  isMobile,
  userId,
}) => {
  const cognitoUserData = useSelector(getCognitoUserData);
  const cognitoUsername = cognitoUserData
    ? cognitoUserData["cognito:username"]
    : null;
  const userFromStore = useSelector(selectUser(cognitoUsername));
  const userRanking = useSelector(selectUserRanking(userId ?? userFromStore?.username));
  const [opened, setOpened] = useState(false);
  return <>
    <div className={cls(styles.container, { [styles.mobile]: isMobile })}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{getTranslations('STUDENT_PROFILE_RANKING')}</div>
        <div className={styles.viewRankings} onClick={() => {
          setOpened(true);
        }}>{getTranslations('STUDENT_PROFILE_VIEW_RANKING')}</div>
      </div>
      <div className={styles.values}>
        <div className={styles.row}>
          <div className={styles.value}>
            <div className={styles.icon}><MiniBadge position={userRanking.points?.position ?? 0} /></div>
            <div className={styles.description}>{getTranslations('STUDENT_PROFILE_POINTS')}</div>
          </div>
          <div className={styles.value}>
            <div className={styles.icon}><MiniBadge position={userRanking.courses?.position ?? 0} /></div>
            <div className={styles.description}>{getTranslations('STUDENT_PROFILE_COURSES')}</div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.value}>
            <div className={styles.icon}><MiniBadge position={userRanking.badges?.position ?? 0} /></div>
            <div className={styles.description}>{getTranslations('STUDENT_PROFILE_BADGES')}</div>
          </div>
          <div className={styles.value}>
            <div className={styles.icon}><MiniBadge position={userRanking.certificates?.position ?? 0} /></div>
            <div className={styles.description}>{getTranslations('STUDENT_PROFILE_CERTIFICATES')}</div>
          </div>
        </div>
      </div>
    </div>
    <Ranking opened={opened} close={() => {
      setOpened(false);
    }} isMobile={isMobile} />
  </>;
};