import { Form, Input, Select } from "antd";
import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { selectUserRole } from "../../../features/auth/authSlice";
import { ICategoriesDict } from "../../../features/category/categorySlice";
import { ICourse } from "../../../features/courses/coursesSlice";
import { IContent } from "../../../features/knowledgeBase/contentSlice";
import { ILesson } from "../../../features/lesson/lessonSlice";
import { ITopic } from "../../../features/topic/topicSlice";
import { UserRole } from "../../../features/users/usersSlice";
import { getTranslations } from "../../../features/translations/translationsUtils";

export const OTHER_CATEGORY_OPTION = "OTHER_CATEGORY_OPTION";

interface CategoryFieldProps {
  setObject: Function;
  label: string;
  categories: ICategoriesDict;
  categoriesIds: string[];
  markRequired?: boolean;
  object?: ICourse | ITopic | ILesson | IContent;
  initialValue?: boolean;
}

export const CategoryField: FunctionComponent<CategoryFieldProps> = ({
  object,
  setObject,
  label,
  markRequired,
  categoriesIds,
  categories,
  initialValue = true,
}) => {
  const userRole = useSelector(selectUserRole);
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setObject({ ...object, categoryName: event.target.value });
  };

  return (
    <React.Fragment>
      <Form.Item
        label={label}
        name={"category"}
        rules={[{ required: markRequired, message: getTranslations('GENERAL_REQUIRED') }]}
        initialValue={initialValue ? object?.category?.toString() : undefined}
      >
        <Select
          placeholder={getTranslations('CATEGORY_FIELD_SELECT_ONE')}
          style={{ width: "100%" }}
          onChange={(value) => {
            setObject({ ...object, category: value, categoryName: "" });
          }}
        >
          {categoriesIds.map((id) => {
            return (
              <Select.Option key={id} value={id}>
                {categories[id].name}
              </Select.Option>
            );
          })}
          {userRole === UserRole.SuperAdmin ? <Select.Option
            key={OTHER_CATEGORY_OPTION}
            value={OTHER_CATEGORY_OPTION}
          >
            {getTranslations('CATEGORY_FIELD_OTHER')}
          </Select.Option> : null}
        </Select>
      </Form.Item>
      {object?.category === OTHER_CATEGORY_OPTION && (
        <Form.Item
          label={getTranslations('CATEGORY_FIELD_NEW')}
          name={"newCategory"}
          rules={[
            { required: markRequired, message: getTranslations('GENERAL_REQUIRED') },
            {
              validator: (_rule, value: string = "") => {
                return new Promise((resolve, reject) => {
                  const MAX_CHAR = 30;
                  if (value.length > MAX_CHAR) {
                    return reject(getTranslations('CATEGORY_FIELD_ERROR'));
                  }
                  return resolve(true);
                });
              },
            },
          ]}
        >
          <Input onChange={onChange} />
        </Form.Item>
      )}
    </React.Fragment>
  );
};
