import { Divider, Form, Input, Select, Switch } from "antd";
import { FormInstance } from "antd/lib/form";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ITeacher, selectTeachers } from "../../features/teacher/teacherSlice";
import formStyles from "../common/form.module.scss";
import { UploadImage } from "../common/UploadImage";
import { MIN_3_CHAR } from "../Users/Form";
import styles from "./form.module.scss";
import { getTranslations } from "../../features/translations/translationsUtils";

interface TeacherFieldProps {
  teacher: string | ITeacher | undefined;
  form: FormInstance;
  isEditing: boolean;
  displayDropdown: boolean;
  setDisplayDropdown: React.Dispatch<React.SetStateAction<boolean>>;
  onUpdateTeacher(teacher: string | ITeacher | undefined): void;
}

const EMPTY_TEACHER = {
  firstName: "",
  lastName: "",
  middleName: "",
  secondLastName: "",
  description: "",
};
const NEW_PROFESOR = "NEW_PROFESOR";

export const TeacherFields: React.FunctionComponent<TeacherFieldProps> = ({
  teacher,
  form,
  displayDropdown,
  setDisplayDropdown,
  onUpdateTeacher,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const teachers = useSelector(selectTeachers);
  const teacherInitialValue = (() => {
    if (typeof teacher === "object") {
      return teacher;
    } else if (typeof teacher === "string") {
      return teachers.find(
        (teacherItem) => teacherItem._id === teacher
      );
    }
  })();
  const [currentTeacher, setCurrentTeacher] = useState<ITeacher>(
    teacherInitialValue || EMPTY_TEACHER
  );

  const handleSelection = (value: boolean) => {
    setDisplayDropdown(value);
    if (!value) {
      form.setFieldsValue({
        teacher: undefined,
      });
      onUpdateTeacher(undefined);
      setCurrentTeacher(EMPTY_TEACHER);
      form.setFieldsValue({
        firstName: undefined,
        middleName: undefined,
        lastName: undefined,
        secondLastName: undefined,
        teacherDescription: undefined,
        picture: undefined,
      });
    }
  };
  const handleChangeFile = (info: any) => {
    const { status } = info.file;
    if (status === "uploading") {
      setLoading(true);
      setError("");
      return;
    }
    if (status === "done") {
      setLoading(false);
      setCurrentTeacher({ ...currentTeacher, picture: info.file.response.url });
      if (typeof teacher === "object") {
        onUpdateTeacher({ ...teacher, picture: info.file.response.url });
      }
    } else if (status === "error") {
      setError("Error al subir imagen");
    }
  };
  if (!displayDropdown) {
    return <>
      <div className={styles.switchContainer}>
      <span className={formStyles.sectionTitle}>{getTranslations('TEACHER_INFO')}</span>
        <Switch
          checked={displayDropdown}
          onChange={(value) => handleSelection(value)}
        />
      </div>
      <div className={formStyles.separator} />
    </>;
  }
  return (
    <>
      <div className={styles.switchContainer}>
        <span className={formStyles.sectionTitle}>{getTranslations('TEACHER_INFO')}</span>
        <Switch
          checked={displayDropdown}
          onChange={(value) => handleSelection(value)}
        />
      </div>
      <Form.Item label={getTranslations('TEACHER_TITLE')} name="teacher">
        <Select
          placeholder={getTranslations('TEACHER_SELECT_ONE')}
          style={{ width: "100%" }}
          onChange={(value) => {
            if (value === NEW_PROFESOR) {
              form.setFieldsValue({
                teacher: undefined,
              });
              setCurrentTeacher(EMPTY_TEACHER);
              form.setFieldsValue({
                firstName: undefined,
                middleName: undefined,
                lastName: undefined,
                secondLastName: undefined,
                teacherDescription: undefined,
                picture: undefined,
              });
            }
            onUpdateTeacher(value?.toString());
            const selectedTeacher = teachers.find(
              (teacher) => teacher._id === value
            );
            if (selectedTeacher) {
              form.setFieldsValue({
                firstName: selectedTeacher.firstName,
                middleName: selectedTeacher.middleName,
                lastName: selectedTeacher.lastName,
                secondLastName: selectedTeacher.secondLastName,
                teacherDescription: selectedTeacher.description,
                picture: selectedTeacher.picture,
              });
              setCurrentTeacher(selectedTeacher);
            }
          }}
        >
          <Select.Option key={NEW_PROFESOR} value={NEW_PROFESOR}>
          {getTranslations('TEACHER_NEW')}
          </Select.Option>
          {teachers.map((teacher) => {
            return (
              <Select.Option key={teacher._id} value={teacher._id ?? ""}>
                {`${teacher.firstName} ${teacher.lastName}`}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      {teacher ? (
        <div className={styles.teacherContainer}>
          <Divider />
          <Form.Item
            name="picture"
          >
            <UploadImage
              loading={loading}
              imageUrl={currentTeacher.picture}
              onHandleChange={handleChangeFile}
            />
          </Form.Item>
          {error !== "" && <p>{error}</p>}
          <Form.Item
            name="firstName"
            label={getTranslations('TEACHER_FIRST_NAME')}
            rules={[
              { required: true, message: getTranslations('GENERAL_REQUIRED') },
              { type: "string", min: 3, message: MIN_3_CHAR() },
              { type: "string", max: 15, message: getTranslations('TEACHER_FIRST_NAME_ERROR_1') },
            ]}
          >
            <Input
              onChange={(event) => {
                const updatedTeacher = {
                  ...currentTeacher,
                  firstName: event.target.value,
                };
                setCurrentTeacher(updatedTeacher);
                onUpdateTeacher(updatedTeacher);
              }}
              type="text"
              placeholder={getTranslations('TEACHER_FIRST_NAME')}
            />
          </Form.Item>
          <Form.Item
            name="middleName"
            label={getTranslations('TEACHER_SECOND_NAME')}
            rules={[
              { type: "string", min: 3, message: MIN_3_CHAR() },
              { type: "string", max: 15, message: getTranslations('TEACHER_SECOND_NAME_ERROR_1') },
            ]}
          >
            <Input
              onChange={(event) => {
                const updatedTeacher = {
                  ...currentTeacher,
                  middleName: event.target.value,
                };
                setCurrentTeacher(updatedTeacher);
                onUpdateTeacher(updatedTeacher);
              }}
              type="text"
              placeholder={getTranslations('TEACHER_SECOND_NAME')}
            />
          </Form.Item>
          <Form.Item
            name="lastName"
            label={getTranslations('TEACHER_LAST_NAME')}
            rules={[
              { required: true, message: getTranslations('GENERAL_REQUIRED') },
              { type: "string", min: 3, message: MIN_3_CHAR() },
              { type: "string", max: 15, message: getTranslations('TEACHER_LAST_NAME_ERROR_1') },
            ]}
          >
            <Input
              onChange={(event) => {
                const updatedTeacher = {
                  ...currentTeacher,
                  lastName: event.target.value,
                };
                setCurrentTeacher(updatedTeacher);
                onUpdateTeacher(updatedTeacher);
              }}
              type="text"
              placeholder={getTranslations('TEACHER_LAST_NAME')}
            />
          </Form.Item>
          <Form.Item
            name="secondLastName"
            label={getTranslations('TEACHER_SECOND_LAST_NAME')}
            rules={[
              { type: "string", min: 3, message: MIN_3_CHAR() },
              { type: "string", max: 15, message: getTranslations('TEACHER_SECOND_LAST_NAME_ERROR_1') },
            ]}
          >
            <Input
              onChange={(event) => {
                const updatedTeacher = {
                  ...currentTeacher,
                  secondLastName: event.target.value,
                };
                setCurrentTeacher(updatedTeacher);
                onUpdateTeacher(updatedTeacher);
              }}
              type="text"
              placeholder={getTranslations('TEACHER_SECOND_LAST_NAME')}
            />
          </Form.Item>
          <Form.Item
            name="teacherDescription"
            label={getTranslations('TEACHER_DESCRIPTION')}
            rules={[
              { type: "string", max: 1000, message: getTranslations('TEACHER_DESCRIPTION_ERROR_1') },
              { type: "string", min: 3, message: MIN_3_CHAR() },
            ]}
          >
            <Input.TextArea
              onChange={(event) => {
                const updatedTeacher = {
                  ...currentTeacher,
                  description: event.target.value,
                };
                setCurrentTeacher(updatedTeacher);
                onUpdateTeacher(updatedTeacher);
              }}
              placeholder={getTranslations('TEACHER_DESCRIPTION')}
            />
          </Form.Item>
        </div>
      ) : null}
      <div className={formStyles.separator} />
    </>
  );
};
