import { createSlice } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../app/AllowedMicroservices";
import { AppThunk, RootState } from "../../app/store";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { FetchStatus, ModalStatus } from "../common/enums";
import { fetchCompanies, ICompanyCustomize, ICompanyLink } from "../company/companySlice";
import { MODAL_DELAY_MS } from "../../utils/constants";





interface CampaignState {
  status: FetchStatus;
  modalStatus: ModalStatus;
}

const initialState: CampaignState = {
  status: FetchStatus.Idle,
  modalStatus: ModalStatus.Hide,
};

export const customizeSlice = createSlice({
  name: "gamification",
  initialState,
  reducers: {
    saving: (state) => {
      state.status = FetchStatus.Fetching;
    },
    saved: (state) => {
      state.status = FetchStatus.Fetched;
    },
    error: (state) => {
      state.status = FetchStatus.Error;
    },
    showModal: (state) => {
      state.modalStatus = ModalStatus.Show;
    },
    hideModal: (state) => {
      state.modalStatus = ModalStatus.Hide;
    }
  },
});

const BASE_ENDPOINT = "/companies";
// export const { fetch } = gamificationSlice.actions;
export const { saving, error, saved, showModal, hideModal } = customizeSlice.actions;


const hideModalWithDelay = (): AppThunk => async (dispatch) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      dispatch(hideModal());
      resolve();
    }, MODAL_DELAY_MS);
  });
};

const saveLinks = (companyId: string, links: ICompanyLink[]) => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_ENDPOINT}/${companyId}/links`,
      types: {
        requestType: saving,
        successTypes: [
          {
            actionOrCreator: showModal,
            selector: () => {
              dispatch(hideModalWithDelay());
              dispatch(fetchCompanies());
            },
          },
          {
            actionOrCreator: saved,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.COMPANY_SERVICE,
      authenticated: true,
      data: links,
    })
  );
};

export const saveCustomize = (companyId: string, customize: ICompanyCustomize | null, links: ICompanyLink[]) => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_ENDPOINT}/${companyId}/customize`,
      types: {
        requestType: saving,
        successTypes: [
          {
            actionOrCreator: showModal,
            selector: () => {
              dispatch(saveLinks(companyId, links));
            },
          },
          {
            actionOrCreator: saved,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.COMPANY_SERVICE,
      authenticated: true,
      data: customize,
    })
  );
};

export const selectCustomizeIsSaving = (state: RootState) =>
  state.customize.status === FetchStatus.Fetching;


export const selectDisplayModal = (state: RootState) =>
  state.customize.modalStatus === ModalStatus.Show;


export default customizeSlice.reducer;
