import React, { FunctionComponent, useEffect } from "react";

import * as H from "history";
import { useDispatch } from "react-redux";
import { Router, Switch, Route } from "react-router-dom";

import { clearBreadcrumbs } from "../../features/breadcrumbs/breadcrumbsSlice";
import { Company } from ".";
import { CompanyPrintableProfile } from "../Printable/CompanyProfile";
import { CompanyProfile } from "./CompanyProfile";
import { ActionSuffixes, Prefixes } from "../prefixes";
import { NewCompany } from "./new";
import { EditCompany } from "./edit";

interface AdminRouterProps {
  history: H.History<any>;
}

const prefix = Prefixes.company;

export const CompanyRouter: FunctionComponent<AdminRouterProps> = ({
  history,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    return history.listen((location) => {
      dispatch(clearBreadcrumbs());
    });
  }, [dispatch, history]);
  return <Router history={history}>
    <Switch>
      <Route path={`${prefix}${ActionSuffixes.new}`} exact>
        <NewCompany />
      </Route>
      <Route path={`${prefix}/:id${ActionSuffixes.print}`} exact>
        <CompanyPrintableProfile />
      </Route>
      <Route path={`${prefix}/:id${ActionSuffixes.edit}`} exact>
        <EditCompany />
      </Route>
      <Route path={`${prefix}/:id`} exact>
        <CompanyProfile />
      </Route>
      <Route path={prefix}>
        <Company />
      </Route>
    </Switch>
  </Router>;
};