import React, { ReactText, useEffect, useState } from "react";

import { Button, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import { RangeValue } from "rc-picker/es/interface";
import { useDispatch, useSelector } from "react-redux";
import * as H from 'history';

import { getCognitoUserData, selectCompanyId, selectUserRole } from '../../features/auth/authSlice';
import {
  fetchCategories,
  selectCategoriesDict,
} from "../../features/category/categorySlice";
import { selectCompanies } from "../../features/company/companySlice";
import {
  disableLessonsStatus,
  enableLessonsStatus,
  fetchLessons,
  ILesson,
  selectAreLessonsFetching,
  selectIsCreatingLesson,
  selectLessons,
} from "../../features/lesson/lessonSlice";
import {
  fetchTeachers,
  selectTeachersDict,
} from "../../features/teacher/teacherSlice";
import { UserRole } from '../../features/users/usersSlice';
import { PAGE_SIZE } from "../../utils/constants";
import { getDateFilterAndSortProps } from "../common/DateFilter";
import { MainLayout } from "../common/Layouts/Main";
import { Status, StatusEnum } from "../common/Status";
import { TableTools } from "../common/TableTools";
import { useDebounce } from "../utils/useDebounce";

import tableStyles from "../common/table.module.scss";
import { ActionSuffixes, Prefixes } from "../prefixes";
import { useHistory } from "react-router-dom";
import { CompanyFilter } from "../Filters/Company";
import { getTranslations } from "../../features/translations/translationsUtils";

function renderOptions(
  history: H.History,
) {
  return (
    <Button
      size='large'
      onClick={() => {
        history.push(`${Prefixes.lesson}${ActionSuffixes.new}`);
      }}
      type="primary"
    >
      {getTranslations('LESSON_NEW')}
    </Button>
  );
}

export const Lessons: React.FunctionComponent = () => {
  const history = useHistory();
  const [dateFilterValues, setDateFilterValues] = useState<
    RangeValue<moment.Moment>
  >();
  const [filterValue, setFilterValue] = useState<ReactText[]>([]);
  const [filterCompanyValue, setFilterCompanyValue] = useState<ReactText[]>([]);
  const [selectedRowKeys, selectRowKeys] = useState<ReactText[]>([]);
  const [showActivationBtns, setShowActivationBtns] = useState<boolean>(false);
  const debounceFilterValue = useDebounce(filterValue, 500);
  const dispatch = useDispatch();
  const categoriesDict = useSelector(selectCategoriesDict);
  const teachersDict = useSelector(selectTeachersDict);
  const areLessonsFetching = useSelector(selectAreLessonsFetching);
  const isCreatingLesson = useSelector(selectIsCreatingLesson);
  const userRole = useSelector(selectUserRole);
  const companyId = useSelector(selectCompanyId);
  const companiesList = useSelector(selectCompanies);
  const cognitoData = useSelector(getCognitoUserData);
  const role = cognitoData ? cognitoData["custom:role"] : undefined;
  const list = [...useSelector(selectLessons)].filter(lesson => {
    if (userRole === UserRole.SuperAdmin) {
      return true;
    }
    return companyId === lesson.companyId;
  }).map((lesson) => ({
    ...lesson,
    categoryName: categoriesDict[lesson.category as string]?.name,
    companyName: lesson.companyId ? companiesList[lesson.companyId || '']?.name :  getTranslations('COMPANY_ALL'),
    teacherName: `${teachersDict[lesson.teacher as string]?.firstName ??
      ""} ${teachersDict[lesson.teacher as string]?.lastName ?? ""}`,
  }));

  const columns: ColumnsType<ILesson> = [
    {
      title: getTranslations('LIST_NAME'),
      dataIndex: "title",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      filteredValue: debounceFilterValue,
      ellipsis: true,
      onFilter: (value, record) => {
        const currentValue = value.toString().toLowerCase();
        const teacherName = record.teacherName ?? "";
        const categoryName = record.categoryName ?? "";
        const id = record._id ?? "";
        return (
          record.title.toLocaleLowerCase().includes(currentValue) ||
          teacherName.toLocaleLowerCase().includes(currentValue) ||
          categoryName.toLocaleLowerCase().includes(currentValue) ||
          id.toLocaleLowerCase().includes(currentValue)
        );
      },
      sorter: (a: ILesson, b: ILesson) => a.title.localeCompare(b.title),
    },
    {
      title: getTranslations('LIST_TEACHER'),
      dataIndex: "teacherName",
      sortDirections: ["descend", "ascend"],
      ellipsis: true,
      defaultSortOrder: "ascend",
      sorter: (a: ILesson, b: ILesson) =>
        a.teacherName?.localeCompare(b?.teacherName ?? "") ?? 0,
    },
    {
      title: getTranslations('LIST_CATEGORY'),
      dataIndex: "categoryName",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      ellipsis: true,
      sorter: (a: ILesson, b: ILesson) =>
        a.categoryName?.localeCompare(b?.categoryName ?? "") ?? 0,
    },
    {
      title: getTranslations('LIST_STATUS'),
      dataIndex: "status",
      sortDirections: ["descend", "ascend"],
      render: (status) => <Status status={status} />,
      sorter: (a: ILesson, b: ILesson) =>
        a.status === b.status ? 0 : a.status === StatusEnum.Enable ? 1 : -1,
    },
    {
      title: getTranslations('LIST_DATE'),
      dataIndex: "createdAt",
      sortDirections: ["descend", "ascend"],
      ...getDateFilterAndSortProps<ILesson>(
        dateFilterValues,
        setDateFilterValues
      ),
      render: (createdAt, lesson) => {
        return (
          <React.Fragment>
            {moment(createdAt).format("DD/MM/YYYY")}
          </React.Fragment>
        );
      },
    },
    {
      title: getTranslations('LIST_COMPANY'),
      dataIndex: "companyName",
      sortDirections: ["descend", "ascend"],
      ellipsis: true,
      filteredValue: filterCompanyValue,
      onFilter: (value, record: ILesson) => {
        return record.companyId === value;
      },
      sorter: (a: ILesson, b: ILesson) =>
        (a.companyName || "").localeCompare(b.companyName || ""),
      render: (company, lesson) => {
        return (
          <React.Fragment>
            {company}
            <div className={tableStyles.actions}>
              <Button
                type="default"
                onClick={() => {
                  history.push(`${Prefixes.lesson}/${lesson._id}${ActionSuffixes.edit}`);
                }}
              >
                {getTranslations('LIST_EDIT')}
              </Button>
            </div>
          </React.Fragment>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchLessons());
    dispatch(fetchCategories());
    dispatch(fetchTeachers());
  }, [dispatch]);

  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      setShowActivationBtns(true);
    } else {
      setShowActivationBtns(false);
    }
  }, [selectedRowKeys]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: ReactText[]) => {
      selectRowKeys(selectedRowKeys);
    },
  };

  const enableStatus = () => {
    const data = {
      ids: selectedRowKeys.map((key) => key.toString()),
    };
    dispatch(enableLessonsStatus(data));
  };

  const disableStatus = () => {
    const data = {
      ids: selectedRowKeys.map((key) => key.toString()),
    };
    dispatch(disableLessonsStatus(data));
  };
  return (
    <MainLayout
      title={getTranslations('LESSON_TITLE')}
      rightTopOptions={renderOptions(history)}
      breadcrumbs={[getTranslations('LESSON_TITLE')]}
    >
      <TableTools
        placeholder={getTranslations('LIST_SEARCH_IN_TEURONA')}
        onSearch={(value) => setFilterValue([value])}
        onChange={(event) => setFilterValue([event.target.value])}
        onEnable={() => enableStatus()}
        onDisable={() => disableStatus()}
        activationBtnsVisible={showActivationBtns}
        filter={
          role === UserRole.SuperAdmin ? (
            <CompanyFilter onApply={setFilterCompanyValue} />
          ) : (
            undefined
          )
        }
      />
      <Table<ILesson>
        loading={areLessonsFetching || isCreatingLesson}
        className={tableStyles.table}
        rowClassName={() => tableStyles.row}
        rowKey="_id"
        rowSelection={rowSelection}
        columns={columns}
        dataSource={list}
        pagination={{ position: ["bottomRight"], pageSize: PAGE_SIZE }}
      />
    </MainLayout>
  );
};
