import React, { FunctionComponent, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import cls from 'classnames';

import { IUser, fetchUser, selectUser } from "../../../features/users/usersSlice";
import { getCognitoUserData } from "../../../features/auth/authSlice";
import { fetchCompany } from "../../../features/company/companySlice";
import { fetchGroups, selectGroupsDict } from "../../../features/groups/groupSlice";
import { useDeviceSize } from "../../utils/useDeviceSize";
import { SIZES } from "../../common/screen";

import styles from "./userData.module.scss";
import { Points } from "../../Header/Points";

export const UserData: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [currentUser, setCurrentUser] = useState<IUser>();
  const cognitoUserData = useSelector(getCognitoUserData);
  const groupsDict = useSelector(selectGroupsDict);
  const size = useDeviceSize();

  const cognitoUsername = cognitoUserData
    ? cognitoUserData["cognito:username"]
    : null;
  const userFromStore = useSelector(selectUser(cognitoUsername));

  useEffect(() => {
    if (userFromStore) {
      setCurrentUser(userFromStore);
      dispatch(fetchCompany(userFromStore?.company ?? ""));
    }
  }, [userFromStore, dispatch]);

  useEffect(() => {
    dispatch(fetchUser(cognitoUsername));
    dispatch(fetchGroups());
  }, [dispatch, cognitoUsername]);


  if (size === SIZES.PHONE) {
    return <div className={styles.mobile}>
      <div className={styles.userData}>
        <div className={styles.avatar}>
          <div className={styles.image} style={{ backgroundImage: `url(${currentUser?.picture})` }} />
        </div>
        <div className={styles.user}>
          <div className={styles.userName}>{currentUser?.given_name} {currentUser?.family_name}</div>
          <div className={styles.group}>{currentUser?.studentGroupId
            ? groupsDict[currentUser.studentGroupId]?.name
            : ""}</div>
        </div>
      </div>
      <div className={styles.points}>
        <Points />
      </div>
    </div>
  }

  return <div className={cls(styles.userData, styles.noMobile)}>
    <div className={styles.top}></div>
    <div className={styles.imageAndData}>
      <div className={styles.imageContainer}>
        <div className={styles.image} style={{ backgroundImage: `url(${currentUser?.picture})` }} />
      </div>
      <div className={styles.data}>
        <div className={styles.userName}>{currentUser?.given_name} {currentUser?.family_name}</div>
        <div className={styles.group}>{currentUser?.studentGroupId
          ? groupsDict[currentUser.studentGroupId]?.name
          : ""}</div>
      </div>
    </div>
  </div>;
};
