import { Form } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  createCompany,
  fetchCompanies,
  hideModal,
  ICompany,
  selectDisplayModal,
} from "../../features/company/companySlice";
import { fetchPrograms } from "../../features/program/programSlice";
import { AfterActions, DialogPopUp } from "../common/DialogPopUp";
import { MainLayout } from "../common/Layouts/Main";
import { StatusEnum } from "../common/Status";
import { CompanyForm } from "./Form";
import { Prefixes } from "../prefixes";
import { FormFooter } from "../common/FormFooter";
import { getTranslations } from "../../features/translations/translationsUtils";
import { fetchLanguages } from "../../features/languages/languagesSlice";

export const NewCompany: React.FunctionComponent = () => {
  const [newCompany, setNewCompany] = useState<ICompany>();
  const [currentAction, setCurrentAction] = useState<string>("");
  const history = useHistory();
  const isModalVisible = useSelector(selectDisplayModal);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCompanies());
    dispatch(fetchPrograms());
    dispatch(fetchLanguages());
  }, [dispatch]);

  const [form] = Form.useForm();

  return (
    <MainLayout
      title={getTranslations('COMPANY_CREATE')}
      onGoBack={() => {
        history.push(`${Prefixes.company}`);
      }}
      breadcrumbs={[getTranslations('COMPANY_TITLE'), getTranslations('COMPANY_CREATE_SHORT')]}
    >
      <DialogPopUp
        name={getTranslations('LIST_COMPANY')}
        action={getTranslations('COMPANY_CREATED')}
        visible={isModalVisible}
        onCancel={() => {
          dispatch(hideModal());
          if (currentAction === AfterActions.BACK) {
            history.push(`${Prefixes.company}`);
          } else {
            form.resetFields();
          }
        }}
      />
      <div>
        <CompanyForm
          form={form}
          newCompany={newCompany}
          setNewCompany={setNewCompany}
        />
      </div>
      <FormFooter
        editing={false}
        objectName={getTranslations('COMPANY_EDIT_SINGULAR').toLocaleLowerCase()}
        onCancel={() => {
          history.push(`${Prefixes.company}`);
        }}
        onSave={() => {
          form
            .validateFields()
            .then((_value) => {
              if (!newCompany) {
                return;
              }
              if (!newCompany.language) {
                newCompany.language = 'es';
              }
              newCompany.status = StatusEnum.Enable;
              setCurrentAction(AfterActions.BACK);
              if (!newCompany.contractDate) {
                newCompany.contractDate = moment(new Date()).toDate();
              }
              dispatch(createCompany(newCompany));
            })
            .catch((error) => {
              console.error(
                "Company:React.FunctionComponent -> error",
                error
              );
            });
        }}
        onSaveAndCreateAnother={() => {
          form
            .validateFields()
            .then((_value) => {
              if (!newCompany) {
                return;
              }
              if (!newCompany.language) {
                newCompany.language = 'es';
              }
              newCompany.status = StatusEnum.Enable;
              setCurrentAction(AfterActions.STAY);
              if (!newCompany.contractDate) {
                newCompany.contractDate = moment(new Date()).toDate();
              }
              dispatch(createCompany(newCompany));
            })
            .catch((error) => {
              console.error(
                "Company:React.FunctionComponent -> error",
                error
              );
            });
        }}
      />
    </MainLayout>
  );
};
