import activityGreen from '../../../assets/infographic/badges/activity/green.png';
import activityBlue from '../../../assets/infographic/badges/activity/blue.png';
import activityDisabled from '../../../assets/infographic/badges/activity/disabled.png';
import activityGold from '../../../assets/infographic/badges/activity/gold.png';
import activitySilver from '../../../assets/infographic/badges/activity/silver.png';
import activityOrange from '../../../assets/infographic/badges/activity/orange.png';
import activityPurple from '../../../assets/infographic/badges/activity/purple.png';

import opinionGreen from '../../../assets/infographic/badges/opinion/green.png';
import opinionBlue from '../../../assets/infographic/badges/opinion/blue.png';
import opinionDisabled from '../../../assets/infographic/badges/opinion/disabled.png';
import opinionGold from '../../../assets/infographic/badges/opinion/gold.png';
import opinionSilver from '../../../assets/infographic/badges/opinion/silver.png';
import opinionOrange from '../../../assets/infographic/badges/opinion/orange.png';
import opinionPurple from '../../../assets/infographic/badges/opinion/purple.png';

import learningGreen from '../../../assets/infographic/badges/learning/green.png';
import learningBlue from '../../../assets/infographic/badges/learning/blue.png';
import learningDisabled from '../../../assets/infographic/badges/learning/disabled.png';
import learningGold from '../../../assets/infographic/badges/learning/gold.png';
import learningSilver from '../../../assets/infographic/badges/learning/silver.png';
import learningOrange from '../../../assets/infographic/badges/learning/orange.png';
import learningPurple from '../../../assets/infographic/badges/learning/purple.png';

import knowledgeGreen from '../../../assets/infographic/badges/knowledge/green.png';
import knowledgeBlue from '../../../assets/infographic/badges/knowledge/blue.png';
import knowledgeDisabled from '../../../assets/infographic/badges/knowledge/disabled.png';
import knowledgeGold from '../../../assets/infographic/badges/knowledge/gold.png';
import knowledgeSilver from '../../../assets/infographic/badges/knowledge/silver.png';
import knowledgeOrange from '../../../assets/infographic/badges/knowledge/orange.png';
import knowledgePurple from '../../../assets/infographic/badges/knowledge/purple.png';


export enum BadgeType {
  activity = 'activity',
  opinion = 'opinion',
  learning = 'learning',
  knowledge = 'knowledge',
}

export const ImagePerType = {
  [BadgeType.activity]: {
    disabled: activityDisabled,
    1:        activityGreen,
    2:        activityPurple,
    3:        activityBlue,
    4:        activityOrange,
    5:        activitySilver,
    6:        activityGold,
  },
  [BadgeType.opinion]: {
    disabled: opinionDisabled,
    1:        opinionGreen,
    2:        opinionPurple,
    3:        opinionBlue,
    4:        opinionOrange,
    5:        opinionSilver,
    6:        opinionGold,
  },
  [BadgeType.learning]: {
    disabled: learningDisabled,
    1:        learningGreen,
    2:        learningPurple,
    3:        learningBlue,
    4:        learningOrange,
    5:        learningSilver,
    6:        learningGold,
  },
  [BadgeType.knowledge]: {
    disabled: knowledgeDisabled,
    1:        knowledgeGreen,
    2:        knowledgePurple,
    3:        knowledgeBlue,
    4:        knowledgeOrange,
    5:        knowledgeSilver,
    6:        knowledgeGold,
  },
}