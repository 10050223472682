import React, { useState, FunctionComponent } from 'react';
import cls from 'classnames';
import { useDispatch } from 'react-redux';

import { ContainerWithCarousel } from '../ContainerWithCarousel';
import { analytics } from '../../../services/analytics';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { Step4 } from './Step4';
import { signUpUser } from '../../../features/signUp/signUpSlice';
import { ISignUpUser } from './interfaces';

import commonStyles from '../Login.module.scss';
import styles from './index.module.scss';
import { DialogPopUp } from '../../common/DialogPopUp';
import LoadingOverlay from '../../common/LoadingOverlay';
import { history } from '../../../App';
import { Progress } from './Progress';
import { getTranslations } from '../../../features/translations/translationsUtils';
import { LanguageSelector } from '../LanguageSelector';

const STEPS = [
  Step1,
  Step2,
  Step3,
  Step4,
];

export const SignUp: FunctionComponent = () => {
  const [key, setKey] = useState<string>('');
  const [sending, setSending] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [userData, setUserData] = useState<ISignUpUser>({});
  const dispatch = useDispatch();

  const save = (user: ISignUpUser) => {
    const newUser = { ...userData, ...user };
    setUserData(newUser);
  };
  const next = (user: ISignUpUser) => {
    const newUser = { ...userData, ...user };
    setUserData(newUser);
    if (stepIndex < STEPS.length - 1) {
      setStepIndex(stepIndex + 1);
      return;
    }
    setSending(true);
    newUser.username = newUser.email;
    dispatch(signUpUser(newUser, () => {
      setSending(false);
      setModalVisible(true);
    }, () => { setSending(false) }));
    analytics.signUp();
  }
  const Step = STEPS[stepIndex];
  const onBack = () => {
    setStepIndex(stepIndex - 1);
  };
  return (
    <React.Fragment>
      <div className={key} />
      {sending || key === '' ? <LoadingOverlay
        spinning={sending || key === ''}
        fullscreen
      /> : null}
      <DialogPopUp
        name={getTranslations('LOGIN_ACCOUNT_CREATED_TITLE')}
        action={getTranslations('LOGIN_ACCOUNT_CREATED_SUBTITLE')}
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          history.replace('/login');
        }}
        extraText={getTranslations('LOGIN_ACCOUNT_CREATED_INFO')}
      />
      <div className={cls(commonStyles.roundedBorder)}>
      </div>
      {stepIndex === 0 ?
        <ContainerWithCarousel>
          {<Step save={save} next={next} userData={userData} />}
        </ContainerWithCarousel> :
        <div className={styles.otherStepsContainer}>
          <div className={styles.headerBackground}></div>
          <div className={styles.innerContainer}>
            <Progress back={onBack} maxSteps={3} progress={stepIndex} />
            <Step save={save} next={next} userData={userData} />
          </div>
        </div>
      }
      <LanguageSelector loading={key === ''} setKey={(value: string) => {
        setKey(value);
      }} />
    </React.Fragment>

  );
}
