import React, { useEffect } from "react";

import { Button, Form } from "antd";
import { useSelector } from "react-redux";
import * as H from "history";

import { selectUserRole } from '../../features/auth/authSlice';
import {
  selectAreProgramsFetching,
} from "../../features/program/programSlice";
import { UserRole } from '../../features/users/usersSlice';
import { MainLayout } from "../common/Layouts/Main";
import { ProgramList } from "./List";
import { ActionSuffixes, Prefixes } from "../prefixes";
import { useHistory } from "react-router-dom";
import { getTranslations } from "../../features/translations/translationsUtils";

function renderOptions(
  userRole: UserRole | null,
  history: H.History,
) {
  if(userRole !== UserRole.SuperAdmin){
    return null;
  }
  return (
    <Button
      size="large"
      onClick={() => {
        history.push(`${Prefixes.program}${ActionSuffixes.new}`);
      }}
      type="primary"
    >
      {getTranslations('PROGRAM_NEW')}
    </Button>
  );
}

export const Program: React.FC = () => {
  const areProgramsFetching = useSelector(selectAreProgramsFetching);
  const userRole = useSelector(selectUserRole);
  const history = useHistory();
  const [form] = Form.useForm();

  useEffect(() => {
    if (!areProgramsFetching) {
      form.setFieldsValue({});
      form.resetFields();
    }
  }, [form, areProgramsFetching]);

  const onEdit = (programId: string) => {
    history.push(`${Prefixes.program}/${programId}${ActionSuffixes.edit}`);
  };
  return (
    <MainLayout
      title={getTranslations('PROGRAM_TITLE')}
      rightTopOptions={renderOptions(
        userRole,
        history,
      )}
      breadcrumbs={[getTranslations('PROGRAM_TITLE')]}
    >
      <ProgramList editProgramById={onEdit} />
    </MainLayout>
  );
};
