import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../app/AllowedMicroservices";
import { RootState } from "../../app/store";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { FetchStatus } from "../common/enums";

export interface RankingRecord {
  userId: string;
  count: number;
  position: number;
}
interface DataRecords {
  points: RankingRecord[];
  badges: RankingRecord[];
  courses: RankingRecord[];
  certificates: RankingRecord[];
}
interface RankingState {
  fetching: FetchStatus;
  data: DataRecords;
}

const initialState: RankingState = {
  fetching: FetchStatus.Idle,
  data: {
    points: [],
    badges: [],
    courses: [],
    certificates: [],
  }
};

export const pointsSlice = createSlice({
  name: "ranking",
  initialState,
  reducers: {
    fetching: (state) => {
      state.data = {
        points: [],
        badges: [],
        courses: [],
        certificates: [],
      };
      state.fetching = FetchStatus.Fetching;
    },
    fetched: (state, action: PayloadAction<DataRecords>) => {
      state.data = action.payload;
      state.fetching = FetchStatus.Fetched;
    },
    error: (state) => {
      state.data= {
        points: [],
        badges: [],
        courses: [],
        certificates: [],
      };
      state.fetching = FetchStatus.Error;
    },
  },
});

const { error, fetched, fetching } = pointsSlice.actions;

export const fetchRanking = (companyId?: string) => (dispatch: Dispatch<object>) => {
  const endpoint = companyId ? `/points/ranking/${companyId}`:'/points/ranking';
  return dispatch(
    apiActionCreator({
      endpoint,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.COMPANY_SERVICE,
      authenticated: true,
    })
  );
};

export const selectRanking = (state: RootState) =>
  state.ranking.data;

export const selectUserRanking = (userId: string) => (state: RootState) => {
  return {
    points: (state.ranking.data.points ?? []).find(record => record.userId === userId) ?? null,
    badges: (state.ranking.data.badges ?? []).find(record => record.userId === userId) ?? null,
    courses: (state.ranking.data.courses ?? []).find(record => record.userId === userId) ?? null,
    certificates: (state.ranking.data.certificates ?? []).find(record => record.userId === userId) ?? null,
  };
}

export const selectArePRankingFetching = (state: RootState) =>
  state.ranking.fetching === FetchStatus.Fetching;

export default pointsSlice.reducer;
