import React, { FunctionComponent, useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

import { selectCompanies, selectCompaniesAreFetching, selectCompaniesIds } from '../../../../features/company/companySlice';
import { ICompanyType, selectCompanyTypesAreFetching, selectCompanyTypesDict } from '../../../../features/companyType/companyTypeSlice';
import { PIECHART_COLORS } from '../../../../features/reports/common';
import LoadingOverlay from '../../../common/LoadingOverlay';
import { RoundedContainer } from '../../../common/RoundedContainer';
import { StatusEnum } from '../../../common/Status';
import { IDataStructure } from '../../common/Doughnut';
import { TileTile } from '../common/TileTitle';
import { StatusSelect, ExtendedStatus } from '../StatusSelector';

import styles from './index.module.scss';
import { getTranslations } from '../../../../features/translations/translationsUtils';

interface CompanyPerTypeCount extends ICompanyType {
  enabled: number,
  disabled: number,
}

interface CompanyPerTypeCountDict {
  [_id: string]: CompanyPerTypeCount,
}

enum StatusSelector {
  byEnabled = 'byEnabled',
  byDisabled = 'byDisabled',
  byEnablePlusDisable = 'byAggregation'
}

interface CompaniesPerTypeProps { }
export const CompaniesPerType: FunctionComponent<CompaniesPerTypeProps> = () => {
  const isFetchingCompanies = useSelector(selectCompaniesAreFetching);
  const isFetchingCompanyTypes = useSelector(selectCompanyTypesAreFetching);
  const companyTypes = useSelector(selectCompanyTypesDict);
  const companyIdsList = useSelector(selectCompaniesIds);
  const companyDict = useSelector(selectCompanies);
  const companyList = companyIdsList.map((companyId) => ({
    ...companyDict[companyId],
  }));
  const [companyPerTypeCount, setCompanyPerTypeCount] = useState<CompanyPerTypeCount[]>([]);
  const [totalEnabledCompaniesCount, setTotalEnabledCompaniesCount] = useState<number>(0);
  const [totalDisabledCompaniesCount, setTotalDisabledCompaniesCount] = useState<number>(0);
  const [statusSelection, setStatusSelection] = useState<ExtendedStatus>(ExtendedStatus.All);

  const dataIsFetching = isFetchingCompanies || isFetchingCompanyTypes;
  useEffect(() => {
    if (dataIsFetching || (companyPerTypeCount.length > 0) || companyList.length === 0) {
      return;
    }

    let totalEnabledCount = 0;
    let totalDisabledCount = 0;

    const countPerType: CompanyPerTypeCountDict = {};
    for (const _id in companyTypes) {
      countPerType[_id] = {
        'enabled': 0,
        'disabled': 0,
        'name': companyTypes[_id].name,
        _id,
      }
    }

    companyList.reduce((acc, current) => {
      const companyTypeId = current.companyType as string;
      if (!companyTypeId || !acc[companyTypeId]) {
        return acc;
      }

      if (current.status === StatusEnum.Enable) {
        totalEnabledCount++;
        acc[companyTypeId].enabled += 1;
        return acc;
      }
      totalDisabledCount++;
      acc[companyTypeId].disabled += 1;
      return acc;
    }, countPerType);
    setTotalEnabledCompaniesCount(totalEnabledCount);
    setTotalDisabledCompaniesCount(totalDisabledCount);
    setCompanyPerTypeCount(Object.values(countPerType));
  }, [dataIsFetching, companyList, companyTypes, companyPerTypeCount]);

  const statusSelector = getStatusSelector(statusSelection);
  const doughnutData = buildDoughnutData(companyPerTypeCount, statusSelector);
  const totalCountDivider = ((statusSelector) => {
    if (statusSelector === StatusSelector.byDisabled) {
      return totalDisabledCompaniesCount || 1;
    }
    if (statusSelector === StatusSelector.byEnabled) {
      return totalEnabledCompaniesCount || 1;
    }
    return (totalEnabledCompaniesCount + totalDisabledCompaniesCount) || 1;
  })(statusSelector);

  return (
    <RoundedContainer className={styles.mainWrapper} >
      <TileTile text={getTranslations('REPORT_COMPANY_PER_TYPE_COUNT')} />
      <StatusSelect onChange={(status: ExtendedStatus) => setStatusSelection(status)} />
      {dataIsFetching ?
        <LoadingOverlay inline height={185} spinning /> :
        (doughnutData.labels.length > 0 ?
          <Doughnut
            type={"doughnut"}
            data={doughnutData}
            options={{
              legend: {
                display: true,
                position: 'right',
                align: 'center',
              },
              maintainAspectRatio: true,
              tooltips: {
                callbacks: {
                  label: (tooltipItem: any, data: any) => {
                    const label = data.labels[tooltipItem.index];
                    const dataset = data.datasets[tooltipItem.datasetIndex];
                    return `${label}: ${((dataset.data[tooltipItem.index] / totalCountDivider) * 100).toFixed(2)}%`;
                  },
                }
              }
            }}
          />
          :
          <div className={styles.placeholder} >
            {getTranslations('REPORT_NO_DATA')}
          </div>
        )
      }
    </RoundedContainer>
  );
}

const buildDoughnutData = (companyPerTypeCount: CompanyPerTypeCount[], type: StatusSelector) => {
  const data: IDataStructure = {
    labels: [],
    datasets: [{
      label: '',
      data: [],
      backgroundColor: [],
    }],
  }

  const sortedCompanyPerTypeCount = sortCompanyPerTypeCount({ companyPerTypeCount, sortBy: type });
  const { firstGroup, secondGroup: allTheRest } = makeItTwoGroups(sortedCompanyPerTypeCount)
  const compactCompaniesCounts = [...firstGroup, aggregateEnableDisbleCounts(allTheRest)];
  const colorsLength = PIECHART_COLORS.length;
  compactCompaniesCounts.forEach((countObj, index) => {
    const colorIndex = index % colorsLength;
    const count = getCount(countObj, type);
    if (count === 0) {
      return;
    }
    data.labels.push(countObj.name);
    data.datasets[0].data.push(count);
    data.datasets[0].backgroundColor.push(PIECHART_COLORS[colorIndex]);
  });

  return data;
}

enum SortDirection {
  asc = 'ascending',
  desc = 'descending',
}

interface SorterProps {
  companyPerTypeCount: CompanyPerTypeCount[],
  sortBy: StatusSelector,
  direction?: SortDirection,
}

const sortCompanyPerTypeCount = ({ companyPerTypeCount, sortBy }: SorterProps) => {
  const comparator = (() => {
    if (sortBy === StatusSelector.byEnablePlusDisable) {
      return (a: CompanyPerTypeCount, b: CompanyPerTypeCount) => ((b.enabled + b.disabled) - (a.enabled + a.disabled));
    }
    if (sortBy === StatusSelector.byEnabled) {
      return (a: CompanyPerTypeCount, b: CompanyPerTypeCount) => b.enabled - a.enabled;
    }
    return (a: CompanyPerTypeCount, b: CompanyPerTypeCount) => (b.disabled - a.disabled);
  })();

  return companyPerTypeCount.sort(comparator);
}

const aggregateEnableDisbleCounts = (data: CompanyPerTypeCount[]) => {
  const aggregation: CompanyPerTypeCount = {
    _id: 'allTheRest',
    name: getTranslations('REPORT_REST_OF_COMPANIES'),
    enabled: 0,
    disabled: 0,
  }

  data.reduce((acc, current) => {
    acc.enabled += current.enabled;
    acc.disabled += current.disabled;
    return acc;
  }, aggregation);

  return aggregation;
}

const makeItTwoGroups = (data: CompanyPerTypeCount[]) => {
  const MAX_LABELS_FIT = 8;
  return {
    firstGroup: data.slice(0, MAX_LABELS_FIT),
    secondGroup: data.slice(MAX_LABELS_FIT)
  }
}

const getCount = (countObj: CompanyPerTypeCount, type: StatusSelector) => {
  if (type === StatusSelector.byEnabled) {
    return countObj.enabled;
  }
  if (type === StatusSelector.byDisabled) {
    return countObj.disabled;
  }
  return countObj.enabled + countObj.disabled;
}

const getStatusSelector = (selectedStatus: ExtendedStatus): StatusSelector => {
  switch (selectedStatus) {
    case ExtendedStatus.All:
      return StatusSelector.byEnablePlusDisable;
    case ExtendedStatus.Disabled:
      return StatusSelector.byDisabled
    case ExtendedStatus.Enabled:
      return StatusSelector.byEnabled
  }
}
