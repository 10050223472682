import React, { FunctionComponent } from 'react';

import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cls from 'classnames';

import { CogIcon } from '../../../common/CogIcon';
import { Event, EventsDict } from './interfaces';

import { COURSES_PRIORITY_COLORS, COURSES_PRIORITY_ICON, COURSES_PRIORITY_LABELS } from '../../../../utils/constants';
import { selectStudentMyCourseById } from '../../../../features/students/myCoursesSlice';
import { selectCategory } from '../../../../features/category/categorySlice';
import { useDeviceSize } from '../../../utils/useDeviceSize';
import { SIZES } from '../../../common/screen';

import styles from './dayView.module.scss';
import { getTranslations } from '../../../../features/translations/translationsUtils';
import { getTimeInHours } from '../../common/timeUtils';


interface DayViewProps {
  selectedDate?: moment.Moment,
  onChangeSelectedDate: (date: moment.Moment) => void,
  eventsDict: EventsDict,
  events: (string | number)[],
}

interface CourseProps {
  event: Event,
}

interface EventListProps {
  eventsDict: EventsDict,
  events: (string | number)[],
}


const Course: FunctionComponent<CourseProps> = ({ event }) => {
  const history = useHistory();
  const size = useDeviceSize();
  const isMobile = size === SIZES.PHONE;

  const {
    priority,
    id
  } = event;
  const myCourse = useSelector(selectStudentMyCourseById(`${id}`));
  const category = useSelector(selectCategory(myCourse?.course.category.toString() ?? ''));
  if (!myCourse || !category) {
    return null;
  }
  const course = myCourse.course;
  const extra = myCourse.extra;
  const startDate = extra?.startDate;
  const endDate = extra?.endDate;

  const dateFormat = isMobile ? 'DD-MM-YY' : 'DD-MM-YYYY';

  return <div className={cls(styles.course, {
    [styles.mobile]: isMobile,
  })}>
    <div className={styles.titleContainer}>
      <div className={styles.info}>
        <div className={styles.categories}>
          {category && course && <span className={styles.category}>{category.name}</span>}
        </div>
        <div className={styles.titleAndDescription}>
          <div className={styles.title}>{course.title}</div>
          <div className={styles.description}>{course.description}</div>
        </div>
      </div>
      <div className={styles.goto}>
        <div
          className={styles.button}
          onClick={() => {
            history.push(`/course/${id}`)
          }}
        >
          <CogIcon size="20" color="black" icon="Arrow-right-big" />
        </div>
      </div>
    </div>
    <div className={styles.data}>
      <div className={styles.priority}>
        <CogIcon size={16} className={styles.icon} color={COURSES_PRIORITY_COLORS[priority]} icon={COURSES_PRIORITY_ICON[priority]} />
        {getTranslations('STUDENT_PRIORITY', [getTranslations(COURSES_PRIORITY_LABELS[priority])])}
      </div>
      <div className={styles.duration}>
        <CogIcon size={16} className={styles.icon} color="#81858B" icon="clock" />
        {getTimeInHours(course?.durationHour)}
      </div>
      <div className={styles.date}>
        {moment(startDate).format(dateFormat)}
        {endDate ? <span>/</span> : null}
        {endDate ? `${moment(endDate).format(dateFormat)}` : ''}
      </div>
    </div>
  </div>;
}

const EventList: FunctionComponent<EventListProps> = ({ eventsDict, events = [] }) => {
  if (events.length === 0) {
    return <div className={styles.noEvents}>No hay eventos este día</div>;
  }
  return <>
    {events.map((event, index) => {
      return <Course key={index} event={eventsDict[event]} />
    })}
  </>;
}
export const DayView: FunctionComponent<DayViewProps> = ({ selectedDate, onChangeSelectedDate, eventsDict, events }) => {
  const size = useDeviceSize();
  const isMobile = size === SIZES.PHONE;

  if (!selectedDate) {
    return null;
  }
  const onPrev = () => {
    let date = selectedDate.clone().subtract(1, 'd');
    onChangeSelectedDate(date);
  };
  const onNext = () => {
    let date = selectedDate.clone().add(1, 'd');
    onChangeSelectedDate(date);
  };
  const name = selectedDate.format('dddd');
  return <div className={cls(styles.container, {
    [styles.mobile]: isMobile,
  })}>
    <div className={styles.innerContainer}>
      <div className={styles.header}>
        <div className={styles.monthAndYear}><span className={styles.month}>{name}</span> {selectedDate.format('DD')}</div>
        <div className={styles.controls}>
          <span className={styles.button} onClick={onPrev}><CogIcon className={styles.icon} icon="Arrow-left-big" /></span>
          <span className={styles.button} onClick={onNext}><CogIcon className={styles.icon} icon="Arrow-right-big" /></span>
        </div>
      </div>
      <div className={styles.eventsContainer}>
        <EventList eventsDict={eventsDict} events={events} />
      </div>
    </div>
  </div>;
}