import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../app/AllowedMicroservices";
import { AppThunk, RootState } from "../../app/store";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { MODAL_DELAY_MS } from "../../utils/constants";
import { ICategory } from "../category/categorySlice";
import { ConditionEnum, FetchStatus, ModalStatus } from "../common/enums";
import { setMessageError, setMessageInfo } from "../message/messageSlice";
import { getTranslations } from "../translations/translationsUtils";

export interface IPdf {
  url: string;
  filename: string;
  size: number;
}

export interface IContent {
  _id: string;
  title: string;
  description: string;
  category: string | ICategory;
  categoryName?: string;
  tags: string[];
  content: string;
  pdfs?: IPdf[];
  companyId: string;
  companyName?: string;
  deleted: boolean;
  deletedAt: Date;
  published: ConditionEnum;
  createdAt: Date | string;
}

interface IContentDict {
  [_id: string]: IContent;
}

interface CourseState {
  list: string[],
  dict: IContentDict,
  status: FetchStatus,
  modalStatus: ModalStatus,
  created?: IContent,
}

const initialState: CourseState = {
  list: [],
  dict: {},
  status: FetchStatus.Idle,
  modalStatus: ModalStatus.Hide,
};

interface ContentFetch {
  docs: IContent[];
  limit: number;
  page: number;
  pages: number;
  total: number;
}

interface CourseUpdateStatusData {
  ids: string[];
}

export const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {
    creating: (state) => {
      state.status = FetchStatus.Creating;
    },
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetchedContents: (state, action: PayloadAction<ContentFetch>) => {
      state.list = action.payload.docs.map((content) => content._id);
      state.dict = {};
      action.payload.docs.forEach((content) => {
        state.dict[content._id] = content;
      });
      state.status = FetchStatus.Fetched;
    },
    fetchedContent: (state, action: PayloadAction<IContent>) => {
      if (!state.list.includes(action.payload._id)) {
        state.list.push(action.payload._id);
      }
      state.dict[action.payload._id] = action.payload;
      state.status = FetchStatus.Fetched;
    },
    error: (state, _action: PayloadAction<ContentFetch>) => {
      state.list = [];
      state.status = FetchStatus.Error;
    },
    showModal: (state) => {
      state.modalStatus = ModalStatus.Show;
    },
    hideModal: (state) => {
      state.modalStatus = ModalStatus.Hide;
    },
    created: (state, action: PayloadAction<IContent>) => {
      state.created = action.payload;
    }
  },
});

const hideModalWithDelay = (): AppThunk => async (dispatch) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      dispatch(hideModal());
      resolve();
    }, MODAL_DELAY_MS);
  });
};

const { showModal } = contentSlice.actions;

export const {
  fetching,
  fetchedContents: fetchedCourses,
  fetchedContent: fetchedCourse,
  error,
  creating,
  hideModal,
  created,
} = contentSlice.actions;
export const createContent = (newContent: IContent) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: "/content",
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: showModal,
            selector: () => {
              dispatch(hideModalWithDelay());
            },
          },
          {
            actionOrCreator: created
          }
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.POST,
      microservice: Microservices.KNOWLEDGE_BASE_SERVICE,
      authenticated: true,
      data: newContent,
    })
  );
};

export const updateContent = (content: IContent) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: `/content/${content._id}`,
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: showModal,
            selector: () => {
              dispatch(hideModalWithDelay());
            },
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.KNOWLEDGE_BASE_SERVICE,
      authenticated: true,
      data: content,
    })
  );
};

export const fetchContents = () => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: "/content?limit=1000000",
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetchedCourses,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.KNOWLEDGE_BASE_SERVICE,
      authenticated: true,
    })
  );
};
export const fetchContent = (contentId: string) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: `/content/${contentId}`,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetchedCourse,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.KNOWLEDGE_BASE_SERVICE,
      authenticated: true,
    })
  );
};

export const pusblishContentStatus = (contents: CourseUpdateStatusData) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: "/content/publish",
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: fetchContents,
          },
          {
            actionOrCreator: setMessageInfo({
              message: getTranslations('KNOWLEDGE_ENABLED_BASES_MESSAGE'),
            }),
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
          {
            actionOrCreator: fetchContents,
          },
          {
            actionOrCreator: setMessageError({
              message: getTranslations('KNOWLEDGE_ENABLED_BASES_MESSAGE_FAILED'),
            }),
          },
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.KNOWLEDGE_BASE_SERVICE,
      authenticated: true,
      data: contents,
    })
  );
};

export const unpublishContentStatus = (cotents: CourseUpdateStatusData) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: "/content/unpublish",
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: fetchContents,
          },
          {
            actionOrCreator: setMessageInfo({
              message: getTranslations('KNOWLEDGE_DISABLED_BASES_MESSAGE'),
            }),
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
          {
            actionOrCreator: fetchContents,
          },
          {
            actionOrCreator: setMessageError({
              message: getTranslations('KNOWLEDGE_DISABLED_BASES_MESSAGE_FAILED'),
            }),
          },
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.KNOWLEDGE_BASE_SERVICE,
      authenticated: true,
      data: cotents,
    })
  );
};

export const selectContents = (state: RootState) => state.content.list;
export const selectContentsDict = (state: RootState) => state.content.dict;
export const selectContent = (id: string | null) => (state: RootState) =>
  id ? state.content.dict[id] : null;
export const selectIsContentFetching = (state: RootState) =>
  state.content.status === FetchStatus.Fetching;
export const selectDisplayModal = (state: RootState) =>
  state.content.modalStatus === ModalStatus.Show;
export const selectJustCreatedContent = (state: RootState) => state.content.created;


export default contentSlice.reducer;
