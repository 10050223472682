import { IWithShortId, buildShortIds } from './../common/shortId';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../app/AllowedMicroservices";
import { AppThunk, RootState } from "../../app/store";
import { StatusEnum } from "../../components/common/Status";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { MODAL_DELAY_MS } from "../../utils/constants";
import { FetchStatus, ModalStatus } from "../common/enums";
import { setMessageError, setMessageInfo } from "../message/messageSlice";
import { getTranslations } from '../translations/translationsUtils';

export interface ICourseRules {
  startDate: Date | moment.Moment;
  endDate?: Date;
  hasPredecessor: boolean;
  predecessor: string[];
  priority: number;
  endUndefined: boolean,
}

export interface IProgram extends IWithShortId {
  _id: string;
  title: string;
  courses: string[];
  coursesRules: ICourseRules[];
  teuronaCourses: string[];
  teuronaCoursesRules: ICourseRules[];
  status: StatusEnum;
  createdAt: Date | string;
}
export interface ICourseDict {
  [_id: string]: IProgram;
}

interface ProgramState {
  list: string[];
  dict: ICourseDict;
  status: FetchStatus;
  modalStatus: ModalStatus;
}

const initialState: ProgramState = {
  list: [],
  dict: {},
  status: FetchStatus.Idle,
  modalStatus: ModalStatus.Hide,
};

interface ProgramFetch {
  docs: IProgram[];
  limit: number;
  page: number;
  pages: number;
  total: number;
}

export const programSlice = createSlice({
  name: "programs",
  initialState,
  reducers: {
    creating: (state) => {
      state.status = FetchStatus.Creating;
    },
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetched: (state, action: PayloadAction<ProgramFetch>) => {
      action.payload.docs = buildShortIds(action.payload.docs) as IProgram[];
      action.payload.docs.sort((programA, programB)=>{
        const titleA = programA.title; 
        const titleB = programB.title; 
        if (titleA < titleB) {
          return -1;
        }
        if (titleA > titleB) {
          return 1;
        }
        return 0;
      });
      state.list = action.payload.docs.map((program) => program._id);
      state.dict = {};
      action.payload.docs.forEach((program) => {
        state.dict[program._id] = program;
      });
      state.status = FetchStatus.Fetched;
    },
    fetchedProgram: (state, action: PayloadAction<IProgram>) => {
      if (!state.list.includes(action.payload._id)) {
        state.list.push(action.payload._id);
      }
      state.dict[action.payload._id] = action.payload;
      state.status = FetchStatus.Fetched;
    },
    error: (state, _action: PayloadAction<ProgramFetch>) => {
      state.list = [];
      state.status = FetchStatus.Error;
    },
    showModal: (state) => {
      state.modalStatus = ModalStatus.Show;
    },
    hideModal: (state) => {
      state.modalStatus = ModalStatus.Hide;
    },
  },
});

const { showModal } = programSlice.actions;
export const {
  creating,
  fetching,
  fetched,
  fetchedProgram,
  error,
  hideModal,
} = programSlice.actions;

export const fetchPrograms = () => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: "/programs?limit=1000000",
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
    })
  );
};

export const fetchProgram = (programId: string) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: `/programs/${programId}`,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetchedProgram,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
    })
  );
};

export const updateProgram = (program: IProgram) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: `/programs/${program._id}`,
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: showModal,
            selector: () => {
              dispatch(hideModalWithDelay());
            },
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
      data: program,
    })
  );
};

export const createProgram = (newProgram: IProgram) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: "/programs",
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: fetchedProgram,
          },
          {
            actionOrCreator: showModal,
            selector: () => {
              dispatch(hideModalWithDelay());
            },
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
            selector: (response: any) => {
              const message = `Error desconocido`;
              dispatch(setMessageError({ message }));
            },
          },
        ],
      },
      method: HttpMethods.POST,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
      data: newProgram,
    })
  );
};

const hideModalWithDelay = (): AppThunk => async (dispatch) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      dispatch(hideModal());
      resolve();
    }, MODAL_DELAY_MS);
  });
};

interface ProgramsUpdateStatusData {
  ids: string[];
}

export const enableProgramsStatus = (programs: ProgramsUpdateStatusData) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: "/programs/enable",
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: fetchPrograms,
          },
          {
            actionOrCreator: setMessageInfo({
              message: getTranslations('PROGRAM_ENABLED_PROGRAMS_MESSAGE'),
            }),
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
            selector: (response: any) => {
              if (
                response.data &&
                response.data.statusCode === 409 &&
                response.data.data === "CanBeEnabled"
              ) {
                const message = getTranslations('PROGRAM_ENABLED_PROGRAMS_MESSAGE_FAILED_2');
                dispatch(setMessageError({ message }));
                return;
              }
              dispatch(setMessageError({ message: getTranslations('PROGRAM_ENABLED_PROGRAMS_MESSAGE_FAILED') }));
            },
          },
          {
            actionOrCreator: fetchPrograms,
          },
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
      data: programs,
    })
  );
};

export const disableProgramsStatus = (programs: ProgramsUpdateStatusData) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: "/programs/disable",
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: fetchPrograms,
          },
          {
            actionOrCreator: setMessageInfo({
              message: getTranslations('PROGRAM_DISABLED_PROGRAMS_MESSAGE'),
            }),
          },
        ],
        errorTypes: [
          {
            actionOrCreator: fetchPrograms,
          },
          {
            actionOrCreator: error,
            selector: (response: any) => {
              if (
                response.data &&
                response.data.statusCode === 409 &&
                response.data.data === "ProgramInUse"
              ) {
                const message = getTranslations('PROGRAM_DISABLED_PROGRAMS_MESSAGE_FAILED_2');
                dispatch(setMessageError({ message }));
                return;
              }
              setMessageError({ message: getTranslations('PROGRAM_DISABLED_PROGRAMS_MESSAGE_FAILED') });
            },
          },
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
      data: programs,
    })
  );
};

export const selectPrograms = (state: RootState) => state.programs.list;
export const selectProgramsFetchStatus = (state: RootState) =>
  state.programs.status;
export const selectProgram = (id: string | null) => (state: RootState) =>
  id ? state.programs.dict[id] : null;
export const selectProgramsDict = (state: RootState) => state.programs.dict;
export const selectDisplayModal = (state: RootState) =>
  state.programs.modalStatus === ModalStatus.Show;
export const selectAreProgramsFetching = (state: RootState) =>
  state.programs.status === FetchStatus.Fetching;

export default programSlice.reducer;
