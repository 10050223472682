import { Button, Card, message, Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import ctx from "classnames";
import React, { FunctionComponent, useState } from "react";
import { IQuestion, QuestionsAnswers } from "../../../features/evaluation/common/interfaces";
import styles from "./evaluations.module.scss";
import { getTranslations } from "../../../features/translations/translationsUtils";

interface QuestionPageProps {
  className?: string;
  question?: IQuestion;
  onNext: Function;
  dontShowFeedback?: boolean;
  currentQuestion: number;
  questionsCount: number;
  setAnswerSelected: Function;
  onBack: Function;
  answersSelected?: QuestionsAnswers;
  questionId?: string;
}

export const QuestionPage: FunctionComponent<QuestionPageProps> = ({
  className,
  question,
  onNext,
  onBack,
  currentQuestion,
  questionsCount,
  dontShowFeedback = false,
  setAnswerSelected,
  answersSelected = {},
  questionId = ''
}) => {
  const [value, setValue] = useState<string | null>(answersSelected[questionId]?.answerId ?? null);
  const [showResults, setShowResults] = useState<boolean>(false);
  const onAnswerChange = (event: RadioChangeEvent) => {
    if (showResults) {
      return;
    }
    const answerId = event.target.value;
    setValue(answerId);
    setAnswerSelected(event.target.value);
  };
  const buttonLabel = getTranslations('STUDENT_EVALUATION_NEXT');
  const answerSelected = value;
  const buttonIsDisable = !answerSelected;
  const wasCorrectAnswer = question?.correctAnswer === value;
  const correcWrongClassName = wasCorrectAnswer ? styles.correct : styles.wrong;
  const answer = question?.possibleAnswers.find(
    (answer) => answer._id === value
  );
  const onInnerBack = () => {
    if (dontShowFeedback) {
      onBack()
    }
  }
  const onInnerNext = () => {
    if (dontShowFeedback) {
      onNext();
      return;
    }
    setShowResults(true);
    const method = wasCorrectAnswer ? "success" : "error";
    const text = wasCorrectAnswer
      ? getTranslations('STUDENT_EVALUATION_RIGHT_ANSWER')
      : getTranslations('STUDENT_EVALUATION_WRONG_ANSWER');
    message[method]({
      content: (
        <div className={styles.innerContainer}>
          <div className={styles.title}>{text}</div>
          {answer?.feedback ? (
            <div className={styles.feedback}>{answer?.feedback}</div>
          ) : null}
        </div>
      ),
      className: styles.feedbackToast,
      onClose: () => {
        onNext();
      },
    });
  };
  return (
    <div className={ctx(styles.container, styles.test, className)}>
      <Card
        className={styles.question}
      >
        <div className={styles.title}>{question?.title}</div>
        <Radio.Group
          className={styles.answers}
          onChange={onAnswerChange}
          value={value}
        >
          {question?.possibleAnswers.map((answer) => (
            <Radio
              className={ctx(styles.answer, {
                [correcWrongClassName]: showResults && value === answer._id,
              })}
              key={answer._id}
              value={answer._id}
            >
              {answer.label}
            </Radio>
          ))}
        </Radio.Group>
        <div className={styles.buttonContainer}>
          {dontShowFeedback ? <Button type="link" onClick={onInnerBack}>{getTranslations('STUDENT_EVALUATION_BACK')}</Button> : null}
          <Button
            disabled={buttonIsDisable || showResults}
            onClick={onInnerNext}
            type="primary"
            size="large"
          >
            {buttonLabel}
          </Button>
        </div>
      </Card>
    </div>
  );
};
