import React, { FunctionComponent, useEffect } from "react";

import { useHistory, useRouteMatch } from "react-router-dom";

import { MainLayout } from "../common/Layouts/Main";
import { ActionSuffixes, Prefixes } from "../prefixes";
import { Infographic } from "../Profile/Infographic";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers, selectUser } from "../../features/users/usersSlice";
import { fetchCompanies } from "../../features/company/companySlice";

import styles from './infographicUser.module.scss';
import LoadingOverlay from "../common/LoadingOverlay";
import { getTranslations } from "../../features/translations/translationsUtils";

export const InfographicUser: FunctionComponent = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const userId = (match.params as any).id!;
  const dispatch = useDispatch();
  const user = useSelector(selectUser(userId || ""));

  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchCompanies());
  }, [dispatch]);

  if (!user) {
    return <LoadingOverlay spinning={true} />;
  }

  return (
    <MainLayout
      title=""
      onGoBack={() => {
        history.push(`${Prefixes.user}/${userId}${ActionSuffixes.edit}`);
      }}
      breadcrumbs={[`${user?.given_name} ${user?.family_name}`, getTranslations('STUDENT_INFOGRAPHIC_TITLE')]}
      hideMenu
      backgroundColor="#F7F8FA"
    >
      <div className={styles.innerContainer}>
        <div className={styles.header}>
          <div className={styles.tools}>
          </div>
          <div className={styles.imageContainer}>
            <div className={styles.image} style={{ backgroundImage: `url(${user?.picture})` }} />
          </div>
          <div className={styles.name}>{user?.given_name} {user?.family_name}</div>
          <div className={styles.email}>{user?.email}</div>
        </div>
        <Infographic userId={userId} companyId={user.company} />
      </div>
    </MainLayout>
  );
};
