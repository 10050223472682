import { Tabs } from "antd";
import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { getCognitoUserData } from "../../features/auth/authSlice";
import {
  selectAreGroupsFetching,
  selectGroups,
} from "../../features/groups/groupSlice";
import {
  selectAreUsersFetching,
  selectStudents,
  UserRole,
} from "../../features/users/usersSlice";
import LoadingOverlay from "../common/LoadingOverlay";
import { TargetType } from "./common";
import styles from "./targets.module.scss";
import { TargetsFilter } from "./TargetsFilter";
import { getTranslations } from "../../features/translations/translationsUtils";

const { TabPane } = Tabs;
interface TargetsProps {
  targets: string[];
  setTargets: (targets: string[]) => void;
  targetType: TargetType;
  setTargetType: (targetType: TargetType) => void;
  companyName: string;
}
export const Targets: FunctionComponent<TargetsProps> = ({
  targets,
  setTargets,
  targetType,
  setTargetType,
  companyName,
}) => {
  const isStudentsFetching = useSelector(selectAreUsersFetching);
  const isGroupsFetching = useSelector(selectAreGroupsFetching);
  const cognitoData = useSelector(getCognitoUserData);
  const groupId = cognitoData ? cognitoData["custom:group"] : undefined;
  const role = cognitoData ? cognitoData["custom:role"] : undefined;
  const groups = useSelector(selectGroups);
  const students = useSelector(selectStudents);

  const studentList =
    role === UserRole.Supervisor
      ? Object.values(students).filter(
          (student) => student.studentGroupId === groupId
        )
      : students;

  const groupList =
    role === UserRole.Supervisor
      ? groups.filter((group) => group._id === groupId)
      : groups;

  return (
    <div className={styles.container}>
      <Tabs
        className={styles.cogTabs}
        defaultActiveKey={targetType}
        onChange={(activeKey) => {
          if (activeKey === targetType) {
            return;
          }
          setTargetType(activeKey as TargetType);
          setTargets([]);
        }}
      >
        <TabPane className={styles.tab} tab={getTranslations('NOTIFICATION_GROUPS')} key={TargetType.groups}>
          {isGroupsFetching ? (
            <LoadingOverlay height={280} inline spinning />
          ) : (
            <TargetsFilter
              setTargetsSelected={(targets) => {
                setTargets(targets);
              }}
              selectedTargets={targets}
              targets={groupList.map((group) => {
                return {
                  id: group._id,
                  label: group.name,
                };
              })}
              searchPlaceHolder={getTranslations('NOTIFICATION_SEARCH_IN_COMPANY', [companyName])}
            />
          )}
        </TabPane>
        <TabPane
          className={styles.tab}
          tab={getTranslations('NOTIFICATION_STUDENTS')}
          key={TargetType.students}
        >
          {isStudentsFetching ? (
            <LoadingOverlay height={280} inline spinning />
          ) : (
            <TargetsFilter
              setTargetsSelected={(targets) => {
                setTargets(targets);
              }}
              selectedTargets={targets}
              targets={Object.values(studentList).map((student) => {
                return {
                  id: student.username,
                  label: student.name,
                  item: student,
                };
              })}
              searchPlaceHolder={getTranslations('NOTIFICATION_SEARCH_IN_STUDENTS')}
            />
          )}
        </TabPane>
      </Tabs>
    </div>
  );
};
