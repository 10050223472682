import React, { FunctionComponent, useEffect } from "react";

import { Modal, Tabs } from "antd";

import styles from './ranking.module.scss';
import { MiniBadge } from "./MiniBadge";
import { useDispatch, useSelector } from "react-redux";
import {  fetchStudents, selectUsers } from "../../../features/users/usersSlice";
import { RankingRecord, selectRanking } from "../../../features/students/rankingSlice";
import { getTranslations } from "../../../features/translations/translationsUtils";

interface IRankingProps {
  isMobile: boolean;
  opened: boolean;
  close: () => void;
}

export const Ranking: FunctionComponent<IRankingProps> = ({
  isMobile,
  opened,
  close,
}) => {

  const ranking = useSelector(selectRanking);
  const usersDict = useSelector(selectUsers);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchStudents());
  }, [dispatch]);

  const generateTable = (records: RankingRecord[] = []) => {
    return <div className={styles.table}>
      {records.map(record => {
        const { count, position, userId } = record;
        const user = usersDict[userId];
        if (!user) {
          return null;
        }
        return <div className={styles.row} key={userId}>
          <div className={styles.firstPart}>
            <div className={styles.badge}><MiniBadge position={position} /></div>
            <div className={styles.user}>
              <div className={styles.avatar}>
                <img className={styles.picture} src={user.picture} alt="" />
              </div>
              <div className={styles.name}>{user.name}</div>
            </div>
          </div>
          <div className={styles.points}>{count}</div>
        </div>;
      })}
    </div>
  };
  const items = [
    {
      label: <span>
        {getTranslations('STUDENT_PROFILE_RANKING_MODAL_POINTS')}
      </span>,
      key: 'points',
      children: generateTable(ranking.points),
    },
    {
      label: <span>
        {getTranslations('STUDENT_PROFILE_RANKING_MODAL_BADGES')}
      </span>,
      key: 'badges',
      children: generateTable(ranking.badges),
    },
    {
      label: <span>
        {getTranslations('STUDENT_PROFILE_RANKING_MODAL_COURSES')}
      </span>,
      key: 'courses',
      children: generateTable(ranking.courses),
    },
    {
      label: <span>
        {getTranslations('STUDENT_PROFILE_RANKING_MODAL_CERTIFICATES')}
      </span>,
      key: 'certificates',
      children: generateTable(ranking.certificates),
    },
  ];


  return <Modal
    closable={true}
    open={opened}
    title={getTranslations('STUDENT_PROFILE_RANKING_MODAL_TITLE')}
    className={styles.container}
    width={721}
    onCancel={close}
    footer={false}
  >
    <Tabs
      className={styles.tabs}
      defaultActiveKey="points"
      items={items}
    />
  </Modal>;
};