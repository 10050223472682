
export enum Prefixes {
  company = '/companies',
  group = '/groups',
  program = '/programs',
  course = '/courses',
  topic = '/topics',
  lesson = '/lessons',
  evaluation = '/evaluations',
  knowledgeBase = '/knowledge-bases',
  campaign = '/campaigns',
  reports = '/reports',
  user = '/users',
  notification = '/notifications',
  educationalContent = '/educationalContent',
  reportedCases = '/reportedCases',
  reportCampaign = '/campaign',
}

export enum ActionSuffixes {
  new = '/new',
  edit = '/edit',
  print = '/print',
}