import React from "react";
import { ICampaign } from "../../../../features/campaign/campaignSlice";
import { DisplayDirection, IconNames } from "../../common/SimpleStat";
import { StatItemType, StatsRow } from "../../common/StatsRow";
import { getTranslations } from "../../../../features/translations/translationsUtils";

interface Props {
  campaigns: ICampaign[];
  enabledCampaigns: ICampaign[];
}

export const CampaignStats: React.FunctionComponent<Props> = ({
  campaigns,
  enabledCampaigns,
}) => {
  const stats: StatItemType[] = [
    {
      description: getTranslations('REPORT_CREATED_CAMPAIGNS'),
      iconName: IconNames.Campaign,
      mainText: campaigns.length.toString(),
      direction: DisplayDirection.row,
    },
    {
      description: getTranslations('REPORT_ENABLED_CAMPAIGNS'),
      iconName: IconNames.CampaignActive,
      mainText: enabledCampaigns.length.toString(),
      direction: DisplayDirection.row,
    },
  ];
  return <StatsRow statsData={stats} />;
};
