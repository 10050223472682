import React, { FunctionComponent, useEffect, useState } from "react";

import { Button, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";

import {
  selectAreCoursesFetching,
} from "../../features/courses/coursesSlice";
import { MainLayout } from "../common/Layouts/Main";
import { UserForm } from "./Form";
import { Prefixes } from "../prefixes";
import { disableUsersStatus, enableUsersStatus, selectUser } from "../../features/users/usersSlice";
import { getTranslations } from "../../features/translations/translationsUtils";
import { selectCompany } from "../../features/company/companySlice";

export const EditUser: FunctionComponent = () => {
  const match = useRouteMatch();
  const areCoursesFetching = useSelector(selectAreCoursesFetching);
  const [form] = Form.useForm();
  const history = useHistory();
  const [title, setTitle] = useState('');
  const [enabled, setEnabled] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!areCoursesFetching) {
      form.setFieldsValue({});
      form.resetFields();
    }
  }, [form, areCoursesFetching]);
  const editing = (match.params as any).id!;
  const userToEdit = useSelector(selectUser(editing || ""));
  const company = useSelector(selectCompany(userToEdit?.company ?? ''));


  const goToPrint = () => {
    history.push(`/admin-users/${editing}/print`);
  };

  const enableStatus = () => {
    const data = {
      usernames: [editing],
    };
    dispatch(enableUsersStatus(true, data));
    setEnabled(!enabled);
  };

  const disableStatus = () => {
    const data = {
      usernames: [editing],
    };
    dispatch(disableUsersStatus(true, data));
    setEnabled(!enabled);
  };
  const renderOptions = () => {
    return <>
      <Button
        type="link"
        size="large"
        onClick={!enabled ? enableStatus : disableStatus}
      >
        {!enabled ? getTranslations('LIST_ENABLE') : getTranslations('LIST_DISABLE')}
      </Button>&nbsp;&nbsp;&nbsp;
      <Button
        type="link"
        size="large"
        onClick={goToPrint}
        className="withBorder"
      >
        {getTranslations('USERS_DOWNLOAD_FILE')}
      </Button>&nbsp;&nbsp;&nbsp;
      {company?.showInfographic ? <Button
        type="primary"
        size="large"
        onClick={() => {
          history.push(`/users/${editing}/infographic`);
        }}
      >
        {getTranslations('USERS_VIEW_INFOGRAPHIC')}
      </Button> : null}
    </>;
  }
  return (
    <MainLayout
      hideTitle={false}
      title={title}
      onGoBack={() => {
        history.push(`${Prefixes.user}`);
      }}
      breadcrumbs={[getTranslations('USERS_TITLE'), title]}
      rightTopOptions={renderOptions()}
    >
      <UserForm
        editing={editing}
        form={form}
        setTitle={setTitle}
        setEnabled={setEnabled}
      />
    </MainLayout>
  );
};
