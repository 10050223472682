import { Form } from "antd";
import React, { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCategories,
  selectCategories,
  selectCategoriesDict,
} from "../../features/category/categorySlice";
import {
  selectAreCoursesFetching,
} from "../../features/courses/coursesSlice";
import { MainLayout } from "../common/Layouts/Main";
import { CoursesForm } from "./Form";
import { useHistory } from "react-router-dom";
import { Prefixes } from "../prefixes";
import { getTranslations } from "../../features/translations/translationsUtils";

export const NewCourse: FunctionComponent = () => {
  const areCoursesFetching = useSelector(selectAreCoursesFetching);
  const categories = useSelector(selectCategoriesDict);
  const categoriesIds = useSelector(selectCategories);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!areCoursesFetching) {
      form.setFieldsValue({});
      form.resetFields();
    }
  }, [form, areCoursesFetching]);

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  return (
    <MainLayout
      title={getTranslations('COURSE_CREATE')}
      onGoBack={() => {
        history.push(`${Prefixes.course}`);
      }}
      breadcrumbs={[getTranslations('COURSE_TITLE'), getTranslations('COURSE_CREATE_SHORT')]}
    >
      <CoursesForm
        categories={categories}
        categoriesIds={categoriesIds}
        editing={null}
        form={form}
      />
    </MainLayout>
  );
};
