import React, { FunctionComponent } from "react";

import styles from './certificates.module.scss';

import starIcon from '../../../assets/infographic/star.png';
import { useSelector } from "react-redux";
import { getCognitoUserData } from "../../../features/auth/authSlice";
import { selectUser } from "../../../features/users/usersSlice";
import { selectUserRanking } from "../../../features/students/rankingSlice";
import { getTranslations } from "../../../features/translations/translationsUtils";

export const Certificates: FunctionComponent<{
  userId?: string
}> = ({ userId }) => {
  const cognitoUserData = useSelector(getCognitoUserData);
  const cognitoUsername = cognitoUserData
    ? cognitoUserData["cognito:username"]
    : null;
  const userFromStore = useSelector(selectUser(cognitoUsername));
  const userRanking = useSelector(selectUserRanking(userId ?? userFromStore?.username));
  return  <div className={styles.container}>
  <div className={styles.icon}><img alt="Points" src={starIcon}/></div>
  <div className={styles.content}>
    <div className={styles.points}>{userRanking.certificates?.count ?? 0}</div>
    <div className={styles.description}>{getTranslations('STUDENT_PROFILE_OBTAINED_CERTIFICATES')}</div>
  </div>
</div>;
};