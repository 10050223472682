import React, { FunctionComponent, ReactNode, useEffect, useState } from "react";

import {
  Alert,
  Button,
  Card,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  Select,
  Row, Col, Switch
} from "antd";
import { FormInstance } from "antd/lib/form";
import cls from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep } from "lodash";

import { Microservices } from "../../app/AllowedMicroservices";
import { BASE_URL } from "../../app/apiMiddleware";
import { selectCompanyId, selectUserRole } from '../../features/auth/authSlice';
import {
  fetchBlueprints,
  IBlueprint,
  selectBlueprintsDict,
} from "../../features/blueprint/blueprintSlice";
import {
  ICategoriesDict,
  ICategory,
} from "../../features/category/categorySlice";
import { selectCompanies } from '../../features/company/companySlice';
import {
  createCourse,
  ICourse,
  selectAreCoursesFetching,
  selectCourse,
  updateCourse,
  hideModal,
  selectDisplayModal,
  fetchCourses,
} from "../../features/courses/coursesSlice";
import {
  fetchEvaluations,
  selectEvaluationsDict,
  selectExamns,
  selectSurveys,
} from "../../features/evaluation/evaluationsSlice";
import { fetchLessons } from "../../features/lesson/lessonSlice";
import {
  fetchTopics,
  ITopic,
  selectTopics,
  selectTopicsIds,
} from "../../features/topic/topicSlice";
import { UserRole } from '../../features/users/usersSlice';
import { DUMMY_DATA } from "../Blueprint/BlueprintForm";
import Template1, { TemplateProps } from "../Certificates/Template1";
import Template2 from "../Certificates/Template2";
import Template3 from "../Certificates/Template3";
import Template4 from "../Certificates/Template4";
import { CategoryField, OTHER_CATEGORY_OPTION } from "../common/CategoryField";
import { CogIcon } from "../common/CogIcon";
import ConfirmationModal from "../common/ConfirmationModal";
import LoadingOverlay from "../common/LoadingOverlay";
import { StatusEnum } from "../common/Status";
import { MIN_3_CHAR } from "../Users/Form";
import { UploadFile } from "../Users/UploadFile";
import { TopicsSelected } from "./TopicsSelected";
import { FormFooter } from "../common/FormFooter";
import { AfterActions, DialogPopUp } from "../common/DialogPopUp";
import { useHistory } from "react-router-dom";
import { ActionSuffixes, Prefixes } from "../prefixes";
import { fetchPrefixedImages, selectPrefixedImages } from "../../features/prefixedImages/prefixedImagesSlice";

import commonStyles from "../common/form.module.scss";
import styles from "./form.module.scss";

import logo from "../../assets/logo-teurona.svg";
import { getTranslations } from "../../features/translations/translationsUtils";

const MAX_TAGS = 10;

interface StepProps {
  setCourse: Function;
  form: FormInstance;
  categories: ICategoriesDict;
  categoriesIds: string[];
  course?: ICourse;
  validateForm?: Function;
}

const FirstStep: FunctionComponent<StepProps> = ({
  course,
  setCourse,
  categories,
  categoriesIds,
}) => {
  const companies = useSelector(selectCompanies);
  const userRole = useSelector(selectUserRole);
  const handleCompanyChange = (value: string) => {
    setCourse({ ...course, companyId: value });
  };

  return (
    <React.Fragment>
      <Form.Item
        name="title"
        label={getTranslations('COURSE_FIELD_TITLE')}
        initialValue={course?.title || ""}
        rules={[
          { type: "string", required: true, message: getTranslations('GENERAL_REQUIRED') },
          { type: "string", min: 3, message: MIN_3_CHAR() },
          {
            type: "string",
            max: 200,
            message: getTranslations('COURSE_FIELD_TITLE_ERROR_1'),
          },
        ]}
      >
        <Input
          placeholder={getTranslations('COURSE_FIELD_TITLE')}
          onChange={(event) => {
            setCourse({ ...course, title: event.target.value });
          }}
        />
      </Form.Item>
      <CategoryField
        categories={categories}
        categoriesIds={categoriesIds}
        markRequired
        label={getTranslations('COURSE_CATEGORY')}
        object={course}
        setObject={setCourse}
      />
      <Form.Item
        label={getTranslations('COURSE_DURATION')}
        name="durationHour"
        initialValue={course?.durationHour || ""}
        rules={[
          { type: "number", required: true, message: getTranslations('GENERAL_REQUIRED') },
          { type: "number", min: 1, message: getTranslations('COURSE_DURATION_ERROR_1') },
          { type: "number", max: 2000, message: getTranslations('COURSE_DURATION_ERROR_2') },
        ]}
      >
        <InputNumber
          style={{ width: '100%' }}
          onChange={(value) => {
            setCourse({ ...course, durationHour: value });
          }}
        />
      </Form.Item>
      <Form.Item
        label={getTranslations('COURSE_DESCRIPTION')}
        name="description"
        initialValue={course?.description || ""}
        rules={[
          { type: "string", required: true, message: getTranslations('GENERAL_REQUIRED') },
          { type: "string", min: 3, message: MIN_3_CHAR() },
          {
            type: "string",
            max: 2000,
            message: getTranslations('COURSE_DESCRIPTION_ERROR_1'),
          },
        ]}
      >
        <Input.TextArea
          onChange={(event) => {
            setCourse({ ...course, description: event.target.value });
          }}
        />
      </Form.Item>
      {userRole === UserRole.SuperAdmin ? <Form.Item
        label={getTranslations('GENERAL_COMPANY')}
        name="company"
        initialValue={course?.companyId || ''}
      >
        <Select
          placeholder=""
          onChange={handleCompanyChange}
          style={{ width: "100%" }}
          disabled={course?.topics && course?.topics.length > 0}
        >
          <Select.Option key={'all_companies'} value={''}>
            {getTranslations('COMPANY_ALL')}
          </Select.Option>
          {Object.values(companies).map(company => {
            return <Select.Option key={company._id} value={company._id}>
              {company.name}
            </Select.Option>
          })}
        </Select>
      </Form.Item> : null}
    </React.Fragment>
  );
};
const ImageStep: FunctionComponent<StepProps> = ({
  course,
  setCourse,
}) => {
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const prefixedImages = useSelector(selectPrefixedImages);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPrefixedImages());
  }, [dispatch]);

  const setPrefixedImage = (imageurl: string) => {
    setCourse({ ...course, imageurl });
  }
  const clearFile = () => {
    setCourse({ ...course, imageurl: null });
  };

  const handleChangeFile = (info: any) => {
    const { status } = info.file;
    if (status === "uploading") {
      setLoading(true);
      setError("");
      return;
    }
    if (status === "done") {
      setLoading(false);
      setCourse({ ...course, imageurl: info.file.response.url });
    } else if (status === "error") {
      setError(getTranslations('COURSE_UPLOAD_ERROR_1'));
    }
  };
  return (
    <React.Fragment>
      <UploadFile
        addButton
        clearFile={clearFile}
        onHandleChange={handleChangeFile}
        loading={loading}
        uploadUrl={`${BASE_URL[Microservices.LEARNING_SERVICE]
          }/media/uploadToS3`}
        fileTypesAllowed={["image/jpeg", "image/png", "image/gif"]}
        fileNotAllowedMessage={getTranslations('COURSE_ONLY_ALLOWED_FILES')}
        imageUrlToShow={course?.imageurl}
        short
        setPrefixedImage={setPrefixedImage}
        prefixedImages={prefixedImages}
      />
      {error}
    </React.Fragment>
  );
};

const ThirdStep: FunctionComponent<StepProps> = ({
  course,
  setCourse,
}) => {
  const dispatch = useDispatch();
  const topicsIds = useSelector(selectTopicsIds);
  const topics = useSelector(selectTopics);
  const userRole = useSelector(selectUserRole);
  const companyId = useSelector(selectCompanyId);
  useEffect(() => {
    dispatch(fetchTopics());
    dispatch(fetchLessons());
  }, [dispatch]);
  const topicByTitleComparator = (a: ITopic, b: ITopic) => {
    const aName = a.title.toLowerCase();
    const bName = b.title.toLowerCase();

    if (aName < bName) {
      return -1;
    }
    if (aName > bName) {
      return 1;
    }
    return 0;
  };
  const selectedTopics = course?.topics ? course.topics : [];
  const menuItems = topicsIds
    .filter((topicId) => {
      const noIncludedInSelectecTopic = !selectedTopics.includes(topicId);
      const isSuperAdmin = userRole === UserRole.SuperAdmin;
      const courseCompanyId = isSuperAdmin ? (!course?.companyId ? undefined : course?.companyId) : companyId;
      const topicFromCompany = topics[topicId].companyId === courseCompanyId;
      return noIncludedInSelectecTopic && topicFromCompany;
    })
    .map((topicId) => topics[topicId])
    .sort(topicByTitleComparator)
    .map((topic) => {
      if (!topic || topic.status === StatusEnum.Disable) {
        return null;
      }
      return (
        <Menu.Item
          key={topic._id}
          onClick={() => {
            setCourse({ ...course, topics: [...selectedTopics, topic._id] });
          }}
        >
          {topic.title}
        </Menu.Item>
      );
    });
  const hasTopicsAvailable = menuItems.length > 0;
  const menu = (
    <Card>
      <Menu
        onClick={({ key }) => {
          if (menuItems.length === 0) {
            return;
          }
          setCourse({ ...course, topics: [...selectedTopics, key] });
        }}
      >
        {menuItems}
      </Menu>
    </Card>
  );
  return (
    <React.Fragment>
      <TopicsSelected
        selectedTopics={selectedTopics}
        setCourse={setCourse}
        course={course}
      />
      {hasTopicsAvailable ? <Dropdown
        overlay={menu}
        overlayClassName={styles.topicMenu}
        disabled={menuItems.length === 0}
        trigger={["click"]}
      >
        <Button
          disabled={menuItems.length === 0}
          className={cls(styles.addTopicButton, commonStyles.addNewButton)}
          type="default"
        >
          {getTranslations('COURSE_ADD_TOPIC')}
          <CogIcon className={commonStyles.square} color="var(--secondary-text-button-color)" icon="Arrow-drop-down" />
        </Button>
      </Dropdown> : <Alert message={getTranslations('COURSE_ADD_TOPIC_LESSON_BEFORE_CREATE')} type="info" />}
    </React.Fragment>
  );
};

const FourthStep: FunctionComponent<StepProps> = ({
  setCourse,
  course,
  form,
}) => {
  const hasAdditionalInfoOpened = !!(course?.additionalInfo || course?.objectives || course?.evaluationSystem || (course?.tags && course?.tags.length > 0));
  const [additionalInfoOpened, setAdditionalInfoOpened] = useState(hasAdditionalInfoOpened);
  const [surveyOpened, setSurveyOpened] = useState(!!course?.survey);
  const [examOpened, setExamOpened] = useState(!!course?.evaluation);
  const [certificateOpened, setCertificateOpened] = useState(!!course?.certificate);
  const surveys = useSelector(selectSurveys);
  const exams = useSelector(selectExamns);
  const evaluations = useSelector(selectEvaluationsDict);
  const dispatch = useDispatch();

  const evaluationsDict = useSelector(selectEvaluationsDict);
  const handleExamUpdate = (evaluationId: string) => {
    setCourse({ ...course, evaluation: evaluationId });
  };
  useEffect(() => {
    dispatch(fetchEvaluations());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchBlueprints());
  }, [dispatch]);

  const handleSurveyUpdate = (evaluationId: string) =>
    setCourse({ ...course, survey: evaluationId });

  const handleTagsChange = (value: string[]) => {
    if (value.length <= MAX_TAGS) {
      setCourse({ ...course, tags: [...value] });
    }
  };
  const handleObjectivesChange: React.ChangeEventHandler<HTMLTextAreaElement> = (event) =>
    setCourse({ ...course, objectives: event.target.value });

  const handleEvaluationSystemChange: React.ChangeEventHandler<HTMLTextAreaElement> = (event) =>
    setCourse({ ...course, evaluationSystem: event.target.value });

  const handleAdditionalInfoChange: React.ChangeEventHandler<HTMLTextAreaElement> = (event) =>
    setCourse({ ...course, additionalInfo: event.target.value });

  const tags: string[] = course?.tags ? [...course.tags] : [];
  return (
    <React.Fragment>
      <div className={styles.hidableContainer}>
        <div className={styles.label}>{getTranslations('COURSE_ADDITIONAL_INFO')}</div>
        <Switch checked={additionalInfoOpened} size='small' onChange={() => {
          setCourse({ ...course, objectives: null, evaluationSystem: null, additionalInfo: null, tags: [] });
          form.setFieldValue('objectives', null);
          form.setFieldValue('evaluationSystem', null);
          form.setFieldValue('additionalInfo', null);
          form.setFieldValue('tags', []);
          setAdditionalInfoOpened(!additionalInfoOpened);
        }} />
      </div>
      {additionalInfoOpened ? <div>
        <Form.Item
          label={getTranslations('GENERAL_TAGS')}
          name={"tags"}
          rules={[{ required: false, message: getTranslations('GENERAL_REQUIRED') }]}
          initialValue={tags}
        >
          <Select
            mode="tags"
            // size={"default" as SizeType}
            placeholder={getTranslations('GENERAL_TAGS_ADD')}
            onChange={handleTagsChange}
            style={{ width: "100%" }}
            maxTagCount={MAX_TAGS}
            maxTagTextLength={20}
            tokenSeparators={[" ", ","]}
          ></Select>
        </Form.Item>
        <Form.Item
          label={getTranslations('COURSE_OBJECTIVE')}
          name={"objectives"}
          rules={[{ required: false, message: getTranslations('GENERAL_REQUIRED') }]}
          initialValue={course?.objectives ? course?.objectives : null}
        >
          <Input.TextArea
            // size={"default" as SizeType}
            placeholder={`${getTranslations('COURSE_OBJECTIVE')}...`}
            onChange={handleObjectivesChange}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          label={getTranslations('COURSE_EVALUATION_SYSTEM')}
          name={"evaluationSystem"}
          rules={[{ required: false, message: getTranslations('GENERAL_REQUIRED') }]}
          initialValue={course?.evaluationSystem ? course?.evaluationSystem : null}
        >
          <Input.TextArea
            // size={"default" as SizeType}
            placeholder={`${getTranslations('COURSE_EVALUATION_SYSTEM')}...`}
            onChange={handleEvaluationSystemChange}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          label={getTranslations('COURSE_ADDITIONAL_INFO')}
          name={"additionalInfo"}
          rules={[{ required: false, message: getTranslations('GENERAL_REQUIRED') }]}
          initialValue={course?.additionalInfo ? course?.additionalInfo : null}
        >
          <Input.TextArea
            // size={"default" as SizeType}
            placeholder={`${getTranslations('COURSE_ADDITIONAL_INFO')}...`}
            onChange={handleAdditionalInfoChange}
            style={{ width: "100%" }}
          />
        </Form.Item>
      </div> : null}
      <div className={commonStyles.separator} />
      <div className={styles.hidableContainer}>
        <div className={styles.label}>{getTranslations('COURSE_FINAL_SURVEY')}</div>
        <Switch checked={surveyOpened} size='small' onChange={() => {
          setCourse({ ...course, survey: null });
          form.setFieldValue('survey', null);
          setSurveyOpened(!surveyOpened);
        }} />
      </div>
      {surveyOpened ? <Form.Item
        label={getTranslations('COURSE_SELECT_SURVEY')}
        name={"survey"}
        rules={[{ required: false, message: getTranslations('GENERAL_REQUIRED') }]}
        initialValue={course?.survey ? course?.survey?.toString() : undefined}
      >
        <Select
          placeholder={getTranslations('COURSE_SELECT_SURVEY')}
          style={{ width: "100%" }}
          onChange={(value) => {
            handleSurveyUpdate(`${value}`);
          }}
        >
          {surveys.map((id) => {
            return (
              <Select.Option key={id} value={id} className={styles.option}>
                {evaluations[id].title} <span className={styles.questionCount}>({getTranslations('COURSE_QUESTION_COUNT', [evaluations[id].questions.length])})</span>
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item> : null}
      <div className={commonStyles.separator} />
      <div className={styles.hidableContainer}>
        <div className={styles.label}>{getTranslations('COURSE_EXAM')}</div>
        <Switch checked={examOpened} size='small' onChange={() => {
          setCourse({ ...course, exam: null });
          form.setFieldValue('exam', null);
          setExamOpened(!examOpened);
        }} />
      </div>
      {examOpened ? <>
        <Form.Item
          label={getTranslations('COURSE_SELECT_EXAM')}
          name={"evaluation"}
          rules={[{ required: false, message: getTranslations('GENERAL_REQUIRED') }]}
          initialValue={course?.evaluation ? course?.evaluation?.toString() : undefined}
        >
          <Select
            placeholder={getTranslations('COURSE_SELECT_EXAM')}
            style={{ width: "100%" }}
            onChange={(value) => {
              handleExamUpdate(`${value}`);
            }}
          >
            {exams.map((id) => {
              return (
                <Select.Option key={id} value={id} className={styles.option}>
                  {evaluations[id].title} <span className={styles.questionCount}>({getTranslations('COURSE_QUESTION_COUNT', [evaluations[id].questions.length])})</span>
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        {course?.evaluation && evaluationsDict[course.evaluation] ? (
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                initialValue={course?.correctAnswersToPass}
                name="correctAnswersToPass"
                label={getTranslations('COURSE_ANSWER_TO_APPROVE')}
                rules={[
                  { required: true, message: getTranslations('GENERAL_REQUIRED') },
                  {
                    type: "number",
                    min: 1,
                    message: getTranslations('COURSE_ANSWER_TO_APPROVE_ERROR_1'),
                  },
                  {
                    type: "number",
                    max: evaluationsDict[course.evaluation].questions
                      .length,
                    message: getTranslations('COURSE_ANSWER_TO_APPROVE_ERROR_2', [evaluationsDict[course.evaluation].questions.length]),
                  },
                  {
                    validator: (rule, value: any) => {
                      return Promise.resolve(0);
                    },
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  placeholder={getTranslations('COURSE_ANSWER_TO_APPROVE_PLACEHOLDER')}
                  onChange={(value) => {
                    setCourse({ ...course, correctAnswersToPass: value });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={course?.timeToRetry}
                name="timeToRetry"
                label={getTranslations('COURSE_TIME_TO_RETRY')}
                rules={[
                  { required: true, message: getTranslations('GENERAL_REQUIRED') },
                  {
                    type: "number",
                    min: 1,
                    message: getTranslations('COURSE_TIME_TO_RETRY_ERROR_1'),
                  },
                  {
                    validator: (rule, value: any) => {
                      return Promise.resolve(0)
                    },
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  placeholder={getTranslations('COURSE_TIME_TO_RETRY')}
                  onChange={(value) => {
                    setCourse({ ...course, timeToRetry: value });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        ) : (
          null
        )}
      </> : null}
      <div className={commonStyles.separator} />
      <div className={styles.hidableContainer}>
        <div className={styles.label}>{getTranslations('COURSE_CERTIFICATE')}</div>
        <Switch checked={certificateOpened} size='small' onChange={(value) => {
          if (value) {
            setCourse({ ...course, certificate: null, certificateInfo: null });
            form.setFieldValue('certificate', null);
            form.setFieldValue('certificateInfo', getEmptyCertificateInfo());
          } else {
            setCourse({ ...course, certificate: null, certificateInfo: null });
            form.setFieldValue('certificate', null);
            form.setFieldValue('certificateInfo', null);
          }
          setCertificateOpened(!certificateOpened);
        }} />
      </div>
      {certificateOpened ? <CertificateForm setCourse={setCourse} course={course} form={form} /> : null}
    </React.Fragment >
  );
};

interface CertificateFormProps {
  setCourse: Function;
  form: FormInstance;
  course?: ICourse;
}


export const EMPTY_DATA = {
  courseName: "",
  description: "",
  totalHours: 0,
  name: "",
  traineeNationalId: "",
  companyName: "",
  uniqueCode: "",
  createdAt: "",
  companyLogo: logo,
};

const getEmptyCertificateInfo = (): IBlueprint => {
  return {
    _id: '',
    blueprintId: 'template1',
    blueprintName: '',
    showCompanyName: true,
    showName: true,
    showDescription: true,
    showTotalHours: true,
    showTraineeFullName: true,
    showTraineeNationalId: true,
    showNameAndLogo: true,
    showUniqueCode: true,
    showQrCode: true,
    status: StatusEnum.Enable,
  };
}
const CertificateForm: FunctionComponent<CertificateFormProps> = ({ course, setCourse, form }) => {
  let { certificateInfo } = (course || {}) as ICourse;
  const blueprints = useSelector(selectBlueprintsDict);
  const newBlueprint: IBlueprint = {
    showName: true,
    showDescription: true,
    showTotalHours: true,
    showTraineeFullName: true,
    showTraineeNationalId: true,
    showCompanyName: true,
    showNameAndLogo: true,
    showUniqueCode: true,
    showQrCode: true,
  } as IBlueprint;
  const TEMPLATES: Record<string, ReactNode> = {
    template1: <Template1 baseFontSizePx={4} {...newBlueprint} {...EMPTY_DATA} />,
    template2: <Template2 baseFontSizePx={4} {...newBlueprint} {...EMPTY_DATA} />,
    template3: <Template3 baseFontSizePx={5} {...newBlueprint} {...EMPTY_DATA} />,
    template4: <Template4 baseFontSizePx={5} {...newBlueprint} {...EMPTY_DATA} />,
  };
  const TEMPLATES_COMPONENTS: Record<string, React.FunctionComponent<TemplateProps>> = {
    template1: Template1,
    template2: Template2,
    template3: Template3,
    template4: Template4,
  }
  const setNewBlueprint = (certificateInfo: any) => {
    setCourse({ ...(course || {}), certificateInfo: { ...certificateInfo } });
  };
  useEffect(() => {
    if (course?.certificate && !course?.certificateInfo && blueprints[course?.certificate]) {
      setCourse({ ...(course || {}), certificateInfo: { ...blueprints[course?.certificate] } });
    }
  }, [blueprints, certificateInfo, course, setCourse]);

  certificateInfo = certificateInfo ? certificateInfo : getEmptyCertificateInfo();
  const selectedTemplate = certificateInfo?.blueprintId || 'template1';
  const SelectedTemplate = TEMPLATES_COMPONENTS[selectedTemplate];
  return <>
    <div className={styles.templates}>
      {Object.keys(TEMPLATES).map(key => {
        return <div
          key={key}
          className={cls(
            styles.templateWrapper,
            { [styles.selected]: key === certificateInfo?.blueprintId }
          )}
          onClick={() => {
            setCourse({ ...(course || {}), certificateInfo: { ...certificateInfo, blueprintId: key } });
          }}
        >
          {TEMPLATES[key]}
        </div>
      })}
    </div>
    <div className={styles.bluePrintWrapper}>
      {certificateInfo ? <SelectedTemplate
        baseFontSizePx={4}
        {...EMPTY_DATA}
        {...DUMMY_DATA}
        {...(certificateInfo as unknown as TemplateProps)}
      /> : null}
    </div>


    <div className={styles.options}>
      <Form.Item
        initialValue={newBlueprint?.showName}
        wrapperCol={{ sm: 24 }}
      >
        <div className={styles.displayField}>
          <div>{getTranslations('COURSE_CERT_COURSE_NAME')}</div>
          <div>
            <Switch
              checked={certificateInfo?.showName}
              onChange={(value) => {
                setNewBlueprint({ ...certificateInfo, showName: value });
              }}
            />
          </div>
        </div>
      </Form.Item>
      <Form.Item
        initialValue={certificateInfo?.showDescription}
        wrapperCol={{ sm: 24 }}
      >
        <div className={styles.displayField}>
          <div>{getTranslations('COURSE_CERT_COURSE_DESCRIPTION')}</div>
          <div>
            <Switch
              checked={certificateInfo?.showDescription}
              onChange={(value) => {
                setNewBlueprint({ ...certificateInfo, showDescription: value });
              }}
            />
          </div>
        </div>
      </Form.Item>
      <Form.Item
        initialValue={certificateInfo?.showTotalHours}
        wrapperCol={{ sm: 24 }}
      >
        <div className={styles.displayField}>
          <div>{getTranslations('COURSE_CERT_COURSE_DURATION')}</div>
          <div>
            <Switch
              checked={certificateInfo?.showTotalHours}
              onChange={(value) => {
                setNewBlueprint({ ...certificateInfo, showTotalHours: value });
              }}
            />
          </div>
        </div>
      </Form.Item>
      <Form.Item
        initialValue={certificateInfo?.showTraineeFullName}
        wrapperCol={{ sm: 24 }}
      >
        <div className={styles.displayField}>
          <div>{getTranslations('COURSE_CERT_PARTICIPANT_NAME')}</div>
          <div>
            <Switch
              checked={certificateInfo?.showTraineeFullName}
              onChange={(value) => {
                setNewBlueprint({
                  ...certificateInfo,
                  showTraineeFullName: value,
                });
              }}
            />
          </div>
        </div>
      </Form.Item>
      <Form.Item
        initialValue={certificateInfo?.showTraineeNationalId}
        wrapperCol={{ sm: 24 }}
      >
        <div className={styles.displayField}>
          <div>{getTranslations('COURSE_CERT_PARTICIPANT_ID')}</div>
          <div>
            <Switch
              checked={certificateInfo?.showTraineeNationalId}
              onChange={(value) => {
                setNewBlueprint({
                  ...certificateInfo,
                  showTraineeNationalId: value,
                });
              }}
            />
          </div>
        </div>
      </Form.Item>
      <Form.Item
        initialValue={certificateInfo?.showCompanyName}
        wrapperCol={{ sm: 24 }}
      >
        <div className={styles.displayField}>
          <div>{getTranslations('COURSE_CERT_COMPANY_NAME')}</div>
          <div>
            <Switch
              checked={certificateInfo?.showCompanyName}
              onChange={(value) => {
                setNewBlueprint({ ...certificateInfo, showCompanyName: value });
              }}
            />
          </div>
        </div>
      </Form.Item>
      <Form.Item
        initialValue={certificateInfo?.showNameAndLogo}
        wrapperCol={{ sm: 24 }}
      >
        <div className={styles.displayField}>
          <div>{getTranslations('COURSE_CERT_TEURONA_NAME_AND_LOGO')}</div>
          <div>
            <Switch
              checked={certificateInfo?.showNameAndLogo}
              onChange={(value) => {
                setNewBlueprint({ ...certificateInfo, showNameAndLogo: value });
              }}
            />
          </div>
        </div>
      </Form.Item>
      <Form.Item
        initialValue={certificateInfo?.showUniqueCode}
        wrapperCol={{ sm: 24 }}
      >
        <div className={styles.displayField}>
          <div>{getTranslations('COURSE_CERT_UNIQUE_CODE')}</div>
          <div>
            <Switch
              checked={certificateInfo?.showUniqueCode}
              onChange={(value) => {
                setNewBlueprint({ ...certificateInfo, showUniqueCode: value });
              }}
            />
          </div>
        </div>
      </Form.Item>
      <Form.Item
        initialValue={certificateInfo?.showQrCode}
        wrapperCol={{ sm: 24 }}
      >
        <div className={styles.displayField}>
          <div>{getTranslations('COURSE_CERT_QR_CODE')}</div>
          <div>
            <Switch
              checked={certificateInfo?.showQrCode}
              onChange={(value) => {
                setNewBlueprint({ ...certificateInfo, showQrCode: value });
              }}
            />
          </div>
        </div>
      </Form.Item>
    </div>
  </>;
}


interface CoursesFormPros {
  form: FormInstance;
  editing: string | null;
  categories: ICategoriesDict;
  categoriesIds: string[];
}

export const CoursesForm: FunctionComponent<CoursesFormPros> = ({
  form,
  editing,
  categories,
  categoriesIds,
}) => {
  const [afterAction, setAfterAction] = useState('');
  const [restart, setRestart] = useState(true);
  const areCoursesFetching = useSelector(selectAreCoursesFetching);
  const courseToEdit = useSelector(selectCourse(editing));
  const coursesAreFetching = useSelector(selectAreCoursesFetching);
  const topics = useSelector(selectTopics);
  const userRole = useSelector(selectUserRole);
  const companyId = useSelector(selectCompanyId);
  const [course, setCourse] = useState<ICourse>();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const isModalVisible = useSelector(selectDisplayModal);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (courseToEdit) {
      setCourse(courseToEdit);
    }
  }, [courseToEdit]);

  useEffect(() => {
    if (editing) {
      dispatch(fetchCourses());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (restart) {
      form.resetFields();
      form.setFieldsValue({});
      setRestart(false);
    }
  }, [restart, form]);

  if (areCoursesFetching || (editing && !course)) {
    return <LoadingOverlay spinning={true} />;
  }
  const handleCancel = () => setShowConfirmationModal(false);

  const doAfterAction = () => {
    if (afterAction === AfterActions.BACK) {
      history.push(`${Prefixes.course}`);
      return;
    }
    if (!editing) {
      setTimeout(() => {
        form.resetFields();
        form.setFieldsValue({});
        setCourse({} as ICourse);
        setRestart(true);
      }, 100);
      return;
    }
    history.push(`${Prefixes.course}${ActionSuffixes.new}`);
  }

  const onSubmit = (afterAction: AfterActions) => {
    if (!course) {
      return;
    }
    setAfterAction(afterAction);
    if (
      !course.topics ||
      course.topics.length === 0 ||
      course.topics?.find((item) => topics[item].lessons.length === 0)
    ) {
      setShowConfirmationModal(true);
      return;
    }
    onSave();
  };

  const onSave = () => {
    setShowConfirmationModal(false);
    if (!course) {
      return;
    }
    const courseCopy = cloneDeep(course);
    if (courseCopy?.category === OTHER_CATEGORY_OPTION) {
      courseCopy.category = {
        name: courseCopy.categoryName || "",
      } as ICategory;
    }
    delete courseCopy.categoryName;
    if (userRole !== UserRole.SuperAdmin) {
      if (courseCopy._id && courseCopy.companyId !== companyId) { // don't allow editing courses that don't belong to the company
        return;
      }
      courseCopy.companyId = companyId; // force to company in case of creating
    }
    if (!courseCopy.companyId) {
      courseCopy.companyId = undefined;
    }
    if (courseCopy._id) {
      dispatch(updateCourse(courseCopy));
    } else {
      dispatch(createCourse(courseCopy));
    }
  };

  if (coursesAreFetching || restart) {
    return <LoadingOverlay spinning={true} />;
  }
  return (
    <>
      <DialogPopUp
        name={getTranslations('COURSE_TITLE_SINGULAR')}
        action={editing ? getTranslations('COURSE_UPDATED') : getTranslations('COURSE_CREATED')}
        visible={isModalVisible}
        onCancel={() => {
          dispatch(hideModal());
          doAfterAction();
        }}
      />
      {showConfirmationModal && (
        <ConfirmationModal
          headerIcon="Help"
          title={getTranslations('COURSE_WITHOUT_TOPIC_TITLE')}
          message={<p>{getTranslations('COURSE_WITHOUT_TOPIC_MESSAGE')}</p>}
          footer={[
            <Button type="link" onClick={onSave} key="save">
              {getTranslations('COURSE_WITHOUT_TOPIC_SAVE')}
            </Button>,
            <Button type="text" onClick={handleCancel} key="cancel">
              {getTranslations('COURSE_WITHOUT_TOPIC_CANCEL')}
            </Button>,
          ]}
          onCancel={handleCancel}
          onConfirmation={onSave}
        />
      )}
      <Form
        form={form}
        className={commonStyles.form}
        layout="vertical"
        name="control-hooks"
      >
        <Row gutter={50}>
          <Col span={9}>
            <div className={commonStyles.section}>
              <div className={commonStyles.sectionTitle}>{getTranslations('COURSE_BASIC_INFO')}</div>
              <FirstStep
                categories={categories}
                categoriesIds={categoriesIds}
                setCourse={setCourse}
                course={course}
                validateForm={form.validateFields}
                form={form}
              />
            </div>
            <div className={commonStyles.section}>
              <ImageStep
                categories={categories}
                categoriesIds={categoriesIds}
                setCourse={setCourse}
                course={course}
                validateForm={form.validateFields}
                form={form}
              />
            </div>
          </Col>
          <Col span={15}>
            <ThirdStep
              categories={categories}
              categoriesIds={categoriesIds}
              setCourse={setCourse}
              course={course}
              validateForm={form.validateFields}
              form={form}
            />
            <div className={commonStyles.section}>
              <div className={commonStyles.sectionTitle}>{getTranslations('COURSE_ADV_CONFIG')}</div>
              <FourthStep
                categories={categories}
                categoriesIds={categoriesIds}
                setCourse={setCourse}
                course={course}
                validateForm={form.validateFields}
                form={form}
              />
            </div>
          </Col>
        </Row>
      </Form>
      <FormFooter
        editing={!!editing}
        objectName={getTranslations('COURSE_TITLE_SINGULAR').toLocaleLowerCase()}
        onCancel={() => {
          history.push(`${Prefixes.course}`);
        }}
        onSave={() => {
          onSubmit(AfterActions.BACK);
        }}
        onSaveAndCreateAnother={() => {
          onSubmit(AfterActions.STAY);
        }}
      />
    </>
  );
};
