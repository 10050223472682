import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../app/AllowedMicroservices";
import { RootState } from "../../app/store";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { FetchStatus } from "../common/enums";

interface SessionInfo {
  time: number;
  sessions: {
    last7Days: number;
    last30Days: number;
    last90Days: number;
  }
}
interface SessionInfoState {
  fetching: FetchStatus;
  data: SessionInfo;
}

const initialState: SessionInfoState = {
  fetching: FetchStatus.Idle,
  data: {
    time: 0,
    sessions: {
      last7Days: 0,
      last30Days: 0,
      last90Days: 0,
    }
  }
};

export const sessionInfoSlice = createSlice({
  name: "sessionInfo",
  initialState,
  reducers: {
    fetching: (state) => {
      state.fetching = FetchStatus.Fetching;
      state.data = {
        time: 0,
        sessions: {
          last7Days: 0,
          last30Days: 0,
          last90Days: 0,
        }
      };
    },
    fetched: (state, action: PayloadAction<SessionInfo>) => {
      state.fetching = FetchStatus.Fetched;
      state.data = action.payload;
    },
    error: (state) => {
      state.fetching = FetchStatus.Error;
      state.data = {
        time: 0,
        sessions: {
          last7Days: 0,
          last30Days: 0,
          last90Days: 0,
        }
      };
    },
  },
});

const { error, fetched, fetching } = sessionInfoSlice.actions;

export const fetchSessionInfo = (userId?: string) => (dispatch: Dispatch<object>) => {
  const endpoint = userId ? `/user/sessions/info/${userId}`:'/user/sessions/info';
  return dispatch(
    apiActionCreator({
      endpoint,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.ANALYTICS_SERVICE,
      authenticated: true,
    })
  );
};

export const selectUserSessionInfo = (state: RootState) => {
  return state.sessionInfo.data;
}

export const selectAreUserSessionFetching = (state: RootState) =>
  state.sessionInfo.fetching === FetchStatus.Fetching;

export default sessionInfoSlice.reducer;
