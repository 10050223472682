import React, { FunctionComponent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCognitoUserData } from "../../features/auth/authSlice";
import { ICompanyCustomize, selectCompanyCustomize } from "../../features/company/companySlice";
import { getCustomizeDefaultValues } from "../Customize/Settings";

export const CustomizeApplier: FunctionComponent = () => {
  const [customize, setCustomize] = useState<ICompanyCustomize>();
  const cognitoData = useSelector(getCognitoUserData);
  const companyCustomize = useSelector(selectCompanyCustomize(cognitoData?.['custom:company']));

  useEffect(() => {
    if (companyCustomize) {
      setCustomize(getCustomizeDefaultValues(companyCustomize));
    } else {
      setCustomize(getCustomizeDefaultValues());
    }
  }, [companyCustomize]);

  useEffect(() => {
    if (customize) {
      const { colors, favicon } = customize;
      document.documentElement.style.setProperty('--main-color', colors?.main ?? '');
      document.documentElement.style.setProperty('--main-bg-button-color', colors?.mainButton?.background ?? '');
      document.documentElement.style.setProperty('--main-text-button-color', colors?.mainButton?.text ?? '');
      document.documentElement.style.setProperty('--secondary-background-button-color', colors?.secondaryButton?.background ?? '');
      document.documentElement.style.setProperty('--secondary-text-button-color', colors?.secondaryButton?.text ?? '');
      document.documentElement.style.setProperty('--tertiary-text-button-color', colors?.tertiaryButton?.text ?? '');
      if (favicon) {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          // link = document.createElement('link');
          // // @ts-ignore
          // link.rel = 'icon';
          // document.getElementsByTagName('head')[0].appendChild(link);
        }
        // @ts-ignore
        link.href = favicon;
      }
    }
  }, [customize])

  return <></>;
}