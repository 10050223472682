import React, { FunctionComponent } from 'react';

import moment from 'moment';
import cls from 'classnames';
import { Output } from '../common/Output';
import { IUser, GenderDict } from '../../features/users/usersSlice';
import { ICompany } from '../../features/company/companySlice';
import { idLabelsByType, IdTypes } from '../Users/common/identification';
import {
  Row,
  Col,
  Divider,
} from "antd";
import { getCountryName } from '../Users/utils';

import styles from './shared.module.scss';
import formStyles from '../common/form.module.scss';
import { getTranslations } from '../../features/translations/translationsUtils';

export enum Headlines {
  Admin = 'ADMIN',
  Student = 'STUDENT',
  Self = 'SELF',
}

interface ProfileDetails {
  user: IUser
  company: ICompany
  hideCreationDate?: boolean,
  headlinesType: Headlines,
}

export const ProfileDetailsColumn: FunctionComponent<ProfileDetails> = ({ user, company, hideCreationDate, headlinesType }) => {
  let firstBoxTitle = getTranslations('USER_PROFILE');
  let secondBoxTitle = getTranslations('USER_COMPANY_INFO');
  if (headlinesType === Headlines.Self) {
    firstBoxTitle = getTranslations('USER_MY_PROFILE');
    secondBoxTitle = getTranslations('USER_MY_COMPANY_INFO');
  }

  return (
    <React.Fragment>
      <Row gutter={[16, 0]}>
        <Col span={24}>
          <div className={cls(formStyles.section, styles.card, styles.overload)}>
            <div className={cls(formStyles.sectionTitle, styles.sectionTitle)}>{firstBoxTitle}</div>
            <Output label={getTranslations('COMPANY_CONTACT_NAMES')} text={(user.given_name ? user.given_name : '') + ' ' + (user.middle_name ? user.middle_name : '')} />
            <Output label={getTranslations('COMPANY_CONTACT_LAST_NAME')} text={(user.family_name ? user.family_name : '') + ' ' + (user.secondLastname ? user.secondLastname : '')} />
            <Output label={getTranslations('USERS_EMAIL')} text={user.email} />
            <Divider />
            <Output label={getTranslations('USERS_NATIONAL_ID_TYPE')} text={idLabelsByType[user.nationalIdType as IdTypes]} />
            <Output label={getTranslations('USERS_NATIONAL_ID')} text={user.nationalId} />
            <Output label={getTranslations('USERS_CELLPHONE')} text={user.phone_number} />
            <Output label={getTranslations('USERS_GENDER')} text={GenderDict()[user.gender]} ></Output>
            <Output label={getTranslations('USERS_BIRTH_DATE')} text={moment.utc(user.birthdate).format("DD-MM-YYYY")} />
            <Divider />
            <Output label={getTranslations('USERS_COUNTRY')} text={getCountryName(user.country) || '---'} />
            <Output label={getTranslations('USERS_CITY')} text={user.city} />
            <Output label={getTranslations('USERS_ADDRESS')} text={user.address} />
            {!hideCreationDate &&
              <Output label={getTranslations('USER_CREATION_DATE')} text={moment.utc(user.createdAt).format("DD-MM-YYYY")} />
            }
          </div>
        </Col>
        <Col span={24}>
          <div className={cls(formStyles.section, styles.card, styles.overload)}>
            <div className={cls(formStyles.sectionTitle, styles.sectionTitle)}>{secondBoxTitle}</div>
            <Output label={getTranslations('USERS_JOB')} text={user.position || ' '} />
            <Output label={getTranslations('USER_COMPANY')} text={company.name} />
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};