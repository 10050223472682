import React, { useEffect, useState } from "react";

import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { selectCompanyId, selectUserRole } from "../../features/auth/authSlice";
import {
  fetchCategories,
  ICategory,
  selectCategories,
  selectCategoriesDict,
} from "../../features/category/categorySlice";
import { fetchLessons } from "../../features/lesson/lessonSlice";
import {
  fetchTopics,
  hideModal,
  ITopic,
  selectDisplayModal,
  selectTopics,
  updateTopic,
} from "../../features/topic/topicSlice";
import { UserRole } from "../../features/users/usersSlice";
import { OTHER_CATEGORY_OPTION } from "../common/CategoryField";
import { AfterActions, DialogPopUp } from "../common/DialogPopUp";
import { MainLayout } from "../common/Layouts/Main";
import { TopicForm } from "./Form";
import { ActionSuffixes, Prefixes } from "../prefixes";
import { useHistory, useRouteMatch } from "react-router-dom";
import LoadingOverlay from "../common/LoadingOverlay";
import { FormFooter } from "../common/FormFooter";
import { cloneDeep } from "lodash";
import { getTranslations } from "../../features/translations/translationsUtils";


export const EditTopic: React.FunctionComponent = () => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const match = useRouteMatch();
  const history = useHistory();
  const categoriesIds = useSelector(selectCategories);
  const categoriesDict = useSelector(selectCategoriesDict);
  const userRole = useSelector(selectUserRole);
  const companyId = useSelector(selectCompanyId);
  const [newTopic, setNewTopic] = useState<ITopic>();
  const dispatch = useDispatch();
  const [currentAction, setCurrentAction] = useState<string>("");
  const isModalVisible = useSelector(selectDisplayModal);
  const selectedTopic = useSelector(selectTopics)[(match.params as any).id!];

  useEffect(() => {
    if ((!newTopic && selectedTopic) || (newTopic && selectedTopic && newTopic._id !== selectedTopic._id)) {
      setNewTopic(selectedTopic);
      setLoaded(true);
    }
  }, [selectedTopic, newTopic]);

  useEffect(() => {
    dispatch(fetchTopics());
    dispatch(fetchCategories());
    dispatch(fetchLessons()); // make form ready to work
  }, [dispatch]);

  const disabledSaveButton = false;

  const [form] = Form.useForm();

  const handleCreateOrUpdate = (afterAction: AfterActions) => {
    form
      .validateFields()
      .then((value) => {
        if (!newTopic) {
          return;
        }
        const topicCopy = cloneDeep(newTopic);
        if (topicCopy?.category === OTHER_CATEGORY_OPTION) {
          topicCopy.category = {
            name: topicCopy.categoryName ?? "",
          } as ICategory;
        }
        if (userRole !== UserRole.SuperAdmin) {
          if (topicCopy._id && topicCopy.companyId !== companyId) { // don't allow editing topic that don't belong to the company
            return;
          }
          topicCopy.companyId = companyId; // force to company in case of creating
        }
        if (!topicCopy.companyId) {
          topicCopy.companyId = undefined;
        }
        setCurrentAction(afterAction);
        dispatch(updateTopic(topicCopy));
      })
      .catch((error) => {
        console.error("Company:React.FunctionComponent -> error", error);
      });
  };
  if (!loaded) {
    return <LoadingOverlay spinning={true} />;
  }

  return (
    <MainLayout
      title={getTranslations('TOPIC_EDIT')}
      onGoBack={() => {
        history.push(`${Prefixes.topic}`);
      }}
      breadcrumbs={[getTranslations('TOPIC_TITLE'), getTranslations('TOPIC_EDIT')]}
    >
      <DialogPopUp
        name={getTranslations('TOPIC_TITLE_SINGULAR')}
        action={getTranslations('TOPIC_UPDATED')}
        visible={isModalVisible}
        onCancel={() => {
          dispatch(hideModal());
          if (currentAction === AfterActions.BACK) {
            history.push(`${Prefixes.topic}`);
            return;
          } 
          history.push(`${Prefixes.topic}${ActionSuffixes.new}`);
        }}
      />
      <TopicForm
        form={form}
        newTopic={newTopic}
        categories={categoriesDict}
        categoriesIds={categoriesIds}
        setNewTopic={setNewTopic}
      />
      <FormFooter
        editing={true}
        objectName={getTranslations('TOPIC_TITLE_SINGULAR')}
        onCancel={() => {
          history.push(`${Prefixes.topic}`);
        }}
        onSave={() => {
          handleCreateOrUpdate(AfterActions.BACK)
        }}
        onSaveAndCreateAnother={() => {
          handleCreateOrUpdate(AfterActions.STAY)
        }}
        disabledSave={disabledSaveButton}
      />
    </MainLayout>
  );
};
