import React, { useEffect, useState } from "react";

import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { getCognitoUserData } from "../../features/auth/authSlice";
import {
  fetchCompanies,
  selectCompanies,
} from "../../features/company/companySlice";
import { fetchCourses } from "../../features/courses/coursesSlice";
import {
  fetchGroups,
  IGroup,
  selectDisplayModal,
  updateGroup,
  selectCreatedGroup,
  selectGroup,
  hideModal
} from "../../features/groups/groupSlice";
import { fetchPrograms } from "../../features/program/programSlice";
import { AfterActions, DialogPopUp } from "../common/DialogPopUp";
import { MainLayout } from "../common/Layouts/Main";
import { GroupForm } from "./Form";
import { analytics } from '../../services/analytics';
import { useHistory, useRouteMatch } from "react-router-dom";
import { ActionSuffixes, Prefixes } from "../prefixes";
import { FormFooter } from "../common/FormFooter";
import { getTranslations } from "../../features/translations/translationsUtils";

function isValidate(group?: IGroup) {
  if (!group) {
    return false;
  }
  if (!group.company) {
    return false;
  }
  return (group.name || "").trim() === "";
}

export const EditGroup: React.FunctionComponent = () => {
  const history = useHistory();
  const [loaded, setLoaded] = useState<boolean>(false);
  const match = useRouteMatch();
  const companiesList = useSelector(selectCompanies);
  const [newGroup, setNewGroup] = useState<IGroup>();
  const dispatch = useDispatch();
  const [currentAction, setCurrentAction] = useState<string>("");
  const cognitoData = useSelector(getCognitoUserData);
  const isModalVisible = useSelector(selectDisplayModal);
  const cognitoUsername = cognitoData ? cognitoData['cognito:username'] : null;
  const createdGroup = useSelector(selectCreatedGroup);
  const selectedGroup = useSelector(selectGroup((match.params as any).id!))

  useEffect(() => {
    if ((!newGroup && selectedGroup) || (newGroup && selectedGroup && newGroup._id !== selectedGroup._id)) {
      setNewGroup(selectedGroup);
      setLoaded(true);
    }
  }, [selectedGroup, newGroup]);

  useEffect(() => {
    if (createdGroup) {
      analytics.createGroup({
        userName: cognitoUsername,
        companyName: companiesList[createdGroup.company]?.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdGroup]);

  useEffect(() => {
    dispatch(fetchGroups());
    dispatch(fetchCompanies());
    dispatch(fetchPrograms());
    dispatch(fetchCourses());
  }, [dispatch]);
  const disabledSaveButton = isValidate(newGroup);
  const [form] = Form.useForm();

  if (!loaded) {
    return null;
  }

  return (
    <MainLayout
      title={getTranslations('GROUP_EDIT')}
      onGoBack={() => {
        history.push(`${Prefixes.group}`);
      }}
      breadcrumbs={[getTranslations('GROUP_TITLE'), getTranslations('GROUP_EDIT')]}
    >
      <DialogPopUp
        name={getTranslations('GROUP_TITLE_SINGULAR')}
        action={getTranslations('GROUP_UPDATED')}
        visible={isModalVisible}
        onCancel={() => {
          if (currentAction === AfterActions.BACK) {
            history.push(`${Prefixes.group}`);
          } else {
            history.push(`${Prefixes.group}${ActionSuffixes.new}`);
          }
          dispatch(hideModal());
        }}
      />
      <GroupForm
        form={form}
        newGroup={newGroup}
        setNewGroup={setNewGroup}
      />
      <FormFooter
        editing
        disabledSave={disabledSaveButton}
        objectName={getTranslations('GROUP_TITLE_SINGULAR').toLocaleLowerCase()}
        onCancel={() => {
          history.push(`${Prefixes.group}`);
        }}
        onSave={() => {
          form
            .validateFields()
            .then((value) => {
              if (!newGroup) {
                return;
              }
              setCurrentAction(AfterActions.BACK);
              dispatch(updateGroup(newGroup));
            })
            .catch((error) => {
              console.error(
                "Company:React.FunctionComponent -> error",
                error
              );
            });
        }}
        onSaveAndCreateAnother={() => {
          form
            .validateFields()
            .then((value) => {
              if (!newGroup) {
                return;
              }
              setCurrentAction(AfterActions.STAY);
              dispatch(updateGroup(newGroup));
            })
            .catch((error) => {
              console.error(
                "Company:React.FunctionComponent -> error",
                error
              );
            });
        }}
      />
    </MainLayout>
  );
};
