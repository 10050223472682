import React, { useEffect, useState } from "react";

import { Form } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import { getCognitoUserData } from "../../features/auth/authSlice";
import {
  fetchCampaigns,
  hideModal,
  ICampaign,
  selectCampaigns,
  selectCampaignStatus,
  selectDisplayModal,
  updateCampaign,
  VisibilityRole,
  selectCreatedCampaign,
} from "../../features/campaign/campaignSlice";
import { ConditionEnum } from "../../features/common/enums";
import {
  fetchCompanies,
  selectCompanies,
} from "../../features/company/companySlice";
import { AfterActions, DialogPopUp } from "../common/DialogPopUp";
import { MainLayout } from "../common/Layouts/Main";
import { CampaignForm, InitialValues } from "./Form";
import { analytics } from "../../services/analytics";
import { ActionSuffixes, Prefixes } from "../prefixes";
import { useHistory, useRouteMatch } from "react-router-dom";

import LoadingOverlay from "../common/LoadingOverlay";
import { FormFooter } from "../common/FormFooter";
import { getTranslations } from "../../features/translations/translationsUtils";

const EMPTY_VALUE = {
  title: "",
  description: "",
  fromDate: "",
  toDate: "",
  companyId: undefined,
  rangeDate: undefined,
};

export const EditCampaign: React.FunctionComponent = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const [initialValues, setInitialValues] = useState<InitialValues>(EMPTY_VALUE);
  const [currentCampaign, setCurrentCampaign] = useState<ICampaign | undefined>(
    undefined
  );
  const [currentAction, setCurrentAction] = useState("");
  const [form] = Form.useForm();
  const companiesDict = useSelector(selectCompanies);
  const isModalVisible = useSelector(selectDisplayModal);
  const isLoading = useSelector(selectCampaignStatus);
  const cognitoData = useSelector(getCognitoUserData);
  const cognitoUsername = cognitoData ? cognitoData['cognito:username'] : null;
  const createdCampaign = useSelector(selectCreatedCampaign);
  const dispatch = useDispatch();
  const campaigns = useSelector(selectCampaigns);
  const id = (match.params as any).id!;

  useEffect(() => {
    const selectedCampaign = campaigns.find(campaign => campaign._id === id);
    if (selectedCampaign) {
      setCurrentCampaign(selectedCampaign);
      const valueToSet = {
        companyId: selectedCampaign.companyId,
        description: selectedCampaign.description,
        title: selectedCampaign.title,
        survey: selectedCampaign.survey,
        rangeDate: [moment(selectedCampaign.fromDate), moment(selectedCampaign.toDate)],
        image: selectedCampaign.image,
        resultsVisibilityRole: selectedCampaign.resultsVisibilityRole ?? VisibilityRole.AllRoles,
      };
      form.setFieldsValue({...valueToSet});
      setInitialValues({...valueToSet, rangeDate: [moment(selectedCampaign.fromDate), moment(selectedCampaign.toDate)]});
    }
  }, [campaigns, form, id]);

  useEffect(() => {
    if (createdCampaign) {
      analytics.createPromoBanner({
        userName: cognitoUsername,
        companyName: companiesDict[createdCampaign.companyId]?.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdCampaign]);

  useEffect(() => {
    if (currentCampaign) {
      setInitialValues({
        companyId: currentCampaign.companyId,
        description: currentCampaign.description,
        title: currentCampaign.title,
        survey: currentCampaign.survey,
        rangeDate: [
          moment(currentCampaign.fromDate),
          moment(currentCampaign.toDate),
        ],
        image: currentCampaign.image,
        resultsVisibilityRole:
          currentCampaign.resultsVisibilityRole ?? VisibilityRole.AllRoles,
      });
    }
  }, [currentCampaign]);

  useEffect(() => {
    dispatch(fetchCampaigns());
    dispatch(fetchCompanies());
  }, [dispatch]);

  const handleClose = () => {
    history.push(`${Prefixes.campaign}`);
  };

  const handleFinish = (campaign: ICampaign) => {
    if (currentCampaign?._id) {
      dispatch(
        updateCampaign({
          ...campaign,
          _id: currentCampaign?._id,
        })
      );
      form.setFieldsValue(EMPTY_VALUE);
      return;
    }
  };

  const handleSave = (afterAction: AfterActions, publish?: ConditionEnum) => {
    if (initialValues) {
      form
        .validateFields()
        .then((values) => {
          const fromDate = values.rangeDate
            ? values.rangeDate[0].format('YYYY-MM-DD')
            : "";
          const toDate = values.rangeDate ? values.rangeDate[1].format('YYYY-MM-DD') : "";
          const newCampaign: ICampaign = {
            _id: "",
            image: initialValues.image,
            companyId: values.companyId,
            survey: initialValues.survey,
            title: values.title,
            description: values.description,
            fromDate,
            toDate,
            published: publish ? publish : undefined,
          };
          if (initialValues.survey && initialValues.resultsVisibilityRole) {
            newCampaign.resultsVisibilityRole =
              initialValues.resultsVisibilityRole;
          }
          setCurrentAction(afterAction);
          handleFinish(newCampaign);
        })
        .catch((_error) => { });
    }
  };

  if (!currentCampaign) {
    return <LoadingOverlay spinning={true} />;
  }

  return (
    <MainLayout
      title={getTranslations('CAMPAIGN_EDIT')}
      onGoBack={() => {
        history.push(`${Prefixes.campaign}`);
      }}
      breadcrumbs={[getTranslations('CAMPAIGN_TITLE'), getTranslations('CAMPAIGN_EDIT')]}
    >
      <DialogPopUp
        name={getTranslations('CAMPAIGN_TITLE')}
        action={getTranslations('CAMPAIGN_UPDATED')}
        visible={isModalVisible}
        onCancel={() => {
          dispatch(hideModal());
          if (currentAction === AfterActions.BACK) {
            history.push(`${Prefixes.campaign}`);
            return;
          }
          history.push(`${Prefixes.campaign}${ActionSuffixes.new}`);
        }}
      />
      <CampaignForm
        form={form}
        campaignId={currentCampaign?._id}
        initialValues={initialValues}
        onUpdateValues={setInitialValues}
      />
      <FormFooter
        editing
        objectName={getTranslations('CAMPAIGN_TITLE_SINGULAR').toLowerCase()}
        disabledSave={isLoading || !initialValues?.survey}
        onCancel={handleClose}
        onSave={() => {
          handleSave(AfterActions.BACK)
        }}
        onSaveAndCreateAnother={() => {
          handleSave(AfterActions.STAY)
        }}
      />
    </MainLayout>
  );
};
