import React, { FunctionComponent } from "react";

import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import cls from 'classnames';

import { Courses } from "./Courses";
import {
  selectStudentMyCompletedCourse,
  selectStudentMyCompletedCoursesKeys,
  selectStudentMyCourse,
  selectStudentMyCoursesFilteredKeys,
  selectStudentMyCoursesFilteredKeysCount,
  selectStudentMyPendingCourse,
  selectStudentMyPendingCoursesKeys,
  selectStudentMyStartedCourse,
  selectStudentMyStartedCoursesKeys
} from "../../../../features/students/myCoursesSlice";
import { Header } from "./Header";
import { useDeviceSize } from "../../../utils/useDeviceSize";
import { SIZES } from "../../../common/screen";

import styles from './myCourses.module.scss';
import { getTranslations } from "../../../../features/translations/translationsUtils";

const TAG_PARAM_NAME = "tag";
const CATEGORY_ID_PARAM_NAME = "category";
const TEXT_TO_SEARCH_PARAM_NAME = "textToSearch";

interface IMyCoursesProps { }

interface IFilteredCourses {
  tags: string[];
  categoryId: string;
  textToSearch: string;
}


const Divider: FunctionComponent<{ title: string }> = ({ title }) => {
  const size = useDeviceSize();
  const isMobile = size === SIZES.PHONE;
  return <div className={cls(styles.divider, {
    [styles.mobile]: isMobile,
  })}>
    <div className={styles.title}>{title}</div>
    <div className={styles.line} />
  </div>;
}

const FilteredCourses: FunctionComponent<IFilteredCourses> = ({
  categoryId,
  tags,
  textToSearch
}) => {

  const coursesCount = useSelector(
    selectStudentMyCoursesFilteredKeysCount(
      { tags: [...tags], categoryId: categoryId },
      { text: textToSearch }
    )
  );
  if (coursesCount === 0) {
    return <div className={styles.notResultContainer}>
      <p className={styles.notFoundText}>
        {getTranslations('MYCOURSES_NO_COURSES_FILTERED')}
      </p>
    </div>;
  }
  return <>
    <Divider title={getTranslations('HOME_COURSES_FOUND')} />
    <Courses
      individualSelector={selectStudentMyCourse}
      selector={selectStudentMyCoursesFilteredKeys(
        { tags: [...tags], categoryId: categoryId },
        { text: textToSearch }
      )}
      emptyMessage={getTranslations('MYCOURSES_NO_IN_PROGRESS')}
    />
  </>
}

export const MyCourses: FunctionComponent<IMyCoursesProps> = () => {
  const location = useLocation();
  const queryParamsObj: any = queryString.parse(location.search);
  const tagsFromUrl = queryParamsObj[TAG_PARAM_NAME] || [];
  const tags = Array.isArray(tagsFromUrl) ? tagsFromUrl : [tagsFromUrl];
  const categoryId = queryParamsObj[CATEGORY_ID_PARAM_NAME] || "";
  const textToSearch = queryParamsObj[TEXT_TO_SEARCH_PARAM_NAME] || "";

  const hasFilter = tags.length > 0 || categoryId || textToSearch;

  const renderAllCoursesStatus = () => {
    return <>
      <Divider title={getTranslations('MYCOURSES_IN_PROGRESS')} />
      <Courses
        individualSelector={selectStudentMyStartedCourse}
        selector={selectStudentMyStartedCoursesKeys}
        emptyMessage={getTranslations('MYCOURSES_NO_IN_PROGRESS')}
      />
      <Divider title={getTranslations('MYCOURSES_PENDING')} />
      <Courses
        individualSelector={selectStudentMyPendingCourse}
        selector={selectStudentMyPendingCoursesKeys}
        emptyMessage={getTranslations('MYCOURSES_NO_PENDING')}
      />
      <Divider title={getTranslations('MYCOURSES_COMPLETED')} />
      <Courses
        individualSelector={selectStudentMyCompletedCourse}
        selector={selectStudentMyCompletedCoursesKeys}
        emptyMessage={getTranslations('MYCOURSES_NO_COMPLETED')}
      />
    </>;
  }
  const renderFilteredCourses = () => {
    return <FilteredCourses
      categoryId={categoryId}
      tags={tags}
      textToSearch={textToSearch}
    />
  }
  return <div className={styles.container}>
    <Header />
    {hasFilter ? renderFilteredCourses() : renderAllCoursesStatus()}
  </div>
}