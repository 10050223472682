import React, { FunctionComponent, useEffect, useState } from "react";

import { Tabs } from "antd";
import { useHistory, useRouteMatch } from "react-router-dom";
import cls from 'classnames';

import { CogIcon } from "../../../common/CogIcon";

import styles from './options.module.scss'
import { useDeviceSize } from "../../../utils/useDeviceSize";
import { SIZES } from "../../../common/screen";
import { getTranslations } from "../../../../features/translations/translationsUtils";

interface IOptionsProps { }

const selectedColor = 'var(--main-color)';
const noSelectedColor = '#333';

export const Options: FunctionComponent<IOptionsProps> = () => {
  const { path } = useRouteMatch<{ tab: string }>();
  const isHome = path === '/home';
  const [selectedTab, setSelectedTab] = useState<string>('home');
  const history = useHistory();
  const size = useDeviceSize();

  const isMobile = size === SIZES.PHONE;
  useEffect(() => {
    setSelectedTab(isHome ? 'home' : 'myCourses');
  }, [isHome]);
  const color1 = isHome ? selectedColor : noSelectedColor;
  const color2 = !isHome ? selectedColor : noSelectedColor;
  const items = [
    {
      label: <span className={styles.tab}>
        <CogIcon size={18} color={color1} icon="home2" />
        {getTranslations('HOME_TAB')}
      </span>,
      key: 'home',
      children: "",
    },
    {
      label: <span className={styles.tab}>
        <CogIcon size={18} color={color2} icon="courses" />
        {getTranslations('HOME_MY_COURSES_TAB')}
      </span>,
      key: 'myCourses',
      children: "",
    },
  ];
  return <Tabs
    className={cls(styles.tabs, {
      [styles.mobile]: isMobile
    })}
    items={items}
    defaultActiveKey={selectedTab}
    activeKey={selectedTab}
    onChange={(tab) => {
      setSelectedTab(tab);
      history.push(`/${tab}`);
    }}
  />;
}
