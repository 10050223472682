import React, { useRef } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Upload, message, Button } from 'antd';
import { RcFile } from 'antd/es/upload/interface';
import cls from 'classnames';

import { CogIcon } from '../../common/CogIcon';
import { cognitoUser } from '../../../features/auth/authSlice';
import { Microservices } from '../../../app/AllowedMicroservices';
import { BASE_URL } from '../../../app/apiMiddleware';

import styles from './index.module.scss';

import folderIcon from '../../../assets/folder.svg';
import { getTranslations } from '../../../features/translations/translationsUtils';


interface UploadImageProps {
    clearFile?: () => void,
    onHandleChange: any,
    loading?: boolean,
    uploadUrl?: string,
    fileTypesAllowed?: string[],
    fileNotAllowedMessage?: string,
    maxMB?: number,
    imageUrlToShow?: string,
    short?: boolean,
    prefixedImages?: string[],
    allowedFileInfo?: string,
    addButton?: boolean,
    setPrefixedImage?: (url: string) => void,
}

const { Dragger } = Upload;



export const UploadFile: React.FunctionComponent<UploadImageProps> = ({
    clearFile,
    onHandleChange,
    loading = false,
    uploadUrl = `${BASE_URL[Microservices.COMPANY_SERVICE]}/user/import`,
    fileTypesAllowed = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', '.xlsx'],
    fileNotAllowedMessage = getTranslations('USERS_ONLY_XLS_ALLOWED'),
    maxMB = 10,
    imageUrlToShow,
    short,
    prefixedImages,
    allowedFileInfo,
    addButton = false,
    setPrefixedImage = (_: string) => { },
}) => {
    const ref: any = useRef();
    const carouselRef = useRef<HTMLInputElement>(null);
    const firstRef = useRef<HTMLInputElement>(null);
    function beforeUpload(file: RcFile) {
        const isFileAllowed = fileTypesAllowed.includes(file.type);
        if (!isFileAllowed) {
            message.error(fileNotAllowedMessage);
        }
        const isLt10M = file.size / 1024 / 1024 < maxMB;
        if (!isLt10M) {
            message.error(getTranslations('UPLOAD_FILE_ERROR', [maxMB]));
        }
        return isFileAllowed && isLt10M;
    }
    const onRemoveImage = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        if (clearFile) {
            clearFile();
        }
    };

    const renderContent = (loading: boolean) => {
        if (loading) {
            return <LoadingOutlined spin
                style={{ fontSize: 44, color: "#4376F9" }}
            />;
        }
        const clearButton = clearFile ? <div onClick={onRemoveImage} className={styles.remove}><CogIcon color='#686F84' size={18} icon="Cross" /></div> : null;
        return <React.Fragment>
            {imageUrlToShow ?
                <div className={styles.imageUploaded}><img src={imageUrlToShow} alt="uploaded file" /> </div> :
                <div className={styles.uploadIcon}>
                    <div className={styles.background} />
                    <div className={styles.bluePart}>
                        <CogIcon className={styles.icon} size="10px" color="white" icon="arrow-up-bold" />
                    </div>
                    <img className={styles.folder} src={folderIcon} alt="foder icon" />
                </div>
            }
            <div className={styles.text}>
                {imageUrlToShow ? clearButton : <><p className={styles.description}>
                    {getTranslations('UPLOAD_FILE_MESSAGE_1')}<br />
                    <span className={styles.fakeLink}>{getTranslations('UPLOAD_FILE_MESSAGE_2')}</span>
                </p>
                    <p className={styles.specifications}>{allowedFileInfo ? allowedFileInfo : getTranslations('UPLOAD_FILE_DEFAULT_ALLOWED_SIZE')}</p>
                </>}
            </div>
        </React.Fragment>;
    }

    const renderFixedImages = !prefixedImages || prefixedImages.length === 0 ? () => null :
        () => {
            const itemWidth = (firstRef.current?.getBoundingClientRect().width ?? 0);
            let step = Math.round((itemWidth + 8));
            let left = 0;
            const max = (itemWidth + 8) * (prefixedImages.length - 1) - 8;
            const onRight = () => {
                if (carouselRef.current) {
                    if (left < max) {
                        left += step;
                    }
                    left = Math.min(left, max);
                    carouselRef.current.scrollTo({
                        top: 0,
                        left,
                        behavior: 'smooth'
                    });
                }
            };

            const onLeft = () => {
                if (carouselRef.current) {
                    if (left > 0) {
                        left -= step;
                    }
                    left = Math.max(left, 0);
                    carouselRef.current.scrollTo({
                        top: 0,
                        left,
                        behavior: 'smooth'
                    });
                }
            };
            return <>
                <div className={styles.divider} />
                <div className={styles.info}>
                    <div>{getTranslations('UPLOAD_FILE_ZERO_TEMPLATES')}</div>
                    <div className={styles.navigator}>
                        <div className={styles.button} onClick={onLeft}>
                            <CogIcon size={16} color='#81858B' icon='Arrow-left-small' />
                        </div>
                        <div className={styles.button} onClick={onRight}>
                            <CogIcon size={16} color='#81858B' icon='Arrow-right-small' />
                        </div>
                    </div>
                </div>
                <div className={styles.carouselContainer}>
                    <div ref={carouselRef} className={styles.carousel}>
                        {prefixedImages.map((prefixedImage, index) => {
                            return <div onClick={() => setPrefixedImage(prefixedImage)} ref={index === 0 ? firstRef : undefined} className={styles.imageContainer} key={`prefixed_${index + 1}`}>
                                <img className={styles.image} src={prefixedImage} alt={`prefixed ${index + 1}`} />
                            </div>;
                        })}
                    </div>
                </div>
            </>
        }
    const addButtonLabel = !!imageUrlToShow ? getTranslations('UPLOAD_FILE_CHANGE') : getTranslations('UPLOAD_FILE_UPLOAD');
    return <>
        <Dragger
            ref={ref}
            headers={{
                Authorization: cognitoUser?.getSignInUserSession()?.getAccessToken().getJwtToken() || '',
            }}
            accept={fileTypesAllowed.join(',')}
            method="POST"
            onChange={onHandleChange}
            name='file'
            beforeUpload={beforeUpload}
            action={uploadUrl}
            className={cls(styles.uploadFile, { [styles.withAddButton]: addButton, [styles.short]: short, [styles.selectedFile]: !!imageUrlToShow })}
            showUploadList={false}
        >
            {renderContent(loading)}
            {addButton ? <div className={styles.addButtonContainer}>
                <Button type="primary" className={styles.changeButton}><CogIcon icon="Backup" color="white" />&nbsp;&nbsp;{addButtonLabel}</Button>
            </div> : null}
        </Dragger>

        {renderFixedImages()}
    </>;
};