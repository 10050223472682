import React from 'react';
import cls from 'classnames';
import { history } from '../../App';
import panelStyles from './Panel.module.scss';
import mainStyles from './PasswordChange.module.scss';

import {
  Col,
  Row,
  Card,
  Button,
} from 'antd';
import { CogIcon } from '../common/CogIcon';
import { getTranslations } from '../../features/translations/translationsUtils';

export function PasswordChange() {
  return (
    <React.Fragment>
      <div className={cls(panelStyles.Bkgnd)}></div>
      <div>
        <Row>
          <Col xs={{ span: 18, offset: 3 }} md={{ span: 8, offset: 8 }}>
            <Row>
              <Col span={22} offset={1}>
                <Card className={cls(mainStyles.card)}>
                  <div className={mainStyles.backButton}>
                    <Button className={cls(mainStyles.button)}
                      onClick={() => { history.replace('/login') }}
                      type="text">
                      <CogIcon size={16} icon="Arrow-left" />&nbsp;
                      {getTranslations('LOGIN_BACK')}
                    </Button>
                  </div>
                  <h2 className={cls(mainStyles.title)}>{getTranslations('LOGIN_RECOVERPASSWORD_LINE_1')}</h2>
                  <p className={cls(mainStyles.description)}>{getTranslations('LOGIN_RECOVERPASSWORD_LINE_2')}</p>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}
