import React, { FunctionComponent, forwardRef, useImperativeHandle, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ctx from "classnames";

import {
  selectStudentCourse,
  selectStudentCourseTopic,
  selectStudentCourseTopicProgress,
  selectStudentCourseLastViewdItemByTopicId,
  TOPIC_TEST,
  selectStudentCourseRules
} from "../../../features/students/courseSlice";

import styles from "./courseTopic.module.scss";
import { Progress } from "antd";
import { selectCategory } from "../../../features/category/categorySlice";
import { CategoryChip } from "../common/Chip";
import { SIZES } from "../../common/screen";
import { useDeviceSize } from "../../utils/useDeviceSize";

// analytics
import { selectPrograms, selectProgramsDict } from "../../../features/program/programSlice";
import { analytics } from "../../../services/analytics";
import {
  getCognitoUserData,
} from "../../../features/auth/authSlice";
import { selectUser } from '../../../features/users/usersSlice';
import { selectCompany } from '../../../features/company/companySlice';
import { selectGroup } from "../../../features/groups/groupSlice";
import { CogIcon } from "../../common/CogIcon";
import { COURSES_PRIORITY_COLORS, COURSES_PRIORITY_ICON, COURSES_PRIORITY_LABELS } from "../../../utils/constants";
import moment from "moment";
import { getTranslations } from "../../../features/translations/translationsUtils";
import { getTimeInHours } from "../common/timeUtils";

interface StudentCourseTopicsProps {
  id: string;
  lastTopicId?: string;
  isFirst: boolean;
  isLast: boolean;
}

export const StudentCourseTopic: FunctionComponent<StudentCourseTopicsProps> = ({
  id,
  isFirst,
  lastTopicId,
  isLast
}) => {
  const history = useHistory();
  const course = useSelector(selectStudentCourse);
  const topic = useSelector(selectStudentCourseTopic(id));
  const progress = useSelector(selectStudentCourseTopicProgress(id));
  const lastProgress = useSelector(
    selectStudentCourseTopicProgress(lastTopicId || "")
  );
  const category = useSelector(selectCategory(topic?.category as string));
  const toGo = useSelector(selectStudentCourseLastViewdItemByTopicId(id));
  const size = useDeviceSize();
  const analyticsRef = useRef<AnalyticsSelectTopicRef>(null);
  const courseRules = useSelector(selectStudentCourseRules);

  const { priority = 1, startDate, endDate } = courseRules ?? {};

  if (!topic) {
    return null;
  }
  const viewTopicHandler = () => {
    let redirectPath = `/course/${course?._id}/${toGo.id}`;
    if (toGo.where === TOPIC_TEST) {
      redirectPath = `/course/${course?._id}/test/${toGo.id}`;
    }
    if (progress === 1) {
      redirectPath = `/course/${course?._id}/${topic.lessons[0]}`;
    }
    // TODO: what happens if a topic will jump to a test
    analyticsRef?.current?.trigger();
    history.push(redirectPath);
  };

  const isViewTopicCTADisabled = () => {
    return !isFirst && (lastProgress ?? 0) < 1;
  }
  const viewTopicCTADisabled = isViewTopicCTADisabled();
  const buttonColor = viewTopicCTADisabled ? '#81858B' : "#2A2A2B";
  return (
    <div
      className={ctx(styles.container, {
        [styles.clickable]: size !== SIZES.DESKTOP
      })}
      onClick={() => {
        if (size !== SIZES.DESKTOP && !viewTopicCTADisabled) {
          viewTopicHandler();
        }
      }}
    >
      <div className={styles.categoryContainer}>
        <CategoryChip categoryName={category?.name} />
      </div>
      <div className={styles.titleButtonContainer}>
        <div className={styles.title}>{topic.title}</div>
        {size === SIZES.DESKTOP ? (
          <div className={styles.gotToTopicContainer}>
            <div
              onClick={() => {
                if (!viewTopicCTADisabled) {
                  viewTopicHandler();
                }
              }}
              className={ctx(
                "studentButton",
                styles.button,
              )}
            >
              <CogIcon size="24" color={buttonColor} icon="Chevron-right" />
            </div>
          </div>
        ) : null}
      </div>
      <div className={styles.progress}>
        <Progress className={styles.bar} percent={progress * 100} showInfo={false} />
      </div>
      {/* <div className={styles.description}>{topic.description}</div> */}
      {isLast ? <div className={styles.durationPriorityContainer}>
        <div className={styles.priority}>
          <CogIcon size={16} className={styles.icon} color={COURSES_PRIORITY_COLORS[priority]} icon={COURSES_PRIORITY_ICON[priority]} />
          {getTranslations('STUDENT_PRIORITY', [getTranslations(COURSES_PRIORITY_LABELS[priority])])}
        </div>
        <div className={styles.duration}>
          <CogIcon size={16} className={styles.icon} color="#81858B" icon="clock" />
          {getTimeInHours(course?.durationHour)}
        </div>
        <div className={styles.date}>
          {moment(startDate).format('DD-MM-YYYY')}
          {endDate ? <span>/</span> : null}
          {endDate ? `${moment(endDate).format('DD-MM-YYYY')}` : ''}

        </div>
      </div> : null}

      {!isLast ? <div className={styles.divider} /> : null}
      <AnalyticsSelectTopic ref={analyticsRef} topicId={topic._id} />
    </div>
  );
};

type AnalyticsSelectTopicRef = {
  trigger: () => void,
}

interface AnalyticsSelectTopicProps {
  topicId: string,
}

const AnalyticsSelectTopic =
  forwardRef<AnalyticsSelectTopicRef, AnalyticsSelectTopicProps>(({ topicId }, ref) => {
    useImperativeHandle(ref, () => ({
      trigger: () => {
        analytics.selectTopic({
          topic: topic,
          course: course,
          program: courseProgram,
          company: company,
          group: group,
        });
      }
    }));
    const course = useSelector(selectStudentCourse);
    const allProgramsIds = useSelector(selectPrograms);
    const programsDict = useSelector(selectProgramsDict);
    const programId = allProgramsIds.filter((programId) => {
      const program = programsDict[programId] || {};
      const { courses: programCourses = [] } = program;
      const foundCourse = programCourses.find(
        (courseId) => courseId === course?._id
      );
      if (foundCourse !== undefined) {
        return true;
      }
      return false;
    });
    const courseProgram = programsDict[programId[0]] || null;
    const topic = useSelector(selectStudentCourseTopic(topicId));
    const cognitoUserData = useSelector(getCognitoUserData);
    const cognitoUsername = cognitoUserData
      ? cognitoUserData["cognito:username"]
      : null;
    const userFromStore = useSelector(selectUser(cognitoUsername));
    const companyId = userFromStore?.company ?? "";
    const company = useSelector(selectCompany(companyId));
    const group = useSelector(selectGroup(userFromStore?.studentGroupId || '')) || null;

    return null;
  });
