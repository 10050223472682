import React from "react";
import { useSelector } from "react-redux";
import {
  selectCaseIsFetching,
  selectCasesByCategoryData,
} from "../../../../features/reports/case/ticketsByCategory";
import {
  CHART_COLORS,
  IReportDataset,
} from "../../../../features/reports/common";
import LoadingOverlay from "../../../common/LoadingOverlay";
import { BarChartGrouped } from "../../common/BarChartGrouped";
import styles from "./index.module.scss";
import { getTranslations } from "../../../../features/translations/translationsUtils";

export const CasesByUserType: React.FunctionComponent = () => {
  const isLoading = useSelector(selectCaseIsFetching);
  const data = useSelector(selectCasesByCategoryData)
    .slice()
    .sort((a, b) => b.name.length - a.name.length);
  const labels = data.map((item) => item.name);
  const personal = data.map((item) => item.personal as number);
  const anonimous = data.map((item) => item.anonimous as number);
  const maxPersonal = Math.max.apply(null, personal);
  const maxAnonimous = Math.max.apply(null, anonimous);

  const userTypeData: IReportDataset[] = [
    {
      label: getTranslations('REPORT_NO_ANONYMOUS_CASES'),
      data: personal,
      color: CHART_COLORS[1],
    },
    {
      label: getTranslations('REPORT_ANONYMOUS_CASES'),
      data: anonimous,
      color: CHART_COLORS[0],
    },
  ];

  const axisMaxValue = Math.max(maxAnonimous, maxPersonal);

  return (
    <>
      <div className={styles.title}>{getTranslations('REPORT_CASES_PER_USER_TYPE')}</div>
      {isLoading || labels.length === 0 ? (
        <LoadingOverlay inline height={400} spinning />
      ) : (
        <BarChartGrouped
          datasets={userTypeData}
          labels={labels}
          labelColumnTitle="my label"
          fetching={false}
          axisMaxValue={axisMaxValue === 0 ? 5 : axisMaxValue}
          displayHorizontal={true}
        />
      )}
    </>
  );
};
