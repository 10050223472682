import React from 'react';

import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { Menu as AntdMenu } from 'antd';
import cls from 'classnames';

import { getCognitoUserData, selectCompanyId, selectUserRole } from '../../../features/auth/authSlice';
import { selectCompany } from '../../../features/company/companySlice';
import { UserRole, UsersGroups } from '../../../features/users/usersSlice';
import { selectUserAdmin } from '../../../features/userMode/UserMode';

import { ReportRoles, ReportTypes, menuTitles } from '../../Reports/MenuAndRoutes';
import { CogIcon } from '../CogIcon';
import { Prefixes } from '../../prefixes';

import styles from './menu.module.scss'
import { getTranslations } from '../../../features/translations/translationsUtils';

const { SubMenu } = AntdMenu;

interface IMainMenus {
  key: string,
  title: string
}

const SupervisorMenu: () =>IMainMenus[] = () => [
  {
    key: 'notifications',
    title: getTranslations('ADMIN_MAIN_MENU_NOTIFICATIONS'),
  },
];

const CompanyAdminMenu: () =>IMainMenus[] = () =>[
  {
    key: 'groups',
    title: getTranslations('ADMIN_MAIN_MENU_GROUPS'),
  },
  {
    key: 'users',
    title: getTranslations('ADMIN_MAIN_MENU_USERS'),
  },
  {
    key: 'notifications',
    title: getTranslations('ADMIN_MAIN_MENU_NOTIFICATIONS'),
  },
];

const SuperAdminMenu: () =>IMainMenus[] = () =>[
  {
    key: 'companies',
    title: getTranslations('ADMIN_MAIN_MENU_COMPANIES'),
  },
  {
    key: 'groups',
    title: getTranslations('ADMIN_MAIN_MENU_GROUPS'),
  },
  {
    key: 'users',
    title: getTranslations('ADMIN_MAIN_MENU_USERS'),
  },
  {
    key: 'notifications',
    title: getTranslations('ADMIN_MAIN_MENU_NOTIFICATIONS'),
  },
];

const CompanyAdminTrainingMenu: () =>IMainMenus[] = () =>[
  {
    key: 'programs',
    title: getTranslations('ADMIN_MAIN_MENU_PROGRAMS'),
  },
  {
    key: 'courses',
    title: getTranslations('ADMIN_MAIN_MENU_COURSES'),
  },
  {
    key: 'topics',
    title: getTranslations('ADMIN_MAIN_MENU_TOPICS'),
  },
  {
    key: 'lessons',
    title: getTranslations('ADMIN_MAIN_MENU_LESSONS'),
  },
  {
    key: 'evaluations',
    title: getTranslations('ADMIN_MAIN_MENU_EVALUATIONS'),
  },
  {
    key: 'campaigns',
    title: getTranslations('ADMIN_MAIN_MENU_CAMPAIGNS'),
  },
  {
    key: 'knowledge-bases',
    title: getTranslations('ADMIN_MAIN_MENU_KNOWLEDGE_BASE'),
  },
];

const SuperAdminSettingsMenu: () =>IMainMenus[] = () =>[
  {
    key: 'company-customize',
    title: getTranslations('ADMIN_MAIN_MENU_STYLES_CONFIG'),
  },
  {
    key: 'gamification',
    title: getTranslations('ADMIN_MAIN_MENU_GAMIFICATION'),
  },
  {
    key: 'translations',
    title: getTranslations('ADMIN_MAIN_MENU_TRANSLATIONS'),
  },
];

const CompanyAdminSettingsMenu: () =>IMainMenus[] = () =>[
  {
    key: 'company-customize',
    title: getTranslations('ADMIN_MAIN_MENU_STYLES_CONFIG'),
  },
  {
    key: 'gamification',
    title: getTranslations('ADMIN_MAIN_MENU_GAMIFICATION'),
  },
];

const SuperAdminTrainingMenu: () =>IMainMenus[] = () =>[
  {
    key: 'programs',
    title: getTranslations('ADMIN_MAIN_MENU_PROGRAMS'),
  },
  {
    key: 'courses',
    title: getTranslations('ADMIN_MAIN_MENU_COURSES'),
  },
  {
    key: 'topics',
    title: getTranslations('ADMIN_MAIN_MENU_TOPICS'),
  },
  {
    key: 'lessons',
    title: getTranslations('ADMIN_MAIN_MENU_LESSONS'),
  },
  {
    key: 'evaluations',
    title: getTranslations('ADMIN_MAIN_MENU_EVALUATIONS'),
  },
  {
    key: 'campaigns',
    title: getTranslations('ADMIN_MAIN_MENU_CAMPAIGNS'),
  },
  {
    key: 'knowledge-bases',
    title: getTranslations('ADMIN_MAIN_MENU_KNOWLEDGE_BASE'),
  },
];

const AdministrationKeys: string[] = SuperAdminMenu().map(menu => menu.key);
const TrainingKeys: string[] = SuperAdminTrainingMenu().map(menu => menu.key);

const getMenuSelected = () => {
  const path = window.location.pathname;
  const isReport = path.startsWith('/reports/');
  if (isReport) {
    const secondPart = path.substring(9);
    return {
      openedKeys: 'reports',
      selectedKeys: `reports/${secondPart}`,
    }
  }
  const adminRoute = AdministrationKeys.find(key => path.startsWith(`/${key}`));
  if (adminRoute) {
    return {
      openedKeys: 'admin',
      selectedKeys: adminRoute,
    }
  }

  const trainingRoute = TrainingKeys.find(key => path.startsWith(`/${key}`));
  if (trainingRoute) {
    return {
      openedKeys: 'training',
      selectedKeys: trainingRoute,
    }
  }

  return {
    openedKeys: '',
    selectedKeys: '',
  };
}

const TRAINING_MENU: () => Record<UserRole, IMainMenus[]> = () => ({
  "SuperAdmin": SuperAdminTrainingMenu(),
  "CompanyAdmin": CompanyAdminTrainingMenu(),
  "Supervisor": [],
});

const ADMIN_MENU: () => Record<UserRole, IMainMenus[]> = () => ({
  "SuperAdmin": SuperAdminMenu(),
  "CompanyAdmin": CompanyAdminMenu(),
  "Supervisor": SupervisorMenu(),
});

const SETTINGS_MENU: () => Record<UserRole, IMainMenus[]> = () => ({
  "SuperAdmin": SuperAdminSettingsMenu(),
  "CompanyAdmin": CompanyAdminSettingsMenu(),
  "Supervisor": [],
});


interface IMenuProps {
  isLoggedIn?: boolean;
}

export const Menu: React.FunctionComponent<IMenuProps> = ({isLoggedIn}) => {
  const userRole = useSelector(selectUserRole);
  const companyId = useSelector(selectCompanyId);
  const company = useSelector(selectCompany(companyId || ''));
  const cognitoData = useSelector(getCognitoUserData);
  const history = useHistory();
  const isUserAnAdmin = useSelector(selectUserAdmin);
  const isSuperAdmin = userRole === UserRole.SuperAdmin;
  const isCompanyAdmin = userRole === UserRole.CompanyAdmin;
  const { openedKeys, selectedKeys } = getMenuSelected();

  if (!isLoggedIn || !cognitoData) {
    return null;
  }
  if (!isUserAnAdmin || !cognitoData['cognito:groups'].includes(UsersGroups.Administrators)) {
    return null;
  }
  const role:UserRole = cognitoData['custom:role'];

  const onMenuClick = (info: any) => {
    const { key } = info;
    history.push(`/${key}`);
  }
  let AdminMenu = ADMIN_MENU()[role];
  const hasNotification = isSuperAdmin || company?.notificationTypesEnabled?.email ||
    company?.notificationTypesEnabled?.sms ||
    company?.notificationTypesEnabled?.web;
  if(!hasNotification){
    AdminMenu = AdminMenu.filter(menu => menu.key !== 'notifications');
  }
  const TrainingMenu = TRAINING_MENU()[role];
  const SettingsMenu = SETTINGS_MENU()[role];

  const { showGamification, showKnowledgeBase } = company ?? {};
  
  return <AntdMenu
      defaultOpenKeys={[openedKeys]}
      defaultSelectedKeys={[selectedKeys]}
      onClick={onMenuClick} className={styles.menu}
      theme="light" mode="inline"
      triggerSubMenuAction="click"
    >
      <AntdMenu.Item style={{paddingLeft: 16}} className={cls(styles.item, styles.welcome)} icon={<CogIcon icon='home2' />} key={`welcome`}>{getTranslations('ADMIN_HOME')}</AntdMenu.Item>
      <SubMenu icon={<CogIcon icon='empresa' />} className={styles.subMenu} popupClassName={styles.subMenuContainer} key="admin" title={getTranslations('ADMIN_MAIN_MENU_ADMIN')}>
        {AdminMenu?.map(menu => (<AntdMenu.Item  className={styles.item} key={menu.key}>{menu.title}</AntdMenu.Item>))}
      </SubMenu>
      {
        TrainingMenu.length > 0 &&
        <SubMenu icon={<CogIcon icon='book' />} className={styles.subMenu} popupClassName={styles.subMenuContainer} key="training" title={getTranslations('ADMIN_MAIN_MENU_CONTENT')}>
          {TrainingMenu?.filter((item) => item.key !== 'knowledge-bases' || showKnowledgeBase || isSuperAdmin ).map(menu => (<AntdMenu.Item className={styles.item} key={menu.key}>{menu.title}</AntdMenu.Item>))}
        </SubMenu>
      }
      <SubMenu icon={<CogIcon icon='Chart' />} className={styles.subMenu} popupClassName={styles.subMenuContainer} key='reports' title={getTranslations('ADMIN_MAIN_MENU_REPORTS_AND_DASHBOARDS')}>
        {Object.values(ReportTypes).filter(reportType => 
          (userRole && ReportRoles[reportType].includes(userRole))
        ).map(reportType => {
          return (
            <AntdMenu.Item className={styles.item} key={`${Prefixes.reports.substring(1)}/${reportType}`}>{menuTitles()[reportType]}</AntdMenu.Item>
          );
        })}
      </SubMenu>
      {isSuperAdmin || isCompanyAdmin ? <SubMenu icon={<CogIcon icon='config' />} className={styles.subMenu} popupClassName={styles.subMenuContainer} key="settings" title={getTranslations('ADMIN_MAIN_MENU_CONFIG')}>
        {SettingsMenu.filter((item) => item.key !== 'gamification' || showGamification || isSuperAdmin ).map(menu => (<AntdMenu.Item  className={styles.item} key={menu.key}>{menu.title}</AntdMenu.Item>))}
      </SubMenu> : null}
  </AntdMenu>;
}
