import React, { FunctionComponent, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import cls from 'classnames';

import { selectCompanies } from "../../../features/company/companySlice";
import { getCognitoUserData } from "../../../features/auth/authSlice";
import { selectUser } from "../../../features/users/usersSlice";
import { fetchBadgesPoints, fetchPoints, selectBadges, selectPoints } from "../../../features/students/pointsSlice";
import { fetchRanking, selectUserRanking } from "../../../features/students/rankingSlice";
import { fetchSessionInfo, selectUserSessionInfo } from "../../../features/students/sessionInfoSlice";
import { useDeviceSize } from "../../utils/useDeviceSize";
import { SIZES } from "../../common/screen";

import styles from "./progress.module.scss";

import badgesImage from '../../../assets/infographic/badges.png';
import pointsImage from '../../../assets/infographic/points.png';
import timeIcon from '../../../assets/infographic/time2.png';
import star from '../../../assets/infographic/star.png';
import { getTranslations } from "../../../features/translations/translationsUtils";

export const Progress: FunctionComponent = () => {
  const history = useHistory();
  const companiesDict = useSelector(selectCompanies);
  const cognitoUserData = useSelector(getCognitoUserData);
  const points = useSelector(selectPoints);
  const badges = useSelector(selectBadges);
  const cognitoUsername = cognitoUserData
    ? cognitoUserData["cognito:username"]
    : null;
  const userFromStore = useSelector(selectUser(cognitoUsername));
  const userRanking = useSelector(selectUserRanking(userFromStore?.username));
  const sessionInfo = useSelector(selectUserSessionInfo);
  const size = useDeviceSize();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPoints());
    dispatch(fetchBadgesPoints());
    dispatch(fetchRanking());
    dispatch(fetchSessionInfo());
  }, [dispatch]);

  const company = companiesDict[userFromStore?.company ?? ''];
  const showInfographic = company?.showInfographic ?? false;
  const time = Math.round(sessionInfo.time ?? 0);
  const hours = time < 60 ? getTranslations('HOME_TIME_IN_MINUTES', [time]) : getTranslations('HOME_TIME_IN_HOURS', [Math.round(time / 60)]);
  const YOUR_PROGRESS_TEXT = getTranslations('HOME_YOUR_PROGRESS');
  const VIEW_INFOGRAPHIC_TEXT = getTranslations('HOME_VIEW_INFOGRAPHIC');

  const GOT_POINTS_TEXT = getTranslations('HOME_GOT_POINTS');
  const GOT_CERTIFICATES_TEXT = getTranslations('HOME_GOT_CERTIFICATES');
  const GOT_TRAINING_HOURS_TEXT = getTranslations('HOME_GOT_TRAINING_HOURS');
  const GOT_BADGES_TEXT = getTranslations('HOME_GOT_BADGES');

  if (size === SIZES.PHONE) {
    return <div className={cls(styles.container, styles.mobile)}>
      <div className={styles.header}>
        <div className={styles.title}>{YOUR_PROGRESS_TEXT}</div>
        {showInfographic ?
          <div className={styles.link}>
            <span onClick={() => {
              history.push('/my-profile/infographic')
            }}>{VIEW_INFOGRAPHIC_TEXT}</span>
          </div> :
          null}
      </div>
      <div className={styles.carousel}>
        <div className={styles.carouselItem}>
          <div key="points" className={styles.record}>
            <div className={styles.icon}><img src={pointsImage} alt="" /></div>
            <div className={styles.value}>{points} {GOT_POINTS_TEXT}</div>
          </div>
        </div>
        <div className={styles.carouselItem}>
          <div key="certificates" className={styles.record}>
            <div className={styles.icon}><img src={star} alt="" /></div>
            <div className={styles.value}>{userRanking.certificates?.count ?? 0} {GOT_CERTIFICATES_TEXT}</div>
          </div>
        </div>
        <div className={styles.carouselItem}>
          <div key="session" className={styles.record}>
            <div className={styles.icon}><img src={timeIcon} alt="" /></div>
            <div className={styles.value}>{hours} {GOT_TRAINING_HOURS_TEXT}</div>
          </div>
        </div>
        <div className={styles.carouselItem}>
          <div key="badges" className={styles.record}>
            <div className={styles.icon}><img src={badgesImage} alt="" /></div>
            <div className={styles.value}>{badges} {GOT_BADGES_TEXT}</div>
          </div>
        </div>
      </div>
    </div>
  }
  return <div className={cls(styles.container, styles.noMobile)}>
    <div className={styles.header}>
      <div className={styles.title}>{YOUR_PROGRESS_TEXT}</div>
      {showInfographic ?
        <div className={styles.link}>
          <span onClick={() => {
            history.push('/my-profile/infographic')
          }}>{VIEW_INFOGRAPHIC_TEXT}</span>
        </div> :
        null}
    </div>
    <div className={styles.records}>
      <div className={styles.record}>
        <div className={styles.icon}><img src={pointsImage} alt="" /></div>
        <div className={styles.value}>{points} {GOT_POINTS_TEXT}</div>
      </div>
      <div className={styles.record}>
        <div className={styles.icon}><img src={star} alt="" /></div>
        <div className={styles.value}>{userRanking.certificates?.count ?? 0} {GOT_CERTIFICATES_TEXT}</div>
      </div>
      <div className={styles.record}>
        <div className={styles.icon}><img src={timeIcon} alt="" /></div>
        <div className={styles.value}>{hours} {GOT_TRAINING_HOURS_TEXT}</div>
      </div>
      <div className={styles.record}>
        <div className={styles.icon}><img src={badgesImage} alt="" /></div>
        <div className={styles.value}>{badges} {GOT_BADGES_TEXT}</div>
      </div>
    </div>
  </div>;
};
