import React, { FunctionComponent, useEffect, forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ctx from 'classnames';
import moment from 'moment';

import {
    selectStudentCourse,
    selectStudentCourseRules,
    selectStudentCourseAdvancePercentage,
    selectStudentCourseLastLessonLessonId,
    selectStudentCourseProgress,
    COURSE_EXAM,
    COURSE_SURVEY,
    TOPIC_TEST,
    selectStudentCourseLesson,
    selectStudentCourseTopicLesson,
} from '../../../features/students/courseSlice';

import styles from './lessonInfo.module.scss';
import {
    selectCategory,
    fetchCategories
} from '../../../features/category/categorySlice';
import { Button, Card, Progress } from 'antd';
import { CogIcon } from '../../common/CogIcon';
import {
    COURSES_PRIORITY_COLORS,
    COURSES_PRIORITY_LABELS,
    COURSES_PRIORITY_ICON
} from '../../../utils/constants';
import { useHistory } from 'react-router-dom';
import { CategoryChip } from '../common/Chip';
import { goToCertificate } from './Congratulations';
import { fetchHashByTemplateId, selectCertificateHash } from '../../../features/students/certificateHashSlice';
import {
    getCognitoUserData,
} from "../../../features/auth/authSlice";
import { analytics } from "../../../services/analytics";
import { selectUser } from '../../../features/users/usersSlice';
import { selectCompany } from '../../../features/company/companySlice';
import { IProgram } from "../../../features/program/programSlice";
import { selectGroup } from "../../../features/groups/groupSlice";
import { getTranslations } from '../../../features/translations/translationsUtils';
import { getTimeInHours } from '../common/timeUtils';
import { fetchStudentHomeCourses, selectIsStudentHomeCourseBlocked, selectStudentHomeCourseById } from '../../../features/students/homeCoursesSlice';
import ConfirmationModal from '../../common/ConfirmationModal';
import { selectStudentMyCourses } from '../../../features/students/myCoursesSlice';

interface StudentLessonInfoProps { }

export const StudentLessonInfo: FunctionComponent<StudentLessonInfoProps> = () => {
    const course = useSelector(selectStudentCourse);
    const { extra } = useSelector(
        selectStudentHomeCourseById(course?._id ?? '')
    ) ?? {};
    const courseRules = useSelector(selectStudentCourseRules);
    const advancePercentage = useSelector(selectStudentCourseAdvancePercentage) || 0;
    const lessonIdToGo = useSelector(selectStudentCourseLastLessonLessonId);
    const progress = useSelector(selectStudentCourseProgress);
    const category = useSelector(selectCategory(course?.category as string));
    const hash = useSelector(selectCertificateHash(course?.certificate ?? ''));
    const analyticsRef = useRef<AnalyticsSelectLessonRef>(null);
    const [isModalShowing, setIsModalShowing] = useState<boolean>(false);
    const isBlockedBecausePredecesor = useSelector(
        selectIsStudentHomeCourseBlocked(course?._id ?? '')
    );
    const homeCourses = useSelector(selectStudentMyCourses);

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(fetchCategories());
        dispatch(fetchStudentHomeCourses());
    }, [dispatch]);

    useEffect(() => {
        if (course?.certificate && advancePercentage === 1) {
            dispatch(fetchHashByTemplateId(course.certificate))
        }
    }, [advancePercentage, course, dispatch])

    if (!course) {
        return null;
    }
    const { priority = 1, endDate } = courseRules || {};
    const daysLeft = endDate ? moment(endDate).diff(moment(), 'days') + 1 : 0;
    const COMPLETED_COURSE = getTranslations('STUDENT_COMPLETED_COURSE');
    const CONTINUE_LESSON = getTranslations('STUDENT_CONTINUE_LESSON');
    const START_COURSE = getTranslations('STUDENT_START_COURSE');
    const buttonLabel = advancePercentage === 1 ? COMPLETED_COURSE : (progress ? CONTINUE_LESSON : START_COURSE);
    let urlToGo = `/course/${course._id}/${lessonIdToGo.id}`;
    let isLessonExamOrTestUrl = false;
    switch (lessonIdToGo.where) {
        case COURSE_EXAM:
        case COURSE_SURVEY:
            urlToGo = `/course/${course._id}/exam`;
            isLessonExamOrTestUrl = true;
            break;
        case TOPIC_TEST:
            urlToGo = `/course/${course._id}/test/${lessonIdToGo.id}`;
            isLessonExamOrTestUrl = true;
            break;
    }

    const predecesorsIds = extra?.hasPredecessor
        ? extra.predecessor.map((id: string) => id)
        : [];

    const predecesorCourses = homeCourses
        .filter((obj) => {
            return predecesorsIds.includes(obj.course._id);
        })
        .map((obj) => obj.course);

    const predecesorCourse = predecesorCourses[0];
    const goToPredecesor = () => {
        const courseId = predecesorCourse?._id || "";
        history.push(`/course/${courseId}`);
    };

    const renderGoToPredecesor = () => {
        return <>
            {isModalShowing ? (
                <ConfirmationModal
                    closable
                    headerIcon="Attention"
                    onCancel={() => setIsModalShowing(false)}
                    title="Hay un curso predecesor pendiente"
                    message={
                        <div className={styles.containerModal}>
                            <div className={styles.mainContent}>
                                <div className={styles.description}>
                                    Este curso no se puede mostrar hasta culminar el curso "
                                    {predecesorCourse?.title}".
                                </div>
                            </div>
                        </div>
                    }
                    footer={
                        <Button
                            type="link"
                            onClick={() => goToPredecesor()}
                            className={styles.text}
                        >
                            Ir al curso predecesor
                        </Button>
                    }
                    onConfirmation={goToPredecesor}
                />
            ) : null}
        </>;
    };

    return <Card className={ctx(styles.container)}>
        <div className={styles.categoryContainer}>
            <CategoryChip categoryName={category?.name} />
        </div>
        <div className={styles.title}>{course.title}</div>
        <div className={styles.progress}>
            <Progress className={styles.bar} percent={advancePercentage * 100} showInfo={false} />
        </div>
        <div className={styles.durationPriorityContainer}>
            <div className={styles.priority}>
                <CogIcon className={styles.icon} color={COURSES_PRIORITY_COLORS[priority]} icon={COURSES_PRIORITY_ICON[priority]} />
                {getTranslations('STUDENT_PRIORITY', [getTranslations(COURSES_PRIORITY_LABELS[priority])])}
            </div>
            <div className={styles.duration}>
                <CogIcon className={styles.icon} color="#81858B" icon="clock" />
                {getTimeInHours(course?.durationHour)}
            </div>
            <div className={styles.timeLeft}>
                {daysLeft ? (daysLeft === 1 ? getTranslations('STUDENT_COURSE_ONE_DAY_LEFT') : getTranslations('STUDENT_COURSE_DAY_LEFT', [daysLeft])) : ''}
            </div>
        </div>
        <div className={styles.gotToLessonContainer}>
            <Button size='large' className={styles.gotToLesson} type="primary" disabled={!lessonIdToGo || advancePercentage === 1} onClick={() => {
                if(isBlockedBecausePredecesor) {
                    setIsModalShowing(true);
                    return;
                }
                if (!isLessonExamOrTestUrl) {
                    analyticsRef?.current?.trigger();
                }
                history.push(urlToGo);
            }}>{buttonLabel}</Button>
            {hash ? <Button className={styles.certificateButton}
                type="default" onClick={goToCertificate(hash)}
            >{getTranslations('STUDENT_VIEW_CERTIFICATE')} <CogIcon icon="" /></Button> : null}
        </div>
        <AnalyticsSelectLesson ref={analyticsRef} lessonId={lessonIdToGo.id} />
        {renderGoToPredecesor()}
    </Card>
}

type AnalyticsSelectLessonRef = {
    trigger: () => void,
}
interface AnalyticsSelectLessonProps {
    lessonId: string,
}

const AnalyticsSelectLesson =
    forwardRef<AnalyticsSelectLessonRef, AnalyticsSelectLessonProps>(({ lessonId }, ref) => {
        useImperativeHandle(ref, () => ({
            trigger: () => {
                analytics.selectLesson({
                    lesson: lesson,
                    topic: topic,
                    course: course,
                    program: {} as IProgram, // TODO how to fetch program
                    company: company,
                    group: group,
                });
            }
        }));
        const lesson = useSelector(selectStudentCourseLesson(lessonId));
        const topic = useSelector(selectStudentCourseTopicLesson(lessonId));
        const course = useSelector(selectStudentCourse);
        const cognitoUserData = useSelector(getCognitoUserData);
        const cognitoUsername = cognitoUserData
            ? cognitoUserData["cognito:username"]
            : null;
        const userFromStore = useSelector(selectUser(cognitoUsername));
        const companyId = userFromStore?.company ?? "";
        const company = useSelector(selectCompany(companyId));
        const group = useSelector(selectGroup(userFromStore?.studentGroupId || '')) || null;

        return null;
    });
