import React, {
  FunctionComponent,
  ReactText,
  useEffect,
  useState,
} from "react";

import { Tooltip } from "antd";
import ctx from "classnames";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { getCognitoUserData } from "../../features/auth/authSlice";
import { UserRole } from "../../features/users/usersSlice";
import { CogIcon } from "../common/CogIcon";
import { MainLayout } from "../common/Layouts/Main";
import LoadingOverlay from "../common/LoadingOverlay";
import { CompanyFilter } from "../Filters/Company";
import {
  menuTitles,
  ReportsRoutes,
  ReportTypes,
  sectionTitles,
  sectionTooltips,
} from "./MenuAndRoutes";
import { Prefixes } from "../prefixes";

import styles from "./index.module.scss";
import { getTranslations } from "../../features/translations/translationsUtils";


export const Reports: FunctionComponent = () => {
  const [filteredInfo, setFilteredInfo] = useState<ReactText[]>([]);
  const [reportType, setReportType] = useState<ReportTypes>();
  const [shouldReset, setShouldReset] = useState(false);
  const cognitoData = useSelector(getCognitoUserData);
  const role = cognitoData ? cognitoData["custom:role"] : undefined;
  const location = useLocation();

  useEffect(() => {
    setShouldReset(true);
    setFilteredInfo([]);
    const reportType = Object.keys(sectionTitles()).find((reportType) => {
      return (
        location.pathname.startsWith(`${Prefixes.reports}/${reportType}`)
      );
    });
    if (reportType) {
      setReportType(reportType as ReportTypes);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (shouldReset) {
      setShouldReset(false);
    }
  }, [shouldReset]);

  if (!reportType) {
    return <LoadingOverlay spinning={true} />;
  }

  return (
    <MainLayout
      title={sectionTitles()[reportType]}
      rightTopOptions={
        <div className={styles.optionsContainer}>
          <Tooltip
            placement="topLeft"
            title={getTranslations(sectionTooltips[reportType as ReportTypes])}
            className={styles.tooltip}
          >
            <div>
              <CogIcon size="1.4em" color="#81858b" icon={"Attention"} />
            </div>
          </Tooltip>
          {role === UserRole.SuperAdmin &&
            (reportType !== ReportTypes.EducationalContent && reportType !== ReportTypes.Companies) && (
              <CompanyFilter
                onApply={setFilteredInfo}
                type="report"
                reset={shouldReset}
              />
            )}
        </div>
      }
      backgroundColor="#f7f8fa"
      titleContainerClass={styles.report}
      breadcrumbs={[getTranslations('REPORT_BREADCRUMB'), menuTitles()[reportType]]}
    >
      <div className={ctx(styles.container)}>
        <ReportsRoutes companyId={filteredInfo[0]?.toString() ?? ""} />
      </div>
    </MainLayout>
  );
};
