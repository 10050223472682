import React, { useState, FunctionComponent, useEffect } from 'react';
import { Form, Button, Input } from 'antd';
import cls from 'classnames';

import { Header } from '../Header';

import styles from './index.module.scss';
import commonStyles from '../Login.module.scss';
import { ISignUpStepProps, layout } from './interfaces';
import { MIN_3_CHAR } from '../../Users/Form';
import { getTranslations } from '../../../features/translations/translationsUtils';

interface Step2Props extends ISignUpStepProps {

};

export const Step2: FunctionComponent<Step2Props> = ({ next, userData }) => {
  const [given_name, setFirstName] = useState<string>(userData.given_name || '');
  const [middle_name, setMiddleName] = useState<string>(userData.middle_name || '');
  const [family_name, setLastName] = useState<string>(userData.family_name || '');
  const [secondLastname, setSecondLastName] = useState<string>(userData.secondLastname || '');
  useEffect(() => {
  }, []);
  const handleSubmit = () => {
    form.validateFields().then(() => {
      next({
        given_name,
        middle_name,
        family_name,
        secondLastname,
      });
    });

  };

  const [form] = Form.useForm();
  return (
    <Form
      {...layout}
      form={form}
      name="basic"
      onFinish={() => {
        handleSubmit();
      }}
      onFinishFailed={() => {
        console.error('signup failed');
      }}

    >
      <div
        className={cls(commonStyles.formInnerContainer, commonStyles.overload, commonStyles.noHeightLimit)}
      >
        <Header title={getTranslations('LOGIN_STEP_2_TITLE')}>
          <div className={styles.subTitles}>
            {getTranslations('LOGIN_STEPS_2_MESSAGE')}
          </div>
        </Header>
        <Form.Item
          label={getTranslations('LOGIN_FIRST_NAME')}
          name="firstName"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValue={given_name}
          rules={[
            { type: "string", required: true, message: getTranslations('GENERAL_REQUIRED') },
            { type: "string", max: 15, message: getTranslations('LOGIN_MAX_15_CHARS') },
            { type: "string", min: 3, message: MIN_3_CHAR() },
            {
              validator: (rule, value: string = "", callback) => {
                if (value.match(/\d/)) {
                  callback(getTranslations('LOGIN_NO_NUMBERS_ALLOWED'));
                  return;
                }
                callback();
              },
            },
          ]}
        >
          <Input
            onChange={(event) => {
              setFirstName(event.target.value);
            }}
          />
        </Form.Item>
        <Form.Item
          label={getTranslations('LOGIN_MIDDLE_NAME')}
          name="middleName"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValue={middle_name}
          rules={[
            { type: "string", required: false, message: getTranslations('GENERAL_REQUIRED') },
            { type: "string", max: 15, message: getTranslations('LOGIN_MAX_15_CHARS') },
            { type: "string", min: 3, message: MIN_3_CHAR() },
            {
              validator: (rule, value: string = "", callback) => {
                if (value.match(/\d/)) {
                  callback(getTranslations('LOGIN_NO_NUMBERS_ALLOWED'));
                  return;
                }
                callback();
              },
            },
          ]}
        >
          <Input
            onChange={(event) => {
              setMiddleName(event.target.value);
            }}
          />
        </Form.Item>
        <Form.Item
          label={getTranslations('LOGIN_LAST_NAME')}
          name="lastName"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValue={family_name}
          rules={[
            { type: "string", required: true, message: getTranslations('GENERAL_REQUIRED') },
            { type: "string", max: 15, message: getTranslations('LOGIN_MAX_15_CHARS') },
            { type: "string", min: 3, message: MIN_3_CHAR() },
            {
              validator: (rule, value: string = "", callback) => {
                if (value.match(/\d/)) {
                  callback(getTranslations('LOGIN_NO_NUMBERS_ALLOWED'));
                  return;
                }
                callback();
              },
            },
          ]}
        >
          <Input
            onChange={(event) => {
              setLastName(event.target.value);
            }}
          />
        </Form.Item>
        <Form.Item
          label={getTranslations('LOGIN_SECOND_LAST_NAME')}
          name="secondLastName"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValue={secondLastname}
          rules={[
            { type: "string", required: false, message: getTranslations('GENERAL_REQUIRED') },
            { type: "string", max: 15, message: getTranslations('LOGIN_MAX_15_CHARS') },
            { type: "string", min: 3, message: MIN_3_CHAR() },
            {
              validator: (rule, value: string = "", callback) => {
                if (value.match(/\d/)) {
                  callback(getTranslations('LOGIN_NO_NUMBERS_ALLOWED'));
                  return;
                }
                callback();
              },
            },
          ]}
        >
          <Input
            onChange={(event) => {
              setSecondLastName(event.target.value);
            }}
          />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          disabled={false}
          className={cls(commonStyles.signupBtn, styles.signupBtn2)}
        >
          {getTranslations('LOGIN_NEXT')}
        </Button>
      </div>
    </Form>
  );
};
