import React, { useState, FunctionComponent, useEffect } from 'react';
import { Form, Button, Input, DatePicker, Select } from 'antd';
import cls from 'classnames';
import moment from 'moment';

import { Header } from '../Header';

import styles from './index.module.scss';
import commonStyles from '../Login.module.scss';
import { ISignUpStepProps, layout } from './interfaces';
import { GenderEnum } from '../../../features/users/usersSlice';
import { genders, identificationTypes } from '../../Users/Form';
import { dateFormatList } from '../../../utils/constants';
import { extractDate } from '../../utils/convertions';
import { isCIValid } from '../../../utils/validation';
import { IdTypes } from '../../Users/common/identification';
import { getTranslations } from '../../../features/translations/translationsUtils';

interface Step2Props extends ISignUpStepProps {

};

export const Step3: FunctionComponent<Step2Props> = ({ next, userData }) => {
  const [gender, setGender] = useState<GenderEnum | undefined>(userData.gender);
  const [birthdate, setBirthdate] = useState<moment.Moment | null>(userData.birthdate ? moment(userData.birthdate) : null);
  const [nationalIdType, setNationalIdType] = useState<string>(userData.nationalIdType || '');
  const [nationalId, setNationalId] = useState<string>(userData.nationalId || '');
  useEffect(() => {
  }, []);
  const handleSubmit = () => {
    form.validateFields().then(() => {
      next({
        birthdate: extractDate(birthdate as moment.Moment),
        gender,
        nationalIdType,
        nationalId
      });
    });
  };

  useEffect(() => {
    setGender(userData.gender);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [form] = Form.useForm();
  return (
    <Form
      {...layout}
      form={form}
      name="basic"
      onFinish={() => {
        handleSubmit();
      }}
      onFinishFailed={() => {
        console.error('signup failed');
      }}

    >
      <div
        className={cls(commonStyles.formInnerContainer, commonStyles.overload, commonStyles.noHeightLimit)}
      >
        <Header title={getTranslations('LOGIN_STEP_3_TITLE')}>
          <div className={styles.subTitles}>
          {getTranslations('LOGIN_STEPS_3_MESSAGE')}
          </div>
        </Header>
        <Form.Item
          label="Genero"
          name="gender"
          rules={[{ required: true, message: getTranslations('GENERAL_REQUIRED')}]}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValue={gender}
        >
          <Select
            placeholder={getTranslations('LOGIN_GENDER')}
            onChange={(value) => {
              setGender(value as GenderEnum);
            }}
          >
            {genders().map((item, index) => (
              <Select.Option key={index} value={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={getTranslations('LOGIN_BIRTHDAY')}
          name="birthday"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValue={birthdate}
          rules={[
            () => ({
              validator: async (rules, value) => {
                const today = moment(new Date());
                const years = today.diff(value, "years");
                if (years < 18 || value === null || isNaN(years)) {
                  throw new Error(getTranslations('LOGIN_BIRTHDAY_ERROR'));
                }
              },
            }),
          ]}
        >
          <DatePicker
            placeholder="DD/MM/AA"
            onChange={(value) => {
              setBirthdate(value);
            }}
            format={dateFormatList}
          />
        </Form.Item>
        <Form.Item
          label={getTranslations('LOGIN_ID_TYPE')}
          name="nationalIdType"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValue={nationalIdType}
          rules={[
            { type: "string", required: true, message: getTranslations('LOGIN_ID_TYPE_ERROR') },
          ]}
        >
          <Select
            placeholder={getTranslations('LOGIN_ID_TYPE_PLACEHOLDER')}
            onChange={(value) => {
              setNationalIdType(value as string);
            }}
          >
            {identificationTypes().map((item, index) => (
              <Select.Option key={index} value={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={getTranslations('LOGIN_NATIONAL_ID')}
          name="nationalId"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValue={nationalId}
          rules={[
            { type: "string", required: true, message: getTranslations('GENERAL_REQUIRED') },
            ({ getFieldValue }) => ({
              validator: async (rules, value: string) => {
                const nationalIdType = getFieldValue("nationalIdType");
                if (nationalIdType === undefined) {
                  return;
                }
                if (nationalIdType === IdTypes.id && !isCIValid(value)) {
                  throw new Error(getTranslations('LOGIN_NATIONAL_ID_ERROR'));
                }
              },
            }),
          ]}
        >
          <Input
            disabled={!nationalIdType}
            onChange={(event) => {
              setNationalId(event.target.value);
            }}
          />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          disabled={false}
          className={cls(commonStyles.signupBtn, styles.signupBtn2)}
        >
          Continuar
        </Button>
      </div>
    </Form>
  );
};
