import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import cls from "classnames";
import { Header } from "./Header";
import { PasswordInput } from "./PasswordInput";
import { cognizantPasswordContraints } from "../utils/passwordConstraint";
import { useParams } from "react-router-dom";

import panelStyles from "./Panel.module.scss";
import styles from "./PasswordNew.module.scss";
import './PasswordNewOverrides.scss'
import { PasswordRequirements } from "./PasswordRequirements";
import {
  setNewPassword,
  loggedIn,
  newPasswordRequired,
} from "../../features/auth/authSlice";
import { DialogPopUp } from "../common/DialogPopUp";

import {
  Col,
  Row,
  Card,
  Button,
  Form,
} from 'antd';
import { getTranslations } from "../../features/translations/translationsUtils";
import LoadingOverlay from "../common/LoadingOverlay";
import { LanguageSelector } from "./LanguageSelector";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export function PasswordNew() {
  const [key, setKey] = useState<string>('');
  const params: any = useParams();
  const { code, username } = params;
  const newPassword = useSelector(newPasswordRequired);
  const [password, setPassword] = useState<string>("");
  const [passwordConfirmation, setPaswrdConfirmation] = useState<string>("");
  const [passwordDontMatchError, setPasswordDontMatchError] = useState<string>(
    ""
  );
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [allIsFulfiled, setAllIsFulfiled] = useState<boolean>(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoggedIn = useSelector(loggedIn);

  const handleSubmit = () => {
    if (password !== passwordConfirmation) {
      setPasswordDontMatchError("Contraseñas no son iguales");
      return;
    }
    const { valid, errors } = cognizantPasswordContraints(password);
    if (!valid) {
      errors.forEach((error) => console.error(error));
      return;
    }
    dispatch(setNewPassword(code, username, password));
  };

  useEffect(() => {
    if (isLoggedIn) {
      history.push("/welcome");
    }
  });

  return (
    <React.Fragment>
      <div className={key} />
      {key === '' ? <LoadingOverlay spinning /> : <>
        <DialogPopUp
          name="¡Contraseña"
          action="actualizada!"
          visible={isModalVisible}
          onCancel={() => {
            setIsModalVisible(false);
          }}
        />
        <div className={cls(panelStyles.Bkgnd)}></div>
        <div>
          <Row>
            <Col xs={{ span: 18, offset: 3 }} md={{ span: 8, offset: 8 }}>
              <Row>
                <div style={{ margin: 'auto' }}>
                  <Card className={cls(styles.Container)}>
                    <Header
                      className={cls(styles.Dark, styles.size)}
                      subTitleClassname={styles.noSubTitle}
                      title={getTranslations('LOGIN_CREATE_NEW_PASSWORD')}
                    />
                    <Form
                      className={cls(styles.form)}
                      {...layout}
                      name="basic"
                      onFinish={(values: { newPassword: string, newPasswordConfirmation: string }) => {
                        handleSubmit();
                      }}
                      onFinishFailed={() => { console.error(getTranslations('LOGIN_CREATE_NEW_PASSWORD_ERROR')) }}
                    >
                      <Form.Item
                        className={styles.gray}
                        label={getTranslations('LOGIN_NEW_PASSWORD')}
                        name="newPassword"
                        rules={[{ required: true, message: getTranslations('LOGIN_NEW_PASSWORD_ERROR') }]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <PasswordInput onChange={setPassword} />
                      </Form.Item>
                      <Form.Item
                        label={getTranslations('LOGIN_REPEAT_PASSWORD')}
                        name="newPasswordConfirmation"
                        rules={[{ required: true, message: getTranslations('LOGIN_REPEAT_PASSWORD_ERROR') }]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <PasswordInput onChange={setPaswrdConfirmation} showMeter={false} />
                      </Form.Item>
                      {passwordDontMatchError ? <div className={cls(styles.errorMsg, 'error')}>{passwordDontMatchError}</div> : null}
                      <div className={cls(styles.requirementsSize)}>
                        <PasswordRequirements
                          password={password}
                          onAllFulfiledChange={(allIsFulfiled: boolean) => {
                            setAllIsFulfiled(allIsFulfiled);
                          }}
                        />
                      </div>
                      <Button
                        disabled={!allIsFulfiled || passwordConfirmation.length === 0}
                        className={cls(styles.setPasswordBtn)}
                        type="primary"
                        htmlType="submit"
                      >
                        {newPassword
                          ? getTranslations('LOGIN_SET_PASSWORD')
                          : getTranslations('LOGIN_RESTORE_PASSWORD')}
                      </Button>
                    </Form>
                  </Card>
                </div>
              </Row>
            </Col>
          </Row>
        </div>
      </>
      }
      <LanguageSelector loading={key === ''} setKey={(value: string) => {
        setKey(value);
      }} />
    </React.Fragment>
  );
}
