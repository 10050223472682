import React from "react";

import { Form } from "antd";
import { useHistory } from "react-router-dom";

import { MainLayout } from "../common/Layouts/Main";
import { ProgramForm } from "./Form";
import { Prefixes } from "../prefixes";
import { getTranslations } from "../../features/translations/translationsUtils";


export const NewProgram: React.FC = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  return (
    <MainLayout
      title={getTranslations('PROGRAM_NEW')}
      onGoBack={() => {
        history.push(`${Prefixes.program}`);
      }}
      breadcrumbs={[getTranslations('PROGRAM_TITLE'), getTranslations('PROGRAM_NEW_SHORT')]}
    >
      <ProgramForm
        isCreate
        programBeingEdited={null}
        form={form}
      />
    </MainLayout>
  );
};
