import React, { FunctionComponent, useState } from 'react';

import { Drawer, Button } from 'antd';
import { useSelector } from 'react-redux';
import cls from 'classnames';

import {
    selectStudentMyCourses
} from '../../../features/students/myCoursesSlice';
import { CogIcon } from '../../common/CogIcon';
import { Calendar as MyCalendar } from './Calendar/index';
import { useDeviceSize } from '../../utils/useDeviceSize';
import { SIZES } from '../../common/screen';

import styles from './calendar.module.scss';
import { getTranslations } from '../../../features/translations/translationsUtils';


interface CalendarProps {
}

export const Calendar: FunctionComponent<CalendarProps> = () => {
    const [visible, setVisible] = useState<boolean>(false);
    const courses = useSelector(selectStudentMyCourses);
    const size = useDeviceSize();
    const isMobile = size === SIZES.PHONE;
    const onClose = () => {
        setVisible(false);
    }
    return <>
        <Drawer
            className={cls(styles.drawer, {
                [styles.mobile]: isMobile,
            })}
            placement={'right'}
            closable={false}
            onClose={onClose}
            visible={visible}
        >
            <MyCalendar courses={courses} weekStartOnSunday={false} date={new Date()} onSelectDate={(date) => {
            }} />
        </Drawer>
        <Button className="clientSize withoutHover" type='link' size='large' onClick={() => {
            setVisible(true);
        }}><CogIcon size={24} color="var(--tertiary-text-button-color)" icon="Calendar" />&nbsp;&nbsp;{getTranslations('MYCOURSES_SCHEDULE')}</Button>
    </>

};