import React, { FunctionComponent } from "react";

import cls from 'classnames';
import { useSelector } from "react-redux";
import moment from 'moment';

import { selectPoints } from "../../../features/students/pointsSlice";

import styles from './points.module.scss';

import pointsIcon from '../../../assets/infographic/points.png';
import { getTranslations } from "../../../features/translations/translationsUtils";
import { Tooltip } from "antd";
import { CogIcon } from "../../common/CogIcon";
import { selectCompany } from "../../../features/company/companySlice";

interface IPointsProps {
  isMobile: boolean;
  companyId: string;
}
export const Points: FunctionComponent<IPointsProps> = ({
  isMobile,
  companyId
}) => {
  const points = useSelector(selectPoints);
  const company = useSelector(selectCompany(companyId));
  return <div className={cls(styles.container, { [styles.mobile]: isMobile })}>
    <div className={styles.icon}><img alt="Points" src={pointsIcon} /></div>
    <div className={styles.content}>
      <div className={styles.points}>
        {points ?? 0}
        <Tooltip title={getTranslations('STUDENT_PROFILE_POINTS_ACCUMULATED_SINCE', [moment(company?.enabledGamificationSince).format("DD-MM-YYYY")])}>
          <><CogIcon size="18px" color="#81858b" icon={'Attention'} /></>
        </Tooltip>
      </div>
      <div className={styles.description}>{getTranslations('STUDENT_PROFILE_OBTAINED_POINTS')}</div>
    </div>
  </div>
};