import { Button, Card, Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import ctx from "classnames";
import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { ParentTypeEnum } from "../../../features/evaluation/common/enums";
import {
  IAnswer,
  QuestionsAnswers,
} from "../../../features/evaluation/common/interfaces";
import {
  fetchEvaluation,
  registerEvaluationResult,
  selectIsEvaluationFetching,
  selectEvaluation,
} from "../../../features/evaluation/currentEvaluationSlice";
import { CogIcon } from "../../common/CogIcon";
import styles from "./evaluations.module.scss";
import { getTranslations } from "../../../features/translations/translationsUtils";

interface QuizProps {
  courseId: string,
  className?: string,
  quizId: string,
  lessonId: string,
  questionToShow?: number,
  onEvaluationComplete: Function,
  answers?: QuestionsAnswers,
  onChangeAnswer?(answers: QuestionsAnswers): void,
  showOnlyOneQuestion?: boolean,
  onNextQuestion?(): void,
  setQuizAnswers?(answers: IAnswer[]): void,
}

export const Quiz: FunctionComponent<QuizProps> = ({
  courseId,
  className,
  quizId,
  lessonId,
  questionToShow = 0,
  onEvaluationComplete,
  answers,
  onChangeAnswer,
  showOnlyOneQuestion,
  onNextQuestion,
  setQuizAnswers = () => { },
}) => {
  const [currentQuestion, setCurrentQuestion] = useState<number>(
    questionToShow
  );
  const [answersSelected, setAnswerSelected] = useState<QuestionsAnswers>(
    answers ? answers : {}
  );
  const [feedback, setFeedback] = useState<string | boolean>(false);
  const [
    correctWrongAnswerIcon,
    setCorrectWrongAnswerIcon,
  ] = useState<ReactNode | null>(null);
  const [correctWrongAnswerlabel, setCorrectWrongAnswerlabel] = useState<
    string | null
  >(null);
  const quiz = useSelector(selectEvaluation(quizId || ''));
  const loading = useSelector(selectIsEvaluationFetching(quizId || ''));
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchEvaluation(quizId, lessonId, courseId));
  }, [dispatch, lessonId, quizId, courseId]);
  const question = quiz?.questions[currentQuestion];
  const questionsCount = quiz?.questions.length || 0;
  const onAnswerChange = (event: RadioChangeEvent) => {
    const answerId = event.target.value;
    const answers = {
      ...answersSelected,
      [question?._id || ""]: {
        answerId,
        correct: question?.correctAnswer === answerId,
      },
    };
    setAnswerSelected(answers);
    if (onChangeAnswer) {
      onChangeAnswer(answers);
    }
  };
  const buttonLabel = feedback
    ? getTranslations('STUDENT_CONTINUE')
    : questionsCount - 1 === currentQuestion
      ? getTranslations('STUDENT_SEND_ANSWERS')
      : getTranslations('STUDENT_NEXT');
  const answerSelected = answersSelected[question?._id || ""];
  const buttonIsDisable = !answerSelected;
  const wasCorrectAnswer = question?.correctAnswer === answerSelected?.answerId;
  const onNext = () => {
    if (!feedback) {
      setCorrectWrongAnswerIcon(
        wasCorrectAnswer ? (
          <CogIcon color="#43D5A9" icon="Check-circle" />
        ) : (
          <CogIcon color="#EB5757" icon="Cross-circle" />
        )
      );
      setCorrectWrongAnswerlabel(
        wasCorrectAnswer ? getTranslations('STUDENT_RIGHT_ANSWER') : getTranslations('STUDENT_WRONG_ANSWER')
      );
      const answer = question?.possibleAnswers.find(
        (answer) => answer._id === answerSelected.answerId
      );
      if (answer?.feedback) {
        setFeedback(answer?.feedback);
      } else {
        setFeedback(true);
      }
      return;
    }
    if (currentQuestion + 1 === questionsCount) {
      const answers: IAnswer[] = [];
      let result = 0;
      Object.keys(answersSelected).forEach((answerSelected) => {
        answers.push({
          answerId: answersSelected[answerSelected].answerId,
          questionId: answerSelected,
        });
        if (answersSelected[answerSelected].correct) {
          ++result;
        }
      });
      setQuizAnswers(answers);
      dispatch(
        registerEvaluationResult({
          answers,
          evaluationId: quizId,
          parentId: lessonId,
          parentType: ParentTypeEnum.Lesson,
          result,
          total: Object.keys(answersSelected).length,
          courseId,
        })
      );
      onEvaluationComplete();
      if (showOnlyOneQuestion && onNextQuestion) {
        onNextQuestion();
      }
      return;
    }
    setFeedback(false);
    setCurrentQuestion(currentQuestion + 1);
    setCorrectWrongAnswerIcon(null);
    setCorrectWrongAnswerlabel(null);
    if (showOnlyOneQuestion && onNextQuestion) {
      onNextQuestion();
    }
  };
  let content: ReactNode;
  if (!feedback) {
    content = (
      <React.Fragment>
        <div className={styles.counter}>
          {currentQuestion + 1}/{questionsCount}
        </div>
        <div className={styles.title}>{question?.title}</div>
        <div className={styles.subTitle}>{getTranslations('STUDENT_SELECT_ANSWER')}</div>
        <Radio.Group
          className={styles.answers}
          onChange={onAnswerChange}
          value={answerSelected?.answerId}
        >
          {question?.possibleAnswers.map((answer) => (
            <Radio
              className={styles.answer}
              key={answer._id}
              value={answer._id}
            >
              {answer.label}
            </Radio>
          ))}
        </Radio.Group>
      </React.Fragment>
    );
  } else {
    content = (
      <React.Fragment>
        <div
          className={ctx(styles.answerInfoIcon, {
            [styles.rightAnswer]: wasCorrectAnswer,
          })}
        >
          {correctWrongAnswerIcon}
        </div>
        <div className={styles.answerInfoLabel}>{correctWrongAnswerlabel}</div>
        {feedback !== true ? (
          <div className={styles.feedback}>{feedback}</div>
        ) : null}
      </React.Fragment>
    );
  }
  return (
    <div className={ctx(styles.container, className)}>
      <Card
        className={styles.question}
        loading={loading}
        actions={[
          <div className={styles.buttonContainer}>
            <Button disabled={buttonIsDisable} onClick={onNext} type="link">
              {buttonLabel}
            </Button>
          </div>,
        ]}
      >
        {content}
      </Card>
    </div>
  );
};
