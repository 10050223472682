import React, { FunctionComponent } from "react";

import cls from 'classnames';

import styles from './sessions.module.scss';

import sessionsIcon from '../../../assets/infographic/sessions.png';
import { selectUserSessionInfo } from "../../../features/students/sessionInfoSlice";
import { useSelector } from "react-redux";
import { getTranslations } from "../../../features/translations/translationsUtils";

interface ISessionsProps {
  isMobile: boolean;
}

export const Sessions: FunctionComponent<ISessionsProps> = ({
  isMobile
}) => {
  const sessionInfo = useSelector(selectUserSessionInfo).sessions || {};

  return <div className={cls(cls(styles.container, { [styles.mobile]: isMobile }))}>
  <div className={styles.title}>{getTranslations('STUDENT_PROFILE_SESSIONS')}</div>
  <div className={styles.secondSection}>
    <div className={styles.chart}>
      <img src={sessionsIcon} alt="Courses chart" />
    </div>
    <div className={styles.content}>
      <div className={styles.percentage}>{sessionInfo.last7Days}</div>
      <div className={styles.description}>{getTranslations('STUDENT_PROFILE_SESSIONS_LAST_WEEK')}</div>
    </div>
  </div>
  <div className={styles.bottomSection}>
    <div className={styles.column}>
      <div className={styles.value}>{sessionInfo.last30Days}</div>
      <div className={styles.description}>{getTranslations('STUDENT_PROFILE_SESSIONS_LAST_30_DAYS')}</div>
    </div>
    <div className={styles.column}>
      <div className={styles.value}>{sessionInfo.last90Days}</div>
      <div className={styles.description}>{getTranslations('STUDENT_PROFILE_SESSIONS_LAST_3_MONTHS')}</div>
    </div>
  </div>
</div>;
};