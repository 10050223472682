import { FirebaseOptions } from "firebase/app";

const env: any =  process.env;
const {
    REACT_APP_DOMAIN,
    REACT_APP_PROTOCOL,
    REACT_APP_ENV = 'development',
    REACT_APP_COGNITO_USER_POOL_ID = '',
    REACT_APP_COGNITO_APP_CLIENT_ID = '',
    REACT_APP_FIREBASE_JSON,
    REACT_APP_FIREBASE_VAPIDKEY='{}',
    REACT_APP_GOOGLE_ANALYTICS_UA_ID = '',
    REACT_APP_GOOGLE_ANALYTICS_UA_ID_DEV = '',
    REACT_APP_MEDIA_PORT= '',
    REACT_APP_LEARNING_PORT = '',
    REACT_APP_EVALUATION_PORT = '',
    REACT_APP_COMPANY_PORT = '',
    REACT_APP_CAMPAIGN_PORT = '',
    REACT_APP_ANALYTICS_PORT = '',
    REACT_APP_KNOWLEDGEBASE_PORT = '',
    REACT_APP_FIREBASE_CONFIG_APIKEY = '',
    REACT_APP_FIREBASE_CONFIG_AUTHDOMAIN = '',
    REACT_APP_FIREBASE_CONFIG_PROJECTID = '',
    REACT_APP_FIREBASE_CONFIG_STORAGEBUCKET = '',
    REACT_APP_FIREBASE_CONFIG_MESSAGINSENDERID = '',
    REACT_APP_FIREBASE_CONFIG_APPID = '',
    REACT_APP_FIREBASE_CONFIG_MEASUREMENTID = '',
    REACT_APP_GA_MEASUREMENT_ID = '',
} = env;

const trim = (value:string) => {
    return (value || '').trim();
};

let firebaseConfigFromJSON: boolean | FirebaseOptions = false;
if (REACT_APP_FIREBASE_JSON) {
  firebaseConfigFromJSON = JSON.parse(trim(REACT_APP_FIREBASE_JSON)) as FirebaseOptions;
}

export const API_DOMAIN = trim(REACT_APP_DOMAIN) || '-cognizant.rvntone.com';
export const API_PROTOCOL = trim(REACT_APP_PROTOCOL) || 'http://';
export const APP_ENV = trim(REACT_APP_ENV);
export const COGNITO_USER_POOL_ID = trim(REACT_APP_COGNITO_USER_POOL_ID);
export const COGNITO_APP_CLIENT_ID = trim(REACT_APP_COGNITO_APP_CLIENT_ID);
export const FIREBASE_JSON = firebaseConfigFromJSON ? firebaseConfigFromJSON : {
    "apiKey": trim(REACT_APP_FIREBASE_CONFIG_APIKEY),
    "authDomain":trim(REACT_APP_FIREBASE_CONFIG_AUTHDOMAIN),
    "projectId":trim(REACT_APP_FIREBASE_CONFIG_PROJECTID),
    "storageBucket":trim(REACT_APP_FIREBASE_CONFIG_STORAGEBUCKET),
    "messagingSenderId":trim(REACT_APP_FIREBASE_CONFIG_MESSAGINSENDERID),
    "appId":trim(REACT_APP_FIREBASE_CONFIG_APPID),
    "measurementId":trim(REACT_APP_FIREBASE_CONFIG_MEASUREMENTID)
}
export const FIREBASE_VAPIDKEY = trim(REACT_APP_FIREBASE_VAPIDKEY);
export const GOOGLE_ANALYTICS_UA_ID = trim(REACT_APP_GOOGLE_ANALYTICS_UA_ID);
export const GOOGLE_ANALYTICS_UA_ID_DEV = trim(REACT_APP_GOOGLE_ANALYTICS_UA_ID_DEV);

export const MEDIA_PORT = trim(REACT_APP_MEDIA_PORT);
export const LEARNING_PORT = trim(REACT_APP_LEARNING_PORT);
export const EVALUATION_PORT = trim(REACT_APP_EVALUATION_PORT);
export const COMPANY_PORT = trim(REACT_APP_COMPANY_PORT);
export const CAMPAIGN_PORT = trim(REACT_APP_CAMPAIGN_PORT);
export const ANALYTICS_PORT = trim(REACT_APP_ANALYTICS_PORT);
export const KNOWLEDGEBASE_PORT = trim(REACT_APP_KNOWLEDGEBASE_PORT);
export const GA_MEASUREMENT_ID = trim(REACT_APP_GA_MEASUREMENT_ID);