import React, { FunctionComponent, useEffect, useState } from "react";
import { BackTop, Input } from "antd";
import ctx from "classnames";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

import { CogIcon } from "../../common/CogIcon";
import { CoursesFilter } from "../../Filters/Courses";
import { Calendar } from './Calendar';

import styles from "./myCoursesSearch.module.scss";
import { SIZES } from '../../common/screen';
import { useDeviceSize } from '../../utils/useDeviceSize';
import { SearchOutlined } from "@ant-design/icons";

export interface HandleFilterParams {
  tags?: string[];
  categoryId?: string;
}

export const MyCourseSearch: FunctionComponent<{}> = () => {
  const [textSearch, setTextSearch] = useState<string>('');
  const history = useHistory();
  const location = useLocation();
  const size = useDeviceSize();
  
  useEffect(()=>{
    const queryParamsObj: any = queryString.parse(location.search);
    if(queryParamsObj.textToSearch){
      setTextSearch(queryParamsObj.textToSearch);
    }else{
      setTextSearch('');
    }
  }, [location.search])
  const handleFilter = ({ tags = [], categoryId = "" }: HandleFilterParams) => {
    const paramsObj = {
      ...(tags.length > 0 && { tag: [...tags] }),
      ...(categoryId.length > 0 && { category: categoryId })
    };
    const qParams = queryString.stringify(paramsObj)
      ? "?" + queryString.stringify(paramsObj)
      : "";
    const pathPlusParams = `/myCourses/filter${qParams}`;
    history.push(pathPlusParams);
  };
  const handleSearch = (value: string) => {
    const paramsObj = {
      ...(value.length > 0 && { textToSearch: value })
    };
    const qParams = queryString.stringify(paramsObj)
      ? "?" + queryString.stringify(paramsObj)
      : "";
    const pathPlusParams = `/myCourses/filter${qParams}`;
    history.push(pathPlusParams);
  };
  return (
    <>
      <BackTop className={styles.backUpTop}>
        <span className={styles.link}>
          Volver arriba <CogIcon className={styles.icon} icon="Arrow-up-big" />
        </span>
      </BackTop>
      <div className={styles.container}>
        {size !== SIZES.PHONE ? <Calendar /> : null}
        <div className={styles.filterContainer}>
          <Input
            value={textSearch}
            prefix={<SearchOutlined className='search-icon' />}
            className={ctx(styles.search, styles.wrapper, 'custom-search')}
            onChange={(event) => {
              handleSearch(event.target.value.trim());
            }}
          />
          <CoursesFilter key="filter" onApply={handleFilter} />
        </div>
      </div>
    </>
  );
};
