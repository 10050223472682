import { Button, Table, TablePaginationConfig } from "antd";
import { ColumnsType } from "antd/es/table";
import { SorterResult } from "antd/es/table/interface";
import { FilterValue } from "antd/lib/table/interface";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { UserReportData } from "..";
import { selectCompanies } from "../../../../features/company/companySlice";
import { CogIcon } from "../../../common/CogIcon";
import { Status, StatusEnum } from "../../../common/Status";
import tableStyles from "../../../common/table.module.scss";
import { EmptyTable } from "../../common/EmptyTable";
import { DataType, exportToExcel, HeadersType } from "../../common/excelExport";
import styles from "./index.module.scss";
import { getTranslations } from "../../../../features/translations/translationsUtils";

const EXCEL_HEADERS: () => HeadersType = () => [
  getTranslations('REPORT_USER_NAME'),
  getTranslations('REPORT_STATUS'),
  getTranslations('REPORT_COMPANY'),
  getTranslations('REPORT_USER_TYPE'),
  getTranslations('REPORT_GROUP'),
  getTranslations('REPORT_GENDER'),
  getTranslations('REPORT_AGE'),
  getTranslations('REPORT_EMAIL'),
  getTranslations('REPORT_PHONE'),
];

interface Props {
  isLoading: boolean;
  companyId?: string;
  filteredUserReportList: UserReportData[];
}

export const UsersTable: React.FunctionComponent<Props> = ({
  isLoading,
  companyId,
  filteredUserReportList,
}) => {
  const [currentUserData, setCurrentUserData] = useState<UserReportData[]>([]);
  const companyDict = useSelector(selectCompanies);
  const columns: ColumnsType<UserReportData> = [
    {
      title: getTranslations('REPORT_USER_NAME'),
      dataIndex: "userName",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      ellipsis: true,
      sorter: (a: UserReportData, b: UserReportData) =>
        a.userName.localeCompare(b.userName),
      width: "15%",
    },
    {
      title: getTranslations('REPORT_STATUS'),
      dataIndex: "enabled",
      render: (status) => {
        return (
          <Status status={status ? StatusEnum.Enable : StatusEnum.Disable} />
        );
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a: UserReportData, b: UserReportData) =>
        a.enabled === b.enabled ? 0 : a.enabled > b.enabled ? 1 : -1,
    },
    {
      title: getTranslations('REPORT_COMPANY'),
      dataIndex: "companyName",
      sortDirections: ["descend", "ascend"],
      sorter: (a: UserReportData, b: UserReportData) =>
        a.companyName?.localeCompare(b.companyName),
    },
    {
      title: getTranslations('REPORT_USER_TYPE'),
      dataIndex: "userTypeName",
      sortDirections: ["descend", "ascend"],
      sorter: (a: UserReportData, b: UserReportData) =>
        a.userTypeName.localeCompare(b.userTypeName),
    },
    {
      title: getTranslations('REPORT_GROUP'),
      dataIndex: "groupName",
      sortDirections: ["descend", "ascend"],
      sorter: (a: UserReportData, b: UserReportData) =>
        a.groupName?.localeCompare(b.groupName),
    },
    {
      title: getTranslations('REPORT_GENDER'),
      dataIndex: "genderName",
      sortDirections: ["descend", "ascend"],
      sorter: (a: UserReportData, b: UserReportData) =>
        a.genderName?.localeCompare(b.genderName),
    },
    {
      title: getTranslations('REPORT_AGE'),
      dataIndex: "age",
      sortDirections: ["descend", "ascend"],
      sorter: (a: UserReportData, b: UserReportData) => a.age - b.age,
      width: "8%",
    },
    {
      title: getTranslations('REPORT_EMAIL'),
      dataIndex: "email",
      sortDirections: ["descend", "ascend"],
      sorter: (a: UserReportData, b: UserReportData) =>
        a.email?.localeCompare(b.email),
    },
    {
      title: getTranslations('REPORT_PHONE'),
      dataIndex: "phone",
      sortDirections: ["descend", "ascend"],
      sorter: (a: UserReportData, b: UserReportData) =>
        a.phone?.localeCompare(b.phone),
    },
  ];

  useEffect(() => {
    setCurrentUserData(filteredUserReportList);
  }, [filteredUserReportList]);

  const handleDownload = () => {
    const headers = [...EXCEL_HEADERS()];
    const data: DataType = currentUserData.map((user) => {
      return [
        user.userName,
        user.enabled ? "Habilitado" : "Deshabilitado",
        user.companyName,
        user.userTypeName,
        user.groupName,
        user.genderName,
        user.age.toString(),
        user.email,
        user.phone,
      ];
    });

    exportToExcel(
      headers,
      data,
      "Usuarios",
      `usuarios_${companyDict[companyId ?? ""].name}`
    );
  };

  const handleTableChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    _sorter: SorterResult<UserReportData> | SorterResult<UserReportData>[],
    extra: { currentDataSource: UserReportData[] }
  ) => setCurrentUserData(extra.currentDataSource);

  return (
    <>
      <div className={styles.tableHeader}>
        <div className={styles.title}>{getTranslations('REPORT_USERS_DETAILS')}</div>
        <Button
          className={styles.downloadButton}
          disabled={!companyId}
          ghost={true}
          onClick={handleDownload}
        >
          {getTranslations('REPORT_DOWNLOAD')} <CogIcon size="1.4em" color="#81858b" icon={"Download"} />
        </Button>
      </div>
      <Table<UserReportData>
        onChange={handleTableChange}
        locale={
          !companyId
            ? {
              emptyText: <EmptyTable />,
            }
            : undefined
        }
        loading={isLoading}
        className={tableStyles.table}
        rowClassName={() => tableStyles.row}
        rowKey="id"
        columns={columns}
        dataSource={companyId ? filteredUserReportList : []}
        pagination={{ position: ["bottomRight"], pageSize: 10 }}
        scroll={companyId ? { y: 372, x: "max-content" } : { y: 372 }}
      />
    </>
  );
};
