import { Button } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { selectGroupsDict } from '../../../../../features/groups/groupSlice';
import { LearningLabelsSingle, LearningType } from '../../../../../features/reports/learning/common/interfaces';
import { fetchEvaluationsResultsWithResponses, selectEvaluationsResultsWithResponses, selectEvaluationsResultsWithResponsesIsFetching } from '../../../../../features/reports/learning/evaluations/evaluationsResultsWithResponses';
import { selectStudents } from '../../../../../features/users/usersSlice';

import tableStyles from "../../../../common/table.module.scss";
import { ITableRecord } from './interfaces';
import { DataType, exportToExcel, HeadersType } from '../../../common/excelExport';
import { getTranslations } from '../../../../../features/translations/translationsUtils';

interface DownloadEvaluationDetailsProps {
  record: ITableRecord,
  survey?: boolean
}
const CONTENT_HEADER = 4;
const EVALUATION_HEADER = 5;
const SCORE_HEADER = 8;
const EXCEL_HEADERS: HeadersType = [
  'Empresa',
  'Usuario',
  'Estado de usuario',
  'Grupo',
  'CONTENT',
  'Nombre del CONTENT',
  'Completada',
  'Fecha de completación',
  'Calificación',
];
export const DownloadEvaluationDetails: FunctionComponent<DownloadEvaluationDetailsProps> = ({
  record,
  survey = false
}) => {
  const {
    usersIds,
    evaluationType,
    contentId,
    contentType,
    contentName,
  } = record;
  let {
    evaluationId,
    evaluation = '',
  } = record;
  if (survey) {
    evaluation = (record as any).survey || '';
    evaluationId = record.surveyId;
  }
  const [processData, setProcessData] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);
  const students = useSelector(selectStudents);
  const groups = useSelector(selectGroupsDict);
  const evaluationsResultsWithResponses = useSelector(selectEvaluationsResultsWithResponses);
  const evaluationsResultsWithResponsesIsFetching = useSelector(selectEvaluationsResultsWithResponsesIsFetching);
  const dispatch = useDispatch();
  const createFile = !downloading && processData && !evaluationsResultsWithResponsesIsFetching;
  const setProcess = downloading && !evaluationsResultsWithResponsesIsFetching;
  useEffect(() => {
    if (!createFile) {
      return;
    }
    const headers = [...EXCEL_HEADERS];
    headers[CONTENT_HEADER] = getTranslations(LearningLabelsSingle[contentType]);
    headers[EVALUATION_HEADER] = 'Instrumento';
    if (contentType === LearningType.lessons) {
      headers.splice(SCORE_HEADER, 1);
    }
    if (!evaluationsResultsWithResponses.evaluation) {
      return;
    }
    const { questions = [] } = evaluationsResultsWithResponses.evaluation;
    questions.forEach(question => {
      headers.push(question.title);
    });
    const data: DataType = [];
    evaluationsResultsWithResponses.results.forEach(resultWithResponses => {
      const { total, result, updatedAt, userId } = resultWithResponses;
      const user = students[userId];
      if (!user || user.company !== record.companyId) {
        return;
      }
      const group = groups[user.studentGroupId || ''];
      const row: string[] = [
        record.company,
        user.name,
        students[userId].enabled ? 'Habilitado' : 'Inhabilitado',
        group ? group.name : '',
        contentName,
        evaluation,
        'Si',
        moment(updatedAt).format('DD-MM-YYYY'),
      ];
      if (contentType !== LearningType.lessons) {
        row.push(`${total ? Math.round(result / total * 100) : 0} %`);
      }
      resultWithResponses.answers.forEach(answer => {
        const question = questions.find(question => question._id === answer.questionId);
        if (!question) {
          row.push('--');
          return;
        }
        const possibleAnswer = question.possibleAnswers.find(possibleAnswer => possibleAnswer._id === answer.answerId);
        if (!possibleAnswer) {
          row.push('--');
          return;
        }
        row.push(possibleAnswer.label);
      });
      data.push(row);
    });
    exportToExcel(headers, data, 'Data', `${record.company}_${contentName}`);
    setProcessData(false);
  }, [contentName, contentType, createFile, evaluation, evaluationsResultsWithResponses.evaluation, evaluationsResultsWithResponses.results, groups, record.company, record.companyId, students]);
  useEffect(() => {
    if (!setProcess) {
      return;
    }
    setProcessData(true);
    setDownloading(false);
  }, [setProcess]);
  if (!evaluationId) {
    return null;
  }
  return <div className={tableStyles.actions}>
    <Button
      loading={downloading}
      disabled={downloading}
      type="default"
      onClick={() => {
        dispatch(fetchEvaluationsResultsWithResponses({
          usersIds,
          parentId: contentId,
          evaluationId: evaluationId || '',
          evaluationType,
        }));
        setDownloading(true);
        setProcessData(false);
      }}
    >
      {getTranslations('REPORT_DOWNLOAD')}
    </Button>
  </div>
}