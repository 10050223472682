import React, {
  FunctionComponent,
  useState,
} from "react";

import { Alert, Button, List, message } from "antd";
import * as H from 'history';

import { MainLayout } from "../common/Layouts/Main";
import { ActionSuffixes, Prefixes } from "../prefixes";
import { useHistory } from "react-router-dom";
import { UsersList } from "./List";
import { getTranslations } from "../../features/translations/translationsUtils";
import { CogDrawer } from "../common/Drawer";
import { UploadFile } from "./UploadFile";

import styles from './students.module.scss';
import { BASE_URL } from "../../app/apiMiddleware";
import { Microservices } from "../../app/AllowedMicroservices";
import { fetchUsers, hideModal, hideModalWithDelay, selectDisplayModal, showModal } from "../../features/users/usersSlice";
import { useDispatch, useSelector } from "react-redux";
import { DialogPopUp } from "../common/DialogPopUp";

interface RenderOptions {
  history: H.History,
  toggleVisibleImport: Function;
}

interface IError {
  list: string[];
  row: string;
}

interface IRowErrors {
  [_id: string]: string[];
}

type ErrorList = IError[];

function renderOptions({
  history,
  toggleVisibleImport,
}: RenderOptions
) {
  return <>
    <Button
      onClick={() => {
        toggleVisibleImport();
        // setNewStudent({ group: "Students" });
      }}
      type="link"
    >
      {getTranslations('USERS_IMPORT')}
    </Button>
    <Button
      onClick={() => {
        history.push(`${Prefixes.user}${ActionSuffixes.new}`);
      }}
      type="primary"
    >
      {getTranslations('USERS_NEW')}
    </Button>
  </>;
}

interface IListItem {
  field: string;
  example: string;
}
const list: () => IListItem[] = () => [
  {
    field: getTranslations('USERS_EMAIL'),
    example: "xxxxxxx@xxxx.xx",
  },
  {
    field: getTranslations('USERS_NATIONAL_ID'),
    example: "xxxxxxxxxxxx",
  },
  {
    field: getTranslations('USERS_NATIONAL_ID_TYPE'),
    example: "CI o PASPORTE",
  },
  {
    field: getTranslations('USERS_FIRST_NAME'),
    example: "xxxxxxx",
  },
  {
    field: getTranslations('USERS_SECOND_NAME'),
    example: "xxxxxxx",
  },
  {
    field: getTranslations('USERS_LAST_NAME'),
    example: "xxxxxxx",
  },
  {
    field: getTranslations('USERS_SECOND_LAST_NAME'),
    example: "xxxxxxx",
  },
  {
    field: getTranslations('USERS_BIRTH_DATE'),
    example: "DD/MM/AAAA (tipo fecha)",
  },
  {
    field: getTranslations('USERS_GENDER'),
    example: "MASCULINO o FEMENINO",
  },
  {
    field: getTranslations('USERS_CELLPHONE'),
    example: "+00000000000 (incluye el código de país)",
  },
  {
    field: getTranslations('USERS_ADDRESS'),
    example: "xxxxxxxxx",
  },
];

export const Users: FunctionComponent = () => {
  const history = useHistory();
  const [visibleImport, setVisibleImport] = useState<boolean>(false);
  const [errors, setErrors] = useState<ErrorList>([]);
  const [errorsCount, setErrorsCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [objectName, setObjectName] = useState<string>("");
  const [currentAction, setCurrentAction] = useState<string>("");
  const isModalVisible = useSelector(selectDisplayModal);

  const toggleVisibleImport = () => {
    if (visibleImport) {
      setErrorsCount(0);
      setErrors([]);
    }
    setVisibleImport(!visibleImport);
  };
  const handleChange = (info: any) => {
    const { status } = info.file;
    if (status === "uploading") {
      setLoading(true);
      setErrors([]);
      setErrorsCount(0);
      setObjectName(getTranslations('USERS_IMPORT_STUDENTS'));
      setCurrentAction(getTranslations('USERS_IMPORT_STUDENTS_CREATED'));
      return;
    }
    if (status === "done") {
      setLoading(false);
      const {
        count,
        errors: errorsDict,
      }: {
        recordsCount: number;
        count: number;
        errors: IRowErrors;
      } = info.file.response;
      const errors: ErrorList = [];
      Object.keys(errorsDict).forEach((rowNumber) => {
        errors.push({
          list: errorsDict[rowNumber],
          row: rowNumber,
        });
      });
      setErrors(errors);
      setErrorsCount(count);
      if (errors.length === 0) {
        dispatch(showModal());
        dispatch(hideModalWithDelay());
        dispatch(fetchUsers());
        toggleVisibleImport();
      }
    } else if (status === "error") {
      setErrors([]);
      setErrorsCount(0);
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const renderLists = (errors: ErrorList, count: number) => {
    if (count === 0) {
      return (
        <React.Fragment>
          <div className={styles.infoText}>
            {getTranslations('USERS_IMPORT_DESCRIPTION')}
            <a
              href={`${BASE_URL[Microservices.COMPANY_SERVICE]
                }/../assets/user-template-import.xlsx`}
            >
              {getTranslations('USERS_IMPORT_DOWNLOAD')}
            </a>
          </div>
          <List<IListItem>
            className={styles.list}
            dataSource={list()}
            renderItem={(item, index) => {
              return (
                <List.Item key={index} className={styles.item}>
                  <div className={styles.field}>{item.field}</div>
                  <div className={styles.example}>{item.example}</div>
                </List.Item>
              );
            }}
          />
        </React.Fragment>
      );
    }
    return (
      <List<IError>
        className={styles.errorslist}
        dataSource={errors}
        renderItem={(item, index) => {
          const errorList = item.list.map((error, index) => {
            return (
              <span className={styles.error} key={index}>
                {error}
              </span>
            );
          });
          return (
            <List.Item key={index} className={styles.item}>
              <div className={styles.text}>
                {getTranslations('USERS_IMPORT_ERROR_1', [index + 2])}{" "}
              </div>
              <Alert
                className={styles.errorAlert}
                message={<React.Fragment>{errorList}</React.Fragment>}
                type="error"
              />
            </List.Item>
          );
        }}
      />
    );
  };

  return (
    <MainLayout
      title={getTranslations('USERS_TITLE')}
      rightTopOptions={renderOptions({ history, toggleVisibleImport })}
      breadcrumbs={[getTranslations('USERS_TITLE')]}

    >
      <DialogPopUp
        name={objectName}
        action={currentAction}
        visible={isModalVisible}
        onCancel={() => {
          dispatch(hideModal());
        }}
      />
      <UsersList />

      <CogDrawer
        loading={loading}
        visible={visibleImport}
        toggleVisible={toggleVisibleImport}
        title={getTranslations('USERS_IMPORT')}
        footer={
          <div>
            <Button
              type="default"
              onClick={toggleVisibleImport}
              disabled={loading}
            >
              {getTranslations('USERS_IMPORT_CANCEL')}
            </Button>
            &nbsp;&nbsp;
          </div>
        }
      >
        <UploadFile onHandleChange={handleChange} loading={loading} />
        {renderLists(errors, errorsCount)}
      </CogDrawer>
    </MainLayout>
  );
};
