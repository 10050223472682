import React, { FunctionComponent, useEffect } from "react";

import * as H from "history";
import { useDispatch } from "react-redux";
import { Router, Switch, Route } from "react-router-dom";

import { clearBreadcrumbs } from "../../features/breadcrumbs/breadcrumbsSlice";
import { ActionSuffixes, Prefixes } from "../prefixes";
import { Lessons } from './index';
import { NewLesson } from './new';
import { EditLesson } from './edit';

interface AdminRouterProps {
  history: H.History<any>;
}

const prefix = Prefixes.lesson;

export const LessonsRouter: FunctionComponent<AdminRouterProps> = ({
  history,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    return history.listen((location) => {
      dispatch(clearBreadcrumbs());
    });
  }, [dispatch, history]);
  return <Router history={history}>
    <Switch>
      <Route path={`${prefix}${ActionSuffixes.new}`} exact>
        <NewLesson />
      </Route>
      <Route path={`${prefix}/:id${ActionSuffixes.edit}`} exact>
        <EditLesson />
      </Route>
      <Route path={prefix}>
        <Lessons />
      </Route>
    </Switch>
  </Router>;
};