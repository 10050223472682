import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../app/AllowedMicroservices";
import { RootState } from "../../app/store";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { FetchStatus, ModalStatus } from "../common/enums";
import { IBoockedCourses, isCourseBlocked, IStudentCourse } from "./common";

interface homeCoursesState {
  current: IStudentCourse | null;
  list: IStudentCourse[];
  blockedCourses: IBoockedCourses;
  status: FetchStatus;
  modalStatus: ModalStatus;
}

const initialState: homeCoursesState = {
  current: null,
  list: [],
  blockedCourses: {},
  status: FetchStatus.Idle,
  modalStatus: ModalStatus.Hide,
};

export const homeCoursesSlice = createSlice({
  name: "homeCourses",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
      state.current = null;
    },
    fetched: (state, action: PayloadAction<IStudentCourse[]>) => {
      state.list = [];
      state.blockedCourses = {};
      state.current = null;
      action.payload.forEach((item) => {
        if(item.advancePercentage === 1){
          return;
        }
        if (
          item.progress?.courseProgress?.startedAt &&
          item.advancePercentage < 1 &&
          item.advancePercentage > (state.current?.advancePercentage || -1)
        ) {
          state.current = item;
        }
        state.list.push(item);
      });
      action.payload.forEach((item) => {
        state.blockedCourses[item.course._id] = isCourseBlocked(
          action.payload,
          item
          );
        });
      state.status = FetchStatus.Fetched;
    },
    error: (state) => {
      state.list = [];
      state.status = FetchStatus.Error;
    },
    showModal: (state) => {
      state.modalStatus = ModalStatus.Show;
    },
    hideModal: (state) => {
      state.modalStatus = ModalStatus.Hide;
    },
  },
});

export const {
  fetching,
  fetched,
  error,
  showModal,
  hideModal,
} = homeCoursesSlice.actions;

export const fetchStudentHomeCourses = () => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: "/student/studentHome?limit=10000",
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
    })
  );
};

export const selectStudentHomeCurrentCourse = (state: RootState) =>
  state.studentHomeCourses.current;
export const selectStudentHomeCourse = (index: number) => (state: RootState) =>
  state.studentHomeCourses.list[index];
export const selectStudentHomeCourseById = (courseId: string) => (state: RootState) =>
  state.studentHomeCourses.list.find(({ course }) => course._id === courseId);
export const selectStudentHomeCoursesIndexes = (state: RootState) =>
  Array.from(state.studentHomeCourses.list.keys()).filter(
    (index: number) =>
      state.studentHomeCourses.list[index].course._id !==
      state.studentHomeCourses.current?.course._id
  ).slice(0, 3);
export const selectIsStudentHomeCourseBlocked = (id: string) => (
  state: RootState
) => state.studentHomeCourses.blockedCourses[id];
export const selectModalStatus = (state: RootState) =>
  state.studentHomeCourses.modalStatus;
export const selectStudentHomeCourses = (state: RootState) =>
  state.studentHomeCourses.list;
export const selectAreStudentHomeCoursesFetching = (state: RootState) =>
  state.studentHomeCourses.status === FetchStatus.Fetching;

export default homeCoursesSlice.reducer;
