import React, { FunctionComponent, useEffect } from 'react';
import {
  Input,
  Card,
  Col,
  Row,
  Divider,
} from 'antd';
import moment from 'moment';
import cls from 'classnames';
import './searchOverrides.scss';
import { SIZES } from '../../common/screen';
import { useHistory } from 'react-router-dom';
import styles from './index.module.scss';
import {
  selectKnowledgeBaseCategories,
  fetchKnowledgeBasesCategories,
} from '../../../features/students/knowledgeBaseSlice';
import {
  fetchContentCategories,
  selectContentCategoriesDict,
} from '../../../features/contentCategory/contentCategorySlice';
import {
  SEARCH_INPUT_PARAM_NAME,
  CATEGORY_PARAM_NAME,
} from './KnowledgeBaseSearch';
import { useDispatch, useSelector } from "react-redux";
import { useDeviceSize } from '../../utils/useDeviceSize';
import { analytics, SelectKBCategoryGAProps } from '../../../services/analytics';
import { SearchOutlined } from '@ant-design/icons';
import { getTranslations } from '../../../features/translations/translationsUtils';

interface KnowledgeBaseProps { }

interface CategoryProps {
  title: string,
  articlesCount: number,
  lastUpdate: string,
}

const CategoryCard: FunctionComponent<CategoryProps> = ({
  title,
  articlesCount,
  lastUpdate,
}) => {
  return (
    <Card className={styles.categoryCard} bodyStyle={{ padding: 0 }}>
      <div className={styles.top}>
        <div className={styles.content}>
          <span>{title}</span>
        </div>
      </div>
      <div className={styles.divider}>
        <Divider style={{ margin: 0 }} />
      </div>
      <div className={styles.bottom}>
        <div className={styles.articlesMeta}>{getTranslations('KNOWLEDGE_ARTICLES_FOUND', [articlesCount])}</div>
        <span className={styles.date}>
          <div>{getTranslations('KNOWLEDGE_LAST_UPDATE')}</div>
          <div>{lastUpdate}</div>
        </span>
      </div>
    </Card>
  );
}

export const KnowledgeBase: FunctionComponent<KnowledgeBaseProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const size = useDeviceSize();

  useEffect(() => {
    dispatch(fetchKnowledgeBasesCategories());
    dispatch(fetchContentCategories());
  }, [dispatch]);

  const contentCategoryDict = useSelector(selectContentCategoriesDict);
  const knowledgeCategories = useSelector(selectKnowledgeBaseCategories);

  const categories = knowledgeCategories.map(item => {
    const category = contentCategoryDict[item.categoryId];
    const name = category ? category.name : '';
    return {
      ...item,
      name,
    };
  });

  const handleSearch = (value: any, event: any) => {
    const pathPlusParams = `/knowledgeBases/search?${SEARCH_INPUT_PARAM_NAME}=${value}`;
    history.push(pathPlusParams);
  };

  const handleSearchByCategory = ({ categoryId }: { categoryId: string }) => {
    const pathPlusParams = `/knowledgeBases/search?${CATEGORY_PARAM_NAME}=${categoryId}`;
    history.push(pathPlusParams);
  };

  const selectKnowledgeBaseCategoryAnalytics = ({ category }: SelectKBCategoryGAProps) => {
    analytics.selectKnowledgeBaseCategory({ category: category });
  }

  return (
    <React.Fragment>
      <div className={styles.masthead}>
        <div className={cls(styles.header, {
          [styles.mobile]: size === SIZES.PHONE,
          [styles.tablet]: size === SIZES.TABLET,
        })}>
          <div className={styles.titleAndDescription}>
            <div className={cls(styles.title, {
              [styles.desktop]: size === SIZES.DESKTOP,
            })}>
              {getTranslations('KNOWLEDGE_DASHBOARD_TITLE')}
            </div>
            <span className={styles.description}>{getTranslations('KNOWLEDGE_DASHBOARD_SUBTITLE')}</span>
          </div>
          <div className={styles.search}>
            <Input
              placeholder={getTranslations('STUDENT_SEARCH_IN_TEURONA')}
              prefix={<SearchOutlined className='search-icon' />}
              className={cls(styles.searchInput, 'custom-search')}
              onChange={(event) => {
                handleSearch(event.target.value.trim(), event);
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles.categoriesSection}>
        <Row gutter={[22, 22]} >
          {categories.map(category =>
            <Col className="gutter-row" key={category.categoryId}
              span={cls({
                8: size === SIZES.DESKTOP,
                12: size === SIZES.TABLET,
                24: size === SIZES.PHONE,
              })}
            >
              <span onClick={() => {
                handleSearchByCategory({ categoryId: category.categoryId })
                selectKnowledgeBaseCategoryAnalytics({ category });
              }}>
                <CategoryCard
                  title={category.name}
                  articlesCount={category.contentCount}
                  lastUpdate={moment(category.lastUpdate).format("DD/MM/YYYY")}
                />
              </span>
            </Col>
          )}
        </Row>
      </div>
    </React.Fragment>
  );
};
