import { Drawer } from "antd";
import ctx from "classnames";
import moment from "moment";
import React, { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCompanyScheduledNotifications,
  INotification,
  selectScheduledNotifications,
  selectScheduledNotificationsIsFetching,
} from "../../features/notifications/notificationsScheduleSlice";
import { CogIcon } from "../common/CogIcon";
import LoadingOverlay from "../common/LoadingOverlay";
import { NotificationsTypes } from "./Manager";
import styles from "./scheduledNotifications.module.scss";
import { getTranslations } from "../../features/translations/translationsUtils";

interface scheduledNotificationsProps {
  companyId: string;
  close: () => void;
}

const getNotificationIcon = (notification: INotification) => {
  if (notification.type === NotificationsTypes.PUSH) {
    return "politicas";
  }
  if (notification.type === NotificationsTypes.SMS) {
    return "sms";
  }
  return "Mail";
};

export const ScheduledNotifications: FunctionComponent<scheduledNotificationsProps> =
  ({ companyId, close }) => {
    const fetching = useSelector(selectScheduledNotificationsIsFetching);
    const notifications = useSelector(selectScheduledNotifications);
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(fetchCompanyScheduledNotifications({ companyId }));
    }, [companyId, dispatch]);

    const sortByDate = (a: INotification, b: INotification) => {
      const date1 = new Date(a.scheduledDateTime).getTime();
      const date2 = new Date(b.scheduledDateTime).getTime();
      return date1 - date2;
    };

    return (
      <Drawer
        title={getTranslations('NOTIFICATION_SCHEDULED')}
        visible={true}
        onClose={close}
        className={styles.drawer}
        placement="left"
      >
        {fetching ? (
          <LoadingOverlay spinning inline height={"100vh"} />
        ) : (
          <div className={ctx(styles.container)}>
            {notifications.length === 0 ? (
              <div className={styles.none}>
                {getTranslations('NOTIFICATION_NO_SCHEDULED')}
              </div>
            ) : (
              <div>
                {notifications
                  .slice()
                  .filter(
                    (item) =>
                      moment(item.scheduledDateTime, "YYYY-MM-DD").diff(
                        moment().startOf("day")
                      ) >= 0
                  )
                  .sort(sortByDate)
                  .map((notification) => {
                    return (
                      <div
                        key={notification.scheduleUid}
                        className={styles.notification}
                      >
                        <div className={styles.iconContainer}>
                          <div className={styles.background}>
                            <CogIcon
                              className={styles.icon}
                              icon={getNotificationIcon(notification)}
                            />
                          </div>
                        </div>
                        <div className={styles.content}>
                          <div className={styles.title}>
                            {notification.title || getTranslations('NOTIFICATION_MESSAGE_WITHOUT_TITLE')}
                          </div>
                          <div className={styles.body}>{notification.body}</div>
                          <div className={styles.date}>
                          {getTranslations('NOTIFICATION_SCHEDULED_TO')}{" "}
                            {moment(notification.scheduledDateTime).format(
                              "DD/MM/Y - HH:mm"
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        )}
      </Drawer>
    );
  };
