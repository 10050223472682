import React, { FunctionComponent } from 'react';

import styles from './miniFooter.module.scss';

import miniLogo from '../../../../assets/teurona-mini.png';
import { useHistory } from 'react-router-dom';
import { getTranslations } from '../../../../features/translations/translationsUtils';
import { Microservices } from '../../../../app/AllowedMicroservices';
import { BASE_URL } from '../../../../app/apiMiddleware';

export const MinFooter: FunctionComponent = () => {
  const history = useHistory();

  const goTo = (path?: string) => () => {
    if (path) {
      history.push(`/${path}`)
    }
  }
  const goToURL = (url: string) => () => {
    window.open(url, 'blank');
  }
  return <div className={styles.container}>
    <div className={styles.logo}>
      <img src={miniLogo} alt="Teurona" />
    </div>
    <div className={styles.actions}>
      <div onClick={goToURL('https://teurona.com')} className={styles.action}>{getTranslations('HOME_ABOUT')}</div>
      <div onClick={goTo('support')} className={styles.action}>{getTranslations('HOME_SUPPORT')}</div>
      <div onClick={goToURL(`${BASE_URL[Microservices.COMPANY_SERVICE]
                }/../assets/privacy-teurona.pdf`)} className={styles.action}>{getTranslations('HOME_PRIVACY')}</div>
      <div onClick={goToURL(`${BASE_URL[Microservices.COMPANY_SERVICE]
                }/../assets/legal-teurona.pdf`)} className={styles.action}>{getTranslations('HOME_LEGAL')}</div>
      <div onClick={goToURL('https://teurona.com/contact')}className={styles.action}>{getTranslations('HOME_CONTACT')}</div>
    </div>
  </div>
};