import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import ctx from 'classnames';
import { Card } from 'antd';

import {
    selectStudentCourseInfo
} from '../../../features/students/courseSlice';

import { CogIcon } from '../../common/CogIcon';
import styles from './courseInfo.module.scss';

interface StudentCourseInfoProps { }
const convertToList = (content: string | undefined) => {
    if (!content) {
        return null;
    }
    const rows = content.split("\n");
    return <ul className={styles.list}>
        {rows.map((row, key) => <li className={styles.item} key={key}>{row}</li>)}
    </ul>
}
const convertToParagraph = (content: string | undefined) => {
    if (!content) {
        return null;
    }
    const rows = content.split("\n");
    return rows.map((row, key) => <p className={styles.item} key={key}>{row}</p>)
}
export const StudentCourseInfo: FunctionComponent<StudentCourseInfoProps> = () => {
    const info = useSelector(selectStudentCourseInfo);

    const hasContent = !!info.objectives || !!info.evaluationSystem || !!info.additionalInfo;
    if (!hasContent) {
        return null;
    }

    const objectives = info.objectives ? <div className={styles.container}>
        <div className={styles.firstColumn}>
            <CogIcon className={styles.icon} icon="Flag" />
        </div>
        <div className={styles.secondColumn}>
            <div className={styles.title}>Objetivos del curso</div>
            <div className={styles.content}>{convertToList(info.objectives)}</div>
        </div>
    </div> : null;
    const additionalInfo = info.additionalInfo ? <div className={styles.container}>
        <div className={styles.firstColumn}>
            <CogIcon className={styles.icon} icon="Attention" />
        </div>
        <div className={styles.secondColumn}>
            <div className={styles.title}>Información adicional</div>
            <div className={styles.content}>{convertToParagraph(info.additionalInfo)}</div>
        </div>
    </div> : null;
    const evaluationSystem = info.evaluationSystem ? <div className={styles.container}>
        <div className={styles.firstColumn}>
            <CogIcon className={styles.icon} icon="Check" />
        </div>
        <div className={styles.secondColumn}>
            <div className={styles.title}>Sistema de evaluación</div>
            <div className={styles.content}>{convertToParagraph(info.evaluationSystem)}</div>
        </div>
    </div> : null;
    return <Card className={ctx(styles.card)}>
        <div className={styles.mainTitle}>Guía de estudios</div>
        {objectives}
        {additionalInfo}
        {evaluationSystem}
    </Card>
}