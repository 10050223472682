import React, { useEffect, useState } from "react";

import { Button, Alert, Switch, Form, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchEvaluations,
  selectEvaluationsDict,
  selectQuizzes,
} from "../../features/evaluation/evaluationsSlice";
import {
  MediaTypes, VideoTypes,
} from "../../features/lesson/lessonSlice";
import { selectVideoStatus, VideoStatus } from '../../features/lesson/videoStatus';
import { StepProps } from "./commonInterfaces";
import { LinkQuizToVideo } from "./LinkQuizToVideo";

import styles from "./form.module.scss";
import formStyles from "../common/form.module.scss";
import { getTranslations } from "../../features/translations/translationsUtils";

export const StepOne2: React.FunctionComponent<StepProps> = ({
  newLesson,
  fileType,
  setNewLesson,
  form,
}) => {
  const hasAdditionalInfoOpened = !!newLesson.evaluation;
  const [quizOpened, setQuizOpened] = useState(hasAdditionalInfoOpened);
  const [openLinkVideo, setOpenLinkVideo] = useState<boolean>(false);
  const evaluationsDict = useSelector(selectEvaluationsDict);
  const videoStatus = useSelector(selectVideoStatus);
  const quizzes = useSelector(selectQuizzes);
  const dispatch = useDispatch();

  const handleQuizUpdate = (evaluationId: string) =>
    setNewLesson({
      ...newLesson,
      evaluation: evaluationId,
      quizCuePoints: [],
    });
  const handleQuizDelete = () => {
    setNewLesson({
      ...newLesson,
      evaluation: undefined,
      quizCuePoints: [],
    });
  }

  useEffect(() => {
    dispatch(fetchEvaluations());
  }, [dispatch]);
  const media = newLesson.media[0] || {};
  const quiz = <>
    {fileType === MediaTypes.VIDEO && media.videoType !== VideoTypes.YOUTUBE && videoStatus !== VideoStatus.available ?
      <Alert
        message={getTranslations('LESSON_LOAD_VIDEO_FOR_QUIZ')}
        type="warning"
      /> : null
    }
    <Form.Item
      label={getTranslations('LESSON_SELECT_QUIZ')}
      name={"evaluation"}
      rules={[{ required: false, message: getTranslations('GENERAL_REQUIRED') }]}
      initialValue={newLesson?.evaluation ? newLesson?.evaluation?.toString() : null}
    >
      <Select
        placeholder={getTranslations('LESSON_SELECT_QUIZ')}
        style={{ width: "100%" }}
        onChange={(value) => {
          handleQuizUpdate(`${value}`);
        }}
      >
        {quizzes.map((id) => {
          return (
            <Select.Option key={id} value={id} className={styles.option}>
              {evaluationsDict[id].title} <span className={styles.questionCount}>({getTranslations('LESSON_QUESTION_COUNT', [evaluationsDict[id].questions.length])})</span>
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
    {fileType === MediaTypes.VIDEO && media.videoType !== VideoTypes.YOUTUBE ? (
      <Button
        disabled={
          !newLesson?.media[0] ||
          !newLesson?.media[0].videoInfo?.duration ||
          !newLesson.evaluation
        }
        onClick={() => {
          setOpenLinkVideo(true);
        }}
        type="text"
      >
        {getTranslations('LESSON_LINK_QUIZ')}
      </Button>
    ) : null}
    {fileType === MediaTypes.VIDEO &&
      openLinkVideo &&
      newLesson?.media &&
      newLesson ? (
      <LinkQuizToVideo
        onClose={() => {
          setOpenLinkVideo(false);
        }}
        open={openLinkVideo}
        setNewLesson={setNewLesson}
        lesson={newLesson}
        videoId={newLesson?.media[0]?.url}
        quizId={newLesson.evaluation}
      />
    ) : null}
  </>;
  return (
    <>
      <div className={styles.switchContainer}>
        <span className={formStyles.sectionTitle}>{getTranslations('LESSON_QUIZ')}</span>
        <Switch
          checked={quizOpened}
          onChange={(value) => {
            if (!value) {
              form.setFieldValue('evaluation', null);
              handleQuizDelete();
            }
            setQuizOpened(value);
          }}
        />
      </div>
      {quizOpened ? quiz : null}
    </>
  );
};
