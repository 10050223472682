import { Col, Row } from "antd";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompanies, selectCompanies } from "../../../features/company/companySlice";
import { fetchContentCategories, selectContentCategoriesDict } from "../../../features/contentCategory/contentCategorySlice";
import { fetchContents, selectContents, selectContentsDict, IContent } from "../../../features/knowledgeBase/contentSlice";
import { fetchKnowledgeVisits, selectKnowledgeBaseVisits } from "../../../features/reports/knowledgeBase/viewKnowledgeBase";
import { fetchStudents } from "../../../features/users/usersSlice";
import { AnalyticsViewReport } from "../common/AnalyticsViewReport";
import { ReportTypes, sectionTitles } from "../MenuAndRoutes";
import { useKBDataIsFetching } from "./common/useDataIsFetching";
import { Indicators } from "./Indicators";
import { StudentsViewingContents } from "./StudentsViewing";
import { KnowledgeBaseTable } from "./Table";
import { TopCategoriesWithMostContent } from "./Top";
import { TopCompaniesWithMostVisitedContent } from "./Top/TopCompanies";

export interface IContentExtended extends IContent {
  categoryName: string
  companyName: string
  viewPercentage: number,
};

export interface CompanyWithContentVisits {
  companyId: string,
  companyName: string,
  contentVisitsCount: number;
  contentVisitsIds: Set<string>
  visitPercentage: number,
  visitorUserIds: Set<string>
}

interface CompaniesWithContentVisits {
  [companyId: string]: CompanyWithContentVisits
}

interface KnowledgeBaseVisitorData {
  visitorIds: Set<string>
  visitorsCount: number,
}
interface VisitorsPerKB {
  [knowledgeBaseId: string]: KnowledgeBaseVisitorData,
}

interface KnowledgeBaseReportsProps {
  companyId: string
}
export const KnowledgeBaseReports: FunctionComponent<KnowledgeBaseReportsProps> = ({ companyId }) => {
  const contentsIdsList = useSelector(selectContents);
  const contentDict = useSelector(selectContentsDict);
  const companies = useSelector(selectCompanies);
  const categories = useSelector(selectContentCategoriesDict);
  const [list, setList] = useState<IContentExtended[]>([]);
  const dataIsFetching = useKBDataIsFetching();
  const [companiesWithVisits, setCompaniesWithVisits] = useState<CompanyWithContentVisits[]>([]);
  const contentVisits = useSelector(selectKnowledgeBaseVisits);
  const [activeKnowledgeBasesCount, setActiveKnoledgeBasesCount] = useState<number>(0);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchContentCategories());
    dispatch(fetchContents());
    dispatch(fetchCompanies())
    dispatch(fetchKnowledgeVisits())
    dispatch(fetchStudents());
  }, [dispatch]);


  useEffect(() => {
    if (dataIsFetching) {
      return;
    }

    const visitorsPerKnowledgeBase = contentVisits.reduce((acc, current) => {
      if (!acc[current.knowledgeBaseId]) {
        acc[current.knowledgeBaseId] = {
          visitorIds: new Set(),
          visitorsCount: 0,
        }
      }
      acc[current.knowledgeBaseId].visitorIds.add(current.userId);
      acc[current.knowledgeBaseId].visitorsCount += 1;
      return acc;
    }, {} as VisitorsPerKB);

    const contentsList: IContentExtended[] = contentsIdsList.map((contentId) => {
      const company = companies[contentDict[contentId].companyId as string];
      const visitorsCount = (visitorsPerKnowledgeBase[contentId] || { visitorsCount: 0 }).visitorsCount;
      const allUsersCountInCompany = company.activeUsersCount + (company.inactiveUsersCount || 0);
      return {
        ...contentDict[contentId],
        categoryName: categories[contentDict[contentId].category as string]?.name,
        companyName: company?.name,
        viewPercentage: allUsersCountInCompany > 0 ? ((100 * visitorsCount) / allUsersCountInCompany) : 0,
      }
    });
    const contentOfCurrentCompany = companyId ? contentsList.filter(item => item.companyId === companyId) : contentsList;
    setList(contentOfCurrentCompany);
  }, [dataIsFetching, contentDict, contentsIdsList, categories, companies, contentVisits, companyId]);

  useEffect(() => {
    const listDict: { [contentId: string]: IContentExtended } = {};
    list.forEach((item) => {
      listDict[item._id] = item;
    });
    const contentIdsCountPerCompanyOverall = list.reduce((acc, current) => {
      if (!acc[current.companyId]) {
        acc[current.companyId] = new Set();
      }
      acc[current.companyId].add(current._id);
      return acc;
    }, {} as { [companyId: string]: Set<string> });

    const companiesWithVisits = contentVisits.reduce((acc, current) => {
      const knowledgeBaseItem = listDict[current.knowledgeBaseId];
      if (!knowledgeBaseItem) {
        return acc;
      }
      if (!acc[knowledgeBaseItem.companyId]) {
        acc[knowledgeBaseItem.companyId] = {
          companyId: knowledgeBaseItem.companyId,
          contentVisitsCount: 0,
          contentVisitsIds: new Set(),
          companyName: knowledgeBaseItem.companyName,
          visitPercentage: 0,
          visitorUserIds: new Set(),
        };
      }
      acc[knowledgeBaseItem.companyId].contentVisitsCount += current.hits;
      acc[knowledgeBaseItem.companyId].contentVisitsIds.add(current.knowledgeBaseId);
      acc[knowledgeBaseItem.companyId].visitorUserIds.add(current.userId);
      return acc;
    }, {} as CompaniesWithContentVisits);

    let activeKnowledgeBasesCountTotal = 0;

    for (const companyId in companiesWithVisits) {
      const totalCount = contentIdsCountPerCompanyOverall[companyId].size;
      const activeKnowledgeBasesCountAtThisCompany = companiesWithVisits[companyId].contentVisitsIds.size;
      activeKnowledgeBasesCountTotal += companiesWithVisits[companyId].contentVisitsIds.size;
      companiesWithVisits[companyId].visitPercentage = activeKnowledgeBasesCountAtThisCompany > 0 ? ((100 * activeKnowledgeBasesCountAtThisCompany) / totalCount) : 0
    }
    setActiveKnoledgeBasesCount(activeKnowledgeBasesCountTotal);
    setCompaniesWithVisits(Object.values(companiesWithVisits));
  }, [dataIsFetching, contentVisits, list]);

  return (
    <>
      <AnalyticsViewReport reportName={sectionTitles()[ReportTypes.KnowledgeBase]} />
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <Indicators list={list} activeKnowledgeBasesCount={activeKnowledgeBasesCount} />
        </Col>
        <Col span={12}>
          <StudentsViewingContents companiesWithVisits={companiesWithVisits} />
        </Col>
        <Col span={12}>
          <TopCategoriesWithMostContent topWhat={5} list={list} />
        </Col>
        <Col span={12}>
          <TopCompaniesWithMostVisitedContent topWhat={5} companiesWithVisits={companiesWithVisits} />
        </Col>
        <Col span={24}>
          <KnowledgeBaseTable list={list} />
        </Col>
      </Row>
    </>
  )
};
