import React, { FunctionComponent } from "react";

import styles from "./completenessIndicator.module.scss";
import { CogIcon } from "../../common/CogIcon";

interface CompletenessIndicatorProps {
  progress: number;
  loading?: boolean;
}
export const CompletenessIndicator: FunctionComponent<CompletenessIndicatorProps> = ({
  progress,
  loading = false,
}) => {
  let icon = <div className={styles.unfinishedIcon} />;
  if (loading) {
    icon = <div><CogIcon
      className={styles.icon}
      color="#4376F9"
      icon="clock"
    /></div>
  } else if (progress === 1) {
    icon = <div><CogIcon
      className={styles.icon}
      color="#27AE60"
      icon="Check-circle"
    /></div>
  }
  return (
    <div className={styles.indicator}>
      {icon}
    </div>
  );
};
