import React, { FunctionComponent } from 'react';

import cls from 'classnames';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getCognitoUserData } from '../../features/auth/authSlice';
import { useDeviceSize } from '../utils/useDeviceSize';
import { SIZES } from '../common/screen';

import styles from './profilePill.module.scss';

import defaultImg from '../../assets/profile/profile-placeholder.png';
import { getTranslations } from '../../features/translations/translationsUtils';

interface ProfilePillProps { }

export const ProfilePill: FunctionComponent<ProfilePillProps> = () => {
  const history = useHistory();
  const cognitoUserData = useSelector(getCognitoUserData);
  const size = useDeviceSize();
  const isMobile = size === SIZES.PHONE;

  return <div className={cls(styles.mainWrapper, {
    [styles.mobile]: isMobile,
  })} onClick={() => {
    history.push('/my-profile/profile')
  }}>
    <div className={styles.imageWrapper}>
      <img src={cognitoUserData?.picture || defaultImg} className={cls(styles.image)} alt="thumbnail"></img>
    </div>
    <div className={styles.profileTitle}>{getTranslations('HEADER_PROFILE')}</div>
  </div>;
}
