import { IReportValue } from "../../../../features/reports/common";
import { getTranslations } from "../../../../features/translations/translationsUtils";

export const buildTooltipsCallbacks = ({
  titleCallback,
  labelCallback,
}: {
  titleCallback: any;
  labelCallback: any;
}) => {
  const tooltipsCallbackObj = {
    tooltips: {
      callbacks: {},
    },
  };

  if (titleCallback) {
    tooltipsCallbackObj.tooltips.callbacks = {
      ...tooltipsCallbackObj.tooltips.callbacks,
      title: titleCallback,
    };
  }

  if (labelCallback) {
    tooltipsCallbackObj.tooltips.callbacks = {
      ...tooltipsCallbackObj.tooltips.callbacks,
      label: labelCallback,
    };
  }

  return tooltipsCallbackObj;
};

export const fillWithEmptyDataIfNeeded = (
  data: IReportValue[],
  topWhat: number
): IReportValue[] => {
  const results = [...data];
  for (let i = data.length; i < topWhat; i++) {
    results.push({
      key: "",
      label: getTranslations('REPORT_NO_DATA'),
      value: 0,
    });
  }
  return results.slice(0, topWhat);
};
