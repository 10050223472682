import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../app/AllowedMicroservices";
import { RootState } from "../../app/store";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { FetchStatus } from "../common/enums";

interface surveysTakenSliceState {
  status: FetchStatus;
  answeredCampaigns: string[];
}

interface ParentSurveyIds {
  parentId: string,
  evaluationId?: string,
}

const initialState: surveysTakenSliceState = {
  answeredCampaigns: [],
  status: FetchStatus.Idle,
};

export const surveysTakenSlice = createSlice({
  name: "surveysTakenSlice",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
      state.answeredCampaigns = [];
    },
    fetched: (state, action: PayloadAction<ParentSurveyIds[]>) => {
      state.status = FetchStatus.Fetched;
      state.answeredCampaigns = action.payload.map(value => value.parentId);
    },
    error: (state, { payload }: PayloadAction<string>) => {
      state.status = FetchStatus.Error;
    },
    addSurveyTaken: (state, action: PayloadAction<string>) => {
      state.answeredCampaigns.push(action.payload);
    }
  },
});

export const {
  fetching,
  fetched,
  error,
  addSurveyTaken,
} = surveysTakenSlice.actions;

export const fetchSurveysTaken = (
  parentSurveyIds: ParentSurveyIds[]
) => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: `/evaluation/results/surveysTaken`,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.POST,
      microservice: Microservices.EVALUATION_SERVICE,
      authenticated: true,
      data: {
        parentSurveyIds
      },
    })
  );
};

export const selectSurveysTakenFetching = () => (state: RootState) =>
  state.surveysTaken.status === FetchStatus.Fetching;
export const selectWasSurveyTaken = (campaignId: string) => (
  state: RootState
) => {
  const surveyTakenSet = new Set(state.surveysTaken.answeredCampaigns);
  return surveyTakenSet.has(campaignId);
};

export default surveysTakenSlice.reducer;
