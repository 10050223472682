import React, { useEffect, useRef, useState } from 'react';
import 'moment/locale/es';
import locale from 'antd/es/date-picker/locale/es_ES';


import {
  Button,
  Divider,
  Menu,
  Dropdown,
  Select,
  Form,
  Switch,
  Modal,
  Calendar,
} from 'antd';
import moment from 'moment';
import cls from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { CogIcon } from '../common/CogIcon';
import {
  ICourse,
  selectCoursesDict,
} from "../../features/courses/coursesSlice";
import {
  selectCompany
} from "../../features/company/companySlice";
import { ICourseRules, IProgram } from "../../features/program/programSlice";
import { setMessageError } from '../../features/message/messageSlice';
import { DATE_FORMAT } from './Form';
import { selectCategoriesDict } from '../../features/category/categorySlice';

import styles from './form.module.scss';
import formStyles from "../common/form.module.scss";
import { HeaderRender } from 'antd/lib/calendar/generateCalendar';
import { getTranslations } from '../../features/translations/translationsUtils';

export type CourseField = 'courses' | 'teuronaCourses';

interface CoursesSelectedProps {
  selectedCourses: string[],
  setProgram: Function,
  program?: IProgram,
  coursesList: ICourse[],
  coursesField: CourseField;
}

interface CourseSelectedProps {
  courseId: string,
  courseIndex: number,
  remove: Function,
  setProgram: Function,
  program?: IProgram,
  fieldName: number,
  coursesField: CourseField;
}

interface ICourseRule {
  startDate: Date | null | moment.Moment,
  endDate?: Date | null,
  hasPredecessor: boolean,
  predecessor: string[],
  priority: number,
  endUndefined: boolean,
}

export const PRIORITIES = () => ([
  {
    value: 1,
    label: 1,
    text: getTranslations('PROGRAM_COURSE_HIGH_PRIORITY'),
  },
  {
    value: 2,
    label: 2,
    text: getTranslations('PROGRAM_COURSE_MEDIUM_PRIORITY'),
  },
  {
    value: 3,
    label: 3,
    text: getTranslations('PROGRAM_COURSE_LOW_PRIORITY'),
  },
]);

interface CourseSelectedFormProps {
  courseId: string,
  courseIndex: number,
  setProgram: Function,
  program?: IProgram,
  coursesField: CourseField,
}

interface DeleteHandlerProps {
  program?: IProgram
  fieldName: number,
  setProgram: Function,
  onRemove: Function,
  courseIndex: number,
  onDeleteRejected: Function,
  coursesField: CourseField,
}
const getCoursesRulesField = (coursesField: CourseField) => {
  return coursesField === 'courses' ? `coursesRules` : 'teuronaCoursesRules';
};

const onDeleteHandler = ({ program, fieldName, setProgram, onRemove, onDeleteRejected, coursesField }: DeleteHandlerProps) => {
  const coursesRulesField = getCoursesRulesField(coursesField);
  let currentCourses = (program?.[coursesField]) ? program[coursesField] : [];
  currentCourses = [...currentCourses];
  const courseIdToDelete = currentCourses[fieldName];
  let currentCoursesRules = (program?.[coursesRulesField]) ? program[coursesRulesField] : [];
  currentCoursesRules = [...currentCoursesRules];
  const precededCoursesIds: string[] = [];
  currentCoursesRules.forEach((courseRule, index) => {
    let predecesorId = '';
    if (Array.isArray(courseRule.predecessor)) {
      predecesorId = courseRule.predecessor[0];
    } else {
      predecesorId = courseRule.predecessor;
    }
    if (predecesorId === courseIdToDelete) {
      precededCoursesIds.push(currentCourses[index])
    }
  });
  if (precededCoursesIds.length > 0) {
    onDeleteRejected(getTranslations('PROGRAM_COURSE_ERROR_1'));
    return;
  }
  currentCoursesRules.splice(fieldName, 1);
  currentCourses.splice(fieldName, 1);
  setProgram({ ...program, [coursesField]: [...currentCourses], [coursesRulesField]: [...currentCoursesRules] } as IProgram);
  onRemove(fieldName);
}

const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

const renderCalendarHeader: HeaderRender<moment.Moment> = ({ value, onChange }) => {
  const start = 0;
  const end = 12;
  const monthOptions = [];

  const current = value.clone();
  const localeData = value.localeData();
  const months = [];
  for (let i = 0; i < 12; i++) {
    current.month(i);
    months.push(capitalizeFirstLetter(localeData.months(current)));
  }

  for (let i = start; i < end; i++) {
    monthOptions.push(
      <Select.Option key={i} value={i} className="month-item">
        {months[i]}
      </Select.Option>,
    );
  }

  const year = value.year();
  const month = value.month();
  const options = [];
  for (let i = 2021; i < year + 10; i += 1) {
    options.push(
      <Select.Option key={i} value={i} className="year-item">
        {i}
      </Select.Option>,
    );
  }
  return (
    <div className={styles.calendarHeader}>
        <Select
          className={styles.selectNoBorders}
          size="small"
          dropdownMatchSelectWidth={false}
          value={month}
          onChange={newMonth => {
            const now = value.clone().month(newMonth);
            onChange(now);
          }}
        >
          {monthOptions}
        </Select>
        <Select
          className={cls(styles.selectNoBorders, styles.yearSelect)}
          size="small"
          dropdownMatchSelectWidth={false}
          value={year}
          onChange={newYear => {
            const now = value.clone().year(newYear);
            onChange(now);
          }}
        >
          {options}
        </Select>
    </div>
  );
};
interface ExpandedDatePickerProps {
  courseIndex: number;
  courseRule: ICourseRule | null;
  currentRule: ICourseRule;
  setProgram: Function;
  leftRules: ICourseRules[];
  rightRules: ICourseRules[];
  coursesField: CourseField;
  program?: IProgram;
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const ExpandedDatePicker: React.FC<ExpandedDatePickerProps> = ({ courseIndex, courseRule, currentRule, setProgram, leftRules, rightRules, coursesField, program, modalOpen, setModalOpen }) => {
  const coursesRulesField = getCoursesRulesField(coursesField);
  const [fromDate, setFromDate] = useState<moment.Moment | undefined>(courseRule?.startDate ? moment(courseRule?.startDate) : undefined);
  const [toDate, setToDate] = useState<moment.Moment | undefined>(courseRule?.endDate ? moment(courseRule?.endDate) : undefined);
  const [hasEndDate, setHasEndDate] = useState<boolean>(!courseRule?.endUndefined);
  const disableToDateCalendar = !hasEndDate;
  const okButtonDisable = !fromDate || (hasEndDate && !toDate) || (hasEndDate && toDate && fromDate && fromDate >= toDate)
  return <Modal
    className={styles.calendarModal}
    open={modalOpen}
    onCancel={() => {
      setModalOpen(false);
    }}
    title={getTranslations('PROGRAM_COURSE_CHOOSE_DATES')}
    footer={[
      <Button
        type="link"
        size='large'
        key="cancel"
        onClick={() => { 
          setModalOpen(false);
        }}
      >
        {getTranslations('PROGRAM_CANCEL_DATE_BUTTON')}
      </Button>,
      <Button
        disabled={okButtonDisable}
        type="primary"
        size='large'
        key="confirm"
        onClick={(result) => {
          let rule: ICourseRule = {...currentRule, startDate: fromDate?.toDate() ?? null, endDate: toDate?.toDate(), endUndefined: hasEndDate };

          if (hasEndDate) {
            rule = { ...rule };
          } else {
            rule = { ...rule, endDate: null };
          }
          setProgram({ ...program, [coursesRulesField]: [...leftRules, rule, ...rightRules] } as IProgram);
          setModalOpen(false);
        }
        }
      >
        {getTranslations('PROGRAM_SAVE_DATE_BUTTON')}
      </Button>
    ]}
  >
    <div className={styles.twoColumns}>
      <div>
        <Calendar
          headerRender={renderCalendarHeader}
          className={cls(styles.calendar, styles.marginTop)}
          locale={locale}
          value={fromDate}
          onSelect={setFromDate}
          disabledDate={(date) => {
            return date.endOf('d').valueOf() < +new Date();
          }}
          fullscreen={false}
        />
      </div>
      <div>
        <Form.Item name={[courseIndex, 'hasEndDate']}>
          <div className={styles.sameLine}>
            <Switch className={styles.switch} checked={!hasEndDate} size='small' onChange={() => setHasEndDate(!hasEndDate)} />
            <div>{getTranslations('PROGRAM_NO_END_DATE')}</div>
          </div>
        </Form.Item>
        <Calendar
          headerRender={renderCalendarHeader}
          className={styles.calendar}
          locale={locale}
          value={toDate}
          onSelect={setToDate}
          disabledDate={(date) => {
            const isBeforeMinDate = fromDate ? date.endOf('d').valueOf() <= fromDate.valueOf() : false;
            return disableToDateCalendar ? true : date.endOf('d').valueOf() < +new Date() ||
              isBeforeMinDate;
          }}
          fullscreen={false}
        />
      </div>
    </div>
  </Modal>
    ;
};

const getDuration = (currentRule: ICourseRule) => {
  if (currentRule.endDate) {
    const months = moment(currentRule.endDate).diff(moment(currentRule.startDate), 'months');
    if (months === 0) {
      const days = moment(currentRule.endDate).diff(moment(currentRule.startDate), 'days');
      return `${days} ${days === 1 ? getTranslations('PROGRAM_DAY'): getTranslations('PROGRAM_DAYS')}`;
    }
    return `${months} ${months === 1 ? getTranslations('PROGRAM_MONTH'): getTranslations('PROGRAM_MONTHS')}`;
  }
  return getTranslations('PROGRAM_START_AT_INFO', [moment(currentRule.startDate).format(DATE_FORMAT).toString()]);
};

const CourseSelectedForm: React.FC<CourseSelectedFormProps> = ({ courseId, courseIndex, setProgram, program, coursesField }) => {
  const coursesRulesField = getCoursesRulesField(coursesField);
  const currentRules = (program?.[coursesRulesField]) ? program?.[coursesRulesField] : [];
  const leftRules = currentRules.slice(0, courseIndex);
  const rightRules = currentRules.slice(courseIndex + 1, currentRules.length);
  const courseRule: ICourseRule | null = program?.[coursesRulesField] ? program[coursesRulesField][courseIndex] : null;
  const currentSelectedCoursesIds = (program?.[coursesField]) ? [...(program?.[coursesField]) as any] : [];
  const availablePredecesorIds = currentSelectedCoursesIds.filter(id => id !== courseId);
  const coursesDict = useSelector(selectCoursesDict);
  const predecesorCoursesList = availablePredecesorIds.map(courseId => coursesDict[courseId]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!program?._id || !courseRule) { // perhaps only course rule needs to be tested
      const rule = { ...currentRules[courseIndex], predecessor: [], hasPredecessor: false };
      setProgram({ ...program, [coursesRulesField]: [...leftRules, rule, ...rightRules] } as IProgram);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentStartDate = currentRules[courseIndex]?.startDate;
  const isCourseAlreadyStarted = currentStartDate ? moment(currentStartDate, DATE_FORMAT).isBefore(moment()) : false;
  const currentRule = { ...currentRules[courseIndex] };
  return (
    <React.Fragment>

      <ExpandedDatePicker
        courseIndex={courseIndex}
        coursesField={coursesField}
        currentRule={currentRule}
        leftRules={leftRules}
        program={program}
        rightRules={rightRules}
        setProgram={setProgram}
        courseRule={courseRule}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      />
      <Form.Item name={[courseIndex, 'startDate']}>
        <div onClick={() => {setModalOpen(true);}} className={styles.durationBox}>{currentRule.startDate ? getDuration(currentRule) : getTranslations('PROGRAM_COURSE_DURATION_PLACEHOLDER')}</div>
      </Form.Item>
      <Form.Item name={[courseIndex, 'priority']} label={getTranslations('PROGRAM_COURSE_PRIORITY')}
        rules={[{ required: true, message: 'Requerido' }]}
      >
        <Select placeholder={getTranslations('PROGRAM_COURSE_PRIORITY_PLACEHOLDER')}
          onChange={value => {
            const rule = { ...currentRules[courseIndex], priority: value };
            setProgram({ ...program, [coursesRulesField]: [...leftRules, rule, ...rightRules] } as IProgram);
          }
          }>
          {PRIORITIES().map(item => (<Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>))}
        </Select>
      </Form.Item>
      <Form.Item name={[courseIndex, 'hasPredecessor']}>
        <div>
          <div className={styles.hidableContainer}>
            <div className={styles.label}>{getTranslations('PROGRAM_COURSE_PREDECESSOR')}</div>
            <Switch checked={courseRule?.hasPredecessor ?? false} size='small' disabled={isCourseAlreadyStarted} onChange={(value) => {
              let rule: ICourseRule;
              if (value === true) {
                rule = { ...currentRules[courseIndex], hasPredecessor: value };
              } else {
                rule = { ...currentRules[courseIndex], hasPredecessor: value };
              }
              setProgram({ ...program, [coursesRulesField]: [...leftRules, rule, ...rightRules] } as IProgram);
            }} />
          </div>
        </div>
      </Form.Item>
      {
        !(courseRule?.hasPredecessor) ? null :
          <Form.Item name={[courseIndex, 'predecessor']} label={getTranslations('PROGRAM_COURSE_PREDECESSOR_LABEL')}
            rules={[]}
          >
            <Select placeholder={getTranslations('PROGRAM_COURSE_PREDECESSOR_CHOOSE')}
              onChange={value => {
                const rule = { ...currentRules[courseIndex], predecessor: value };
                setProgram({ ...program, [coursesRulesField]: [...leftRules, rule, ...rightRules] } as IProgram);
              }
              }>
              {predecesorCoursesList.map((course: ICourse) => (<Select.Option key={course._id} value={course._id}>{course.title}</Select.Option>))}
            </Select>
          </Form.Item>
      }
      {isCourseAlreadyStarted && <p><small>{getTranslations('PROGRAM_COURSE_CANT_CHANGE')}</small></p>}
    </React.Fragment>
  );
}

const CourseSelected: React.FC<CourseSelectedProps> = ({ courseId, remove: onRemove, setProgram, program, courseIndex, fieldName, coursesField }) => {
  const courses = useSelector(selectCoursesDict);
  let course = useRef(courses[courseId]);
  const dispatch = useDispatch();
  const companyName = useSelector(selectCompany(course.current?._id))?.name;
  const categories = useSelector(selectCategoriesDict);

  useEffect(() => {
    course.current = courses[courseId];
  }, [courseId, courses]);

  if (!course) {
    return null;
  }


  const onDeleteRejected = (message: string) => {
    dispatch(setMessageError({ message }))
  }

  const { current } = course;
  return (
    <div className={cls(formStyles.section, styles.card)}>
      <div className={formStyles.header}>
        <div className={formStyles.pillNumber}>
          <CogIcon size={18} icon="drag" /><span className={formStyles.number}>{courseIndex + 1}</span>
        </div>
        <div className={formStyles.actionContainer}>
          <span
            className={formStyles.action}
            onClick={() => {
              onDeleteHandler({ program, fieldName, setProgram, onRemove, courseIndex, onDeleteRejected, coursesField });
            }}
          >
            <CogIcon size={18} icon="Cross" />
          </span>
        </div>
      </div>
      {companyName ? <div className={styles.topCompanyName}>{companyName}</div> : null}
      <div className={styles.title}>{current?.title}</div>
      <div className={styles.description}>
        {current?.description}
      </div>
      {current?.category ? <div className={styles.category}>{categories[current?.category as string]?.name}</div> : null}
      {current?.evaluation || current?.survey ?
        <div className={styles.evaluations}>
          {current?.evaluation ? <span className={styles.evaluation}><CogIcon className={styles.icon} color='#2A2A2B' icon="test" />{getTranslations('PROGRAM_COURSE_EXAM')}</span> : null}
          {current?.survey ? <span className={styles.evaluation}><CogIcon className={styles.icon} color='#2A2A2B' icon="test" />{getTranslations('PROGRAM_COURSE_SURVEY')}</span> : null}
        </div>
        : null
      }

      <Divider />
      <CourseSelectedForm
        courseId={courseId}
        courseIndex={courseIndex}
        setProgram={setProgram}
        program={program}
        coursesField={coursesField}
      />
    </div>
  );
}

export const CoursesSelected: React.FC<CoursesSelectedProps> = ({ selectedCourses, setProgram, program, coursesList, coursesField }) => {
  const currentCoursesIds = (program?.[coursesField]) ? [...(program[coursesField]) as any] : [];
  const coursesRulesField = getCoursesRulesField(coursesField);

  const courseByTitleComparator = (a: ICourse, b: ICourse) => {
    const aName = a.title.toLowerCase();
    const bName = b.title.toLowerCase();

    if (aName < bName) {
      return -1;
    }
    if (aName > bName) {
      return 1;
    }
    return 0;
  }

  return (
    <div className={styles.container}>
      <Form.List name={`${coursesRulesField}2`}>
        {(fields, { add, remove }) => {
          const menu = (
            <Menu>
              {coursesList.filter((course: ICourse) => !selectedCourses.includes(course._id)).sort(courseByTitleComparator).map((course: ICourse, index: number) => {
                return (
                  <Menu.Item key={course._id}>
                    <p onClick={() => {
                      add();
                      setProgram({ ...program, [coursesField]: [...currentCoursesIds, course._id] });
                    }}>
                      {course.title}
                    </p>
                  </Menu.Item>
                );
              })}
            </Menu>
          );
          return (
            <div>
              {fields.map((field, index) => {
                return (
                  <div key={field.key}>
                    <CourseSelected
                      courseId={selectedCourses[index]}
                      fieldName={field.name}
                      courseIndex={index}
                      program={program}
                      remove={remove}
                      setProgram={setProgram}
                      coursesField={coursesField}
                    />
                  </div>
                );
              })}
              <Form.Item>
                <Dropdown
                  overlay={menu}
                  trigger={['click']}
                  overlayStyle={{ maxHeight: '100vh' }}
                >
                  <p className="ant-dropdown-link"
                    onClick={
                      e => {
                        e.preventDefault();
                      }}>
                    <Button className={cls(styles.addCoursesButton, formStyles.addNewButton)} type="default">
                      {getTranslations('PROGRAM_ADD_COURSE')} <CogIcon className={formStyles.square} color="var(--secondary-text-button-color)" icon="Arrow-drop-down" />
                    </Button>
                  </p>
                </Dropdown>
              </Form.Item>
            </div>
          );
        }}
      </Form.List>
    </div>
  );
}
