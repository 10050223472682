import cls from "classnames";
import React, { FunctionComponent } from "react";
import styles from "./index.module.scss";
import { getTranslations } from "../../../features/translations/translationsUtils";

export enum StatusEnum {
  Enable = "ENABLE",
  Disable = "DISABLE",
}

interface StatusProps {
  status: StatusEnum;
  small?: boolean;
}

export const Status: FunctionComponent<StatusProps> = ({
  status,
  small = false,
}) => {
  return (
    <div className={cls(styles.status, { [styles.sm]: small })}>
      <div
        className={cls(styles.color, {
          [styles.green]: status === StatusEnum.Enable,
          [styles.red]: status === StatusEnum.Disable,
        })}
      />
      <div className={styles.label}>
        {status === StatusEnum.Enable ? getTranslations('LIST_ENABLED') : getTranslations('LIST_DISABLED')}
      </div>
    </div>
  );
};
