import React, { FunctionComponent, useEffect } from 'react';
import Table, { ColumnsType } from "antd/lib/table";
import { TileTile } from '../../Company/common/TileTitle';
import { useKBDataIsFetching } from '../common/useDataIsFetching';
import { Status, StatusEnum } from '../../../common/Status';
import { DataType, exportToExcel, HeadersType } from '../../common/excelExport';
import { ConditionEnum } from '../../../../features/common/enums';
import moment from 'moment';
import { Button, Tooltip } from 'antd';
import { CogIcon } from '../../../common/CogIcon';

import styles from './index.module.scss';
import { IContentExtended } from '..';
import { Dot } from '../../common/Dot';
import { getTranslations } from '../../../../features/translations/translationsUtils';

export const fulfillmentColumnTitle = () => {
  return <>
    {getTranslations('REPORT_CONSULT')} <Tooltip
      title={<>
        {getTranslations('REPORT_GREEN_CONSULT')}<br />
        {getTranslations('REPORT_YELLOW_CONSULT')}<br />
        {getTranslations('REPORT_RED_CONSULT')}
      </>}
      className={styles.tooltip}
    >
      <span>
        <CogIcon size="1.4em" color="#81858b" icon={"Attention"} />
      </span>
    </Tooltip>
  </>;
}

const EXCEL_HEADERS: () => HeadersType = () => [
  getTranslations('REPORT_CATEGORY'),
  getTranslations('REPORT_COMPANY'),
  getTranslations('REPORT_TITLE'),
  getTranslations('REPORT_KNOWLEDGE_BASE_STATUS'),
  getTranslations('REPORT_CONSULT'),
];


interface IKnowledgeBaseReport extends IContentExtended { }
interface Props {
  list: IContentExtended[],
}
export const KnowledgeBaseTable: FunctionComponent<Props> = ({ list }) => {
  const dataIsFetching = useKBDataIsFetching();

  useEffect(() => {
    if (dataIsFetching) {
      return;
    }

  }, [dataIsFetching]);

  const columns: ColumnsType<IKnowledgeBaseReport> = [
    {
      title: getTranslations('REPORT_CATEGORY'),
      dataIndex: 'categoryName',
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      ellipsis: true,
      sorter: (a: IKnowledgeBaseReport, b: IKnowledgeBaseReport) => (a.categoryName || '').localeCompare(b.categoryName || ''),
    },
    {
      title: getTranslations('REPORT_COMPANY'),
      dataIndex: 'companyName',
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      sorter: (a: IKnowledgeBaseReport, b: IKnowledgeBaseReport) => a.companyName.localeCompare(b.companyName),
    },
    {
      title: getTranslations('REPORT_TITLE'),
      dataIndex: 'title',
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      sorter: (a: IKnowledgeBaseReport, b: IKnowledgeBaseReport) => a.title.localeCompare(b.title),
    },
    {
      title: getTranslations('REPORT_KNOWLEDGE_BASE_STATUS'),
      dataIndex: 'published',
      render: (published) => {
        return <Status status={published === ConditionEnum.Published ? StatusEnum.Enable : StatusEnum.Disable} />;
      },
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      sorter: (a: IKnowledgeBaseReport, b: IKnowledgeBaseReport) =>
        a.published === b.published ? 0 : a.published > b.published ? 1 : -1,
    },
    {
      title: fulfillmentColumnTitle,
      dataIndex: 'viewPercentage',
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      sorter: (a: IKnowledgeBaseReport, b: IKnowledgeBaseReport) => a.viewPercentage - b.viewPercentage,
      render: (percentage) => {
        const color = ((value) => {
          if (value < 50) {
            return '#ec5757'; //red
          }
          if (value >= 50 && value < 100) {
            return '#fbbf21'; //yellow
          }
          return '#44d4a8'; //green
        })(percentage);
        return <Dot color={color} value={percentage} suffix={'%'} />
      },
      width: "12%",
    },
  ];

  const handleDownload = () => {
    const headers = [...EXCEL_HEADERS()];
    const data: DataType = list.map(content => {
      return [
        content.categoryName,
        content.companyName,
        content.title,
        content.published === ConditionEnum.Published ? 'Publicado' : 'No publicado',
        content.viewPercentage.toString(),
      ];

    });

    const now = moment().format('HH[h]:mm[m]:ss[s]_DD')
    exportToExcel(headers, data, 'Bases de Conocimiento', `reporte_bases_${now}`);

  }
  return (
    <div className={styles.mainWrapper}>
      <div className={styles.header}>
        <TileTile text={getTranslations('REPORT_DOCUMENTS_PER_COMPANY')} />
        <Button className={styles.downloadButton} onClick={handleDownload} >
          {getTranslations('REPORT_DOWNLOAD')} <CogIcon size="1.4em" color="#81858b" icon={"Download"} />
        </Button>
      </div>
      <Table<IContentExtended>
        loading={dataIsFetching}
        rowKey='_id'
        columns={columns}
        dataSource={list}
        pagination={{ position: ["bottomRight"], pageSize: 10 }}
        scroll={{ y: 372, x: 'max-content' }}
      />
    </div>
  );
}