import { Button, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { CheckboxProps } from "antd/lib/checkbox";
import React, {
  FunctionComponent,
  ReactText,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getCognitoUserData,
  resendConfirmationEmail,
} from "../../features/auth/authSlice";
import {
  fetchCompanies,
  selectCompanies,
  selectCompaniesAreFetching,
} from "../../features/company/companySlice";
import { fetchGroups, selectGroupsDict } from "../../features/groups/groupSlice";
import {
  disableUsersStatus,
  enableUsersStatus,
  IUser,
  RolesDict,
  selectAreUsersFetching,
  selectUsers,
  selectUsersIds,
  UserRole,
  fetchUsers,
  UsersGroups,
} from "../../features/users/usersSlice";
import { PAGE_SIZE } from "../../utils/constants";
import { Status, StatusEnum } from "../common/Status";
import tableStyles from "../common/table.module.scss";
import { TableTools } from "../common/TableTools";
import { CompanyFilter } from "../Filters/Company";
import { useDebounce } from "../utils/useDebounce";
import { NamePlusThumbnail } from "./common/NamePlusThumbnail";
import { ActionSuffixes, Prefixes } from "../prefixes";
import { getTranslations } from "../../features/translations/translationsUtils";


export const UsersList: FunctionComponent = () => {
  const cognitoUserData = useSelector(getCognitoUserData);
  const companiesDict = useSelector(selectCompanies);
  const usersIdsList = useSelector(selectUsersIds);
  const usersDict = useSelector(selectUsers);
  const groupsDict = useSelector(selectGroupsDict);
  const usersList = usersIdsList.map((userId) => ({
    ...usersDict[userId],
    companyName: companiesDict[usersDict[userId].company ?? ""]?.name,
    studentGroupName:
      groupsDict[usersDict[userId].studentGroupId ?? ""]?.name,
  }));
  const areCompaniesFetching = useSelector(selectCompaniesAreFetching);
  const areUsersFetching = useSelector(selectAreUsersFetching);
  const [filterValue, setFilterValue] = useState<ReactText[]>([]);
  const debounceFilterValue = useDebounce(filterValue, 500);
  const history = useHistory();
  const [selectedRowKeys, selectRowKeys] = useState<ReactText[]>([]);
  const [filteredInfo, setFilteredInfo] = useState<ReactText[]>([]);
  const [showActivationBtns, setShowActivationBtns] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      setShowActivationBtns(true);
    } else {
      setShowActivationBtns(false);
    }
  }, [selectedRowKeys]);


  const onEdit = (userId: string) => {
    history.push(`${Prefixes.user}/${userId}${ActionSuffixes.edit}`);
  };

  const columns: ColumnsType<IUser> = [
    {
      title: getTranslations('LIST_FULL_NAME'),
      dataIndex: "name",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      filteredValue: debounceFilterValue,
      ellipsis: true,
      sorter: (a: IUser, b: IUser) => a.name.localeCompare(b.name),
      onFilter: (value, record) => {
        return (
          record.name.toLowerCase().includes(value.toString().toLowerCase()) ||
          record.email.toLowerCase().includes(value.toString().toLowerCase())
        );
      },
      render: (value, user) => {
        return <NamePlusThumbnail value={value} user={user} />;
      },
    },
    {
      title: getTranslations('LIST_FULL_ROLE'),
      dataIndex: "role",
      render: (value, values) => {
        if (values.groups?.includes(UsersGroups.Administrators)) {
          return RolesDict[value as UserRole];
        }
        return 'Estudiante';
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: getTranslations('LIST_STATUS'),
      dataIndex: "enabled",
      render: (status) => {
        return (
          <Status status={status ? StatusEnum.Enable : StatusEnum.Disable} />
        );
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a: IUser, b: IUser) =>
        a.enabled === b.enabled ? 0 : a.enabled > b.enabled ? 1 : -1,
    },
    {
      title: getTranslations('LIST_GROUP'),
      dataIndex: "studentGroupName",
      ellipsis: true,
      sortDirections: ["descend", "ascend"],
      filteredValue: filteredInfo.length > 1 ? filteredInfo : [],
      sorter: (a: any, b: any) =>
        (a.studentGroupName || "").localeCompare(b.studentGroupName || ""),
      onFilter: (value, record: IUser) =>
        record.studentGroupId === (value as string),
    },
    {
      title: getTranslations('LIST_COMPANY'),
      dataIndex: "companyName",
      sortDirections: ["descend", "ascend"],
      filteredValue: filteredInfo,
      onFilter: (value, record: IUser) => record.company === (value as string),
      sorter: (a: IUser, b: IUser) =>
        (a.companyName || "").localeCompare(b.companyName || ""),
      render: (value, user) => {
        return (
          <React.Fragment>
            {value}
            <div className={tableStyles.actions}>
              {user.status === "FORCE_CHANGE_PASSWORD" ? (
                <Button
                  type="default"
                  onClick={() => {
                    dispatch(resendConfirmationEmail(user.username));
                  }}
                >
                  {getTranslations('USER_RESEND_INVITATION')}
                </Button>
              ) : null}
              <Button
                type="default"
                onClick={() => {
                  onEdit(user.username);
                }}
              >
                {getTranslations('LIST_EDIT')}
              </Button>
            </div>
          </React.Fragment>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchCompanies());
    dispatch(fetchGroups());
  }, [dispatch]);
  const cognitoUsername = cognitoUserData
    ? cognitoUserData["cognito:username"]
    : null;
  const rowSelection = {
    getCheckboxProps: (record: IUser): CheckboxProps => {
      if (record.username === cognitoUsername) {
        return {
          disabled: true,
        };
      }
      return {};
    },
    selectedRowKeys,
    onChange: (selectedRowKeys: ReactText[]) => {
      selectRowKeys(selectedRowKeys);
    },
  };

  const enableStatus = () => {
    const data = {
      usernames: selectedRowKeys.map((key) => key.toString()),
    };
    dispatch(enableUsersStatus(true, data));
  };

  const disableStatus = () => {
    const data = {
      usernames: selectedRowKeys.map((key) => key.toString()),
    };
    dispatch(disableUsersStatus(true, data));
  };

  return (
    <React.Fragment>
      <TableTools
        placeholder={getTranslations('LIST_SEARCH_IN_TEURONA')}
        onSearch={(value) => setFilterValue([value])}
        onChange={(event) => setFilterValue([event.target.value])}
        onEnable={() => enableStatus()}
        onDisable={() => disableStatus()}
        filter={<CompanyFilter onApply={setFilteredInfo} />}
        activationBtnsVisible={showActivationBtns}
      />
      <Table<IUser>
        loading={areCompaniesFetching || areUsersFetching}
        className={tableStyles.table}
        rowClassName={() => tableStyles.row}
        rowKey="username"
        rowSelection={rowSelection}
        columns={columns}
        dataSource={usersList}
        pagination={{ position: ["bottomRight"], pageSize: PAGE_SIZE }}
      />
    </React.Fragment>
  );
};
