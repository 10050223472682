import React from "react";
import welcome from "../../assets/admin/welcome.svg";
import { MainLayout } from "../common/Layouts/Main";
import "./LoginOverrides.scss";
import styles from "./welcome.module.scss";
import { WelcomeMenu } from "./WelcomeMenu";
import { getTranslations } from "../../features/translations/translationsUtils";

export function Welcome() {
  return (
    <MainLayout title="" hideTitle>
      <div className={styles.container}>
        <div className={styles.imageContainer}>
          <img src={welcome} alt="welcome" />
        </div>
        <div className={styles.info}>
          <div className={styles.title}>
            {getTranslations('ADMIN_WELCOME')}
          </div>
          <div className={styles.description}>
            {getTranslations('ADMIN_WELCOME_DESCRIPTION')}
          </div>
        </div>
      </div>
      <WelcomeMenu />
    </MainLayout>
  );
}
