import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AuthenticationDetails,
  CognitoIdToken,
  CognitoUser,
} from "amazon-cognito-identity-js";
import { Dispatch } from "react";
import { history } from "../../App";
import { Microservices } from "../../app/AllowedMicroservices";
import { AppThunk, RootState } from "../../app/store";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import CognizantUserPool from "../../utils/CognizantUserPool";
import { setMessageInfo } from "../message/messageSlice";
import { setUserMode } from "../userMode/UserMode";
import { UserRole } from "../users/usersSlice";
import { analytics } from "../../services/analytics";
import { getTranslations } from "../translations/translationsUtils";

enum MarkEmailAsVerifiedStatus {
  Idle = "Idle",
  Sending = "Sending",
  Success = "Success",
  Error = "Error",
}
enum ResendInvitationStatus {
  Idle = "Idle",
  Sending = "Sending",
  Success = "Success",
  Error = "Error",
}
enum PointsStatus {
  Idle = "Idle",
  Adding = "Adding",
  Error = "Error",
}
enum LoginStatus {
  Idle = "Idle",
  Checking = "Checking",
  LoggedIn = "LoggedIn",
  Error = "Error",
  NewPasswordRequired = "NewPasswordRequired",
}
enum ChangePasswordStatus {
  Cleared = "Cleared",
  Changing = "Changing",
  Error = "Error",
  Success = "Success",
}
interface AuthState {
  errorMessage: string;
  userEmail: string;
  cognitoData: CognitoIdToken | null;
  cognitoUserEmail: string;
  cognitoUsername: string;
  cognitoGivenName: string;
  status: LoginStatus;
  resendInvitationStatus: ResendInvitationStatus;
  markEmailAsVerified: MarkEmailAsVerifiedStatus;
  changePasswordStatus: ChangePasswordStatus;
  changePasswordError: string;
  role: UserRole | null;
  statusPoints: PointsStatus;
}
interface SetCognitoUserDataPayload {
  email: string;
  username: string;
  givenName: string;
}

const initialState: AuthState = {
  errorMessage: "",
  status: LoginStatus.Checking,
  userEmail: "",
  cognitoData: null,
  cognitoUserEmail: "",
  cognitoUsername: "",
  cognitoGivenName: "",
  resendInvitationStatus: ResendInvitationStatus.Idle,
  markEmailAsVerified: MarkEmailAsVerifiedStatus.Idle,
  changePasswordStatus: ChangePasswordStatus.Cleared,
  changePasswordError: "",
  role: null,
  statusPoints: PointsStatus.Idle,
};
interface LoginSuccessPayload {
  userEmail: string;
  cognitoData: CognitoIdToken | null;
}
interface ChangePasswordFailedPayload {
  error: string;
}
interface ISetRole {
  role: UserRole | null;
}
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setRole: (state, action: PayloadAction<ISetRole>) => {
      state.role = action.payload.role;
    },
    loaded: (state) => {
      state.status = LoginStatus.Idle;
      state.resendInvitationStatus = ResendInvitationStatus.Idle;
    },
    login: (state) => {
      state.status = LoginStatus.Checking;
      state.resendInvitationStatus = ResendInvitationStatus.Idle;
    },
    loggingOut: (state) => {
      state.status = LoginStatus.Checking;
    },
    logout: (state) => {
      state.status = LoginStatus.Idle;
    },
    loginSuccess: (state, action: PayloadAction<LoginSuccessPayload>) => {
      state.status = LoginStatus.LoggedIn;
      state.errorMessage = "";
      state.userEmail = action.payload.userEmail;
      state.cognitoData = action.payload.cognitoData;
    },
    loginError: (state) => {
      state.status = LoginStatus.Error;
      state.errorMessage = getTranslations('LOGIN_ERROR_1');
    },
    loginNewPasswordRequired: (state) => {
      state.status = LoginStatus.NewPasswordRequired;
      state.errorMessage = "";
    },
    resendingInvitation: (state) => {
      state.resendInvitationStatus = ResendInvitationStatus.Sending;
    },
    resendInvitationSuccess: (state) => {
      state.resendInvitationStatus = ResendInvitationStatus.Success;
    },
    resendInvitationError: (state) => {
      state.resendInvitationStatus = ResendInvitationStatus.Error;
    },
    markingEmailAsVerified: (state) => {
      state.markEmailAsVerified = MarkEmailAsVerifiedStatus.Sending;
    },
    markEmailAsVerifiedSuccess: (state) => {
      state.markEmailAsVerified = MarkEmailAsVerifiedStatus.Success;
    },
    markEmailAsVerifiedError: (state) => {
      state.markEmailAsVerified = MarkEmailAsVerifiedStatus.Error;
    },
    setCognitoUserData: (
      state,
      action: PayloadAction<SetCognitoUserDataPayload>
    ) => {
      state.cognitoUserEmail = action.payload.email;
      state.cognitoUsername = action.payload.username;
      state.cognitoGivenName = action.payload.givenName;
    },
    clearChangePasswordStatus: (state) => {
      state.changePasswordError = "";
      state.changePasswordStatus = ChangePasswordStatus.Cleared;
    },
    changingPassword: (state) => {
      state.changePasswordStatus = ChangePasswordStatus.Changing;
    },
    changedPassword: (state) => {
      state.changePasswordError = "";
      state.changePasswordStatus = ChangePasswordStatus.Success;
    },
    changePasswordFailed: (
      state,
      action: PayloadAction<ChangePasswordFailedPayload>
    ) => {
      state.changePasswordError = action.payload.error;
      state.changePasswordStatus = ChangePasswordStatus.Error;
    },
    sendAddPoint: (state) => {
      state.statusPoints = PointsStatus.Adding;
    },
    sendAddPointSuccess: (state) => {
      state.statusPoints = PointsStatus.Idle;
    },
    sendAddPointError: (state) => {
      state.statusPoints = PointsStatus.Error;
    }
  },
});

export const {
  loaded,
  login,
  loginSuccess,
  loginError,
  loginNewPasswordRequired,
  loggingOut,
  setRole,
  logout,
  resendingInvitation,
  resendInvitationSuccess,
  resendInvitationError,
  markingEmailAsVerified,
  markEmailAsVerifiedSuccess,
  markEmailAsVerifiedError,
  setCognitoUserData,
  changePasswordFailed,
  clearChangePasswordStatus,
  changedPassword,
  changingPassword,
  sendAddPoint,
  sendAddPointError,
  sendAddPointSuccess,
} = authSlice.actions;

export let cognitoUser: CognitoUser | null = null;

export const resendConfirmationEmail = (username: string) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: `/user/${username}/resendInvitationEmail`,
      types: {
        requestType: resendingInvitation,
        successTypes: [
          {
            actionOrCreator: resendInvitationSuccess,
          },
          {
            actionOrCreator: setMessageInfo({ message: "Invitación enviada" }),
          },
        ],
        errorTypes: [
          {
            actionOrCreator: resendInvitationError,
          },
        ],
      },
      method: HttpMethods.POST,
      microservice: Microservices.COMPANY_SERVICE,
      authenticated: true,
    })
  );
};

export const markEmailAsVerified = (username: string) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: `/user/${username}/markEmailAsVerified`,
      types: {
        requestType: markingEmailAsVerified,
        successTypes: [
          {
            actionOrCreator: markEmailAsVerifiedSuccess,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: markEmailAsVerifiedError,
          },
        ],
      },
      method: HttpMethods.POST,
      microservice: Microservices.COMPANY_SERVICE,
      authenticated: false,
    })
  );
};

export const changePassword = (
  oldPassword: string,
  newPassword: string
): AppThunk => (dispatch) => {
  if (!cognitoUser?.getUsername()) {
    return;
  }
  dispatch(changingPassword());
  cognitoUser?.changePassword(oldPassword, newPassword, (error, response) => {
    if (error) {
      let errorText: string = "Error desconocido, intente mas tarde";
      if (error.message === "Incorrect username or password.") {
        errorText = "Contraseña o usuario incorrectos";
      } else if (
        error.message === "Attempt limit exceeded, please try after some time."
      ) {
        errorText =
          "Demasiados intentos, por favor vuelva a intentar mas tarde";
      }
      dispatch(changePasswordFailed({ error: errorText }));
      return;
    }
    dispatch(changedPassword());
  });
};

export const forgotPassword = (username: string): AppThunk => (dispatch) => {
  const userData = {
    Username: username,
    Pool: CognizantUserPool,
  };
  const user = new CognitoUser(userData);
  user?.forgotPassword({
    onSuccess: (_data) => {
      history.push("/change-password");
    },
    onFailure: (error) => {
      console.error("error", error);
    },
  });
};

export const logoutFromCognito = (): AppThunk => (dispatch) => {
  dispatch(loggingOut());
  cognitoUser?.globalSignOut({
    onSuccess: (_msg: string) => {
      cognitoUser = null;
      dispatch(logout());
    },
    onFailure: (_err: Error) => {
      cognitoUser?.signOut();
      cognitoUser = null;
      dispatch(logout());
    },
  });

  return;
};
const addLoginPoint =  () => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: '/points/login',
      types: {
        requestType: sendAddPoint,
        successTypes: [
          {
            actionOrCreator: sendAddPointSuccess,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: sendAddPointError,
          },
        ],
      },
      method: HttpMethods.POST,
      microservice: Microservices.COMPANY_SERVICE,
      authenticated: true,
    })
  );
};

export const loginToCognito = (
  username: string,
  password: string,
  history: any
): AppThunk => async (dispatch) => {
  dispatch(login());
  return new Promise((resolve) => {
    const user = new CognitoUser({
      Username: username,
      Pool: CognizantUserPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: async (data) => {
        const idToken = data.getIdToken();
        const cognitoData = JSON.parse(JSON.stringify(idToken));
        const role = cognitoData.payload["custom:role"];
        await dispatch(setUserRole(role));
        const result: any = setUserMode(cognitoData);
        await dispatch(result);
        setCognitoUser(user);
        await dispatch(
          setCognitoUserData({
            email: idToken.payload.email,
            username: user.getUsername(),
            givenName: idToken.payload.given_name,
          })
        );
        await dispatch(loginSuccess({ userEmail: username, cognitoData }));
        await history.push(role ? "/welcome" : "/home");
        analytics.logIn(cognitoData.payload["cognito:username"], cognitoData.payload["custom:company"]);
        await dispatch(addLoginPoint());
        resolve();
      },
      onFailure: (err) => {
        console.error("loginToCognito -> err", err);
        dispatch(loginError());
        resolve();
      },
      newPasswordRequired: (data) => {
        cognitoUser = user;
        dispatch(
          setCognitoUserData({
            email: username,
            username: user.getUsername(),
            givenName: data.given_name,
          })
        );
        dispatch(loginNewPasswordRequired());
        resolve();
      },
    });
  });
};

export const setFirstNewPassword = (newPassword: string): AppThunk => async (
  dispatch
) => {
  return new Promise((resolve, reject) => {
    if (!cognitoUser) {
      return reject();
    }
    cognitoUser.completeNewPasswordChallenge(
      newPassword,
      {},
      {
        onSuccess: (_session: any) => {
          dispatch(logout());
          dispatch(loaded());
          history.replace("/login");
          resolve();
        },
        onFailure: (err: any) => {
          console.error("Error:", err);
          dispatch(loginError());
          resolve();
        },
      }
    );
  });
};
export const setNewPassword = (
  code: string,
  username: string,
  newPassword: string
): AppThunk => async (dispatch, getState) => {
  const user = new CognitoUser({
    Username: username,
    Pool: CognizantUserPool,
  });

  return new Promise((resolve, reject) => {
    if (!user) {
      return reject();
    }
    user.confirmPassword(code, newPassword, {
      onSuccess: () => {
        history.push("/login");
        dispatch(
          setMessageInfo({ message: getTranslations('PASSWORD_SUCCESSFUL_CHANGED') })
        );
        resolve();
      },
      onFailure: (err: any) => {
        console.error("Error:", err);
        dispatch(setMessageInfo({ message: getTranslations('PASSWORD_ERROR_ON_CHANGE') }));
        history.push("/login");
        resolve();
      },
    });
  });
};
export const setUserRole = (role: UserRole | null) => {
  return setRole({ role });
};
export const selectCompanyId = (state: RootState):string|undefined => {
  return state.auth.cognitoData?.payload['custom:company'];
};
export const selectUserRole = (state: RootState) => state.auth.role;
export const errorMessage = (state: RootState) => state.auth.errorMessage;
export const newPasswordRequired = (state: RootState) =>
  state.auth.status === LoginStatus.NewPasswordRequired;
export const isLoading = (state: RootState) =>
  state.auth.status === LoginStatus.Checking;
export const loggedIn = (state: RootState) =>
  state.auth.status === LoginStatus.LoggedIn;
export const userEmail = (state: RootState) => state.auth.userEmail;
export const cognitoUsername = (state: RootState) => state.auth.cognitoUsername;
export const cognitoGivenName = (state: RootState) =>
  state.auth.cognitoGivenName;
export const cognitoUserEmail = (state: RootState) =>
  state.auth.cognitoUserEmail;
export const getCognitoUserData = (state: RootState) =>
  state.auth.cognitoData?.payload;

export const selectChangingPassword = (state: RootState) =>
  state.auth.changePasswordStatus === ChangePasswordStatus.Changing;
export const selectChangePasswordFailed = (state: RootState) =>
  state.auth.changePasswordStatus === ChangePasswordStatus.Error;
export const selectChangePasswordError = (state: RootState) =>
  state.auth.changePasswordError;
export const selectChangePasswordSuccess = (state: RootState) =>
  state.auth.changePasswordStatus === ChangePasswordStatus.Success;

export const setCognitoUser = (user: CognitoUser) => {
  cognitoUser = user;
};
export default authSlice.reducer;
