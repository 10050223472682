import { Button, Card, Divider, Space } from "antd";
import moment from "moment";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import {
  fetchCompany,
  ICompany,
  selectCompany,
} from "../../features/company/companySlice";
import {
  fetchCompletedCourses,
  fetchCompletedLessons,
  fetchCompletedTopics,
  IEval,
  selectCompletedCourses,
  selectCompletedLessons,
  selectCompletedTopics,
  selectIsCompletedEvaluationFetching,
} from "../../features/printable/user";
import {
  fetchCategories,
  selectCategoriesDict,
} from '../../features/category/categorySlice';
import {
  fetchUser,
  GenderDict,
  IUser,
  selectUser,
  UserRole,
} from "../../features/users/usersSlice";
import { PrintProfileLayout } from "../common/Layouts/PrintProfileLyt";
import { Status, StatusEnum } from "../common/Status";
import { idLabelsByType, IdTypes } from "../Users/common/identification";
import { getCountryName } from "../Users/utils";
import { PageCountHeader } from "./common/PageCountHeader";
import { GroupOfCompletedEvals } from "./GroupOfCompletedEvals";
import { Output } from "./Output";
import styles from "./profile.module.scss";
import { SectionHeader } from "./SectionHeader";
import {
  fetchGroups,
  GroupDict,
  selectGroupsDict,
} from "../../features/groups/groupSlice";
import { getTranslations } from "../../features/translations/translationsUtils";

interface RouteParams {
  id: string;
}

export interface IEvalWithCategory extends IEval {
  categoryName: string,
}

export const PrintableProfile: React.FunctionComponent = () => {
  let componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const { id: username } = useParams<RouteParams>();
  const isFetchingData = useSelector(selectIsCompletedEvaluationFetching);

  return (
    <React.Fragment>
      <div className={styles.floatingCtaWrapper}>
        <div className={styles.buttonWrapper}>
          {isFetchingData === true ? (
            <div className={styles.cta}>{getTranslations('COMPANY_LOADING_PROFILE')}</div>
          ) : (
            <Button
              type={"primary"}
              onClick={handlePrint}
              className={styles.cta}
            >
              {getTranslations('COMPANY_PROFILE_PRINT')}
            </Button>
          )}
        </div>
      </div>
      <UserPrintableProfileWithRef username={username} ref={componentRef} />
    </React.Fragment>
  );
};

interface ProfileDetailsProps {
  user: IUser;
  company: ICompany;
}

const ProfileDetailsColumn: FunctionComponent<ProfileDetailsProps> = ({
  user,
  company,
}) => {
  return (
    <React.Fragment>
      <Space
        direction="vertical"
        style={{ width: "100%" }}
        className={styles.column}
      >
        <Card className={styles.card}>
          <div className={styles.title}>{getTranslations('USER_PROFILE')}</div>
          <Output
            label={getTranslations('COMPANY_CONTACT_NAMES')}
            text={
              (user.given_name ? user.given_name : "") +
              " " +
              (user.middle_name ? user.middle_name : "")
            }
          />
          <Output
            label={getTranslations('COMPANY_CONTACT_LAST_NAME')}
            text={
              (user.family_name ? user.family_name : "") +
              " " +
              (user.secondLastname ? user.secondLastname : "")
            }
          />
          <Divider />
          <Output label={getTranslations('USERS_EMAIL')} text={user.email} />
          <Output
            label={getTranslations('USERS_NATIONAL_ID_TYPE')}
            text={idLabelsByType[user.nationalIdType as IdTypes]}
          />
          <Output label={getTranslations('USERS_NATIONAL_ID')} text={user.nationalId} />
          <Output label={getTranslations('USERS_CELLPHONE')} text={user.phone_number} />
          <Output label={getTranslations('USERS_GENDER')} text={GenderDict()[user.gender]}></Output>
          <Output
            label={getTranslations('USERS_BIRTH_DATE')}
            text={moment.utc(user.birthdate).format("DD-MM-YYYY")}
          />
          <Output label={getTranslations('USERS_COUNTRY')} text={getCountryName(user.country) || "---"} />
          <Output label={getTranslations('USERS_CITY')} text={user.city} />
        </Card>
      </Space>
    </React.Fragment>
  );
};

const renderRightColumn = (user: IUser, company: ICompany, groupsDict: GroupDict) => {
  const isSupervisor = user.role === UserRole.Supervisor;
  return (
    <Space
      direction="vertical"
      style={{ width: "100%" }}
      className={styles.column}
    >
      <Card className={styles.card}>
        <Output label={getTranslations('USERS_ADDRESS')} text={user.address} />
        <Output
          label={getTranslations('USER_CREATION_DATE')}
          text={moment.utc(user.createdAt).format("DD-MM-YYYY")}
        />
      </Card>
      {isSupervisor ? <Card className={styles.card}>
        <div className={styles.title}>Estudios</div>
        <Output label={"Grupo Asignado"}
          text={user?.studentGroupId
            ? groupsDict[user.studentGroupId]?.name
            : ""}
        />
        <Output label={"Estatus"} text={""} />
        <Status status={user.status ? StatusEnum.Enable : StatusEnum.Disable} />
      </Card> : null}
      <Card className={styles.card}>
        <div className={styles.title}>{getTranslations('USER_COMPANY_INFO')}</div>
        <Output label={getTranslations('USERS_JOB')} text={user.position || " "} />
        <Output label={getTranslations('USER_COMPANY')} text={company.name} />
      </Card>
    </Space>
  );
};

const renderLeftColumn = (user: IUser, company: ICompany) => {
  return <ProfileDetailsColumn user={user} company={company} />;
};

const HEADER_TEXT = "Ficha de estudiante";
interface UserProfileProps {
  username: string;
}

const UserPrintableProfile = ({ username }: UserProfileProps, ref: any) => {
  const userFromStore = useSelector(selectUser(username));
  const [company, setCompany] = useState<ICompany>();
  const [currentUser, setCurrentUser] = useState<IUser>();
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = useState<number>(0);
  const completedCourses = useSelector(selectCompletedCourses);
  const completedLessons = useSelector(selectCompletedLessons);
  const completedTopics = useSelector(selectCompletedTopics);
  const groupsDict = useSelector(selectGroupsDict);
  const categoriesDict = useSelector(selectCategoriesDict);

  useEffect(() => {
    if (userFromStore) {
      setCurrentUser(userFromStore);
      dispatch(fetchCompany(userFromStore?.company ?? ""));
    }
  }, [userFromStore, dispatch]);

  const companyFromStore = useSelector(
    selectCompany(userFromStore?.company ?? "")
  );
  useEffect(() => {
    if (companyFromStore) {
      setCompany(companyFromStore);
    }
  }, [companyFromStore]);

  useEffect(() => {
    dispatch(fetchGroups());
    dispatch(fetchUser(username));
  }, [dispatch, username]);

  useEffect(() => {
    if (currentUser?.username) {
      dispatch(fetchCompletedCourses(currentUser?.username));
      dispatch(fetchCompletedLessons(currentUser?.username));
      dispatch(fetchCompletedTopics(currentUser?.username));
    }
  }, [dispatch, currentUser]);

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const groupCompletedEvaluations = (allItems: IEval[], perChunk: number) => {
    return allItems.reduce((acc: IEvalWithCategory[][], item, index) => {
      const chunkIndex = Math.floor(index / perChunk);

      if (!acc[chunkIndex]) {
        acc[chunkIndex] = [];
      }
      const itemCategory = categoriesDict[item.categoryId];
      acc[chunkIndex].push({ ...item, categoryName: itemCategory?.name });
      return acc;
    }, []);
  };

  const COMPLETED_EVALS_PER_PAGE: number = 11;
  const groupsOfCourses = groupCompletedEvaluations(
    completedCourses,
    COMPLETED_EVALS_PER_PAGE,
  );
  const groupsOfLessons = groupCompletedEvaluations(
    completedLessons,
    COMPLETED_EVALS_PER_PAGE
  );
  const groupsOfTopics = groupCompletedEvaluations(
    completedTopics,
    COMPLETED_EVALS_PER_PAGE
  );

  useEffect(() => {
    const groupOfCoursesCount = groupsOfCourses.length;
    const groupOfLessonsCount = groupsOfLessons.length;
    const groupOfTopicsCount = groupsOfTopics.length;
    setPageCount(
      1 + groupOfCoursesCount + groupOfLessonsCount + groupOfTopicsCount
    );
  }, [groupsOfCourses, groupsOfLessons, groupsOfTopics, setPageCount]);

  return (
    <React.Fragment>
      <div ref={ref} className={styles.mainWrapper}>
        <PageCountHeader
          text={HEADER_TEXT}
          currentPage={1}
          totalPages={pageCount}
        />
        <PrintProfileLayout
          topPanelColor={"#f7f8fa"}
          image={currentUser?.picture}
          leftBottomOptions={renderLeftColumn(
            Object.assign({}, currentUser),
            Object.assign({}, company)
          )}
          rightBottomOptions={renderRightColumn(
            Object.assign({}, currentUser),
            Object.assign({}, company),
            Object.assign({}, groupsDict)
          )}
        ></PrintProfileLayout>
        <br />
        {groupsOfCourses.map((group, index) => {
          return (
            <React.Fragment key={`completedCourses-${index}`}>
              <div className={styles.pageBreak} />
              <PageCountHeader
                text={HEADER_TEXT}
                currentPage={index + 1 + 1}
                totalPages={pageCount}
              />
              <SectionHeader title={"Cursos culminados"} />
              <GroupOfCompletedEvals items={group} />
            </React.Fragment>
          );
        })}
        <br />
        {groupsOfLessons.map((group, index) => {
          return (
            <React.Fragment key={`completedLessons-${index}`}>
              <div className={styles.pageBreak} />
              <PageCountHeader
                text={HEADER_TEXT}
                currentPage={index + 1 + 1 + groupsOfCourses.length}
                totalPages={pageCount}
              />
              <SectionHeader
                title={"Lecciones culminadas"}
                hideExceptOnPrint={index !== 0}
              />
              <GroupOfCompletedEvals items={group} />
            </React.Fragment>
          );
        })}
        <br />
        {groupsOfTopics.map((group, index) => {
          return (
            <React.Fragment key={`completedTopics-${index}`}>
              <div className={styles.pageBreak} />
              <PageCountHeader
                text={HEADER_TEXT}
                currentPage={
                  index +
                  1 +
                  1 +
                  groupsOfCourses.length +
                  groupsOfLessons.length
                }
                totalPages={pageCount}
              />
              <SectionHeader title={"Temas culminados"} />
              <GroupOfCompletedEvals items={group} />
            </React.Fragment>
          );
        })}
      </div>
    </React.Fragment>
  );
};

const UserPrintableProfileWithRef = React.forwardRef(UserPrintableProfile);
