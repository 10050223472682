import React, { Component } from 'react';

import { EditableInput, Hue, Saturation } from 'react-color/lib/components/common';
import { CustomPicker, CustomPickerProps } from 'react-color';
import cls from 'classnames';
import { Popover } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';

import styles from './colorPicker.module.scss';

interface IColorPickerProps {
  color: string;
  setColor: (color: string) => void;
  trigger: React.ReactElement;
  placement?: TooltipPlacement;
}
interface IColorPickerState {
  color: string;
}

const PointerForSaturation = (props: any) => {
  return <div className={cls(styles.pointer, styles.forSaturation)} style={{ backgroundColor: props.color }} />;
};

const PointerForHue = (props: any) => {
  return <div className={cls(styles.pointer, styles.forHue)} style={{ backgroundColor: props.color }} />;
};

class InnerColorPicker extends React.Component<CustomPickerProps<any>> {
  render() {
    const color = (this.props.color ?? '#FFFFFF') as string;
    return <div className={styles.innerColorPicker}>
      <div className={styles.sat}>
        <Saturation
          pointer={PointerForSaturation}
          {...this.props}
        />
      </div>
      <div className={styles.hue}>
        <Hue
          direction={'horizontal'}
          pointer={PointerForHue}
          {...this.props} />
      </div>
      <div className={styles.section}>
        <div className={styles.label}>HEX</div>
        <div className={styles.hex}>
          <div className={styles.color} style={{ backgroundColor: color }} />
          <EditableInput
            style={{
              input: {
                border: 0,
                background: 'transparent',
                color: '#FFFFFF',
                fontFamily: 'Inter',
                fontSize: 14,
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
                width: 128,
              },

            }}
            value={color}
            onChange={this.props.onChange}
          />
        </div>
      </div>
    </div>;
  }
}
const InnerCustomPicker = CustomPicker(InnerColorPicker as any);

export class ColorPicker extends Component<IColorPickerProps, IColorPickerState> {
  constructor(props: any) {
    super(props);
    this.state = {
      color: '#000000',
    };
    this.onChangeColor = this.onChangeColor.bind(this);
    this.onOpenColorPicker = this.onOpenColorPicker.bind(this);
  }

  private onColorClick(color: string) {
    this.onChangeColor(color);
  }

  private onOpenColorPicker() {
    const { color } = this.props;
    this.setState({
      color,
    });
  }

  private onChangeColor(color: string) {
    this.setState({
      color,
    }, () => this.props.setColor(color));
  }

  private renderColorMap(): React.ReactNode {
    return <>
      <InnerCustomPicker color={this.state.color} onChange={(color) => {
        this.onChangeColor(color.hex);
      }} />
    </>;
  }

  private renderColors(colors: string[]) {
    return colors.map((color, index) => {
      return <div
        onClick={this.onColorClick.bind(this, color)}
        key={`${color}_${index}`}
        className={styles.color}
        style={{ backgroundColor: color }}
      />;
    });
  }

  private renderColorPicker() {
    return <div className={styles.container}>
      <div className={styles.innerContainer}>
        {this.renderColorMap()}
      </div>
    </div>;
  }

  render() {
    const { trigger, placement } = this.props;
    return <Popover
      placement={ placement ?? 'right'}
      overlayClassName={styles.popover}
      className={styles.popover}
      onOpenChange={this.onOpenColorPicker}
      destroyTooltipOnHide={true}
      trigger="click"
      content={this.renderColorPicker()}
    >
      {trigger}
    </Popover>;
  }
}