export interface IFulfillment {
  completed: number,
  inProgress: number,
  notStarted: number,
  id: string,
  username: string,
}
export interface IFulfillmentPerCompany {
  [id:string]: {
    completed: number,
    inProgress: number,
    notStarted: number,
    id: string,
  }
}

export enum LearningType {
  lessons = 'lessons',
  topics = 'topics',
  courses = 'courses',
}

export enum LearningLabelsSingle {
  lessons = 'REPORT_LESSON_LABEL',
  topics = 'REPORT_TOPIC_LABEL',
  courses = 'REPORT_COURSE_LABEL',
}

export enum LearningLabels {
  lessons = 'REPORT_LESSONS_LABEL',
  topics = 'REPORT_TOPICS_LABEL',
  courses = 'REPORT_COURSES_LABEL',
}

export enum EvaluationLabels {
  lessons = 'REPORT_QUIZ',
  topics = 'REPORT_TEST',
  courses = 'REPORT_EXAM',
}

export interface IEvaluationsCounters{
  passed: number;
  responsesCount: number;
  results: number;
  total: number;
  _id: {
    parentId: string;
    correctAnswersToPass: number;
    username: string;
  }
}