import React, { useState } from "react";

import { useSelector } from "react-redux";

import { selectUserRole } from "../../features/auth/authSlice";
import { UserRole } from "../../features/users/usersSlice";
import { StepProps } from "./commonInterfaces";
import { TeacherFields } from "./TeacherFields";

export const StepZero2: React.FunctionComponent<StepProps> = ({
  newLesson,
  form,
  setNewLesson,
}) => {
  const userRole = useSelector(selectUserRole);
  const [displayDropdown, setDisplayDropdown] = useState(!!newLesson.teacher);
  return (
    <>
      {newLesson.companyId || userRole !== UserRole.SuperAdmin ? null : (
          <TeacherFields
            isEditing={typeof newLesson._id === "string"}
            form={form}
            teacher={newLesson.teacher}
            displayDropdown={displayDropdown}
            setDisplayDropdown={setDisplayDropdown}
            onUpdateTeacher={(teacher) =>
              setNewLesson({ ...newLesson, teacher: teacher })
            }
          />
      )}
    </>
  );
};
