import React from 'react';

import {
  Link
} from "react-router-dom";
import { Layout, Radio, RadioChangeEvent } from 'antd';
import { useHistory } from 'react-router-dom';
import ctx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { isIOS } from 'react-device-detect';

import { CogIcon } from '../common/CogIcon';
import { getCognitoUserData } from '../../features/auth/authSlice';
import { UsersGroups } from '../../features/users/usersSlice';
import { ProfilePill } from '../LoggedUser/ProfilePill';
import {
  selectUserStudent, changeToAdmin, changeToStudent
} from '../../features/userMode/UserMode';
import { Breadcrumbs } from '../common/Breadcrumbs';
import { NotificationMenu } from '../LoggedUser/NotificationMenu';
import { analytics } from '../../services/analytics';
import { PointsNotificationFirestore } from '../../services/PointsNotificationFirestore';
import { Points } from './Points';
import { useDeviceSize } from '../utils/useDeviceSize';
import { SIZES } from '../common/screen';

import styles from './index.module.scss';

import logo from '../../../src/assets/logo-teurona.svg';
import minLogo from '../../../src/assets/logo-small-teurona.png';
import { CustomizeApplier } from './customizeApplier';
import { selectCompanies, selectCompanyCustomize } from '../../features/company/companySlice';
import { getTranslations } from '../../features/translations/translationsUtils';


interface CognitoAttr {
  [key: string]: any;
}

interface HeadermenuProps {
  isLoggedIn: boolean;
  cognitoData: CognitoAttr | undefined;
}

interface HeaderProps {
  isLoggedIn?: boolean;
  logoCentered?: boolean;
}

const BottomHeader: React.FunctionComponent<HeadermenuProps> = ({
  isLoggedIn,
  cognitoData,
}) => {
  if (!isLoggedIn || !cognitoData) {
    return null;
  }
  return <div className={styles.separator} />;
};

const UserMode: React.FunctionComponent<HeadermenuProps> = ({
  isLoggedIn,
  cognitoData,
}) => {
  const isUserAStudent = useSelector(selectUserStudent);
  const history = useHistory();
  const dispatch = useDispatch();
  if (!isLoggedIn || !cognitoData) {
    return null;
  }
  if (!cognitoData['cognito:groups'].includes(UsersGroups.Administrators)) {
    return null;
  }
  const options = [
    { label: getTranslations('HEADER_STUDENT_BUTTON'), value: 'student' },
    { label: getTranslations('HEADER_ADMIN_BUTTON'), value: 'admin' },
  ];
  const userMode = isUserAStudent ? 'student' : 'admin';
  const onChangeUserMode = ({ target: { value } }: RadioChangeEvent) => {
    if (value === 'student') {
      dispatch(changeToStudent());
      history.push('/home');
      return;
    }
    dispatch(changeToAdmin());
    history.push('/welcome');
  };

  return <div className={styles.userMode}>
    <Radio.Group
      options={options}
      onChange={onChangeUserMode}
      value={userMode}
      optionType="button"
      buttonStyle="solid"
    />
  </div>;
};

const KNOWLEDGE_BASES_KEY = 'knowledgeBases';

export const Header: React.FunctionComponent<HeaderProps> = ({ isLoggedIn = false, logoCentered = false }) => {
  const companiesDict = useSelector(selectCompanies);
  const isUserAStudent = useSelector(selectUserStudent);
  const cognitoData = useSelector(getCognitoUserData);
  const companyId = cognitoData
    ? cognitoData["custom:company"]
    : null;
  const company = companiesDict[companyId ?? ''];
  const companyCustomize = useSelector(selectCompanyCustomize(companyId));
  const history = useHistory();
  const size = useDeviceSize();
  const goToKnowledgeBase = () => {
    analytics.selectKnowledgeBases();
    history.push(`/${KNOWLEDGE_BASES_KEY}`);
  }

  const minLogoUrl = companyCustomize?.mobileLogo ? companyCustomize?.mobileLogo : minLogo;
  const logoUrl = companyCustomize?.logo ? companyCustomize?.logo : logo;
  const KNOWLEDGE_BASE_TEXT = getTranslations('HOME_KNOWLEDGE_BASE');

  const { showKnowledgeBase } = company ?? {};

  const showNotifications = company?.notificationTypesEnabled?.email ||
    company?.notificationTypesEnabled?.sms ||
    company?.notificationTypesEnabled?.web;

  if (size === SIZES.PHONE) {
    return <>
      <CustomizeApplier />
      <Layout.Header className={ctx(styles.header, styles.mobile, {
        [styles.ios]: isIOS,
        [styles.longHeader]: isLoggedIn
      })}>
        <div className={styles.topHeader}>
          <div className={styles.innerTopHeader}>
            <div className={styles.logo}>
              <Link to="/"><img src={minLogoUrl} alt="logo" /></Link>
            </div>
            {cognitoData && <PointsNotificationFirestore email={cognitoData['email']} userId={cognitoData['cognito:username']} />}
          </div>
        </div>
        <BottomHeader isLoggedIn={isLoggedIn} cognitoData={cognitoData} />
        <Breadcrumbs />


      </Layout.Header>
      {
        isLoggedIn ?
          <div className={styles.bottom}>
            <div className={styles.logo}>
              <Link to="/"><img src={minLogoUrl} alt="logo" /></Link>
            </div>
            {isUserAStudent && showKnowledgeBase ? <div className={styles.iconMenu} onClick={goToKnowledgeBase}>
              <CogIcon size="1.2em" icon='book' color='#81858b' className={styles.icon} />
              <div className={styles.title}>{KNOWLEDGE_BASE_TEXT}</div>
            </div> : null}
            {showNotifications ? <NotificationMenu /> : null}
            <ProfilePill />
          </div> : null
      }
    </>;
  }

  if (logoCentered) {
    return <Layout.Header className={ctx(styles.header, styles.noMobile, {
      [styles.longHeader]: isLoggedIn
    })}>
      <div className={styles.topHeader}>
        <div className={ctx(styles.innerTopHeader, styles.centered)}>
          <div className={styles.logo}>
            <Link to="/"><img src={logoUrl} alt="logo" /></Link>
          </div>
        </div>
      </div>
    </Layout.Header>;
  }

  return <>
    <CustomizeApplier />
    <Layout.Header className={ctx(styles.header, styles.noMobile, {
      [styles.longHeader]: isLoggedIn
    })}>
      <div className={styles.topHeader}>
        <div className={styles.innerTopHeader}>
          <div className={styles.logo}>
            <Link to="/"><img src={logoUrl} alt="logo" /></Link>
          </div>
          {
            cognitoData &&
            cognitoData['cognito:groups'].includes(UsersGroups.Administrators) &&
            cognitoData['cognito:groups'].includes(UsersGroups.Students) &&
            <UserMode isLoggedIn={isLoggedIn} cognitoData={cognitoData} />
          }
          {cognitoData && <PointsNotificationFirestore email={cognitoData['email']} userId={cognitoData['cognito:username']} />}
        </div>
        {
          isLoggedIn ?
            <div className={styles.right}>
              {isUserAStudent && showKnowledgeBase ? <div className={styles.iconMenu} onClick={goToKnowledgeBase}>
                <CogIcon size="1.2em" icon='book' color='#81858b' className={styles.icon} />
                <div className={styles.title}>{KNOWLEDGE_BASE_TEXT}</div>
              </div> : null}
              {showNotifications ? <NotificationMenu /> : null}
              {isUserAStudent ? <div className={styles.divider} /> : null}
              <Points />
              <ProfilePill />
            </div> : null
        }
      </div>
      <BottomHeader isLoggedIn={isLoggedIn} cognitoData={cognitoData} />
      <Breadcrumbs />
    </Layout.Header>
  </>;
}
