import React from "react";
import { ICourse } from "../../../../features/courses/coursesSlice";
import { ILesson } from "../../../../features/lesson/lessonSlice";
import { ITopic } from "../../../../features/topic/topicSlice";
import { StatusEnum } from "../../../common/Status";
import { IconNames } from "../../common/SimpleStat";
import { StatsRow } from "../../common/StatsRow";
import { getTranslations } from "../../../../features/translations/translationsUtils";

interface Props {
  lessons: ILesson[];
  topics: ITopic[];
  courses: ICourse[];
}

export const EducationalResourcesAvailability: React.FunctionComponent<Props> =
  ({ lessons, topics, courses }) => {
    const stats = [
      {
        description: getTranslations('REPORT_AVAILABLE_LESSONS'),
        iconName: IconNames.Availability,
        mainText: lessons
          .filter((lesson) => lesson.status === StatusEnum.Enable)
          .length.toString(),
      },
      {
        description: getTranslations('REPORT_AVAILABLE_TOPICS'),
        iconName: IconNames.AvailableTopics,
        mainText: topics
          .filter((topic) => topic.status === StatusEnum.Enable)
          .length.toString(),
      },
      {
        description: getTranslations('REPORT_AVAILABLE_COURSES'),
        iconName: IconNames.AvailableCourses,
        mainText: courses
          .filter((course) => course.status === StatusEnum.Enable)
          .length.toString(),
      },
    ];
    return <StatsRow statsData={stats} />;
  };
