import {
  Divider,
  Form,
  Input,
  Select,
} from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { selectUserRole } from '../../features/auth/authSlice';
import { selectCompanies } from '../../features/company/companySlice';
import { UserRole } from '../../features/users/usersSlice';
import { CategoryField } from "../common/CategoryField";
import { MIN_3_CHAR } from "../Users/Form";
import { StepProps } from "./interfaces";
import { getTranslations } from "../../features/translations/translationsUtils";


export const FirstStep: React.FC<StepProps> = ({
  newTopic,
  setNewTopic,
  categories,
  categoriesIds,
}) => {
  const userRole = useSelector(selectUserRole);
  const companies = useSelector(selectCompanies);
  const handleCompanyChange = (value: string) => {
    setNewTopic({ ...newTopic, companyId: value });
  };

  return (
    <React.Fragment>
      <Form.Item
        initialValue={newTopic?.title}
        name="title"
        label={getTranslations('TOPIC_FIELD_TITLE')}
        rules={[
          { required: true, message: getTranslations('GENERAL_REQUIRED') },
          { type: "string", min: 3, message: MIN_3_CHAR() },
          { type: "string", max: 200, message: getTranslations('TOPIC_FIELD_TITLE_ERROR_1') },
        ]}
      >
        <Input
          onChange={(event) => {
            setNewTopic({ ...newTopic, title: event.target.value });
          }}
          type="text"
          placeholder={getTranslations('TOPIC_FIELD_TITLE')}
        />
      </Form.Item>
      <CategoryField
        markRequired
        label={getTranslations('TOPIC_CATEGORY')}
        categories={categories}
        categoriesIds={categoriesIds}
        object={newTopic}
        setObject={setNewTopic}
      />

      {userRole === UserRole.SuperAdmin ? <Form.Item
        label={getTranslations('GENERAL_COMPANY')}
        name="company"
        initialValue={newTopic?.companyId || ''}
      >
        <Select
          placeholder=""
          onChange={handleCompanyChange}
          style={{ width: "100%" }}
          disabled={newTopic?.lessons && newTopic?.lessons.length > 0}
        >
          <Select.Option key={'all_companies'} value={''}>
            {getTranslations('COMPANY_ALL')}
          </Select.Option>
          {Object.values(companies).map(company => {
            return <Select.Option key={company._id} value={company._id}>
              {company.name}
            </Select.Option>
          })}
        </Select>
      </Form.Item> : null}

      <Divider />
    </React.Fragment>
  );
};
