import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../app/AllowedMicroservices";
import { RootState } from "../../app/store";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { FetchStatus } from "../common/enums";
import { IPdf } from "../knowledgeBase/contentSlice";

interface IContentDict {
  [_id: string]: IContent;
}

interface KnowledgeBaseState {
  list: string[];
  dict: IContentDict;
  status: FetchStatus;
  categories: IKBCategorySummary[];
}

const initialState: KnowledgeBaseState = {
  list: [],
  dict: {},
  status: FetchStatus.Idle,
  categories: [],
};

export interface IContent {
  _id: string;
  title: string;
  category: string;
  description: string;
  tags: string[];
  content: string;
  pdfs?: IPdf[];
  companyId: string;
  published: string; // might be changed to enum
  createdAt: Date;
  updatedAt: Date;
}

interface KnowledgeBaseFetch {
  docs: IContent[];
  limit: number;
  page: number;
  pages: number;
  total: number;
}

interface IKBCategorySummary {
  categoryId: string;
  contentCount: number;
  lastUpdate: string;
}

interface KnowledgeBaseFetch {
  docs: IContent[];
  limit: number;
  page: number;
  pages: number;
  total: number;
}

export const knowledgeBaseSlice = createSlice({
  name: "knowledgeBase",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetchedKnowledgeBasesSummary: (
      state,
      action: PayloadAction<IKBCategorySummary[]>
    ) => {
      state.categories = action.payload.map((summary) => summary);
      state.status = FetchStatus.Fetched;
    },
    fetchedKnowledgeBases: (
      state,
      action: PayloadAction<KnowledgeBaseFetch>
    ) => {
      state.list = action.payload.docs.map((content) => content._id);
      action.payload.docs.forEach((content) => {
        state.dict[content._id] = content;
      });
      state.status = FetchStatus.Fetched;
    },
    fetchedKnowledgeBase: (state, action: PayloadAction<IContent[]>) => {
      const currentList = state.list;
      const index = currentList.indexOf(action.payload[0]._id);
      if (index !== -1) {
        currentList.splice(index, 1);
      }
      state.list = currentList;
      action.payload.forEach((content) => {
        state.dict[content._id] = content;
      });
      state.status = FetchStatus.Fetched;
    },
    fetchedKnowledgeBasesHome: (
      state,
      action: PayloadAction<KnowledgeBaseFetch>
    ) => {
      state.list = action.payload.docs.map((content) => content._id);
      action.payload.docs.forEach((content) => {
        state.dict[content._id] = content;
      });
      state.status = FetchStatus.Fetched;
    },
    error: (state) => {
      state.status = FetchStatus.Error;
    },
  },
});

const {
  fetching,
  fetchedKnowledgeBase,
  fetchedKnowledgeBases,
  fetchedKnowledgeBasesSummary,
  fetchedKnowledgeBasesHome,
  error,
} = knowledgeBaseSlice.actions;

const BASE_ENDPOINT = "/students";

export const fetchKnowledgeBasesCategories = () => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_ENDPOINT}/categories`,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetchedKnowledgeBasesSummary,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.KNOWLEDGE_BASE_SERVICE,
      authenticated: true,
    })
  );
};

export const fetchKnowledgeBasesHome = () => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_ENDPOINT}/home?limit=3`,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetchedKnowledgeBasesHome,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.KNOWLEDGE_BASE_SERVICE,
      authenticated: true,
    })
  );
};

export interface FetchKBPostData {
  categoryId?: string;
  textToSearch?: string;
}

export const fetchKnowledgeBases = (data: FetchKBPostData) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_ENDPOINT}/content`,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetchedKnowledgeBases,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.POST,
      microservice: Microservices.KNOWLEDGE_BASE_SERVICE,
      authenticated: true,
      data: data,
    })
  );
};

export const fetchKnowledgeBase = (id: string) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_ENDPOINT}/content/${id}`,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetchedKnowledgeBase,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.KNOWLEDGE_BASE_SERVICE,
      authenticated: true,
    })
  );
};

export const selectKnowledgeBaseDict = (state: RootState) =>
  state.studentKnowledgeBase.dict;
export const selectKnowledgeBasesIds = (state: RootState) =>
  state.studentKnowledgeBase.list;
export const selectKnowledgeBaseFetchStatus = (state: RootState) =>
  state.studentKnowledgeBase.status;
export const selectIsKnowledgeBaseFetching = (state: RootState) =>
  state.studentKnowledgeBase.status === FetchStatus.Fetching;
export const selectKnowledgeBaseCategories = (state: RootState) =>
  state.studentKnowledgeBase.categories;
export const selectKnowledgeBase = (id: string) => (state: RootState) =>
  state.studentKnowledgeBase.dict[id];

export default knowledgeBaseSlice.reducer;
