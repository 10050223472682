import React, { useState } from "react";

import { Divider, Spin, Upload, message } from "antd";
import { RcFile } from 'antd/lib/upload';

import { Microservices } from "../../app/AllowedMicroservices";
import { BASE_URL } from "../../app/apiMiddleware";
import { cognitoUser } from "../../features/auth/authSlice";
import {
  IMedia,
  IVideoInfo,
  MediaTypes,
  VideoTypes,
} from "../../features/lesson/lessonSlice";
import { CogIcon } from "../common/CogIcon";
import { StepProps } from "./commonInterfaces";
import { LinkQuizToVideo } from "./LinkQuizToVideo";
import { MediaPreview } from "./MediaPreview";

import styles from "./form.module.scss";
import formStyles from '../common/form.module.scss';

import uploadIcon from '../../assets/admin/upload.svg';
import { VideoSwitcher } from "./videoSwitcher";
import { VideoYoutube } from "./VideoYoutube";
import { getTranslations } from "../../features/translations/translationsUtils";

const MAX_UPLOAD_PDFS = 3;

const MEDIA_OPTIONS = () => ({
  IMAGE: {
    maxSizeMB: 5,
    messageTypeError: getTranslations('LESSON_ALLOWED_IMAGES_TYPES'),
    description: <>
      {getTranslations('UPLOAD_FILE_MESSAGE_1')}<br />
      <span className={styles.fakeLink}>{getTranslations('UPLOAD_FILE_MESSAGE_2')}</span>
    </>,
    specifications: getTranslations('LESSON_ALLOWED_IMAGES_FORMAT'),
  },
  VIDEO: {
    maxSizeMB: 1000,
    messageTypeError: getTranslations('LESSON_ALLOWED_VIDEO_TYPES'),
    description: <>
      {getTranslations('UPLOAD_FILE_MESSAGE_1')}<br />
      <span className={styles.fakeLink}>{getTranslations('UPLOAD_FILE_MESSAGE_2')}</span>
    </>,
    specifications: getTranslations('LESSON_ALLOWED_VIDEO_FORMAT'),// "Tamaño máximo permitido 1000MB, PNP, JPG, GIF",
  },
  PDF: {
    messageTypeError: getTranslations('LESSON_ALLOWED_PDF_TYPES'),
    maxSizeMB: 5,
    description: <>
      {getTranslations('UPLOAD_FILE_MESSAGE_1')}<br />
      <span className={styles.fakeLink}>{getTranslations('UPLOAD_FILE_MESSAGE_2')}</span>
    </>,
    specifications: getTranslations('LESSON_ALLOWED_PDF_FORMAT'),
  },
});
const beforeUpload = (acceptedTypes: string, messageTypeError: string, maxSize: number, file: RcFile, FileList: RcFile[]) => {
  const validTypes = acceptedTypes.split(',');
  const isvalidType = validTypes.includes(file.type);
  if (!isvalidType) {
    message.error(messageTypeError);
  }
  const isSizeValid = file.size / 1024 / 1024 <= maxSize;
  if (!isSizeValid) {
    message.error(getTranslations('LESSON_UPLOAD_ERROR_1'));
  }
  return isvalidType && isSizeValid;
}

export const StepOne: React.FunctionComponent<StepProps> = ({
  newLesson,
  fileType,
  acceptedTypes,
  setNewLesson,
  setFileType,
  youtubeUrlError,
}) => {
  const [error, setError] = useState("");
  const [openLinkVideo, setOpenLinkVideo] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isVideoLocked, setIsVideoLocked] = useState(true);
  const mediaIsVideo = fileType === MediaTypes.VIDEO;
  const mediaIsPDF = fileType === MediaTypes.PDF;
  const mediaIsImage = fileType === MediaTypes.IMAGE;

  const handleDelete = (url: string) => {
    setNewLesson({
      ...newLesson,
      media: newLesson.media?.filter((media) => media.url !== url),
      quizCuePoints: [],
    });
  }

  const handleChangeFile = (info: any) => {
    const { status } = info.file;
    if (status === "uploading") {
      setIsLoading(true);
      setError("");
      return;
    }
    if (status === "done") {
      let updatedMedia: IMedia[] = [];
      if (newLesson.media) {
        updatedMedia = [...newLesson.media];
      }
      const url =
        mediaIsVideo
          ? info.file.response.videoUri
          : info.file.response.url;
      updatedMedia.push({
        type: fileType,
        url,
        filename: info.file.name,
        size: info.file.size,
        videoType: VideoTypes.FILE,
      });
      setNewLesson({ ...newLesson, media: updatedMedia });
      setIsLoading(false);
    } else if (status === "error") {
      setError(getTranslations('LESSON_UPLOAD_ERROR_2'));
    }
  };

  const onChangeVideoType = (type: VideoTypes) => {
    let updatedMedia: IMedia[] = [];
    if (newLesson.media) {
      updatedMedia = [...newLesson.media];
    }
    if (type === VideoTypes.FILE) {
      updatedMedia.pop();
    } else {
      updatedMedia.push({
        type: fileType,
        url: '',
        filename: 'youtube video',
        size: 0,
        videoType: VideoTypes.YOUTUBE,
      });
    }
    setNewLesson({ ...newLesson, media: updatedMedia });
  };

  const onChangeYoutubeVideoUrl = (url: string) => {
    const media = { ...newLesson.media[0] };
    media.url = url;
    setNewLesson({ ...newLesson, media: [media] });
  }

  let action: string = `${BASE_URL[Microservices.LEARNING_SERVICE]
    }/media/uploadToS3`;
  if (mediaIsVideo) {
    action = `${BASE_URL[Microservices.MEDIA_SERVICE]}/files/uploadToVimeo`;
  }
  let uploader = null;
  const videoUploaded = mediaIsVideo && newLesson.media?.length === 1 && newLesson.media[0]?.videoType !== VideoTypes.YOUTUBE;
  const hasYoutubeVideo = mediaIsVideo && newLesson.media?.length === 1 && newLesson.media[0]?.videoType === VideoTypes.YOUTUBE;
  const maxPDFsUploaded = mediaIsPDF && newLesson.media?.length >= MAX_UPLOAD_PDFS;
  if ((!videoUploaded && !maxPDFsUploaded) || hasYoutubeVideo) {
    let videoSwitcher = null;
    if (mediaIsVideo) {
      videoSwitcher = <VideoSwitcher type={newLesson.media[0]?.videoType} changeType={onChangeVideoType} />;
    }
    if (!hasYoutubeVideo) {
      uploader = <div className={formStyles.section}>
        {videoSwitcher}
        <Upload
          accept={acceptedTypes}
          headers={{
            Authorization:
              cognitoUser
                ?.getSignInUserSession()
                ?.getAccessToken()
                .getJwtToken() || "",
          }}
          action={action}
          showUploadList={false}
          onChange={handleChangeFile}
          className={styles.uploadContainer}
          beforeUpload={(file: RcFile, FileList: RcFile[]): boolean => {
            return beforeUpload(acceptedTypes, MEDIA_OPTIONS()[fileType].messageTypeError, MEDIA_OPTIONS()[fileType].maxSizeMB, file, FileList);
          }}
        >
          <div className={styles.uploadZone}>
            {isLoading && <Spin size="large" className={styles.spin} />}
            <img className={styles.icon} src={uploadIcon} alt="upload icon" />
            <p className={styles.description}>
              {MEDIA_OPTIONS()[fileType].description}
            </p>
            <p className={styles.specifications}>
              {MEDIA_OPTIONS()[fileType].specifications}
            </p>
          </div>
        </Upload>
      </div>;
    } else {
      uploader = <VideoYoutube error={youtubeUrlError || ''} url={newLesson.media[0]?.url} change={onChangeYoutubeVideoUrl} videoSwitcher={videoSwitcher} />;
    }
  }
  return (
    <>
      <div className={styles.formSubtitle}>{getTranslations('LESSON_CONTENT')}</div>
      <div className={styles.mediaContainer}>
        <button
          className={`${styles.mediaButton} ${mediaIsImage ? styles.selected : ""
            }`}
          onClick={() => {
            if (fileType !== MediaTypes.IMAGE) {
              setNewLesson({ ...newLesson, media: [] });
            }
            setFileType(MediaTypes.IMAGE);
          }}
          type="button"
        >
          <CogIcon size="39px" icon="Image" />
          <span>{getTranslations('LESSON_IMAGES')}</span>
        </button>
        <button
          className={`${styles.mediaButton} ${mediaIsVideo ? styles.selected : ""
            }`}
          onClick={() => {
            if (fileType !== MediaTypes.VIDEO) {
              setNewLesson({ ...newLesson, media: [] });
            }
            setFileType(MediaTypes.VIDEO);
          }}
          type="button"
        >
          <CogIcon size="39px" icon="video" />
          <span>{getTranslations('LESSON_VIDEO')}</span>
        </button>
        <button
          className={`${styles.mediaButton} ${mediaIsPDF ? styles.selected : ""
            }`}
          onClick={() => {
            if (fileType !== MediaTypes.PDF) {
              setNewLesson({ ...newLesson, media: [] });
            }
            setFileType(MediaTypes.PDF);
          }}
          type="button"
        >
          <CogIcon size="39px" icon="pdf" />
          <span>{getTranslations('LESSON_DOCUMENTS')}</span>
        </button>
      </div>
      <div className={styles.listMediaContainer}>
        {newLesson.media
          ?.filter((item) => item.type === fileType && item.videoType !== VideoTypes.YOUTUBE)
          .map((media) => (
            <MediaPreview
              key={media.url}
              media={media}
              onDelete={handleDelete}
              onUnlockVideo={(videoInfo: IVideoInfo) => {
                setIsVideoLocked(false);
                const { media: mediaList = [] } = newLesson;
                let media: IMedia = mediaList[0];
                if (!media) {
                  media = {
                    filename: "",
                    size: 0,
                    type: MediaTypes.VIDEO,
                    url: "",
                  };
                } else {
                  media = { ...media };
                }
                media.videoInfo = videoInfo;
                setNewLesson({ ...newLesson, media: [media] });
              }}
              isVideoLocked={isVideoLocked}
            />
          ))}
        {error !== "" && <p>{error}</p>}
      </div>
      {uploader}
      <Divider />
      {mediaIsVideo &&
        openLinkVideo &&
        newLesson?.media &&
        newLesson ? (
        <LinkQuizToVideo
          onClose={() => {
            setOpenLinkVideo(false);
          }}
          open={openLinkVideo}
          setNewLesson={setNewLesson}
          lesson={newLesson}
          videoId={newLesson?.media[0]?.url}
          quizId={newLesson.evaluation}
        />
      ) : null}
    </>
  );
};
