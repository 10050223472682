import React, { useState, FunctionComponent, useEffect } from 'react';

import { Form, Button, Input } from 'antd';
import cls from 'classnames';

import { Header } from '../Header';
import { Footer } from '../Footer';
import { ISignUpStepProps, layout } from './interfaces';
import { BASE_URL } from '../../../app/apiMiddleware';
import { Microservices } from '../../../app/AllowedMicroservices';

import commonStyles from '../Login.module.scss';
import styles from './index.module.scss';
import { getTranslations } from '../../../features/translations/translationsUtils';

// const {
//   loadCaptchaEnginge,
//   LoadCanvasTemplate,
//   validateCaptcha,
// } = require('react-simple-captcha');

interface Step1Props extends ISignUpStepProps {

};

export const Step1: FunctionComponent<Step1Props> = ({ next, userData }) => {
  const [email, setEmail] = useState<string>('');
  // const [captcha, setCaptcha] = useState<string>('');
  // const [captchaError, setCaptchaError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  useEffect(() => {
    // loadCaptchaEnginge(6);
    setEmail(userData.email || '');
    setEmail(userData.email || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSubmit = async () => {
    setEmailError('');
    // if (!validateCaptcha(captcha)) {
    //   setCaptchaError('Valor incorrecto');
    //   setCaptcha('');
    //   // loadCaptchaEnginge(6);
    //   return;
    // }
    try {
      const response = await fetch(`${BASE_URL[Microservices.COMPANY_SERVICE]}/user/verifyEmail`, {
        method: 'POST', headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });
      if (!response.ok) {
        // loadCaptchaEnginge(6);
        setEmailError('Error en el registro, intente más tarde');
        // setCaptcha('');
        // setCaptchaError('');
        return;
      }
    } catch (e) {
      // loadCaptchaEnginge(6);
      setEmailError('Error en el registro, intente más tarde');
      // setCaptcha('');
      // setCaptchaError('');
      return;
    }
    setEmailError('');
    // setCaptchaError('');
    // setCaptcha('');
    next({ email });
  };

  return <>
    <Form
      {...layout}
      name="basic"
      onFinish={(values: {
        password: string;
        confirmation: string;
        email: string;
      }) => {
        handleSubmit();
      }}
      onFinishFailed={() => {
        console.error('signup failed');
      }}

    >
      <div
        className={cls(commonStyles.formInnerContainer, commonStyles.overload)}
      >
        <Header title={getTranslations('LOGIN_WELCOME')}>
          <div className={styles.subTitles}>
            {getTranslations('LOGIN_SIGNUP_FIRST_STEP_MESSAGE')}
          </div>
        </Header>
        <Form.Item
          label={getTranslations('LOGIN_EMAIL')}
          name="email"
          rules={[{ required: true, message: getTranslations('LOGIN_EMAIL_PLACEHOLDER') }]}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValue={email}
        >
          <Input
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
        </Form.Item>
        {emailError ? <div className={styles.captchaError}>{emailError}</div> : <div className={styles.captchaError}>&nbsp;</div>}
        {/* <div className={styles.captchaContainer}>
              <div className={styles.captchaTitle}>
                Escribe abajo lo que ves en la imagen
              </div>
              <div className={styles.captchaImage}>
                <LoadCanvasTemplate reloadText="Recargar" />
              </div>
              <div className={styles.captchaInput}>
                <Input
                  value={captcha}
                  onChange={(event) => {
                    setCaptcha(event.target.value);
                  }}
                />
                {captchaError ? <div className={styles.captchaError}>{captchaError}</div> : <div className={styles.captchaError}>&nbsp;</div>}
              </div>
            </div> */}
        <Button
          type="primary"
          htmlType="submit"
          disabled={false}
          className={cls(commonStyles.signupBtn, styles.signupBtn)}
        >
          {getTranslations('LOGIN_LOGIN_BUTTON')}
        </Button>
        <Footer
          className={cls()}
          title={getTranslations('LOGIN_HAVE_ACCOUNT_BUTTON')}
          linkLabel={getTranslations('LOGIN_LOGIN_HERE')}
          linkRef={'/login'}
        />
      </div>
    </Form>
  </>;
};
