import React, { FunctionComponent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { CompanyWithContentVisits } from '..'
import { PIECHART_COLORS } from '../../../../features/reports/common';
import { selectUsersIds } from '../../../../features/users/usersSlice';
import LoadingOverlay from '../../../common/LoadingOverlay';
import { RoundedContainer } from '../../../common/RoundedContainer'
import { StatData, StatsRow } from '../../common/StatsRow';
import { useKBDataIsFetching } from '../common/useDataIsFetching';

import styles from './index.module.scss'
import { getTranslations } from '../../../../features/translations/translationsUtils';

interface Props {
  companiesWithVisits: CompanyWithContentVisits[],
}

export const StudentsViewingContents: FunctionComponent<Props> = ({ companiesWithVisits }) => {
  const dataIsFetching = useKBDataIsFetching();
  const studentsIds = useSelector(selectUsersIds);
  const [studentsVisitsToContent, setStudentsVisitsToContent] = useState<number>(0);

  useEffect(() => {
    if (dataIsFetching) {
      return;
    }
    const studentsIdsSet = new Set([...studentsIds]);
    let studentsViewingContentsCount = 0;

    companiesWithVisits.forEach(data => {
      const intersection = new Set(
        [...studentsIdsSet].filter(x => data.visitorUserIds.has(x)));
      studentsViewingContentsCount += intersection.size;
    });
    setStudentsVisitsToContent(studentsViewingContentsCount);
  }, [dataIsFetching, companiesWithVisits, studentsIds]);

  const totalStudentsCount = studentsIds.length;
  const percentage = totalStudentsCount > 0 ? (100 * studentsVisitsToContent) / totalStudentsCount : 0;
  const percentageFormatted = Math.round(percentage);

  const statsData: StatData = [
    {
      percentValue: percentageFormatted.toFixed(2),
      description: <div className={styles.description}>{getTranslations('REPORT_PERCENT_OF_STUDENT_INQUIRING')}</div>,
      canvasHeight: 150,
      type: '1',
      data: {
        labels: [
          getTranslations('REPORT_KNOWLEDGE_BASE_CONSULTED'),
          getTranslations('REPORT_KNOWLEDGE_BASE_NO_CONSULTED'),
        ],
        datasets: [{
          label: 'My First Dataset',
          data: [percentageFormatted, 100 - percentageFormatted],
          backgroundColor: [
            PIECHART_COLORS[1],
            PIECHART_COLORS[0],
          ],
          hoverOffset: 4
        }],
      },
    }
  ]

  return (
    <RoundedContainer>
      <div className={styles.wrapper}>
        {dataIsFetching ?
          (<LoadingOverlay inline height={200} spinning />) :
          <StatsRow statsData={statsData} />
        }
      </div>
    </RoundedContainer>
  )
}

