import React from 'react';
import { useSelector } from 'react-redux';

import { selectTranslationsAdminTranslations } from '../../features/translationsAdmin/translationsAdminSlice';

import styles from './translationsPane.module.scss';
import { TranslationItem } from './TranslationItem';

interface ITranslationPaneProps {
  translationKey: string;
  selectedLanguage: string;
}

export const TranslationPane: React.FunctionComponent<ITranslationPaneProps> = ({ translationKey, selectedLanguage }) => {
  const translations = useSelector(selectTranslationsAdminTranslations(translationKey));

  return <div className={styles.container}>
    {translations.map((key) => <TranslationItem itemKey={key} key={key} selectedLanguage={selectedLanguage} />)}
  </div>;
};