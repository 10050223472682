import React, { FunctionComponent, useEffect } from "react";

import * as H from "history";
import { useDispatch } from "react-redux";
import { Router, Switch, Route } from "react-router-dom";

import { clearBreadcrumbs } from "../../features/breadcrumbs/breadcrumbsSlice";
import { ActionSuffixes, Prefixes } from "../prefixes";
import { Topics } from './index';
import { NewTopic } from "./new";
import { EditTopic } from "./edit";

interface AdminRouterProps {
  history: H.History<any>;
}

const prefix = Prefixes.topic;

export const TopicRouter: FunctionComponent<AdminRouterProps> = ({
  history,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    return history.listen((location) => {
      dispatch(clearBreadcrumbs());
    });
  }, [dispatch, history]);
  return <Router history={history}>
    <Switch>
      <Route path={`${prefix}${ActionSuffixes.new}`} exact>
        <NewTopic />
      </Route>
      <Route path={`${prefix}/:id${ActionSuffixes.edit}`} exact>
        <EditTopic />
      </Route>
      <Route path={prefix}>
        <Topics />
      </Route>
    </Switch>
  </Router>;
};