import { Input as AntInput } from "antd";
import ctx from "classnames";
import React, { useEffect, useState } from "react";
import {
  ICompanyDict,
} from "../../features/company/companySlice";
import styles from "./companySideFilter.module.scss";
import { SearchOutlined } from "@ant-design/icons";
import { getTranslations } from "../../features/translations/translationsUtils";

interface CompanyFilterProps {
  list: ICompanyDict;
  companyId: string | null;
  setCompanyId: (compnayId: string) => void;
}

export const CompanySideFilter: React.FunctionComponent<CompanyFilterProps> = ({
  list,
  companyId,
  setCompanyId,
}) => {
  const [companyName, setCompanyName] = useState("");
  const companies = Object.values(list);

  useEffect(() => {
    if (companies.length > 0 && !companyId) {
      setCompanyId(companies[0]._id);
    }
  }, [companies, companyId, setCompanyId]);

  return (
    <>
      <AntInput
        placeholder={getTranslations('COMPANY_SIDE_FILTER_SEARCH_COMPANIES')}
        prefix={<SearchOutlined className='search-icon' />}
        className={ctx(styles.search, styles.wrapper, 'custom-search')}
        onChange={(event) => {
          setCompanyName(event.target.value.trim());
        }}
      />
      <div className={styles.companies}>
        {companies
          .filter((company) =>
            company.name.toUpperCase().includes(companyName.toUpperCase())
          )
          .map((company) => {
            return (
              <div
                onClick={() => {
                  setCompanyId(company._id);
                }}
                className={ctx(styles.company, {
                  [styles.selected]: companyId === company._id,
                })}
                key={company._id}
                title={company.name}
              >
                {company.name}
              </div>
            );
          })}
      </div>
    </>
  );
};
