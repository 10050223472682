import React from "react";

import { Form, Input, Select } from "antd";
import { useSelector } from "react-redux";

import { selectUserRole } from "../../features/auth/authSlice";
import { selectCompanies } from "../../features/company/companySlice";
import { UserRole } from "../../features/users/usersSlice";
import { CategoryField } from "../common/CategoryField";
import { MIN_3_CHAR } from "../Users/Form";
import { StepProps } from "./commonInterfaces";
import { getTranslations } from "../../features/translations/translationsUtils";

export const StepZero: React.FunctionComponent<StepProps> = ({
  newLesson,
  form,
  categories,
  categoriesIds,
  setNewLesson,
}) => {
  const userRole = useSelector(selectUserRole);
  const companies = useSelector(selectCompanies);

  const handleCompanyChange = (value: string) => {
    setNewLesson({ ...newLesson, companyId: value });
  };
  return (
    <>
      <Form.Item
        name="title"
        label={getTranslations('LESSON_TITLE_FIELD')}
        initialValue={newLesson?.title || ""}
        rules={[
          { required: true, message: getTranslations('GENERAL_REQUIRED') },
          { type: "string", min: 3, message: MIN_3_CHAR() },
          { type: "string", max: 200, message: getTranslations('LESSON_TITLE_FIELD_ERROR_1') },
        ]}
      >
        <Input
          onChange={(event) => {
            setNewLesson({ ...newLesson, title: event.target.value });
          }}
          type="text"
          placeholder={getTranslations('LESSON_TITLE_FIELD')}
        />
      </Form.Item>

      <CategoryField
        markRequired
        categories={categories}
        categoriesIds={categoriesIds}
        label={getTranslations('LESSON_CATEGORY')}
        object={newLesson}
        setObject={setNewLesson}
        initialValue={true}
      />

      {userRole === UserRole.SuperAdmin ? (
        <Form.Item
          label={getTranslations('GENERAL_COMPANY')}
          name="companyId"
          initialValue={newLesson?.companyId || ""}
        >
          <Select
            placeholder=""
            onChange={handleCompanyChange}
            style={{ width: "100%" }}
          >
            <Select.Option key={"all_companies"} value={""}>
              {getTranslations('COMPANY_ALL')}
            </Select.Option>
            {Object.values(companies).map((company) => {
              return (
                <Select.Option key={company._id} value={company._id}>
                  {company.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      ) : null}
    </>
  );
};
