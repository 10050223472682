import React from 'react';

const debug = false;
export interface  ITranslationsDict {
  [key: string]: string;
}
let translationsDict: ITranslationsDict = {};
export const setTranslationsDict = (data: ITranslationsDict) => {
  translationsDict = data;
}

export const getTranslations = (key: string, values?: (string|number)[]): string => {
  if (debug) {
    let extra = (values ?? []).length === 0 ? '' : ` (${(values ?? []).join(', ')})`;
    return `${translationsDict[key] === undefined ? '** ' : ''}${key}${extra}`;
  }
  if (!translationsDict[key]) {
    return key;
  }
  let text = translationsDict[key];
  (values ?? []).forEach((value, index) => {
    text = text.replace(`{{%${index + 1}}}`, `${value}`);
  });
  return text;
}

export const getTranslationsJSX = (key: string, value: JSX.Element) => {
  if (debug) {
    return <>
      {translationsDict[key] === undefined ? '** ' : ''}{key}{value}
    </>;
  }
  if (!translationsDict[key]) {
    return translationsDict[key];
  }
  const item = `{{%1}}`;
  const position = translationsDict[key].indexOf(item);
  if (position === 0) {
    return <>
      {value}
      {translationsDict[key]}
    </>;
  }
  if (position === translationsDict[key].length - item.length - 1) {
    return <>
      {translationsDict[key]}
      {value}
    </>;
  }
  let text = translationsDict[key].split(item);
  return <>
    {text[0]}
    {value}
    {text[1]}
  </>;
}