import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IGamification, saveGamification, selectGamification, selectGamificationIsFetching } from "../../features/gamification/gamificationSlice";
import LoadingOverlay from "../common/LoadingOverlay";
import { Button, Checkbox, Col, Form, InputNumber, Row } from "antd";

import commonStyles from "../common/form.module.scss";
import styles from "./settings.module.scss";
import { FormFooter } from "../common/FormFooter";
import { Badge } from "../Profile/Infographic/Badge";
import { BadgeType } from "../Profile/Infographic/ImagePerType";
import { getTranslations } from "../../features/translations/translationsUtils";

interface ISettings {
  companyId: string;
}

export const Settings: FunctionComponent<ISettings> = ({
  companyId
}) => {
  const fetching = useSelector(selectGamificationIsFetching);
  const gamification = useSelector(selectGamification);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [gamificationCopy, setGamificationCopy] = useState<IGamification | null>(null);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(gamification);
    setGamificationCopy(gamification);
  }, [form, gamification]);

  if (fetching) {
    return <LoadingOverlay spinning />;
  }

  if (!gamification || !gamificationCopy) {
    return <LoadingOverlay spinning />;
  }

  const pointsFields = {
    login: getTranslations('GAMIFICATION_START_SESSION'),
    finishLesson: getTranslations('GAMIFICATION_FINISH_LESSON'),
    finishTopic: getTranslations('GAMIFICATION_FINISH_TOPIC'),
    finishCourse: getTranslations('GAMIFICATION_FINISH_COURSE'),
    certification: getTranslations('GAMIFICATION_GET_CERTIFICATE'),
    finishTest: getTranslations('GAMIFICATION_FINISH_TEST'),
    finishExam: getTranslations('GAMIFICATION_FINISH_EXAM'),
    finishSurvey: getTranslations('GAMIFICATION_FINISH_SURVEY'),
  };


  const badgesFields = {
    activityBadges: getTranslations('GAMIFICATION_ACTIVITY_BADGE_DESCRIPTION'),
    learningBadges: getTranslations('GAMIFICATION_LEARNING_BADGE_DESCRIPTION'),
    opinionBadges: getTranslations('GAMIFICATION_OPINION_BADGE_DESCRIPTION'),
    knowledgeBadges: getTranslations('GAMIFICATION_KNOWLEDGE_BADGE_DESCRIPTION'),
  };

  const badgeTypes = {
    activityBadges: BadgeType.activity,
    learningBadges: BadgeType.learning,
    opinionBadges: BadgeType.opinion,
    knowledgeBadges: BadgeType.knowledge,
  }
  const getPointsIndeterminate = () => {
    let allTrue = true;
    let allFalse = false;
    Object.keys(pointsFields).forEach(field => {
      const value = form.getFieldValue(field) !== -1;
      allFalse = allFalse || value;
      allTrue = allTrue && value;
    });
    allFalse = !allFalse;
    return !allTrue && !allFalse;
  }
  const getPointsChecked = () => {
    let allTrue = true;
    Object.keys(pointsFields).forEach(field => {
      const value = form.getFieldValue(field) !== -1;
      allTrue = allTrue && value;
    });
    return allTrue;
  }
  const checkAllPoints = (value: boolean) => {
    Object.keys(pointsFields).forEach(field => {
      if (value) {
        const prevValue = form.getFieldValue(field);
        form.setFieldValue(field, prevValue === -1 ? 1 : prevValue);
        return;
      }
      form.setFieldValue(field, -1);
    });
  }


  const getBadgesIndeterminate = () => {
    let allTrue = true;
    let allFalse = false;
    Object.keys(badgesFields).forEach(field => {
      const value = form.getFieldValue(field);
      allFalse = allFalse || value;
      allTrue = allTrue && value;
    });
    allFalse = !allFalse;
    return !allTrue && !allFalse;
  }
  const getBadgesChecked = () => {
    let allTrue = true;
    Object.keys(badgesFields).forEach(field => {
      const value = form.getFieldValue(field);
      allTrue = allTrue && value;
    });
    return allTrue;
  }
  const checkAllBadges = (value: boolean) => {
    Object.keys(badgesFields).forEach(field => {
      form.setFieldValue(field, value);
    });
  }
  const onResetPoints = () => {
    Object.keys(pointsFields).forEach((field) => {
      form.setFieldValue(field, 1);
    });
  };

  const onResetBadges = () => {
    Object.keys(badgesFields).forEach((field) => {
      form.setFieldValue(field, true);
    });
  };
  return <div>
    <Form
      form={form}
      className={commonStyles.form}
      layout="vertical"
      name="control-hooks"
      initialValues={gamificationCopy ?? {}}

    >
      <div className={commonStyles.section}>
        <div className={commonStyles.sectionTitle}>{getTranslations('GAMIFICATION_POINTS')}
          <Button onClick={onResetPoints} type="link">{getTranslations('GAMIFICATION_FACTORY_RESET')}</Button>
        </div>
        <div className={commonStyles.sectionDescription}>{getTranslations('GAMIFICATION_POINTS_DESCRIPTION')}</div>
        <Form.Item shouldUpdate>
          {() => {
            return <Checkbox onChange={({ target: { checked } }) => {
              checkAllPoints(checked);
            }} indeterminate={getPointsIndeterminate()} checked={getPointsChecked()}>Todos</Checkbox>;
          }}
        </Form.Item>
        {Object.keys(pointsFields).map((field) => {
          return <Row key={field} gutter={50} className={styles.row}>
            <Col span={12}>
              <Form.Item shouldUpdate>
                {() => {
                  return <Checkbox checked={form.getFieldValue(field) !== -1} onChange={(({ target: { checked } }) => {
                    if (checked) {
                      form.setFieldValue(field, 1);
                      return;
                    }
                    form.setFieldValue(field, -1);
                  })}>
                    {pointsFields[field as keyof typeof pointsFields]}
                  </Checkbox>;
                }}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item shouldUpdate>
                {() => {
                  return <Form.Item name={field}>
                    <InputNumber min={form.getFieldValue(field) === -1 ? -1 : 1} disabled={form.getFieldValue(field) === -1} addonAfter={getTranslations('GAMIFICATION_POINTS')} />
                  </Form.Item>;
                }}
              </Form.Item>
            </Col>

          </Row>
        })}
      </div>
      <div className={commonStyles.section}>
        <div className={commonStyles.sectionTitle}>{getTranslations('GAMIFICATION_BADGED')}
          <Button onClick={onResetBadges} type="link">{getTranslations('GAMIFICATION_FACTORY_RESET')}</Button>
        </div>
        <div className={commonStyles.sectionDescription}>{getTranslations('GAMIFICATION_BADGES_DESCRIPTION')}</div>
        <Form.Item shouldUpdate>
          {() => {
            return <Checkbox onChange={({ target: { checked } }) => {
              checkAllBadges(checked);
            }} indeterminate={getBadgesIndeterminate()} checked={getBadgesChecked()}>{getTranslations('GAMIFICATION_BADGES_ALL')}</Checkbox>;
          }}
        </Form.Item>
        {Object.keys(badgesFields).map((field) => {
          return <Row key={field} gutter={0}>
            <Col span={21}>
              <Form.Item name={field} valuePropName="checked">
                <Checkbox>
                  {badgesFields[field as keyof typeof badgesFields]}
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item shouldUpdate>
                {() => {
                  return <Badge
                    small
                    disabled={!form.getFieldValue(field)}
                    points={form.getFieldValue(field) ? 16 : 0}
                    type={badgeTypes[field as keyof typeof badgeTypes]}
                  />
                }}
              </Form.Item>
            </Col>
          </Row>
        })}
      </div>
    </Form>
    <FormFooter
      justSave
      editing
      objectName=""
      onCancel={() => {
        form.resetFields();
      }}
      onSave={() => {
        dispatch(saveGamification(companyId, form.getFieldsValue()));
      }}
      onSaveAndCreateAnother={() => {
        dispatch(saveGamification(companyId, form.getFieldsValue()));
      }}
    />
  </div>;
};
