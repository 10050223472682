import React, { FunctionComponent } from 'react';

import { useSelector } from 'react-redux';
import cls from 'classnames';

import { Content } from './Content';
import { useDeviceSize } from '../../../utils/useDeviceSize';
import { SIZES } from '../../../common/screen';

import styles from './interestingContent.module.scss';
import { getTranslations } from '../../../../features/translations/translationsUtils';
import { selectCompanyLinks } from '../../../../features/company/companySlice';
import { getCognitoUserData } from '../../../../features/auth/authSlice';
import { selectUser } from '../../../../features/users/usersSlice';

export const OtherLinks: FunctionComponent<{}> = () => {
  const cognitoUserData = useSelector(getCognitoUserData);
  const cognitoUsername = cognitoUserData
    ? cognitoUserData["cognito:username"]
    : null;
  const userFromStore = useSelector(selectUser(cognitoUsername));
  const companyId = userFromStore?.company ?? "";
  const links = useSelector(selectCompanyLinks(companyId))
  const size = useDeviceSize();
  const isMobile = size === SIZES.PHONE;
  const OTHER_LINKS = getTranslations('HOME_OTHER_LINKS')
  if (!links) {
    return null;
  }
  if (isMobile) {
    return <div className={cls(styles.container,styles.mobile)}>
      <div className={styles.title}>{OTHER_LINKS}</div>
      <div className={styles.carousel}>
      {links.map((link, index) => {
        return <Content key={index} link={link} />;
      })}
      </div>
    </div>;
  }

  return <div className={styles.container}>
    <div className={styles.title}>{OTHER_LINKS}</div>
    <div className={styles.contents}>
    {links.map((link, index) => {
        return <Content key={index} link={link} />;
      })}
    </div>
  </div>;
};