import React from "react";

import { Radio, RadioChangeEvent } from "antd";

import { VideoTypes } from "../../features/lesson/lessonSlice";

import styles from './videoSwitcher.module.scss';
import { getTranslations } from "../../features/translations/translationsUtils";

interface IVideoSwitcher {
  type: VideoTypes | undefined;
  changeType: (type: VideoTypes) => void;
}

export const VideoSwitcher: React.FunctionComponent<IVideoSwitcher> = ({ type = VideoTypes.FILE, changeType }) => {
  const options = [
    { label: getTranslations('LESSON_UPLOAD_VIDEO'), value: VideoTypes.FILE },
    { label: getTranslations('LESSON_YOUTUBE_LINK'), value: VideoTypes.YOUTUBE },
  ];
  const onChange = ({ target: { value } }: RadioChangeEvent) => {
    changeType(value);
  };
  return <div className={styles.container}>
    <Radio.Group
      className={styles.switch}
      options={options}
      onChange={onChange}
      value={type}
      optionType="button"
      buttonStyle="solid"
    />
  </div>;
};
