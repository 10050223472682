import React, { FunctionComponent } from 'react';

import { useSelector } from 'react-redux';
import cls from 'classnames';

import { selectKnowledgeBasesIds } from '../../../../features/students/knowledgeBaseSlice';
import { Content } from './Content';
import { useDeviceSize } from '../../../utils/useDeviceSize';
import { SIZES } from '../../../common/screen';

import styles from './interestingContent.module.scss';
import { getTranslations } from '../../../../features/translations/translationsUtils';
import { getCognitoUserData } from '../../../../features/auth/authSlice';
import { selectCompanies } from '../../../../features/company/companySlice';

export const InterestingContent: FunctionComponent<{}> = () => {
  const cognitoUserData = useSelector(getCognitoUserData);
  const companiesDict = useSelector(selectCompanies);
  const contentIds = useSelector(selectKnowledgeBasesIds);
  const size = useDeviceSize();
  const isMobile = size === SIZES.PHONE;
  const INTERESTING_ARTICLES = getTranslations('HOME_INTERESTING_ARTICLES');
  const NO_INTERESTING_ARTICLES = getTranslations('HOME_NO_INTERESTING_ARTICLES');
  
  const companyId = cognitoUserData
  ? cognitoUserData["custom:company"]
  : null;
  const company = companiesDict[companyId ?? ''];
  const { showKnowledgeBase } = company ?? {};

  if (!showKnowledgeBase) {
    return null;
  }

  if (isMobile) {
    return <div className={cls(styles.container,styles.mobile)}>
      <div className={styles.title}>{INTERESTING_ARTICLES}</div>
      <div className={styles.carousel}>
      {contentIds.length === 0 ? NO_INTERESTING_ARTICLES : null}
      {contentIds.map((contentId) => {
        return <Content key={contentId} contentId={contentId} />;
      })}
      </div>
    </div>;
  }

  return <div className={styles.container}>
    <div className={styles.title}>{INTERESTING_ARTICLES}</div>
    <div className={styles.contents}>
    {contentIds.length === 0 ? NO_INTERESTING_ARTICLES : null}
    {contentIds.map((contentId) => {
      return <Content key={contentId} contentId={contentId} />;
    })}
    </div>
  </div>;
};