import { Button, Dropdown, Input, Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import ctx from "classnames";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCompanies } from "../../features/company/companySlice";
import {
  fetchGroups,
  IGroup,
  selectGroups,
} from "../../features/groups/groupSlice";
import styles from "./shared.module.scss";
import { SearchOutlined } from "@ant-design/icons";
import { getTranslations } from "../../features/translations/translationsUtils";

const { Search } = Input;

interface CompanyFilteMenuProps {
  closeMenu: Function;
  showGroups?: boolean;
  onApply: React.Dispatch<React.SetStateAction<(string | number)[]>>;
}
const FilterMenu: FunctionComponent<CompanyFilteMenuProps> = ({
  onApply,
  closeMenu,
  showGroups = false,
}) => {
  const [selectedCompanyId, setValue] = useState<string>("");
  const [selectedGroupId, setSelectedGroup] = useState<string>("");
  const [filterCompanyText, setFilterCompanyTextValue] = useState<string>("");
  const [filterGroupText, setFilterGroupTextValue] = useState<string>("");
  const [companyGroups, setCompanyGroups] = useState<IGroup[]>([]);
  const companiesDict = useSelector(selectCompanies);
  const groups = useSelector(selectGroups);
  const dispatch = useDispatch();
  const plainOptions = Object.keys(companiesDict)
    .map((companyId) => ({
      label: companiesDict[companyId].name,
      value: companyId,
    }))
    .filter((company) => {
      const textToSeach = (filterCompanyText.toLowerCase() || "").trim();
      if (!textToSeach) {
        return true;
      }
      return company.label.toLowerCase().includes(textToSeach);
    });
  const groupOptions = companyGroups
    .map((group) => ({ label: group.name, value: group._id }))
    .filter((item) => {
      const textToSeach = (filterGroupText.toLowerCase() || "").trim();
      if (!textToSeach) {
        return true;
      }
      return item.label.toLowerCase().includes(textToSeach);
    });
  const onChange = (e: RadioChangeEvent) => {
    const companyId = e.target.value;
    if (companyId) {
      setValue(companyId || "");
      setCompanyGroups(groups.filter((group) => group.company === companyId));
    }
  };
  const onChangeGroup = (e: RadioChangeEvent) => {
    const groupId = e.target.value;
    if (groupId) {
      setSelectedGroup(groupId || "");
    }
  };
  const onApplyFilter = () => {
    const filters: string[] = [];
    if (selectedCompanyId !== "") {
      filters.push(selectedCompanyId);
    }
    if (selectedGroupId !== "" && showGroups) {
      filters.push(selectedGroupId);
    }
    onApply(filters);
    closeMenu();
  };
  const onClearFilter = () => {
    onApply([]);
    closeMenu();
    setValue("");
    setFilterCompanyTextValue("");
  };

  useEffect(() => {
    setSelectedGroup("");
    setFilterGroupTextValue("");
  }, [selectedCompanyId]);

  useEffect(() => {
    if (groups.length === 0) {
      dispatch(fetchGroups());
    }
  }, [groups, dispatch]);

  return (
    <div className={styles.filterMenu}>
      <div className={styles.wrapper}>
        <div className={styles.innerContainer}>
          <div className={styles.inpuntContainer}>
            <Input
              prefix={<SearchOutlined className='search-icon' />}
              className={ctx(styles.search, styles.wrapper, 'custom-search')}
              onChange={(event) => {
                setFilterCompanyTextValue(event.target.value)
              }}
            />
          </div>
          {
            <Radio.Group
              className={ctx(styles.radioGroup, styles.vertical)}
              options={plainOptions}
              onChange={onChange}
              value={selectedCompanyId}
            />
          }
        </div>
        {selectedCompanyId !== "" && companyGroups.length > 0 && showGroups && (
          <div className={styles.innerContainer}>
            <div className={styles.inpuntContainer}>
              <Search
                onChange={(event) =>
                  setFilterGroupTextValue(event.target.value)
                }
                className={styles.searchInput}
              />
            </div>
            {
              <Radio.Group
                className={ctx(styles.radioGroup, styles.vertical)}
                options={groupOptions}
                onChange={onChangeGroup}
                value={selectedGroupId}
              />
            }
          </div>
        )}
      </div>
      <div className={styles.actionButton}>
        <Button
          disabled={!selectedCompanyId}
          type="link"
          onClick={onClearFilter}
        >
          {getTranslations('COMPANY_FILTER_CLEAR')}
          
        </Button>
        <Button type="link" onClick={onApplyFilter}>
          {getTranslations('COMPANY_FILTER_APPLY')}
        </Button>
      </div>
    </div>
  );
};
interface CompanyFilterProps {
  type?: "report";
  showGroups?: boolean;
  reset?: boolean;
  onApply: React.Dispatch<React.SetStateAction<(string | number)[]>>;
}

export const CompanyFilter: FunctionComponent<CompanyFilterProps> = ({
  type,
  showGroups,
  onApply,
  reset,
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [companyIds, setCompanyIds] = useState<(string | number)[]>([]);
  const companiesDict = useSelector(selectCompanies);

  useEffect(() => {
    if (reset) {
      setCompanyIds([]);
    }
  }, [reset]);

  const getFilterLabel = (): string => {
    if (!type) {
      return getTranslations('COMPANY_FILTER_TITLE');
    }
    if (companyIds.length > 0) {
      return companiesDict[companyIds[0]]?.name ?? "";
    }
    return getTranslations('COMPANY_FILTER_TITLE');
  };

  const getClassName = (): string => {
    if (!type) {
      return styles.filterButton;
    }
    if (companyIds.length > 0) {
      return ctx(styles.filterButton, styles.company);
    }
    return ctx(styles.filterButton, styles.report);
  };

  useEffect(() => {
    onApply(companyIds);
  }, [companyIds, onApply]);

  return (
    <Dropdown
      overlay={
        !reset ? (
          <FilterMenu
            closeMenu={() => setVisible(false)}
            showGroups={showGroups}
            onApply={setCompanyIds}
          />
        ) : (
          <></>
        )
      }
      trigger={["click"]}
      onVisibleChange={(visible) => setVisible(visible)}
      visible={visible}
    >
      <div className={getClassName()}>
        <div className={styles.filterIcon}>
          <div className={styles.filterIconTopLine} />
          <div className={styles.filterIconBottomLine} />
        </div>
        {getFilterLabel()}
      </div>
    </Dropdown>
  );
};
