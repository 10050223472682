import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import cls from "classnames";
import {
  Button,
  Form,
} from 'antd';

import { Header } from "./Header";
import { ContainerWithCarousel } from './ContainerWithCarousel';
import { PasswordInput } from "./PasswordInput";
import { cognizantPasswordContraints } from "../utils/passwordConstraint";
import {
  loggedIn,
  markEmailAsVerified,
  cognitoUsername,
  setFirstNewPassword,
  cognitoGivenName,
  cognitoUserEmail,
} from "../../features/auth/authSlice";
import { PasswordRequirements } from "./PasswordRequirements";
import { DialogPopUp } from "../common/DialogPopUp";

import styles from "./PasswordNew.module.scss";
import { getTranslations } from "../../features/translations/translationsUtils";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export function FinishRegistration() {
  const username = useSelector(cognitoUsername);
  const givenName = useSelector(cognitoGivenName);
  const userEmail = useSelector(cognitoUserEmail);
  const [password, setPassword] = useState<string>("");
  const [passwordConfirmation, setPaswrdConfirmation] = useState<string>("");
  const [passwordDontMatchError, setPasswordDontMatchError] = useState<string>(
    ""
  );
  const [isModalVisible, setIsModalVisble] = useState<boolean>(false);
  const [allIsFulfiled, setAllIsFulfiled] = useState<boolean>(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoggedIn = useSelector(loggedIn);

  const handleSubmit = () => {
    if (password !== passwordConfirmation) {
      setPasswordDontMatchError("Contraseñas no son iguales");
      return;
    }
    const { valid, errors } = cognizantPasswordContraints(password);
    if (!valid) {
      errors.forEach((error) => console.error(error));
      return;
    }
    dispatch(markEmailAsVerified(username));
    dispatch(setFirstNewPassword(password));
  };

  useEffect(() => {
    if (isLoggedIn) {
      history.push("/welcome");
    }
    if (!username) {
      history.replace("/login");
    }
  });

  return (
    <React.Fragment>
      <ContainerWithCarousel>
        <DialogPopUp
          name="¡Contraseña"
          action="actualizada!"
          visible={isModalVisible}
          onCancel={() => {
            setIsModalVisble(false);
          }}
        />
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={(values: { password: string, confirmation: string }) => {
            handleSubmit();
          }}
          onFinishFailed={() => { console.error('on finish failed') }}
        >
          <div className={cls(styles.innerContainer)}>
            <Header
              className={styles.header}
              titleClassname={cls(styles.headerTitle)}
              subTitleClassname={cls(styles.headerSubTitle)}
              title={<>{getTranslations('LOGIN_HELLO', [givenName])},<br />{getTranslations('LOGIN_CREATE_PASSWORD')}</>}
            >
              <div className={styles.welcomeMessage}>
                <div>{userEmail}</div>
              </div>
            </Header>
            <Form.Item
              label={getTranslations('LOGIN_PASSWORD')}
              name="password"
              rules={[{ required: true, message: getTranslations('LOGIN_PASSWORD_ERROR') }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <PasswordInput onChange={setPassword} />
            </Form.Item>
            <Form.Item
              label={getTranslations('LOGIN_REPEAT_PASSWORD')}
              name="confirmation"
              rules={[{ required: true, message: getTranslations('LOGIN_REPEAT_PASSWORD_ERROR') }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <PasswordInput onChange={setPaswrdConfirmation} showMeter={false} />
            </Form.Item>
            {passwordDontMatchError ? <div className={cls(styles.errorMsg, 'error')}>{passwordDontMatchError}</div> : null}
            <PasswordRequirements
              password={password}
              onAllFulfiledChange={(allIsFulfiled: boolean) => {
                setAllIsFulfiled(allIsFulfiled);
              }}
            />
            <Button
              disabled={!allIsFulfiled || passwordConfirmation.length === 0}
              className={cls(styles.resetBtn)}
              type="primary"
              htmlType="submit"
            >
              {getTranslations('LOGIN_SET_PASSWORD')}
            </Button>
          </div>
        </Form>
      </ContainerWithCarousel>
    </React.Fragment>
  );
}
