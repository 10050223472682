import React from "react";
import { useSelector } from "react-redux";
import { selectCasesByReportTypeData } from "../../../../features/reports/case/ticketsByReportType";
import { selectCaseResolvedData } from "../../../../features/reports/case/ticketsByStatus";
import { DoughnutSimpleType } from "../../common/Doughnut";
import { StatsRow } from "../../common/StatsRow";
import { getTotal } from "../CasesStats";
import { getTranslations } from "../../../../features/translations/translationsUtils";

export const CasesDoughnut: React.FunctionComponent = () => {
  const data = useSelector(selectCasesByReportTypeData);
  const resolved = useSelector(selectCaseResolvedData);
  const percentage = Number(
    ((getTotal(resolved) / getTotal(data)) * 100).toFixed(1)
  );
  const anonimous = data.find((element) => element.label === "anonimous");
  const anonimousTotal = anonimous ? (anonimous.value as number) : 0;
  const anonimousPercentage = Number(
    ((anonimousTotal / getTotal(data)) * 100).toFixed(1)
  );

  const stats: DoughnutSimpleType[] = [
    {
      type: "2",
      data: {
        labels: [getTranslations('REPORT_SOLVED_CASES'), getTranslations('REPORT_UNSOLVED_CASES')],
        datasets: [
          {
            label: `${percentage}%`,
            data: [getTotal(resolved), getTotal(data) - getTotal(resolved)],
            backgroundColor: ["#3DA3E8", "#FD6585"],
            hoverOffset: 10,
          },
        ],
      },
      percentValue: isNaN(percentage) ? 0 : percentage.toFixed(2),
      description: getTranslations('REPORT_PERCENT_CASES'),
    },
    {
      type: "2",
      data: {
        labels: [getTranslations('REPORT_ANONYMOUS_CASES'), getTranslations('REPORT_NO_ANONYMOUS_CASES')],
        datasets: [
          {
            label: `${anonimousPercentage}%`,
            data: [anonimousTotal, getTotal(data) - anonimousTotal],
            backgroundColor: ["#FECC60", "#51C0BF"],
            hoverOffset: 10,
          },
        ],
      },
      percentValue: isNaN(anonimousPercentage) ? 0 : anonimousPercentage.toFixed(2),
      description: getTranslations('REPORT_PERCENT_REPORTED_CASES'),
    },
  ];
  return <StatsRow statsData={stats} />;
};
