import React, { FunctionComponent, useEffect, useState } from "react";

import { Button, Space, Row, Col, Tabs } from "antd";
import cls from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";

import {
  getCognitoUserData,
  logoutFromCognito,
} from "../../features/auth/authSlice";
import {
  fetchCompany,
  ICompany,
  selectCompany,
} from "../../features/company/companySlice";
import {
  fetchStats,
  selectStats,
} from "../../features/loggedUser/loggedUserSlice";
import {
  fetchCertificatesList,
  selectCertificateList,
  selectIsCertificateListFetching,
} from "../../features/students/certificateListSlice";
import { changeToStudent } from "../../features/userMode/UserMode";
import {
  fetchUser,
  IUser,
  selectUser,
  UsersGroups,
} from "../../features/users/usersSlice";
import { PasswordChangeModal } from "../Authentication/PasswordChangeModal";
import { CogIcon } from "../common/CogIcon";
import LoadingOverlay from "../common/LoadingOverlay";
import { SIZES } from "../common/screen";
import { Headlines, ProfileDetailsColumn } from "../Profile/UserDetailsColumn";
import { goToCertificate } from "../Students/Course/Congratulations";
import { useDeviceSize } from "../utils/useDeviceSize";

import starImage from '../../assets/Star 1.svg';

import styles from "./loggedUserProfile.module.scss";
import formStyles from "../common/form.module.scss";
import { analytics } from "../../services/analytics";
import { Infographic } from "../Profile/Infographic";
import { getTranslations, getTranslationsJSX } from "../../features/translations/translationsUtils";

const renderLeftColumn = (user: IUser, company: ICompany) => {
  return (
    <ProfileDetailsColumn
      headlinesType={Headlines.Self}
      hideCreationDate
      user={user}
      company={company}
    />
  );
};

interface ProgressCardProps {
  user: IUser;
}
const ProgressCard: React.FC<ProgressCardProps> = ({ user }) => {
  const { groups = [] } = user;
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(fetchStats());
  }, [dispatch]);

  const stats = useSelector(selectStats);
  if (!groups.includes(UsersGroups.Students)) {
    return null;
  }
  return <div className={cls(styles.progressCard)}>
    {stats.pendingCourses > 0 && (
      <div className={styles.coursesInfo}>
        <div className={styles.headline}>
          <CogIcon icon="clock-1" /><br />
          {getTranslations('PROFILE_WELCOME', [user.given_name])}
        </div>
        <div className={styles.description}>
          {getTranslations('PROFILE_WELCOME_2')}
        </div>
        <div className={styles.ctaWrapper}>
          <Button
            type="primary"
            onClick={() => {
              dispatch(changeToStudent());
              history.push("/my-courses");
            }}
          >
            {getTranslations('PROFILE_VIEW_DETAILS')}
          </Button>
        </div>
      </div>
    )}
  </div>;
};

interface CertificationProps {
  user: IUser;
}
const Certifications: React.FC<CertificationProps> = ({ user }) => {
  const { groups = [] } = user;
  const dispatch = useDispatch();
  const fetching = useSelector(selectIsCertificateListFetching);
  const list = useSelector(selectCertificateList);
  useEffect(() => {
    dispatch(fetchCertificatesList());
  }, [dispatch]);
  if (!groups.includes(UsersGroups.Students)) {
    return null;
  }
  if (fetching) {
    return <LoadingOverlay inline spinning height={100} />;
  }
  return <div className={cls(formStyles.section, styles.certifications)}>
    <img className={styles.image} src={starImage} alt="star" />
    <div className={styles.title}>{getTranslations('PROFILE_OBTAINED_CERTIFICATES')}</div>
    {list.map((item) => {
      return (
        <div className={styles.certificate} key={item._id}>
          <div className={styles.courseName}>{item.courseName}</div>
          <div className={styles.gotoLink}>
            <span
              className={styles.viewButton}
              onClick={goToCertificate(item.hash)}
            >
              {getTranslations('PROFILE_VIEW_CERTIFICATE')}
            </span>
          </div>
        </div>
      );
    })}
    {list.length === 0 && (
      <div className={styles.description}>
        {getTranslations('PROFILE_NO_OBTAINED_CERTIFICATES')}
      </div>
    )}
  </div>;
};

interface SecurityProps {
  company: ICompany;
}
const Security: React.FC<SecurityProps> = ({ company }) => {
  const [isPasswordChangeOpen, setIsPasswordChangeOpen] =
    useState<boolean>(false);
  const onChangePassword = () => {
    setIsPasswordChangeOpen(true);
  };
  const onCloseChangeModal = () => {
    setIsPasswordChangeOpen(false);
  };
  const dispatch = useDispatch();
  return <>
    <div className={formStyles.section}>
      <PasswordChangeModal
        visible={isPasswordChangeOpen}
        onClose={() => {
          onCloseChangeModal();
        }}
        onChanged={() => {
          onCloseChangeModal();
          dispatch(logoutFromCognito());
        }}
      />
      <div className={cls(styles.securityCard, styles.Card)}>
        <div className={styles.mainWrapper}>
          <div className={styles.title}>{getTranslations('PROFILE_SECURITY')}</div>
          <div className={styles.description}>
            {getTranslations('PROFILE_SECURITY_DESCRIPTION')}
          </div>
          <div className={styles.ctaWrapper}>
            <Button
              type="link"
              style={{ paddingRight: "0", paddingLeft: "0" }}
              onClick={() => {
                onChangePassword();
              }}
            >
              {getTranslations('PROFILE_CHANGE_PASSWORD')}
            </Button>
          </div>
        </div>
      </div>
    </div>
    {company?.contactInfo && (
      <div className={cls(styles.infoCard)}>
        <span className={styles.mainWrapper}>
          <div className={styles.ctaWrapper}>
            <CogIcon size="1.8em" color="var(--tertiary-text-button-color)" icon="Attention" />
          </div>
          <div className={styles.description}>
            {getTranslationsJSX('PROFILE_CHANGE_INFO',
              <span className={styles.email}>
                &nbsp;{company.contactInfo.email}
              </span>
            )}
          </div>
        </span>
      </div>
    )}
  </>;
};

const renderRightColumn = (user: IUser, company: ICompany) => {
  return <Space direction="vertical" size="large">
    <ProgressCard user={user} />
    <Certifications user={user} />
    <Security company={company} />
  </Space>;
};

export const LoggedUserProfile: FunctionComponent = () => {
  const { params } = useRouteMatch<{ tab: string }>();
  const [currentUser, setCurrentUser] = useState<IUser>();
  const [selectedTab, setSelectedTab] = useState<string>('profile');
  const [company, setCompany] = useState<ICompany>();
  const dispatch = useDispatch();
  const cognitoUserData = useSelector(getCognitoUserData);
  const size = useDeviceSize();
  const history = useHistory();

  const cognitoUsername = cognitoUserData
    ? cognitoUserData["cognito:username"]
    : null;
  const userFromStore = useSelector(selectUser(cognitoUsername));

  const showInfographic = company?.showInfographic ?? false;

  useEffect(() => {
    if (userFromStore) {
      setCurrentUser(userFromStore);
      dispatch(fetchCompany(userFromStore?.company ?? ""));
    }
  }, [userFromStore, dispatch]);

  const companyFromStore = useSelector(
    selectCompany(userFromStore?.company ?? "")
  );
  useEffect(() => {
    if (companyFromStore) {
      setCompany(companyFromStore);
    }
  }, [companyFromStore]);

  useEffect(() => {
    dispatch(fetchUser(cognitoUsername));
  }, [dispatch, cognitoUsername]);

  useEffect(() => {
    let tab = params.tab ?? 'profile';
    if (!showInfographic) {
      tab = 'profile';
    }
    setSelectedTab(tab);
  }, [params.tab, showInfographic]);

  const isMobile = size === SIZES.PHONE || size === SIZES.TABLET;

  const leftColumn = renderLeftColumn(
    Object.assign({}, currentUser),
    Object.assign({}, company)
  );

  const rightColumn = renderRightColumn(
    Object.assign({}, currentUser),
    Object.assign({}, company)
  );

  const logOutAnalyticEvent = () => {
    analytics.logOut();
  }

  const renderProfileContent = () => {
    if (isMobile) {
      return <>
        <Row style={{ padding: '0 8px' }} gutter={50}>
          <Col>
            {rightColumn}
          </Col>
        </Row>
        <Row gutter={50}>
          <Col>
            {leftColumn}
          </Col>
        </Row>
      </>
    }
    return <Row gutter={50}>
      <Col span={8}>
        {leftColumn}
      </Col>
      <Col span={16}>
        {rightColumn}
      </Col>
    </Row>
  }
  const items = [
    {
      label: <span>
        <CogIcon icon="upload-image" />&nbsp;
        {getTranslations('PROFILE_TITLE')}
      </span>,
      key: 'profile',
      children: renderProfileContent(),
    },
  ];

  if (showInfographic) {
    items.push({
      label: <span>
        <CogIcon icon="infrographic" />&nbsp;
        {getTranslations('PROFILE_INFOGRAPHIC')}
      </span>,
      key: 'infographic',
      children: <Infographic />
    });
  }

  return <div className={styles.container}>
    <div className={styles.innerContainer}>
      <div className={styles.header}>
        <div className={styles.tools}>
          <span
            onClick={() => {
              dispatch(logoutFromCognito());
              logOutAnalyticEvent();
            }}
            className={styles.option}
          >{getTranslations('PROFILE_CLOSE_SESSION')}</span>
        </div>
        <div className={styles.imageContainer}>
          <div className={styles.image} style={{ backgroundImage: `url(${currentUser?.picture})` }} />
        </div>
        <div className={styles.name}>{currentUser?.given_name} {currentUser?.family_name}</div>
        <div className={styles.email}>{currentUser?.email}</div>
      </div>
      <Tabs
        className={styles.tabs}
        defaultActiveKey={selectedTab}
        items={items}
        activeKey={selectedTab}
        onChange={(tab) => {
          setSelectedTab(tab);
          dispatch(fetchUser(cognitoUsername));
          history.push(`/my-profile/${tab}`);
        }}
      />

    </div>
  </div>;
};
