import { Button, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import React, { ReactText, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as H from "history";

import {
  disableCompaniesStatus,
  enableCompaniesStatus,
  fetchCompanies,
  ICompany,
  selectCompanies,
  selectCompaniesAreFetching,
  selectCompaniesIds,
} from "../../features/company/companySlice";
import { fetchPrograms } from "../../features/program/programSlice";
import { PAGE_SIZE } from "../../utils/constants";
import { CogIcon } from "../common/CogIcon";
import { MainLayout } from "../common/Layouts/Main";
import { Status, StatusEnum } from "../common/Status";
import tableStyles from "../common/table.module.scss";
import { TableTools } from "../common/TableTools";
import { useDebounce } from "../utils/useDebounce";
import { MAX_EXTRA_STUDENTS_PERCENT } from "./CompanyProfile";
import styles from "./index.module.scss";
import { Prefixes } from "../prefixes";
import { getTranslations } from "../../features/translations/translationsUtils";

function renderOptions(
  history: H.History<unknown>,
) {
  return (
    <Button
      onClick={() => {
        history.push('/companies/new');
      }}
      size="large"
      type="primary"
    >
      {getTranslations('COMPANY_NEW')}
    </Button>
  );
}

export const Company: React.FunctionComponent = () => {
  const [selectedRowKeys, selectRowKeys] = useState<ReactText[]>([]);
  const [filterValue, setFilterValue] = useState<ReactText[]>([]);
  const [showActivationBtns, setShowActivationBtns] = useState<boolean>(false);
  const debounceFilterValue = useDebounce(filterValue, 500);
  const history = useHistory();
  const areCompaniesFetching = useSelector(selectCompaniesAreFetching);
  const companyIdsList = useSelector(selectCompaniesIds);
  const companyDict = useSelector(selectCompanies);
  const list = companyIdsList.map((companyId) => ({
    ...companyDict[companyId],
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      setShowActivationBtns(true);
    } else {
      setShowActivationBtns(false);
    }
  }, [selectedRowKeys]);

  const columns: ColumnsType<ICompany> = [
    {
      title: getTranslations('LIST_COMPANY'),
      dataIndex: "name",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      filteredValue: debounceFilterValue,
      ellipsis: true,
      onFilter: (value, record) => {
        return (
          record.name.toLowerCase().includes(value.toString().toLowerCase()) ||
          record.ruc.toLowerCase().includes(value.toString().toLowerCase())
        );
      },
      render: (value, company: ICompany) => {
        const { activeUsersCount = 0, inactiveUsersCount = 0 } = company;
        const totalStudentsCount = activeUsersCount + inactiveUsersCount;
        const extraStudents =
          totalStudentsCount - (company.maxActiveStudents ?? 0);
        const showAlert =
          extraStudents > 0 &&
          (extraStudents / company.maxActiveStudents) * 100 >
            MAX_EXTRA_STUDENTS_PERCENT;
        return (
          <>
            <span className={styles.name}>
              {value}
            </span>
            {showAlert ? (
              <CogIcon
                className={styles.alertIcon}
                icon="Attention"
                color="#EB5757"
                size="21"
              />
            ) : undefined}
          </>
        );
      },
      sorter: (a: ICompany, b: ICompany) => a.name.localeCompare(b.name),
    },
    {
      title: getTranslations('LIST_STATUS'),
      dataIndex: "status",
      render: (status) => <Status status={status} />,
      sortDirections: ["descend", "ascend"],
      sorter: (a: ICompany, b: ICompany) =>
        a.status === b.status ? 0 : a.status === StatusEnum.Enable ? 1 : -1,
    },
    {
      title: getTranslations('LIST_CONTRACT_START_DATE'),
      dataIndex: "contractDate",
      sortDirections: ["descend", "ascend"],
      sorter: (a: ICompany, b: ICompany) =>
        new Date(a.contractDate).getTime() - new Date(b.contractDate).getTime(),
      render: (value) => {
        return <>{moment(value).format("DD/MM/YYYY")}</>
      }
    },
    {
      title: getTranslations('COMPANY_RUC'),
      dataIndex: "ruc",
      sortDirections: ["descend", "ascend"],
      sorter: (a: ICompany, b: ICompany) => a.ruc.localeCompare(b.ruc),
      render: (value, company) => {
        return (
          <React.Fragment>
            {value}
            <div className={tableStyles.actions}>
              <Button
                type="default"
                onClick={() => {
                  history.push(`${Prefixes.company}/${company._id}`);
                }}
              >
                {getTranslations('COMPANY_VIEW')}
              </Button>
              <Button
                type="default"
                onClick={() => {
                  history.push(`${Prefixes.company}/${company._id}/edit`)
                }}
              >
                {getTranslations('LIST_EDIT')}
              </Button>
            </div>
          </React.Fragment>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchCompanies());
    dispatch(fetchPrograms());
  }, [dispatch]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: ReactText[]) => {
      selectRowKeys(selectedRowKeys);
    },
  };
  const enableStatus = () => {
    const data = {
      ids: selectedRowKeys.map((key) => key.toString()),
    };
    dispatch(enableCompaniesStatus(data));
  };

  const disableStatus = () => {
    const data = {
      ids: selectedRowKeys.map((key) => key.toString()),
    };
    dispatch(disableCompaniesStatus(data));
  };

  return (
    <MainLayout
      title={getTranslations('COMPANY_TITLE')}
      rightTopOptions={renderOptions(history)}
      breadcrumbs={[getTranslations('COMPANY_TITLE')]}
    >
      <TableTools
        placeholder={getTranslations('LIST_SEARCH_IN_TEURONA')}
        onSearch={(value) => setFilterValue([value])}
        onChange={(event) => setFilterValue([event.target.value])}
        onEnable={() => enableStatus()}
        onDisable={() => disableStatus()}
        activationBtnsVisible={showActivationBtns}
      />
      <Table<ICompany>
        loading={areCompaniesFetching}
        className={tableStyles.table}
        rowClassName={() => tableStyles.row}
        rowKey="_id"
        rowSelection={rowSelection}
        columns={columns}
        dataSource={list}
        pagination={{ position: ["bottomRight"], pageSize: PAGE_SIZE }}
      />
    </MainLayout>
  );
};
