import { Button, Form, Table } from "antd";
import { FormInstance } from "antd/lib/form";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { RangeValue } from "rc-picker/es/interface";
import React, { ReactText, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createBlueprint,
  disableBlueprintsStatus,
  enableBlueprintsStatus,
  fetchBlueprints,
  hideModal,
  IBlueprint,
  selectAreBlueprintsFetching,
  selectBlueprintsDict,
  selectBlueprintsIds,
  selectDisplayModal,
  updateBlueprint,
} from "../../features/blueprint/blueprintSlice";
import { PAGE_SIZE } from "../../utils/constants";
import { getDateFilterAndSortProps } from "../common/DateFilter";
import { DialogPopUp } from "../common/DialogPopUp";
import { CogDrawer } from "../common/Drawer";
import { MainLayout } from "../common/Layouts/Main";
import { Status, StatusEnum } from "../common/Status";
import tableStyles from "../common/table.module.scss";
import { TableTools } from "../common/TableTools";
import { useDebounce } from "../utils/useDebounce";
import { BlueprintForm } from "./BlueprintForm";
import { getTranslations } from "../../features/translations/translationsUtils";

function renderOptions(
  toggleVisible: any,
  setNewCertificate: any,
  form: FormInstance
) {
  return (
    <Button
      onClick={() => {
        toggleVisible();
        setNewCertificate({
          blueprintName: "",
          showName: true,
          showDescription: true,
          showTotalHours: true,
          showTraineeFullName: true,
          showTraineeNationalId: true,
          showCompanyName: true,
          showNameAndLogo: true,
          showUniqueCode: true,
          showQrCode: true,
        });
        setTimeout(() => {
          form.resetFields();
          form.setFieldsValue({});
        }, 100);
      }}
      type="primary"
    >
      Nuevo certificado
    </Button>
  );
}

export const Blueprints: React.FunctionComponent = () => {
  const areBlueprintsFetching = useSelector(selectAreBlueprintsFetching);
  const blueprintsIdsList = useSelector(selectBlueprintsIds);
  const blueprintDict = useSelector(selectBlueprintsDict);
  const list = blueprintsIdsList.map((blueprintId: string) => ({
    ...blueprintDict[blueprintId],
  }));
  const [visible, setVisible] = useState<boolean>(false);
  const [newCertificate, setNewCertificate] = useState<IBlueprint>();
  const [selectedRowKeys, selectRowKeys] = useState<ReactText[]>([]);
  const toggleVisible = () => setVisible(!visible);
  const dispatch = useDispatch();
  const [dateFilterValues, setDateFilterValues] = useState<
    RangeValue<moment.Moment>
  >();
  const [filterValue, setFilterValue] = useState<ReactText[]>([]);
  const debounceFilterValue = useDebounce(filterValue, 500);
  const isModalVisible = useSelector(selectDisplayModal);
  const [currentAction, setCurrentAction] = useState<string>("");
  const [showActivationBtns, setShowActivationBtns] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchBlueprints());
  }, [dispatch]);

  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      setShowActivationBtns(true);
    } else {
      setShowActivationBtns(false);
    }
  }, [selectedRowKeys]);

  const columns: ColumnsType<IBlueprint> = [
    {
      title: getTranslations('LIST_TITLE'),
      dataIndex: "blueprintName",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      filteredValue: debounceFilterValue,
      ellipsis: true,
      onFilter: (value, record) => {
        return (
          record.blueprintName
            .toLowerCase()
            .includes(value.toString().toLowerCase()) ||
          record._id?.toLowerCase().includes(value.toString().toLowerCase())
        );
      },
      sorter: (a: IBlueprint, b: IBlueprint) =>
        a.blueprintName.localeCompare(b.blueprintName),
    },
    {
      title: getTranslations('LIST_STATUS'),
      dataIndex: "status",
      render: (status) => <Status status={status} />,
      sortDirections: ["descend", "ascend"],
      sorter: (a: IBlueprint, b: IBlueprint) =>
        a.status === b.status ? 0 : a.status === StatusEnum.Enable ? 1 : -1,
    },
    {
      title: "ID",
      dataIndex: "shortId",
      sortDirections: ["descend", "ascend"],
      ellipsis: true,
      sorter: (a: IBlueprint, b: IBlueprint) => a._id.localeCompare(b._id),
    },
    {
      title: getTranslations('LIST_DATE'),
      dataIndex: "createdAt",
      ...getDateFilterAndSortProps<IBlueprint>(
        dateFilterValues,
        setDateFilterValues
      ),
      render: (createdAt, blueprint) => {
        return (
          <React.Fragment>
            {moment(createdAt).format("DD/MM/YYYY")}
            <div className={tableStyles.actions}>
              <Button
                type="default"
                onClick={() => {
                  setNewCertificate(blueprint);
                  form.resetFields();
                  toggleVisible();
                }}
              >
                {getTranslations('LIST_EDIT')}
              </Button>
            </div>
          </React.Fragment>
        );
      },
      sortDirections: ["descend", "ascend"],
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: ReactText[]) => {
      selectRowKeys(selectedRowKeys);
    },
  };
  const enableStatus = () => {
    const data = {
      ids: selectedRowKeys.map((key) => key.toString()),
    };
    dispatch(enableBlueprintsStatus(data));
  };

  const disableStatus = () => {
    const data = {
      ids: selectedRowKeys.map((key) => key.toString()),
    };
    dispatch(disableBlueprintsStatus(data));
  };

  const [form] = Form.useForm();

  return (
    <MainLayout
      title="Certificados"
      rightTopOptions={renderOptions(toggleVisible, setNewCertificate, form)}
    >
      <DialogPopUp
        name="Certificado"
        action={currentAction}
        visible={isModalVisible}
        onCancel={() => {
          dispatch(hideModal());
        }}
      />
      <TableTools
        placeholder={getTranslations('LIST_SEARCH_IN_TEURONA')}
        onSearch={(value) => setFilterValue([value])}
        onChange={(event) => setFilterValue([event.target.value])}
        onEnable={() => enableStatus()}
        onDisable={() => disableStatus()}
        activationBtnsVisible={showActivationBtns}
      />
      <Table<IBlueprint>
        loading={areBlueprintsFetching}
        className={tableStyles.table}
        rowClassName={() => tableStyles.row}
        rowKey="_id"
        rowSelection={rowSelection}
        columns={columns}
        dataSource={list}
        pagination={{ position: ["bottomRight"], pageSize: PAGE_SIZE }}
      />
      <CogDrawer
        visible={visible}
        toggleVisible={toggleVisible}
        title={newCertificate?._id ? "Editar certificado" : "Nuevo certificado"}
        footer={
          <div>
            <Button onClick={toggleVisible} type="default">
              Cancelar
            </Button>
            &nbsp;&nbsp;
            <Button
              onClick={() => {
                form
                  .validateFields()
                  .then((value) => {
                    if (!newCertificate) {
                      return;
                    }
                    if (!newCertificate._id) {
                      newCertificate.status = StatusEnum.Enable;
                      setCurrentAction("creado");
                      dispatch(createBlueprint(newCertificate));
                    } else {
                      setCurrentAction("actualizado");
                      dispatch(updateBlueprint(newCertificate));
                    }
                    toggleVisible();
                  })
                  .catch((error) => {
                    console.error(
                      "Certificate:React.FunctionComponent -> error",
                      error
                    );
                  });
              }}
              type="primary"
            >
              {newCertificate?._id
                ? "Actualizar certificado"
                : "Crear certificado"}
            </Button>
          </div>
        }
      >
        <div>
          {visible ? (
            <BlueprintForm
              form={form}
              newBlueprint={newCertificate}
              setNewBlueprint={setNewCertificate}
            />
          ) : null}
        </div>
      </CogDrawer>
    </MainLayout>
  );
};
