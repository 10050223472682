import React, { FunctionComponent } from "react";
import ctx from "classnames";
import { Input } from "antd";

import styles from "./common.module.scss";
import { ViewProps } from "./common";
import { getTranslations } from "../../features/translations/translationsUtils";

const { TextArea } = Input;

export const Push: FunctionComponent<ViewProps> = ({
  message,
  setMessage,
  title,
  setTitle,
  maxMessageCount
}) => {
  return (
    <div className={ctx(styles.container)}>
      <div className={styles.title}>{getTranslations('NOTIFICATION_DETAILS')}</div>
      <div className={styles.label}>{getTranslations('NOTIFICATION_FIELD_TITLE')}</div>
      <Input
        placeholder={getTranslations('NOTIFICATION_FIELD_TITLE')}
        value={title}
        onChange={event => {
          setTitle(event.target.value);
        }}
        className={styles.input}
      />
      <div className={styles.label}>{getTranslations('NOTIFICATION_MESSAGE')}</div>
      <TextArea
        placeholder={getTranslations('NOTIFICATION_WRITE_MESSAGE')}
        value={message}
        onChange={event => {
          setMessage(event.target.value);
        }}
        className={styles.textarea}
        rows={4}
      />
      <div className={styles.counter}>{message.length} / {maxMessageCount}</div>
    </div>
  );
};
