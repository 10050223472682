import React, { ReactText, useEffect, useState } from "react";

import { Button } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import moment from "moment";
import { RangeValue } from "rc-picker/es/interface";
import { useDispatch, useSelector } from "react-redux";
import * as H from 'history';

import { getCognitoUserData, selectCompanyId, selectUserRole } from '../../features/auth/authSlice';
import { selectCompanies } from "../../features/company/companySlice";
import {
  EvaluationTypeLabelEnum,
} from "../../features/evaluation/common/enums";
import { IEvaluation } from "../../features/evaluation/common/interfaces";
import {
  disableEvaluationsStatus,
  enableEvaluationStatus,
  fetchEvaluations,
  selectAreEvaluationFetching,
  selectEvaluations,
} from "../../features/evaluation/evaluationsSlice";
import { UserRole } from '../../features/users/usersSlice';
import { getDateFilterAndSortProps } from "../common/DateFilter";
import { MainLayout } from "../common/Layouts/Main";
import { Status, StatusEnum } from "../common/Status";
import { getStatusFilterAndSortProps } from "../common/statusFilter";
import { TableTools } from "../common/TableTools";
import { useDebounce } from "../utils/useDebounce";

import tableStyles from "../common/table.module.scss";
import { ActionSuffixes, Prefixes } from "../prefixes";
import { useHistory } from "react-router-dom";

import styles from './evaluation.module.scss';
import { CompanyFilter } from "../Filters/Company";
import { getTranslations } from "../../features/translations/translationsUtils";

const PAGE_SIZE = 10;

function renderOptions(history: H.History) {
  return <Button
    size="large"
    type="primary"
    onClick={() => {
      history.push(`${Prefixes.evaluation}${ActionSuffixes.new}`);
    }}
  >
    {getTranslations('EVALUATION_NEW')}
  </Button>;
};
export const Evaluations: React.FunctionComponent = () => {
  const history = useHistory();
  const [statusFilter, setStatusFilter] = useState<StatusEnum>();
  const [dateFilterValues, setDateFilterValues] = useState<
    RangeValue<moment.Moment>
  >();
  const [selectedRowKeys, selectRowKeys] = useState<ReactText[]>([]);
  const [filterValue, setFilterValue] = useState<ReactText[]>([]);
  const [filterCompanyValue, setFilterCompanyValue] = useState<ReactText[]>([]);
  const [showActivationBtns, setShowActivationBtns] = useState(false);
  const userRole = useSelector(selectUserRole);
  const companyId = useSelector(selectCompanyId);
  const companiesList = useSelector(selectCompanies);
  const cognitoData = useSelector(getCognitoUserData);
  const role = cognitoData ? cognitoData["custom:role"] : undefined;
  const evaluationList = [...useSelector(selectEvaluations)].filter(evaluation => {
    if (userRole === UserRole.SuperAdmin) {
      return true;
    }
    return companyId === evaluation.companyId;
  }).map(
    (evaluation) => ({
      ...evaluation,
      typeName: getTranslations(EvaluationTypeLabelEnum[evaluation.type]),
      companyName: evaluation.companyId ? companiesList[evaluation.companyId || '']?.name : getTranslations('COMPANY_ALL'),
    })
  );
  const areEvaluationsFetching = useSelector(selectAreEvaluationFetching);
  const debounceFilterValue = useDebounce(filterValue, 500);
  const dispatch = useDispatch();

  const columns: ColumnsType<IEvaluation> = [
    {
      title: getTranslations('LIST_TITLE'),
      dataIndex: "title",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      filteredValue: debounceFilterValue,
      ellipsis: true,
      onFilter: (value, record) => {
        return (
          record.title.toLowerCase().includes(value.toString().toLowerCase()) ||
          record.typeName
            .toLowerCase()
            .includes(value.toString().toLowerCase()) ||
          record._id?.toLowerCase().includes(value.toString().toLowerCase())
        );
      },
      sorter: (a: IEvaluation, b: IEvaluation) =>
        a.title.localeCompare(b.title),
    },
    {
      title: getTranslations('LIST_TYPE'),
      dataIndex: "typeName",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      sorter: (a: IEvaluation, b: IEvaluation) =>
        a.typeName?.localeCompare(b?.typeName ?? "") ?? 0,
      render: ((typeName, evaluation) => {
        return <>
          <span className={styles.pill}>{typeName}</span>
        </>
      }),
    },
    {
      title: getTranslations('LIST_STATUS'),
      dataIndex: "status",
      sortDirections: ["descend", "ascend"],
      render: (status) => <Status status={status} />,
      ...getStatusFilterAndSortProps<IEvaluation>(statusFilter),
    },
    {
      title: getTranslations('LIST_DATE'),
      dataIndex: "createdAt",
      sortDirections: ["descend", "ascend"],
      ...getDateFilterAndSortProps<IEvaluation>(
        dateFilterValues,
        setDateFilterValues
      ),
      render: (createdAt, evaluation) => {
        return (
          <React.Fragment>
            {moment(createdAt).format("DD/MM/YYYY")}
          </React.Fragment>
        );
      },
    },
    {
      title: getTranslations('LIST_COMPANY'),
      dataIndex: "companyName",
      sortDirections: ["descend", "ascend"],
      ellipsis: true,
      filteredValue: filterCompanyValue,
      onFilter: (value, record: IEvaluation) => {
        return record.companyId === value;
      },
      sorter: (a: IEvaluation, b: IEvaluation) =>
        (a.companyName || "").localeCompare(b.companyName || ""),
      render: (company, evaluation) => {
        return (
          <React.Fragment>
            {company}
            <div
              className={tableStyles.actions}
              onClick={handleEditEvaluation(evaluation)}
            >
              <Button type="default">{getTranslations('LIST_EDIT')}</Button>
            </div>
          </React.Fragment>
        );
      },
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: ReactText[]) => {
      selectRowKeys(selectedRowKeys);
    },
  };

  const handleEditEvaluation = (evaluation: IEvaluation) => () => {
    history.push(`${Prefixes.evaluation}/${evaluation._id}${ActionSuffixes.edit}`);
  };

  const enableStatus = () => {
    const data = {
      ids: selectedRowKeys.map((key) => key.toString()),
    };
    dispatch(enableEvaluationStatus(data));
  };

  const disableStatus = () => {
    const data = {
      ids: selectedRowKeys.map((key) => key.toString()),
    };
    dispatch(disableEvaluationsStatus(data));
  };

  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      setShowActivationBtns(true);
    } else {
      setShowActivationBtns(false);
    }
  }, [selectedRowKeys]);

  useEffect(() => {
    dispatch(fetchEvaluations());
  }, [dispatch]);

  function onChange(_pagination: any, filters: any, _sorter: any, _extra: any) {
    if (filters.status) {
      setStatusFilter(filters.status);
    }
  }
  return (
    <MainLayout
      title={getTranslations('EVALUATION_TITLE')}
      rightTopOptions={renderOptions(history)}
      breadcrumbs={[getTranslations('EVALUATION_TITLE')]}
    >
      <TableTools
        placeholder={getTranslations('LIST_SEARCH_IN_TEURONA')}
        onEnable={() => enableStatus()}
        onDisable={() => disableStatus()}
        onSearch={(value) => setFilterValue([value])}
        onChange={(event) => setFilterValue([event.target.value])}
        activationBtnsVisible={showActivationBtns}
        filter={
          role === UserRole.SuperAdmin ? (
            <CompanyFilter onApply={setFilterCompanyValue} />
          ) : (
            undefined
          )
        }
      />
      <Table<IEvaluation>
        onChange={onChange}
        loading={areEvaluationsFetching}
        className={tableStyles.table}
        rowClassName={() => tableStyles.row}
        rowKey="_id"
        rowSelection={rowSelection}
        columns={columns}
        dataSource={evaluationList}
        pagination={{ position: ["bottomRight"], pageSize: PAGE_SIZE }}
      />
    </MainLayout>
  );
};
