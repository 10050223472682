import React, { useEffect, useState, FunctionComponent } from 'react';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLanguages, selectLanguages } from '../../features/languages/languagesSlice';
import * as icons from 'country-flag-icons/react/3x2'

import supportedLanguages from '../Translations/googleTranslateSupportedLanguages.json';
import styles from './languageSelector.module.scss';
import { fetchTranslations } from '../../features/translations/translationsSlice';


export const LanguageSelector: FunctionComponent<{ setKey: (key: string) => void, loading: boolean }> = ({ setKey, loading }) => {
  const languages = useSelector(selectLanguages);
  const dispatch = useDispatch();
  const [language, setLanguage] = useState('es');

  useEffect(() => {
    dispatch(fetchLanguages());
    const language = localStorage.getItem('language') ?? 'es';
    setLanguage(language);
    dispatch(fetchTranslations(language, () => {
      setKey(language);
    }));
  }, [dispatch, setKey]);
  if (loading) {
    return null;
  }
  return <div className={styles.container}>
    <div className={styles.innerContainer}>
      <Select
        value={language}
        className={styles.selector}
        onChange={async (value) => {
          setKey('');
          localStorage.setItem('language', value);
          setLanguage(value);
          dispatch(fetchTranslations(value, () => {
            setKey(language);
          }));
        }}
        bordered={false}
        dropdownMatchSelectWidth={false}
      >
        {languages.map((language) => {
          const item = supportedLanguages.find((item) => item.code === language);
          let code = item?.code;
          const anyIcons: any = icons;
          if (code) {
            if (code === 'en') {
              code = 'us';
            }
            const Icon = anyIcons[code.toLocaleUpperCase()];
            if (Icon) {
              return <Select.Option key={item?.code} value={item?.code}>
                <><Icon className={styles.flag} />{item?.language}</>
              </Select.Option>;
            }
          }
          return <Select.Option key={item?.code} value={item?.code}>
            <>{item?.language}</>
          </Select.Option>;
        })}
      </Select>


    </div>
  </div>;
}