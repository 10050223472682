import { message } from "antd";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchStudentNotifications } from "../../features/students/notifications";
import { getFirebaseToken, onMessageListener } from "../../services/firebase";
import { CogIcon } from "../common/CogIcon";
import styles from "./index.module.scss";
import { analytics } from "../../services/analytics";

export const PushNotification: FunctionComponent = () => {
  const [tokenFound, setTokenFound] = useState(false);
  const [, setMessageContent] = useState<any>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!tokenFound) {
      getFirebaseToken(setTokenFound, dispatch);
    }
  }, [tokenFound, dispatch]);

  onMessageListener()
    .then((payload: any) => {
      const { title, body } = payload.notification;
      message.info({
        content: (
          <div className={styles.toast}>
            <div className={styles.content}>
              <div className={styles.titleContainer}>
                <div className={styles.iconContainer}>
                  <CogIcon className={styles.icon} icon="politicas" />
                </div>
                <div className={styles.title}>{title}</div>
              </div>
              <div className={styles.body}>{body}</div>
            </div>
          </div>
        ),
        duration: 5,
        className: styles.container,
        onClose: () => {
          analytics.notificationDismiss(title);
        },
      });
      setMessageContent(payload);
      dispatch(fetchStudentNotifications());
      analytics.notificationReceive(title);
    })
    .catch((err) => console.error("failed: ", err));

  return null;
};
