import { Button, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { RangeValue } from "rc-picker/es/interface";
import React, { ReactText, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  disableProgramsStatus,
  enableProgramsStatus,
  fetchPrograms,
  IProgram,
  selectAreProgramsFetching,
  selectPrograms,
  selectProgramsDict,
} from "../../features/program/programSlice";
import { PAGE_SIZE } from "../../utils/constants";
import { getDateFilterAndSortProps } from "../common/DateFilter";
import { Status } from "../common/Status";
import tableStyles from "../common/table.module.scss";
import { TableTools } from "../common/TableTools";
import { useDebounce } from "../utils/useDebounce";
import { useHistory } from "react-router-dom";
import { ActionSuffixes, Prefixes } from "../prefixes";
import { getTranslations } from "../../features/translations/translationsUtils";

interface ProgramListProps {
  editProgramById: Function;
}

export const ProgramList: React.FC<ProgramListProps> = ({
  editProgramById,
}) => {
  const dispatch = useDispatch();
  const [dateFilterValues, setDateFilterValues] = useState<
    RangeValue<moment.Moment>
  >();
  const [filterValue, setFilterValue] = useState<ReactText[]>([]);
  const debounceFilterValue = useDebounce(filterValue, 500);
  const [selectedRowKeys, selectRowKeys] = useState<ReactText[]>([]);
  const areProgramsFetching = useSelector(selectAreProgramsFetching);
  const programIdsList = useSelector(selectPrograms);
  const programsDict = useSelector(selectProgramsDict);
  const history = useHistory();
  const programList = programIdsList.map((programId) => ({
    ...programsDict[programId],
  }));
  const [showActivationBtns, setShowActivationBtns] = useState<boolean>(false);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: ReactText[]) => {
      selectRowKeys(selectedRowKeys);
    },
  };

  useEffect(() => {
    dispatch(fetchPrograms());
  }, [dispatch]);

  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      setShowActivationBtns(true);
    } else {
      setShowActivationBtns(false);
    }
  }, [selectedRowKeys]);

  const enableStatus = () => {
    const data = {
      ids: selectedRowKeys.map((key) => key.toString()),
    };
    dispatch(enableProgramsStatus(data));
  };

  const disableStatus = () => {
    const data = {
      ids: selectedRowKeys.map((key) => key.toString()),
    };
    dispatch(disableProgramsStatus(data));
  };

  const columns: ColumnsType<IProgram> = [
    {
      title: getTranslations('LIST_NAME'),
      dataIndex: "title",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      ellipsis: true,
      sorter: (a: IProgram, b: IProgram) => a.title.localeCompare(b.title),
      filteredValue: debounceFilterValue,
      onFilter: (value, record) => {
        return (
          record.title.toLowerCase().includes(value.toString().toLowerCase()) ||
          record._id?.toLowerCase().includes(value.toString().toLowerCase())
        );
      },
    },
    {
      title: getTranslations('LIST_STATUS'),
      dataIndex: "status",
      render: (status) => {
        return <Status status={status} />;
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a: IProgram, b: IProgram) =>
        a.status === b.status ? 0 : a.status > b.status ? 1 : -1,
    },
    {
      title: getTranslations('LIST_CREATION_DATE'),
      dataIndex: "createdAt",
      ...getDateFilterAndSortProps<IProgram>(
        dateFilterValues,
        setDateFilterValues
      ),
      render: (createdAd, program) => {
        return (
          <React.Fragment>
            {moment.utc(createdAd).format("DD/MM/YYYY")}
            <div className={tableStyles.actions}>
              <Button
                type="default"
                onClick={() => {
                  history.push(`${Prefixes.program}/${program._id}${ActionSuffixes.edit}`);
                  // editProgramById(program._id);
                }}
              >
                {getTranslations('LIST_EDIT')}
              </Button>
            </div>
          </React.Fragment>
        );
      },
      sortDirections: ["descend", "ascend"],
    },
  ];

  return (
    <React.Fragment>
      <TableTools
        placeholder={getTranslations('LIST_SEARCH_IN_TEURONA')}
        onSearch={(value) => setFilterValue([value])}
        onChange={(event) => setFilterValue([event.target.value])}
        onEnable={() => enableStatus()}
        onDisable={() => disableStatus()}
        activationBtnsVisible={showActivationBtns}
      />
      <Table<IProgram>
        loading={areProgramsFetching}
        className={tableStyles.table}
        rowClassName={() => tableStyles.row}
        rowKey="_id"
        rowSelection={rowSelection}
        columns={columns}
        dataSource={programList}
        pagination={{ position: ["bottomRight"], pageSize: PAGE_SIZE }}
      />
    </React.Fragment>
  );
};
