import React, { FunctionComponent, useState } from "react";

import { Button, Card } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import cls from 'classnames';

import { QuestionsAnswers } from "../../../../features/evaluation/common/interfaces";
import { selectEvaluation } from "../../../../features/evaluation/currentEvaluationSlice";
import {
  COURSE_EXAM,
  COURSE_SURVEY,
  FINISH_COURSE,
  ISelectStudentCourseNextLessonIdReturn,
  makeProgressStudentCourse,
  selectStudentCourse,
  selectStudentCourseNextLessonIdAfterTest,
  selectStudentCourseTopic,
} from "../../../../features/students/courseSlice";
import { CogIcon } from "../../../common/CogIcon";

import styles from "./lastPage.module.scss";
import { getTranslations } from "../../../../features/translations/translationsUtils";
import { Survey } from "../Survey";
import { ParentTypeEnum } from "../../../../features/evaluation/common/enums";

interface LastPageProps {
  topicId: string;
  courseId: string;
  answersSelected: QuestionsAnswers;
  retakeTest: Function;
}

interface GetActionsParams {
  showRetake: boolean;
  testWasPassed: boolean;
  retakeTest: Function;
  nextLessonId: ISelectStudentCourseNextLessonIdReturn;
  courseId: string;
}

export const LastPage: FunctionComponent<LastPageProps> = ({
  topicId,
  courseId,
  answersSelected = {},
  retakeTest,
}) => {
  const course = useSelector(selectStudentCourse);
  const [showSurvey, setShowSurvey] = useState<boolean>(false);
  const correctAnswersCount = Object.values(answersSelected).reduce(
    (total: number, answer) => total + (answer?.correct ? 1 : 0),
    0
  );
  const topic = useSelector(selectStudentCourseTopic(topicId));
  const evaluation = useSelector(selectEvaluation(topic.evaluation || ''));
  const questionsCount = evaluation?.questions?.length || 1;
  const successRate = Math.round((correctAnswersCount / questionsCount) * 100);
  const correctAnswersToPass = topic?.correctAnswersToPass || 0;
  const testWasPassed = correctAnswersCount >= correctAnswersToPass;
  const title = testWasPassed ? getTranslations('STUDENT_TEST_APPROVED') : getTranslations('STUDENT_TEST_FAILED');
  const icon = testWasPassed ? "Check-circle" : "Cross-circle";
  const iconColor = testWasPassed ? "#27AE60" : "#eb5757";
  const nextLessonId = useSelector(
    selectStudentCourseNextLessonIdAfterTest(topicId)
  );
  const history = useHistory();
  const dispatch = useDispatch();

  const onFinish = () => {
    const doNext = () => {
      dispatch(makeProgressStudentCourse({
        courseId: courseId,
        topicId,
        progress: 1,
      }));
    }
    if (![FINISH_COURSE, COURSE_EXAM, COURSE_SURVEY].includes(nextLessonId.where)) {
      doNext();
      history.push(`/course/${courseId}/${nextLessonId.id}`);
      return;
    }
    if (nextLessonId.where === COURSE_EXAM) {
      doNext();
      history.push(`/course/${courseId}/exam`);
      return;
    }
    dispatch(makeProgressStudentCourse({
      courseId: courseId,
      topicId,
      progress: 1,
    }, () => dispatch(
      makeProgressStudentCourse(
        {
          courseId: courseId,
          progress: 1,
        },
        () => history.push(`/course/${courseId}/congratulations`)
      )
    )));
  }
  const getActions = ({
    showRetake,
    testWasPassed,
    retakeTest,
    nextLessonId,
    courseId,
  }: GetActionsParams) => {
    if (testWasPassed) {
      let buttonLabel = getTranslations('STUDENT_TEST_NEXT_LESSON');
      if (nextLessonId.where === FINISH_COURSE) {
        buttonLabel = getTranslations('STUDENT_TEST_COMPLETE_COURSE');
      }
      if (nextLessonId.where === COURSE_EXAM) {
        buttonLabel = getTranslations('STUDENT_TEST_TAKE_EXAM');
      }
      if (nextLessonId.where === COURSE_SURVEY) {
        buttonLabel = getTranslations('STUDENT_EXAM_TAKE_SURVEY');
      }

      const buttons = [];
      if (showRetake) {
        buttons.push(
          <Button
            key="retake"
            onClick={() => {
              retakeTest();
            }}
            type="link"
          >
            {getTranslations('STUDENT_TEST_IMPROVE_SCORE')}
          </Button>
        );
      }
      buttons.push(
        <Button
          key="next_lesson"
          onClick={() => {
            if (nextLessonId.where === COURSE_SURVEY) {
              setShowSurvey(true);
              return;
            }
            onFinish();
          }}
          type="primary"
        >
          {buttonLabel}
        </Button>
      );
      return <div className={styles.buttonContainer}>
        {buttons}
      </div>;
    }
    return [
      <div className={styles.buttonContainer}>
        <Button
          onClick={() => {
            retakeTest();
          }}
          type="primary"
        >
          {getTranslations('STUDENT_TEST_RETRY')}
        </Button>
      </div>,
    ];
  };

  const questionsSummary = !evaluation || testWasPassed ? [] :
    evaluation?.questions.map(question => {
      return {
        key: question._id,
        title: question.title,
        correct: answersSelected[question?._id ?? ''].correct ?? false,
      }
    });

  return (
    <Card
      className={styles.container}
    >
      {showSurvey ? (
        <Survey
          courseId={courseId}
          parentId={courseId}
          surveyId={course?.survey || ""}
          onSurveyComplete={() => {
            onFinish();
          }}
          parentType={ParentTypeEnum.CourseSurvey}
          onCancelSurvey={() => setShowSurvey(false)}
        />
      ) : null}
      <CogIcon color={iconColor} className={styles.icon} icon={icon} />
      <div className={styles.title}>{title}</div>
      <div className={styles.results}>
        <div className={styles.result}>
          {getTranslations('STUDENT_TEST_SCORE', [`${correctAnswersCount} / ${questionsCount}`])}
        </div>
        <div className={styles.result}>
          {getTranslations('STUDENT_TEST_RIGHT_ANSWERS_PERCENT', [successRate])}
        </div>
        <div className={styles.result}>
          {getTranslations('STUDENT_TEST_RIGHT_ANSWERS_COUNT', [correctAnswersCount])}
        </div>
        <div className={styles.result}>
          {getTranslations('STUDENT_TEST_WRONG_ANSWERS_COUNT', [questionsCount - correctAnswersCount])}
        </div>
      </div>
      {questionsSummary.length === 0 ? null :
        <div className={styles.questions}>
          <div className={styles.title}>{getTranslations('STUDENT_TEST_ANSWERS_DETAILS')}</div>
          {questionsSummary.map(question => {
            const { correct, key, title } = question;
            return <div key={key} className={cls(styles.question, { [styles.failed]: !question.correct })}>
              <CogIcon color={correct ? '#27AE60' : '#EB5757'} className={styles.icon} icon={correct ? 'Check' : 'Cross'} /> {title}
            </div>
          })}
        </div>}
      {getActions({
        showRetake: correctAnswersCount !== questionsCount,
        testWasPassed,
        retakeTest,
        nextLessonId,
        courseId,
      })}
    </Card>
  );
};
