import { Dispatch } from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from 'moment';

import { Microservices } from "../../app/AllowedMicroservices";
import { RootState } from "../../app/store";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { FetchStatus } from "../common/enums";
import { ITranslationsDict, setTranslationsDict } from "./translationsUtils";

interface TranslationsState {
  status: FetchStatus;
  language: string;
}

const initialState: TranslationsState = {
  status: FetchStatus.Idle,
  language: 'unset',
};

export const translationsSlice = createSlice({
  name: "translations",
  initialState,
  reducers: {
    saving: (state) => {
      state.status = FetchStatus.Fetching;
    },
    saved: (state, action: PayloadAction<string>) => {
      state.status = FetchStatus.Fetched;
      state.language = action.payload;
    },
    error: (state) => {
      state.status = FetchStatus.Error;
    },
  },
});

const BASE_ENDPOINT = "/translations";
export const { saving, error, saved } = translationsSlice.actions;
export const fetchTranslations = (language: string, callback?: () => void) => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_ENDPOINT}/public/${language}`,
      types: {
        requestType: saving,
        successTypes: [
          {
            actionOrCreator: () => {},
            selector: async (response: any) => {
              moment.locale(language);
              
              const translationsDict: ITranslationsDict = {};
              response.forEach((item: any) => {
                translationsDict[item.key] = item.text;
              });
              await setTranslationsDict(translationsDict);
              await dispatch(saved(language));
              if (callback) {
                callback();
              }
            },
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.COMPANY_SERVICE,
      authenticated: false,
    })
  );
};

export const selectTranslationsIsFetching = (state: RootState) =>
  state.translations.status === FetchStatus.Fetching;

export const selectTranslationsFetchedLanguage = (state: RootState) =>
  state.translations.language

export default translationsSlice.reducer;
