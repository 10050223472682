import React, { FunctionComponent, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import cls from 'classnames';

import styles from './points.module.scss';
import { fetchPoints, selectPoints } from '../../../features/students/pointsSlice';
import { useDeviceSize } from '../../utils/useDeviceSize';

import pointsIcon from '../../../assets/infographic/points.png';
import { SIZES } from '../../common/screen';
import { getCognitoUserData } from '../../../features/auth/authSlice';
import { selectCompanies } from '../../../features/company/companySlice';

export const Points: FunctionComponent = () => {
  const cognitoUserData = useSelector(getCognitoUserData);
  const companiesDict = useSelector(selectCompanies);
  const companyId = cognitoUserData
    ? cognitoUserData["custom:company"]
    : null;
    const company = companiesDict[companyId ?? ''];
    const { showGamification } = company ?? {};

  const dispatch = useDispatch();
  const points = useSelector(selectPoints);
  const size = useDeviceSize();

  useEffect(() => {
    dispatch(fetchPoints());
  }, [dispatch])

  const isMobile = size === SIZES.PHONE;

  if (!showGamification) {
    return null;
  }

  return <div className={cls(styles.container, {
    [styles.mobile]: isMobile,
    [styles.noMobile]: !isMobile,
  }
  )}>
    <div className={styles.points}>{points}</div>
    <img className={styles.icon} alt="Points" src={pointsIcon} />
  </div >;
}