import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCompanies,
  selectCompanies,
} from "../../features/company/companySlice";
import { MainLayout } from "../common/Layouts/Main";
import { CompanySideFilter } from "./CompanySideFilter";
import styles from "./index.module.scss";
import { Settings } from "./Settings";
import { getTranslations } from "../../features/translations/translationsUtils";

export const Customize: FunctionComponent = () => {
  const [companyId, setCompanyId] = useState<string | null>(null);
  const companies = useSelector(selectCompanies);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCompanies());
  }, [dispatch]);

  return (
    <MainLayout
      title={getTranslations('CUSTOMIZE_TITLE')}
      titleContainerClass={styles.header}
      breadcrumbs={[getTranslations('CUSTOMIZE_BREADCRUMB')]}
    >
      <div className={styles.container}>
        <div>
          <div className={styles.companyFilterContainer}>
            <CompanySideFilter
              list={companies}
              companyId={companyId}
              setCompanyId={(companyId: string) => {
                setCompanyId(companyId);
              }}
            />
          </div>
        </div>
        <div className={styles.innerContainer}>
            {companyId ? <Settings companyId={companyId} /> : null}
        </div>
      </div>
    </MainLayout>
  );
};
