import React, { FunctionComponent, useState } from "react";

import cls from 'classnames';
import moment from 'moment';
import { useSelector } from "react-redux";
import { Modal, Tooltip } from "antd";

import { Badge } from "./Badge";
import { getImagePosition, selectBadges, selectBadgesPoints } from "../../../features/students/pointsSlice";
import { BadgeType, ImagePerType } from "./ImagePerType";
import { getTranslations } from "../../../features/translations/translationsUtils";
import { selectCompany } from "../../../features/company/companySlice";
import { CogIcon } from "../../common/CogIcon";

import goldenBadge from '../../../assets/infographic/goldenBadge.png';
import styles from './badges.module.scss';

interface IBadgesProps {
  isMobile: boolean;
  companyId: string;
}

export const Badges: FunctionComponent<IBadgesProps> = ({
  isMobile,
  companyId
}) => {
  const [open, setOpen] = useState(false);
  const badges = useSelector(selectBadges);
  const badgesPoints = useSelector(selectBadgesPoints);
  const company = useSelector(selectCompany(companyId));
  const progress = Math.trunc(badges / (4 * 6) * 100);
  const values = {
    [BadgeType.learning]: [1, 2, 4, 8, 16, 32],
    [BadgeType.knowledge]: [4, 8, 16, 32, 64, 128],
    [BadgeType.activity]: [4, 8, 16, 32, 64, 128],
    [BadgeType.opinion]: [2, 4, 8, 16, 32, 64],
  };
  const renderType = (badgeType: BadgeType, type: string, typeDescription: string, plural: string, typeValue: number) => {
    return <div className={styles.typeRow}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{getTranslations(type)}</div>
        <div className={styles.description}>{getTranslations(typeDescription)}</div>
      </div>
      <div className={styles.badges}>
        {values[badgeType].map((value, index) => {
          let position: 'disabled' | 0 | 1 | 2 | 3 | 4 | 5 | 6 = getImagePosition(value, badgeType);
          position = position === 0 ? 'disabled' : position;
          const isDisabled = typeValue < value
          const src = !isDisabled ? ImagePerType[badgeType][position] : ImagePerType[badgeType].disabled;
          return <div key={index} className={styles.badge}>
            <div className={styles.imageContainer}>
              <img className={styles.image} src={src} alt={`${badgeType.toString()}${isDisabled ? ' disabled' : ''}`} />
            </div>
            <div className={styles.text}>{getTranslations(plural, [value])}</div>
          </div>;
        })}
      </div>
    </div>
  }
  return <div className={cls(styles.container, { [styles.mobile]: isMobile })}>
    <div className={styles.firstContainer}>
      <div className={styles.goldenBadge}><img src={goldenBadge} alt="golden badge" /></div>
      <div>
        <div className={styles.points}>
          {badges}
          <Tooltip title={getTranslations('STUDENT_PROFILE_POINTS_ACCUMULATED_SINCE', [moment(company?.enabledGamificationSince).format("DD-MM-YYYY")])}>
            <><CogIcon size="22px" color="#FFF" icon={'Attention'} /></>
          </Tooltip>
        </div>
        <div className={styles.description}>{getTranslations('STUDENT_PROFILE_OBTAINED_BADGES')}</div>
      </div>
    </div>
    <div className={styles.secondContainer}>
      <div className={styles.progressBarContainer}>
        <div className={styles.progressInfo}>
          <div>{getTranslations('STUDENT_PROFILE_PROGRESS')}</div>
          <div>{progress}%</div>
        </div>
        <div className={styles.progressBar}>
          <div style={{ width: `${progress}%` }} className={styles.progress}></div>
        </div>
      </div>
      <div className={styles.badges}>
        <div className={styles.badge} title={getTranslations('STUDENT_PROFILE_KNOWLEDGE', [badgesPoints.knowledgeBase])}><Badge points={badgesPoints.knowledgeBase} type={BadgeType.knowledge} /></div>
        <div className={styles.badge} title={getTranslations('STUDENT_PROFILE_LEARNING', [badgesPoints.courses])}><Badge points={badgesPoints.courses} type={BadgeType.learning} /></div>
        <div className={styles.badge} title={getTranslations('STUDENT_PROFILE_ACTIVITY', [badgesPoints.login])}><Badge points={badgesPoints.login} type={BadgeType.activity} /></div>
        <div className={styles.badge} title={getTranslations('STUDENT_PROFILE_OPINION', [badgesPoints.survey])}><Badge points={badgesPoints.survey} type={BadgeType.opinion} /></div>
      </div>
      <div className={styles.detailsContainer}>
        <span onClick={() => setOpen(true)} className={styles.details}>{getTranslations('STUDENT_PROFILE_VIEW_DETAILS')}</span>
      </div>
    </div>
    <Modal
      open={open}
      className={styles.detailsModal}
      title={<div className={styles.title}>
        {getTranslations('STUDENT_PROFILE_BADGES_MODAL_TITLE')}
      </div>}
      onCancel={() => setOpen(false)}
      footer={false}
    >
      {renderType(BadgeType.knowledge, 'STUDENT_PROFILE_BADGES_MODAL_KNOWLEDGE', 'STUDENT_PROFILE_BADGES_MODAL_KNOWLEDGE_INFO', 'STUDENT_PROFILE_BADGES_MODAL_KNOWLEDGE_COUNT', badgesPoints.knowledgeBase)}
      {renderType(BadgeType.learning, 'STUDENT_PROFILE_BADGES_MODAL_LEARNING', 'STUDENT_PROFILE_BADGES_MODAL_LEARNING_INFO', 'STUDENT_PROFILE_BADGES_MODAL_LEARNING_COUNT', badgesPoints.courses)}
      {renderType(BadgeType.activity, 'STUDENT_PROFILE_BADGES_MODAL_ACTIVITY', 'STUDENT_PROFILE_BADGES_MODAL_ACTIVITY_INFO', 'STUDENT_PROFILE_BADGES_MODAL_ACTIVITY_COUNT', badgesPoints.login)}
      {renderType(BadgeType.opinion, 'STUDENT_PROFILE_BADGES_MODAL_OPINION', 'STUDENT_PROFILE_BADGES_MODAL_OPINION_INFO', 'STUDENT_PROFILE_BADGES_MODAL_OPINION_COUNT', badgesPoints.survey)}
    </Modal>
  </div>;
};