import React, { FunctionComponent, useEffect } from "react";

import cls from 'classnames';

import { useDeviceSize } from "../../utils/useDeviceSize";
import { SIZES } from "../../common/screen";

import { Courses } from "./Curses";
import { Certificates } from "./Certificates";
import { Points } from "./Points";
import { Sessions } from "./Sessions";
import { Badges } from "./Badges";
import { Time } from "./Time";
import { Ranks } from "./Ranks";

import styles from './infographic.module.scss';
import { useDispatch } from "react-redux";
import { fetchBadgesPoints, fetchPoints } from "../../../features/students/pointsSlice";
import { fetchRanking } from "../../../features/students/rankingSlice";
import { fetchSessionInfo } from "../../../features/students/sessionInfoSlice";
import { fetchCoursesCounts } from "../../../features/students/coursesCountsSlice";

interface IInfographic {
  userId?: string;
  companyId?: string;
}

export const Infographic: FunctionComponent<IInfographic> = ({userId, companyId}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPoints(userId));
    dispatch(fetchBadgesPoints(userId));
    dispatch(fetchRanking(companyId));
    dispatch(fetchSessionInfo(userId));
    dispatch(fetchCoursesCounts(userId));
  }, [dispatch, userId, companyId]);

  const size = useDeviceSize();
  const isMobile = size === SIZES.PHONE || size === SIZES.TABLET;
  if (!isMobile) {
    return <div className={styles.container}>
      <div className={styles.twoColumns}>
        <div className={styles.column}><Points companyId={companyId ?? ''} isMobile={false} /></div>
        <div className={styles.column}><Certificates userId={userId} /></div>
      </div>
      <div className={styles.twoColumns}>
        <div className={styles.column}><Courses /></div>
        <div className={styles.column}><Sessions isMobile={false} /></div>
      </div>
      <div className={styles.oneColumn}>
        <Badges companyId={companyId ?? ''} isMobile={false} />
      </div>
      <div className={styles.twoColumns}>
        <div className={cls(styles.column, styles.small)}><Time isMobile={false} /> </div>
        <div className={cls(styles.column, styles.wide)}><Ranks isMobile={false} userId={userId} /></div>
      </div>
    </div>
  }
  return <div className={cls(styles.container, styles.mobile)}>
    <div className={styles.twoColumns}>
      <div className={styles.column}><Points companyId={companyId ?? ''} isMobile /></div>
      <div className={styles.column}><Certificates userId={userId} /></div>
    </div>
    <div className={styles.oneColumn}>
      <Courses />
    </div>
    <div className={styles.oneColumn}>
      <Sessions isMobile />
    </div>
    <div className={styles.oneColumn}>
      <Badges companyId={companyId ?? ''} isMobile />
    </div>
    <div className={styles.oneColumn}>
      <Time isMobile />
    </div>
    <div className={styles.oneColumn}>
      <Ranks isMobile userId={userId} />
    </div>
  </div>;
};