import React, { FunctionComponent } from 'react';
import { Row, Col } from 'antd';

import { selectCoursesStatus } from '../../../../features/courses/coursesSlice';
import { LearningType } from '../../../../features/reports/learning/common/interfaces';
import { fetchCoursesCompletion, selectCoursesFulfillments, selectCoursesFulfillmentsFetching } from '../../../../features/reports/learning/courses/fulfillment';
import { fetchCoursesCompletionPerCompany, selectCoursesFulfillmentsPerCompany, selectCoursesFulfillmentsPerCompanyFetching } from '../../../../features/reports/learning/courses/fulfillmentPerCompany';
import { CommonFetcher } from '../common/CommonFetcher';
import { ChartCoursesIndicators, LeftCoursesIndicators } from './CoursesIndicators';
import { FulfillmentChart } from '../common/FulfillmentChart';
import { FulfillmentTop5Chart } from '../common/FulfillmentTop5';
import { ContentTable } from '../common/ContentTable';
import { selectExamResultsCounters, selectExamResultsCountersIsFetching } from '../../../../features/reports/learning/courses/examResultsCounters';
import { useSelector } from 'react-redux';
import { selectUserRole } from '../../../../features/auth/authSlice';
import { UserRole } from '../../../../features/users/usersSlice';
import { FulfillmentInTop10 } from '../common/FulfillmentInTop10';
import { fetchCoursesInTop10, selectCoursesInTop10, selectCoursesInTop10Fetching } from '../../../../features/reports/learning/courses/onTop10';
import { AnalyticsViewReport } from '../../common/AnalyticsViewReport';
import { ReportTypes, sectionTitles } from '../../MenuAndRoutes';

interface CoursesReportProps {
  companyId: string;
}

export const CoursesReport: FunctionComponent<CoursesReportProps> = ({companyId}) => {
  const userRole = useSelector(selectUserRole);
  let FulfillmentTop;
  if(userRole === UserRole.SuperAdmin){
    FulfillmentTop = <FulfillmentTop5Chart
    fetchLearningContentCompletionPerCompany={fetchCoursesCompletionPerCompany}
    selectFulfillmentsPerCompany={selectCoursesFulfillmentsPerCompany}
    selectFulfillmentsPerCompanyFetching={selectCoursesFulfillmentsPerCompanyFetching}
    learningType={LearningType.courses}
    />
  }else{
    FulfillmentTop = <FulfillmentInTop10
      fetchLearningContentCompletionPerCompany={fetchCoursesCompletionPerCompany}
      fetchIsInTop10={fetchCoursesInTop10}
      selectFetchingInTop10={selectCoursesInTop10Fetching}
      selectIsInTop10={selectCoursesInTop10}
      learningType={LearningType.courses}
    />
  }
  return <>
    <AnalyticsViewReport companyId={companyId} reportName={sectionTitles()[ReportTypes.Courses]} />
    <CommonFetcher />
    <Row gutter={24} style={{marginBottom: 24}}>
      <Col span={18}>
        <LeftCoursesIndicators companyId={companyId}/>
      </Col>
      <Col span={6}>
        <ChartCoursesIndicators
          companyId={companyId}
          selectFulfillmentsPerCompany={selectCoursesFulfillmentsPerCompany}
          selectFulfillmentsPerCompanyFetching={selectCoursesFulfillmentsPerCompanyFetching}
        />
      </Col>
    </Row>
    <Row gutter={24}>
      <Col span={12}>
        <FulfillmentChart
            companyId={companyId}
            fetchLearningContentStatus={selectCoursesStatus}
            fetchLearningContentCompletion={fetchCoursesCompletion}
            selectFulfillments={selectCoursesFulfillments}
            selectFulfillmentsFetching={selectCoursesFulfillmentsFetching}
            learningType={LearningType.courses}
        />
      </Col>
      <Col span={12}>
        {FulfillmentTop}
      </Col>
    </Row>
    <Row gutter={24}>
      <Col span={24}>
        <ContentTable
          companyId={companyId}
          selectEvaluationsResultsCounters={selectExamResultsCounters}
          selectEvaluationsResultsCountersIsFetching={selectExamResultsCountersIsFetching}
          learningType={LearningType.courses} 
          selectFulfillments={selectCoursesFulfillments}
          selectFulfillmentsFetching={selectCoursesFulfillmentsFetching}
        />
      </Col>
    </Row>
  </>
};