import React, { FunctionComponent, useState } from 'react';

import { useSelector } from 'react-redux';
import { Switch } from 'antd';
import cls from 'classnames';

import { selectTopics } from '../../features/topic/topicSlice';
import { ICourse, TopicsLessonsSelectedDisabled } from '../../features/courses/coursesSlice';
import { CogIcon } from '../common/CogIcon';
import { selectLessons, selectLesson } from '../../features/lesson/lessonSlice';

import styles from './topicsSelected.module.scss';
import formStyles from "../common/form.module.scss";
import { ICategoriesDict, selectCategoriesDict } from '../../features/category/categorySlice';
import { getTranslations } from '../../features/translations/translationsUtils';

interface TopicsSelectedProps {
    course?: ICourse,
    selectedTopics: string[],
    setCourse: Function,
}

interface TopicSelectedProps {
    topicId: string,
    topicsLessonsDisabled: TopicsLessonsSelectedDisabled,
    onRemove: Function,
    onLessonChnageDisable: Function,
    topicIndex: number;
    categories: ICategoriesDict;
}

interface TopicsLessonsProps {
    selectedLessons: string[],
    topicsLessonsDisabled: TopicsLessonsSelectedDisabled,
    onLessonChnageDisable: Function
    categories: ICategoriesDict;
}
interface TopicsLessonProps {
    lessonId: string,
    disabled: boolean,
    onLessonChnageDisable: Function,
    categories: ICategoriesDict;
}

const TopicsLesson: FunctionComponent<TopicsLessonProps> = ({ lessonId, disabled, onLessonChnageDisable, categories }) => {
    const lesson = useSelector(selectLesson(lessonId));
    if (!lesson) {
        return null;
    }
    return <div className={styles.lesson}>
        <div className={styles.handler}><CogIcon icon="drag" /></div>
        <div className={styles.innerContainer}>
            <div className={styles.title}>
                {lesson.title}
            </div>
            <div className={styles.bottomPart}>
                <div className={styles.category}>
                    {categories[lesson.category as string]?.name}
                </div>
                <div className={styles.disableSwitch}>
                    {getTranslations('COURSE_LESSON_ENABLED')} <Switch onChange={(value) => { onLessonChnageDisable(lessonId, value) }} checked={!disabled} size="small" />
                </div>
            </div>
        </div>
    </div>;
}
const TopicsLessons: FunctionComponent<TopicsLessonsProps> = ({ selectedLessons, topicsLessonsDisabled, onLessonChnageDisable, categories }) => {
    const lessons = useSelector(selectLessons);
    if (!lessons) {
        return <div>No hay lecciones seleccionadas</div>;
    }
    const result = selectedLessons.map(lessonId => {
        return <TopicsLesson categories={categories} onLessonChnageDisable={onLessonChnageDisable} disabled={!!topicsLessonsDisabled[lessonId]} key={lessonId} lessonId={lessonId} />
    });
    return <div className={styles.lessons}>
        {result}
    </div>
};
const TopicSelected: FunctionComponent<TopicSelectedProps> = ({ topicId, onRemove, topicsLessonsDisabled, onLessonChnageDisable, topicIndex, categories }) => {
    const [isLessonOpened, setLessonOpened] = useState<boolean>(false);
    const topics = useSelector(selectTopics);
    const topic = topics[topicId];
    if (!topic) {
        return null;
    }
    let lessonsOpenedIcon = isLessonOpened ? 'Arrow-up-big' : 'Arrow-down-big';
    return <div className={cls(formStyles.section, styles.card, styles.topic)}>
        <div className={formStyles.header}>
            <div className={formStyles.pillNumber}>
                <CogIcon size={18} icon="drag" /><span className={formStyles.number}>{topicIndex + 1}</span>
            </div>
            <div className={formStyles.actionContainer}>
                <span
                    className={formStyles.action}
                    onClick={() => {
                        onRemove();
                    }}
                >
                    <CogIcon size={18} icon="Cross" />
                </span>
            </div>
        </div>
        <div className={styles.title}>
            {topic.title}
        </div>
        {topic?.category ? <div className={styles.category}>{categories[topic?.category as string]?.name}</div> : null}
        <div className={formStyles.separator} />
        <div className={cls({ [styles.closed]: !isLessonOpened }, styles.lessonContainer)}>
            <TopicsLessons
                categories={categories}
                onLessonChnageDisable={onLessonChnageDisable}
                selectedLessons={topic.lessons}
                topicsLessonsDisabled={topicsLessonsDisabled}
            />
        </div>
        <div className={styles.lessonControl} onClick={() => {
            setLessonOpened(!isLessonOpened);
        }}>
            <span className={styles.lessonControlLabel}>{getTranslations('COURSE_LESSONS_COUNT', [topic.lessons.length])}</span>
            <CogIcon size={18} color='#686F84' icon={lessonsOpenedIcon} />
        </div>
    </div>
};
export const TopicsSelected: FunctionComponent<TopicsSelectedProps> = ({ selectedTopics, setCourse, course }) => {
    const topicsLessonsDisabled = course && course.topicsLessonsDisabled ? course.topicsLessonsDisabled : {};
    const categories = useSelector(selectCategoriesDict);
    return <div className={styles.topics}>
        {selectedTopics.map((selectedTopic, index) => {
            return <TopicSelected
                categories={categories}
                topicIndex={index}
                topicsLessonsDisabled={topicsLessonsDisabled}
                key={index} topicId={selectedTopic}
                onRemove={() => {
                    const currentTopics = [...selectedTopics];
                    currentTopics.splice(index, 1);
                    setCourse({ ...course, topics: currentTopics })
                }}
                onLessonChnageDisable={(lessonId: string, value: boolean) => {
                    setCourse({ ...course, topicsLessonsDisabled: { ...topicsLessonsDisabled, [lessonId]: !value } })
                }}
            />
        })}
    </div>
};