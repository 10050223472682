import { IWithShortId } from './../common/shortId';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../app/AllowedMicroservices";
import { RootState } from "../../app/store";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { FetchStatus } from "../common/enums";
import { BASE_URL } from '../../app/apiMiddleware';

export interface TopicsLessonsSelectedDisabled {
  [_id: string]: boolean;
}

export interface ICourse extends IWithShortId {
}

export interface ICourseDict {
  [_id: string]: ICourse;
}

interface CourseState {
  images: string[];
  status: FetchStatus;
}

const initialState: CourseState = {
  images: [],
  status: FetchStatus.Idle,
};

const learningServiceRootURL = BASE_URL[Microservices.LEARNING_SERVICE].replace('/v1', '');

export const prefixedImagesSlice = createSlice({
  name: "prefixedImages",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
      state.images = [];
    },
    fetchedPrefixedImages: (state, action: PayloadAction<string[]>) => {
      state.status = FetchStatus.Fetched;
      state.images = (action.payload || []).map((image) => {
        return `${learningServiceRootURL}/assets/prefixed-images/${image}`;
      });
    },
    fetchErrorPrefixedImages: (state) => {
      state.status = FetchStatus.Error;
      state.images = [];
    },
  },
});

const {
  fetchErrorPrefixedImages,
  fetchedPrefixedImages,
  fetching,
} = prefixedImagesSlice.actions;

export const fetchPrefixedImages = () => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: "/courses/prefixedImages",
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetchedPrefixedImages,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: fetchErrorPrefixedImages,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
    })
  );
};

export const selectPrefixedImages = (state: RootState) => state.prefixedImages.images;
export const selectArePrefixedImagesFetching = (state: RootState) =>
  state.prefixedImages.status === FetchStatus.Fetching;

  export default prefixedImagesSlice.reducer;
