import React, { FunctionComponent } from "react";

import cls from 'classnames';

import { getImagePosition } from "../../../features/students/pointsSlice";
import { BadgeType, ImagePerType } from "./ImagePerType";

import styles from './badge.module.scss';
interface BadgeProps {
  type: BadgeType;
  points: number;
  disabled?: boolean;
  small?: boolean;
};
export const Badge: FunctionComponent<BadgeProps> = ({
  points,
  type,
  disabled,
  small,
}) => {
  if (disabled) {
    return <div className={cls(styles.badge, { [styles.small]: small })}>
      <img src={ImagePerType[type].disabled} alt={`${type.toString()} disabled`} />
    </div>;
  }
  const position = getImagePosition(points, type);
  if (position === 0) {
    return null;
  }
  return <div className={cls(styles.badge, { [styles.small]: small })}>
    <img src={ImagePerType[type][position]} alt={`${type.toString()}`} />
  </div>;
};