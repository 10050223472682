import { Button, Card, Col, Divider, Row, Space } from "antd";
import moment from "moment";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import {
  fetchCompany,
  ICompany,
  selectCompaniesAreFetching,
  selectCompany,
} from "../../features/company/companySlice";
import {
  fetchPrograms,
  ICourseDict,
  selectAreProgramsFetching,
  selectProgramsDict,
} from "../../features/program/programSlice";
import { CogIcon } from "../common/CogIcon";
import { PrintProfileLayout } from "../common/Layouts/PrintProfileLyt";
import { Status, StatusEnum } from "../common/Status";
import { getCountryName } from "../Users/utils";
import { PageCountHeader } from "./common/PageCountHeader";
import { Output } from "./Output";
import styles from "./profile.module.scss";
import { getTranslations } from "../../features/translations/translationsUtils";

interface RouteParams {
  id: string;
}

export const CompanyPrintableProfile: React.FunctionComponent = () => {
  let componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const { id: companyId } = useParams<RouteParams>();

  const isFetchingPrograms = useSelector(selectAreProgramsFetching);
  const isFetchingCompanies = useSelector(selectCompaniesAreFetching);

  return (
    <React.Fragment>
      <div className={styles.floatingCtaWrapper}>
        <div className={styles.buttonWrapper}>
          {isFetchingPrograms === true || isFetchingCompanies === true ? (
            <div className={styles.cta}>{getTranslations('COMPANY_LOADING_PROFILE')}</div>
          ) : (
            <Button
              type={"primary"}
              onClick={handlePrint}
              className={styles.cta}
            >
              {getTranslations('COMPANY_PROFILE_PRINT')}
            </Button>
          )}
        </div>
      </div>
      <CompanyPrintableProfileWithRef
        companyId={companyId}
        ref={componentRef}
      />
    </React.Fragment>
  );
};

interface ProfileDetailsProps {
  company: ICompany;
}

const CompanyDetailsColumn: FunctionComponent<ProfileDetailsProps> = ({
  company,
}) => {
  return (
    <React.Fragment>
      <Space
        direction="vertical"
        style={{ width: "100%" }}
        className={styles.column}
      >
        <Card className={styles.card}>
          <div className={styles.title}>{getTranslations('COMPANY_INFO')}</div>
          <Output label={getTranslations('COMPANY_NAME_PLACEHOLDER')} text={company.name} />
          <Output label={getTranslations('COMPANY_RUC')} text={company.ruc} />
          <Output
            label={getTranslations('COMPANY_MAX_STUDENTS')}
            text={company.maxActiveStudents?.toString()}
          />
          <Output
            label={getTranslations('COMPANY_CREATION_DATE')}
            text={moment.utc(company.createdAt).format("DD-MM-YYYY")}
          />
          <Divider />
          <Output label={getTranslations('COMPANY_ADDRESS')} text={company.address} />
          <Output
            label={getTranslations('COMPANY_COUNTRY')}
            text={getCountryName(company.country) || "---"}
          />
          <Output label={getTranslations('COMPANY_CITY')} text={company.city} />
        </Card>
      </Space>
    </React.Fragment>
  );
};

const renderRightColumn = (company: ICompany) => {
  const contact = company.contactInfo || {};
  return (
    <React.Fragment>
      <Space
        direction="vertical"
        style={{ width: "100%" }}
        className={styles.column}
      >
        <Card className={styles.card}>
          <div className={styles.title}>{getTranslations('COMPANY_CONTACT_INFO')}</div>
          <Output
            label={getTranslations('COMPANY_CONTACT_NAMES')}
            text={`${contact.firstname} ${contact.middlename ?? ""}`}
          />
          <Output
            label={getTranslations('COMPANY_CONTACT_LAST_NAME')}
            text={`${contact.lastname} ${contact.secondLastname ?? ""}`}
          />
          <Output label={getTranslations('COMPANY_JOB')} text={contact.position} />
          <Divider />
          <Output label={getTranslations('COMPANY_CELLPHONE')} text={contact.cellphone} />
          <Output label={getTranslations('COMPANY_EMAIL')} text={contact.email} />
          <Output label={getTranslations('COMPANY_ADDRESS')} text={contact.address} />
          <Output
            label={getTranslations('COMPANY_COUNTRY')}
            text={getCountryName(contact.country) || "---"}
          />
          <Output label={getTranslations('COMPANY_CITY')} text={contact.city} />
        </Card>
      </Space>
    </React.Fragment>
  );
};

const renderLeftColumn = (company: ICompany) => {
  return <CompanyDetailsColumn company={company} />;
};

interface ContractProps {
  company: ICompany;
  programs: ICourseDict;
}
const Contract: FunctionComponent<ContractProps> = ({ company, programs }) => {
  const notificationBlock = company.notificationTypesEnabled?.email ||
    company.notificationTypesEnabled?.sms ||
    company.notificationTypesEnabled?.web;
  return (
    <div className={styles.contract}>
      <Row gutter={[32, 0]} style={{ marginTop: "40px" }}>
        <Col span={12}>
          <Space
            direction="vertical"
            style={{ width: "100%" }}
            className={styles.column}
          >
            <Card className={styles.card}>
              <div className={styles.title}>{getTranslations('')}Contrato</div>
              <Output
                label={getTranslations('COMPANY_STUDY_PROGRAM')}
                text={programs[company.studyProgram]?.title ?? getTranslations('COMPANY_STUDY_PROGRAM_NOT_DEFINED')}
              />
              <Output label={"Notificaciones activas"} text={""}>
                {notificationBlock ? 
                <>
                  {company.notificationTypesEnabled?.email ? (
                    <div>
                      <CogIcon icon="Earth" /> <span>{getTranslations('COMPANY_NOTIFICATIONS_EMAIL')}</span>
                    </div>
                  ) : null}
                  {company.notificationTypesEnabled?.web ? (
                    <div>
                      <CogIcon icon="Mail" /> <span>{getTranslations('COMPANY_NOTIFICATIONS_PUSH')}</span>
                    </div>
                  ) : null}
                  {company.notificationTypesEnabled?.sms ? (
                    <div>
                      <CogIcon icon="sms" /> <span>{getTranslations('COMPANY_NOTIFICATIONS_SMS')}</span>
                    </div>
                  ) : null}
                  
                  <Divider />
                </>:getTranslations('COMPANY_NO_NOTIFICATIONS')}
              </Output>
              <Output
                label={getTranslations('COMPANY_CONTRACT')}
                text={
                  company.contractDate
                    ? moment(company.contractDate).format("DD/MM/YYYY")
                    : "---"
                }
              />
              <Output label={getTranslations('COMPANY_STATUS')} text={""}>
                <div>
                  <Status
                    status={
                      company.status ? StatusEnum.Enable : StatusEnum.Disable
                    }
                  ></Status>
                </div>
              </Output>
            </Card>
          </Space>
        </Col>
        <Col span={12}></Col>
      </Row>
    </div>
  );
};

const HEADER_TEXT = "Ficha de empresas";

interface CompanyProfileProps {
  companyId: string;
}

const PrintableProfile = ({ companyId }: CompanyProfileProps, ref: any) => {
  const companyFromStore = useSelector(selectCompany(companyId));
  const programs = useSelector(selectProgramsDict);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCompany(companyId));
    dispatch(fetchPrograms());
  }, [dispatch, companyId]);

  const [company, setCompany] = useState<ICompany>();

  useEffect(() => {
    if (companyFromStore) {
      setCompany(companyFromStore);
    }
  }, [companyFromStore]);

  return (
    <React.Fragment>
      <div ref={ref} className={styles.mainWrapper}>
        <PageCountHeader text={HEADER_TEXT} currentPage={1} totalPages={2} />
        <PrintProfileLayout
          topPanelColor={"#f7f8fa"}
          image={companyFromStore?.logo}
          leftBottomOptions={renderLeftColumn(Object.assign({}, company))}
          rightBottomOptions={renderRightColumn(Object.assign({}, company))}
        ></PrintProfileLayout>
        <br />
        <div className={styles.pageBreak} />
        <PageCountHeader text={HEADER_TEXT} currentPage={2} totalPages={2} />
        <Contract company={Object.assign({}, company)} programs={programs} />
      </div>
    </React.Fragment>
  );
};

const CompanyPrintableProfileWithRef = React.forwardRef(PrintableProfile);
