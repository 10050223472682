import React, { ReactText, useEffect, useState } from "react";

import { Button, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import * as H from 'history';
import { useHistory } from "react-router-dom";

import { getCognitoUserData } from "../../features/auth/authSlice";
import {
  fetchCampaigns,
  ICampaign,
  publishCampaignStatus,
  selectCampaigns,
  selectCampaignsAreFetching,
  unpublishCampaignStatus,
  selectCreatedCampaign,
} from "../../features/campaign/campaignSlice";
import {
  fetchCompanies,
  selectCompanies,
} from "../../features/company/companySlice";
import { UserRole } from "../../features/users/usersSlice";
import { MainLayout } from "../common/Layouts/Main";
import { TableTools } from "../common/TableTools";
import { CompanyFilter } from "../Filters/Company";
import { analytics } from "../../services/analytics";
import { ActionSuffixes, Prefixes } from "../prefixes";

import tableStyles from "../common/table.module.scss";
import { Status } from "./Status";
import { getTranslations } from "../../features/translations/translationsUtils";
import { useDebounce } from "../utils/useDebounce";


const PAGE_SIZE = 10;

function renderOptions(
  history: H.History,
) {
  return (
    <Button
      size="large"
      onClick={() => {
        history.push(`${Prefixes.campaign}${ActionSuffixes.new}`)
      }}
      type="primary"
    >
      {getTranslations('CAMPAIGN_NEW')}
    </Button>
  );
}

export const Campaigns: React.FunctionComponent = () => {
  const history = useHistory();
  const [filterValue, setFilterValue] = useState<ReactText[]>([]);
  const [filterCompanyValue, setFilterCompanyValue] = useState<ReactText[]>([]);
  const [selectedRowKeys, selectRowKeys] = useState<ReactText[]>([]);
  const [showActivationBtns, setShowActivationBtns] = useState(false);
  const list = useSelector(selectCampaigns);
  const companiesDict = useSelector(selectCompanies);
  const areCampaignsFetching = useSelector(selectCampaignsAreFetching);
  const cognitoData = useSelector(getCognitoUserData);
  const role = cognitoData ? cognitoData["custom:role"] : undefined;
  const cognitoUsername = cognitoData ? cognitoData['cognito:username'] : null;
  const createdCampaign = useSelector(selectCreatedCampaign);
  const CustomRow = (props: any) => {
    const { children = [] } = props;
    const row = <tr {...props} />;
    if (!Array.isArray(children)) {
      return row
    }
    const [{ props: { record = {} } }] = children;
    if (record.survey) {
      return row;
    }
    return (
      <Tooltip title="Necesita configurar encuesta">
        {row}
      </Tooltip>
    );
  };

  const campaignsList = list.map(campaign => {
    return {
      ...campaign,
      companyName: companiesDict[campaign.companyId]?.name,
    }
  });
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: ReactText[]) => {
      selectRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record: ICampaign) => {
      return {
        disabled: !record.survey,
        title: '',
      };
    },
  };
  const dispatch = useDispatch();

  const handleEditCampaign = (campaign: ICampaign) => {
    history.push(`${Prefixes.campaign}/${campaign._id}${ActionSuffixes.edit}`)
  };
  const debounceFilterValue = useDebounce(filterValue, 500);

  const columns: ColumnsType<ICampaign> = [
    {
      title: getTranslations('LIST_NAME'),
      dataIndex: "title",
      sortDirections: ["descend", "ascend"],
      ellipsis: true,
      filteredValue: debounceFilterValue,
      onFilter: (value, record) => {
        return (
          record.title.toLowerCase().includes(value.toString().toLowerCase()) ||
          record.description
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        );
      },
      sorter: (a: ICampaign, b: ICampaign) =>
        (a.title || "").localeCompare(b.title || ""),
    },
    {
      title: getTranslations('LIST_DESCRIPTION'),
      dataIndex: "description",
      sortDirections: ["descend", "ascend"],
      ellipsis: true,
      sorter: (a: ICampaign, b: ICampaign) =>
        (a.description || "").localeCompare(b.description || ""),
    },
    {
      title: getTranslations('LIST_STATUS'),
      dataIndex: "published",
      render: (published) => {
        return <Status status={published} />;
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a: ICampaign, b: ICampaign) => {
        const aPublished = a.published ?? 0;
        const bPublished = b.published ?? 0;
        return aPublished === bPublished ? 0 : aPublished > bPublished ? 1 : -1;
      }
    },
    {
      title: getTranslations('LIST_CAMPAIGN_DURATION'),
      dataIndex: "campaignDuration",
      render: (_, campaign) => {
        return <>
          {moment(campaign.fromDate).format("DD/MM/YYYY")} - {moment(campaign.toDate).format("DD/MM/YYYY")}
        </>;
      },
    },
    {
      title: getTranslations('LIST_COMPANY'),
      dataIndex: "companyName",
      sortDirections: ["descend", "ascend"],
      filteredValue: filterCompanyValue,
      onFilter: (value, record: ICampaign) => {
        return record.companyId === value;
      },
      ellipsis: true,
      sorter: (a: ICampaign, b: ICampaign) =>
        (a.companyName || "").localeCompare(b.companyName || ""),
      render: (companyName, campaing) => {
        return (
          <React.Fragment>
            {companyName}
            <div className={tableStyles.actions}>
              <Button
                type="default"
                onClick={() => {
                  handleEditCampaign(campaing);
                }}
              >
                {getTranslations('LIST_EDIT')}
              </Button>
            </div>
          </React.Fragment>
        );
      },
    },
  ];

  useEffect(() => {
    if (createdCampaign) {
      analytics.createPromoBanner({
        userName: cognitoUsername,
        companyName: companiesDict[createdCampaign.companyId]?.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdCampaign]);

  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      setShowActivationBtns(true);
    } else {
      setShowActivationBtns(false);
    }
  }, [selectedRowKeys]);

  useEffect(() => {
    dispatch(fetchCampaigns());
    dispatch(fetchCompanies());
  }, [dispatch]);

  const enableStatus = () => {
    const data = {
      ids: selectedRowKeys.map((key) => key.toString()),
    };
    dispatch(publishCampaignStatus(data));
  };

  const disableStatus = () => {
    const data = {
      ids: selectedRowKeys.map((key) => key.toString()),
    };
    dispatch(unpublishCampaignStatus(data));
  };

  return (
    <MainLayout
      title={getTranslations('CAMPAIGN_TITLE')}
      rightTopOptions={renderOptions(history)}
      breadcrumbs={[getTranslations('CAMPAIGN_TITLE')]}
    >
      <TableTools
        placeholder={getTranslations('LIST_SEARCH_IN_TEURONA')}
        onEnable={() => enableStatus()}
        onDisable={() => disableStatus()}
        onSearch={(value) => setFilterValue([value])}
        onChange={(event) => setFilterValue([event.target.value])}
        filter={
          role === UserRole.SuperAdmin ? (
            <CompanyFilter onApply={setFilterCompanyValue} />
          ) : (
            undefined
          )
        }
        activationBtnsVisible={showActivationBtns}
      />
      <Table<ICampaign>
        loading={areCampaignsFetching}
        className={tableStyles.table}
        rowClassName={() => tableStyles.row}
        rowKey="_id"
        rowSelection={rowSelection}
        columns={columns}
        dataSource={campaignsList}
        pagination={{ position: ["bottomRight"], pageSize: PAGE_SIZE }}
        components={{
          body: {
            row: CustomRow
          }
        }}
      />
    </MainLayout>
  );
};
