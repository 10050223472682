import { Spin } from 'antd';
import React from 'react';

interface Props {
    spinning: boolean;
    fullscreen?: boolean;
    inline?: boolean;
    height?: number | string;
    minHeight?: number | string;
    message?: string;
}

const LoadingOverlay: React.FunctionComponent<Props> = ({
    spinning,
    fullscreen = false,
    inline = false,
    height,
    minHeight,
    message
}) => {
    const styles: any = {
        position: "absolute", top: 0
    }
    const innerStyles: any = {
        height: "100vh",
        width: "100vw",
        backgroundColor: "#00000038",
    }
    if (fullscreen) {
        styles.position = 'fixed';
        styles.left = 0;
        styles.zIndex = 10000;
    }
    if (inline) {
        styles.position = undefined;
        innerStyles.width = '100%';
        if (height !== undefined) {
            styles.height = height;
            innerStyles.height = height;
        }
        if (minHeight !== undefined) {
            styles.minHeight = minHeight;
            innerStyles.minHeight = minHeight;
        }
    }
    return <div style={styles} >
        <Spin size="large" spinning={spinning}>
            <div
                style={innerStyles}
            />
        </Spin> {fullscreen && message ? <span style={{
            color: 'black',
            opacity: 1,
            position: 'fixed',
            top: '50vh',
            textAlign: 'center',
            width: '100%',
            fontWeight: 'bold',
            fontFamily: 'Inter',
            fontSize: 20,
        }}>
            <span style={{
                backgroundColor: '#ffffff',
                display: 'inline-block',
                padding: '10px 20px',
                border: '1px solid #D0D0D0'
            }}>{message}</span>
        </span> : null}
    </div>;
}

export default LoadingOverlay;