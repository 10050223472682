import { initializeApp } from 'firebase/app';
import 'firebase/messaging';
import 'firebase/analytics';
import 'firebase/firestore';
import { registerClient } from '../features/notifications/registerClientSlice';
import { FIREBASE_JSON, FIREBASE_VAPIDKEY } from '../app/config';
import { getMessaging, isSupported, getToken, onMessage } from 'firebase/messaging';
import { getAnalytics } from 'firebase/analytics';
import type { Messaging } from 'firebase/messaging';


const firebaseConfig = FIREBASE_JSON;
export const firebaseApp = initializeApp(firebaseConfig);


export const analytics = getAnalytics(firebaseApp)

let messaging:Messaging | null = null;
let firebaseIsSupported: boolean;

export const getFirebaseToken = async (setTokenFound: any, dispatch: any) => {
  firebaseIsSupported = await isSupported();
  if (firebaseIsSupported) {
    messaging = getMessaging(firebaseApp);
  }

  if(!firebaseIsSupported || !messaging){
    return;
  }
  return getToken(messaging, {
      vapidKey: FIREBASE_VAPIDKEY,
    })
    .then((currentToken) => {
      if (currentToken) {
          dispatch(registerClient({ firebaseClientId: currentToken }));
        setTokenFound(true);
      } else {
        console.info(
          'No registration token available. Request permission to generate one.'
        );
        setTokenFound(false);
      }
    })
    .catch((err) => {
      console.error('An error occurred while retrieving token. ', err.message);
    });
};
export const onMessageListener = () => {
  if(!firebaseIsSupported || !messaging){
    return new Promise(() => {});
  }
  return new Promise((resolve) => {
    messaging && onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
}