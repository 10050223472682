import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../app/AllowedMicroservices";
import { RootState } from "../../app/store";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { FetchStatus } from "../common/enums";

interface CoursesCounts {
  pending: number;
  inProgress: number;
  completed: number;
}
interface CoursesCountsState {
  fetching: FetchStatus;
  data: CoursesCounts;
}

const initialState: CoursesCountsState = {
  fetching: FetchStatus.Idle,
  data: {
    pending: 0,
    inProgress: 0,
    completed: 0,
  }
};

export const coursesCountsSlice = createSlice({
  name: "coursesCounts",
  initialState,
  reducers: {
    fetching: (state) => {
      state.fetching = FetchStatus.Fetching;
      state.data = {
        pending: 0,
        inProgress: 0,
        completed: 0,
      };
    },
    fetched: (state, action: PayloadAction<CoursesCounts>) => {
      state.fetching = FetchStatus.Fetched;
      state.data = action.payload;
    },
    error: (state) => {
      state.fetching = FetchStatus.Error;
      state.data = {
        pending: 0,
        inProgress: 0,
        completed: 0,
      };
    },
  },
});

const { error, fetched, fetching } = coursesCountsSlice.actions;

export const fetchCoursesCounts = (userId?: string) => (dispatch: Dispatch<object>) => {
  const endpoint = userId ? `/student/courses/counts/${userId}`:'/student/courses/counts';
  return dispatch(
    apiActionCreator({
      endpoint,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
    })
  );
};

export const selectUserCoursesCounts = (state: RootState) => {
  return state.coursesCounts.data;
}

export const selectAreCoursesCountsFetching = (state: RootState) =>
  state.coursesCounts.fetching === FetchStatus.Fetching;

export default coursesCountsSlice.reducer;
