import React, { FunctionComponent, useEffect } from 'react';

import styles from './PasswordChangeModal.module.scss';

import { getRequirementFlags, RequirementIndicatorEnum } from '../utils/passwordConstraint';

import fulfilledIcon from '../../assets/passwordIndicator/fulfilled.png';
import unfulfilled from '../../assets/passwordIndicator/unfulfilled.png';
import unset from '../../assets/passwordIndicator/unset.png';
import { getTranslations } from '../../features/translations/translationsUtils';

interface RequirementIndicatorProps {
  label: string,
  status?: RequirementIndicatorEnum,
}

const RequirementIndicator: FunctionComponent<RequirementIndicatorProps> = ({ label, status = RequirementIndicatorEnum.UNSET }) => {
  let icon = '';
  let alt = '';
  switch (status) {
    case RequirementIndicatorEnum.FULFILLED:
      icon = fulfilledIcon;
      alt = 'fulfiled requirement';
      break;
    case RequirementIndicatorEnum.UNFULFILLED:
      icon = unfulfilled;
      alt = 'unfulfilled requirement';
      break;
    case RequirementIndicatorEnum.UNSET:
      icon = unset;
      alt = 'unset requirement';
      break;
  }
  return <div className={styles.requirement}>
    <img src={icon} alt={alt} className={styles.icon} /><span className={styles.label}>{label}</span>
  </div>
};
interface PPasswordRequirementsProps {
  password: string,
  onAllFulfiledChange(allIsFulfiled: boolean): void,
}
export const PasswordRequirements: FunctionComponent<PPasswordRequirementsProps> = ({ password, onAllFulfiledChange: onAllIsFulfiled }) => {
  const {
    minCatacterRequirement,
    numberRequirement,
    letterRequirement,
    specialCaracterRequirement,
    allFulfiled,
  } = getRequirementFlags(password);
  useEffect(() => {
    onAllIsFulfiled(allFulfiled === RequirementIndicatorEnum.FULFILLED);
  }, [allFulfiled, onAllIsFulfiled])
  return (
    <React.Fragment>
      <div>
        <RequirementIndicator status={minCatacterRequirement} label={getTranslations('LOGIN_PASSWORD_REQUIREMENT_1')} />
        <RequirementIndicator status={numberRequirement} label={getTranslations('LOGIN_PASSWORD_REQUIREMENT_2')} />
        <RequirementIndicator status={letterRequirement} label={getTranslations('LOGIN_PASSWORD_REQUIREMENT_3')} />
        <RequirementIndicator status={specialCaracterRequirement} label={getTranslations('LOGIN_PASSWORD_REQUIREMENT_4')} />
      </div>
    </React.Fragment>
  );
}
