import React, { FunctionComponent, useEffect, useState } from "react";

import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";

import {
  hideModal,
  selectDisplayModal,
  selectIsContentFetching,
  selectJustCreatedContent,
} from "../../features/knowledgeBase/contentSlice";
import { AfterActions, DialogPopUp } from "../common/DialogPopUp";
import { MainLayout } from "../common/Layouts/Main";
import { ContentForm } from "./Form";
import { analytics } from '../../services/analytics';
import { getCognitoUserData } from '../../features/auth/authSlice';
import {
  selectCompanies,
  fetchCompanies,
} from '../../features/company/companySlice';
import { useHistory } from "react-router-dom";
import { Prefixes } from "../prefixes";
import { getTranslations } from "../../features/translations/translationsUtils";

export const NewKnowledgeBase: FunctionComponent = () => {
  const [restart, setRestart] = useState(false);
  const [afterAction, setAfterAction] = useState<AfterActions>();
  const [editingContent, setEditingContent] = useState<string | null>(null);
  const isModalVisible = useSelector(selectDisplayModal);
  const isFetchingContents = useSelector(selectIsContentFetching);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (restart) {
      form.setFieldsValue({});
      form.resetFields();
      setEditingContent(null);
      setRestart(false);
    }
  }, [restart, form])

  useEffect(() => {
    if (!isFetchingContents) {
      form.setFieldsValue({});
      form.resetFields();
    }
  }, [form, isFetchingContents]);

  if (restart) {
    return null;
  }

  return (
    <MainLayout
      title={getTranslations('KNOWLEDGE_BASE_CREATE')}
      onGoBack={() => {
        history.push(`${Prefixes.knowledgeBase}`);
      }}
      breadcrumbs={[getTranslations('KNOWLEDGE_BASE_TITLE'), getTranslations('KNOWLEDGE_BASE_NEW')]}
    >
      <DialogPopUp
        name={getTranslations('KNOWLEDGE_BASE_EXCELLENT')}
        action={getTranslations('KNOWLEDGE_BASE_SUCCESSFUL_CREATION')}
        visible={isModalVisible}
        onCancel={() => {
          dispatch(hideModal());
          if (afterAction === AfterActions.BACK) {
            history.push(`${Prefixes.knowledgeBase}`);
            return;
          }
          setRestart(true);

        }}
      />
      <AnalyticsKnowledgeBaseCreated />
      <ContentForm
        editing={editingContent}
        form={form}
        onDone={(afterAction: AfterActions) => {
          setAfterAction(afterAction);
        }}
      />
    </MainLayout>
  );
};

interface AnalyticsKBCreatedProps { }
const AnalyticsKnowledgeBaseCreated: React.FunctionComponent<AnalyticsKBCreatedProps> = () => {
  const createdKB = useSelector(selectJustCreatedContent);
  const cognitoData = useSelector(getCognitoUserData);
  const cognitoUsername = cognitoData ? cognitoData['cognito:username'] : null;
  const companiesList = useSelector(selectCompanies);
  const dispatch = useDispatch();
  useEffect(() => {
    if (createdKB) {
      analytics.createKnowledgebase({
        userName: cognitoUsername,
        companyName: companiesList[createdKB.companyId]?.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdKB]);
  useEffect(() => {
    dispatch(fetchCompanies());
  }, [dispatch]);
  return null;
}
