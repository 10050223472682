import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../app/AllowedMicroservices";
import { RootState } from "../../app/store";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { FetchStatus } from "../common/enums";





export interface IGamification {
  login: number;
  finishLesson: number;
  finishTopic: number;
  finishCourse: number;
  certification: number;
  finishTest: number;
  finishExam: number;
  finishSurvey: number;
  activityBadges: boolean;
  learningBadges: boolean;
  opinionBadges: boolean;
  knowledgeBadges: boolean;
}

interface CampaignState {
  status: FetchStatus,
  data: IGamification | null;
}

const initialState: CampaignState = {
  status: FetchStatus.Idle,
  data: null,
};

interface GamificationFetch {
  login: number;
  finishLesson: number;
  finishTopic: number;
  finishCourse: number;
  certification: number;
  finishTest: number;
  finishExam: number;
  finishSurvey: number;
  activityBadges: boolean;
  learningBadges: boolean;
  opinionBadges: boolean;
  knowledgeBadges: boolean;
}

export const gamificationSlice = createSlice({
  name: "gamification",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetched: (state, action: PayloadAction<GamificationFetch>) => {
      state.status = FetchStatus.Fetched;
      state.data = action.payload;
    },
    error: (state) => {
      state.status = FetchStatus.Error;
    },
  },
});

const BASE_ENDPOINT = "/gamification";
// export const { fetch } = gamificationSlice.actions;
const { fetching, error, fetched } = gamificationSlice.actions;

export const fetchGamification = (companyId: string) => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_ENDPOINT}/${companyId}`,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.COMPANY_SERVICE,
      authenticated: true,
    })
  );
};

export const saveGamification = (companyId: string, gamification: IGamification) => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: `${BASE_ENDPOINT}/${companyId}`,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.COMPANY_SERVICE,
      authenticated: true,
      data: gamification,
    })
  );
};

export const markMessageAsSeen =  () => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: '/points/sawMessage',
      types: {
        requestType: { type: 'DO_NOTHING', payload: undefined },
        successTypes: [
          {
            actionOrCreator: { type: 'DO_NOTHING', payload: undefined },
          },
        ],
        errorTypes: [
          {
            actionOrCreator: { type: 'DO_NOTHING', payload: undefined },
          },
        ],
      },
      method: HttpMethods.POST,
      microservice: Microservices.COMPANY_SERVICE,
      authenticated: true,
    })
  );
};

export const selectGamificationIsFetching = (state: RootState) =>
  state.gamification.status === FetchStatus.Fetching;
export const selectGamification = (state: RootState) => state.gamification.data;

export default gamificationSlice.reducer;
