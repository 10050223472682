import React, {FC} from 'react';

import PhoneInput from 'react-phone-input-2'

import 'react-phone-input-2/lib/style.css'

interface OnChangeHandler {
  (e:any): void;
}
interface MyInputProps {
  value?: string;
  onChange: OnChangeHandler;
}
export const PhoneInputAntdWrapper: FC<MyInputProps> = ({value, onChange}: MyInputProps) => {
  return (
    <div>
      <PhoneInput inputStyle={{width: '100%'}} country='ec' value={value} onChange={onChange} />
    </div>
  );
}