import React, { useEffect, useState } from "react";

import { Button, Divider, Form, Input, Radio, Select, Row, Col } from "antd";
import { FormInstance } from "antd/lib/form";
import { FormListFieldData } from "antd/lib/form/FormList";
import { RadioChangeEvent } from "antd/lib/radio";
import { useSelector } from 'react-redux';
import { v4 as uuid_v4 } from "uuid";
import cls from 'classnames';

import { selectUserRole } from '../../features/auth/authSlice';
import { selectCompanies } from '../../features/company/companySlice';
import {
  EvaluationTypeEnum,
} from "../../features/evaluation/common/enums";
import {
  IEvaluation,
  IPossibleAnswer,
  IQuestion,
} from "../../features/evaluation/common/interfaces";
import { UserRole } from '../../features/users/usersSlice';
import { CogIcon } from "../common/CogIcon";
import { StatusEnum } from "../common/Status";
import { MIN_3_CHAR } from "../Users/Form";

import formStyles from "../common/form.module.scss";
import { FormFooter } from "../common/FormFooter";
import { AfterActions } from "../common/DialogPopUp";

import styles from "./form.module.scss";
import { getTranslations } from "../../features/translations/translationsUtils";

const TextArea = Input.TextArea;

const ANSWER_MAX_LIMIT = 5;
const ANSWER_MIN_LIMIT = 2;
const QUESTION_MIN_LIMIT = 1;

enum QuestionsLimit {
  QUIZ = 3,
  TEST = 10,
  EXAM = 60,
  SURVEY = 30,
}

interface Answer {
  title: string;
  feedback: string;
}

interface Question {
  title: string;
  answers: Answer[];
  selected: number;
}

export interface Values {
  evaluationTitle: string;
  questions: Question[];
  companyId?: string;
  type: EvaluationTypeEnum;
}

interface QuestionCardProps {
  questionsNumber: number;
  form: FormInstance<Values>;
  type: EvaluationTypeEnum;
  currentIndex: number;
  question: FormListFieldData;
  onDuplicateQuestion(question: Question, index: number): void;
  onDeleteQuestion(index: number): void;
}

const QuestionCard: React.FunctionComponent<QuestionCardProps> = ({
  form,
  currentIndex,
  question,
  type,
  onDuplicateQuestion,
  onDeleteQuestion,
}) => {
  const [selectedAnswer, setSelectedAnswer] = useState(0);
  const [answersNumber, setAnswersNumber] = useState(ANSWER_MIN_LIMIT);
  useEffect(() => {
    const currentQuestions = form.getFieldsValue().questions;
    if (currentQuestions) {
      setSelectedAnswer(currentQuestions[question.name].selected);
      setAnswersNumber(currentQuestions[question.name].answers.length);
    }
  }, [form, question.name]);
  const handleDelete = () => {
    onDeleteQuestion(currentIndex);
  };
  const handleDuplicate = () => {
    const selectedQuestion = form.getFieldsValue().questions[
      question.name
    ];
    if (selectedQuestion) {
      onDuplicateQuestion(selectedQuestion, currentIndex + 1);
    }
  };
  const handleSelect = (e: RadioChangeEvent) => {
    const currentQuestions = form.getFieldsValue().questions;
    currentQuestions[question.name].selected = e.target.value;
    setSelectedAnswer(e.target.value);
    form.setFieldsValue({
      questions: currentQuestions,
    });
  };
  return (
    <div className={formStyles.section}>
      <div className={formStyles.header}>
        <div className={formStyles.pillNumber}>
          <CogIcon size={18} icon="drag" /><span className={formStyles.number}>{currentIndex + 1}</span>
        </div>
        <div className={formStyles.actionContainer}>
          <span
            className={formStyles.action}
            onClick={handleDuplicate}
            title={getTranslations('EVALUATION_CLONE')}
          >
            <CogIcon size={18} icon="Copy" />
          </span>
          <span
            className={cls(styles.delete, formStyles.action)}
            onClick={handleDelete}
          >
            <CogIcon color="#EB5757" size={18} icon="Delete" />
          </span>
        </div>
      </div>
      <Form.Item
        style={{ marginTop: 20 }}
        label={getTranslations('EVALUATION_QUESTION')}
        {...question}
        name={[question.name, "title"]}
        rules={[
          { required: true, message: getTranslations('GENERAL_REQUIRED') },
          { type: "string", min: 3, message: MIN_3_CHAR() },
          { type: "string", max: 500, message: getTranslations('EVALUATION_QUESTION_ERROR_1') },
        ]}
      >
        <Input type="text" placeholder="Escribe la pregunta" />
      </Form.Item>
      <Divider />
      {type !== "SURVEY" && <div className={styles.info}>{getTranslations('EVALUATION_CHOOSE_ANSWER')}</div>}
      <Radio.Group onChange={handleSelect} value={selectedAnswer} style={{ width: '100%' }}>
        <Form.List name={[question.name, "answers"]}>
          {(answers, { add, remove }) => {
            const handleAddAnswer = (index: number) => () => {
              if (answersNumber < ANSWER_MAX_LIMIT) {
                add(undefined, index + 1);
                setAnswersNumber(answersNumber + 1);
                if (index + 1 <= selectedAnswer) {
                  setSelectedAnswer(selectedAnswer + 1);
                }
              }
            };
            const handleDeleteAnswer = (index: number) => () => {
              if (answersNumber > ANSWER_MIN_LIMIT) {
                remove(index);
                setAnswersNumber(answersNumber - 1);
                if (index === selectedAnswer) {
                  setSelectedAnswer(index === 0 ? index : index - 1);
                }
                if (selectedAnswer >= answersNumber - 1) {
                  setSelectedAnswer(selectedAnswer - 1);
                }
              }
            };
            const answerLastIndex = answers.length - 1;
            return (
              <div>
                {answers.map((answer, index) => {
                  return (
                    <>
                      <div
                        key={`answer-${index}`}
                        className={styles.answerContainer}
                      >
                        {type !== "SURVEY" && <Radio className={styles.selectedAnswer} value={index} />}
                        <div className={styles.fieldContainer}>
                          <Form.Item
                            name={[answer.name, "title"]}
                            label={getTranslations('EVALUATION_ANSWER')}
                            rules={[
                              { required: true, message: getTranslations('GENERAL_REQUIRED') },
                              {
                                type: "string",
                                min: 1,
                                message: getTranslations('EVALUATION_ANSWER_ERROR_1'),
                              },
                              {
                                type: "string",
                                max: 50,
                                message: getTranslations('EVALUATION_ANSWER_ERROR_2'),                              },
                            ]}
                          >
                            <Input
                              type="text"
                              style={{ width: '100%' }}
                              placeholder={getTranslations('EVALUATION_ANSWER_PLACEHOLDER')}
                            />
                          </Form.Item>
                          {selectedAnswer === index && type !== "SURVEY" ? <div className={styles.rightAnswer}>{getTranslations('EVALUATION_RIGHT_ANSWER')}</div> : null}
                          {type !== "SURVEY" && type !== "EXAM" && (
                            <Form.Item
                              name={[answer.name, "feedback"]}
                              label={getTranslations('EVALUATION_FEEDBACK')}
                              rules={[
                                { type: "string", min: 3, message: MIN_3_CHAR() },
                                {
                                  type: "string",
                                  max: 500,
                                  message: getTranslations('EVALUATION_RIGHT_ANSWER_ERROR_1'),
                                },
                              ]}
                            >
                              <TextArea
                                placeholder={getTranslations('EVALUATION_FEEDBACK_PLACEHOLDER')}
                              />
                            </Form.Item>
                          )}
                        </div>
                        <div className={styles.actions}>
                          <Button
                            type="default"
                            onClick={handleDeleteAnswer(index)}
                            className={styles.answerButton}
                            icon={
                              <CogIcon size="15px" color="#4376F9" icon="Minus" />
                            }
                            disabled={answersNumber === ANSWER_MIN_LIMIT}
                          />
                          <Button
                            type="default"
                            onClick={handleAddAnswer(index)}
                            className={styles.answerButton}
                            icon={
                              <CogIcon size="15px" color="#4376F9" icon="Plus" />
                            }
                            disabled={answersNumber === ANSWER_MAX_LIMIT}
                          />
                        </div>
                      </div>
                      {answerLastIndex !== index ? <div className={formStyles.separator} /> : null}
                    </>
                  );
                })}
              </div>
            );
          }}
        </Form.List>
      </Radio.Group>
    </div>
  );
};

interface FormProps {
  id?: string;
  initialValues: Values;
  isLoading: boolean;
  onClose(): void;
  onSave(evaluation: IEvaluation, afterAction: AfterActions): void;
}

const EvaluationForm: React.FunctionComponent<FormProps> = ({
  id,
  initialValues,
  isLoading,
  onClose,
  onSave,
}) => {
  const [type, selectType] = useState<EvaluationTypeEnum>(initialValues.type);
  const userRole = useSelector(selectUserRole);
  const companies = useSelector(selectCompanies);
  const [questionsNumber, setQuestionsNumber] = useState(0);
  const [form] = Form.useForm<Values>();
  let afterAction: AfterActions;
  const evaluationLabel = getTranslations('EVALUATION_NAME');

  useEffect(() => {
    const currentQuestions = form.getFieldsValue().questions as Question[];
    if (currentQuestions) {
      setQuestionsNumber(currentQuestions.length);
    }
  }, [form]);

  const handleFinish = (values: Values) => {
    const currentQuestions = values.questions.map((question) => {
      let correctAnswer = "";
      const possibleAnswers = question.answers.map((answer, key) => {
        const generatedId = uuid_v4();
        let possibleAnswer: IPossibleAnswer = {
          _id: generatedId,
          label: answer.title,
        };
        if (type !== "SURVEY") {
          if (key === question.selected) {
            correctAnswer = generatedId;
          }
          possibleAnswer.feedback = answer.feedback;
        }
        return possibleAnswer;
      });
      let currentQuestion: IQuestion = {
        title: question.title,
        possibleAnswers,
      };
      if (type !== "SURVEY") {
        currentQuestion.correctAnswer = correctAnswer;
      }
      return currentQuestion;
    });
    onSave({
      _id: "",
      typeName: type,
      type: type,
      title: values.evaluationTitle,
      companyId: values.companyId,
      questions: currentQuestions,
      status: StatusEnum.Enable,
    }, afterAction);
  };

  const newQuestion = {
    title: "",
    answers: [
      { title: "", feedback: "" },
      { title: "", feedback: "" },
    ],
    selected: 0,
  };
  return (
    <Form
      form={form}
      initialValues={initialValues}
      layout="vertical"
      onFinish={handleFinish}
      className={styles.form}
    >
      <Row gutter={50}>
        <Col span={9}>
          <div className={formStyles.section}>
            <div className={formStyles.sectionTitle}>{getTranslations('EVALUATION_BASIC_INFO')}</div>
            <Form.Item
              name="evaluationTitle"
              label={evaluationLabel}
              rules={[
                { required: true, message: getTranslations('GENERAL_REQUIRED') },
                { type: "string", min: 3, message: MIN_3_CHAR() },
                { type: "string", max: 50, message: getTranslations('EVALUATION_NAME_ERROR_1') },
              ]}
            >
              <Input type="text" placeholder={evaluationLabel} />
            </Form.Item>
            <div className={formStyles.separator} />
            {userRole === UserRole.SuperAdmin ?
              <Form.Item
                label={getTranslations('GENERAL_COMPANY')}
                name="companyId"
              >
                <Select
                  placeholder={getTranslations('EVALUATION_SELECT_COMPANY')}
                  style={{ width: "100%" }}
                >
                  <Select.Option key={'all_companies'} value={''}>
                    {getTranslations('COMPANY_ALL')}
                  </Select.Option>
                  {Object.values(companies).map(company => {
                    return <Select.Option key={company._id} value={company._id}>
                      {company.name}
                    </Select.Option>
                  })}
                </Select>
              </Form.Item>
              : <Form.Item
                style={{ display: 'none' }}
                name="companyId"
              ><Input type="hidden" /></Form.Item>
            }
          </div>
        </Col>
        <Col span={15}>
          <div className={styles.evaluationTypes}>
            <div className={styles.title}>{getTranslations('EVALUATION_TYPE')}</div>
            <div
              onClick={() => selectType(EvaluationTypeEnum.Quiz)}
              className={cls(styles.type, { [styles.selected]: type === EvaluationTypeEnum.Quiz })}
            >{getTranslations('EVALUATION_QUIZ')}</div>
            <div
              onClick={() => selectType(EvaluationTypeEnum.Test)}
              className={cls(styles.type, { [styles.selected]: type === EvaluationTypeEnum.Test })}
            >{getTranslations('EVALUATION_TEST')}</div>
            <div
              onClick={() => selectType(EvaluationTypeEnum.Exam)}
              className={cls(styles.type, { [styles.selected]: type === EvaluationTypeEnum.Exam })}
            >{getTranslations('EVALUATION_EXAM')}</div>
            <div
              onClick={() => selectType(EvaluationTypeEnum.Survey)}
              className={cls(styles.type, { [styles.selected]: type === EvaluationTypeEnum.Survey })}
            >{getTranslations('EVALUATION_SURVEY')}</div>
          </div>
          <Form.List name="questions">
            {(questions, { add, remove }) => {
              const handleAdd = () => {
                if (questionsNumber < QuestionsLimit[type]) {
                  add(newQuestion);
                  setQuestionsNumber(questionsNumber + 1);
                }
              };
              const handleDelete = (index: number) => {
                if (questionsNumber > QUESTION_MIN_LIMIT) {
                  remove(index);
                  setQuestionsNumber(questionsNumber - 1);
                }
              };
              const handleDuplicate = (
                selectedQuestion: Question,
                index: number
              ) => {
                if (questionsNumber < QuestionsLimit[type]) {
                  add(selectedQuestion, index);
                  setQuestionsNumber(questionsNumber + 1);
                }
              };
              return (
                <div className={styles.questionContainer}>
                  {questions.map((question, key) => {
                    return <>
                      <QuestionCard
                        key={`question-${key}`}
                        type={type}
                        form={form}
                        question={question}
                        questionsNumber={questionsNumber}
                        currentIndex={key}
                        onDeleteQuestion={handleDelete}
                        onDuplicateQuestion={handleDuplicate}
                      />
                    </>;
                  })}
                  <Button
                    onClick={handleAdd}
                    className={cls(styles.addNewButton, formStyles.addNewButton)}
                    type="default"
                    disabled={
                      questionsNumber >= QuestionsLimit[type] || isLoading
                    }
                  >
                    {getTranslations('EVALUATION_ADD_QUESTION')}
                  </Button>
                </div>
              );
            }}
          </Form.List>
        </Col>
      </Row>
      <FormFooter
        editing={!!id}
        disabledSave={isLoading}
        objectName={getTranslations('EVALUATION_TITLE_SINGULAR').toLocaleLowerCase()}
        onCancel={onClose}
        onSave={() => {
          afterAction = AfterActions.BACK;
          form.submit();
        }}
        onSaveAndCreateAnother={() => {
          afterAction = AfterActions.STAY;
          form.submit();
        }}
      />
    </Form>
  );
};

export default EvaluationForm;
