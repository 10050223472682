import React from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react'

import App from './App';
import { persistor, store } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { setNewBreakpoints } from './changeAntdBreakpoints';

import 'antd/dist/antd.min.css';
import './index.scss';
import LoadingOverlay from './components/common/LoadingOverlay';

setNewBreakpoints();

const rootElement = document.getElementById('root');

const render = (App: any) => {
  return ReactDOM.render(
    <Provider store={store}>
     <PersistGate loading={<LoadingOverlay spinning={true} />} persistor={persistor}>
       <App />
       </PersistGate>
    </Provider>,
    rootElement
  );
}
render(App);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


if(module.hot){
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    render(NextApp)
  });
}
