import React, { useEffect, useState } from 'react';
import { FunctionComponent } from "react";
import { useSelector } from 'react-redux';
import { ConditionEnum } from '../../../../features/common/enums';
import { selectContents, selectContentsDict, IContent } from '../../../../features/knowledgeBase/contentSlice';
import LoadingOverlay from '../../../common/LoadingOverlay';
import { RoundedContainer } from "../../../common/RoundedContainer";
import { IconNames } from '../../common/SimpleStat';
import { StatsRow, StatData } from '../../common/StatsRow';
import { useKBDataIsFetching } from '../common/useDataIsFetching';
import { getTranslations } from '../../../../features/translations/translationsUtils';

interface IndicatorsProps {
  list: IContent[],
  activeKnowledgeBasesCount: number,
}
export const Indicators: FunctionComponent<IndicatorsProps> = ({ list, activeKnowledgeBasesCount }) => {
  const [registeredCount, setRegisteredCount] = useState<number>(0);
  const [publishedCount, setPublishedCount] = useState<number>(0);
  const dataIsFetching = useKBDataIsFetching();
  const contentsIdsList = useSelector(selectContents);
  const contentDict = useSelector(selectContentsDict);

  useEffect(() => {
    if (dataIsFetching) {
      return;
    }
    const publishedContents = list.filter(item => item.published === ConditionEnum.Published);
    setRegisteredCount(list.length);
    setPublishedCount(publishedContents.length);
  }, [dataIsFetching, contentDict, contentsIdsList, list]);

  const statsData: StatData = [
    {
      iconName: IconNames.Base,
      mainText: `${registeredCount}`,
      description: getTranslations('REPORT_CREATED_KNOWLEDGE_BASES'),
      tooltipText: getTranslations('REPORT_CREATED_KNOWLEDGE_BASES_INFO'),
    },
    {
      iconName: IconNames.BaseEnabled,
      mainText: `${publishedCount}`,
      description: getTranslations('REPORT_ENABLED_KNOWLEDGE_BASES'),
      tooltipText: getTranslations('REPORT_ENABLED_KNOWLEDGE_BASES_INFO'),
    },
    {
      iconName: IconNames.BaseActive,
      mainText: activeKnowledgeBasesCount.toString(),
      description: getTranslations('REPORT_ACTIVE_KNOWLEDGE_BASES'),
      tooltipText: getTranslations('REPORT_ACTIVE_KNOWLEDGE_BASES_INFO'),
    }
  ];

  return (
    <RoundedContainer>
      {
        dataIsFetching ?
          <LoadingOverlay inline height={150} spinning /> :
          <StatsRow statsData={statsData} />
      }
    </RoundedContainer>
  )
}
