import { Button } from "antd";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCompanies,
  selectCompanies,
} from "../../features/company/companySlice";
import { MainLayout } from "../common/Layouts/Main";
import { CompanySideFilter } from "./CompanySideFilter";
import styles from "./index.module.scss";
import { Manager } from "./Manager";
import { CogIcon } from "../common/CogIcon";
import { getTranslations } from "../../features/translations/translationsUtils";

export const Notifications: FunctionComponent = () => {
  const [companyId, setCompanyId] = useState<string | null>(null);
  const [openScheduledNotifications, setOpenScheduledNotifications] = useState<
    boolean
  >(false);
  const companies = useSelector(selectCompanies);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCompanies());
  }, [dispatch]);

  return (
    <MainLayout
      title={getTranslations('NOTIFICATION_TITLE')}
      titleContainerClass={styles.header}
      breadcrumbs={[getTranslations('NOTIFICATION_TITLE')]}
    >
      <div className={styles.container}>
        <div>
          <div className={styles.companyFilterContainer}>
            <CompanySideFilter
              list={companies}
              companyId={companyId}
              setCompanyId={(companyId: string) => {
                setCompanyId(companyId);
              }}
            />
          </div>
          <Button
            className={styles.scheduledButton}
            disabled={!companyId}
            onClick={() => {
              setOpenScheduledNotifications(true);
            }}
            type="link"
          >
            <CogIcon className={styles.icon} icon="Calendar" size={20} />
            {getTranslations('NOTIFICATION_VIEW_SCHEDULED')}
          </Button>
        </div>
        <div className={styles.innerContainer}>
          <Manager
            closeScheduledNotifications={() => {
              setOpenScheduledNotifications(false);
            }}
            openScheduledNotifications={openScheduledNotifications}
            companyId={companyId}
          />
        </div>
      </div>
    </MainLayout>
  );
};
