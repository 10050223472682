import React, { useEffect, useState } from "react";

import { DatePicker, Form, Input, Select, Switch, Row, Col, Radio } from "antd";
import { FormInstance } from "antd/lib/form";
import moment from "moment";
import phoneValidator from "phone";
import { useDispatch, useSelector } from "react-redux";
import { getCognitoUserData } from "../../features/auth/authSlice";
import { FetchStatus } from "../../features/common/enums";
import {
  fetchUsers,
  GenderDict,
  hideModal,
  IUser,
  RolesDict,
  selectDisplayModal,
  selectUser,
  selectUsersFetchStatus,
  UserRole,
  UsersGroups,
  createUser,
  updateUser
} from "../../features/users/usersSlice";
import { dateFormatList } from "../../utils/constants";
import { isCIValid } from "../../utils/validation";
import { CompanyField } from "../common/CompanyField";
import { GroupField } from "../common/GroupField";
import { StudentGroupField } from "../common/SudentGroupField";
import { UploadImage } from "../common/UploadImage";
import { IdTypes } from "./common/identification";
import countriesJson from '../../assets/countries/countries.json';
import { getUniqueNamesCities } from "../common/cities.utils";
import { ActionSuffixes, Prefixes } from "../prefixes";
import { AfterActions, DialogPopUp } from "../common/DialogPopUp";
import { useHistory } from "react-router-dom";
import { FormFooter } from "../common/FormFooter";
import { PhoneInputAntdWrapper } from "../common/PhoneInputAntdWrapper";

import commonStyles from "../common/form.module.scss";
import styles from "./students.module.scss";
import { extractDate } from "../utils/convertions";
import { getGroupsForUser } from "./utils";
import { fetchCompanies } from "../../features/company/companySlice";
import LoadingOverlay from "../common/LoadingOverlay";
import { getTranslations } from "../../features/translations/translationsUtils";

export interface ISelectValue {
  value: string;
  label: string;
}
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};
const roles = [
  {
    value: UserRole.SuperAdmin,
    label: RolesDict[UserRole.SuperAdmin],
  },
  {
    value: UserRole.CompanyAdmin,
    label: RolesDict[UserRole.CompanyAdmin],
  },
  {
    value: UserRole.Supervisor,
    label: RolesDict[UserRole.Supervisor],
  },
];

enum Gender {
  male = "MALE",
  female = "FEMALE",
  other = "OTHER",
}
export const genders = () => [
  {
    value: Gender.female,
    label: GenderDict()[Gender.female],
  },
  {
    value: Gender.male,
    label: GenderDict()[Gender.male],
  },
  {
    value: Gender.other,
    label: GenderDict()[Gender.other],
  },
];

export const identificationTypes = () => ([
  {
    value: IdTypes.id,
    label: getTranslations('USERS_NATIONAL_ID'),
  },
  {
    value: IdTypes.passport,
    label: getTranslations('USERS_PASSPORT'),
  },
]);

export const Countries: ISelectValue[] = countriesJson.map(country => {
  return {
    value: country.iso,
    label: country.name,
  }
});

export const MIN_3_CHAR = () => getTranslations('GENERAL_ERROR_MIN_3_CHAR');

interface UserFormProps {
  form: FormInstance;
  editing: string | null;
  setTitle?: (value: string) => void;
  setEnabled?: (value: boolean) => void;
}
export const UserForm: React.FunctionComponent<UserFormProps> = (props) => {
  const {
    form,
    editing,
    setTitle,
    setEnabled,
  } = props;
  const fetchStatus = useSelector(selectUsersFetchStatus);
  const userToEdit = useSelector(selectUser(editing || ""));
  const [loadingImage, setLoadingImage] = useState<boolean>(false);
  const cognitoUserData = useSelector(getCognitoUserData);
  const userRole = cognitoUserData ? cognitoUserData["custom:role"] : null;
  const [cities, setCities] = useState<ISelectValue[]>([]);
  const history = useHistory();
  const [user, setUser] = useState<IUser>();
  const [addAdminPermissions, setAddAdminPermissions] = useState(user?.groups?.includes(UsersGroups.Administrators));
  const isModalVisible = useSelector(selectDisplayModal);
  const dispatch = useDispatch();
  const [afterAction, setAfterAction] = useState('');
  const [restart, setRestart] = useState(true);

  const country = user?.country;

  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchCompanies());
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      if (country) {
        const countryCities: any = await import(`../../assets/countries/cities/${country}.json`);
        const cities: ISelectValue[] = getUniqueNamesCities(countryCities);
        setCities(cities);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country])
  useEffect(() => {
    if (userToEdit && fetchStatus === FetchStatus.Fetched) {
      setUser(
        Object.assign({}, userToEdit, {
          groups: userToEdit.groups,
        })
      );
      form.resetFields();
      if (setTitle) {
        setTitle(`${userToEdit.given_name} ${userToEdit.family_name}`);
      }
      if (setEnabled) {
        setEnabled(userToEdit.enabled);
      }
      setAddAdminPermissions(user?.groups?.includes(UsersGroups.Administrators));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchStatus, userToEdit]);

  useEffect(() => {
    if (restart) {
      form.resetFields();
      form.setFieldsValue({});
      setRestart(false);
    }
  }, [restart, form]);

  const handleChange = (info: any) => {
    if (info.file.status === "uploading") {
      setLoadingImage(true);
      return;
    }
    if (info.file.status === "done") {
      setLoadingImage(false);
      setUser(Object.assign({}, user, { picture: info.file.response.url }));
    }
  };
  const birthdate = moment.utc(user?.birthdate);

  const onSubmit = (afterAction: AfterActions) => {
    form
      .validateFields()
      .then(() => {
        if (!user) {
          return;
        }
        setAfterAction(afterAction);
        const userToSend = { ...user };
        delete userToSend.companyName;
        userToSend.birthdate = extractDate(
          userToSend.birthdate
        );
        userToSend.groups = getGroupsForUser(
          userToSend.groups,
          UsersGroups.Students
        );
        if (!userToSend.phone_number?.startsWith('+')) {
          userToSend.phone_number = `+${userToSend.phone_number}`;
        }
        if (!editing) {
          userToSend.username = userToSend.email;
          dispatch(
            createUser(userToSend)
          );
        } else {
          dispatch(
            updateUser(userToSend)
          );
        }
      })
      .catch((error) => {
        console.error(
          "Company:React.FunctionComponent -> error",
          error
        );
      });
  };

  const doAfterAction = () => {
    if (afterAction === AfterActions.BACK) {
      history.push(`${Prefixes.user}`);
      return;
    }
    if (!editing) {
      setTimeout(() => {
        form.resetFields();
        form.setFieldsValue({});
        setUser({} as IUser);
        setRestart(true);
      }, 100);
      return;
    }
    history.push(`${Prefixes.user}${ActionSuffixes.new}`);
  }

  if ((!user && editing) || restart) {
    return <LoadingOverlay spinning={true} />;
  }

  return <>
    <DialogPopUp
      name={getTranslations('USERS_TITLE_SINGULAR')}
      action={editing ? getTranslations('USERS_UPDATED') : getTranslations('USERS_CREATED')}
      visible={isModalVisible}
      onCancel={() => {
        dispatch(hideModal());
        doAfterAction();
      }}
    />
    <Form
      {...formItemLayout}
      form={form}
      name="register"
      layout="vertical"
      scrollToFirstError
      id={"user_form"}
      className={commonStyles.form}
    >
      <Row gutter={50}>
        <Col span={10}>
          <div className={commonStyles.section}>
            <Form.Item
              name="picture"
              initialValue={user?.picture}
            >
              <UploadImage
                loading={loadingImage}
                imageUrl={user?.picture}
                onHandleChange={handleChange}
              />
            </Form.Item>
          </div>
          <div className={commonStyles.section}>
            {user?.role !== UserRole.SuperAdmin && (
              <CompanyField
                resetForm={(companyId: string) => {
                  form.setFieldValue('company', companyId);
                }}
                newObject={user}
                value={user?.company}
                setNewObject={setUser}
              />
            )}
            <StudentGroupField
              newObject={user}
              value={user?.studentGroupId}
              setNewObject={setUser}
              companyId={user?.company}
              form={form}
            />
            <Form.Item
              initialValue={user?.position}
              label={getTranslations('USERS_JOB')}
              name="position"
              required
              rules={[
                { type: "string", required: true, message: getTranslations('GENERAL_REQUIRED') },
                { type: "string", min: 3, message: MIN_3_CHAR() },
                { type: "string", max: 50, message: getTranslations('USERS_JOB_ERROR_1') },
              ]}
            >
              <Input
                placeholder={getTranslations('USERS_JOB_PLACEHOLDER')}
                onChange={(event) => {
                  setUser(
                    Object.assign({}, user, { position: event.target.value })
                  );
                }}
              />
            </Form.Item>
            <div className={styles.divider} />
            <div className={commonStyles.hidableContainer}>
              <div className={styles.label}>{getTranslations('USERS_GIVE_ADMIN')}</div>
              <Switch checked={addAdminPermissions} size='small' onChange={() => {
                const groupsSelected = new Set(user?.groups);
                if (!addAdminPermissions) {
                  groupsSelected.add(UsersGroups.Administrators);
                } else {
                  groupsSelected.delete(UsersGroups.Administrators);
                }
                setUser(
                  Object.assign({}, user, {
                    groups: Array.from(groupsSelected),
                    role: undefined,
                    groupId: undefined,
                  })
                );
                form.setFieldValue('role', null);
                form.setFieldValue('groupId', null);
                setAddAdminPermissions(!addAdminPermissions);
              }} />
            </div>
            {addAdminPermissions ? <>
              <Form.Item
                name="role"
                label={getTranslations('USERS_ROLE')}
                initialValue={
                  user?.role && RolesDict[user?.role]
                    ? RolesDict[user?.role]
                    : null
                }
                rules={[
                  { type: "string", required: true, message: getTranslations('USERS_SELECT_ROLE') },
                ]}
              >
                <Select
                  placeholder={getTranslations('USERS_SELECT_ROLE_PLACEHOLDER')}
                  onChange={(value) => {
                    setUser(Object.assign({}, user, { role: value }));
                  }}
                >
                  {roles
                    .filter(
                      (role) =>
                        userRole === UserRole.SuperAdmin ||
                        role.value === UserRole.Supervisor
                    )
                    .map((item, index) => (
                      <Select.Option key={index} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>

              {user?.role === UserRole.Supervisor ? (
                <GroupField
                  newObject={user}
                  value={user?.groupId}
                  setNewObject={setUser}
                  companyId={user?.company}
                />
              ) : null}
            </> : null}
          </div>
        </Col>
        <Col span={14}>
          <div className={commonStyles.section}>
            <div className={commonStyles.sectionTitle}>{getTranslations('USERS_BASIC_INFO')}</div>
            <Row gutter={20}>
              <Col span={12}>
                <Form.Item
                  initialValue={user?.given_name}
                  label={getTranslations('USERS_FIRST_NAME')}
                  name="given_name"
                  required
                  rules={[
                    { type: "string", required: true, message: getTranslations('GENERAL_REQUIRED') },
                    { type: "string", max: 15, message: getTranslations('USERS_FIRST_NAME_ERROR_1') },
                    { type: "string", min: 3, message: MIN_3_CHAR() },
                    {
                      validator: (rule, value: string = "", callback) => {
                        if (value.match(/\d/)) {
                          callback(getTranslations('USERS_NO_NUMBER_ALLOWED'));
                          return;
                        }
                        callback();
                      },
                    },
                  ]}
                >
                  <Input
                    placeholder={getTranslations('USERS_FIRST_NAME')}
                    onChange={(event) => {
                      setUser(
                        Object.assign({}, user, { given_name: event.target.value })
                      );
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  initialValue={user?.middle_name}
                  label={getTranslations('USERS_SECOND_NAME')}
                  name="middle_name"
                  rules={[
                    { type: "string", max: 15, message: getTranslations('USERS_SECOND_NAME_ERROR_1') },
                    { type: "string", min: 3, message: MIN_3_CHAR() },
                    {
                      validator: (rule, value: string = "", callback) => {
                        if (value.match(/\d/)) {
                          callback(getTranslations('USERS_NO_NUMBER_ALLOWED'));
                          return;
                        }
                        callback();
                      },
                    },
                  ]}
                >
                  <Input
                    placeholder={getTranslations('USERS_SECOND_NAME')}
                    onChange={(event) => {
                      setUser(
                        Object.assign({}, user, { middle_name: event.target.value })
                      );
                    }}
                  />
                </Form.Item></Col>
            </Row>
            <Row gutter={20}>
              <Col span={12}>
                <Form.Item
                  initialValue={user?.family_name}
                  label={getTranslations('USERS_LAST_NAME')}
                  name="family_name"
                  required
                  rules={[
                    { type: "string", required: true, message: getTranslations('GENERAL_REQUIRED') },
                    { type: "string", max: 15, message: getTranslations('USERS_LAST_NAME_ERROR_1') },
                    { type: "string", min: 3, message: MIN_3_CHAR() },
                    {
                      validator: (rule, value: string = "", callback) => {
                        if (value.match(/\d/)) {
                          callback(getTranslations('USERS_NO_NUMBER_ALLOWED'));
                          return;
                        }
                        callback();
                      },
                    },
                  ]}
                >
                  <Input
                    placeholder={getTranslations('USERS_LAST_NAME')}
                    onChange={(event) => {
                      setUser(
                        Object.assign({}, user, { family_name: event.target.value })
                      );
                    }}
                  />
                </Form.Item></Col>
              <Col span={12}>
                <Form.Item
                  initialValue={user?.secondLastname}
                  label={getTranslations('USERS_SECOND_LAST_NAME')}
                  name="secondLastname"
                  rules={[
                    { type: "string", max: 15, message: getTranslations('USERS_SECOND_LAST_NAME_ERROR_1') },
                    { type: "string", min: 3, message: MIN_3_CHAR() },
                    {
                      validator: (rule, value: string = "", callback) => {
                        if (value.match(/\d/)) {
                          callback(getTranslations('USERS_NO_NUMBER_ALLOWED'));
                          return;
                        }
                        callback();
                      },
                    },
                  ]}
                >
                  <Input
                    placeholder={getTranslations('USERS_SECOND_LAST_NAME')}
                    onChange={(event) => {
                      setUser(
                        Object.assign({}, user, { secondLastname: event.target.value })
                      );
                    }}
                  />
                </Form.Item></Col>
            </Row>
            <Form.Item
              initialValue={user?.email}
              label={getTranslations('USERS_EMAIL')}
              name="email"
              required
              rules={[
                { type: "string", required: true, message: getTranslations('GENERAL_REQUIRED') },
                { type: "email", message: getTranslations('USERS_EMAIL_ERROR_1') },
              ]}
            >
              <Input
                disabled={user?.status === "CONFIRMED"}
                placeholder={getTranslations('USERS_EMAIL')}
                onChange={(event) => {
                  setUser(Object.assign({}, user, { email: event.target.value }));
                }}
              />
            </Form.Item>
            <Form.Item
              initialValue={user?.phone_number}
              label={getTranslations('USERS_CELLPHONE')}
              name="phone_number"
              required
              rules={[
                { required: true, message: getTranslations('GENERAL_REQUIRED') },
                (obj) => ({
                  validator: async (rules, value) => {
                    const plusPhone = `+${value}`;
                    const phone = phoneValidator(plusPhone);
                    if (phone.length === 0) {
                      throw new Error(
                        getTranslations('USERS_CELLPHONE_ERROR_1')
                      );
                    }
                  },
                }),
              ]}
            >
              {/* @ts-ignore */}
              <PhoneInputAntdWrapper
                onChange={(phone) => {
                  setUser(Object.assign({}, user, { phone_number: phone }));
                }}
              />
            </Form.Item>
            <div className={styles.divider} />
            <Form.Item
              initialValue={user?.gender}
              label={getTranslations('USERS_GENDER')}
              name="gender"
              rules={[
                { type: "string", required: true, message: getTranslations('USERS_GENDER_ERROR_1') },
              ]}
            >
              <Radio.Group
                onChange={(value) => {
                  setUser(Object.assign({}, user, { gender: value.target.value }));
                }}
              >
                {genders().map((item, index) => (
                  <Radio key={index} value={item.value}>{item.label}</Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              initialValue={
                !editing
                  ? null
                  : birthdate.isValid()
                    ? birthdate
                    : null
              }
              label={getTranslations('USERS_BIRTH_DATE')}
              name="birthdate"
              required
              rules={[
                () => ({
                  validator: async (rules, value) => {
                    const today = moment(new Date());
                    const years = today.diff(value, "years");
                    if (years < 18 || value === null || isNaN(years)) {
                      throw new Error(getTranslations('USERS_BIRTH_DATE_ERROR_1'));
                    }
                  },
                }),
              ]}
            >
              <DatePicker
                placeholder="DD/MM/AA"
                onChange={(value) => {
                  setUser(Object.assign({}, user, { birthdate: value }));
                }}
                format={dateFormatList}
              />
            </Form.Item>
            <Row gutter={20}>
              <Col span={12}>
                <Form.Item
                  initialValue={user?.nationalIdType}
                  name="nationalIdType"
                  label={getTranslations('USERS_NATIONAL_ID_TYPE')}
                  rules={[
                    { type: "string", required: true, message: getTranslations('USERS_NATIONAL_ID_TYPE_ERROR_1') },
                  ]}
                >
                  <Select
                    placeholder={getTranslations('USERS_NATIONAL_ID_TYPE_PLACEHOLDER')}
                    onChange={(value) => {
                      setUser(Object.assign({}, user, { nationalIdType: value }));
                    }}
                  >
                    {identificationTypes().map((item, index) => (
                      <Select.Option key={index} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  initialValue={user?.nationalId}
                  label={getTranslations('USERS_NATIONAL_ID')}
                  name="nationalId"
                  required
                  rules={[
                    { type: "string", required: true, message: getTranslations('GENERAL_REQUIRED') },
                    ({ getFieldValue }) => ({
                      validator: async (rules, value: string) => {
                        const nationalIdType = getFieldValue("nationalIdType");
                        if (nationalIdType === undefined) {
                          return;
                        }
                        if (nationalIdType === IdTypes.id && !isCIValid(value)) {
                          throw new Error(getTranslations('USERS_NATIONAL_ID_ERROR_1'));
                        }
                      },
                    }),
                  ]}
                >
                  <Input
                    disabled={!user?.nationalIdType}
                    placeholder="00 000 0000"
                    onChange={(event) => {
                      setUser(
                        Object.assign({}, user, { nationalId: event.target.value })
                      );
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <div className={styles.divider} />
            <Row gutter={20}>
              <Col span={12}>
                <Form.Item
                  initialValue={user?.country}
                  name="country"
                  label={getTranslations('USERS_COUNTRY')}
                  rules={[
                    { type: "string", required: true, message: getTranslations('USERS_COUNTRY_ERROR_1') },
                  ]}
                >
                  <Select
                    showSearch
                    filterOption={(input, option) => {
                      return (option?.children?.toString().toLowerCase() ?? '').includes(`${input}`.toLowerCase());
                    }}
                    filterSort={(optionA, optionB) => {
                      return (optionA?.children?.toString() ?? '').toLowerCase().localeCompare((optionB?.children?.toString() ?? '').toLowerCase());
                    }
                    }
                    placeholder={getTranslations('USERS_COUNTRY_PLACEHOLDER')}
                    onChange={async (value) => {
                      setUser(Object.assign({}, user, { country: value, city: null }));
                      form.setFieldsValue({
                        city: null,
                      });
                      const countryCities: any = await import(`../../assets/countries/cities/${value}.json`);
                      const cities: ISelectValue[] = getUniqueNamesCities(countryCities);
                      setCities(cities);
                    }}
                  >
                    {Countries.map((item) => (
                      <Select.Option key={item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  initialValue={user?.city}
                  name="city"
                  label={getTranslations('USERS_CITY')}
                  rules={[
                    { type: "string", required: true, message: getTranslations('USERS_CITY_ERROR_1') },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder={getTranslations('USERS_CITY_PLACEHOLDER')}
                    onChange={(value) => {
                      setUser(Object.assign({}, user, { city: value }));
                    }}
                  >
                    {cities.map((item) => (
                      <Select.Option key={item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              initialValue={user?.address}
              name="address"
              label={getTranslations('USERS_ADDRESS')}
              rules={[
                { type: "string", required: true, message: getTranslations('GENERAL_REQUIRED') },
                { type: "string", max: 200, message: getTranslations('USERS_ADDRESS_ERROR_1') },
                { type: "string", min: 3, message: MIN_3_CHAR() },
              ]}
            >
              <Input.TextArea
                onChange={(event) => {
                  setUser(
                    Object.assign({}, user, { address: event.target.value })
                  );
                }}
              />
            </Form.Item>
          </div>
        </Col>
      </Row>
    </Form>
    <FormFooter
      editing={!!editing}
      objectName={getTranslations('USERS_TITLE_SINGULAR').toLocaleLowerCase()}
      onCancel={() => {
        history.push(`${Prefixes.user}`);
      }}
      onSave={() => {
        onSubmit(AfterActions.BACK);
      }}
      onSaveAndCreateAnother={() => {
        onSubmit(AfterActions.STAY);
      }}
    />
  </>;
};
