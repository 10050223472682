import React, { FunctionComponent, useEffect } from 'react';
import { Select } from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import { fetchGroups, fetchGroupsByCompany, selectAreGroupsFetching, selectGroupsDict } from '../../../../features/groups/groupSlice';
import { StatusEnum } from '../../../common/Status';

import styles from './groupSelector.module.scss';
import { getTranslations } from '../../../../features/translations/translationsUtils';

interface GroupSelectorProps {
  onChange: (groupId:string) => void,
  groupId?:string,
  companyId?: string,
}

const { Option } = Select;

export const GroupSelector: FunctionComponent<GroupSelectorProps> = ({
  onChange,
  groupId,
  companyId,
}) => {
  const groups = useSelector(selectGroupsDict);
  const groupsFetching = useSelector(selectAreGroupsFetching);

  const dispatch = useDispatch();
  const groupsIds = Object.keys(groups);
  const hasOneGroup = groupsIds.length === 1;
  const firstGroupId = groupsIds[0];
  useEffect(()=>{
    if(companyId){
      dispatch(fetchGroupsByCompany(companyId));
      return;
    }
    dispatch(fetchGroups());
  }, [dispatch, companyId]);
  useEffect(() => {
    if (hasOneGroup) {
      onChange(firstGroupId)
    }
  }, [firstGroupId, hasOneGroup, onChange]);

  return <Select placeholder={getTranslations('REPORT_GROUP')} allowClear value={groupId} onChange={onChange} className={styles.selector}  style={{ width: 250 }} dropdownStyle={{ width: 180 }} >
    {Object.keys(groupsFetching ? [] : groups).filter(groupId => {
      const group = groups[groupId];
      return !companyId || group.company === companyId;
    }).map(groupId=>{
      const group = groups[groupId];
      return <Option value={groupId} key={groupId}>
        {group.name} ({group.status === StatusEnum.Enable ? getTranslations('REPORT_ACTIVE'): getTranslations('REPORT_IDLE')})
      </Option>
    })}
  </Select>;
};