import React from "react";
import { UserReportData } from "..";
import { DoughnutSimple } from "../../common/Doughnut";
import { getTranslations } from "../../../../features/translations/translationsUtils";

interface Props {
  enabledUsers: UserReportData[];
  activeUsers: UserReportData[];
}

export const UserDoughnut: React.FunctionComponent<Props> = ({
  enabledUsers,
  activeUsers,
}) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      minHeight: 171,
    }}
  >
    <DoughnutSimple
      type="2"
      data={{
        labels: [getTranslations('REPORT_ACTIVE'), getTranslations('REPORT_IDLE')],
        datasets: [
          {
            label: "",
            data: [
              activeUsers.length,
              enabledUsers.length - activeUsers.length,
            ],
            backgroundColor: ["#FD6585", "#3DA3E8"],
            hoverOffset: 10,
          },
        ],
      }}
      percentValue={Number(
        (enabledUsers.length > 0
          ? (activeUsers.length / (enabledUsers.length)) *
            100
          : 0
        ).toFixed(2)
      )}
      description={getTranslations('REPORT_ACTIVE_USERS')}
    />
  </div>
);
