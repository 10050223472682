import React, { FunctionComponent } from 'react';
import { Row, Col } from 'antd';

import { selectTopicsStatus } from '../../../../features/topic/topicSlice';
import { LearningType } from '../../../../features/reports/learning/common/interfaces';
import { fetchTopicsCompletion, selectTopicsFulfillments, selectTopicsFulfillmentsFetching } from '../../../../features/reports/learning/topics/fulfillment';
import { fetchTopicsCompletionPerCompany, selectTopicsFulfillmentsPerCompany, selectTopicsFulfillmentsPerCompanyFetching } from '../../../../features/reports/learning/topics/fulfillmentPerCompany';
import { CommonFetcher } from '../common/CommonFetcher';
import { ChartTopicsIndicators, LeftTopicsIndicators } from './TopicsIndicators';
import { FulfillmentChart } from '../common/FulfillmentChart';
import { FulfillmentTop5Chart } from '../common/FulfillmentTop5';
import { ContentTable } from '../common/ContentTable';
import { selectTestResultsCounters, selectTestResultsCountersIsFetching } from '../../../../features/reports/learning/topics/testResultsCounters';
import { useSelector } from 'react-redux';
import { selectUserRole } from '../../../../features/auth/authSlice';
import { UserRole } from '../../../../features/users/usersSlice';
import { FulfillmentInTop10 } from '../common/FulfillmentInTop10';
import { fetchTopicsInTop10, selectTopicsInTop10, selectTopicsInTop10Fetching } from '../../../../features/reports/learning/topics/onTop10';
import { AnalyticsViewReport } from '../../common/AnalyticsViewReport';
import { ReportTypes, sectionTitles } from '../../MenuAndRoutes';

interface TopicsReportProps {
  companyId: string;
}

export const TopicsReport: FunctionComponent<TopicsReportProps> = ({companyId}) => {
  const userRole = useSelector(selectUserRole);
  let FulfillmentTop10;
  if(userRole === UserRole.SuperAdmin){
    FulfillmentTop10 = <FulfillmentTop5Chart
    fetchLearningContentCompletionPerCompany={fetchTopicsCompletionPerCompany}
    selectFulfillmentsPerCompany={selectTopicsFulfillmentsPerCompany}
    selectFulfillmentsPerCompanyFetching={selectTopicsFulfillmentsPerCompanyFetching}
    learningType={LearningType.topics}
    />
  }else{
    FulfillmentTop10 = <FulfillmentInTop10
      fetchLearningContentCompletionPerCompany={fetchTopicsCompletionPerCompany}
      fetchIsInTop10={fetchTopicsInTop10}
      selectFetchingInTop10={selectTopicsInTop10Fetching}
      selectIsInTop10={selectTopicsInTop10}
      learningType={LearningType.topics}
    />
  }
  return <>
    <AnalyticsViewReport companyId={companyId} reportName={sectionTitles()[ReportTypes.Topics]} />
    <CommonFetcher />
    <Row gutter={24} style={{marginBottom: 24}}>
      <Col span={16}>
        <LeftTopicsIndicators companyId={companyId}/>
      </Col>
      <Col span={8}>
        <ChartTopicsIndicators
          companyId={companyId}
          selectFulfillmentsPerCompany={selectTopicsFulfillmentsPerCompany}
          selectFulfillmentsPerCompanyFetching={selectTopicsFulfillmentsPerCompanyFetching}
        />
      </Col>
    </Row>
    <Row gutter={24}>
      <Col span={12}>
        <FulfillmentChart
            companyId={companyId}
            fetchLearningContentStatus={selectTopicsStatus}
            fetchLearningContentCompletion={fetchTopicsCompletion}
            selectFulfillments={selectTopicsFulfillments}
            selectFulfillmentsFetching={selectTopicsFulfillmentsFetching}
            learningType={LearningType.topics}
        />
      </Col>
      <Col span={12}>
        {FulfillmentTop10}
      </Col>
    </Row>
    <Row gutter={24}>
      <Col span={24}>
        <ContentTable
          companyId={companyId}
          selectEvaluationsResultsCounters={selectTestResultsCounters}
          selectEvaluationsResultsCountersIsFetching={selectTestResultsCountersIsFetching}
          learningType={LearningType.topics} 
          selectFulfillments={selectTopicsFulfillments}
          selectFulfillmentsFetching={selectTopicsFulfillmentsFetching}
        />
      </Col>
    </Row>
  </>
};