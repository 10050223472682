import { Tooltip } from "antd";
import React from "react";
import { CampaignSurveyTaken } from "..";
import { CogIcon } from "../../../common/CogIcon";
import { DoughnutSimple } from "../../common/Doughnut";
import { getTranslations } from "../../../../features/translations/translationsUtils";

interface Props {
  campaignsWithSurveyPercentage: CampaignSurveyTaken[];
}

export const CampaignsDoughnut: React.FunctionComponent<Props> = ({
  campaignsWithSurveyPercentage,
}) => {
  const surveyTakenPercentage =
    campaignsWithSurveyPercentage.length > 0
      ? Number(
          (
            campaignsWithSurveyPercentage.reduce(
              (total, item) => (total += item.percentage),
              0
            ) / campaignsWithSurveyPercentage.length
          ).toFixed(2)
        )
      : 0;

  return (
    <DoughnutSimple
      data={{
        labels: [getTranslations('REPORT_SURVEYS_TAKEN'), getTranslations('REPORT_SURVEYS_NO_TAKEN')],
        datasets: [
          {
            label: surveyTakenPercentage.toString() + "%",
            data: [surveyTakenPercentage, 100 - surveyTakenPercentage],
            backgroundColor: ["#3DA3E8", "#FD6585"],
            hoverOffset: 10,
          },
        ],
      }}
      percentValue={surveyTakenPercentage}
      description={<div>
        <div>
          {getTranslations('REPORT_PERCENT_SURVEYS_TAKEN')}&nbsp;
          <Tooltip placement='bottomRight' title={getTranslations('REPORT_PERCENT_SURVEYS_TAKEN_NFO')}>
            <span><CogIcon size="1.4em" color="#81858b" icon={'Attention'} /></span>
          </Tooltip>
        </div>
      </div>}
    />
  );
};
