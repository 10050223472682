import React, { FunctionComponent } from "react";

import { Spin } from "antd";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import cls from 'classnames';

import {
  selectAreStudentHomeCoursesFetching,
  selectStudentHomeCurrentCourse,
} from "../../../../features/students/homeCoursesSlice";

import placeholderImage from "../../../../assets/business-12.png";
import currentCourseIcon from '../../../../assets/homeCurrentCourse.png';
import playIcon from '../../../../assets/play.png';

import styles from "./currentCourse.module.scss";
import { useDeviceSize } from "../../../utils/useDeviceSize";
import { SIZES } from "../../../common/screen";
import { getTranslations } from "../../../../features/translations/translationsUtils";

interface StudentRouterProps { }

export const CurrentCourse: FunctionComponent<StudentRouterProps> = () => {
  const { course, progress, topics } = useSelector(selectStudentHomeCurrentCourse) || {};
  const loading = useSelector(selectAreStudentHomeCoursesFetching);
  const history = useHistory();
  const size = useDeviceSize();
  if (!course) {
    return null;
  }
  const isMobile = size === SIZES.PHONE;
  const imageUrl = course.imageurl ?? placeholderImage;
  const finishedLessonsCount = progress?.lessons.reduce((acc, value) => {
    if (value.progress === 1) {
      return acc + 1;
    }
    return acc;
  }, 0) ?? 0;
  const totalLessons = topics?.reduce((acc, topic) => {
    return acc + topic.lessons.length;
  }, 0)
  const renderHeader = () => {
    return <div className={cls(styles.header, {
      [styles.mobile]: isMobile,
    })}>
      <div className={styles.icon}>
        <img src={currentCourseIcon} alt="Current course" />
      </div>
      <div className={styles.info}>
        <div className={styles.title}>{getTranslations('HOME_CURRENT_COURSE_TITLE')}</div>
        <div className={styles.courseInfo}>
          {course?.title}<span className={styles.divider}>·</span>{getTranslations('HOME_LESSONS_CURRENT_COURSE', [`${finishedLessonsCount}/${totalLessons}`])}
        </div>
      </div>
    </div>
  };
  return <>
    <Spin spinning={loading}>
      <div className={cls(styles.container, {
        [styles.mobile]: isMobile,
      })}>
        <div className={styles.innerContainer}>
          {renderHeader()}
          <div className={styles.imageWrapper} onClick={() => {
            history.push(`/course/${course._id}`);
          }}>
            <div className={styles.play}><img src={playIcon} alt="go to course" /></div>
            <div
              style={{ background: `url('${imageUrl}') lightgray 50% / cover no-repeat` }}
              className={styles.image}
            />
          </div>
        </div>
      </div>
    </Spin>
  </>;
};
