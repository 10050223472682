import { Button, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import React from "react";
import { EducationalResource } from "..";
import { CogIcon } from "../../../common/CogIcon";
import { Status, StatusEnum } from "../../../common/Status";
import tableStyles from "../../../common/table.module.scss";
import { DataType, exportToExcel, HeadersType } from "../../common/excelExport";
import styles from "./index.module.scss";
import { getTranslations } from "../../../../features/translations/translationsUtils";

const EXCEL_HEADERS: HeadersType = [
  getTranslations('REPORT_TYPE'),
  getTranslations('REPORT_CATEGORY'),
  getTranslations('REPORT_NAME'),
  getTranslations('REPORT_FILE'),
  getTranslations('REPORT_STUDY_TIME'),
  getTranslations('REPORT_CONTENT_STATUS'),
  getTranslations('REPORT_TEACHER'),
  getTranslations('REPORT_CREATION_DATE'),
];

interface Props {
  resources: EducationalResource[];
  loading: boolean;
}

export const EducationalResourcesTable: React.FunctionComponent<Props> = ({
  resources,
  loading,
}) => {
  const columns: ColumnsType<EducationalResource> = [
    {
      title: getTranslations('REPORT_TYPE'),
      dataIndex: "type",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      sorter: (a: EducationalResource, b: EducationalResource) =>
        a.type.localeCompare(b.type),
      width: "10%",
    },
    {
      title: getTranslations('REPORT_CATEGORY'),
      dataIndex: "category",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      sorter: (a: EducationalResource, b: EducationalResource) =>
        a.category.localeCompare(b.category),
    },
    {
      title: getTranslations('REPORT_NAME'),
      dataIndex: "name",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      sorter: (a: EducationalResource, b: EducationalResource) =>
        a.name.localeCompare(b.name),
    },
    {
      title: getTranslations('REPORT_FILE'),
      dataIndex: "fileType",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      sorter: (a: EducationalResource, b: EducationalResource) =>
        a.fileType.localeCompare(b.fileType),
    },
    {
      title: getTranslations('REPORT_STUDY_TIME'),
      dataIndex: "studyHours",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      sorter: (a: EducationalResource, b: EducationalResource) =>
        a.studyHours - b.studyHours,
      width: "10%",
    },
    {
      title: getTranslations('REPORT_CONTENT_STATUS'),
      dataIndex: "status",
      render: (status) => {
        return <Status status={status} />;
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a: EducationalResource, b: EducationalResource) =>
        a.status === b.status ? 0 : a.status > b.status ? 1 : -1,
      width: "18%",
    },
    {
      title: getTranslations('REPORT_TEACHER'),
      dataIndex: "teacher",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      sorter: (a: EducationalResource, b: EducationalResource) =>
        a.teacher.localeCompare(b.teacher),
    },
    {
      title: getTranslations('REPORT_CREATION_DATE'),
      dataIndex: "creationDate",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      sorter: (a: EducationalResource, b: EducationalResource) =>
        new Date(a.creationDate).getTime() - new Date(b.creationDate).getTime(),
    },
  ];

  const handleDownload = () => {
    const headers = [...EXCEL_HEADERS];
    const data: DataType = resources.map((resource) => {
      return [
        resource.type,
        resource.category,
        resource.name,
        resource.fileType,
        resource.studyHours.toString(),
        resource.status === StatusEnum.Enable ? getTranslations('REPORT_ENABLED') : getTranslations('REPORT_DISABLED'),
        resource.teacher,
        resource.creationDate,
      ];
    });

    exportToExcel(headers, data, "Material", "detalle_material_educativo");
  };

  return (
    <>
      <div className={styles.tableHeader}>
        <div className={styles.title}>{getTranslations('REPORT_EDUCATIONAL_CONTENT_DETAILS')}</div>
        <Button
          className={styles.downloadButton}
          ghost={true}
          onClick={handleDownload}
        >
          {getTranslations('REPORT_DOWNLOAD')} <CogIcon size="1.4em" color="#81858b" icon={"Download"} />
        </Button>
      </div>
      <Table<EducationalResource>
        loading={loading}
        className={tableStyles.table}
        rowClassName={() => tableStyles.row}
        rowKey="id"
        columns={columns}
        dataSource={resources}
        pagination={{ position: ["bottomRight"], pageSize: 50 }}
        scroll={{ y: 372 }}
      />
    </>
  );
};
