import React, { useEffect, useState } from "react";

import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchProgram,
  selectAreProgramsFetching,
} from "../../features/program/programSlice";
import { MainLayout } from "../common/Layouts/Main";
import { ProgramForm } from "./Form";
import { useHistory, useRouteMatch } from "react-router-dom";
import LoadingOverlay from "../common/LoadingOverlay";
import { Prefixes } from "../prefixes";
import { getTranslations } from "../../features/translations/translationsUtils";

export const EditProgram: React.FC = () => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const match = useRouteMatch();
  const [editedProgram, setEditedProgram] = useState<string | null>(null);
  const areProgramsFetching = useSelector(selectAreProgramsFetching);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const programId = (match.params as any).id!;
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchProgram(programId));
    setEditedProgram(programId);
    setLoaded(true);
  }, [programId, dispatch]);

  useEffect(() => {
    if (!areProgramsFetching) {
      form.setFieldsValue({});
      form.resetFields();
    }
  }, [form, areProgramsFetching]);

  if (!loaded || areProgramsFetching) {
    return <LoadingOverlay spinning={true} />;
  }

  return (
    <MainLayout
      title={getTranslations('PROGRAM_EDIT')}
      onGoBack={() => {
        history.push(`${Prefixes.program}`);
      }}
      breadcrumbs={[getTranslations('PROGRAM_TITLE'), getTranslations('PROGRAM_EDIT')]}
    >
      <ProgramForm
        isCreate={false}
        programBeingEdited={editedProgram}
        form={form}
      />
    </MainLayout>
  );
};
