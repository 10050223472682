import React from 'react';
import { Select, Form } from 'antd';
import { useSelector } from 'react-redux';

import {
    selectCompanies,
    ICompany,
    selectCompaniesIds
} from '../../../features/company/companySlice';
import { IContent } from '../../../features/knowledgeBase/contentSlice';
import { getTranslations } from '../../../features/translations/translationsUtils';

interface CompanyFieldProps {
    newObject?: IContent,
    value?: string | ICompany,
    setNewObject: Function
}

export const CompanyField: React.FunctionComponent<CompanyFieldProps> = (props) => {
    const { value, setNewObject, newObject } = props;
    const idsList = useSelector(selectCompaniesIds);
    const list = useSelector(selectCompanies);
    return <Form.Item
        initialValue={newObject?.companyId}
        name="company"
        wrapperCol={{ sm: 24 }}
        label={getTranslations('COMPANY_FIELD_TITLE')}
        rules={[{ required: true, message: 'Requerido' }]}
    >
        <Select
            placeholder={getTranslations('COMPANY_FIELD__SELECT_ONE')}
            value={value?.toString()}
            style={{ width: '100%' }}
            onChange={(value) => {
                setNewObject({ ...newObject, companyId: value })
            }}>
            {idsList.map(id => {
                return <Select.Option key={id} value={id}>{list[id].name}</Select.Option>
            })}
        </Select>
    </Form.Item>
};