import React from "react";

import type { MenuProps } from 'antd';
import { Dropdown, Button } from "antd";

import { CogIcon } from "../CogIcon";

import styles from './formFooter.module.scss';
import { getTranslations } from "../../../features/translations/translationsUtils";

interface IFormFooter {
  onSave: () => void,
  onSaveAndCreateAnother: () => void,
  onCancel: () => void,
  objectName: string;
  disabledSave?: boolean;
  editing: boolean;
  justSave?: boolean;
}

const SAVE_AND_OUT = '1';
const SAVE_AND_ADD_ANOTHER = '2';

export const FormFooter: React.FunctionComponent<IFormFooter> = ({
  onCancel,
  onSave,
  onSaveAndCreateAnother,
  objectName,
  disabledSave = false,
  editing,
  justSave = false,
}) => {
  const actions: MenuProps['items'] = [
    {
      key: SAVE_AND_OUT,
      label: getTranslations('FORM_FOOTER_SAVE_AND_EXIT'),
    },
    {
      key: SAVE_AND_ADD_ANOTHER,
      label: getTranslations('FORM_FOOTER_SAVE_AND_ADD_OTHER'),
    },
  ];
  const defaultAction = actions[0];

  const onMenuClick = (e:any) => {
    if (e.key === SAVE_AND_ADD_ANOTHER) {
      onSaveAndCreateAnother();
      return;
    }
    onSave();
  };
  const saveButtonLabel = editing ? getTranslations('FORM_FOOTER_SAVE_AND_CHANGES') : getTranslations('FORM_FOOTER_CREATE_OBJECT', [objectName]);
  return <div className={styles.formFooter}>
    <div className={styles.divider} />
    <div className={styles.actions}>
      <Button
        type="default"
        size="large"
        onClick={onCancel}
      >
        {getTranslations('FORM_FOOTER_CANCEL')}
      </Button>
      &nbsp;
      {justSave ? <Button
        size="large"
        type="primary"
        onClick={() => onMenuClick(defaultAction)}
      >
        {getTranslations('FORM_FOOTER_APPLY_CHANGES')}
      </Button>:<Dropdown.Button
        disabled={disabledSave}
        placement="topRight"
        overlayClassName={styles.list}
        icon={<CogIcon color="var(--main-text-button-color)"  icon="Arrow-drop-down" />}
        type="primary"
        size="large"
        trigger={["click"]}
        menu={{
          items: actions,
          onClick: onMenuClick,
        }}
        onClick={() => onMenuClick(defaultAction)}
      >{saveButtonLabel}</Dropdown.Button>}
      
    </div>
  </div>
}