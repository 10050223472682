import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
    Button,
    Progress,
} from 'antd';
import cls from 'classnames';
import { useHistory } from 'react-router-dom';

import { CogIcon } from '../../common/CogIcon';
import {
    COURSES_PRIORITY_COLORS,
    COURSES_PRIORITY_LABELS,
    COURSES_PRIORITY_ICON
} from '../../../utils/constants';
import {
    selectCategory,
} from '../../../features/category/categorySlice';
import styles from './courseBlock.module.scss';
import { IStudentCourse } from '../../../features/students/common';
import { convertToP } from '../../utils/convertions';
import { selectIsStudentMyCourseBlocked } from '../../../features/students/myCoursesSlice';
import { ICourseRules } from '../../../features/program/programSlice';
import { NotAvailable } from '../common/NotAvaiblable';
import { SIZES } from '../../common/screen';
import { useDeviceSize } from '../../utils/useDeviceSize';
import { fetchHashByTemplateId, selectCertificateHash } from '../../../features/students/certificateHashSlice';
import { goToCertificate } from '../Course/Congratulations';
import { getTranslations } from '../../../features/translations/translationsUtils';
import { getTimeInHours } from '../common/timeUtils';

interface ProgressLocalProps {
    percentage: number,
}

const ProgressLocal: FunctionComponent<ProgressLocalProps> = ({ percentage }) => {
    return (
        <div className={styles.progress}>
            <div className={styles.percentage}>{(percentage * 100).toFixed(0)}%</div>
            <Progress percent={percentage * 100} showInfo={false} />
        </div>
    )
}

interface ScheduleProps {
    startDate?: Date,
    endDate?: Date,
    size?: SIZES,
}

const Schedule: FunctionComponent<ScheduleProps> = ({ startDate, endDate, size }) => {
    return (
        <div className={cls(
            styles.scheduleContainer,
            {
                [styles.justify]: (size === SIZES.TABLET || size === SIZES.PHONE),
            })
        }>
            <div className={styles.start}>
                Comienza: {moment(startDate).format('DD-MM-YYYY')}
            </div>
            <div className={styles.finish}>
                Termina: {endDate ? moment(endDate).format('DD-MM-YYYY') : 'no tiene fecha'}
            </div>
        </div>
    );
}

interface ColumnProps {
    courseId: string,
    isCompleted: boolean;
    extra?: ICourseRules,
    ctaHandle?: Function,
    isCourseDateAvailable: boolean,
    isBlockedBecausePredecesor: boolean,
}


const transformToDate = (date: Date | moment.Moment | undefined): Date | undefined => {
    if (date) {
        if (date instanceof Date) {
            return date;
        } else {
            return moment.isMoment(date) ? date.toDate() : undefined;
        }
    }
    return undefined;
};

const SecondColumn: FunctionComponent<ColumnProps> = ({
    isCompleted,
    extra,
    ctaHandle = () => { },
    isCourseDateAvailable,
    isBlockedBecausePredecesor,
}) => {
    const { startDate } = (extra || {}) as ICourseRules;
    const transformedStartDate = transformToDate(startDate);
    return (
        <div className={styles.secondColumn}>
            <div className={styles.buttonContainer}>
                {
                    (isCompleted || isCourseDateAvailable) && !isBlockedBecausePredecesor ?
                        <Button onClick={() => ctaHandle()} className="studentButton">Ver curso</Button>
                        : <NotAvailable />
                }
            </div>
            <Schedule startDate={transformedStartDate} endDate={extra?.endDate} />
        </div>
    );
}

interface StudentRouterProps {
    courseKey: number,
    selector: any
}

export const CourseBlock: FunctionComponent<StudentRouterProps> = ({ courseKey, selector }) => {
    const { course, extra, advancePercentage, isCompleted }: IStudentCourse = useSelector(selector(courseKey));
    const { priority = 1 } = extra || {};
    const category = useSelector(selectCategory(course.category.toString())) || {};
    const history = useHistory();
    const size = useDeviceSize();
    const isBlockedBecausePredecesor = useSelector(selectIsStudentMyCourseBlocked(course._id));
    const isCourseDateAvailable = extra?.endUndefined ?
        moment().isAfter(extra?.startDate) :
        moment().isBetween(
            extra?.startDate,
            extra?.endDate
        );
    const dispatch = useDispatch();
    const viewCourseHandler = () => {
        history.push(`/course/${course._id}`)
    }
    const hash = useSelector(selectCertificateHash(course.certificate || ''));

    useEffect(() => {
        if (course.certificate && advancePercentage === 1) {
            dispatch(fetchHashByTemplateId(course.certificate))
        }
    }, [advancePercentage, course.certificate, dispatch]);

    const { startDate } = (extra || {}) as ICourseRules;
    const transformedStartDate = transformToDate(startDate);

    return <div className={cls(styles.container, {
        [styles.clickable]: (size === SIZES.PHONE || size === SIZES.TABLET)
    })} onClick={() => {
        if (size === SIZES.TABLET || size === SIZES.PHONE) {
            if ((isCompleted || isCourseDateAvailable) && !isBlockedBecausePredecesor) {
                viewCourseHandler();
            }
        }
    }}>
        <div className={cls(
            styles.imageWrapper,
            {
                [styles.width25]: size === SIZES.DESKTOP,
                [styles.width40]: size === SIZES.TABLET,
                [styles.width50]: size === SIZES.PHONE,
            }
        )}>
            <span className={styles.category}>{category.name}</span>
            <img className={styles.image} src={course.imageurl} alt={`${course.title}`} />
        </div>
        <div className={cls(
            styles.innerContainer,
            {
                [styles.width75]: size === SIZES.DESKTOP,
                [styles.width60]: size === SIZES.TABLET,
                [styles.width50]: size === SIZES.PHONE,
            })
        }>
            <div className={styles.firstColumn}>
                <div className={cls(
                    styles.durationPriorityContainer,
                    {
                        [styles.justify]: (size === SIZES.TABLET || size === SIZES.PHONE),
                    })
                }>
                    <div className={styles.duration}>
                        <CogIcon className={styles.icon} color="#81858B" icon="clock" />
                        {getTimeInHours(course?.durationHour)}
                    </div>
                    <div className={styles.priority}>
                        <CogIcon className={styles.icon} color={COURSES_PRIORITY_COLORS[priority]} icon={COURSES_PRIORITY_ICON[priority]} />
                        {
                            size !== SIZES.PHONE ? (
                                <span>{getTranslations('STUDENT_PRIORITY', [getTranslations(COURSES_PRIORITY_LABELS[priority])])}</span>
                            ) : null
                        }
                    </div>
                </div>
                <div className={styles.title}>
                    {course.title}
                </div>
                <div className={styles.descriptionContainer}>
                    <div className={styles.description}>
                        {convertToP(course.description, '', '')}
                    </div>
                    <div className={styles.certificateButtonContainer}>
                        {hash && advancePercentage === 1 ? <Button className={cls('studentButton', styles.certificateButton)}
                            type="default" onClick={goToCertificate(hash)}
                        >Ver certificado <CogIcon icon="" /></Button> : null}
                    </div>
                </div>
                {
                    size === SIZES.DESKTOP &&
                    <div>
                        <ProgressLocal percentage={advancePercentage} />
                    </div>
                }
            </div>
            {
                size === SIZES.DESKTOP &&
                <SecondColumn
                    courseId={course._id}
                    extra={extra}
                    ctaHandle={viewCourseHandler}
                    isCompleted={isCompleted}
                    isBlockedBecausePredecesor={isBlockedBecausePredecesor}
                    isCourseDateAvailable={isCourseDateAvailable}
                />
            }
        </div>
        {
            (size === SIZES.TABLET || size === SIZES.PHONE) &&
            <>
                <div style={{ width: '100%' }}>
                    <ProgressLocal percentage={advancePercentage} />
                </div>
                <div style={{ width: '100%' }}>
                    <Schedule startDate={transformedStartDate} endDate={extra?.endDate} size={size} />
                </div>
            </>
        }
    </div>
};