import React from "react";
import { CogIcon } from "../../common/CogIcon";
import styles from "./emptyTable.module.scss";
import { getTranslations } from "../../../features/translations/translationsUtils";

export const EmptyTable: React.FunctionComponent = () => (
  <div className={styles.emptyContainer}>
    <CogIcon size="5.5em" color="#81858b" icon={"filter-empty"} />
    <h2>{getTranslations('REPORT_EMPTY_TABLE_1')}</h2>
    <p>
      {getTranslations('REPORT_EMPTY_TABLE_2')}
    </p>
  </div>
);
