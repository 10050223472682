import React, { FunctionComponent, useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { CompanyWithContentVisits } from "..";
import { getCognitoUserData } from "../../../../features/auth/authSlice";
import { selectUser, UserRole } from "../../../../features/users/usersSlice";
import LoadingOverlay from "../../../common/LoadingOverlay";
import { RoundedContainer } from "../../../common/RoundedContainer";
import { BarChartSimple } from "../../common/BarChartSimple";
import { TopFiveAward } from "../../common/TopFiveAward";
import { TileTile } from "../../Company/common/TileTitle";
import { useKBDataIsFetching } from "../common/useDataIsFetching";
import { getTranslations } from "../../../../features/translations/translationsUtils";

interface TopCompaniesProps {
  topWhat: number,
  companiesWithVisits: CompanyWithContentVisits[],
}
export const TopCompaniesWithMostVisitedContent: FunctionComponent<TopCompaniesProps> = ({ topWhat, companiesWithVisits }) => {
  const dataIsFetching = useKBDataIsFetching();
  const topCompaniesData = fillWithEmptyDataIfNeeded(companiesWithVisits, topWhat);
  const chartData = prepareDataForChart(topCompaniesData);
  const height = 300;
  const cognitoUserData = useSelector(getCognitoUserData);
  const cognitoUsername = cognitoUserData
    ? cognitoUserData["cognito:username"]
    : null;
  const userFromStore = useSelector(selectUser(cognitoUsername));
  const [isATopCompany, setIsATopCompany] = useState<boolean>(false);
  const [isCurrentACompanyAdmin, setiIsCurrentACompanyAdmin] = useState<boolean>(false);

  useEffect(() => {
    if (userFromStore?.role === UserRole.CompanyAdmin) {
      setiIsCurrentACompanyAdmin(true);
      if (topCompaniesData.some(companyData => companyData.companyId === userFromStore?.company)) {
        setIsATopCompany(true)
      }
    }
  }, [userFromStore, topCompaniesData]);

  return (
    <RoundedContainer>
      {!isCurrentACompanyAdmin ?
        (<TileTile text={getTranslations('TOP_N_COMPANIES_CONSULTED_KNOWLEDGE_BASE', [topWhat])} />) : null
      }
      {dataIsFetching ?
        (<LoadingOverlay inline height={height} spinning />) :
        (isCurrentACompanyAdmin ?
          <TopFiveAward isInTop5={isATopCompany} tooltipText='El top 10 se calcula en base a las empresas con más consultas a bases de conocimiento' /> :
          <BarChartSimple
            height={height}
            fetching={dataIsFetching}
            labelColumnTitle=""
            data={chartData}
            xMaxValue={100}
            xTickCallBack={(value: number) => (value + '%')}
            labelCallback={(tooltipItem: any, data: any) => {
              const dataset = data.datasets[tooltipItem.datasetIndex];
              return `${(dataset.data[tooltipItem.index].x).toFixed(2)}%`;
            }}
          />
        )
      }
    </RoundedContainer>
  )
}

const fillWithEmptyDataIfNeeded = (data: CompanyWithContentVisits[], topWhat: number): CompanyWithContentVisits[] => {
  const results: CompanyWithContentVisits[] = [...data];
  for (let i = data.length; i < topWhat; i++) {
    results.push({
      companyId: 'unknown',
      visitorUserIds: new Set(),
      contentVisitsIds: new Set(),
      companyName: getTranslations('REPORT_NO_DATA'),
      contentVisitsCount: 0,
      visitPercentage: 0,
    })
  }
  return results.slice(0, topWhat)
}

interface DataForChart {
  key: string,
  label: string,
  value: number,
}

const prepareDataForChart = (topCompaniesData: CompanyWithContentVisits[]) => {
  const dataForChart: DataForChart[] = [];
  topCompaniesData.forEach(item => {
    dataForChart.push({
      key: item.companyName,
      label: item.companyName,
      value: Math.round(item.visitPercentage * 10) / 10,
    })
  });

  return dataForChart;
}
