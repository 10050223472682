import React, { FunctionComponent, useEffect, useState } from "react";

import { Editor } from "@tinymce/tinymce-react";
import { Form, Input, Select, Row, Col, Switch } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { FormInstance } from "antd/lib/form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import cls from 'classnames';

import { Microservices } from "../../app/AllowedMicroservices";
import { BASE_URL } from "../../app/apiMiddleware";
import { cognitoUser, selectCompanyId, selectUserRole } from "../../features/auth/authSlice";
import {
  ICategoriesDict,
  ICategory,
} from "../../features/category/categorySlice";
import {
  fetchContentCategories,
  selectContentCategories,
  selectContentCategoriesDict,
} from "../../features/contentCategory/contentCategorySlice";
import {
  createContent,
  IContent,
  selectContent,
  selectIsContentFetching,
  updateContent,
} from "../../features/knowledgeBase/contentSlice";
import { CategoryField } from "../common/CategoryField";
import { CogIcon } from "../common/CogIcon";
import { OTHER_CATEGORY_OPTION } from "../common/CompanyTypeField";
import LoadingOverlay from "../common/LoadingOverlay";
import { MIN_3_CHAR } from "../Users/Form";
import { UploadFile } from "../Users/UploadFile";
import { CompanyField } from "./CompanyField";
import { FormFooter } from "../common/FormFooter";
import { Prefixes } from "../prefixes";
import { AfterActions } from "../common/DialogPopUp";

import styles from "./form.module.scss";
import formStyles from "../common/form.module.scss";
import { getTranslations } from "../../features/translations/translationsUtils";
import { UserRole } from "../../features/users/usersSlice";

interface StepProps {
  setContent: Function;
  form: FormInstance;
  categories: ICategoriesDict;
  categoriesIds: string[];
  validateForm?: Function;
  content?: IContent;
}
const MAX_TAGS = 10;
const MAX_TAG_CHAR_COUNT = 20;
const ONEMB = 1024 * 1024;
const ONEkB = 1024;

const fileUploadUrl = `${BASE_URL[Microservices.FILES_SERVICE]
  }/files/uploadToS3`;

const FirstStep: FunctionComponent<StepProps> = ({
  content,
  setContent,
  categories,
  categoriesIds,
}) => {
  const userRole = useSelector(selectUserRole);
  return (
    <React.Fragment>
      <div className={cls(styles.card, formStyles.section)}>
        <div className={formStyles.sectionTitle}>{getTranslations('KNOWLEDGE_BASIC_INFO')}</div>
        <Form.Item
          name="title"
          label={getTranslations('KNOWLEDGE_BASIC_INFO_TITLE')}
          initialValue={content?.title || ""}
          rules={[
            { type: "string", required: true, message: getTranslations('GENERAL_REQUIRED') },
            { type: "string", min: 3, message: MIN_3_CHAR() },
            {
              type: "string",
              max: 200,
              message: getTranslations('KNOWLEDGE_BASIC_INFO_TITLE_ERROR'),
            },
          ]}
        >
          <Input
            onChange={(event) => {
              setContent({ ...content, title: event.target.value });
            }}
          />
        </Form.Item>
        <CategoryField
          markRequired
          label={getTranslations('KNOWLEDGE_BASIC_INFO_CATEGORY')}
          object={content}
          setObject={setContent}
          categories={categories}
          categoriesIds={categoriesIds}
        />
        <Form.Item
          label={getTranslations('KNOWLEDGE_BASIC_INFO_DESCRIPTION')}
          name="description"
          initialValue={content?.description || ""}
          rules={[
            { type: "string", required: true, message: getTranslations('GENERAL_REQUIRED') },
            { type: "string", min: 3, message: MIN_3_CHAR() },
            {
              type: "string",
              max: 2000,
              message: getTranslations('KNOWLEDGE_BASIC_INFO_DESCRIPTION_ERROR'),
            },
          ]}
        >
          <Input.TextArea
            onChange={(event) => {
              setContent({ ...content, description: event.target.value });
            }}
          />
        </Form.Item>
        {userRole === UserRole.SuperAdmin ? <CompanyField
          newObject={content}
          value={content?.companyId}
          setNewObject={setContent}
        /> : null}
      </div>
    </React.Fragment>
  );
};

function image_upload_handler(
  blobInfo: any,
  success: any,
  failure: any,
  progress: any
) {
  let xhr: any;
  let formData;

  xhr = new XMLHttpRequest();
  xhr.withCredentials = false;
  xhr.open("POST", fileUploadUrl);
  xhr.setRequestHeader(
    "Authorization",
    cognitoUser?.getSignInUserSession()?.getAccessToken().getJwtToken() || ""
  );

  xhr.upload.onprogress = function (e: any) {
    progress((e.loaded / e.total) * 100);
  };

  xhr.onload = function () {
    let json: any;

    if (xhr.status === 403) {
      failure("HTTP Error: " + xhr.status, { remove: true });
      return;
    }

    if (xhr.status < 200 || xhr.status >= 300) {
      failure("HTTP Error: " + xhr.status);
      return;
    }

    json = JSON.parse(xhr.responseText);

    if (!json || typeof json.url != "string") {
      failure("Invalid JSON: " + xhr.responseText);
      return;
    }

    success(json.url);
  };

  xhr.onerror = function () {
    failure(
      "Image upload failed due to a XHR Transport error. Code: " + xhr.status
    );
  };

  formData = new FormData();
  formData.append("file", blobInfo.blob(), blobInfo.filename());

  xhr.send(formData);
}

const SecondStep: FunctionComponent<StepProps> = ({
  content,
  setContent,
}) => {
  const [editorContent, setEditorContent] = useState<string>(
    content?.content || ""
  );
  useEffect(() => {
    setEditorContent(content?.content || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleEditorChange = (text: string) => {
    setContent({ ...content, content: text });
  };
  const alignmentTools = 'alignleft aligncenter alignright alignjustify';
  const imageLinkTools = 'link image';
  const stylesTools = 'bold italic underline';
  const listIndentationTools = 'bullist numlist outdent indent';
  const undoRedoTools = 'undo redo';
  const formatTools = 'fontsizeselect';
  return (
    <React.Fragment>
      <div className={cls(styles.card, formStyles.section)}>
        <Editor
          initialValue={editorContent || ""}
          apiKey="eh3m6wbwp7pvti57g3mfuulw7rwm1xizoh50ylo8donvq20t"
          init={{
            height: 500,
            menubar: false,
            image_advtab: true,
            images_upload_handler: image_upload_handler,
            language: "es",
            language_url: "/langs/es.js",
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount",
            ],
            toolbar: ` ${formatTools} | ${alignmentTools} | ${stylesTools} | ${imageLinkTools} | ${listIndentationTools} | ${undoRedoTools} `,
          }}
          onEditorChange={handleEditorChange}
        />
      </div>
    </React.Fragment>
  );
};

const ThirdStep: FunctionComponent<StepProps> = ({
  content,
  setContent,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const pdfs = [...(content?.pdfs || [])];
  const handleChange = (info: any) => {
    const { status } = info.file;
    if (status === "uploading") {
      setLoading(true);
      return;
    }
    if (status === "done") {
      setLoading(false);
      pdfs.push({
        filename: info.file.name,
        size: info.file.size,
        url: info.file.response.url,
      });
      setContent({ ...content, pdfs });
    } else if (status === "error") {
      setLoading(false);
    }
  };
  const onRemovePdf = (index: number) => {
    const pdfs = [...(content?.pdfs || [])];
    pdfs.splice(index, 1);
    setContent({ ...content, pdfs });
  };
  return (
    <React.Fragment>
      <div className={cls(styles.card, formStyles.section)}>
        <div className={cls(styles.sectionTitle, formStyles.sectionTitle)}>{getTranslations('KNOWLEDGE_FILES')}</div>
        <UploadFile
          onHandleChange={handleChange}
          loading={loading}
          uploadUrl={fileUploadUrl}
          fileTypesAllowed={["application/pdf"]}
          fileNotAllowedMessage={getTranslations('KNOWLEDGE_FILES_ALLOWED')}
          maxMB={5}
          allowedFileInfo={getTranslations('KNOWLEDGE_FILES_ALLOWED_SIZE')}
        />
        {pdfs.map((pdf, index) => {
          const size =
            Math.round(
              (pdf.size > ONEMB ? pdf.size / ONEMB : pdf.size / ONEkB) * 10
            ) / 10;
          let unit = pdf.size > ONEMB ? "MB" : "kB";
          return (
            <div className={styles.pdfFile} key={index}>
              <CogIcon className={styles.attach} icon="Attach" />
              <a target="_black" href={pdf.url} className={styles.name}>
                {pdf.filename}
              </a>
              <span className={styles.size}>
                {size} {unit}
              </span>
              <span
                className={styles.removePdf}
                onClick={() => onRemovePdf(index)}
              >
                <CogIcon className={styles.remove} icon="Cross" />
              </span>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};


const FourthStep: FunctionComponent<StepProps> = ({
  content,
  setContent,
  form,
}) => {
  const hasAdditionalInfoOpened = content?.tags && content?.tags.length > 0;
  const [additionalInfoOpened, setAdditionalInfoOpened] = useState(hasAdditionalInfoOpened);
  const handleChange = (value: string[]) => {
    if (value.length <= MAX_TAGS) {
      setContent({ ...content, tags: [...value] });
    }
  };
  const tags: string[] = content?.tags ? [...content.tags] : [];
  return (
    <React.Fragment>
      <div className={cls(styles.card, formStyles.section)}>
        <div className={cls(styles.card, formStyles.sectionTitle)}>{getTranslations('KNOWLEDGE_ADVANCE')}</div>
        <div className={formStyles.hidableContainer}>
          <div className={formStyles.label}>{getTranslations('KNOWLEDGE_ADVANCE_ADDITIONAL_INFO')}</div>
          <Switch checked={additionalInfoOpened} size='small' onChange={() => {
            setContent({ ...content, tags: [] });
            form.setFieldValue('tags', []);
            setAdditionalInfoOpened(!additionalInfoOpened);
          }} />
        </div>
        {additionalInfoOpened ? <Form.Item
          label={getTranslations('GENERAL_TAGS')}
          name="tags"
          initialValue={content?.tags || []}
          rules={[
            {
              validator: (rule, value: any) => {
                if (value) {
                  const excedsMaxCharCount = value.find(
                    (element: string) => element.length > MAX_TAG_CHAR_COUNT
                  );
                  return new Promise((resolve, reject) => {
                    if (value.length > MAX_TAGS) {
                      value.pop();
                      reject(getTranslations('GENERAL_TAGS_ERROR_1'));
                      return;
                    } else if (excedsMaxCharCount) {
                      value.pop();
                      reject(getTranslations('GENERAL_TAGS_ERROR_2'));
                      return;
                    }
                    resolve(true);
                  });
                }
              },
            },
          ]}
        >
          <Select
            mode="tags"
            size={"default" as SizeType}
            placeholder={getTranslations('GENERAL_TAGS_ADD')}
            defaultValue={[...tags]}
            onChange={handleChange}
            style={{ width: "100%" }}
            maxTagCount={MAX_TAGS}
            maxTagTextLength={20}
            tokenSeparators={[" ", ","]}
          ></Select>
        </Form.Item> : null}
      </div>
    </React.Fragment>
  );
};


interface ContentFormPros {
  form: FormInstance;
  editing: string | null;
  onDone: (afterAction: AfterActions) => void;
}
export const ContentForm: FunctionComponent<ContentFormPros> = ({
  form,
  editing,
  onDone,
}) => {
  const isFetching = useSelector(selectIsContentFetching);
  const contentToEdit = useSelector(selectContent(editing));
  const [content, setContent] = useState<IContent>();
  const categoriesIds = useSelector(selectContentCategories);
  const categories = useSelector(selectContentCategoriesDict);
  const userRole = useSelector(selectUserRole);
  const companyId = useSelector(selectCompanyId);
   const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchContentCategories());
  }, [dispatch]);

  useEffect(() => {
    if (contentToEdit) {
      setContent(contentToEdit);
    }
  }, [contentToEdit]);
  if (isFetching || (contentToEdit && !content)) {
    return <LoadingOverlay spinning={true} />;
  }
  const onSave = (afterAction: AfterActions) => {
    if (!content) {
      return;
    }
    if (!content.content) {
      content.content = "<p></p>";
    }
    if (content?.category === OTHER_CATEGORY_OPTION) {
      content.category = {
        name: content.categoryName || "",
      } as ICategory;
    }
    delete content.categoryName;
    if (userRole !== UserRole.SuperAdmin) {
      content.companyId = companyId ?? '';
    }
    if (content._id) {
      dispatch(updateContent(content));
    } else {
      dispatch(createContent(content));
    }
    onDone(afterAction);
  };

  return (
    <div className={styles.container}>
      <Form form={form} layout="vertical">
        <Row gutter={50}>
          <Col span={9}>
            <FirstStep
              categories={categories}
              categoriesIds={categoriesIds}
              setContent={setContent}
              content={content}
              validateForm={form.validateFields}
              form={form}
            />
          </Col>
          <Col span={15}>
            <SecondStep
              categories={categories}
              categoriesIds={categoriesIds}
              setContent={setContent}
              content={content}
              validateForm={form.validateFields}
              form={form}
            />
            <ThirdStep
              categories={categories}
              categoriesIds={categoriesIds}
              setContent={setContent}
              content={content}
              validateForm={form.validateFields}
              form={form}
            />
            <FourthStep
              categories={categories}
              categoriesIds={categoriesIds}
              setContent={setContent}
              content={content}
              validateForm={form.validateFields}
              form={form}
            />
          </Col>
        </Row>
        <FormFooter
          editing={!!content?._id}
          objectName={getTranslations('KNOWLEDGE_BASE_TITLE_SINGULAR')}
          onCancel={() => {
            history.push(`${Prefixes.knowledgeBase}`);
          }}
          onSave={() => {
            onSave(AfterActions.BACK);
          }}
          onSaveAndCreateAnother={() => {
            onSave(AfterActions.STAY);
          }}
        />
      </Form>
    </div>
  );
};
