import React, { FunctionComponent, useEffect } from "react";

import * as H from "history";
import { useDispatch } from "react-redux";
import { Router, Switch, Route } from "react-router-dom";

import { clearBreadcrumbs } from "../../features/breadcrumbs/breadcrumbsSlice";
import { Group } from ".";
import { ActionSuffixes, Prefixes } from "../prefixes";
import { NewGroup } from "./new";
import { EditGroup } from "./edit";

interface AdminRouterProps {
  history: H.History<any>;
}

const prefix = Prefixes.group;

export const GroupRouter: FunctionComponent<AdminRouterProps> = ({
  history,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    return history.listen((location) => {
      dispatch(clearBreadcrumbs());
    });
  }, [dispatch, history]);
  return <Router history={history}>
    <Switch>
      <Route path={`${prefix}${ActionSuffixes.new}`} exact>
        <NewGroup />
      </Route>
      <Route path={`${prefix}/:id${ActionSuffixes.edit}`} exact>
        <EditGroup />
      </Route>
      <Route path={prefix}>
        <Group />
      </Route>
    </Switch>
  </Router>;
};