import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { FetchStatus } from "../../common/enums";
import apiActionCreator, {
  HttpMethods,
} from "../../../services/apiActionCreator";
import { Microservices } from "../../../app/AllowedMicroservices";
import { RootState } from "../../../app/store";

interface KnowledgeBaseVisits {
  userId: string;
  knowledgeBaseId: string;
  hits: number;
}

interface IInitialState {
  data: KnowledgeBaseVisits[];
  status: FetchStatus;
}

const initialState: IInitialState = {
  data: [],
  status: FetchStatus.Idle,
};

export const viewKnowledgeBaseSlice = createSlice({
  name: "knowledgeBaseVisits",
  initialState,
  reducers: {
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetched: (state, action: PayloadAction<Array<KnowledgeBaseVisits>>) => {
      state.data = action.payload;
      state.status = FetchStatus.Fetched;
    },
    error: (state) => {
      state.data = [];
      state.status = FetchStatus.Error;
    },
  },
});

export const { fetching, fetched, error } = viewKnowledgeBaseSlice.actions;

export const fetchKnowledgeVisits = () => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: "/content/visits",
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.KNOWLEDGE_BASE_SERVICE,
      authenticated: true,
    })
  );
};

export const selectKnowledgeBaseVisitsIsFetching = (state: RootState) =>
  state.reports.knowledgeBase.visits.status === FetchStatus.Fetching;
export const selectKnowledgeBaseVisits = (state: RootState) =>
  state.reports.knowledgeBase.visits.data;

export default viewKnowledgeBaseSlice.reducer;
