import ctx from "classnames";
import React from "react";
import { Menu } from "../Menu";
import { CogIcon } from "../CogIcon";
import cls from 'classnames';

import styles from "./main.module.scss";

import { getTranslations } from "../../../features/translations/translationsUtils";

interface MainLayoutProps {
  children: React.ReactNode;
  classname?: string;
  title: string;
  rightTopOptions?: React.ReactNode;
  hideTitle?: boolean;
  hideRightTopOptions?: boolean;
  titleContainerClass?: string;
  backgroundColor?: string;
  onGoBack?: () => void;
  breadcrumbs?: string[];
  hideMenu?: boolean;
}

const renderRightTopOptions = (
  rightTopOptions?: React.ReactNode,
  hideRightTopOptions?: boolean
) => {
  if (!rightTopOptions || hideRightTopOptions) {
    return null;
  }
  return <div className={styles.rightTopOptions}>{rightTopOptions}</div>;
};
interface IBreadcrumbsProps {
  breadcrumbs: string[];
  goBack?: () => void;
}
const Breadcrumbs: React.FunctionComponent<IBreadcrumbsProps> = ({ breadcrumbs, goBack }) => {
  return <div className={styles.breadcrumbs}>
    
    {breadcrumbs.map((item, index) => {
      return <span
        onClick={() => {
          if (goBack && index === 0) {
            goBack();
          }
        }}
        key={item}
        className={cls(styles.item, {[styles.pointer]: goBack && index === 0})}>
        {item}
      </span>;
    })}

  </div>;
}
export const MainLayout: React.FunctionComponent<MainLayoutProps> = ({
  title,
  rightTopOptions,
  hideRightTopOptions,
  hideTitle,
  children,
  titleContainerClass = "",
  backgroundColor,
  onGoBack,
  breadcrumbs,
  hideMenu = false,
}) => {
  return (
    <div className={styles.wrapper} style={{
      backgroundColor: backgroundColor ? backgroundColor : 'white'
    }}>
      <div className={styles.main}>
        {hideMenu ? null : <div className={styles.sider}>
          <Menu isLoggedIn />
        </div>}
        <div className={ctx(styles.contentContainer, { [styles.withoutPadding]: hideTitle })}>
          {breadcrumbs ? <Breadcrumbs goBack={onGoBack} breadcrumbs={breadcrumbs} /> : null}
          {onGoBack ? <div onClick={onGoBack} className={styles.goBackContainer}><CogIcon color="var(--tertiary-text-button-color)" icon="Arrow-left" /> {getTranslations('GENERAL_BACK')}</div> : null}
          <div className={ctx(styles.titleContainer, titleContainerClass, { [styles.hide]: hideTitle, [styles.withGoBack]: !!onGoBack })}>
            {!hideTitle ? <div className={styles.title}>{title}</div> : null}
            {renderRightTopOptions(rightTopOptions, hideRightTopOptions)}
          </div>
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};
