import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { useSelector } from "react-redux";
import { history } from "../../App";
import { Microservices } from "../../app/AllowedMicroservices";
import { RootState } from "../../app/store";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { ICourse } from "../courses/coursesSlice";
import { ILesson } from "../lesson/lessonSlice";
import { setMessageError } from "../message/messageSlice";
import { ICourseRules } from "../program/programSlice";
import { ITopic } from "../topic/topicSlice";
import { ILessonProgress, IProgress } from "./common";

export enum PreviousTopic {
  Same = 'same',
  Other = 'other'
}
export enum FetchCourseStatus {
  Fetching = "Fetching",
  Fetched = "Fetched",
  Error = "Error",
  Idle = "Idle",
  MakingProgress = "MakingProgress",
  RegisteringEvaluationPassed = "RegisteringEvaluationPassed",
}

export interface TopicsLessonsSelectedDisabled {
  [_id: string]: boolean;
}

interface topicDict {
  [_id: string]: ITopic;
}
interface lessonDict {
  [_id: string]: ILesson;
}

interface ITopicProgress {
  [_id: string]: number;
}
export enum CurrentItemType {
  Evaluation = "Evaluation",
  Lesson = "Lesson",
}
interface ICurrentItem {
  id: string | null;
  type: CurrentItemType | null;
}
export interface CourseState {
  course: ICourse | null;
  topics: topicDict;
  lessons: lessonDict;
  progress: IProgress | null;
  courseRules: ICourseRules | null;
  topicProgress: ITopicProgress;
  advancePercentage: number;
  status: FetchCourseStatus;
  testPassedStatus: FetchCourseStatus;
  evaluationsIds: string[];
  currentItem: ICurrentItem;
}

const initialState: CourseState = {
  course: null,
  topics: {},
  lessons: {},
  progress: null,
  courseRules: null,
  topicProgress: {},
  advancePercentage: 0,
  status: FetchCourseStatus.Idle,
  testPassedStatus: FetchCourseStatus.Idle,
  evaluationsIds: [],
  currentItem: {
    id: null,
    type: null,
  },
};

interface CoursesFetch {
  course: ICourse | null;
  topics: topicDict;
  lessons: lessonDict;
  progress: IProgress | null;
  courseRules: ICourseRules | null;
  topicProgress: ITopicProgress;
  advancePercentage: number;
}

export const FINISH_COURSE = "FINISH_COURSE";
export const COURSE_EXAM = "COURSE_EXAM";
export const COURSE_SURVEY = "COURSE_SURVEY";
export const TOPIC_TEST = "TOPIC_TEST";
export const LESSON = "LESSON";

export const studentCourseSlice = createSlice({
  name: "studentCourse",
  initialState,
  reducers: {
    registeringEvaluationPassed: (state) => {
      state.testPassedStatus = FetchCourseStatus.RegisteringEvaluationPassed;
    },
    registerEvaluationPassedSuccess: (state) => {
      state.testPassedStatus = FetchCourseStatus.Fetched;
    },
    makingProgress: (state) => {
      state.status = FetchCourseStatus.MakingProgress;
    },
    madeProgressSuccess: (state) => {
      state.status = FetchCourseStatus.Fetched;
    },
    fetching: (state) => {
      state.status = FetchCourseStatus.Fetching;
    },
    fetchedCourse: (
      state,
      {
        payload: {
          course,
          lessons,
          progress,
          topics,
          courseRules,
          advancePercentage,
          topicProgress,
        },
      }: PayloadAction<CoursesFetch>
    ) => {
      state.course = course;
      state.lessons = lessons;
      state.progress = progress;
      state.topics = topics;
      state.courseRules = courseRules;
      state.advancePercentage = advancePercentage;
      state.topicProgress = topicProgress;
      state.status = FetchCourseStatus.Fetched;
      const evaluationsIds = [];
      if (course?.evaluation) {
        evaluationsIds.push(course.evaluation);
      }
      if (course?.survey) {
        evaluationsIds.push(course.survey);
      }
      Object.values(lessons).forEach((lesson) => {
        if (lesson.evaluation) {
          evaluationsIds.push(lesson.evaluation);
        }
      });
      Object.values(topics).forEach((topic) => {
        if (topic.evaluation) {
          evaluationsIds.push(topic.evaluation);
        }
      });
      state.evaluationsIds = evaluationsIds;
      if (!course) {
        return;
      }
      const topicsCount = course.topics.length;
      const currentItem: ICurrentItem = {
        id: topics[course.topics[0]]?.lessons[0],
        type: CurrentItemType.Lesson,
      };
      state.currentItem = currentItem;
      for (let i = 0; i < topicsCount; ++i) {
        const topic = topics[course.topics[i]];
        const lessonsCount = topic.lessons.length;
        for (let j = 0; j < lessonsCount; ++j) {
          const lessonId = topic.lessons[j];
          const lessonProgress = progress?.lessons.find(
            (lessonProgress) => lessonProgress.lessonId === lessonId
          );
          currentItem.id = lessonId;
          currentItem.type = CurrentItemType.Lesson;
          if ((lessonProgress?.progress ?? 0) < 1) {
            return;
          }
        }
        if (topic.evaluation && topicProgress[topic._id] !== 1) {
          currentItem.id = topic.evaluation;
          currentItem.type = CurrentItemType.Evaluation;
          return;
        }
      }
      if (course.evaluation) {
        currentItem.id = course.evaluation;
        currentItem.type = CurrentItemType.Evaluation;
      }
    },
    error: (state, _action: PayloadAction) => {
      state.course = null;
      state.lessons = {};
      state.progress = null;
      state.topics = {};
      state.courseRules = null;
      state.advancePercentage = 0;
      state.topicProgress = {};
      state.status = FetchCourseStatus.Error;
    },
  },
});

export const {
  fetching,
  makingProgress,
  madeProgressSuccess,
  registeringEvaluationPassed,
  registerEvaluationPassedSuccess,
  fetchedCourse: fetchedCourses,
  fetchedCourse,
  error,
} = studentCourseSlice.actions;

export const fetchStudentCourse = (courseId: string) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: `/student/course/${courseId}`,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetchedCourse,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
            selector: () => {
              history.push("/myCourses");
            },
          },
          {
            actionOrCreator: setMessageError({
              message: "Este curso no esta disponible",
            }),
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
    })
  );
};

interface IMakeProgressStudentCourseParams {
  courseId: string;
  lessonId?: string;
  topicId?: string;
  progress: number;
  evaluationId?: string;
  
}
export const makeProgressStudentCourse = (
  params: IMakeProgressStudentCourseParams, doNext: () => void = () => {}
) => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: "/student/makeProgress",
      types: {
        requestType: makingProgress,
        successTypes: [
          {
            actionOrCreator: madeProgressSuccess,
            selector: () => {
              doNext();
              dispatch(fetchStudentCourse(params.courseId));
            },
          },
        ],
        errorTypes: [],
      },
      data: params,
      method: HttpMethods.POST,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
    })
  );
};

export const registeringEvaluationPassedStudentCourse = (
  evaluationId: string,
  courseId: string
) => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: "/student/passEvaluation",
      types: {
        requestType: registeringEvaluationPassed,
        successTypes: [
          {
            actionOrCreator: madeProgressSuccess,
            selector: () => {
              dispatch(fetchStudentCourse(courseId));
            },
          },
        ],
        errorTypes: [],
      },
      data: { evaluationId, courseId },
      method: HttpMethods.POST,
      microservice: Microservices.LEARNING_SERVICE,
      authenticated: true,
    })
  );
};

export const selectStudentCourseCurrentItem = (state: RootState) =>
  state.studentCourse.currentItem;
export const selectStudentCourseIsFetching = (state: RootState) =>
  state.studentCourse.status === FetchCourseStatus.Fetching;
export const selectStudentCourseAdvancePercentage = (state: RootState) =>
  state.studentCourse.advancePercentage;
export const selectStudentCourseTopicProgress = (id: string) => (
  state: RootState
) => state.studentCourse.topicProgress[id];
export const selectStudentCourseTopicProgressInternal = (id: string) => (
  state: RootState
) => state.studentCourse.progress?.topics.find((topic) => topic.topicId === id);
export const selectStudentCourseImage = (state: RootState) =>
  state.studentCourse.course?.imageurl;
export const selectStudentCourseDescription = (state: RootState) =>
  state.studentCourse.course?.description;
export const selectStudentCourseInfo = (state: RootState) => ({
  objectives: state.studentCourse.course?.objectives,
  evaluationSystem: state.studentCourse.course?.evaluationSystem,
  additionalInfo: state.studentCourse.course?.additionalInfo,
});

export const selectEvaluationsIds = (state: RootState) =>
  state.studentCourse.evaluationsIds;
export const selectStudentCourse = (state: RootState) =>
  state.studentCourse.course;
export const selectStudentCourseRules = (state: RootState) =>
  state.studentCourse.courseRules;
export const selectStudentCourseLesson = (id: string) => (state: RootState) =>
  state.studentCourse.lessons[id];
export const selectStudentCourseNextLessonIdAfterTest = (topicId: string) => (
  state: RootState
): ISelectStudentCourseNextLessonIdReturn => {
  const { topics, course } = state.studentCourse;
  const topicsIds = state.studentCourse.course?.topics || [];
  const topicIndex = topicsIds.findIndex(
    (topicIdToCompare) => topicIdToCompare === topicId
  );
  const topicsIdsCount = topicsIds.length;
  if (topicIndex >= topicsIdsCount - 1) {
    if (course?.evaluation) {
      return {
        where: COURSE_EXAM,
        id: course._id,
        samePreviousTopic: false,
      };
    }
    if (course?.survey) {
      return {
        where: COURSE_SURVEY,
        id: course._id,
        samePreviousTopic: false,
      };
    }
    return {
      where: FINISH_COURSE,
      id: course?._id || "",
      samePreviousTopic: false,
    };
  }
  const topic = topics[topicsIds[topicIndex + 1]];
  return {
    where: LESSON,
    id: topic.lessons[0],
    samePreviousTopic: false,
  };
};

export interface ISelectStudentCourseNextLessonIdReturn {
  where: string;
  samePreviousTopic: boolean;
  id: string;
}

export const selectStudentCourseNextLessonId = (id: string) => (
  state: RootState
): ISelectStudentCourseNextLessonIdReturn => {
  const { topics, course, progress } = state.studentCourse;
  const topicsProgress = progress?.topics || [];
  const topicsIds = course?.topics || [];
  const topicsIdsCount = topicsIds.length;
  for (const topicId in topics) {
    const topic = topics[topicId];
    const lessonsIds = topic.lessons;
    const lessonsIdsCount = lessonsIds.length;
    const lessonIndex = lessonsIds.indexOf(id);
    if (lessonIndex === -1) {
      continue;
    }
    if (lessonIndex !== lessonsIdsCount - 1) {
      return {
        where: LESSON,
        id: lessonsIds[lessonIndex + 1],
        samePreviousTopic: true,
      };
    }
    // last lesson
    const topicProgress = topicsProgress.find(progress => progress.topicId === topicId);
    if (topics[topicId].evaluation && (!topicProgress || topicProgress.progress !== 1)) {
      return {
        where: TOPIC_TEST,
        id: topicId,
        samePreviousTopic: false,
      };
    }

    const topicIndex = topicsIds.indexOf(topicId);
    if (topicIndex === topicsIdsCount - 1) {
      // last topic
      continue;
    }
    //next topic
    const nextTopic = topics[topicsIds[topicIndex + 1]];
    return {
      where: LESSON,
      id: nextTopic.lessons[0],
      samePreviousTopic: false,
    };
  }
  if (course?.evaluation) {
    return {
      where: COURSE_EXAM,
      id: course._id,
      samePreviousTopic: false,
    };
  }
  if (course?.survey) {
    return {
      where: COURSE_SURVEY,
      id: course._id,
      samePreviousTopic: false,
    };
  }
  return {
    where: FINISH_COURSE,
    id: course?._id || "",
    samePreviousTopic: false,
  };
};
export const selectStudentCourseTopicLesson = (lsessonId: string) => (
  state: RootState
) => {
  const { topics } = state.studentCourse;
  const topicId = Object.keys(topics).find((topicId: string) =>
    topics[topicId].lessons.includes(lsessonId)
  );
  if (!topicId) {
    return null;
  }
  return topics[topicId];
};
export const selectStudentCourseTopicIds = (state: RootState) =>
  state.studentCourse.course?.topics || [];
export const selectStudentCourseTopic = (id: string) => (state: RootState) =>
  state.studentCourse.topics[id];

export const selectStudentCourseProgress = (state: RootState) =>
  state.studentCourse.progress;
export const selectStudentCourseLessonProgress = (id: string) => (
  state: RootState
) =>
  state.studentCourse.progress?.lessons.find(
    (lesson) => lesson.lessonId === id
  );

const seekLastViewedItem = (
  state: RootState,
  lessonIds: string[],
  lessonsProgress: ILessonProgress[]
): ISelectStudentCourseNextLessonIdReturn => {
  let lessonId = lessonIds[0];
  let lastProgressUpdate = +new Date("1970/01/01");
  let lastLessonProgress = 0;

  lessonsProgress?.forEach((lessonProgress) => {
    const lastProgress = +new Date(lessonProgress.lastProgress);
    if (lessonProgress.lastProgress && lastProgressUpdate < lastProgress) {
      lastProgressUpdate = lastProgress;
      lessonId = lessonProgress.lessonId;
      lastLessonProgress = lessonProgress.progress;
    }
  });

  if (lastLessonProgress === 1) {
    return selectStudentCourseNextLessonId(lessonId)(state);
  }
  return {
    where: LESSON,
    id: lessonId,
    samePreviousTopic: true,
  };
};

export const selectStudentCourseLastLessonLessonId = (
  state: RootState
): ISelectStudentCourseNextLessonIdReturn => {
  const lessonIds:string[] = [];
  const topics = state.studentCourse.topics;
  state.studentCourse.course?.topics.forEach((topicId) => {
    const topic = topics[topicId];
    if (!topic) {
      return;
    }
    lessonIds.push(...topic.lessons);
  });
  const lessonsProgress = state.studentCourse.progress?.lessons || [];
  return seekLastViewedItem(state, lessonIds, lessonsProgress);
};

export const selectStudentCourseLastViewdItemByTopicId = (topicId: string) => (
  state: RootState
): ISelectStudentCourseNextLessonIdReturn => {
  const topic = useSelector(selectStudentCourseTopic(topicId));
  const { lessons: topicLessonsIds } = topic;
  const topicLessonsProgress =
    state.studentCourse.progress?.lessons.filter((progress) =>
      topicLessonsIds.includes(progress.lessonId)
    ) || [];

  return seekLastViewedItem(state, topicLessonsIds, topicLessonsProgress);
};

export const selectStudentCourseLastLessonLesson = (state: RootState) => {
  let lessonId = Object.keys(state.studentCourse.lessons)[0];
  let lastProgressUpdate = +new Date("1970/01/01");
  let progress = null;
  state.studentCourse.progress?.lessons.forEach((lessonProgress) => {
    const lastProgress = +new Date(lessonProgress.lastProgress);
    if (lessonProgress.lastProgress && lastProgressUpdate < lastProgress) {
      lastProgressUpdate = lastProgress;
      lessonId = lessonProgress.lessonId;
      progress = lessonProgress;
    }
  });
  return {
    lesson: state.studentCourse.lessons[lessonId],
    progress,
  };
};

export default studentCourseSlice.reducer;
