import { Form } from "antd";
import React, { FunctionComponent, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  selectAreCoursesFetching,
} from "../../features/courses/coursesSlice";
import { MainLayout } from "../common/Layouts/Main";
import { UserForm } from "./Form";
import { useHistory } from "react-router-dom";
import { Prefixes } from "../prefixes";
import { getTranslations } from "../../features/translations/translationsUtils";

export const NewUser: FunctionComponent = () => {
  const areCoursesFetching = useSelector(selectAreCoursesFetching);
  const [form] = Form.useForm();
  const history = useHistory();

  useEffect(() => {
    if (!areCoursesFetching) {
      form.setFieldsValue({});
      form.resetFields();
    }
  }, [form, areCoursesFetching]);

  return (
    <MainLayout
      title={getTranslations('USERS_CREATE')}
      onGoBack={() => {
        history.push(`${Prefixes.user}`);
      }}
      breadcrumbs={[getTranslations('USERS_TITLE'), getTranslations('USERS_NEW')]}
    >
      <UserForm
        editing={null}
        form={form}
      />
    </MainLayout>
  );
};
