import React, { FunctionComponent, useEffect } from "react";

import { Button, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import * as H from 'history';
import { useHistory } from "react-router-dom";

import {
  fetchCategories,
  selectCategoriesDict,
} from "../../features/category/categorySlice";
import {
  selectAreCoursesFetching,
} from "../../features/courses/coursesSlice";
import { MainLayout } from "../common/Layouts/Main";
import { CoursesList } from "./List";
import { ActionSuffixes, Prefixes } from "../prefixes";
import { getTranslations } from "../../features/translations/translationsUtils";

function renderOptions(history: H.History) {
  return (
    <Button
      size="large"
      onClick={() => {
        history.push(`${Prefixes.course}${ActionSuffixes.new}`);
      }}
      type="primary"
    >
      {getTranslations('COURSE_NEW')}
    </Button>
  );
}

export const Courses: FunctionComponent = () => {
  const areCoursesFetching = useSelector(selectAreCoursesFetching);
  const categories = useSelector(selectCategoriesDict);
  const history = useHistory();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!areCoursesFetching) { 
      form.setFieldsValue({});
      form.resetFields();
    }
  }, [form, areCoursesFetching]);

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const onEdit = (courseId: string) => {
    history.push(`${Prefixes.course}/${courseId}${ActionSuffixes.edit}`);
  };
  return (
    <MainLayout
      title={getTranslations('COURSE_TITLE')}
      rightTopOptions={renderOptions(history)}
      breadcrumbs={[getTranslations('COURSE_TITLE')]}
    >
      <CoursesList editCourse={onEdit} categories={categories} />
    </MainLayout>
  );
};
