import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../app/store';
import { selectCompanies, selectCompaniesAreFetching } from '../../../../../features/company/companySlice';
import { selectCompaniesGroups } from '../../../../../features/groups/groupSlice';
import { PIECHART_COLORS } from '../../../../../features/reports/common';
import { selectCompaniesContents, selectCompaniesContentsAreFetching, selectGroupsContents } from '../../../../../features/reports/learning/common/companiesContent';
import { IFulfillmentPerCompany } from '../../../../../features/reports/learning/common/interfaces';
import { fetchTestCounters, selectTestResultsCounters, selectTestResultsCountersIsFetching } from '../../../../../features/reports/learning/topics/testResultsCounters';
import { selectAreUsersFetching, selectStudents, selectStudentsPerCompany } from '../../../../../features/users/usersSlice';
import LoadingOverlay from '../../../../common/LoadingOverlay';
import { RoundedContainer } from '../../../../common/RoundedContainer';
import { DisplayDirection, IconNames } from '../../../common/SimpleStat';
import { StatData, StatsRow } from '../../../common/StatsRow';

import styles from './index.module.scss';
import { getTranslations } from '../../../../../features/translations/translationsUtils';

interface TopicsIndicatorsProps {
  companyId?: string,
  selectFulfillmentsPerCompany: (state: RootState) => IFulfillmentPerCompany;
  selectFulfillmentsPerCompanyFetching: (state: RootState) => boolean;
}

interface LeftTopicsIndicatorsProps {
  companyId?: string,
}

export const LeftTopicsIndicators: FunctionComponent<LeftTopicsIndicatorsProps> = (
  {
    companyId
  }
) => {
  const [testResult, setTestResult] = useState<number>(0)
  const testResultsCountersIsFetching = useSelector(selectTestResultsCountersIsFetching);
  const testResultsCounters = useSelector(selectTestResultsCounters);
  const students = useSelector(selectStudents);
  const companiesContent = useSelector(selectCompaniesContents);
  const contentsAreFetching = useSelector(selectCompaniesContentsAreFetching);
  const isStudentFetching = useSelector(selectAreUsersFetching);
  const dispatch = useDispatch();

  let count = 0;
  if(companyId){
    count = companiesContent[companyId] && companiesContent[companyId].topics ? (Array.from(new Set(companiesContent[companyId].topics))).length : 0;
  }else{
    const topicsIds:string[] = [];
    Object.values(companiesContent).forEach(companyContents => {
      topicsIds.push(...companyContents.topics);
    });
    count = (Array.from(new Set(topicsIds))).length;
  }
  const statsData: StatData = [
    {
      iconName: IconNames.Check,
      mainText: `${testResult}%`,
      description: getTranslations('REPORT_AVERAGE_SCORE_PER_TEST'),
      direction:DisplayDirection.row,
      iconColor: '#4376F9',
    },
    {
      iconName: IconNames.AvailableTopics,
      mainText: `${count}`,
      description: getTranslations('REPORT_AVAILABLE_TOPICS'),
      direction:DisplayDirection.row
    }
  ];
  useEffect(()=>{
    if(isStudentFetching){
      return;
    }
    const usersIds = Object.keys(students).filter(userId => {
      const student = students[userId];
      const isFromCompany = !companyId || (student.company === companyId);
      return student.enabled && isFromCompany;
    });
    if (usersIds.length) {
      dispatch(fetchTestCounters({
        usersIds,
        companyId
      }));
    }
  }, [companyId, dispatch, isStudentFetching, students]);

  useEffect(()=>{
    if(testResultsCountersIsFetching){
      return;
    }
    let total = 0;
    let responses = 0;
    testResultsCounters.forEach(testResultCounter=>{
      total += testResultCounter.responsesCount;
      responses += testResultCounter.results;
    });
    total = total ? total : 1;
    setTestResult(Math.round(responses/total*100));
  }, [testResultsCounters, testResultsCountersIsFetching])
  
  return <RoundedContainer className={styles.container}>
    {contentsAreFetching ? 
      <LoadingOverlay inline height={300} spinning /> :
      <StatsRow statsData={statsData} />
    }
  </RoundedContainer>;
}

export const ChartTopicsIndicators: FunctionComponent<TopicsIndicatorsProps> = (
  {
    selectFulfillmentsPerCompany,
    selectFulfillmentsPerCompanyFetching,
    companyId
  }
) => {
  const [data, setData] = useState<[number, number]>([0, 0]);
  const companiesContent = useSelector(selectCompaniesContents);
  const contentsAreFetching = useSelector(selectCompaniesContentsAreFetching);
  // Users
  const studentsPerCompany = useSelector(selectStudentsPerCompany);
  // Companies
  const isCompaniesFetching = useSelector(selectCompaniesAreFetching);
  const companies = useSelector(selectCompanies);
  // Fulfillments
  const isFulfillmentsFetching = useSelector(selectFulfillmentsPerCompanyFetching);
  const fulfillments = useSelector(selectFulfillmentsPerCompany);
  //Groups
  const companiesGroups = useSelector(selectCompaniesGroups);
  const groupsContents = useSelector(selectGroupsContents);

  const fetching = isCompaniesFetching || 
    isFulfillmentsFetching || 
    contentsAreFetching;

  useEffect(()=>{
    if(fetching || Object.keys(companiesContent).length === 0){
      return;
    }
    let completed = 0;
    Object.keys(fulfillments).forEach(fulfillmentsCompanyId => {
      if(companyId && fulfillmentsCompanyId !== companyId){
        return;
      }
      if(!companiesContent[fulfillmentsCompanyId]){
        return;
      }
      const fulfillment = fulfillments[fulfillmentsCompanyId];
      const company = companies[fulfillmentsCompanyId];
      if(!company){
        return;
      }
      const studentsCount = studentsPerCompany[fulfillmentsCompanyId]?.length;
      if(!studentsCount){
        return;
      }
      completed += fulfillment.completed;
    });
    let total = 0;
    const addCompanyToTotal = (companyId:string) => {
      Array.from(new Set(companiesGroups[companyId])).forEach(groupId=>{
        if(!groupsContents[groupId]){
          return;
        }
        const contentCount = groupsContents[groupId].topics.length;
        const studentsCount = groupsContents[groupId].students;
        total += studentsCount * contentCount ;
      });
    }
    Object.keys(companiesGroups).forEach(companyId => {
      addCompanyToTotal(companyId);
    });
    setData([completed, total - completed]);
  },[companies, fetching, fulfillments, companiesContent, studentsPerCompany, companyId, companiesGroups, groupsContents]);

  const [completed, rest] = data;
  let total = completed + rest;
  const percentage = Math.round(completed/(total ? total : 1) * 100);
  const statsData: StatData = [
    {
      percentValue: percentage.toFixed(2),
      description: <div>{getTranslations('REPORT_PERCENT_FINISHED_TOPICS')}</div>,
      canvasHeight: 100,
      type: '2',
      data: {
        labels: [
          getTranslations('REPORT_TOPICS_FINISHED'),
          getTranslations('REPORT_TOPICS_UNFINISHED'),
        ],
        datasets: [{
          label: '',
          data,
          backgroundColor: [
            PIECHART_COLORS[0],
            PIECHART_COLORS[1],
          ],
          hoverOffset: 4
        }],
      },
    }
  ];
  return <RoundedContainer className={styles.container}>
    {contentsAreFetching ? 
      <LoadingOverlay inline height={300} spinning /> :
      <StatsRow statsData={statsData} />
    }
  </RoundedContainer>;
}