import React, { useEffect, useState } from "react";

import { Form } from "antd";
import { cloneDeep } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";

import { selectCompanyId, selectUserRole } from '../../features/auth/authSlice';
import {
  fetchCategories,
  ICategory,
  selectCategories,
  selectCategoriesDict,
} from "../../features/category/categorySlice";
import { selectEvaluationsDict } from '../../features/evaluation/evaluationsSlice';
import {
  fetchLessons,
  hideModal,
  ILesson,
  MediaTypes,
  selectDisplayModal,
  selectLesson,
  updateLesson,
  VideoTypes,
} from "../../features/lesson/lessonSlice";
import {
  fetchTeachers, selectTeachersDict,
} from "../../features/teacher/teacherSlice";
import { UserRole } from '../../features/users/usersSlice';
import { OTHER_CATEGORY_OPTION } from "../common/CategoryField";
import { AfterActions, DialogPopUp } from "../common/DialogPopUp";
import { MainLayout } from "../common/Layouts/Main";
import { LessonForm } from "./Form";
import LoadingOverlay from "../common/LoadingOverlay";
import { FormFooter } from "../common/FormFooter";
import { ActionSuffixes, Prefixes } from "../prefixes";
import { getTranslations } from "../../features/translations/translationsUtils";

const EMPTY_LESSON = {
  title: "",
  category: "",
  description: "",
  tags: [],
  media: [],
};

const INITIAL_VALUES = {
  title: "",
  category: undefined,
  description: "",
  tags: [],
  teacher: undefined,
  picture: "",
  firstName: "",
  middleName: "",
  lastName: "",
  secondLastName: "",
  teacherDescription: "",
};

export const EditLesson: React.FunctionComponent = () => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const match = useRouteMatch();
  const [fileType, setFileType] = useState<MediaTypes>(MediaTypes.IMAGE);
  const [newLesson, setNewLesson] = useState<ILesson>(EMPTY_LESSON);
  const [currentAction, setCurrentAction] = useState<string>("");
  const [youtubeUrlError, setYoutubeUrlError] = useState('');
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const categoriesDict = useSelector(selectCategoriesDict);
  const categoriesIds = useSelector(selectCategories);
  const evaluationsDict = useSelector(selectEvaluationsDict);
  const userRole = useSelector(selectUserRole);
  const companyId = useSelector(selectCompanyId);
  const selectedLesson = useSelector(selectLesson((match.params as any).id!));
  const teachersDict = useSelector(selectTeachersDict);
  const history = useHistory();

  const isModalVisible = useSelector(selectDisplayModal);

  useEffect(() => {
    if ((!newLesson && selectedLesson) || (newLesson && selectedLesson && newLesson._id !== selectedLesson._id)) {
      setNewLesson(selectedLesson);
      if (selectedLesson.media && selectedLesson.media[0]) {
        setFileType(selectedLesson.media[0].type);
      }
      setLoaded(true);
      let teacher;
      if (typeof selectedLesson.teacher === "string") {
        teacher = teachersDict[selectedLesson.teacher];
      }
      const fields = {
        title: selectedLesson.title,
        category:
          typeof selectedLesson.category === "object"
            ? selectedLesson.category._id
            : selectedLesson.category,
        description: selectedLesson.description,
        tags: selectedLesson.tags,
        teacher: teacher?._id,
        picture: teacher?.picture ?? "",
        firstName: teacher?.firstName ?? "",
        middleName: teacher?.middleName ?? "",
        lastName: teacher?.lastName ?? "",
        secondLastName: teacher?.secondLastName ?? "",
        teacherDescription: teacher?.description ?? "",
        companyId: selectedLesson.companyId,
      };
      form.setFieldsValue(fields);
    }
  }, [selectedLesson, newLesson, form, teachersDict]);

  useEffect(() => {
    dispatch(fetchLessons());
    dispatch(fetchCategories());
    dispatch(fetchTeachers());
  }, [dispatch]);

  const handleAction = (afterAction: AfterActions) => {
    form
      .validateFields()
      .then(() => {
        if (!newLesson?.category) {
          return;
        }
        const lessonCopy = cloneDeep(newLesson);
        if (newLesson.media[0].videoType === VideoTypes.YOUTUBE) {
          if (!newLesson.media[0].url) {
            setYoutubeUrlError(getTranslations('GENERAL_REQUIRED'));
            return;
          }
          if (
            !newLesson.media[0].url.startsWith('https://www.youtube.com/watch?v=') &&
            !newLesson.media[0].url.startsWith('https://youtu.be/')
          ) {
            setYoutubeUrlError(getTranslations('GENERAL_URL_INVALID'));
            return;
          }
          if (newLesson.media[0].url.startsWith('https://youtu.be/')) {
            lessonCopy.media[0].url = `https://www.youtube.com/watch?v=${newLesson.media[0].url.replace('https://youtu.be/', '')}`;
          }
        }
        if (lessonCopy.category === OTHER_CATEGORY_OPTION) {
          lessonCopy.category = {
            name: lessonCopy.categoryName ?? "",
          } as ICategory;
        }
        delete lessonCopy.categoryName;
        if (userRole !== UserRole.SuperAdmin) {
          if (lessonCopy._id && lessonCopy.companyId !== companyId) { // don't allow editing lesson that don't belong to the company
            return;
          }
          lessonCopy.companyId = companyId; // force to company in case of creating
        }
        if (!lessonCopy.companyId) {
          lessonCopy.companyId = undefined;
        }
        if (lessonCopy.companyId || !lessonCopy.teacher || lessonCopy.teacher === "") {
          delete lessonCopy.teacher;
        }
        delete lessonCopy.teacherName;
        setCurrentAction(afterAction);
        dispatch(
          updateLesson({
            ...lessonCopy,
            media: lessonCopy.media?.filter((item) => item.type === fileType),
          })
        );
        form.setFieldsValue(INITIAL_VALUES);
      })
      .catch((_error) => { });
  };
  const isYoutube = !!(newLesson?.media && newLesson?.media[0] && newLesson?.media[0].videoType === VideoTypes.YOUTUBE);
  let saveButtonIsDisable = !newLesson?.evaluation ?
    false :
    (fileType === MediaTypes.VIDEO && !isYoutube && newLesson.quizCuePoints?.length !== evaluationsDict[newLesson.evaluation]?.questions.length);


  if (!loaded || !newLesson._id) {
    return <LoadingOverlay spinning={true} />;
  }

  return (
    <MainLayout
      title={getTranslations('LESSON_EDIT')}
      onGoBack={() => {
        history.push(`${Prefixes.lesson}`);
      }}
      breadcrumbs={[getTranslations('LESSON_TITLE'), getTranslations('LESSON_EDIT')]}
    >
      <DialogPopUp
        name={getTranslations('LESSON_TITLE_SINGULAR')}
        action={getTranslations('LESSON_TITLE_UPDATED')}
        visible={isModalVisible}
        onCancel={() => {
          dispatch(hideModal());
          if (currentAction === AfterActions.BACK) {
            history.push(`${Prefixes.lesson}`);
            return;
          }
          history.push(`${Prefixes.lesson}${ActionSuffixes.new}`);
        }}
      />
      <LessonForm
        form={form}
        fileType={fileType}
        newLesson={newLesson}
        categories={categoriesDict}
        categoriesIds={categoriesIds}
        setFileType={setFileType}
        setNewLesson={setNewLesson}
        youtubeUrlError={youtubeUrlError}
      />
      <FormFooter
        editing
        objectName={getTranslations('LESSON_TITLE_SINGULAR').toLocaleLowerCase()}
        disabledSave={saveButtonIsDisable}
        onCancel={() => {
          history.push(`${Prefixes.lesson}`);
        }}
        onSave={() => {
          handleAction(AfterActions.BACK);
        }}
        onSaveAndCreateAnother={() => {
          handleAction(AfterActions.STAY);
        }}
      />
    </MainLayout>
  );
};
