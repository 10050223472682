import React, { FunctionComponent } from "react";
import ctx from "classnames";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  selectStudentCourseLessonProgress,
  selectStudentCourseLesson,
  selectStudentCourse,
  selectStudentCourseCurrentItem,
  CurrentItemType
} from "../../../features/students/courseSlice";
import { CompletenessIndicator } from "./CompletenessIndicator";

import styles from "./topicLesson.module.scss";

interface StudentLessonTopicLessonProp {
  className?: string;
  lessonId: string;
  currentLessonId?: string;
}

export const StudentLessonTopicLesson: FunctionComponent<StudentLessonTopicLessonProp> = ({
  className,
  lessonId,
  currentLessonId
}) => {
  const currentItem = useSelector(selectStudentCourseCurrentItem);
  const courseId = useSelector(selectStudentCourse)?._id;
  const lesson = useSelector(selectStudentCourseLesson(lessonId));
  const progress = useSelector(selectStudentCourseLessonProgress(lessonId));
  const history = useHistory();
  const isCurrentLesson = currentLessonId === lesson._id;
  const canGoThere =
    (currentItem.id === lessonId && currentItem.type === CurrentItemType.Lesson) ||
    isCurrentLesson || (progress?.progress ?? 0) > 0;
  const lessonProgress = progress?.progress || 0;
  const hasInProgressTag = canGoThere && (lessonProgress < 1);
  return (
    <React.Fragment>
      <div className={styles.divider} />
      <div
        className={ctx(
          styles.container,
          { [styles.cannotGoThere]: !canGoThere },
          className
        )}
      >
        <CompletenessIndicator progress={lessonProgress} />
        <div
          className={ctx(styles.title, {
            [styles.selected]: isCurrentLesson && !hasInProgressTag
          })}
          onClick={() => {
            if (canGoThere) {
              history.push(`/course/${courseId}/${lesson._id}`);
            }
          }}
        >

          <div
            className={ctx(styles.title, { [styles.selected]: hasInProgressTag })}
          >
            {lesson?.title}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
