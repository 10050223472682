import React, {
  FunctionComponent,
} from "react";
import { Button, Card } from "antd";

import styles from "./firstPage.module.scss";
import { useSelector } from "react-redux";
import {
  selectEvaluation,
} from "../../../../features/evaluation/currentEvaluationSlice";
import { CogIcon } from "../../../common/CogIcon";
import {
  selectStudentCourseTopic
} from "../../../../features/students/courseSlice";
import { getTranslations } from "../../../../features/translations/translationsUtils";

interface FirstPageProps {
  className?: string;
  topicId: string;
  onNext: Function;
  evaluationId: string;
}

export const FirstPage: FunctionComponent<FirstPageProps> = ({
  evaluationId,
  topicId,
  onNext,
}) => {
  const topic = useSelector(selectStudentCourseTopic(topicId));
  const evaluation = useSelector(selectEvaluation(evaluationId));
  const questionsCount = evaluation?.questions?.length || 1;
  const correctAnswersToPass = topic?.correctAnswersToPass || 0;
  const percentageToPass = Math.round(correctAnswersToPass / questionsCount * 100);
  return <Card className={styles.container}>
    <CogIcon className={styles.icon} icon="test-1" />
    <div className={styles.info}>{getTranslations('STUDENT_TEST_TITLE')}</div>
    <ul className={styles.infoList}>
      <li>{getTranslations('STUDENT_TEST_INSTRUCTION_1')}</li>
      <li>{getTranslations('STUDENT_TEST_INSTRUCTION_2', [percentageToPass > 100 || percentageToPass === 0 ? '--' : percentageToPass])}</li>
      <li>{getTranslations('STUDENT_TEST_INSTRUCTION_3')}</li>
    </ul>
    <div className={styles.buttonContainer}>
      <Button
        className={styles.button}
        onClick={() => onNext()}
        type="primary"
      >
        {getTranslations('STUDENT_TEST_BEGIN')}
      </Button>
    </div>
  </Card>;
};
