import React, { FunctionComponent } from 'react';
import { Button } from 'antd';
import { CogIcon } from '../../common/CogIcon';
import cls from 'classnames';

import styles from './progress.module.scss';
import { getTranslations } from '../../../features/translations/translationsUtils';

interface IProgressProps {
  back: () => void,
  progress: number,
  maxSteps: number,
};

export const Progress: FunctionComponent<IProgressProps> = ({
  back,
  maxSteps,
  progress
}) => {
  const steps = Array.from(Array(maxSteps).keys()).map(step => step + 1);
  return <div className={styles.container}>
    <div className={styles.backContainer}>
      <Button disabled={progress === 0} className={styles.backButton} onClick={back} type='link'><CogIcon icon='Arrow-left' /> {getTranslations('LOGIN_BACK')}</Button>
    </div>
    <div className={styles.steps}>
      {steps.map(step => {
        return <div className={cls(styles.step, {
          [styles.active]: progress === step,
        })} key={step} />;
      })}
    </div>
  </div>;
};
