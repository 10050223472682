import React, { useState } from "react";

import { Form, InputNumber, Select, Switch } from "antd";
import { useSelector } from "react-redux";
import {
  selectEvaluationsDict,
  selectTests,
} from "../../features/evaluation/evaluationsSlice";
import { StepProps } from "./interfaces";

import styles from "./form.module.scss";
import formStyles from "../common/form.module.scss";
import { getTranslations } from "../../features/translations/translationsUtils";

export const SecondStep: React.FC<StepProps> = ({
  newTopic,
  setNewTopic,
  form,
}) => {
  const tests = useSelector(selectTests);
  const evaluationsDict = useSelector(selectEvaluationsDict);
  const handleTestUpdate = (evaluationId: string) =>
    setNewTopic({ ...newTopic, evaluation: evaluationId });
  const hasTestOpened = !!newTopic?.evaluation;
  const [testOpened, setTestOpened] = useState(hasTestOpened);

  return (
    <React.Fragment>
      <div className={formStyles.hidableContainer}>
        <div className={formStyles.label}>Test</div>
        <Switch checked={testOpened} size='small' onChange={() => {
          setNewTopic({ ...newTopic, evaluation: null });
          form?.resetFields(['evaluation'])
          setTestOpened(!testOpened);
        }} />
      </div>
      {testOpened ? <Form.Item
        label={getTranslations('TOPIC_SELECT_TEST')}
        name={"evaluation"}
        rules={[{ required: false, message: getTranslations('GENERAL_REQUIRED') }]}
        initialValue={newTopic?.evaluation ? newTopic?.evaluation?.toString() : null}
      >
        <Select
          placeholder={getTranslations('TOPIC_SELECT_TEST')}
          style={{ width: "100%" }}
          onChange={(value) => {
            handleTestUpdate(`${value}`);
          }}
        >
          {tests.map((id) => {
            return (
              <Select.Option key={id} value={id} className={styles.option}>
                {evaluationsDict[id].title} <span className={styles.questionCount}>({getTranslations('TOPIC_QUESTION_COUNT', [evaluationsDict[id].questions.length])})</span>
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item> : null}

      {newTopic?.evaluation && evaluationsDict[newTopic?.evaluation] ? (
        <React.Fragment>
          <Form.Item
            initialValue={newTopic?.correctAnswersToPass}
            name="correctAnswersToPass"
            label={getTranslations('TOPIC_ANSWER_TO_APPROVE')}
            rules={[
              { required: true, message: getTranslations('GENERAL_REQUIRED') },
              {
                type: "number",
                min: 1,
                message: getTranslations('TOPIC_ANSWER_TO_APPROVE_ERROR_1'),              },
              {
                type: "number",
                max:
                  evaluationsDict[newTopic?.evaluation].questions.length,
                message: getTranslations('TOPIC_ANSWER_TO_APPROVE_ERROR_2', [evaluationsDict[newTopic?.evaluation].questions.length]),
              },
              {
                validator: (rule, value: any) => {
                  return new Promise((resolve, reject) => {
                    resolve(true);
                  });
                },
              },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              placeholder={getTranslations('TOPIC_ANSWER_TO_APPROVE_PLACEHOLDER')}
              onChange={(value) => {
                setNewTopic({ ...newTopic, correctAnswersToPass: value });
              }}
            />
          </Form.Item>
        </React.Fragment>
      ) :
        null
      }
    </React.Fragment>
  );
};
