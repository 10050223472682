import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Microservices } from "../../app/AllowedMicroservices";
import { AppThunk, RootState } from "../../app/store";
import { StatusEnum } from "../../components/common/Status";
import apiActionCreator, { HttpMethods } from "../../services/apiActionCreator";
import { MODAL_DELAY_MS } from "../../utils/constants";
import { FetchStatus, ModalStatus } from "../common/enums";
import { setMessageError, setMessageInfo } from "../message/messageSlice";
import { getTranslations } from "../translations/translationsUtils";

export interface IGroup {
  _id: string,
  name: string,
  status: StatusEnum,
  company: string,
  courses: string[],
  companyName?: string, //Only for filter
  createdAt: string,
}

export interface GroupDict {
  [_id: string]: IGroup;
}

interface GroupState {
  list: IGroup[],
  dict: GroupDict,
  groupPerCompanies: {
    [_id:string]: string[],
  }
  status: FetchStatus,
  modalStatus: ModalStatus,
  created?: IGroup,
}

const initialState: GroupState = {
  list: [],
  dict: {},
  groupPerCompanies: {},
  status: FetchStatus.Idle,
  modalStatus: ModalStatus.Hide,
};

interface GroupFetch {
  docs: IGroup[],
  limit: number,
  page: number,
  pages: number,
  total: number,
}

interface GroupUpdateStatusData {
  ids: string[];
}

export const groupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    creating: (state) => {
      state.status = FetchStatus.Creating;
    },
    created: (state, action: PayloadAction<IGroup>) => {
      state.created = action.payload;
    },
    fetching: (state) => {
      state.status = FetchStatus.Fetching;
    },
    fetched: (state, action: PayloadAction<GroupFetch>) => {
      state.list = action.payload.docs;
      state.status = FetchStatus.Fetched;
      state.dict = {};
      action.payload.docs.forEach((group) => {
        state.dict[group._id] = group;
        if(!state.groupPerCompanies[group.company]){
          state.groupPerCompanies[group.company] = []
        }
        state.groupPerCompanies[group.company].push(group._id);
      });
    },
    error: (state, _action: PayloadAction<GroupFetch>) => {
      state.list = [];
      state.status = FetchStatus.Error;
    },
    showModal: (state) => {
      state.modalStatus = ModalStatus.Show;
    },
    hideModal: (state) => {
      state.modalStatus = ModalStatus.Hide;
    },
  },
});

const { showModal } = groupsSlice.actions;
export const {
  fetching,
  fetched,
  error,
  creating,
  hideModal,
  created,
} = groupsSlice.actions;

export const createGroup = (newGroup: IGroup) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: "/groups",
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: created,
          },
          {
            actionOrCreator: fetchGroups,
          },
          {
            actionOrCreator: showModal,
            selector: () => {
              dispatch(hideModalWithDelay());
            },
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.POST,
      microservice: Microservices.COMPANY_SERVICE,
      authenticated: true,
      data: newGroup,
    })
  );
};

export const updateGroup = (group: IGroup) => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: `/groups/${group._id}`,
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: fetchGroups,
          },
          {
            actionOrCreator: showModal,
            selector: () => {
              dispatch(hideModalWithDelay());
            },
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.COMPANY_SERVICE,
      authenticated: true,
      data: group,
    })
  );
};

export const fetchGroups = () => (dispatch: Dispatch<object>) => {
  return dispatch(
    apiActionCreator({
      endpoint: "/groups?limit=1000000",
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.COMPANY_SERVICE,
      authenticated: true,
    })
  );
};

export const fetchGroupsByCompany = (companyId: string) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: `/groups/byCompany/${companyId}?limit=1000000`,
      types: {
        requestType: fetching,
        successTypes: [
          {
            actionOrCreator: fetched,
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
        ],
      },
      method: HttpMethods.GET,
      microservice: Microservices.COMPANY_SERVICE,
      authenticated: true,
    })
  );
};

export const enableGroupsStatus = (groups: GroupUpdateStatusData) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: "/groups/enable",
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: fetchGroups,
          },
          {
            actionOrCreator: setMessageInfo({
              message: getTranslations('GROUP_ENABLED_GROUPS_MESSAGE'),
            }),
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
          {
            actionOrCreator: fetchGroups,
          },
          {
            actionOrCreator: setMessageError({
              message: getTranslations('GROUP_ENABLED_GROUPS_MESSAGE_FAILED'),
            }),
          },
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.COMPANY_SERVICE,
      authenticated: true,
      data: groups,
    })
  );
};

export const disableGroupsStatus = (groups: GroupUpdateStatusData) => (
  dispatch: Dispatch<object>
) => {
  return dispatch(
    apiActionCreator({
      endpoint: "/groups/disable",
      types: {
        requestType: creating,
        successTypes: [
          {
            actionOrCreator: fetchGroups,
          },
          {
            actionOrCreator: setMessageInfo({
              message: getTranslations('GROUP_DISABLED_GROUPS_MESSAGE'),
            }),
          },
        ],
        errorTypes: [
          {
            actionOrCreator: error,
          },
          {
            actionOrCreator: fetchGroups,
          },
          {
            actionOrCreator: setMessageError({
              message: getTranslations('GROUP_DISABLED_GROUPS_MESSAGE_FAILED'),
            }),
          },
        ],
      },
      method: HttpMethods.PUT,
      microservice: Microservices.COMPANY_SERVICE,
      authenticated: true,
      data: groups,
    })
  );
};

const hideModalWithDelay = (): AppThunk => async (dispatch) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      dispatch(hideModal());
      resolve();
    }, MODAL_DELAY_MS);
  });
};

export const selectGroups = (state: RootState) => state.groups.list;
export const selectGroupsDict = (state: RootState) => state.groups.dict;
export const selectGroupsFetchStatus = (state: RootState) =>
  state.groups.status;
export const selectAreGroupsFetching = (state: RootState) =>
  state.groups.status === FetchStatus.Fetching;
export const selectGroup = (id: string) => (state: RootState) =>
  state.groups.list.find((group) => group._id === id);
export const selectDisplayModal = (state: RootState) =>
  state.groups.modalStatus === ModalStatus.Show;
export const selectCreatedGroup = (state: RootState) => state.groups.created;
export const selectCompaniesGroups = (state: RootState) => state.groups.groupPerCompanies;


export default groupsSlice.reducer;
