import React, {
  FunctionComponent,
  useState,
} from "react";
import cls from 'classnames';
import { Document, Page, pdfjs } from 'react-pdf';

import styles from './pdfViewer.module.scss';
import { CogIcon } from "../../common/CogIcon";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface IPDFViewer {
  url: string;
  allowDownload?: boolean;
}
const SCALE_STEP = 0.2;
const MAX_SCALE = 4;
const MIN_SCALE = 1;

export const PDFViewer: FunctionComponent<IPDFViewer> = ({
  url,
  allowDownload,
}) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [page, setPage] = useState<number>(1);
  const [scale, setScale] = useState<number>(2);
  const [fullscreen, setFullscreen] = useState<boolean>(false);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setPage(1);
    setNumPages(numPages);
  };



  const goToPrevPage = () => {
    setPage(page - 1 <= 1 ? 1 : page - 1);
  }

  const goToNextPage = () => {
    if (numPages) {
      setPage(
        page + 1 >= numPages ? numPages : page + 1,
      );
    } else
      setPage(1);
  }

  const onZoomIn = () => {
    if (scale < MAX_SCALE) {
      setScale(scale + SCALE_STEP);
    } else {
      setScale(MAX_SCALE);
    }
  }

  const toggleFullscreen = () => {
    setFullscreen(!fullscreen);
    if (!fullscreen) {
      document.body.classList.add(styles.bodyFullscreen);
    } else {
      document.body.classList.remove(styles.bodyFullscreen);
    }
  }

  const onZoomOut = () => {
    if (scale > MIN_SCALE) {
      setScale(scale - SCALE_STEP);
    } else {
      setScale(MIN_SCALE);
    }
  }
  const backColor = page === 1 ? '#81858B' : 'white';
  const nextColor = numPages === page ? '#81858B' : 'white';
  const zoomInColor = scale === MAX_SCALE ? '#81858B' : 'white';
  const zoomOutColor = scale === MIN_SCALE ? '#81858B' : 'white';

  return <>
    <div className={styles.pdfViewerContainer}>
      <div className={cls(styles.container, {
        [styles.fullscreen]: fullscreen,
      })}>
        <Document
          className={styles.pdfViewer}
          file={url}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page
            pageNumber={page}
            height={500}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            key={`page_${page}`}
            scale={scale}
          />
        </Document>
      </div>
      <div className={cls(styles.tools, {
        [styles.fullscreen]: fullscreen,
      })}>
        <div className={styles.button} onClick={onZoomOut}><CogIcon size={19} color={zoomOutColor} icon="zoom-out" /></div>
        <div className={styles.button} onClick={onZoomIn}><CogIcon size={19} color={zoomInColor} icon="zoom-in" /></div>
        <nav className={styles.navigator}>
          <div className={styles.button} onClick={goToPrevPage}><CogIcon color={backColor} icon="Arrow-up-small" /></div>
          <div className={styles.pageIndicator}>
            {page} / {numPages}
          </div>
          <div className={styles.button} onClick={goToNextPage}><CogIcon color={nextColor} icon="Arrow-drop-down" /></div>
        </nav>
        <div className={styles.button} onClick={toggleFullscreen}><CogIcon size={19} color="white" icon="full" /></div>
        {allowDownload ? <div className={styles.button} onClick={() => {
          window.open(url, "_blank");
        }}><CogIcon size={19} color={zoomInColor} icon="Download" /></div> : null}
      </div>
    </div>
  </>;
}
