import React, { useEffect } from "react";

import { Form, Row, Col } from "antd";
import { FormInstance } from "antd/lib/form";
import { useDispatch } from "react-redux";

import { ICategoriesDict } from "../../features/category/categorySlice";
import { ITopic } from "../../features/topic/topicSlice";
import { FirstStep } from "./FirstStep";
import { FirstStep2 } from "./FirstStep2";
import { SecondStep } from "./SecondStep";
import { fetchEvaluations } from "../../features/evaluation/evaluationsSlice";

import formStyles from "../common/form.module.scss";
import { getTranslations } from "../../features/translations/translationsUtils";

interface TopicFormProps {
  categories: ICategoriesDict;
  categoriesIds: string[];
  form: FormInstance;
  newTopic?: ITopic;
  setNewTopic: Function;
}

export const TopicForm: React.FunctionComponent<TopicFormProps> = ({
  categories,
  categoriesIds,
  form,
  newTopic,
  setNewTopic,
}) => {
  const dispatch = useDispatch();
  const validateForm = form.validateFields;

  useEffect(() => {
    dispatch(fetchEvaluations());
  }, [dispatch]);

  return <Form
    form={form}
    className={formStyles.form}
    layout="vertical"
    name="control-hooks"
  >
    <Row gutter={50}>
      <Col span={9}>
        <div className={formStyles.section}>
          <div className={formStyles.sectionTitle}>{getTranslations('TOPIC_BASIC_INFO')}</div>
          <FirstStep
            newTopic={newTopic}
            setNewTopic={setNewTopic}
            categories={categories}
            categoriesIds={categoriesIds}
            validateForm={validateForm}
          />
        </div>
      </Col>
      <Col span={15}>
        <FirstStep2
          newTopic={newTopic}
          setNewTopic={setNewTopic}
          categories={categories}
          categoriesIds={categoriesIds}
          validateForm={validateForm}
        />
        <div className={formStyles.section}>
          <div className={formStyles.sectionTitle}>{getTranslations('TOPIC_BASIC_INFO')}</div>
          <SecondStep
            newTopic={newTopic}
            setNewTopic={setNewTopic}
            categories={categories}
            categoriesIds={categoriesIds}
            validateForm={validateForm}
            form={form}
          />
        </div>
      </Col>
    </Row>

  </Form>;
};
