import React, { useEffect, useState } from "react";

import {
  DatePicker,
  Form,
  Input,
  Select,
  Row,
  Col,
} from "antd";
import { FormInstance } from "antd/lib/form";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Microservices } from "../../app/AllowedMicroservices";

import { BASE_URL } from "../../app/apiMiddleware";
import {
  VisibilityRole,
} from "../../features/campaign/campaignSlice";
import {
  selectCompanies,
  selectCompaniesIds,
} from "../../features/company/companySlice";
import {
  fetchEvaluations,
  selectEvaluationsDict,
  selectSurveys,
} from "../../features/evaluation/evaluationsSlice";
import { CogIcon } from "../common/CogIcon";
import { MIN_3_CHAR } from "../Users/Form";
import { UploadFile } from "../Users/UploadFile";

import styles from "./form.module.scss";
import formStyles from "../common/form.module.scss";
import { getTranslations } from "../../features/translations/translationsUtils";
import { RangePickerProps } from "antd/lib/date-picker";
import { UserRole } from "../../features/users/usersSlice";
import { selectUserRole } from "../../features/auth/authSlice";

const dateFormat = "DD/MM/YYYY";

export interface InitialValues {
  description: string;
  title: string;
  resultsVisibilityRole?: VisibilityRole;
  companyId?: string;
  survey?: string;
  rangeDate?: [moment.Moment, moment.Moment];
  image?: string;
}

interface Props {
  initialValues: InitialValues;
  form: FormInstance;
  campaignId?: string;
  onUpdateValues: React.Dispatch<
    React.SetStateAction<InitialValues>
  >;
}

export const CampaignForm: React.FunctionComponent<Props> = ({
  form,
  campaignId,
  initialValues,
  onUpdateValues,
}) => {
  const surveys = useSelector(selectSurveys);
  const evaluations = useSelector(selectEvaluationsDict);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const idsList = useSelector(selectCompaniesIds);
  const list = useSelector(selectCompanies);
  const userRole = useSelector(selectUserRole);
  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;

  useEffect(() => {
    dispatch(fetchEvaluations());
  }, [dispatch]);

  const isDateDisabled =
    campaignId !== undefined &&
    initialValues.rangeDate &&
    initialValues.rangeDate[0].diff(moment(new Date())) <= 0;

  const handleChangeFile = (info: any) => {
    const { status } = info.file;
    if (status === "uploading") {
      setLoading(true);
      setError("");
      return;
    }
    if (status === "done") {
      const currentCampaign = form.getFieldsValue() as InitialValues;
      setLoading(false);
      form.setFieldsValue({
        ...currentCampaign,
        image: info.file.response.url,
      });
      onUpdateValues({ ...initialValues, image: info.file.response.url });
    } else if (status === "error") {
      setError("Error al subir imagen");
    }
  };

  const handleSurveyUpdate = (evaluationId: string) => {
    const currentCampaign = form.getFieldsValue() as InitialValues;
    form.setFieldsValue({
      ...currentCampaign,
      survey: evaluationId,
    });
    onUpdateValues({ ...initialValues, survey: evaluationId });
  };
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
  };

  return (
    <Form
      form={form}
      initialValues={initialValues}
      className={styles.container}
      layout="vertical"
    >
      <Row gutter={50}>
        <Col span={9}>
          <div className={formStyles.section}>
            <UploadFile
              onHandleChange={handleChangeFile}
              uploadUrl={`${BASE_URL[Microservices.FILES_SERVICE]
                }/files/uploadToS3`}
              loading={loading}
              fileTypesAllowed={["image/jpeg", "image/png", "image/gif"]}
              fileNotAllowedMessage="¡Solo se puede subir imagenes jpg, png o gif!"
              imageUrlToShow={initialValues.image}
            />
            <p>{error}</p>
            {userRole === UserRole.SuperAdmin ? <Form.Item
              name="companyId"
              wrapperCol={{ sm: 24 }}
              label={getTranslations('GENERAL_COMPANY')}
              rules={[{ required: true, message: getTranslations('GENERAL_REQUIRED') }]}
            >
              <Select
                placeholder={getTranslations('GENERAL_SELECT_COMPANY')}
                style={{ width: "100%" }}
              >
                {idsList.map((id) => {
                  return (
                    <Select.Option key={id} value={id}>
                      {list[id].name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item> : null}
          </div>

        </Col>
        <Col span={15}>
          <div className={formStyles.section}>
            <div className={formStyles.sectionTitle}>{getTranslations('CAMPAIGN_BASIC_INFO')}</div>
            <Form.Item
              label={getTranslations('CAMPAIGN_FIELD_TITLE')}
              name={"title"}
              rules={[
                { required: true, message: getTranslations('GENERAL_REQUIRED') },
                { type: "string", min: 3, message: MIN_3_CHAR() },
                { type: "string", max: 50, message: getTranslations('CAMPAIGN_FIELD_TITLE_ERROR_1') },
              ]}
            >
              <Input type="text" placeholder={getTranslations('CAMPAIGN_FIELD_TITLE')} />
            </Form.Item>
            <Form.Item
              label={getTranslations('CAMPAIGN_DESCRIPTION')}
              name="description"
              rules={[
                { type: "string", required: true, message: getTranslations('GENERAL_REQUIRED') },
                {
                  type: "string",
                  max: 500,
                  message: getTranslations('CAMPAIGN_DESCRIPTION_ERROR_1'),
                },
              ]}
            >
              <Input.TextArea placeholder={getTranslations('CAMPAIGN_DESCRIPTION')} />
            </Form.Item>
            <Form.Item
              label={getTranslations('CAMPAIGN_DATE')}
              name="rangeDate"
              rules={[
                { required: true, message: getTranslations('GENERAL_REQUIRED') },
                () => ({
                  validator: async (_rules, value) => {
                    if (!value) {
                      return;
                    }
                    if (isDateDisabled) {
                      return;
                    }
                    const startDate = value[0];
                    const endDate = value[1];
                    const today = moment(new Date());
                    if (
                      !(
                        today.isBefore(startDate) || today.isSame(startDate, "days")
                      )
                    ) {
                      throw new Error(getTranslations('CAMPAIGN_ERROR_1'));
                    } else if (startDate.isSame(endDate)) {
                      throw new Error(getTranslations('CAMPAIGN_ERROR_2'));
                    }
                  },
                }),
              ]}
            >
              <RangePicker
                name="rangeDate"
                placeholder={["DD/MM/AAAA", "DD/MM/AAAA"]}
                suffixIcon={<CogIcon icon="Calendar" color={"#c1c1c1"} />}
                separator="-"
                format={dateFormat}
                disabled={isDateDisabled}
                disabledDate={disabledDate}
                className={styles.rangePicker}
              />
            </Form.Item>
          </div>
          <div className={formStyles.section}>
            <div className={formStyles.sectionTitle}>{getTranslations('CAMPAIGN_SURVEY')}</div><br />
            <Form.Item
              label={getTranslations('CAMPAIGN_SELECT_SURVEY')}
              name={"survey"}
              rules={[{ required: false, message: getTranslations('GENERAL_REQUIRED') }]}
              initialValue={initialValues.survey?.toString()}
            >
              <Select
                placeholder={getTranslations('CAMPAIGN_SELECT_SURVEY')}
                style={{ width: "100%" }}
                onChange={(value) => {
                  handleSurveyUpdate(`${value}`);
                }}
              >
                {surveys.map((id) => {
                  return (
                    <Select.Option key={id} value={id} className={styles.option}>
                      {evaluations[id]?.title} <span className={styles.questionCount}>({getTranslations('CAMPAIGN_QUESTION_COUNT', [evaluations[id]?.questions.length])})</span>
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
