import React, { useEffect, useState } from "react";

import { Form, Row, Col } from "antd";
import { FormInstance } from "antd/lib/form";

import { ICategoriesDict } from "../../features/category/categorySlice";
import { ILesson, MediaTypes } from "../../features/lesson/lessonSlice";
import { StepOne } from "./StepOne";
import { StepZero } from "./StepZero";
import { StepOne2 } from "./StepOne2";
import { StepZero2 } from "./StepZero2";

import formStyles from "../common/form.module.scss";
import { getTranslations } from "../../features/translations/translationsUtils";

interface Props {
  form: FormInstance;
  fileType: MediaTypes;
  newLesson: ILesson;
  categories: ICategoriesDict;
  categoriesIds: string[];
  setNewLesson: React.Dispatch<React.SetStateAction<ILesson>>;
  setFileType: React.Dispatch<React.SetStateAction<MediaTypes>>;
  youtubeUrlError?: string;
}

export const LessonForm: React.FunctionComponent<Props> = ({
  form,
  newLesson,
  fileType,
  categories,
  categoriesIds,
  setNewLesson,
  setFileType,
  youtubeUrlError,
}) => {
  const [acceptedTypes, setAcceptedTypes] = useState<string>("");

  useEffect(() => {
    if (fileType === MediaTypes.IMAGE) {
      setAcceptedTypes("image/jpeg,image/jpg,image/gif,image/png");
    }
    if (fileType === MediaTypes.PDF) {
      setAcceptedTypes("application/pdf");
    }
    if (fileType === MediaTypes.VIDEO) {
      setAcceptedTypes(
        "video/mp4,video/x-flv,video/quicktime,video/x-msvideo,video/x-ms-wmv"
      );
    }
  }, [fileType]);

  return (
    <Form
      form={form}
      className={formStyles.form}
      layout="vertical"
      name="control-hooks"
    >
      <Row gutter={50}>
        <Col span={9}>
          <div className={formStyles.section}>
            <div className={formStyles.sectionTitle}>{getTranslations('LESSON_BASIC_INFO')}</div>
            <StepZero
              categories={categories}
              categoriesIds={categoriesIds}
              form={form}
              fileType={fileType}
              newLesson={newLesson}
              acceptedTypes={acceptedTypes}
              setNewLesson={setNewLesson}
              setFileType={setFileType}
            />
          </div>
        </Col>
        <Col span={15}>
          <StepOne
            categories={categories}
            categoriesIds={categoriesIds}
            form={form}
            fileType={fileType}
            newLesson={newLesson}
            acceptedTypes={acceptedTypes}
            setNewLesson={setNewLesson}
            setFileType={setFileType}
            youtubeUrlError={youtubeUrlError}
          />
          <div className={formStyles.section}>
            <div className={formStyles.sectionTitle}>{getTranslations('LESSON_ADV_CONFIG')}</div>
            <StepZero2
              categories={categories}
              categoriesIds={categoriesIds}
              form={form}
              fileType={fileType}
              newLesson={newLesson}
              acceptedTypes={acceptedTypes}
              setNewLesson={setNewLesson}
              setFileType={setFileType}
            />
            <StepOne2
              categories={categories}
              categoriesIds={categoriesIds}
              form={form}
              fileType={fileType}
              newLesson={newLesson}
              acceptedTypes={acceptedTypes}
              setNewLesson={setNewLesson}
              setFileType={setFileType}
            />
          </div>
        </Col>
      </Row>

    </Form>
  );
};
