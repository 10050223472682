import React, { useState, FunctionComponent, useEffect } from 'react';
import { Form, Button, Input, Select } from 'antd';
import cls from 'classnames';
import phoneValidator from "phone";

import { Header } from '../Header';
import { ISignUpStepProps, layout } from './interfaces';
import { UploadImage } from '../../common/UploadImage';
import { Countries, ISelectValue, MIN_3_CHAR } from '../../Users/Form';
import { getUniqueNamesCities } from '../../common/cities.utils';

import styles from './index.module.scss';
import commonStyles from '../Login.module.scss';
import { PhoneInputAntdWrapper } from '../../common/PhoneInputAntdWrapper';
import { getTranslations } from '../../../features/translations/translationsUtils';

interface Step2Props extends ISignUpStepProps {

};

export const Step4: FunctionComponent<Step2Props> = ({ next, userData, save }) => {
  const [picture, setPicture] = useState<string>(userData.picture || '');
  const [country, setCountry] = useState<string>(userData.country || '');
  const [city, setCity] = useState<string>(userData.city || '');
  const [address, setAddress] = useState<string>(userData.address || '');
  const [phone_number, setPhone] = useState<string>(userData.phone_number || '');
  const [loadingImage, setLoadingImage] = useState<boolean>(false);
  const [cities, setCities] = useState<ISelectValue[]>([]);
  useEffect(() => {
  }, []);
  const handleSubmit = () => {
    form.validateFields().then(() => {
      next({
        address,
        city,
        country,
        phone_number,
        picture
      });
    });
  };
  const handleChange = (info: any) => {
    if (info.file.status === "uploading") {
      setLoadingImage(true);
      return;
    }
    if (info.file.status === "done") {
      setLoadingImage(false);
      setPicture(info.file.response.url);
      save({
        picture: info.file.response.url
      });
    }
  };
  const [form] = Form.useForm();
  return (
    <Form
      {...layout}
      form={form}
      name="basic"
      onFinish={() => {
        handleSubmit();
      }}
      onFinishFailed={() => {
        console.error('signup failed');
      }}

    >
      <div
        className={cls(commonStyles.formInnerContainer, commonStyles.overload, commonStyles.noHeightLimit)}
      >
        <Header title={getTranslations('LOGIN_STEP_4_TITLE')}>
          <div className={styles.subTitles}>
            {getTranslations('LOGIN_STEPS_4_MESSAGE')}
          </div>
        </Header>
        <Form.Item
          name="picture"
          initialValue={picture}
          wrapperCol={{ sm: 24 }}
        >
          <UploadImage
            loading={loadingImage}
            imageUrl={picture}
            onHandleChange={handleChange}
          />
        </Form.Item>
        <Form.Item
          label={getTranslations('LOGIN_COUNTRY')}
          name="country"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValue={country}
          rules={[
            { type: "string", required: true, message: getTranslations('LOGIN_COUNTRY_ERROR') },
          ]}
        >
          <Select
            showSearch
            onChange={async (value) => {
              setCountry(value as string);
              setCity('');
              const countryCities: any = await import(`../../../assets/countries/cities/${value}.json`);
              const cities: ISelectValue[] = getUniqueNamesCities(countryCities);
              setCities(cities);

              save({
                country: value,
                city: '',
              });
            }}
          >
            {Countries.map((item) => (
              <Select.Option key={item.value} value={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={getTranslations('LOGIN_CITY')}
          name="city"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValue={city}
          rules={[
            { type: "string", required: true, message: getTranslations('LOGIN_CITY_ERROR') },
          ]}
        >
          <Select
            showSearch
            onChange={(value) => {
              setCity(value as string);
              save({
                city: value,
              });
            }}
          >
            {cities.map((item) => (
              <Select.Option key={item.value} value={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={getTranslations('LOGIN_ADDRESS')}
          name="address"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValue={address}
          rules={[
            { type: "string", required: true, message: getTranslations('GENERAL_REQUIRED') },
            { type: "string", max: 200, message: getTranslations('LOGIN_ADDRESS_ERROR') },
            { type: "string", min: 3, message: MIN_3_CHAR() },
          ]}
        >
          <Input.TextArea
            onChange={(event) => {
              setAddress(event.target.value);
              save({
                address: event.target.value,
              });
            }}
          />
        </Form.Item>
        <Form.Item
          label={getTranslations('LOGIN_PHONE')}
          name="phone_number"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValue={phone_number}
          rules={[
            (obj) => ({
              validator: async (rules, value) => {
                const plusPhone = `+${value}`;
                const phone = phoneValidator(plusPhone);
                if (phone.length === 0) {
                  throw new Error(
                    getTranslations('LOGIN_PHONE_ERROR')
                  );
                }
              },
            }),
          ]}
        >
          {/* @ts-ignore */}
          <PhoneInputAntdWrapper
            onChange={(phone) => {
              const plusPhone = `+${phone}`;
              setPhone(plusPhone);
              save({
                phone_number: plusPhone,
              });
            }}
          />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          disabled={false}
          className={cls(commonStyles.signupBtn, styles.signupBtn2)}
        >
          {getTranslations('LOGIN_CREATE_ACCOUNT')}
        </Button>
      </div>
    </Form>
  );
};
