import React, { FunctionComponent } from 'react';
import cls from 'classnames';
import footerStyles from './Footer.module.scss';
import { useHistory } from 'react-router-dom';

interface FooterProps {
  title: string,
  linkLabel: string,
  linkRef: string,
  className?: string,
}

export const Footer: FunctionComponent<FooterProps> = ({ title, linkLabel, linkRef }) => {
  const history = useHistory();
  return <div className={cls(footerStyles.container)}>
    <div className={footerStyles.info}>{title}</div>
    <a
      className={cls(footerStyles.link)}
      href='#s'
      onClick={() => {
        history.push(linkRef);
      }}
      rel="noopener noreferrer"
    >
      {linkLabel}
    </a>
  </div>;
}
