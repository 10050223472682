import React, { useEffect, useState } from "react";

import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { selectCompanyId, selectUserRole } from '../../features/auth/authSlice';
import {
  EvaluationTypeEnum,
} from "../../features/evaluation/common/enums";
import { IEvaluation } from "../../features/evaluation/common/interfaces";
import {
  createEvaluation,
  fetchEvaluations,
  hideModal,
  selectAreEvaluationFetching,
  selectDisplayModal,
} from "../../features/evaluation/evaluationsSlice";
import { UserRole } from '../../features/users/usersSlice';
import ConfirmationModal from "../common/ConfirmationModal";
import { AfterActions, DialogPopUp } from "../common/DialogPopUp";
import { MainLayout } from "../common/Layouts/Main";
import EvaluationForm from "./Form";
import { Prefixes } from "../prefixes";
import { useHistory } from "react-router-dom";
import { getTranslations } from "../../features/translations/translationsUtils";

const emptyState = {
  evaluationTitle: "",
  type: EvaluationTypeEnum.Quiz,
  questions: [
    {
      title: "",
      answers: [
        { title: "", feedback: "" },
        { title: "", feedback: "" },
      ],
      selected: 0,
    },
  ],
};

export const NewEvaluation: React.FunctionComponent = () => {
  const [restart, setRestart] = useState(true);
  const [afterAction, setAfterAction] = useState<AfterActions>();
  const history = useHistory();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const userRole = useSelector(selectUserRole);
  const companyId = useSelector(selectCompanyId);
  const initialValues = { ...emptyState };

  const isModalVisible = useSelector(selectDisplayModal);
  const isLoading = useSelector(selectAreEvaluationFetching);
  const dispatch = useDispatch();

  const handleClose = () => {
    history.push(`${Prefixes.evaluation}`);
    setShowConfirmationModal(false);
  };
  const handleOpenConfirmationModal = () => setShowConfirmationModal(true);
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);
  const handleCreate = (currentEvaluation: IEvaluation, afterAction: AfterActions) => {
    if (userRole !== UserRole.SuperAdmin) {
      currentEvaluation.companyId = companyId; // force to company in case of creating
    }
    if (!currentEvaluation.companyId) {
      currentEvaluation.companyId = undefined;
    }
    setAfterAction(afterAction);
    dispatch(createEvaluation(currentEvaluation));
  };
  useEffect(() => {
    if (restart) {
      setRestart(false);
    }
  }, [restart]);

  useEffect(() => {
    dispatch(fetchEvaluations());
  }, [dispatch]);

  if (restart) {
    return null;
  }

  return (
    <MainLayout
      title={getTranslations('EVALUATION_CREATE')}
      onGoBack={() => {
        history.push(`${Prefixes.evaluation}`);
      }}
      breadcrumbs={[getTranslations('EVALUATION_TITLE'), getTranslations('EVALUATION_NEW')]}
    >
      {showConfirmationModal && (
        <ConfirmationModal
          headerIcon="Help"
          title={getTranslations('EVALUATION_EXIT_MESSAGE')}
          message={
            <p>{getTranslations('EVALUATION_CANCEL_MESSAGE')}</p>
          }
          footer={[
            <Button key="keep" type="text" onClick={handleCloseConfirmationModal}>
              {getTranslations('EVALUATION_CANCEL_kEEP')}
            </Button>,
            <Button key="out" type="link" onClick={handleClose}>
              {getTranslations('EVALUATION_CANCEL_EXIT')}
            </Button>,
          ]}

          onCancel={handleCloseConfirmationModal}
          onConfirmation={handleClose}
        />
      )}
      <DialogPopUp
        name={getTranslations('EVALUATION_EXCELLENT')}
        action={"crear"}
        visible={isModalVisible}
        onCancel={() => {
          dispatch(hideModal());
          if (afterAction === AfterActions.BACK) {
            history.push(`${Prefixes.evaluation}`);
            return;
          }
          setRestart(true);
        }}
      />
      <EvaluationForm
        isLoading={isLoading}
        onClose={handleOpenConfirmationModal}
        onSave={handleCreate}
        initialValues={initialValues}
      />
    </MainLayout>
  );
};
