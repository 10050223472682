import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Unsubscribe, onSnapshot, doc, getFirestore } from 'firebase/firestore';

import { firebaseApp } from "./firebase";

import badge from '../assets/infographic/badge.png';
import { markMessageAsSeen } from '../features/gamification/gamificationSlice';

import styles from './pointsNotificationFirestore.module.scss';
import { fetchPoints } from '../features/students/pointsSlice';
import { getTranslations } from '../features/translations/translationsUtils';

const NOTIFICATION_COLLECTION = 'notification';
const db = getFirestore(firebaseApp);

interface IPointsNotificationFirestore {
  userId: string;
  email: string;
}

interface INotification {
  message: string;
  points: number;
  type: string;
}
interface INotifications {
  notifications: INotification[];
}

export const PointsNotificationFirestore: FunctionComponent<IPointsNotificationFirestore> = ({
  email,
  userId
}) => {
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const dispatch = useDispatch();
  const id = `${userId}_${email}`;
  useEffect(() => {
    const unsubscribe: Unsubscribe = onSnapshot(
      doc(db, NOTIFICATION_COLLECTION, id),
      { includeMetadataChanges: true },
      (doc) => {
        const { notifications = [] } = (doc.data() || {}) as INotifications;
        console.log("🚀 ~ useEffect ~ notifications:", notifications)
        if (notifications.length === 0) {
          setNotifications([]);
          return;
        }
        dispatch(fetchPoints());
        setNotifications(notifications);
        setTimeout(() => {
          dispatch(markMessageAsSeen());
        }, 4000);
      }
    );
    return () => {
      unsubscribe();
    };
  }, [dispatch, id]);

  if (notifications.length === 0) {
    return null;
  }

  return <div className={styles.notifications}>
    {notifications.map((notification, index) => {
      return <div key={index} className={styles.container}>
      <div className={styles.badge}><img src={badge} alt='badge' /></div>
      <div className={styles.message}>{getTranslations(notification.message)}</div>
      <div className={styles.points}>+{notification.points} pts</div>
    </div>
    })}
  </div>;
}